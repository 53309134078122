import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { commonservices, AppConfigData } from "../../Services/commonService";
import { ReactComponent as Logo } from "../../assets/Images/login-logo.svg";
import { Spinner } from "react-bootstrap";
function Login() {
    const navigate = useNavigate();
    const [Loading, setLoading] = useState(false);

    const [ShowPassword, setShowPassword] = useState(false);
    function showPassword() {
        setShowPassword(!ShowPassword);
    }
    const formInitialSchema = {
        email: "",
        password: "",
    };

    const formValidation = yup.object().shape({
        email: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "email"))
            .email(commonservices.MakeValidationMessage("email", "email")),
        password: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "password"))
    });
    useEffect(() => {
        let token = localStorage.getItem("token");
        if (token !== null) {
            if (token !== "") {
                navigate("/dashboard");
            }
        }
    }, []);
    return (
        <div className="login-page">
            <div className="container-fluid p-0 h-100">
                <div className="login-box h-100">
                    <div className="row m-0 h-100">
                        <div className="col-12 p-0 d-flex justify-content-center align-items-center">
                            <div className="login-right-col">
                                <div className="login-logo text-center">
                                    <Logo />
                                </div>
                                <Formik
                                    initialValues={formInitialSchema}
                                    validationSchema={formValidation}
                                    onSubmit={async (values) => {
                                        setLoading(true);
                                        const formData = new FormData();
                                        formData.append("UserName", values.email);
                                        formData.append("Password", values.password);
                                        const axiosConfig = {
                                            headers: {
                                                "content-type": "multipart/form-data",
                                            },
                                        };
                                        const response = await axios
                                            .post(
                                                `${AppConfigData.APIBasePath}v1/user/login`,
                                                formData,
                                                axiosConfig
                                            )
                                            .then((response) => {
                                                setLoading(false);
                                                localStorage.setItem("token", response.data.data.accesstoken);
                                                localStorage.setItem("userData", response.data.data.userData);
                                                commonservices.setItem(
                                                    "roleData",
                                                    JSON.stringify(response.data.data.roleData)
                                                );

                                                commonservices.setItem(
                                                    "userData",
                                                    JSON.stringify(response.data.data.userData)
                                                );
                                                if (response.data.status_code === 0) {
                                                    toast.error(response.data.message);
                                                } else if (response.data.status_code === 1) {
                                                    toast.success("You are Successfully Logged In");
                                                    navigate("/dashboard");
                                                } else if (response.data.status_code === 2) {
                                                    toast.error(response.data.message);
                                                }
                                                else {
                                                    toast.error(response.data.message);
                                                }
                                            })
                                            .catch((error) => {
                                            });
                                    }}
                                >
                                    <Form>
                                        <div className="form-group">
                                            <Field
                                                type="email"
                                                name="email"
                                                className="form-control"
                                                placeholder={commonservices.LabelDisplayText("EnterYourEmail")}
                                            />
                                            <label lbl-for="email">{commonservices.LabelDisplayText("Email")}</label>
                                            <div className="text-danger-error">
                                                <ErrorMessage name="email" />
                                            </div>
                                            <span className="gmail-icon">
                                                <i className="fa-solid fa-envelope"></i>
                                            </span>
                                        </div>

                                        <div className="form-group password">
                                            <Field
                                                type={ShowPassword ? "text" : "password"}
                                                name="password"
                                                className="form-control"
                                                placeholder={commonservices.LabelDisplayText("EnterYourPassword")}
                                            />
                                            <label lbl-for="password">{commonservices.LabelDisplayText("Password")}</label>
                                            <div className="text-danger-error">
                                                <ErrorMessage name="password" />
                                            </div>
                                            <div className="passwordshow">
                                                {ShowPassword ? (
                                                    <span
                                                        className="showIcon"
                                                        role="button"
                                                        tabIndex={-1}
                                                        onClick={() => showPassword()}
                                                    >
                                                        <i className="fa-solid fa-eye-slash"></i>
                                                    </span>
                                                ) : (
                                                    <span
                                                        className="hideIcon"
                                                        role="button"
                                                        tabIndex={-1}
                                                        onClick={() => showPassword()}
                                                    >
                                                        <i className="fa-solid fa-eye"></i>
                                                    </span>
                                                )}
                                            </div>
                                        </div>

                                        <div className="form-check login-selection-data-area">
                                            <label htmlFor="forgot-pass" className="forgot-pass">
                                                <Link to="/forgot">
                                                    {commonservices.LabelDisplayText("ForgotPassword")} ?
                                                </Link>
                                            </label>
                                        </div>

                                        <div className="login-btn-class">
                                            <button className="btn btn-for-login" type="submit" title="Sign In">
                                            {Loading && <Spinner animation="border" />}{commonservices.LabelDisplayText("Signin")}
                                            </button>
                                        </div>
                                    </Form>
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Login;
