import axios, { AxiosError } from "axios";
import { useState, useEffect } from "react";
import { ErrorMessage, Field, Form, Formik, FormikProps } from "formik";
import { default as React } from "react";
import File from "../assets/Icons/code.svg";
import { toast } from "react-toastify";
import moment from "moment";
import * as yup from "yup";
import { commonservices, AppConfigData } from "../Services/commonService";
import { Col, Modal, Button, Container, Row, Table } from "react-bootstrap";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import Spinner from "react-bootstrap/Spinner";
import $ from "jquery";

const AddTaskModal = (props) => {
  const [projectTerm, setProjectTerm] = useState([]);
  const [Submitting, setSubmitting] = useState(false);
  const [lstProjectStory, setlstProjectStory] = useState([]);
  const [lstProject, setlstProject] = useState([]);
  const [lstProjectUser, setlstProjectUser] = useState([]);
  const [lstTaskSubPoint, setlstTaskSubPoint] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [disabledTrue, setDisabledTrue] = useState(false);
  const [optionUser, setoptionUser] = useState([]);
  const [isvalidUser, setisvalidUser] = useState(false);
  const [ProjectIDBlur, setProjectIDBlur] = useState("");
  const [file, setFile] = useState([]);
  let data = commonservices.getLoginUserData();

  const formFieldLength = {
    Title: 267,
  };

  const formInitialSchema = {
    TaskID: 0,
    Title: "",
    Details: "",
    Status: "",
    Priority: "",
    StartDate: commonservices.getDateInDBFormat(new Date()),
    EndDate: commonservices.getDateInDBFormat(new Date()),
    IsArchive: "",
    Tag: "",
    ProjectStoryID: "",
    ProjectID: "",
    ProjectUser: "",
  };
  const [IsValidationMessageSet, setIsValidationMessageSet] = useState("");

  useEffect(() => {
    setIsValidationMessageSet(true);
  });

  function fnAddNewSubPoint() {
    const newList = lstTaskSubPoint.concat({
      lstSubPointID: commonservices.createGuid(),
      Title: "",
      TaskSubPointID: 0,
    });
    setlstTaskSubPoint(newList);
  }

  function removeSubPoint(index) {
    let data = [...lstTaskSubPoint];
    data.splice(index, 1);
    setlstTaskSubPoint(data);
  }

  const formValidation = yup.object().shape({
    ProjectID: yup
      .string()
      .required(commonservices.MakeValidationMessage("required", "ProjectID")),
    Title: yup
      .string()
      .required(commonservices.MakeValidationMessage("required", "Title"))
      .test(
        "len",
        commonservices.MakeValidationMessage("maxlength", ""),
        (value, obj) => {
          return value ? value.length <= formFieldLength.Title : true;
        }
      ),
    //Details: yup
    //    .string()
    //    .required(commonservices.MakeValidationMessage("required", "Details")),
    Status: yup
      .string()
      .required(commonservices.MakeValidationMessage("required", "Status")),
    Priority: yup
      .string()
      .required(commonservices.MakeValidationMessage("required", "Priority")),
    StartDate: yup
      .date()
      .required(commonservices.MakeValidationMessage("required", "StartDate"))
      .nullable(),
    EndDate: yup
      .date()
      .required(commonservices.MakeValidationMessage("required", "EndDate"))
      .min(
        yup.ref("StartDate"),
        commonservices.MakeValidationMessage(
          "custom",
          "EndDate",
          commonservices
            .CustomValidationMessage("ValidationData1CannotBeLessThanDate2")
            .replace("{0}", $("[lbl-for='EndDate']").text().trim())
            .replace("{1}", $("[lbl-for='StartDate']").text().trim())
        )
      ),
  });

  useEffect(() => {
    if (props.projectid > 0) {
      formInitialSchema.ProjectID = props.projectid;
      setDisabledTrue(true);
    }

    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(
        `${AppConfigData.APIBasePath}v1/projectterm/getlist`,
        {
          term: "",
          category: "Documents,TaskStatus,TaskPriority",
        },
        axiosConfig
      )
      .then((res) => {
        if (res.data.status_code === 0) {
        } else if (res.data.status_code === 1) {
          setProjectTerm(res.data.data);
        } else if (res.data.status_code === 2) {
        } else {
        }
      })
      .catch((err) => {});

    axios
      .post(
        `${AppConfigData.APIBasePath}v1/project/getlist`,
        {
          ProjectName: "",
          Status: "",
          StartDate: null,
          EndDate: null,
          Technologies: "",
          SearchText: "",
        },
        axiosConfig
      )
      .then((res) => {
        if (res.data.status_code === 0) {
        } else if (res.data.status_code === 1) {
          setlstProject(res.data.data.projectList);
          if (
            res.data.data.projectList !== null &&
            res.data.data.projectList.length !== 0
          ) {
            setlstProject(res.data.data.projectList);
          }
          setlstProjectUser(res.data.data.projectUser);
          if (props.projectid > 0) {
            fnGetProjectStoryByProjectID(
              props.projectid,
              res.data.data.projectUser
            );
          }
        } else if (res.data.status_code === 2) {
        } else {
        }
      })
      .catch((err) => {});
  }, []);

  function GetProjectStory(ProjectID) {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(
        `${AppConfigData.APIBasePath}v1/projectstory/getlist`,
        {
          Title: "",
          ProjectID: ProjectID,
          StartDate: null,
          EndDate: null,
          Status: "",
          SearchText: "",
        },
        axiosConfig
      )
      .then((res) => {
        if (res.data.status_code === 0) {
        } else if (res.data.status_code === 1) {
          setlstProjectStory(res.data.data);
        } else if (res.data.status_code === 2) {
        } else {
        }
      })
      .catch((err) => {});
  }
  function fnGetProjectStory(event) {
    // function fnGetProjectStory(event) {

    /*console.log("Click Fire", event.target.value)*/
    let value = 0;
    if (
      event.target.value !== undefined &&
      event.target.value !== null &&
      event.target.value !== ""
    ) {
      value = Number(event.target.value);

      // let ABC = lstProject.length > 0 && lstProject.find((item) => item.ProjectID === value);
      // setProjectIDBlur(ABC.ProjectName)

      // formInitialSchema.ProjectID = value;
    }
    let optionUser = [];
    lstProjectUser
      .filter((t) => t.ProjectID === value)
      .forEach((element) => {
        optionUser.push({
          label: element.DisplayName,
          value: element.UserID,
        });
      });
    setoptionUser(optionUser);
    GetProjectStory(value);
  }

  const fnGetProjectStoryByProjectID = (projectid, userlist) => {
    const value = projectid;
    let optionUser = [];
    userlist
      .filter((t) => t.ProjectID === value)
      .forEach((element) => {
        optionUser.push({
          label: element.DisplayName,
          value: element.UserID,
        });
      });
    setoptionUser(optionUser);
    GetProjectStory(value);
  };

  function fnSelectedOptionUser(value, event) {
    setSelectedUser(value);
    if (value !== null && value !== undefined && value !== "") {
      setisvalidUser(false);
    } else {
      setisvalidUser(true);
    }
  }

  function uploadSingleFile(e) {
    let lst = [...file];
    Object.entries(e.target.files).forEach((element) => {
      for (let i = 0; i < element.length; i++) {
        if (
          element[i].type !== null &&
          element[i].type !== "" &&
          element[i].type !== undefined
          //   && element[i].size <= 20971520
        ) {
          lst.push({
            path: URL.createObjectURL(element[1]),
            filetype: element[i].type,
            name: element[i].name,
            file: element[i],
          });
        }
      }
    });

    setFile(lst);
  }

  function deleteFile(e) {
    const s = file.filter((item, index) => index !== e);
    setFile(s);
  }

  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        className="right-modal modal-new modal-create-task"
        scrollable={true}
        backdrop="static"
        keyboard={false}
      >
        <Formik
          initialValues={formInitialSchema}
          validationSchema={formValidation}
          onSubmit={async (values) => {
            if (selectedUser.length !== 0) {
              setisvalidUser(false);
              setSubmitting(true);

              let PushNotificationData = [];
              const formData = new FormData();
              formData.append("TaskID", values.TaskID);
              formData.append("Title", values.Title);
              formData.append("Details", values.Details ? values.Details : "");
              formData.append("Status", values.Status);
              formData.append("Priority", values.Priority);
              formData.append("StartDate", values.StartDate);
              formData.append("EndDate", values.EndDate);
              formData.append("IsArchive", values.IsArchive);
              // formData.append("Tag", values.Tag);
              formData.append("StoryID", values.ProjectStoryID);
              formData.append("ProjectID", values.ProjectID);
              //   var ins = document.getElementsByName("Document")[0].files.length;
              var ins = file.length;
              for (var x = 0; x < ins; x++) {
                formData.append(
                  "lstDocuments[" + x + "].DocName",
                  //   document.getElementsByName("Document")[0].files[x].name
                  file[x].name
                );
                formData.append(
                  "lstDocuments[" + x + "].File",
                  //   document.getElementsByName("Document")[0].files[x]
                  file[x].file
                );
              }

              for (let i = 0; i < selectedUser.length; i++) {
                formData.append(
                  "lstTaskUser[" + i + "].UserID",
                  selectedUser[i].value
                );
                if (data[0].UserID !== selectedUser[i].value) {
                  PushNotificationData.push({
                    UserID: selectedUser[i].value,
                    Message: values.Title,
                    Title: "New Task Assign To You",
                  });
                }
              }
              let JJ = 0;
              for (let j = 0; j < lstTaskSubPoint.length; j++) {
                if (
                  values["SubPoint" + lstTaskSubPoint[j].lstSubPointID] !==
                    undefined &&
                  values["SubPoint" + lstTaskSubPoint[j].lstSubPointID] !==
                    null &&
                  values["SubPoint" + lstTaskSubPoint[j].lstSubPointID] !== ""
                ) {
                  formData.append(
                    "lstTaskSubPoint[" + JJ + "].TaskSubPointID",
                    lstTaskSubPoint[j].TaskSubPointID
                  );
                  formData.append(
                    "lstTaskSubPoint[" + JJ + "].Title",
                    values["SubPoint" + lstTaskSubPoint[j].lstSubPointID]
                  );
                  JJ++;
                }
              }

              const axiosConfig = {
                headers: {
                  "content-type": "multipart/form-data",
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              };
              const response = await axios
                .post(
                  `${AppConfigData.APIBasePath}v1/task/save`,
                  formData,
                  axiosConfig
                )
                .then((res) => {
                  setSubmitting(false);
                  if (res.data.status_code === 0) {
                    toast.error(res.data.message);
                  } else if (res.data.status_code === 1) {
                    props.onHide();
                    commonservices.SendPushNotification(PushNotificationData);
                    toast.success(res.data.message);
                    //setTimeout(() => {
                    //    props.onHide();
                    //}, 1000);
                    props.bindList();
                    props.taskBind();
                  } else if (res.data.status_code === 2) {
                    toast.error(res.data.message);
                  } else {
                    toast.error(res.data.message);
                  }
                })
                .catch((err) => {});
            } else {
              setisvalidUser(true);
            }
          }}
        >
          {({
            handleChange,
            setFieldValue,
            setFieldTouched,
            handleBlur,
            handleSubmit,
            isSubmitting,
            values,
          }) => {
            const setProductValue = (ProjectID) => {
           
              setFieldValue("ProjectID", ProjectID.target.value);
              setFieldTouched("ProjectID", true, false);
              fnGetProjectStory(ProjectID);
            };
            return (
              <Form>
                <Modal.Header closeButton className="border-0">
                  <Modal.Title id="contained-modal-title-vcenter">
                    {commonservices.LabelDisplayText("CreateTask")}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Container>
                    <Row>
                      <Col xs={6} className="mb-3">
                        <Field
                          type="hidden"
                          name="TaskID"
                          className="form-control"
                          placeholder="TaskID"
                        />
                        <div className="form-floating">
                          <Field
                            as="select"
                            name="ProjectID"
                            className="form-control down-arrow-icon"
                            disabled={disabledTrue}
                            onChange={setProductValue}
                           >
                            <option
                              value=""
                            >
                              {commonservices.LabelDisplayText("Select")}
                            </option>
                            {lstProject.length > 0 &&
                              lstProject.map((item, index) => (
                                <option key={index} value={item.ProjectID}>
                                  {item.ProjectName}
                                </option>
                              ))}
                          </Field>
                          <label lbl-for="ProjectID">
                            {commonservices.LabelDisplayText("ProjectName")}
                          </label>
                          <div className="text-danger-error">
                            <ErrorMessage name="ProjectID" />
                          </div>
                        </div>
                      </Col>
                      <Col xs={6} className="mb-3">
                        <div className="form-floating">
                          <Field
                            type="text"
                            name="Title"
                            className="form-control"
                            placeholder={commonservices.LabelDisplayText(
                              "Title"
                            )}
                            maxLength={formFieldLength.Title}
                          />
                          <label lbl-for="Title">
                            {commonservices.LabelDisplayText("Title")}
                          </label>
                          <div className="text-danger-error">
                            <ErrorMessage name="Title" />
                          </div>
                        </div>
                      </Col>
                      <Col xs={6} className="mb-3">
                        <div className="form-group">
                          <div className="form-floating">
                            <Field
                              type="date"
                              name="StartDate"
                              className="form-control calendar-icon"
                            />
                            <label lbl-for="StartDate">
                              {commonservices.LabelDisplayText("StartDate")}
                            </label>
                            <div className="text-danger-error">
                              <ErrorMessage name="StartDate" />
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xs={6} className="mb-3">
                        <div className="form-group">
                          <div className="form-floating">
                            <Field
                              type="date"
                              name="EndDate"
                              className="form-control calendar-icon"
                            />
                            <label lbl-for="EndDate">
                              {commonservices.LabelDisplayText("EndDate")}
                            </label>
                            <div className="text-danger-error">
                              <ErrorMessage name="EndDate" />
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col xs={6} className="mb-3">
                        <div className="form-floating">
                          <Field
                            as="select"
                            name="Priority"
                            className="form-control down-arrow-icon"
                          >
                            <option value="">
                              {commonservices.LabelDisplayText("Select")}
                            </option>
                            {projectTerm.length > 0 &&
                              projectTerm.map(
                                (item, index) =>
                                  item.Category === "TaskPriority" && (
                                    <option key={index} value={item.Term}>
                                      {item.Term}
                                    </option>
                                  )
                              )}
                          </Field>
                          <label lbl-for="Priority">
                            {commonservices.LabelDisplayText("Priority")}
                          </label>
                          <div className="text-danger-error">
                            <ErrorMessage name="Priority" />
                          </div>
                        </div>
                      </Col>

                      <Col xs={6} className="mb-3">
                        <div className="form-floating">
                          <Field
                            as="select"
                            name="Status"
                            className="form-control down-arrow-icon"
                          >
                            <option value="">
                              {commonservices.LabelDisplayText("Select")}
                            </option>
                            {projectTerm.length > 0 &&
                              projectTerm.map(
                                (item, index) =>
                                  item.Category === "TaskStatus" && (
                                    <option key={index} value={item.Term}>
                                      {item.Term}
                                    </option>
                                  )
                              )}
                          </Field>
                          <label lbl-for="Status">
                            {commonservices.LabelDisplayText("Status")}
                          </label>
                          <div className="text-danger-error">
                            <ErrorMessage name="Status" />
                          </div>
                        </div>
                      </Col>

                      <Col xs={6} className="mb-3">
                        <div className="form-floating multipleselect-form-floating">
                          <ReactMultiSelectCheckboxes
                            width={"100%"}
                            placeholderButtonLabel={"Assign To"}
                            isMulti
                            name="ProjectUser"
                            onChange={fnSelectedOptionUser}
                            options={optionUser}
                            value={selectedUser}
                          />
                          <label lbl-for="ProjectUser">
                            {commonservices.LabelDisplayText("Select")}
                          </label>
                          {isvalidUser ? (
                            <div className="text-danger-error">
                              {commonservices.LabelDisplayText(
                                "SelectTheUserName"
                              )}
                            </div>
                          ) : (
                            <div className="text-danger-error"></div>
                          )}
                        </div>
                      </Col>
                      <Col xs={6} className="mb-3">
                        <div className="form-floating">
                          <Field
                            as="select"
                            name="ProjectStoryID"
                            className="form-control down-arrow-icon"
                          >
                            <option value="">
                              {commonservices.LabelDisplayText("Select")}{" "}
                            </option>
                            {lstProjectStory.length > 0 &&
                              lstProjectStory.map((item, index) => (
                                <option key={index} value={item.ProjectStoryID}>
                                  {item.Title}
                                </option>
                              ))}
                          </Field>
                          <label lbl-for="ProjectStoryID">
                            {commonservices.LabelDisplayText("ProjectStory")}
                          </label>
                          <div className="text-danger-error">
                            <ErrorMessage name="ProjectStory" />
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} className="mb-3">
                        <div className="form-floating">
                          <Field
                            as="textarea"
                            name="Details"
                            className="form-control pt-2 pb-2 h-auto"
                            rows="5"
                            placeholder={commonservices.LabelDisplayText(
                              "Details"
                            )}
                          />
                          <label lbl-for="Details">
                            {commonservices.LabelDisplayText("Details")}
                          </label>
                          <div className="text-danger-error">
                            <ErrorMessage name="Details" />
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} className="mb-3">
                        <Button
                          onClick={(e) => fnAddNewSubPoint()}
                          title="Add"
                          className="save-btn"
                        >
                          Add SubPoint
                        </Button>
                      </Col>
                      {lstTaskSubPoint.length > 0 &&
                        lstTaskSubPoint.map((item, index) => (
                          <Col xs={6} className="mb-3 mb-2">
                            <div className="form-floating relative ">
                              <Field
                                type="text"
                                name={`SubPoint` + item.lstSubPointID}
                                className="form-control"
                                placeholder={commonservices.LabelDisplayText(
                                  "SubPoint"
                                )}
                                maxLength={267}
                              />
                              <label lbl-for={`SubPoint` + item.lstSubPointID}>
                                {commonservices.LabelDisplayText("SubPoint")}
                              </label>
                              <Button
                                variant="btn btn-close-subpoint-icon"
                                title="Close"
                                onClick={(e) =>
                                  removeSubPoint(index, item.lstSubPointID)
                                }
                              >
                                <i className="fa fa-close"></i>
                              </Button>
                            </div>
                          </Col>
                        ))}

                      <Col xs={12} className="mb-3">
                        <label>Attachment</label>
                        <div className="attachmentimg">
                          {file.length > 0 &&
                            file.map((item, index) => {
                              return item.filetype === "image/jpeg" ? (
                                <>
                                  <div
                                    key={index}
                                    className="attachmentimg-item position-relative"
                                  >
                                    <div className="image-uploaded h-100">
                                      <img src={item.path} alt="" />
                                    </div>
                                    <button
                                      className="btn-close-subpoint-icon"
                                      type="button"
                                      title="Close"
                                      onClick={() => deleteFile(index)}
                                    >
                                      <i className="fa fa-close"></i>
                                    </button>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div
                                    key={item}
                                    className="attachmentimg-item position-relative"
                                  >
                                    <div className="image-uploaded h-100">
                                      <img src={File} alt="" />
                                    </div>
                                    <button
                                      className="btn-close-subpoint-icon"
                                      title="Close"
                                      type="button"
                                      onClick={() => deleteFile(index)}
                                    >
                                      <i className="fa fa-close"></i>
                                    </button>
                                  </div>
                                </>
                              );
                            })}
                          <div className="documnet-attachment plus-open upload-input-wrap">
                            <i className="fa-regular fa-plus"></i>
                            <Field
                              type="file"
                              name="Document"
                              className="form-control upload-input"
                              onChange={uploadSingleFile}
                              multiple
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={props.onHide} className="Cancel-btn" title="Cancel">
                    {commonservices.LabelDisplayText("Cancel")}
                  </Button>
                  <Button
                    className="btn btn-primary save-btn"
                    type="submit"
                    title="Submit"
                    disabled={Submitting}
                  >
                    {Submitting && (
                      <>
                        <Spinner animation="border" />
                      </>
                    )}
                    {commonservices.LabelDisplayText("Create")}
                  </Button>
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};
export default AddTaskModal;
