import axios from "axios";
import React, { useState, useEffect } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import * as yup from "yup";
import { commonservices, AppConfigData } from "../Services/commonService";
import { Col, Modal, Button, Container, Row, Table } from "react-bootstrap";
import { useSearchParams, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import Spinner from "react-bootstrap/Spinner";

const AddAchievementModal = (props) => {
    let param = useParams();
    const [Submitting, setSubmitting] = useState(false);
    const [IsGetDetailsDone, setIsGetDetailsDone] = useState(false);
    const [IsValidationMessageSet, setIsValidationMessageSet] = useState("");

    useEffect(() => {
        setIsValidationMessageSet(true);
    });

    const formInitialSchema = {
        EmpAchievementID: 0,
        Type: "",
        Institute: "",
        Skills: "",
        Credentials: "",
        IssueDate: "",
        EmployeeID: "",
    };

    const formFieldLength = {
        Type: 167,
        Institute: 167,
        Skills: 167,
        Credentials: 167,
    };

    const formValidation = yup.object().shape({
        Type: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "Type"))
            .test('len', commonservices.MakeValidationMessage("maxlength", ""), (value, obj) => { return value ? value.length <= formFieldLength.Type : true }),
        Institute: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "Institute"))
            .test('len', commonservices.MakeValidationMessage("maxlength", ""), (value, obj) => { return value ? value.length <= formFieldLength.Institute : true }),
        Skills: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "Skills"))
            .test('len', commonservices.MakeValidationMessage("maxlength", ""), (value, obj) => { return value ? value.length <= formFieldLength.Skills : true }),
        Credentials: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "Credentials"))
            .test('len', commonservices.MakeValidationMessage("maxlength", ""), (value, obj) => { return value ? value.length <= formFieldLength.Credentials : true }),
        IssueDate: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "IssueDate")),
    });

    useEffect(() => {
        if (props.id > 0) {
            const axiosConfig = {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            };
            axios
                .post(
                    `${AppConfigData.APIBasePath}v1/empachievement/getdetailsbyid`,
                    {
                        id: props.id,
                        requestFrom: "Web",
                    },
                    axiosConfig
                )
                .then((res) => {
                    if (res.data.status_code === 0) {
                        toast.error(res.data.message);
                    } else if (res.data.status_code === 1) {

                        commonservices.CheckNullAndReturnBlankInObjectKey(
                            res.data.data.empachievement
                        );

                        formInitialSchema.EmpAchievementID =
                            res.data.data.empachievement.empAchievementID;
                        formInitialSchema.Type = res.data.data.empachievement.type;
                        formInitialSchema.Institute =
                            res.data.data.empachievement.institute;
                        formInitialSchema.Skills = res.data.data.empachievement.skills;
                        formInitialSchema.Credentials =
                            res.data.data.empachievement.credentials;
                        formInitialSchema.IssueDate =
                            res.data.data.empachievement.issueDate;
                        formInitialSchema.EmployeeID =
                            res.data.data.empachievement.employeeID;
                        setIsGetDetailsDone(true);
                    } else if (res.data.status_code === 2) {
                        toast.error(res.data.message);
                    } else {
                        toast.error(res.data.message);
                    }
                })
                .catch((err) => { });
        }
    }, []);

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                className="right-modal modal-new"
                // centered
                scrollable={true}
                backdrop="static"
                keyboard={false}
            >
                <Formik
                    initialValues={formInitialSchema}
                    validationSchema={formValidation}
                    onSubmit={async (values) => {
                        setSubmitting(true);
                        const formData = new FormData();
                        formData.append("EmpAchievementID", values.EmpAchievementID);
                        formData.append("Type", values.Type);
                        formData.append("Institute", values.Institute);
                        formData.append("Skills", values.Skills);
                        formData.append("Credentials", values.Credentials);
                        formData.append("IssueDate", values.IssueDate);
                        formData.append("EmployeeID", parseInt(param.id));

                        const axiosConfig = {
                            headers: {
                                "content-type": "multipart/form-data",
                                Authorization: "Bearer " + localStorage.getItem("token"),
                            },
                        };
                        const response = await axios
                            .post(
                                `${AppConfigData.APIBasePath}v1/empachievement/save`,
                                formData,
                                axiosConfig
                            )
                            .then((res) => {
                                setSubmitting(false);
                                if (res.data.status_code === 0) {
                                    toast.error(res.data.message);
                                } else if (res.data.status_code === 1) {
                                    toast.success(res.data.message);
                                    //setTimeout(() => {
                                    //  props.onHide()
                                    //}, 1000);
                                    props.bindList();
                                    props.onHide();
                                } else if (res.data.status_code === 2) {
                                    toast.error(res.data.message);
                                } else {
                                    toast.error(res.data.message);
                                }
                            })
                            .catch((error) => { });
                    }}
                >
                    <Form>
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                {commonservices.LabelDisplayText("AchievementDetail")}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Container>
                                <Row>
                                    <Field
                                        type="hidden"
                                        name="EmployeeID"
                                        className="form-control"
                                    />
                                    <Field
                                        type="hidden"
                                        name="EmpAchievementID"
                                        className="form-control"
                                    />

                                    <Col xs={6} className="mb-3">
                                        <div className="form-floating">
                                            <Field
                                                type="text"
                                                name="Type"
                                                className="form-control"
                                                placeholder={commonservices.LabelDisplayText("Type")}
                                                maxLength={formFieldLength.Type}
                                            />
                                            <label lbl-for="Type">{commonservices.LabelDisplayText("Type")}</label>
                                            <div className="text-danger-error">
                                                <ErrorMessage name="Type" />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xs={6} className="mb-3">
                                        <div className="form-floating">
                                            <Field
                                                type="text"
                                                name="Institute"
                                                className="form-control"
                                                placeholder={commonservices.LabelDisplayText(
                                                    "Institute"
                                                )}
                                                maxLength={formFieldLength.Institute}
                                            />
                                            <label lbl-for="Institute">
                                                {commonservices.LabelDisplayText("Institute")}
                                            </label>
                                            <div className="text-danger-error">
                                                <ErrorMessage name="Institute" />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xs={6} className="mb-3">
                                        <div className="form-floating">
                                            <Field
                                                type="text"
                                                className="form-control"
                                                name="Skills"
                                                placeholder={commonservices.LabelDisplayText("Skills")}
                                                maxLength={formFieldLength.Skills}
                                            />
                                            <label lbl-for="Skills">{commonservices.LabelDisplayText("Skills")}</label>
                                            <div className="text-danger-error">
                                                <ErrorMessage name="Skills" />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xs={6} className="mb-3">
                                        <div className="form-floating">
                                            <Field
                                                type="text"
                                                name="Credentials"
                                                className="form-control"
                                                placeholder={commonservices.LabelDisplayText(
                                                    "Credentials"
                                                )}
                                                maxLength={formFieldLength.Credentials}
                                            />
                                            <label lbl-for="Credentials">
                                                {commonservices.LabelDisplayText("Credentials")}
                                            </label>
                                            <div className="text-danger-error">
                                                <ErrorMessage name="Credentials" />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xs={6} className="mb-3">
                                        <div className="form-floating">
                                            <Field
                                                type="date"
                                                name="IssueDate"
                                                className="form-control"
                                                placeholder={commonservices.LabelDisplayText(
                                                    "IssueDate"
                                                )}
                                            />
                                            <label lbl-for="IssueDate">
                                                {commonservices.LabelDisplayText("IssueDate")}
                                            </label>
                                            <div className="text-danger-error">
                                                <ErrorMessage name="IssueDate" />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={props.onHide} title="Close">
                                {commonservices.LabelDisplayText("Close")}
                            </Button>
                            <Button
                                className="btn btn-primary"
                                type="submit"
                                title="Submit"
                                disabled={Submitting}
                            >
                                {Submitting && (
                                    <>
                                        <Spinner animation="border" />
                                    </>
                                )}
                                {commonservices.LabelDisplayText("Save")}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Formik>
            </Modal>
        </>
    );
};

export default AddAchievementModal;
