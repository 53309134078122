import { HubConnectionBuilder } from "@microsoft/signalr";
import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import $ from "jquery";
import UserPlaceholder from "../../assets/Icons/placeholder.svg";
import ProjectPlaceholder from "../../assets/Images/ProjectPlaceholder.png";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Modal,
  Nav,
  Row,
  Tab,
  Table,
} from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { confirmAlert } from "react-confirm-alert";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ReactComponent as CalendarIcon } from "../../assets/Icons/calendar-icon.svg";
import { ReactComponent as Pencil } from "../../assets/Icons/pencil.svg";
import { ReactComponent as TimeQuarterPast } from "../../assets/Icons/time-quarter-past.svg";
import { ReactComponent as TimeQuarter } from "../../assets/Icons/time-quarter.svg";
import { ReactComponent as Trash } from "../../assets/Icons/trash.svg";
import Loader, { LoaderType } from "../../Loader";
import AddProjectStory from "../../Modal/AddProjectStory";
import AddProjectUser from "../../Modal/AddProjectUser";
import AddTaskModal from "../../Modal/AddTaskModal";
import AddVault from "../../Modal/AddVault";
import ModalFiles from "../../Modal/ModalFiles";
import ModalProjectShowPasswordVault from "../../Modal/ModalProjectShowPasswordVault";
import ModalShare from "../../Modal/ModalShare";
import TaskDetail from "../../Modal/TaskDetail";
import { AppConfigData, commonservices } from "../../Services/commonService";
import { viewFilePopupService } from "../../Services/viewFilePopup.service";
import Spinner from "react-bootstrap/Spinner";
import * as yup from "yup";
import "react-datepicker/dist/react-datepicker.css";

import { ReactComponent as EyeshowPassword } from "../../assets/Icons/eyeshow-password.svg";
import { ReactComponent as Share } from "../../assets/Icons/share.svg";
import Calendar from "./Calender";
import { ReactComponent as DeleteIconNew } from "../../assets/Icons/delete-icon-new.svg";

function ProjectDetails() {
  let param = useParams();
  let data = commonservices.getLoginUserData();
  const [Submitting, setSubmitting] = useState(false);
  const [ProjectID, setProjectID] = useState(0);
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [OpenTab, setOpenTab] = useState("general");
  const [TaskSearchText, setTaskSearchText] = useState("");
  const [FileSearchText, setFileSearchText] = useState("");
  const [VaultSearchText, setVaultSearchText] = useState("");
  const [modalTaskAdd, setModalTaskAdd] = useState(false);
  const [modalStoryShow, setModalStoryShow] = useState(false);
  const [modalVaultShow, setModalVaultShow] = useState(false);
  const [modalVaultPasswordShow, setModalVaultPasswordShow] = useState(false);
  const [modalFilesShow, setModalFilesShow] = useState(false);
  const [modalShareShow, setModalShareShow] = useState(false);
  const [selectedSkill, setSelectedSkill] = useState([]);
  const [optionSkill, setoptionSkill] = useState([]);
  const [projectItem, setProjectItem] = useState([]);
  const [projectStatus, setProjectStatus] = useState([]);
  const [ProjectFiles, setProjectFiles] = useState([]);
  const [ProjectShare, setProjectShare] = useState([]);
  const [ProjectPassowrdSeeData, setProjectPassowrdSeeData] = useState([]);
  const [ProjectUser, setProjectUser] = useState({});
  const [ProjectStory, setProjectStory] = useState({});
  const [projectDocs, setProjectDocs] = useState({
    Data: [],
    IsLoading: true,
  });
  const [memberslist, setMemberslist] = useState({
    Data: [],
    IsLoading: true,
  });
  useState([]);
  const [ProjectVaultData, setProjectVaultData] = useState({
    Data: [],
    IsLoading: true,
  });
  const [storylist, setStorylist] = useState({
    Data: [],
    IsLoading: true,
  });
  const [projectDetails, setProjectDetails] = useState({
    Data: [],
    IsLoading: true,
  });
  const [actionlog, setActionlog] = useState({
    Data: [],
    IsLoading: true,
  });
  const [projectCount, setProjectCount] = useState({
    Data: [],
    IsLoading: true,
  });
  const [taskList, setTaskList] = useState({
    Data: [],
    IsLoading: true,
  });
  const [taskListNew, setTaskListNew] = useState([]);
  const [taskFile, setTaskFile] = useState([]);
  const [ProjectVault, setProjectVault] = useState({});
  const [TaskDataForShowDetails, setTaskDataForShowDetails] = useState({
    TaskID: 0,
    ProjectID: 0,
  });
  const [modalShowDetails, setModalShowDetails] = useState(false);
  var USERLIST = commonservices.getLoginUserData();
  let USERID = 0;
  if (USERLIST.length !== 0) {
    USERID = USERLIST[0].UserID;
  }
  const [IsFileUploadRunning, setIsFileUploadRunning] = useState(false);
  function uploadHandler(file) {
    setIsFileUploadRunning(true);
    const fd = new FormData();
    fd.append(
      "Document[0].file",
      document.getElementsByName("file")[0].files[0]
    );
    fd.append("Document[0].associationID", parseInt(param.id));
    fd.append("Document[0].associationType", "Project");
    fd.append(
      "Document[0].docName",
      document.getElementsByName("file")[0].files[0].name
    );
    fd.append(
      "Document[0].docType",
      document.getElementsByName("file")[0].files[0].type
    );
    fd.append(
      "Document[0].details",
      document.getElementsByName("file")[0].files[0].lastModifiedDate
    );

    if (file != null) {
      const axiosConfig = {
        headers: {
          Accept: "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .post(`${AppConfigData.APIBasePath}v1/documents/save`, fd, axiosConfig)
        .then((response) => {
          setIsFileUploadRunning(false);
          if (response.data.status_code === 0) {
            toast.error(response.data.message);
          } else if (response.data.status_code === 1) {
            toast.success(response.data.message);
            DocBind();
            bindList();
            actionlogBind(new Date(), new Date());
          } else if (response.data.status_code === 2) {
            toast.error(response.data.message);
          } else {
            toast.error(response.data.message);
          }
        })
        .catch((error) => {
          setIsFileUploadRunning(false);
        });
    } else {
    }
  }

  const formFieldLength = {
    ProjectName: 167,
    ShortDetails: 500,
    TotalCostAmt: 18,
    OverCostAmt: 18,
    Tag: 267,
  };

  const formInitialSchema = {
    ProjectID: 0,
    ProjectName: "",
    ShortDetails: "",
    ClientID: "",
    StartDate: "",
    EndDate: "",
    Status: "",
    Technologies: "",
    IsFixCost: false,
    TotalCostAmt: "",
    NoOfUser: "",
    OverCostAmt: "",
    Tag: "",
    CreatedBy: "",
    CreatedOn: "",
    ActualEndDate: "",
    ProjectLogoFile: "",
  };
  const FILE_SIZE = 160 * 1024; // 160KB
  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
  const formValidation = yup.object().shape({
    ProjectName: yup
      .string()
      .required(commonservices.MakeValidationMessage("required", "ProjectName"))
      .test(
        "len",
        commonservices.MakeValidationMessage("maxlength", ""),
        (value, obj) => {
          return value ? value.length <= formFieldLength.ProjectName : true;
        }
      ),
    ProjectLogoFile: yup
      .mixed()
      .test(
        "type",
        commonservices.MakeValidationMessage(
          "custom",
          "ProjectLogoFile",
          commonservices.CustomValidationMessage("ValidationOnlyImage")
        ),
        (value, obj) => {
          if (
            document.getElementsByName("ProjectLogoFile") !== null &&
            document.getElementsByName("ProjectLogoFile") !== "" &&
            document.getElementsByName("ProjectLogoFile") !== undefined &&
            document.getElementsByName("ProjectLogoFile")[0].files.length > 0
          ) {
            return (
              document.getElementsByName("ProjectLogoFile")[0].files[0].type ===
                "image/jpeg" ||
              document.getElementsByName("ProjectLogoFile")[0].files[0].type ===
                "image/png"
            );
          } else {
            return true;
          }
        }
      ),
    // ProjectLogoFile: yup.mixed()
    // .required("A file is required")
    // .test(
    //   "fileSize",
    //   "File too large",
    //   value => value && value.size <= FILE_SIZE
    // )
    // .test(
    //   "fileFormat",
    //   "Unsupported Format",
    //   value => value && SUPPORTED_FORMATS.includes(value.type)
    // ),
    ShortDetails: yup
      .string()
      .required(
        commonservices.MakeValidationMessage("required", "ShortDetails")
      )
      .test(
        "len",
        commonservices.MakeValidationMessage("maxlength", ""),
        (value, obj) => {
          return value ? value.length <= formFieldLength.ShortDetails : true;
        }
      ),
    ClientID: yup
      .string()
      .required(commonservices.MakeValidationMessage("required", "ClientID")),

    StartDate: yup
      .date()
      .required(commonservices.MakeValidationMessage("required", "StartDate")),

    EndDate: yup
      .date()
      .required(commonservices.MakeValidationMessage("required", "EndDate"))
      .min(
        yup.ref("StartDate"),
        commonservices.MakeValidationMessage(
          "custom",
          "EndDate",
          commonservices.MakeValidationMessage(
            "custom",
            "confirm_password",
            commonservices
              .CustomValidationMessage("ValidationData1CannotBeLessThanDate2")
              .replace("{0}", $("[lbl-for='EndDate']").text().trim())
              .replace("{0}", $("[lbl-for='StartDate']").text().trim())
          )
        )
      ),
    Status: yup
      .string()
      .required(commonservices.MakeValidationMessage("required", "Status")),
    // TotalCostAmt: yup
    //   .number()
    //   .test(
    //     "is-decimal",
    //     commonservices.LabelDisplayText("EnterDecimalValue"),
    //     (value) => (value + "").match(/^\d+(\.)?\d/)
    //   ),
    // OverCostAmt: yup
    //   .number()
    //   .test(
    //     "is-decimal",
    //     commonservices.LabelDisplayText("EnterDecimalValue"),
    //     (value) => (value + "").match(/^\d+(\.)?\d/)
    //   ),
    Tag: yup
      .string()
      .test(
        "len",
        commonservices.MakeValidationMessage("maxlength", ""),
        (value, obj) => {
          return value ? value.length <= formFieldLength.Tag : true;
        }
      ),
  });

  function fnOpenEmployeeAddEditPopup(item) {
    if (item === null) {
      item = {
        projectUserID: 0,
        userID: "",
        projectID: 0,
        isManager: false,
        isTeamLead: false,
        isActive: true,
        isOwner: false,
        projectRoleID: 0,
      };
    }
    setProjectUser(item);
    setModalShow(true);
  }

  function fnOpenProjectAddEditPopup(item) {
    if (item === null) {
      item = {
        projectStoryID: 0,
        projectID: 0,
        title: "",
        details: "",
        startDate: "",
        endDate: "",
        status: "",
        actualEndDate: "",
        isActive: true,
      };
    }
    setProjectStory(item);
    setModalStoryShow(true);
  }

  function fnOpenVaultAddEditPopup(item) {
    if (item === null) {
      item = {
        passwordManagmentID: 0,
        userName: "",
        password: "",
        details: "",
        typeTerm: "",
        associationID: "",
        associationType: "",
        isActive: true,
      };
    }
    setProjectVault(item);
    setModalVaultShow(true);
  }

  //function fnOpenFilesPopup(item) {
  //    setProjectFiles(item);
  //    setModalFilesShow(true);
  //}

  function bindList() {
    setProjectDetails({
      Data: [],
      IsLoading: true,
    });
    setProjectDocs({ Data: [], IsLoading: true });
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(
        `${AppConfigData.APIBasePath}v1/project/getdetailsbyid`,
        {
          id: parseInt(param.id),
          requestFrom: "string",
        },
        axiosConfig
      )
      .then((res) => {
        if (res.data.status_code === 0) {
          setProjectDetails({ Data: [], IsLoading: false });
          setProjectCount({ Data: [], IsLoading: false });
          setMemberslist({ Data: [], IsLoading: false });
          setStorylist({ Data: [], IsLoading: false });
          setProjectVaultData({ Data: [], IsLoading: false });
          setProjectDocs({ Data: [], IsLoading: false });
        } else if (res.data.status_code === 1) {
          setProjectDocs({ Data: res.data.data.documents, IsLoading: false });
          setProjectDetails({ Data: res.data.data.project, IsLoading: false });
          setProjectCount({
            Data: res.data.data.projectCount,
            IsLoading: false,
          });
          setMemberslist({ Data: res.data.data.projectuser, IsLoading: false });
          setStorylist({ Data: res.data.data.projectstory, IsLoading: false });
          setProjectVaultData({
            Data: res.data.data.passwordmanagment,
            IsLoading: false,
          });

          commonservices.CheckNullAndReturnBlankInObjectKey(
            res.data.data.project
          );
          formInitialSchema.ProjectID = res.data.data.project.ProjectID;
          formInitialSchema.ProjectName = res.data.data.project.ProjectName;
          formInitialSchema.ClientID = res.data.data.project.ClientID;
          formInitialSchema.CreatedOn = res.data.data.project.CreatedOn;
          formInitialSchema.EndDate = commonservices.getDateInDBFormat(
            res.data.data.project.EndDate
          );
          formInitialSchema.StartDate = commonservices.getDateInDBFormat(
            res.data.data.project.StartDate
          );
          formInitialSchema.ShortDetails = res.data.data.project.ShortDetails;
          formInitialSchema.Status = res.data.data.project.Status;
          formInitialSchema.TotalCostAmt = res.data.data.project.TotalCostAmt;
          formInitialSchema.NoOfUser = res.data.data.project.NoOfUser;
          formInitialSchema.OverCostAmt = res.data.data.project.OverCostAmt;
          formInitialSchema.Tag = res.data.data.project.Tag;
          formInitialSchema.CreatedBy = res.data.data.project.CreatedBy;
          formInitialSchema.CreatedOn = res.data.data.project.CreatedOn;
          formInitialSchema.IsFixCost =
            res.data.data.project.IsFixCost === 1 ? true : false;

          formInitialSchema.ActualEndDate = commonservices.getDateInDBFormat(
            res.data.data.project.ActualEndDate
          );

          let _selectOption = [];
          if (
            res.data.data.project.Technologies !== null &&
            res.data.data.project.Technologies !== ""
          ) {
            let List = res.data.data.project.Technologies.split(",");
            List.forEach((element) => {
              if (element !== null && element !== "" && element.trim() !== "") {
                _selectOption.push({
                  value: element.trim(),
                  label: element.trim(),
                });
              }
            });
          }
          setSelectedSkill(_selectOption);
          actionlogBind(new Date(), new Date());
        } else if (res.data.status_code === 2) {
          commonservices.ClearLoginDetails();
          navigate("/");
        } else {
          setProjectDetails({ Data: [], IsLoading: false });
          setProjectCount({ Data: [], IsLoading: false });
          setMemberslist({ Data: [], IsLoading: false });
          setStorylist({ Data: [], IsLoading: false });
          setProjectDocs({ Data: [], IsLoading: false });
          setProjectVaultData({ Data: [], IsLoading: false });
        }
      })
      .catch((err) => {
        setProjectDetails({ Data: [], IsLoading: false });
        setProjectCount({ Data: [], IsLoading: false });
        setMemberslist({ Data: [], IsLoading: false });
        setStorylist({ Data: [], IsLoading: false });
        setProjectDocs({ Data: [], IsLoading: false });
        setProjectVaultData({ Data: [], IsLoading: false });
      });
  }

  function fnSelectedOptionSkill(value, event) {
    setSelectedSkill(value);
  }

  function getStatus() {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(
        `${AppConfigData.APIBasePath}v1/projectterm/getlist`,
        {
          term: "",
          category: "ProjectStatus,technologies",
        },
        axiosConfig
      )
      .then((res) => {
        if (res.data.status_code === 0) {
        } else if (res.data.status_code === 1) {
          setProjectStatus(res.data.data);
          let optionSkill = [];
          res.data.data
            .filter((t) => t.Category === "Technologies")
            .forEach((element) => {
              optionSkill.push({
                label: element.Term,
                value: element.Term,
              });
            });
          setoptionSkill(optionSkill);
        } else if (res.data.status_code === 2) {
        } else {
        }
      })
      .catch((err) => {});
  }

  function getClient() {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(
        `${AppConfigData.APIBasePath}v1/client/getlist`,
        {
          term: "",
          category: "Documents",
        },
        axiosConfig
      )
      .then((res) => {
        if (res.data.status_code === 0) {
        } else if (res.data.status_code === 1) {
          setProjectItem(res.data.data);
        } else if (res.data.status_code === 2) {
        } else {
        }
      })
      .catch((err) => {});
  }

  function deleteData(id) {
    let input = {
      id: id,
      requestFrom: "Web",
    };
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(
        `${AppConfigData.APIBasePath}v1/projectuser/delete`,
        input,
        axiosConfig
      )
      .then((res) => {
        if (res.data.status_code === 0) {
          toast.error(res.data.message);
        } else if (res.data.status_code === 1) {
          toast.success(res.data.message);
          memberBind();
        } else if (res.data.status_code === 2) {
          toast.error(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {});
  }

  function deleteStory(id) {
    let input = {
      id: id,
      requestFrom: "string",
    };
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(
        `${AppConfigData.APIBasePath}v1/projectstory/delete`,
        input,
        axiosConfig
      )
      .then((res) => {
        if (res.data.status_code === 0) {
          toast.error(res.data.message);
        } else if (res.data.status_code === 1) {
          toast.success(res.data.message);
          storyBind();
          actionlogBind(new Date(), new Date());
        } else if (res.data.status_code === 2) {
          toast.error(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {});
  }

  function deleteVault(id) {
    let input = {
      id: id,
      requestFrom: "string",
    };
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(
        `${AppConfigData.APIBasePath}v1/passwordmanagment/delete`,
        input,
        axiosConfig
      )
      .then((res) => {
        if (res.data.status_code === 0) {
          toast.error(res.data.message);
        } else if (res.data.status_code === 1) {
          toast.success(res.data.message);
          vaultBind();
          actionlogBind(new Date(), new Date());
        } else if (res.data.status_code === 2) {
          toast.error(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {});
  }

  function deleteDoc(id) {
    let input = {
      id: id,
      requestFrom: "string",
    };
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(
        `${AppConfigData.APIBasePath}v1/documents/delete`,
        input,
        axiosConfig
      )
      .then((res) => {
        if (res.data.status_code === 0) {
          toast.error(res.data.message);
        } else if (res.data.status_code === 1) {
          toast.success(res.data.message);
          DocBind();
          actionlogBind(new Date(), new Date());
        } else if (res.data.status_code === 2) {
          toast.error(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {});
  }

  function memberBind() {
    setMemberslist({
      Data: [],
      IsLoading: true,
    });
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(
        `${AppConfigData.APIBasePath}v1/projectuser/getlist`,
        {
          id: parseInt(param.id),
          requestFrom: "string",
        },
        axiosConfig
      )
      .then((res) => {
        if (res.data.status_code === 0) {
          setMemberslist({ Data: [], IsLoading: false });
        } else if (res.data.status_code === 1) {
          setMemberslist({ Data: res.data.data, IsLoading: false });
          actionlogBind(new Date(), new Date());
        } else if (res.data.status_code === 2) {
          commonservices.ClearLoginDetails();
          navigate("/");
        } else {
          setMemberslist({ Data: [], IsLoading: false });
        }
      })
      .catch((err) => {
        setMemberslist({ Data: [], IsLoading: false });
      });
  }

  function storyBind() {
    setStorylist({
      Data: [],
      IsLoading: true,
    });
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(
        `${AppConfigData.APIBasePath}v1/projectstory/getlist`,
        {
          projectID: parseInt(param.id),
          requestFrom: "string",
        },
        axiosConfig
      )
      .then((res) => {
        if (res.data.status_code === 0) {
          setStorylist({ Data: [], IsLoading: false });
        } else if (res.data.status_code === 1) {
          setStorylist({ Data: res.data.data, IsLoading: false });
          actionlogBind(new Date(), new Date());
        } else if (res.data.status_code === 2) {
          commonservices.ClearLoginDetails();
          navigate("/");
        } else {
        }
      })
      .catch((err) => {
        setStorylist({ Data: [], IsLoading: true });
      });
  }

  function deleteTaskData(id) {
    let input = {
      id: id,
      requestFrom: "Web",
    };
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(`${AppConfigData.APIBasePath}v1/task/delete`, input, axiosConfig)
      .then((res) => {
        if (res.data.status_code === 0) {
          toast.error(res.data.message);
        } else if (res.data.status_code === 1) {
          taskBind();
          toast.success(res.data.message);
        } else if (res.data.status_code === 2) {
          toast.error(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {});
  }

  function actionlogBind(sdate, edate) {
    setActionlog({
      Data: [],
      IsLoading: true,
    });
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(
        `${AppConfigData.APIBasePath}v1/actionlog/getrecent`,
        {
          associationID: parseInt(param.id),
          associationType: "Project",
          noOfData: 0,
          startDate: sdate,
          endDate: edate,
        },
        axiosConfig
      )
      .then((res) => {
        if (res.data.status_code === 0) {
          setActionlog({ Data: [], IsLoading: false });
        } else if (res.data.status_code === 1) {
          setActionlog({ Data: res.data.data, IsLoading: false });
        } else if (res.data.status_code === 2) {
          commonservices.ClearLoginDetails();
          navigate("/");
        } else {
        }
      })
      .catch((err) => {
        setActionlog({ Data: [], IsLoading: true });
      });
  }

  function taskBind() {
    setTaskList({
      Data: [],
      IsLoading: true,
    });
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(
        `${AppConfigData.APIBasePath}v1/task/getprojectdetailstasklist`,
        {
          title: null,
          status: null,
          priority: null,
          startDate: null,
          endDate: null,
          projectID: parseInt(param.id),
          searchText: TaskSearchText,
        },

        axiosConfig
      )
      .then((res) => {
        if (res.data.status_code === 0) {
          setTaskList({ Data: [], IsLoading: false });
        } else if (res.data.status_code === 1) {
          const groups = res.data.data.taskList.reduce((groups, task) => {
            const date = task.DateRang;
            if (!groups[date]) {
              groups[date] = {
                Date: date,
                tasklist: [],
                TotalTimeInMinites: 0,
              };
            }
            task.TaskUserList = res.data.data.taskUserList.filter(
              (U) => U.TaskID == task.TaskID
            );
            task.RunningTaskList = res.data.data.runningTaskList.filter(
              (R) => R.TaskID == task.TaskID && R.TimerDate == task.DateRang
            );

            groups[date].tasklist.push(task);
            groups[date].TotalTimeInMinites =
              groups[date].TotalTimeInMinites + task.TotalTimeInMinites;
            return groups;
          }, {});

          setTaskList({ Data: groups, IsLoading: false });

          // const keyOne = 'Title';
          const keyTwo = "TaskID";
          const arrayUniqueByKey = [
            ...new Map(
              res.data.data.taskList.map((item) => [
                // item[keyOne],
                item[keyTwo],
                item,
              ])
            ).values(),
          ];
          setTaskListNew(arrayUniqueByKey);
          setTaskFile(res.data.data.taskFiles);
          actionlogBind(new Date(), new Date());
        } else if (res.data.status_code === 2) {
          commonservices.ClearLoginDetails();
          navigate("/");
        }
      })
      .catch((err) => {
        setTaskList({ Data: [], IsLoading: true });
      });
  }

  function vaultBind() {
    setProjectVaultData({
      Data: [],
      IsLoading: true,
    });
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(
        `${AppConfigData.APIBasePath}v1/passwordmanagment/getprojectlist`,
        {
          ID: parseInt(param.id),
          searchText: VaultSearchText,
        },
        axiosConfig
      )
      .then((res) => {
        if (res.data.status_code === 0) {
          setProjectVaultData({ Data: [], IsLoading: false });
        } else if (res.data.status_code === 1) {
          setProjectVaultData({ Data: res.data.data, IsLoading: false });
          actionlogBind(new Date(), new Date());
        } else if (res.data.status_code === 2) {
          commonservices.ClearLoginDetails();
          navigate("/");
        } else {
        }
      })
      .catch((err) => {
        setProjectVaultData({ Data: [], IsLoading: false });
      });
  }

  function DocBind() {
    setProjectDocs({ Data: [], IsLoading: true });
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(
        `${AppConfigData.APIBasePath}v1/documents/getlist`,
        {
          associationID: parseInt(param.id),
          associationType: "Project",
          docName: null,
          docType: null,
          searchText: FileSearchText,
        },
        axiosConfig
      )
      .then((res) => {
        if (res.data.status_code === 0) {
          setProjectDocs({ Data: [], IsLoading: false });
        } else if (res.data.status_code === 1) {
          actionlogBind(new Date(), new Date());
          setProjectDocs({ Data: res.data.data, IsLoading: false });
        } else if (res.data.status_code === 2) {
        } else {
          setProjectDocs({ Data: [], IsLoading: false });
        }
      })
      .catch((err) => {});
  }

  function fnOpenSharePopup(item) {
    setProjectShare(item);
    setModalShareShow(true);
  }

  function fnOpenVaultPasswordPopup(item) {
    setProjectPassowrdSeeData(item);
    setModalVaultPasswordShow(true);
  }

  const showDetailsHandle = (dayStr) => {
    actionlogBind(dayStr, dayStr);
  };

  function BindAllData() {
    setProjectID(param.id);
    taskBind();
    //let divData = document.getElementById("DetailsPageMainTab")
    //let ActiveTab = divData.querySelectorAll('[aria-selected="true"]')[0].getAttribute("data-rr-ui-event-key");
    setOpenTab("general");

    bindList();
    getStatus();
    getClient();
    actionlogBind(new Date(), new Date());
  }

  useEffect(() => {
    if (ProjectID !== param.id) {
      BindAllData();
    }
  });

  // For Datatable
  $(document).ready(function () {
    $("table#tblData").DataTable({
      destroy: true,
      searching: false,
      dom: "ft<'tbl-info'<'length-info'li<'paggination'p>>>",
      aoColumnDefs: [
        {
          bSortable: false,
          aTargets: [-1, -3] /* 1st one, start by the right */,
        },
      ],
    });
  });
  useEffect(() => {
    $(document).ready(function () {
      $("table#tblData")
        .DataTable({
          destroy: true,
          searching: false,
          dom: "ft<'tbl-info'<'length-info'li<'paggination'p>>>",
          aoColumnDefs: [
            {
              bSortable: false,
              aTargets: [-1, -3],
            },
          ],
        })
        .destroy();
    });
  }, []);

  function fnCreateTaskButtonAndTimeUI(task) {
    if (task.IsAssign > 0) {
      if (task.RunningCount > 0) {
        return (
          <>
            <div className="task-time text-nowrap me-3">
              {commonservices.getTimeInFormat(
                task.RunningTaskList[0].StartTime
              )}
            </div>
            <button
              type="button"
              className="pause-play-button"
              title={"Stop Timer"}
              onClick={(e) => {
                confirmAlert({
                  message: commonservices.LabelDisplayText(
                    "AreYouSureWantToStopTask"
                  ),
                  buttons: [
                    {
                      label: commonservices.LabelDisplayText("Yes"),
                      onClick: function () {
                        let input = {
                          taskID: task.TaskID,
                          projectID: task.ProjectID,
                          stopTaskComment: "",
                        };
                        const axiosConfig = {
                          headers: {
                            Accept: "application/json",
                            Authorization:
                              "Bearer " + localStorage.getItem("token"),
                          },
                        };
                        axios
                          .post(
                            `${AppConfigData.APIBasePath}v1/task/taskstoptimer`,
                            input,
                            axiosConfig
                          )
                          .then((res) => {
                            if (res.data.status_code === 0) {
                              toast.error(res.data.message);
                            } else if (res.data.status_code === 1) {
                              toast.success(res.data.message);
                              fnCallTaskStartStopInHub();
                              taskBind();
                            } else if (res.data.status_code === 2) {
                              commonservices.ClearLoginDetails();
                              navigate("/");
                            }
                          })
                          .catch((err) => {});
                      },
                    },
                    {
                      label: commonservices.LabelDisplayText("No"),
                    },
                  ],
                });
              }}
            >
              <i className="fa-regular fa-pause"></i>
            </button>
          </>
        );
      } else {
        if (task.IsTaskRunningByMe === 0) {
          return (
            <>
              <div className="task-time text-nowrap me-3">
                {task.TotalTimeInMinites > 0 &&
                  commonservices.ConvertMinutesToTimeFormat(
                    task.TotalTimeInMinites
                  ) + " hrs"}
              </div>
              <button
                type="button"
                className="pause-play-button"
                title={"Start Timer"}
                onClick={(e) => {
                  confirmAlert({
                    message: commonservices.LabelDisplayText(
                      "AreYouSureWantToStartTask"
                    ),
                    buttons: [
                      {
                        label: commonservices.LabelDisplayText("Yes"),
                        onClick: function () {
                          let input = {
                            taskID: task.TaskID,
                            projectID: task.ProjectID,
                            stopTaskComment: "",
                          };
                          const axiosConfig = {
                            headers: {
                              Accept: "application/json",
                              Authorization:
                                "Bearer " + localStorage.getItem("token"),
                            },
                          };
                          axios
                            .post(
                              `${AppConfigData.APIBasePath}v1/task/taskstarttimer`,
                              input,
                              axiosConfig
                            )
                            .then((res) => {
                              if (res.data.status_code === 0) {
                                toast.error(res.data.message);
                              } else if (res.data.status_code === 1) {
                                toast.success(res.data.message);
                                fnCallTaskStartStopInHub();
                                taskBind();
                              } else if (res.data.status_code === 2) {
                                commonservices.ClearLoginDetails();
                                navigate("/");
                              }
                            })
                            .catch((err) => {});
                        },
                      },
                      {
                        label: commonservices.LabelDisplayText("No"),
                      },
                    ],
                  });
                }}
              >
                <i className="fa-regular fa-play"></i>
              </button>
            </>
          );
        }
      }
    } else {
      if (task.RunningCount > 0) {
        if (task.RunningCount == 1 && task.RunningTaskList.length == 1) {
          return (
            <>
              <div className="task-time text-nowrap me-3">
                {commonservices.getTimeInFormat(
                  task.RunningTaskList[0].StartTime
                )}
              </div>
              <button
                type="button"
                className="pause-play-button"
                title={"Running By " + task.RunningTaskList[0].DisplayName + ""}
              >
                <i className="fa-regular fa-timer"></i>
              </button>
            </>
          );
        } else {
          return (
            <>
              <OverlayTrigger
                trigger="click"
                key={"bottom"}
                placement={"bottom"}
                overlay={
                  <Popover id={`popover-positioned-bottom`}>
                    <Popover.Body>
                      {task.RunningTaskList.map((item, index) => (
                        <div
                          className="task-time-status d-flex align-items-center justify-content-end"
                          key={index}
                        >
                          <img
                            className="project-img me-2"
                            // src={item.ProfilePic}
                            src={
                              item.ProfilePic !==
                                null &&
                              item.ProfilePic !==
                                ""
                                ? item.ProfilePic
                                : UserPlaceholder
                            }
                            alt={commonservices.getInitials(item.DisplayName)}
                            title={item.DisplayName}
                            width={30}
                            height={30}
                          />
                          <div className="task-time text-nowrap me-3">
                            {commonservices.getTimeInFormat(item.StartTime)}
                          </div>
                        </div>
                      ))}
                    </Popover.Body>
                  </Popover>
                }
              >
                <button
                  type="button"
                  className="pause-play-button"
                  title={"Running By " + task.RunningCount + " User"}
                >
                  <i className="fa-regular fa-timer"></i>
                </button>
              </OverlayTrigger>
              <div className="task-time text-nowrap me-3">
                {task.RunningCount} user
              </div>
            </>
          );
        }
      } else {
        if (task.TotalTimeInMinites > 0) {
          return (
            <>
              <div className="task-time text-nowrap me-3">
                {commonservices.ConvertMinutesToTimeFormat(
                  task.TotalTimeInMinites
                )}{" "}
                hrs
              </div>
            </>
          );
        }
      }
    }
  }

  function fnCallTaskStartStopInHub() {
    let userdata = commonservices.getLoginUserData();
    const connect = new HubConnectionBuilder()
      .withUrl(AppConfigData.SignalRHubPath, { withCredentials: false })
      .withAutomaticReconnect()
      .build();
    connect
      .start()
      .then(() => {
        connect.send("TaskStartStop", { UserID: userdata[0].UserID });
      })
      .catch((error) => {});
  }

  function onDragOver(e) {
    e.preventDefault();
  }

  function onDragStart(e, id) {
    e.dataTransfer.setData("id", id);
  }

  function onDrop(e, cat) {
    let id = e.dataTransfer.getData("id");

    taskListNew.filter((task) => {
      if (task.TaskID === parseInt(id) && task.Status !== cat) {
        task.Status = cat;

        const axiosConfig = {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        };
        axios
          .post(
            `${AppConfigData.APIBasePath}v1/task/taskupdatefield`,
            {
              taskID: parseInt(id),
              projectID: parseInt(param.id),
              fieldName: "Status",
              fieldValue: cat,
            },
            axiosConfig
          )
          .then((res) => {
            if (res.data.status_code === 1) {
              toast.success(res.data.message);
              taskBind();
            } else if (res.data.status_code === 0) {
              toast.error(res.data.message);
            } else if (res.data.status_code === 2) {
              commonservices.ClearLoginDetails();
              navigate("/");
            }
          })
          .catch((err) => {});
      }
      return task;
    });
  }

  const tasks = {
    NotStarted: [],
    InProcess: [],
    InTesting: [],
    Closed: [],
    OnHold: [],
  };
  taskListNew.length > 0 &&
    taskListNew.forEach((t) => {
      tasks[t.Status.replace(" ", "")].push(
        <div
          key={t.name}
          onDragStart={(e) => onDragStart(e, t.TaskID)}
          draggable
          className="draggable-box"
        >
          <div className="d-flex justify-content-between draggable-box-top">
            <p className="draggable-box-project-tag">
              {/* Create UI design for Profile in OMS Dashboard */}
            </p>
            <div className="priority-icons">
              <img
                src={commonservices.GetPriorityIcon(t.Priority)}
                alt={t.Priority}
                title={t.Priority}
              />
            </div>
          </div>
          <div className="draggable-box-board-title">{t.Title}</div>
          <div className="draggable-box-description">{t.Details}</div>
          <div className="draggable-box-img">
            {taskFile.length > 0 &&
              taskFile
                .filter((D) => D.AssociationID == t.TaskID)
                .map((docItem, docIndex) =>
                  docItem.FileType === "IMAGE" ? (
                    <>
                      <img
                        src={docItem.FilePath}
                        alt=""
                        width={30}
                        height={90}
                      />
                    </>
                  ) : (
                    <>
                      <img
                        src={
                          AppConfigData.FileTypePath[
                            docItem.FileType.toUpperCase()
                          ]
                        }
                        alt=""
                        width={30}
                        height={90}
                      />
                    </>
                  )
                )}

            {/* <img src={taskFile?.FilePath} alt="" width={30} height={90} /> */}
          </div>
          <div className="d-flex justify-content-between draggable-box-bottom">
            <div className="users-imgs-col">
              <div className="users-imgs">
                {t.TaskUserList.map(
                  (useritem, userindex) =>
                    userindex < 3 && (
                      <img
                        title={useritem.DisplayName}
                        className="user-img"
                        // src={useritem.ProfilePic}
                        src={
                          useritem.ProfilePic !==
                            null &&
                          useritem.ProfilePic !==
                            ""
                            ? useritem.ProfilePic
                            : UserPlaceholder
                        }
                        alt={commonservices.getInitials(useritem.DisplayName)}
                        width={30}
                        height={30}
                        key={userindex}
                      />
                    )
                )}
                {t.TaskUserList.length > 3 && (
                  <div className="add-user ">
                    <span>{t.TaskUserList.length - 3}+</span>
                  </div>
                )}
              </div>
            </div>
            <div className="day-and-date">
              <TimeQuarter className="me-2" />
              {Math.round(
                Math.abs(
                  (new Date(t.EndDate) - new Date(t.StartDate)) / 86400000
                )
              )}{" "}
              day left
            </div>
          </div>
        </div>
      );
    });

  return (
    <>
      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey="general"
        activeKey={OpenTab}
      >
        <Container fluid className="p-0">
          <div className="table-section-col">
            <Row>
              <Col xl={3} lg={6} md={6} className="headadminmain">
                <div className="project-cards projects-cards pro-details-left-header">
                  <div className="card border-0">
                    <div className="d-flex">
                      <img
                        className="card-img-center rounded-circle"
                        width="80"
                        height="80"
                        // src={projectDetails.Data.ProjectLogo}
                        src={
                          projectDetails.Data.ProjectLogo !==
                            null &&
                          projectDetails.Data.ProjectLogo !==
                            ""
                            ? projectDetails.Data.ProjectLogo
                            : ProjectPlaceholder
                        }
                        alt={commonservices.getInitials(
                          projectDetails.Data.ProjectName
                        )}
                      />
                      <div className="d-flex flex-column justify-content-center">
                        <div className="d-flex align-content-center  align-items-center">
                          <Card.Title>
                            {projectDetails.Data.ProjectName}
                          </Card.Title>
                          <div className="project-cards-labels project-details-labels mt-0">
                            <label className="ms-2 m-0">
                              {projectDetails.Data.IsFixCost === 0 ? (
                                <>
                                  {commonservices.LabelDisplayText("Dedicated")}
                                </>
                              ) : (
                                <>
                                  {commonservices.LabelDisplayText("FixCost")}
                                </>
                              )}
                            </label>
                          </div>
                        </div>
                        <div className="project-cards-labels">
                          {projectDetails.Data.Technologies &&
                            projectDetails.Data.Technologies.split(",").map(
                              (item, index) =>
                                item && <label key={index}>{item}</label>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col
                xl={5}
                lg={12}
                md={12}
                className="text-center mt-auto mb-auto headmanutxt"
              >
                <Nav
                  variant="pills custom-tabs custom-tabs-grey d-inline-flex"
                  id={"DetailsPageMainTab"}
                >
                  <Nav.Item>
                    <Nav.Link
                      eventKey="general"
                      onClick={(e) => setOpenTab("general")}
                    >
                      General
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="board"
                      onClick={(e) => setOpenTab("board")}
                    >
                      Board
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="task"
                      onClick={(e) => setOpenTab("task")}
                    >
                      Task
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="files"
                      onClick={(e) => setOpenTab("files")}
                    >
                      Files
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="vault"
                      onClick={(e) => setOpenTab("vault")}
                    >
                      Vault
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="settings"
                      onClick={(e) => setOpenTab("settings")}
                    >
                      Settings
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col
                xl={4}
                lg={6}
                md={6}
                className="mt-auto mb-auto headlastserch"
              >
                <div className="d-flex align-items-center">
                  <div className="inner-search-bar inner-search-grey w-100">
                    {
                      // (OpenTab === "board" || OpenTab === "task") &&
                      OpenTab === "task" && (
                        <>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={commonservices.LabelDisplayText(
                              "SearchAnything"
                            )}
                            name="searchData"
                            onChange={(e) => setTaskSearchText(e.target.value)}
                            onKeyUp={(e) => e.keyCode === 13 && taskBind()}
                          />

                          <Button
                            variant="btn btn-icon-btn-lg search-button"
                            onClick={(e) => taskBind()}
                            title="Search"
                          >
                            <i className="fa-light fa-magnifying-glass"></i>
                          </Button>
                        </>
                      )
                    }
                    {OpenTab === "vault" && (
                      <>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={commonservices.LabelDisplayText(
                            "SearchAnything"
                          )}
                          name="searchData"
                          onChange={(e) => setVaultSearchText(e.target.value)}
                          onKeyUp={(e) => e.keyCode === 13 && vaultBind()}
                        />

                        <Button
                          variant="btn btn-icon-btn-lg search-button"
                          onClick={(e) => vaultBind()}
                          title="Search"
                        >
                          <i className="fa-light fa-magnifying-glass"></i>
                        </Button>
                      </>
                    )}
                    {OpenTab === "files" && (
                      <>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={commonservices.LabelDisplayText(
                            "SearchAnything"
                          )}
                          name="searchData"
                          onChange={(e) => setFileSearchText(e.target.value)}
                          onKeyUp={(e) => e.keyCode === 13 && DocBind()}
                        />

                        <Button
                          variant="btn btn-icon-btn-lg search-button"
                          onClick={(e) => DocBind()}
                          title="Search"
                        >
                          <i className="fa-light fa-magnifying-glass"></i>
                        </Button>
                      </>
                    )}
                  </div>

                  <div className="project-add-icon">
                    {commonservices.getLoginRoleData("Project", "IsUpdate") &&
                      OpenTab === "general" && <></>}
                    {commonservices.getLoginRoleData("Task", "IsCreate") &&
                      OpenTab === "task" && (
                        <Button
                          className="project-btn"
                          title="Add"
                          onClick={(e) => setModalTaskAdd(true)}
                        >
                          <i className="fa-regular fa-plus"></i>
                        </Button>
                      )}
                    {commonservices.getLoginRoleData("Project", "IsUpdate") &&
                      OpenTab === "files" && (
                        <div className="project-add-icon open-file project-btn btn btn-primary">
                          <i className="fa-regular fa-plus"></i>
                          <input
                            type="file"
                            id="file"
                            name="file"
                            onChange={uploadHandler}
                          />
                        </div>
                      )}
                    {commonservices.getLoginRoleData("Project", "IsUpdate") &&
                      OpenTab === "vault" && (
                        <Button
                          className="project-btn"
                          title="Add"
                          onClick={(e) => setModalVaultShow(true)}
                        >
                          <i className="fa-regular fa-plus"></i>
                        </Button>
                      )}
                    {commonservices.getLoginRoleData("Project", "IsUpdate") &&
                      OpenTab === "settings" && <></>}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <Container className="ps-0 pe-0 ">
          <Tab.Content className="pro-details-tab-content">
            <Tab.Pane eventKey="general">
              <div className="general-tab">
                <Row>
                  <Col lg="8">
                    {projectCount.IsLoading ? (
                      <>
                        <Loader type={LoaderType.PROJECTCOUNT} count={4} />
                      </>
                    ) : (
                      <>
                        {projectCount.Data.length > 0 &&
                          projectCount.Data.map((item, index) => (
                            <>
                              <div className="cards" key={index}>
                                <Row>
                                  <Col lg={4} md={4}>
                                    <Card className="today-task blue-crayola square-bg-img ">
                                      <Card.Body>
                                        <Card.Title className="ms-auto me-0">
                                          {item.RunningTask}
                                        </Card.Title>
                                        <Card.Text>
                                          {commonservices.LabelDisplayText(
                                            "Task"
                                          )}
                                          <span className="d-block">
                                            {commonservices.LabelDisplayText(
                                              "InProgress"
                                            )}
                                          </span>
                                        </Card.Text>
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                  <Col lg={4} md={4}>
                                    <Card className="profile-complete pastel-orange hexagon-bg-img ">
                                      <Card.Body>
                                        <Card.Title className="ms-auto me-0">
                                          {item.CompletedTask}
                                        </Card.Title>
                                        <Card.Text>
                                          {commonservices.LabelDisplayText(
                                            "Completed"
                                          )}
                                          <span className="d-block">
                                            {commonservices.LabelDisplayText(
                                              "Tasks"
                                            )}
                                          </span>
                                        </Card.Text>
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                  <Col lg={4} md={4}>
                                    <Card className="balance-leave h-100">
                                      <Card.Body className="p-0 d-flex justify-between flex-column">
                                        <div>
                                          <div className="d-flex align-items-center justify-content-between card-header-top">
                                            <div className="project-title me-3">
                                              {commonservices.LabelDisplayText(
                                                "TeamMembers"
                                              )}
                                            </div>
                                            <div className="project-add-icon">
                                              {commonservices.getLoginRoleData(
                                                "Project",
                                                "IsUpdate"
                                              ) && (
                                                <button
                                                  type="button"
                                                  title="Add"
                                                  className="project-btn btn btn-primary"
                                                  onClick={(e) =>
                                                    fnOpenEmployeeAddEditPopup(
                                                      null
                                                    )
                                                  }
                                                >
                                                  <i className="fa-regular fa-plus"></i>
                                                </button>
                                              )}
                                            </div>
                                          </div>
                                          <div className="project-details">
                                            <div className="users">
                                              {memberslist.Data.length > 0 &&
                                                memberslist.Data.map(
                                                  (item, index) =>
                                                    index < 5 && (
                                                      <div className="form-floating relative ">
                                                        <OverlayTrigger
                                                          overlay={
                                                            <Tooltip>
                                                              {item.DisplayName}
                                                            </Tooltip>
                                                          }
                                                        >
                                                          {commonservices.getLoginRoleData(
                                                            "Project",
                                                            "IsUpdate"
                                                          ) ? (
                                                            <img
                                                              className="project-img mt-0 mb-0 rounded-circle cursorpointer"
                                                              src={
                                                                item.ProfilePic !==
                                                                  null &&
                                                                item.ProfilePic !==
                                                                  ""
                                                                  ? item.ProfilePic
                                                                  : UserPlaceholder
                                                              }
                                                              alt={commonservices.getInitials(
                                                                item.DisplayName
                                                              )}
                                                              width={44}
                                                              height={44}
                                                              onClick={(e) =>
                                                                fnOpenEmployeeAddEditPopup(
                                                                  item
                                                                )
                                                              }
                                                            />
                                                          ) : (
                                                            <img
                                                              className="project-img mt-0 mb-0 rounded-circle"
                                                              src={
                                                                item.ProfilePic !==
                                                                  null &&
                                                                item.ProfilePic !==
                                                                  ""
                                                                  ? item.ProfilePic
                                                                  : UserPlaceholder
                                                              }
                                                              alt={commonservices.getInitials(
                                                                item.DisplayName
                                                              )}
                                                              width={44}
                                                              height={44}
                                                            />
                                                          )}
                                                        </OverlayTrigger>
                                                        {commonservices.getLoginRoleData(
                                                          "Project",
                                                          "IsUpdate"
                                                        ) && (
                                                          <Button
                                                            variant="btn btn-close-subpoint-icon"
                                                            title="Close"
                                                            onClick={(e) => {
                                                              confirmAlert({
                                                                message:
                                                                  commonservices.LabelDisplayText(
                                                                    "AreYouSureToDoDelete"
                                                                  ),
                                                                buttons: [
                                                                  {
                                                                    label:
                                                                      commonservices.LabelDisplayText(
                                                                        "Yes"
                                                                      ),
                                                                    onClick:
                                                                      function () {
                                                                        deleteData(
                                                                          item.ProjectUserID
                                                                        );
                                                                      },
                                                                  },
                                                                  {
                                                                    label:
                                                                      commonservices.LabelDisplayText(
                                                                        "No"
                                                                      ),
                                                                  },
                                                                ],
                                                              });
                                                            }}
                                                          >
                                                            <i className="fa fa-close"></i>
                                                          </Button>
                                                        )}
                                                      </div>
                                                    )
                                                )}

                                              {memberslist.Data.length > 5 && (
                                                <div className="add-user task-list">
                                                  <span>
                                                    {memberslist.Data.length -
                                                      5}
                                                    +
                                                  </span>
                                                  <div className="all_user_at_hover position-absolute">
                                                    {memberslist.Data.length >
                                                      0 &&
                                                      memberslist.Data.map(
                                                        (item, index) => (
                                                          <div className="form-floating relative ">
                                                            <OverlayTrigger
                                                              overlay={
                                                                <Tooltip>
                                                                  {
                                                                    item.DisplayName
                                                                  }
                                                                </Tooltip>
                                                              }
                                                            >
                                                              {commonservices.getLoginRoleData(
                                                                "Project",
                                                                "IsUpdate"
                                                              ) ? (
                                                                <img
                                                                  className="project-img mt-0 mb-0 rounded-circle cursorpointer"
                                                                  src={
                                                                    item.ProfilePic !==
                                                                      null &&
                                                                    item.ProfilePic !==
                                                                      ""
                                                                      ? item.ProfilePic
                                                                      : UserPlaceholder
                                                                  }
                                                                  alt={commonservices.getInitials(
                                                                    item.DisplayName
                                                                  )}
                                                                  width={44}
                                                                  height={44}
                                                                  onClick={(
                                                                    e
                                                                  ) =>
                                                                    fnOpenEmployeeAddEditPopup(
                                                                      item
                                                                    )
                                                                  }
                                                                />
                                                              ) : (
                                                                <img
                                                                  className="project-img mt-0 mb-0 rounded-circle"
                                                                  src={
                                                                    item.ProfilePic !==
                                                                      null &&
                                                                    item.ProfilePic !==
                                                                      ""
                                                                      ? item.ProfilePic
                                                                      : UserPlaceholder
                                                                  }
                                                                  alt={commonservices.getInitials(
                                                                    item.DisplayName
                                                                  )}
                                                                  width={44}
                                                                  height={44}
                                                                />
                                                              )}
                                                            </OverlayTrigger>
                                                            {commonservices.getLoginRoleData(
                                                              "Project",
                                                              "IsUpdate"
                                                            ) && (
                                                              <Button
                                                                variant="btn btn-close-subpoint-icon"
                                                                title="Close"
                                                                onClick={(
                                                                  e
                                                                ) => {
                                                                  confirmAlert({
                                                                    message:
                                                                      commonservices.LabelDisplayText(
                                                                        "AreYouSureToDoDelete"
                                                                      ),
                                                                    buttons: [
                                                                      {
                                                                        label:
                                                                          commonservices.LabelDisplayText(
                                                                            "Yes"
                                                                          ),
                                                                        onClick:
                                                                          function () {
                                                                            deleteData(
                                                                              item.ProjectUserID
                                                                            );
                                                                          },
                                                                      },
                                                                      {
                                                                        label:
                                                                          commonservices.LabelDisplayText(
                                                                            "No"
                                                                          ),
                                                                      },
                                                                    ],
                                                                  });
                                                                }}
                                                              >
                                                                <i className="fa fa-close"></i>
                                                              </Button>
                                                            )}
                                                          </div>
                                                        )
                                                      )}
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="d-flex mt-auto">
                                          <Card.Title className="mb-0">
                                            {item.WorkingHours !== null &&
                                            item.WorkingHours !== "" &&
                                            item.WorkingHours !== undefined ? (
                                              <>{item.WorkingHours}</>
                                            ) : (
                                              <>0</>
                                            )}
                                          </Card.Title>
                                          <Card.Text>
                                            {commonservices.LabelDisplayText(
                                              "Working"
                                            )}
                                            <span className="d-block">
                                              {commonservices.LabelDisplayText(
                                                "Hours"
                                              )}
                                            </span>
                                          </Card.Text>
                                        </div>
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                </Row>
                              </div>
                            </>
                          ))}
                      </>
                    )}
                    <div className="pro-details-description table-section-col leave-request-col">
                      <div className="pro-details-des-header d-flex">
                        <div className="about-project-title me-3">
                          {commonservices.LabelDisplayText("AboutProject")}
                        </div>
                        <div className="project-cards-labels mt-auto mb-auto">
                          <label>
                            <span className="me-1">
                              {Math.round(
                                Math.abs(
                                  (new Date(projectDetails.Data.EndDate) -
                                    new Date(projectDetails.Data.StartDate)) /
                                    86400000
                                )
                              )}
                            </span>
                            {commonservices.LabelDisplayText("DaysLeft")}
                          </label>
                        </div>
                      </div>
                      {/* <div className="project-description">
                        {projectDetails.Data.ShortDetails}
                      </div> */}
                      <div
                                        className="project-description"
                                        dangerouslySetInnerHTML={{ __html: projectDetails.Data.ShortDetails?.replaceAll('\n','<br/>') }}
                                    ></div>
                      <div className="pro-details-description-bottom">
                        <span>
                          <CalendarIcon className="me-2" />{" "}
                          {commonservices.getDays(
                            commonservices.getDateInDDMMYYYY(
                              projectDetails.Data.StartDate
                            )
                          )}
                        </span>
                        <span>
                          <img
                            src={
                              AppConfigData.TaskIconPath[
                                projectDetails.Data.Status
                              ]
                            }
                            alt={projectDetails.Data.Status}
                            height={20}
                            width={20}
                            className="me-2"
                          />
                          {projectDetails.Data.Status}
                        </span>
                        <span>
                          <TimeQuarterPast className="me-2" />
                          {commonservices.getDays(
                            commonservices.getDateInDDMMYYYY(
                              projectDetails.Data.EndDate
                            )
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="table-col table-section-col leave-request-col">
                      <div className="d-flex align-items-center justify-content-between table-header-col">
                        <div className="project-title me-3">
                          {commonservices.LabelDisplayText("Story")}
                        </div>
                        <div className="project-add-icon">
                          {commonservices.getLoginRoleData(
                            "Project",
                            "IsUpdate"
                          ) && (
                            <button
                              type="button"
                              title="Add"
                              className="project-btn btn btn-primary"
                              onClick={() => fnOpenProjectAddEditPopup(null)}
                            >
                              <i className="fa-regular fa-plus"></i>
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="recent-task-section">
                        <div className="table-scroll">
                          <Table
                            responsive
                            className="table-inner-section mb-0 pb-0"
                          >
                            <tbody>
                              {storylist.Data.length > 0 &&
                                storylist.Data.map((item, index) => (
                                  <tr key={index}>
                                    <td className="text-nowrap padding-top-bottom">
                                      <div className="d-flex align-items-center">
                                        <img
                                          className="project-short-img rounded-circle"
                                          
                                          src={
                                            item.ProfilePic !==
                                              null &&
                                            item.ProfilePic !==
                                              ""
                                              ? item.ProfilePic
                                              : UserPlaceholder
                                          }
                                          alt={commonservices.getInitials(
                                            item.DisplayName
                                          )}
                                          width="50"
                                          height="50"
                                        />
                                        <div className="content-project-col">
                                          <div className="project-description">
                                            {item.Title}
                                          </div>
                                          <div className="project-name">
                                            {commonservices.getDays(
                                              commonservices.getDateInDDMMYYYY(
                                                item.StartDate
                                              )
                                            )}{" "}
                                            to{" "}
                                            {commonservices.getDays(
                                              commonservices.getDateInDDMMYYYY(
                                                item.EndDate
                                              )
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </td>

                                    <td className="text-center padding-top-bottom width-200px">
                                      <div className="d-flex justify-content-end ">
                                        <div
                                          className="status-icon ms-0 mt-auto mb-auto"
                                          title={item.Status}
                                        >
                                          {commonservices.GetTaskStatusIcon(
                                            item.Status
                                          )}
                                        </div>
                                        {commonservices.getLoginRoleData(
                                          "Project",
                                          "IsUpdate"
                                        ) && (
                                          <>
                                            <Button
                                              className="icon-btn me-1 h-auto"
                                              onClick={(e) =>
                                                fnOpenProjectAddEditPopup(item)
                                              }
                                              title="Edit"
                                            >
                                              <Pencil />
                                            </Button>
                                            <Button
                                              className="icon-btn h-auto"
                                              title="Delete"
                                              onClick={(e) => {
                                                confirmAlert({
                                                  message:
                                                    commonservices.LabelDisplayText(
                                                      "AreYouSureToDoDelete"
                                                    ),
                                                  buttons: [
                                                    {
                                                      label:
                                                        commonservices.LabelDisplayText(
                                                          "Yes"
                                                        ),
                                                      onClick: () =>
                                                        deleteStory(
                                                          item.ProjectStoryID
                                                        ),
                                                    },
                                                    {
                                                      label:
                                                        commonservices.LabelDisplayText(
                                                          "No"
                                                        ),
                                                    },
                                                  ],
                                                });
                                              }}
                                            >
                                              <DeleteIconNew />
                                            </Button>
                                          </>
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="table-section-col projectdetails-right-col">
                      <div className="calendar-col">
                        <Calendar showDetailsHandle={showDetailsHandle} />
                      </div>
                      {actionlog.IsLoading ? (
                        <>
                          <Loader type={LoaderType.Line} count={7} />
                        </>
                      ) : (
                        <>
                          <div className="activities-col scroll">
                            <div className="activities-title">
                              {commonservices.LabelDisplayText("Activities")}
                            </div>
                            <div className="timeline-items">
                              {actionlog.Data.length > 0 &&
                                actionlog.Data.map((item, index) => (
                                  <div
                                    className="timeline-item d-flex"
                                    key={index}
                                  >
                                    <div className="timeline-img">
                                      <img
                                        className="project-img mt-0 mb-0"
                                       
                                        src={
                                          item.ProfilePic !==
                                            null &&
                                          item.ProfilePic !==
                                            ""
                                            ? item.ProfilePic
                                            : UserPlaceholder
                                        }
                                        alt={commonservices.getInitials(
                                          item.DisplayName
                                        )}
                                        width={40}
                                        height={40}
                                      />
                                    </div>
                                    <div className="timeline-chat">
                                      <div className="d-flex align-content-center justify-content-between">
                                        <div className="chat-name">
                                          {item.DisplayName}
                                        </div>
                                        {/* <div className="time-day">Today, 10:15 AM</div> */}
                                      </div>
                                      <div className="timeline-commente-share d-flex align-content-center">
                                        <span>
                                          {commonservices.LabelDisplayText(
                                            "CommentedOn"
                                          )}
                                        </span>
                                        <div className="time-day ms-1">
                                          {commonservices.getDays(
                                            commonservices.getDateInDDMMYYYY(
                                              item.ActionOn
                                            )
                                          )}
                                        </div>
                                        {/* <span className="commente-name">
                                  Exito Fresh Market Project
                                </span> */}
                                      </div>
                                      <div className="timeline-commente">
                                        {item.ShortDetails}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="board">
              <div className="board-tab">
                <div className="board-items">
                  <div
                    className="board-item"
                    onDragOver={(e) => onDragOver(e)}
                    onDrop={(e) => onDrop(e, "Not Started")}
                  >
                    <div className="task-header not-started-col">
                      Not Started
                      <span className="task-number">
                        {tasks.NotStarted.length}
                      </span>
                    </div>
                    <div className="table-section-col">
                      <div className="board-hight">{tasks.NotStarted}</div>
                    </div>
                  </div>
                  <div
                    className="board-item"
                    onDragOver={(e) => onDragOver(e)}
                    onDrop={(e) => onDrop(e, "In Process")}
                  >
                    <div className="task-header in-process-col">
                      In Process
                      <span className="task-number">
                        {tasks.InProcess.length}
                      </span>
                    </div>
                    <div className="table-section-col">
                      <div className="board-hight">{tasks.InProcess}</div>
                    </div>
                  </div>
                  <div
                    className="board-item"
                    onDragOver={(e) => onDragOver(e)}
                    onDrop={(e) => onDrop(e, "On Hold")}
                  >
                    <div className="task-header onhold-col">
                      On Hold
                      <span className="task-number">{tasks.OnHold.length}</span>
                    </div>
                    <div className="table-section-col">
                      <div className="board-hight">{tasks.OnHold}</div>
                    </div>
                  </div>
                  <div
                    className="board-item"
                    onDragOver={(e) => onDragOver(e)}
                    onDrop={(e) => onDrop(e, "In Testing")}
                  >
                    <div className="task-header in-testing-col">
                      In Testing
                      <span className="task-number">
                        {tasks.InTesting.length}
                      </span>
                    </div>
                    <div className="table-section-col">
                      <div className="board-hight">{tasks.InTesting}</div>
                    </div>
                  </div>
                  <div
                    className="board-item"
                    onDragOver={(e) => onDragOver(e)}
                    onDrop={(e) => onDrop(e, "Closed")}
                  >
                    <div className="task-header closed-col">
                      Closed
                      <span className="task-number">{tasks.Closed.length}</span>
                    </div>
                    <div className="table-section-col">
                      <div className="board-hight">{tasks.Closed}</div>
                    </div>
                  </div>
                </div>
              </div>
            </Tab.Pane>

            <Tab.Pane eventKey="task">
              <div className="table-col task-table-tab">
                {taskList.IsLoading ? (
                  <Loader type={LoaderType.TASKLIST} />
                ) : taskList.Data.length === 0 ? (
                  <Loader type={LoaderType.NODATAFOUND} />
                ) : (
                  Object.keys(taskList.Data)
                    .sort()
                    .reverse()
                    .map((item, index) => (
                      <div className="recent-task-section" key={index}>
                        <div className="d-flex align-items-center justify-content-between table-header-col">
                          <div className="project-title me-3">
                            {commonservices.getDays(
                              commonservices.getDateInDDMMYYYY(
                                taskList.Data[item].Date
                              )
                            )}
                          </div>
                          <div className="total-time">
                            {commonservices.ConvertMinutesToTimeFormat(
                              taskList.Data[item].TotalTimeInMinites
                            )}{" "}
                            {commonservices.LabelDisplayText("Hrs")}
                          </div>
                        </div>
                        <div className="table-scroll table-section-col">
                          <Table
                            responsive
                            className="table-inner-section mb-0 pb-0"
                          >
                            <tbody>
                              {taskList.Data[item].tasklist.length > 0 &&
                                taskList.Data[item].tasklist.map(
                                  (itemTask, rowindex) => (
                                    <tr key={rowindex}>
                                      <td
                                        onClick={(e) => {
                                          setTaskDataForShowDetails({
                                            TaskID: itemTask.TaskID,
                                            ProjectID: itemTask.ProjectID,
                                          });
                                          setModalShowDetails(true);
                                        }}
                                        className="text-nowrap padding-top-bottom width-300px"
                                      >
                                        <div className="leave-reason project-description">
                                          {itemTask.Title}
                                        </div>
                                      </td>
                                      <td className="text-nowrap padding-top-bottom width-300px">
                                        <div className="project-details">
                                          <div className="d-flex align-items-center users">
                                            {itemTask.TaskUserList.map(
                                              (useritem, userindex) =>
                                                userindex < 3 && (
                                                  <img
                                                    title={useritem.DisplayName}
                                                    className="project-img"                                                   
                                                    src={
                                                      useritem.ProfilePic !==
                                                        null &&
                                                      useritem.ProfilePic !==
                                                        ""
                                                        ? useritem.ProfilePic
                                                        : UserPlaceholder
                                                    }
                                                    alt={commonservices.getInitials(
                                                      useritem.DisplayName
                                                    )}
                                                    width={34}
                                                    height={34}
                                                    key={userindex}
                                                  />
                                                )
                                            )}
                                            {itemTask.TaskUserList.length >
                                              3 && (
                                              <div className="add-user task-list">
                                                <span>
                                                  {itemTask.TaskUserList
                                                    .length - 3}
                                                  +
                                                </span>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </td>
                                      <td className="width-150px">
                                        <div className="table-text-common text-nowrap">
                                          <CalendarIcon />
                                          <span>
                                            {commonservices.getDateInDDMMYYYY(
                                              itemTask.StartDate
                                            )}
                                          </span>
                                        </div>
                                      </td>
                                      <td className="text-start width-150px">
                                        <div className="table-text-common text-nowrap">
                                          <TimeQuarterPast />
                                          <span>
                                            {commonservices.getDateInDDMMYYYY(
                                              itemTask.EndDate
                                            )}
                                          </span>
                                        </div>
                                      </td>
                                      <td className="text-end width-75px">
                                        <div>
                                          <img
                                            src={commonservices.GetPriorityIcon(
                                              itemTask.Priority
                                            )}
                                            alt={itemTask.Priority}
                                            title={itemTask.Priority}
                                          />
                                        </div>
                                      </td>
                                      <td className="text-center padding-top-bottom width-200px">
                                        <div className="d-flex justify-content-end align-items-end">
                                          <div className="task-time-status d-flex align-items-center justify-content-end">
                                            {fnCreateTaskButtonAndTimeUI(
                                              itemTask
                                            )}
                                          </div>
                                          <div
                                            className="status-icon me-0"
                                            title={itemTask.Status}
                                          >
                                            {commonservices.GetTaskStatusIcon(
                                              itemTask.Status
                                            )}
                                          </div>
                                          {commonservices.getLoginRoleData(
                                            "Task",
                                            "IsDelete"
                                          ) &&
                                            USERLIST[0].UserID ===
                                              itemTask.CreatedBy && (
                                              <Button
                                                className="icon-btn ms-2"
                                                title="Delete"
                                                onClick={(e) => {
                                                  confirmAlert({
                                                    message:
                                                      commonservices.LabelDisplayText(
                                                        "AreYouSureToDoDelete"
                                                      ),
                                                    buttons: [
                                                      {
                                                        label:
                                                          commonservices.LabelDisplayText(
                                                            "Yes"
                                                          ),
                                                        onClick: () =>
                                                          deleteTaskData(
                                                            itemTask.TaskID
                                                          ),
                                                      },
                                                      {
                                                        label:
                                                          commonservices.LabelDisplayText(
                                                            "No"
                                                          ),
                                                      },
                                                    ],
                                                  });
                                                }}
                                              >
                                                <DeleteIconNew />
                                              </Button>
                                            )}
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                )}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    ))
                )}
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="files">
              <div className="files-tab">
                {/* projectDocs */}
                {projectDocs.IsLoading ? (
                  <>
                    <Loader type={LoaderType.DOCFILE} count={3} />
                  </>
                ) : (
                  <div className="files-tab-items">
                    {IsFileUploadRunning && (
                      <Loader type={LoaderType.DOCUpload} />
                    )}
                    {projectDocs.Data.length > 0 &&
                      projectDocs.Data.map((item, index) => (
                        <div className="files-tab-item" key={index}>
                          <div className="files-tab-item-img">
                            {item.FileType === "IMAGE" ? (
                              <>
                                <div
                                  className="files-img-type mt-auto mb-auto abc"
                                  onClick={(e) =>
                                    viewFilePopupService.viewFilePopup({
                                      data: [item],
                                    })
                                  }
                                >
                                  <img
                                    className="files-img"
                                    src={item.FilePath}
                                    alt={item.DocName}
                                  />
                                </div>
                              </>
                            ) : (
                              <>
                                <div
                                  className="files-img-type mt-auto mb-auto"
                                  onClick={(e) =>
                                    viewFilePopupService.viewFilePopup({
                                      data: [item],
                                    })
                                  }
                                >
                                  <img
                                    src={
                                      AppConfigData.FileTypePath[
                                        item.FileType.toUpperCase()
                                      ]
                                    }
                                    alt={item.DocName}
                                    width={75}
                                    height={100}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                          <div className="files-tab-item-bottom">
                            <div className="files-tab-bottom-left">
                              <div className="files-tab-bottom-title">
                                {item.DocName}
                              </div>
                              <div className="files-bottom-size-data">
                                <div className="files-size-bottom">
                                  {item.SizeInMB} MB
                                </div>
                                <div className="files-upload-data-bottom">
                                  {commonservices.getDays(
                                    commonservices.getDateInDDMMYYYY(
                                      item.CreatedOn
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="files-tab-bottom-right">
                              {commonservices.getLoginRoleData(
                                "Project",
                                "IsUpdate"
                              ) && (
                                <button
                                  type="button"
                                  title="Delete"
                                  className="p-0 border-0 bg-transparent"
                                  onClick={(e) => {
                                    confirmAlert({
                                      message: commonservices.LabelDisplayText(
                                        "AreYouSureToDoDelete"
                                      ),
                                      buttons: [
                                        {
                                          label:
                                            commonservices.LabelDisplayText(
                                              "Yes"
                                            ),
                                          onClick: () =>
                                            deleteDoc(item.DocumentID),
                                        },
                                        {
                                          label:
                                            commonservices.LabelDisplayText(
                                              "No"
                                            ),
                                        },
                                      ],
                                    });
                                  }}
                                >
                                  <Trash />
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="vault">
              <>
                <div className="vault-tab">
                  {ProjectVaultData.IsLoading ? (
                    <>
                      <Loader type={LoaderType.Table} count={4} />
                    </>
                  ) : (
                    <div className="table-col leave-request-col">
                      <div className="recent-task-section">
                        <div className="table-scroll">
                          <Table
                            id="tblData"
                            className="table table-inner-section"
                          >
                            <thead>
                              <tr>
                                <th className="width-100px text-center">
                                  {commonservices.LabelDisplayText("SrNo")}
                                </th>
                                <th className="width-300px">
                                  <div className="username">
                                    {commonservices.LabelDisplayText(
                                      "UserName"
                                    )}
                                  </div>
                                </th>
                                <th className="width-300px">
                                  {commonservices.LabelDisplayText("Type")}
                                </th>
                                <th className="width-200px">
                                  {commonservices.LabelDisplayText("Password")}
                                </th>
                                
                                <th className="text-end width-100px">
                                  {commonservices.LabelDisplayText("Action")}
                                </th>
                              </tr>
                            </thead>
                            <tbody className="table-section-col">
                              {ProjectVaultData.Data.length > 0 &&
                                ProjectVaultData.Data.map((item, index) => (
                                  <tr key={index}>
                                    <td className="text-center padding-top-bottom width-100px">
                                      <div className="sr-no">{index + 1}</div>
                                    </td>
                                    <td className="text-nowrap padding-top-bottom width-300px">
                                      <div className="d-flex align-items-center">
                                        <img
                                          src={commonservices.GetVaultMailIcon(
                                            AppConfigData.MailTypePath[
                                              item.TypeTerm.toUpperCase()
                                            ]
                                          )}
                                          alt={item.DocName}
                                          width="20"
                                          height="20"
                                        />

                                        <div className="content-project-col ms-2">
                                          <div className="project-description username">
                                            {item.UserName}
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="width-300px padding-top-bottom">
                                      <div className="table-text-common">
                                        {item.TypeTerm}
                                      </div>
                                    </td>
                                    <td className="width-200px padding-top-bottom">
                                      <div className="d-flex">
                                        <div className="password-dots">
                                          <i className="fa-solid fa-period"></i>
                                          <i className="fa-solid fa-period"></i>
                                          <i className="fa-solid fa-period"></i>
                                          <i className="fa-solid fa-period"></i>
                                          <i className="fa-solid fa-period"></i>
                                          <i className="fa-solid fa-period"></i>
                                          <i className="fa-solid fa-period"></i>
                                          <i className="fa-solid fa-period"></i>
                                          <i className="fa-solid fa-period"></i>
                                          <i className="fa-solid fa-period"></i>
                                        </div>
                                        <div>
                                          {commonservices.getLoginRoleData(
                                            "Project",
                                            "IsUpdate"
                                          ) && (
                                            <>
                                              {item.CreatedBy === USERID && (
                                                <Button
                                                  type="button"
                                                  title="Show"
                                                  className="icon-btn ms-2 h-auto w-auto btn btn-primary"
                                                  onClick={() =>
                                                    fnOpenVaultPasswordPopup(
                                                      item
                                                    )
                                                  }
                                                >
                                                  <EyeshowPassword />
                                                </Button>
                                              )}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </td>
                                    
                                    <td className="text-end padding-top-bottom width-100px">
                                      {commonservices.getLoginRoleData(
                                        "Project",
                                        "IsUpdate"
                                      ) && (
                                        <>
                                          {item.CreatedBy === USERID && (
                                            <div className="d-flex justify-content-end">
                                              <Button
                                                className="icon-btn me-1 h-auto"
                                                onClick={() =>
                                                  fnOpenVaultAddEditPopup(item)
                                                }
                                                title="Edit"
                                              >
                                                <Pencil />
                                              </Button>
                                              <Button
                                                className="icon-btn me-1 h-auto"
                                                onClick={(e) =>
                                                  fnOpenSharePopup(item)
                                                }
                                                title="Share"
                                              >
                                                <Share />
                                              </Button>
                                              {commonservices.getLoginRoleData(
                                                "Project",
                                                "IsUpdate"
                                              ) && (
                                                <Button
                                                  className="icon-btn h-auto w-auto"
                                                  title="Delete"
                                                  onClick={(e) => {
                                                    confirmAlert({
                                                      message:
                                                        commonservices.LabelDisplayText(
                                                          "AreYouSureToDoDelete"
                                                        ),
                                                      buttons: [
                                                        {
                                                          label:
                                                            commonservices.LabelDisplayText(
                                                              "Yes"
                                                            ),
                                                          onClick: () =>
                                                            deleteVault(
                                                              item.PasswordManagmentID
                                                            ),
                                                        },
                                                        {
                                                          label:
                                                            commonservices.LabelDisplayText(
                                                              "No"
                                                            ),
                                                        },
                                                      ],
                                                    });
                                                  }}
                                                >
                                                  <Trash />
                                                </Button>
                                              )}
                                            </div>
                                          )}
                                        </>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            </Tab.Pane>
            <Tab.Pane eventKey="settings">
              <div className="setting-tab">
                <div className="table-section-col setting-from">
                  <div className="project-details-left-col">
                    <Card className="border-0 p-0 ps-0 pe-0">
                      <Card.Body className="p-0 pt-2 ps-0 pe-0">
                        <Formik
                          initialValues={formInitialSchema}
                          validationSchema={formValidation}
                          validateOnBlur={false}
                          onSubmit={async (values) => {
                            setSubmitting(true);
                            const formData = new FormData();
                            formData.append("ProjectID", values.ProjectID);
                            formData.append("ProjectName", values.ProjectName);
                            formData.append(
                              "ShortDetails",
                              values.ShortDetails
                            );
                            formData.append("ClientID", values.ClientID);
                            formData.append("StartDate", values.StartDate);
                            formData.append("EndDate", values.EndDate);
                            formData.append("Status", values.Status);
                            formData.append("IsFixCost", values.IsFixCost);
                            formData.append(
                              "ProjectLogoFile",
                              document.getElementsByName("ProjectLogoFile")[0]
                                .files[0]
                            );
                            formData.append(
                              "TotalCostAmt",
                              values.TotalCostAmt !== ""
                                ? values.TotalCostAmt
                                : 0
                            );
                            // console.log("TotalCostAmt ::", values.TotalCostAmt);
                            formData.append("NoOfUser", values.NoOfUser);
                            formData.append(
                              "OverCostAmt",
                              values.OverCostAmt !== "" ? values.OverCostAmt : 0
                            );
                            formData.append("Tag", values.Tag);
                            formData.append("CreatedBy", values.CreatedBy);

                            formData.append("CreatedOn", values.CreatedOn);

                            if (
                              values.ActualEndDate !== null &&
                              values.ActualEndDate !== ""
                            ) {
                              formData.append(
                                "ActualEndDate",
                                values.ActualEndDate
                              );
                            }

                            let Technologies = "";
                            for (let i = 0; i < selectedSkill.length; i++) {
                              Technologies =
                                Technologies + selectedSkill[i].value + ",";
                            }
                            formData.append("Technologies", Technologies);

                            const axiosConfig = {
                              headers: {
                                "content-type": "multipart/form-data",
                                Authorization:
                                  "Bearer " + localStorage.getItem("token"),
                              },
                            };
                            const response = await axios
                              .post(
                                `${AppConfigData.APIBasePath}v1/project/save`,
                                formData,
                                axiosConfig
                              )
                              .then((res) => {
                                setSubmitting(false);
                                if (res.data.status_code === 0) {
                                  toast.error(res.data.message);
                                } else if (res.data.status_code === 1) {
                                  toast.success(res.data.message);
                                  bindList();
                                } else if (res.data.status_code === 2) {
                                  toast.error(res.data.message);
                                } else {
                                  toast.error(res.data.message);
                                }
                                window.location.reload()
                              })
                              .catch((error) => {
                                setSubmitting(false);
                              });
                          }}
                        >
                          <Form>
                            <Row>
                              <Col
                                xl={4}
                                lg={4}
                                md={6}
                                className="from-bottom-margin"
                              >
                                <Field
                                  type="hidden"
                                  name="ProjectID"
                                  className="form-control"
                                  placeholder="ProjectID"
                                />
                                <div className="form-group">
                                  <div className="form-floating">
                                    <Field
                                      type="text"
                                      name="ProjectName"
                                      className="form-control"
                                      placeholder={commonservices.LabelDisplayText(
                                        "ProjectName"
                                      )}
                                      maxLength={167}
                                    />
                                    <label lbl-for="ProjectName">
                                      {commonservices.LabelDisplayText(
                                        "ProjectName"
                                      )}
                                    </label>
                                    <div className="text-danger-error">
                                      <ErrorMessage name="ProjectName" />
                                    </div>
                                  </div>
                                </div>
                              </Col>
                              <Col
                                xl={4}
                                lg={4}
                                md={6}
                                className="from-bottom-margin"
                              >
                                <div className="form-group">
                                  <div className="form-floating">
                                    <Field
                                      as="select"
                                      name="ClientID"
                                      className="form-control down-arrow-icon"
                                    >
                                      <option disabled value="">
                                        {commonservices.LabelDisplayText(
                                          "Select"
                                        )}
                                      </option>
                                      {projectItem.length > 0 &&
                                        projectItem.map((item, index) => (
                                          <option
                                            disabled
                                            key={index}
                                            value={item.ClientID}
                                          >
                                            {item.Name}
                                          </option>
                                        ))}
                                    </Field>
                                    <label lbl-for="ClientID">
                                      {commonservices.LabelDisplayText(
                                        "Client"
                                      )}
                                    </label>
                                    <div className="text-danger-error">
                                      <ErrorMessage name="ClientID" />
                                    </div>
                                  </div>
                                </div>
                              </Col>
                              <Col
                                xl={4}
                                lg={4}
                                md={6}
                                className="from-bottom-margin"
                              >
                                <div className="form-group">
                                  <div className="form-floating multipleselect-form-floating">
                                    <ReactMultiSelectCheckboxes
                                      width={"100%"}
                                      placeholderButtonLabel={"Select "}
                                      isMulti
                                      name="Technologies"
                                      onChange={fnSelectedOptionSkill}
                                      options={optionSkill}
                                      value={selectedSkill}
                                    />
                                    <label lbl-for="Technologies">
                                      {commonservices.LabelDisplayText(
                                        "Technologies"
                                      )}
                                    </label>
                                  </div>
                                </div>
                              </Col>
                              <Col
                                xl={4}
                                lg={4}
                                md={6}
                                className="from-bottom-margin"
                              >
                                <div className="form-group">
                                  <div className="form-floating">
                                    <Field
                                      type="date"
                                      name="StartDate"
                                      className="form-control calendar-icon"
                                    />
                                    <label lbl-for="StartDate">
                                      {commonservices.LabelDisplayText(
                                        "StartDate"
                                      )}
                                    </label>
                                    <div className="text-danger-error">
                                      <ErrorMessage name="StartDate" />
                                    </div>
                                  </div>
                                </div>
                              </Col>
                              <Col
                                xl={4}
                                lg={4}
                                md={6}
                                className="from-bottom-margin"
                              >
                                <div className="form-group">
                                  <div className="form-floating">
                                    <div className="form-control browse-file">
                                      <Field
                                        type="file"
                                        name="ProjectLogoFile"
                                        className="form-control"
                                      />
                                      <label lbl-for="ProjectLogoFile">
                                        {commonservices.LabelDisplayText(
                                          "ChooseProjectLogoFile"
                                        )}
                                      </label>
                                      <div className="text-danger-error">
                                        <ErrorMessage name="ProjectLogoFile" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                              <Col
                                xl={4}
                                lg={4}
                                md={6}
                                className="from-bottom-margin"
                              >
                                <div className="form-group">
                                  <div className="form-floating">
                                    <Field
                                      type="date"
                                      name="EndDate"
                                      className="form-control calendar-icon"
                                    />
                                    <label lbl-for="EndDate">
                                      {commonservices.LabelDisplayText(
                                        "EndDate"
                                      )}
                                    </label>
                                    <div className="text-danger-error">
                                      <ErrorMessage name="EndDate" />
                                    </div>
                                  </div>
                                </div>
                              </Col>
                              <Col
                                xl={4}
                                lg={4}
                                md={6}
                                className="from-bottom-margin"
                              >
                                <div className="form-group">
                                  <div className="form-floating">
                                    <Field
                                      as="select"
                                      name="Status"
                                      className="form-control down-arrow-icon"
                                    >
                                      <option value="">
                                        {commonservices.LabelDisplayText(
                                          "Select"
                                        )}
                                      </option>
                                      {projectStatus.length > 0 &&
                                        projectStatus.map(
                                          (item, index) =>
                                            item.Category ===
                                              "ProjectStatus" && (
                                              <option
                                                key={index}
                                                value={item.Term}
                                              >
                                                {item.Term}
                                              </option>
                                            )
                                        )}
                                    </Field>
                                    <label lbl-for="Status">
                                      {commonservices.LabelDisplayText(
                                        "Status"
                                      )}
                                    </label>
                                    <div className="text-danger-error">
                                      <ErrorMessage name="Status" />
                                    </div>
                                  </div>
                                </div>
                              </Col>
                              <Col
                                xl={4}
                                lg={4}
                                md={6}
                                className="from-bottom-margin"
                              >
                                <div className="form-group">
                                  <div className="form-floating">
                                    <Field
                                      type="text"
                                      name="TotalCostAmt"
                                      className="form-control"
                                      placeholder={commonservices.LabelDisplayText(
                                        "TotalCostAmt"
                                      )}
                                      maxLength={12}
                                    />
                                    <label lbl-for="TotalCostAmt">
                                      {commonservices.LabelDisplayText(
                                        "TotalCostAmt"
                                      )}
                                    </label>
                                    <div className="text-danger-error">
                                      <ErrorMessage name="TotalCostAmt" />
                                    </div>
                                  </div>
                                </div>
                              </Col>
                              <Col
                                xl={4}
                                lg={4}
                                md={6}
                                className="from-bottom-margin"
                              >
                                <div className="form-group">
                                  <div className="form-floating">
                                    <Field
                                      type="text"
                                      name="OverCostAmt"
                                      className="form-control"
                                      placeholder={commonservices.LabelDisplayText(
                                        "OverCostAmt"
                                      )}
                                      maxLength={12}
                                    />
                                    <label lbl-for="OverCostAmt">
                                      {commonservices.LabelDisplayText(
                                        "OverCostAmt"
                                      )}
                                    </label>
                                    <div className="text-danger-error">
                                      <ErrorMessage name="OverCostAmt" />
                                    </div>
                                  </div>
                                </div>
                              </Col>
                              <Col
                                xl={4}
                                lg={4}
                                md={6}
                                className="from-bottom-margin"
                              >
                                <div className="form-group">
                                  <div className="form-floating">
                                    <Field
                                      type="text"
                                      name="Tag"
                                      className="form-control"
                                      placeholder={commonservices.LabelDisplayText(
                                        "Tag"
                                      )}
                                      maxLength={267}
                                    />
                                    <label lbl-for="Tag">
                                      {commonservices.LabelDisplayText("Tag")}
                                    </label>
                                    <div className="text-danger-error">
                                      <ErrorMessage name="Tag" />
                                    </div>
                                  </div>
                                </div>
                              </Col>
                              <Col
                                xl={4}
                                lg={4}
                                md={6}
                                className="from-bottom-margin"
                              >
                                <div className="form-group">
                                  <div className="form-floating">
                                    <Field
                                      type="date"
                                      name="ActualEndDate"
                                      className="form-control calendar-icon"
                                    />
                                    <label lbl-for="ActualEndDate">
                                      {commonservices.LabelDisplayText(
                                        "ActualEndDate"
                                      )}
                                    </label>
                                    <div className="text-danger-error">
                                      <ErrorMessage name="ActualEndDate" />
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={8} className="from-bottom-margin">
                                <div className="form-group">
                                  <div className="form-floating">
                                    <Field
                                      as="textarea"
                                      name="ShortDetails"
                                      className="form-control"
                                      placeholder={commonservices.LabelDisplayText(
                                        "ShortDetails"
                                      )}
                                      maxLength={450}
                                    />
                                    <label lbl-for="ShortDetails">
                                      {commonservices.LabelDisplayText(
                                        "ShortDetails"
                                      )}
                                    </label>
                                    <div className="text-danger-error">
                                      <ErrorMessage name="ShortDetails" />
                                    </div>
                                  </div>
                                </div>
                              </Col>
                              <Col xs={4} className="from-bottom-margin">
                                <div className="form-group checkbox-class pt-2">
                                  <Field
                                    type="checkbox"
                                    name="IsFixCost"
                                    className="me-2"
                                    id="checkbox"
                                  />
                                  <label htmlFor="checkbox" lbl-for="IsFixCost">
                                    {commonservices.LabelDisplayText(
                                      "IsFixCost"
                                    )}
                                  </label>
                                  <div className="text-danger-error">
                                    <ErrorMessage name="IsFixCost" />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <div className="pt-3 row">
                              <Modal.Footer>
                                {commonservices.getLoginRoleData(
                                  "Project",
                                  "IsUpdate"
                                ) && (
                                  <button
                                    className="btn btn-primary"
                                    title="Save"
                                    type="submit"
                                    disabled={Submitting}
                                  >
                                    {Submitting && (
                                      <>
                                        <Spinner animation="border" />
                                      </>
                                    )}
                                    {commonservices.LabelDisplayText("Save")}
                                  </button>
                                )}
                                <button
                                  className="btn btn-secondary ms-2 me-0"
                                  type="button"
                                  title="Cancel"
                                >
                                  {commonservices.LabelDisplayText("Cancel")}
                                </button>
                              </Modal.Footer>
                            </div>
                          </Form>
                        </Formik>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Container>
      </Tab.Container>

      {modalShow && (
        <AddProjectUser
          id={ProjectUser}
          show={modalShow}
          bindList={memberBind}
          UserList={memberslist}
          onHide={() => setModalShow(false)}
        />
      )}

      {modalStoryShow && (
        <AddProjectStory
          id={ProjectStory}
          show={modalStoryShow}
          bindList={storyBind}
          onHide={() => setModalStoryShow(false)}
        />
      )}

      {modalVaultShow && (
        <AddVault
          id={ProjectVault}
          show={modalVaultShow}
          bindList={vaultBind}
          onHide={() => setModalVaultShow(false)}
        />
      )}

      {modalFilesShow && (
        <ModalFiles
          id={ProjectFiles}
          show={modalFilesShow}
          // bindList={bindList}
          onHide={() => setModalFilesShow(false)}
        />
      )}

      {modalShareShow && (
        <ModalShare
          id={ProjectShare}
          show={modalShareShow}
          // bindList={bindList}
          onHide={() => setModalShareShow(false)}
        />
      )}

      {modalVaultPasswordShow && (
        <ModalProjectShowPasswordVault
          id={ProjectPassowrdSeeData}
          show={modalVaultPasswordShow}
          // bindList={bindList}
          onHide={() => setModalVaultPasswordShow(false)}
        />
      )}
      {modalShowDetails && (
        <TaskDetail
          details={TaskDataForShowDetails}
          show={modalShowDetails}
          onHide={() => setModalShowDetails(false)}
          bindList={taskBind}
        />
      )}

      {modalTaskAdd && (
        <AddTaskModal
          // id={TASKID}
          projectid={parseInt(param.id)}
          show={modalTaskAdd}
          bindList={taskBind}
          onHide={() => setModalTaskAdd(false)}
        />
      )}
    </>
  );
}
export default ProjectDetails;
