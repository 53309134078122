import axios from "axios";
import { useState, useEffect } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { default as React } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import * as yup from "yup";
import { commonservices, AppConfigData } from "../Services/commonService";
import { confirmAlert } from "react-confirm-alert";
import {
  Breadcrumb,
  Button,
  Col,
  Container,
  OverlayTrigger,
  Row,
  Modal,
  Table,
  Tooltip,
  Tab,
  Tabs,
} from "react-bootstrap";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { viewFilePopupService } from "../Services/viewFilePopup.service";
import Spinner from "react-bootstrap/Spinner";
import { ReactComponent as Pencil } from "../assets/Icons/pencil.svg";
import { ReactComponent as Trash } from "../assets/Icons/trash.svg";
import { ReactComponent as EyeshowPassword } from "../assets/Icons/eyeshow-password.svg";
import $ from "jquery";

const AddEmployeeModal = (props) => {
  const [general, setgeneral] = useState("general");

  const [IsGetDetailsDone, setIsGetDetailsDone] = useState(false);
  const [NextError, setNextError] = useState(false);
  const [projectItem, setProjectItem] = useState([]);
  const [lstDocuments, setLstDocuments] = useState([]);
  const [lstPasswordManagment, setLstPasswordManagment] = useState([]);
  const [optionSkill, setoptionSkill] = useState([]);
  const [optionLang, setoptionLang] = useState([]);
  const [selectedSkill, setSelectedSkill] = useState([]);
  const [selectedLang, setSelectedLang] = useState([]);
  const [Submitting, setSubmitting] = useState(false);

  const [optionLeave, setoptionLeave] = useState([]);
  const [selectedLeave, setSelectedLeave] = useState([]);
  const [IsValidationMessageSet, setIsValidationMessageSet] = useState("");

  useEffect(() => {
    setIsValidationMessageSet(true);
  });
  const addFields = () => {
    let newField = {
      lstID: commonservices.createGuid(),
      docId: 0,
      docType: "",
      docName: "",
      details: "",
      file: "",
      DataItem: {},
    };
    setLstDocuments([...lstDocuments, newField]);
  };

  const removeFields = async (index, lstid, docId) => {
    if (docId === 0) {
      let data = [...lstDocuments];
      data.splice(index, 1);
      setLstDocuments(data);
    } else {
      const response = await commonservices.DeleteDocumentByID(docId, 0, "");
      if (response.data.status_code === 1) {
        let data = [...lstDocuments];
        data.splice(index, 1);
        setLstDocuments(data);
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    }
  };

  const addpassFields = () => {
    let newField = {
      lstID: commonservices.createGuid(),
      passId: 0,
      typeTerm: "",
      userName: "",
      password: "",
      details: "",
    };
    setLstPasswordManagment([...lstPasswordManagment, newField]);
  };

  const removepassFields = async (index, lstid, passId) => {
    if (passId === 0) {
      let data = [...lstDocuments];
      data.splice(index, 1);
      setLstPasswordManagment(data);
    } else {
      const response = await commonservices.DeletePasswordByID(passId);
      if (response.data.status_code === 1) {
        let data = [...lstPasswordManagment];
        data.splice(index, 1);
        setLstPasswordManagment(data);
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    }
  };

  const formFieldLength = {
    FirstName: 67,
    LastName: 67,
    MiddleName: 67,
    MobileNo: 17,
    EmplyeesNo: 17,
    EmailID: 167,
    PersonalEmailID: 167,
    OrignalDocDetails: 500,
    Address1: 500,
    Address2: 500,
    City: 67,
    State: 67,
    Country: 67,
    Contact1: 17,
    Contact1Name: 67,
    Contact1Type: 67,
    Contact2: 17,
    Contact2Name: 67,
    ShortProfile: 500,
    Contact2Type: 67,
    AdharNo: 67,
    PancardNo: 67,
    BankName: 67,
    BankBranch: 67,
    BankAccountNo: 67,
    BankIFSCCode: 67,
    BankAccountName: 167,
    BankAccountType: 67,
    PlaceOfBirth: 167,
    Disabilities: 250,
    ZipCode: 27,
  };

  const formInitialSchema = {
    EmployeeID: 0,
    FirstName: "",
    LastName: "",
    MiddleName: "",
    MobileNo: "",
    DOB: "",
    DateOfJoining: "",
    EmplyeesNo: "",
    ProfilePic: "",
    ProfilePicFile: "",
    Position: "",
    DepartmentID: "",
    SkillTag: "",
    DateOfLeaving: "",
    MaritalStatus: "",
    Gender: "",
    EmailID: "",
    PersonalEmailID: "",
    ShortProfile: "",
    OrignalDocDetails: "",
    Address1: "",
    Address2: "",
    City: "",
    State: "",
    Country: "",
    Contact1: "",
    Contact1Name: "",
    Contact1Type: "",
    Contact2: "",
    Contact2Name: "",
    Contact2Type: "",
    AdharNo: "",
    PancardNo: "",
    BankName: "",
    BankBranch: "",
    BankAccountNo: "",
    BankIFSCCode: "",
    BankAccountName: "",
    BankAccountType: "",
    PlaceOfBirth: "",
    ResidenceType: "",
    Disabilities: "",
    Languages: "",
    ZipCode: "",
  };
  $("input[type='file']").on("change", function () {
    if (this.files !== null && this.files.length !== 0) {
      $("[lbl-for='" + $(this).attr("name") + "']").text(this.files[0].name);
    } else {
      $("[lbl-for='" + $(this).attr("name") + "']").text(
        commonservices.LabelDisplayText("ChooseFile")
      );
    }
  });
  const formValidation = yup.object().shape({
    FirstName: yup
      .string()
      .required(commonservices.MakeValidationMessage("required", "FirstName"))
      .test(
        "len",
        commonservices.MakeValidationMessage("maxlength", ""),
        (value, obj) => {
          return value && value.length <= formFieldLength.FirstName;
        }
      ),
    LastName: yup
      .string()
      .required(commonservices.MakeValidationMessage("required", "LastName"))
      .test(
        "len",
        commonservices.MakeValidationMessage("maxlength", ""),
        (value, obj) => {
          return value && value.length <= formFieldLength.LastName;
        }
      ),
    MobileNo: yup
      .string()
      .required(commonservices.MakeValidationMessage("required", "MobileNo"))
      .test(
        "len",
        commonservices.MakeValidationMessage("maxlength", ""),
        (value, obj) => {
          return value && value.length <= formFieldLength.MobileNo;
        }
      ),
    PersonalEmailID: yup.string().email(),
    EmailID: yup
      .string()
      .email()
      .required(commonservices.MakeValidationMessage("required", "EmailID"))
      .test(
        "len",
        commonservices.MakeValidationMessage("maxlength", ""),
        (value, obj) => {
          return value && value.length <= formFieldLength.EmailID;
        }
      ),
    Gender: yup
      .string()
      .required(commonservices.MakeValidationMessage("required", "Gender")),
    EmplyeesNo: yup
      .string()
      .required(commonservices.MakeValidationMessage("required", "EmplyeesNo"))
      .test(
        "len",
        commonservices.MakeValidationMessage("maxlength", ""),
        (value, obj) => {
          return value && value.length <= formFieldLength.EmplyeesNo;
        }
      ),
    Position: yup
      .string()
      .required(commonservices.MakeValidationMessage("required", "Position")),
    DepartmentID: yup
      .string()
      .required(
        commonservices.MakeValidationMessage("required", "DepartmentID")
      ),
    AdharNo: yup
      .string()
      .required(commonservices.MakeValidationMessage("required", "AdharNo"))
      .test(
        "len",
        commonservices.MakeValidationMessage("maxlength", ""),
        (value, obj) => {
          return value && value.length <= formFieldLength.AdharNo;
        }
      ),
    PancardNo: yup
      .string()
      .required(commonservices.MakeValidationMessage("required", "PancardNo"))
      .test(
        "len",
        commonservices.MakeValidationMessage("maxlength", ""),
        (value, obj) => {
          return value && value.length <= formFieldLength.PancardNo;
        }
      ),
    MiddleName: yup
      .string()
      .required(commonservices.MakeValidationMessage("required", "MiddleName"))
      .test(
        "len",
        commonservices.MakeValidationMessage("maxlength", ""),
        (value, obj) => {
          return value ? value.length <= formFieldLength.MiddleName : true;
        }
      ),
    ProfilePicFile: yup
      .mixed()
      .test(
        "type",
        commonservices.MakeValidationMessage(
          "custom",
          "ProfilePicFile",
          commonservices.CustomValidationMessage("ValidationOnlyImage")
        ),
        (value, obj) => {
          if (
            document.getElementsByName("ProfilePicFile") !== null &&
            document.getElementsByName("ProfilePicFile") !== "" &&
            document.getElementsByName("ProfilePicFile") !== undefined &&
            document.getElementsByName("ProfilePicFile")[0].files.length > 0
          ) {
            return (
              document.getElementsByName("ProfilePicFile")[0].files[0].type ===
                "image/jpeg" ||
              document.getElementsByName("ProfilePicFile")[0].files[0].type ===
                "image/png"
            );
          } else {
            return true;
          }
        }
      ),
    DOB: yup
      .date()
      .max(
        new Date(),
        commonservices.MakeValidationMessage(
          "custom",
          "DOB",
          commonservices.CustomValidationMessage(
            "ValidationDateCanBeLessThanOrEqualTodayDate"
          )
        )
      ),
    DateOfLeaving: yup
      .date()
      .max(
        new Date(),
        commonservices.MakeValidationMessage(
          "custom",
          "DateOfLeaving",
          commonservices.CustomValidationMessage(
            "ValidationDateCanBeLessThanOrEqualTodayDate"
          )
        )
      ),
    DateOfJoining: yup
      .date()
      .required(
        commonservices.MakeValidationMessage("required", "DateOfJoining")
      )
      .max(
        new Date(),
        commonservices.MakeValidationMessage(
          "custom",
          "DateOfJoining",
          commonservices.CustomValidationMessage(
            "ValidationDateCanBeLessThanOrEqualTodayDate"
          )
        )
      ),
    OrignalDocDetails: yup
      .string()
      .test(
        "len",
        commonservices.MakeValidationMessage("maxlength", ""),
        (value, obj) => {
          return value
            ? value.length <= formFieldLength.OrignalDocDetails
            : true;
        }
      ),
    Address1: yup
      .string()
      .test(
        "len",
        commonservices.MakeValidationMessage("maxlength", ""),
        (value, obj) => {
          return value ? value.length <= formFieldLength.Address1 : true;
        }
      ),
    Address2: yup
      .string()
      .test(
        "len",
        commonservices.MakeValidationMessage("maxlength", ""),
        (value, obj) => {
          return value ? value.length <= formFieldLength.Address2 : true;
        }
      ),
    City: yup
      .string()
      .test(
        "len",
        commonservices.MakeValidationMessage("maxlength", ""),
        (value, obj) => {
          return value ? value.length <= formFieldLength.City : true;
        }
      ),
    State: yup
      .string()
      .test(
        "len",
        commonservices.MakeValidationMessage("maxlength", ""),
        (value, obj) => {
          return value ? value.length <= formFieldLength.State : true;
        }
      ),
    Country: yup
      .string()
      .test(
        "len",
        commonservices.MakeValidationMessage("maxlength", ""),
        (value, obj) => {
          return value ? value.length <= formFieldLength.Country : true;
        }
      ),
    Contact1: yup
      .string()
      .test(
        "len",
        commonservices.MakeValidationMessage("maxlength", ""),
        (value, obj) => {
          return value ? value.length <= formFieldLength.Contact1 : true;
        }
      ),
    Contact1Name: yup
      .string()
      .test(
        "len",
        commonservices.MakeValidationMessage("maxlength", ""),
        (value, obj) => {
          return value ? value.length <= formFieldLength.Contact1Name : true;
        }
      ),
    Contact1Type: yup
      .string()
      .test(
        "len",
        commonservices.MakeValidationMessage("maxlength", ""),
        (value, obj) => {
          return value ? value.length <= formFieldLength.Contact1Type : true;
        }
      ),
    Contact2: yup
      .string()
      .test(
        "len",
        commonservices.MakeValidationMessage("maxlength", ""),
        (value, obj) => {
          return value ? value.length <= formFieldLength.Contact2 : true;
        }
      ),
    Contact2Name: yup
      .string()
      .test(
        "len",
        commonservices.MakeValidationMessage("maxlength", ""),
        (value, obj) => {
          return value ? value.length <= formFieldLength.Contact2Name : true;
        }
      ),
    Contact2Type: yup
      .string()
      .test(
        "len",
        commonservices.MakeValidationMessage("maxlength", ""),
        (value, obj) => {
          return value ? value.length <= formFieldLength.Contact2Type : true;
        }
      ),
    BankName: yup
      .string()
      .test(
        "len",
        commonservices.MakeValidationMessage("maxlength", ""),
        (value, obj) => {
          return value ? value.length <= formFieldLength.BankName : true;
        }
      ),
    BankBranch: yup
      .string()
      .test(
        "len",
        commonservices.MakeValidationMessage("maxlength", ""),
        (value, obj) => {
          return value ? value.length <= formFieldLength.BankBranch : true;
        }
      ),
    BankAccountNo: yup
      .string()
      .test(
        "len",
        commonservices.MakeValidationMessage("maxlength", ""),
        (value, obj) => {
          return value ? value.length <= formFieldLength.BankAccountNo : true;
        }
      ),
    BankIFSCCode: yup
      .string()
      .test(
        "len",
        commonservices.MakeValidationMessage("maxlength", ""),
        (value, obj) => {
          return value ? value.length <= formFieldLength.BankIFSCCode : true;
        }
      ),
    BankAccountName: yup
      .string()
      .test(
        "len",
        commonservices.MakeValidationMessage("maxlength", ""),
        (value, obj) => {
          return value ? value.length <= formFieldLength.BankAccountName : true;
        }
      ),
    BankAccountType: yup
      .string()
      .test(
        "len",
        commonservices.MakeValidationMessage("maxlength", ""),
        (value, obj) => {
          return value ? value.length <= formFieldLength.BankAccountType : true;
        }
      ),
    PlaceOfBirth: yup
      .string()
      .test(
        "len",
        commonservices.MakeValidationMessage("maxlength", ""),
        (value, obj) => {
          return value ? value.length <= formFieldLength.PlaceOfBirth : true;
        }
      ),
    Disabilities: yup
      .string()
      .test(
        "len",
        commonservices.MakeValidationMessage("maxlength", ""),
        (value, obj) => {
          return value ? value.length <= formFieldLength.Disabilities : true;
        }
      ),
    ZipCode: yup
      .string()
      .test(
        "len",
        commonservices.MakeValidationMessage("maxlength", ""),
        (value, obj) => {
          return value ? value.length <= formFieldLength.ZipCode : true;
        }
      ),
  });

  function fnSelectedOptionSkill(value, event) {
    setSelectedSkill(value);
  }

  function fnSelectedOptionLang(value, event) {
    setSelectedLang(value);
  }

  function fnSelectedOptionLeave(value, event) {
    setSelectedLeave(value);
  }

  useEffect(() => {
    addFields();
    addpassFields();
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(
        `${AppConfigData.APIBasePath}v1/projectterm/getlist`,
        {
          term: "",
          category:
            "Department,Position,Documents,Technologies,MaritalStatus,Gender,ResidenceType,Documents,PasswordManagmenType,Languages",
        },
        axiosConfig
      )
      .then((res) => {
        if (res.data.status_code === 0) {
        } else if (res.data.status_code === 1) {
          setProjectItem(res.data.data);
          let optionSkill = [];
          res.data.data
            .filter((t) => t.Category === "Technologies")
            .forEach((element) => {
              optionSkill.push({
                label: element.Term,
                value: element.Term,
              });
            });
          setoptionSkill(optionSkill);

          let Langlst = [];
          res.data.data
            .filter((t) => t.Category === "Languages")
            .forEach((element) => {
              Langlst.push({
                label: element.Term,
                value: element.Term,
              });
            });
          setoptionLang(Langlst);
        } else if (res.data.status_code === 2) {
        } else {
        }
      })
      .catch((err) => {});

    axios
      .post(
        `${AppConfigData.APIBasePath}v1/leavemaster/getlist`,
        {
          leaveCode: "",
          leaveName: "",
          searchText: "",
        },
        axiosConfig
      )
      .then((res) => {
        if (res.data.status_code === 0) {
        } else if (res.data.status_code === 1) {
          let LeaveLst = [];
          res.data.data.forEach((element) => {
            LeaveLst.push({
              label: element.LeaveName,
              value: element.LeaveMasterID,
            });
          });
          setoptionLeave(LeaveLst);
        } else if (res.data.status_code === 2) {
        } else {
        }
      })
      .catch((err) => {});

    if (props.id > 0) {
      axios
        .post(
          `${AppConfigData.APIBasePath}v1/employees/getdetailsbyid`,
          {
            id: props.id,
            requestFrom: "Web",
          },
          axiosConfig
        )
        .then((res) => {
          if (res.data.status_code === 0) {
            toast.error(res.data.message);
          } else if (res.data.status_code === 1) {
            commonservices.CheckNullAndReturnBlankInObjectKey(
              res.data.data.employees
            );
            formInitialSchema.EmployeeID = res.data.data.employees.EmployeeID;
            formInitialSchema.FirstName = res.data.data.employees.FirstName;
            formInitialSchema.LastName = res.data.data.employees.LastName;
            formInitialSchema.MiddleName = res.data.data.employees.MiddleName;
            formInitialSchema.MobileNo = res.data.data.employees.MobileNo;
            formInitialSchema.DOB = commonservices.getDateInDBFormat(
              res.data.data.employees.DOB
            );
            formInitialSchema.DateOfJoining = commonservices.getDateInDBFormat(
              res.data.data.employees.DateOfJoining
            );
            formInitialSchema.EmplyeesNo = res.data.data.employees.EmplyeesNo;
            formInitialSchema.ProfilePicFile =
              res.data.data.employees.ProfilePicFile;
            formInitialSchema.ProfilePic = res.data.data.employees.ProfilePic;
            formInitialSchema.Position = res.data.data.employees.Position;
            formInitialSchema.DepartmentID =
              res.data.data.employees.DepartmentID;

            let _selectOption = [];
            if (
              res.data.data.employees.SkillTag !== null &&
              res.data.data.employees.SkillTag !== ""
            ) {
              let List = res.data.data.employees.SkillTag.split(",");
              List.forEach((element) => {
                if (
                  element !== null &&
                  element !== "" &&
                  element.trim() !== ""
                ) {
                  _selectOption.push({
                    value: element.trim(),
                    label: element.trim(),
                  });
                }
              });
            }
            setSelectedSkill(_selectOption);

            let _selectLang = [];
            if (
              res.data.data.employees.Languages !== null &&
              res.data.data.employees.Languages !== ""
            ) {
              let List = res.data.data.employees.Languages.split(",");
              List.forEach((element) => {
                if (
                  element !== null &&
                  element !== "" &&
                  element.trim() !== ""
                ) {
                  _selectLang.push({
                    value: element.trim(),
                    label: element.trim(),
                  });
                }
              });
            }
            setSelectedLang(_selectLang);
            if (
              res.data.data.leave != null &&
              res.data.data.leave.length != 0
            ) {
              let List = [];
              for (let i = 0; i < res.data.data.leave.length; i++) {
                List.push({
                  value: res.data.data.leave[i].LeaveMasterID,
                  label: res.data.data.leave[i].LeaveName,
                });
              }
              setSelectedLeave(List);
            }

            formInitialSchema.DateOfLeaving =
              res.data.data.employees.DateOfLeaving;
            formInitialSchema.MaritalStatus =
              res.data.data.employees.MaritalStatus;
            formInitialSchema.Gender = res.data.data.employees.Gender;
            formInitialSchema.EmailID = res.data.data.employees.EmailID;
            formInitialSchema.PersonalEmailID =
              res.data.data.employees.PersonalEmailID;
            formInitialSchema.ShortProfile =
              res.data.data.employees.ShortProfile;
            formInitialSchema.OrignalDocDetails =
              res.data.data.employees.OrignalDocDetails;
            formInitialSchema.Address1 = res.data.data.employees.Address1;
            formInitialSchema.Address2 = res.data.data.employees.Address2;
            formInitialSchema.City = res.data.data.employees.City;
            formInitialSchema.State = res.data.data.employees.State;
            formInitialSchema.Country = res.data.data.employees.Country;
            formInitialSchema.Contact1 = res.data.data.employees.Contact1;
            formInitialSchema.Contact1Name =
              res.data.data.employees.Contact1Name;
            formInitialSchema.Contact1Type =
              res.data.data.employees.Contact1Type;
            formInitialSchema.Contact2 = res.data.data.employees.Contact2;
            formInitialSchema.Contact2Name =
              res.data.data.employees.Contact2Name;
            formInitialSchema.Contact2Type =
              res.data.data.employees.Contact2Type;
            formInitialSchema.AdharNo = res.data.data.employees.AdharNo;
            formInitialSchema.PancardNo = res.data.data.employees.PancardNo;
            formInitialSchema.BankName = res.data.data.employees.BankName;
            formInitialSchema.BankBranch = res.data.data.employees.BankBranch;
            formInitialSchema.BankAccountNo =
              res.data.data.employees.BankAccountNo;
            formInitialSchema.BankIFSCCode =
              res.data.data.employees.BankIFSCCode;
            formInitialSchema.BankAccountName =
              res.data.data.employees.BankAccountName;
            formInitialSchema.BankAccountType =
              res.data.data.employees.BankAccountType;
            formInitialSchema.PlaceOfBirth =
              res.data.data.employees.PlaceOfBirth;
            formInitialSchema.ResidenceType =
              res.data.data.employees.ResidenceType;
            formInitialSchema.Disabilities =
              res.data.data.employees.Disabilities;
            formInitialSchema.ZipCode = res.data.data.employees.ZipCode;

            let _lstDocuments = [];
            for (let i = 0; i < res.data.data.documents.length; i++) {
              _lstDocuments.push({
                lstID: commonservices.createGuid(),
                docId: res.data.data.documents[i].DocumentID,
                docType: res.data.data.documents[i].DocType,
                docName: res.data.data.documents[i].DocName,
                details: res.data.data.documents[i].Details,
                file: res.data.data.documents[i].FilePath,
                DataItem: res.data.data.documents[i],
              });
            }
            setLstDocuments(_lstDocuments);

            let _lstPasswordManagment = [];
            for (let i = 0; i < res.data.data.passwordmanagment.length; i++) {
              _lstPasswordManagment.push({
                lstID: commonservices.createGuid(),
                passId: res.data.data.passwordmanagment[i].PasswordManagmentID,
                typeTerm: res.data.data.passwordmanagment[i].TypeTerm,
                userName: res.data.data.passwordmanagment[i].UserName,
                password: res.data.data.passwordmanagment[i].Password,
                details: res.data.data.passwordmanagment[i].Details,
              });
            }
            setLstPasswordManagment(_lstPasswordManagment);
            setIsGetDetailsDone(true);
          } else if (res.data.status_code === 2) {
            toast.error(res.data.message);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {});
    }
  }, []);

  function fnnextButtonValidation(
    CurrentTab,
    NextTable,
    validateForm,
    validateField,
    tabValidateFieldName
  ) {
    if (
      tabValidateFieldName !== null &&
      tabValidateFieldName !== undefined &&
      tabValidateFieldName.length !== 0
    ) {
      tabValidateFieldName.map((item, index) => validateField(item));
    }
    let IsTabDataAreValid = true;
    validateForm().then((values) => {
      if (
        tabValidateFieldName !== null &&
        tabValidateFieldName !== undefined &&
        tabValidateFieldName.length !== 0 &&
        values !== null &&
        values !== undefined
      ) {
        tabValidateFieldName.map((item, index) => {
          if (values.hasOwnProperty(item)) {
            IsTabDataAreValid = false;
          }
        });
        if (IsTabDataAreValid) {
          setgeneral(NextTable);
        }
      } else {
        if (IsTabDataAreValid) {
          setgeneral(NextTable);
        }
      }
    });
  }

  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        className="right-modal modal-tabs"
        scrollable={true}
        backdrop="static"
        keyboard={false}
      >
        <Formik
          id="frmEmployeeDetails"
          initialValues={formInitialSchema}
          validationSchema={formValidation}
          validateOnBlur={false}
          onSubmit={async (values) => {
            setSubmitting(true);
            const formData = new FormData();
            formData.append("EmployeeID", values.EmployeeID);
            formData.append("FirstName", values.FirstName);
            formData.append("LastName", values.LastName);
            formData.append("MiddleName", values.MiddleName);
            formData.append("MobileNo", values.MobileNo);

            if (values.DOB !== null && values.DOB !== "") {
              formData.append("DOB", values.DOB);
            }
            if (values.DateOfJoining !== null && values.DateOfJoining !== "") {
              formData.append("DateOfJoining", values.DateOfJoining);
            }

            formData.append("EmplyeesNo", values.EmplyeesNo);
            formData.append("ProfilePic", values.ProfilePic);
            formData.append("DepartmentID", values.DepartmentID);
            formData.append("Position", values.Position);

            if (values.DateOfLeaving !== null && values.DateOfLeaving !== "") {
              formData.append("DateOfLeaving", values.DateOfLeaving);
            }

            formData.append(
              "ProfilePicFile",
              document.getElementsByName("ProfilePicFile")[0].files[0]
            );

            formData.append("MaritalStatus", values.MaritalStatus);
            formData.append("Gender", values.Gender);
            formData.append("EmailID", values.EmailID);
            formData.append("PersonalEmailID", values.PersonalEmailID);
            formData.append("ShortProfile", values.ShortProfile);
            formData.append("OrignalDocDetails", values.OrignalDocDetails);
            formData.append("Address1", values.Address1);
            formData.append("Address2", values.Address2);
            formData.append("City", values.City);
            formData.append("State", values.State);
            formData.append("Country", values.Country);
            formData.append("Contact1", values.Contact1);
            formData.append("Contact1Name", values.Contact1Name);
            formData.append("Contact1Type", values.Contact1Type);
            formData.append("Contact2", values.Contact2);
            formData.append("Contact2Name", values.Contact2Name);
            formData.append("Contact2Type", values.Contact2Type);
            formData.append("AdharNo", values.AdharNo);
            formData.append("PancardNo", values.PancardNo);
            formData.append("BankName", values.BankName);
            formData.append("BankBranch", values.BankBranch);
            formData.append("BankAccountNo", values.BankAccountNo);
            formData.append("BankIFSCCode", values.BankIFSCCode);
            formData.append("BankAccountName", values.BankAccountName);
            formData.append("BankAccountType", values.BankAccountType);
            formData.append("PlaceOfBirth", values.PlaceOfBirth);
            formData.append("ResidenceType", values.ResidenceType);
            formData.append("Disabilities", values.Disabilities);
            formData.append("ZipCode", values.ZipCode);

            let SkillTag = "";
            for (let i = 0; i < selectedSkill.length; i++) {
              SkillTag = SkillTag + selectedSkill[i].value + ",";
            }
            formData.append("SkillTag", SkillTag);

            let Languages = "";
            for (let i = 0; i < selectedLang.length; i++) {
              Languages = Languages + selectedLang[i].value + ",";
            }
            formData.append("Languages", Languages);

            let NoOfDoc = 0;
            lstDocuments.forEach((element) => {
              if (element.docId === 0) {
                if (
                  document.getElementsByName("file" + element.lstID)[0].files
                    .length !== 0
                ) {
                  formData.append(
                    "lstDocuments[" + NoOfDoc + "].docName",
                    values["docName" + element.lstID] !== null &&
                      values["docName" + element.lstID] !== undefined
                      ? values["docName" + element.lstID]
                      : ""
                  );
                  formData.append(
                    "lstDocuments[" + NoOfDoc + "].file",
                    document.getElementsByName("file" + element.lstID)[0]
                      .files[0]
                  );
                  formData.append(
                    "lstDocuments[" + NoOfDoc + "].details",
                    values["details" + element.lstID] !== null &&
                      values["details" + element.lstID] !== undefined
                      ? values["details" + element.lstID]
                      : ""
                  );
                  formData.append(
                    "lstDocuments[" + NoOfDoc + "].docType",
                    values["docType" + element.lstID] !== null &&
                      values["docType" + element.lstID] !== undefined
                      ? values["docType" + element.lstID]
                      : ""
                  );
                }
                NoOfDoc = NoOfDoc + 1;
              }
            });

            let NoOfPass = 0;
            lstPasswordManagment.forEach((element) => {
              if (element.passId === 0) {
                if (
                  values["typeTerm" + element.lstID] !== null &&
                  values["typeTerm" + element.lstID] !== undefined &&
                  values["typeTerm" + element.lstID] !== ""
                ) {
                  formData.append(
                    "lstPasswordManagment[" + NoOfPass + "].typeTerm",
                    values["typeTerm" + element.lstID] !== null &&
                      values["typeTerm" + element.lstID] !== undefined
                      ? values["typeTerm" + element.lstID]
                      : ""
                  );
                  formData.append(
                    "lstPasswordManagment[" + NoOfPass + "].userName",
                    values["userName" + element.lstID] !== null &&
                      values["userName" + element.lstID] !== undefined
                      ? values["userName" + element.lstID]
                      : ""
                  );
                  formData.append(
                    "lstPasswordManagment[" + NoOfPass + "].password",
                    values["password" + element.lstID] !== null &&
                      values["password" + element.lstID] !== undefined
                      ? values["password" + element.lstID]
                      : ""
                  );
                  formData.append(
                    "lstPasswordManagment[" + NoOfPass + "].details",
                    values["details" + element.lstID] !== null &&
                      values["details" + element.lstID] !== undefined
                      ? values["details" + element.lstID]
                      : ""
                  );
                }
                NoOfPass = NoOfPass + 1;
              }
            });

            if (selectedLeave != null && selectedLeave.length != 0) {
              for (let i = 0; i < selectedLeave.length; i++) {
                formData.append(
                  "lstLeave[" + i + "].leaveID",
                  parseInt(selectedLeave[i].value)
                );
              }
            }

            const axiosConfig = {
              headers: {
                "content-type": "multipart/form-data",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            };
            const response = await axios
              .post(
                `${AppConfigData.APIBasePath}v1/employees/save`,
                formData,
                axiosConfig
              )
              .then((response) => {
                setSubmitting(false);
                if (response.data.status_code === 1) {
                  toast.success(response.data.message);
                  props.bindList();
                  props.onHide();

                  //setTimeout(() => {
                  //    props.onHide();
                  //}, 1000);
                } else if (response.data.status_code === 2) {
                  toast.error(response.data.message);
                } else if (response.data.status_code === 0) {
                  toast.error(response.data.message);
                } else {
                  toast.error(response.data.message);
                }
              })
              .catch((error) => {});
          }}
        >
          {({ errors, touched, validateField, validateForm }) => (
            <Form>
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  {commonservices.LabelDisplayText("AddEmployeesDetails")}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Container>
                  <Tabs
                    defaultActiveKey="general"
                    id="uncontrolled-tab-example"
                    activeKey={general}
                  >
                    <Tab
                      eventKey="general"
                      title="General"
                      className="modal-class"
                    >
                      <Container className="height-adjust-modal-body">
                        <Row>
                          <Col xs={6} className="from-bottom-margin">
                            <Field
                              type="hidden"
                              name="EmployeeID"
                              className="form-control"
                              placeholder="EmployeeID"
                            />
                            <div className="form-floating">
                              <Field
                                type="text"
                                name="FirstName"
                                className="form-control"
                                placeholder={commonservices.LabelDisplayText(
                                  "FirstName"
                                )}
                                maxLength={formFieldLength.FirstName}
                              />
                              <label lbl-for="FirstName">
                                {commonservices.LabelDisplayText("FirstName")}
                              </label>
                              <div className="text-danger-error">
                                {errors.FirstName && <>{errors.FirstName}</>}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} className="from-bottom-margin">
                            <div className="form-floating">
                              <Field
                                type="text"
                                name="MiddleName"
                                className="form-control"
                                placeholder={commonservices.LabelDisplayText(
                                  "MiddleName"
                                )}
                                maxLength={formFieldLength.MiddleName}
                              />
                              <label lbl-for="MiddleName">
                                {commonservices.LabelDisplayText("MiddleName")}
                              </label>
                              <div className="text-danger-error">
                                {errors.MiddleName && <>{errors.MiddleName}</>}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} className="from-bottom-margin">
                            <div className="form-floating">
                              <Field
                                type="text"
                                name="LastName"
                                className="form-control"
                                placeholder={commonservices.LabelDisplayText(
                                  "LastName"
                                )}
                                maxLength={formFieldLength.LastName}
                              />
                              <label lbl-for="LastName">
                                {commonservices.LabelDisplayText("LastName")}
                              </label>
                              <div className="text-danger-error">
                                {errors.LastName && <>{errors.LastName}</>}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} className="from-bottom-margin">
                            <div className="form-floating">
                              <Field
                                type="date"
                                name="DOB"
                                className="form-control calendar-icon"
                              />
                              <label lbl-for="DOB">
                                {commonservices.LabelDisplayText("DateOfBirth")}
                              </label>
                              <div className="text-danger-error">
                                {errors.DOB && <>{errors.DOB}</>}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} className="from-bottom-margin">
                            <div className="form-floating">
                              <Field
                                as="select"
                                name="Gender"
                                className="form-control down-arrow-icon"
                              >
                                <option value="">
                                  {commonservices.LabelDisplayText("Select")}
                                </option>
                                {projectItem.length > 0 &&
                                  projectItem.map(
                                    (item, index) =>
                                      item.Category === "Gender" && (
                                        <option key={index} value={item.Term}>
                                          {item.Term}
                                        </option>
                                      )
                                  )}
                              </Field>
                              <label lbl-for="Gender">
                                {commonservices.LabelDisplayText("Gender")}
                              </label>
                              <div className="text-danger-error">
                                {errors.Gender && <>{errors.Gender}</>}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} className="from-bottom-margin">
                            <div className="form-floating">
                              <Field
                                type="text"
                                name="PlaceOfBirth"
                                className="form-control"
                                placeholder={commonservices.LabelDisplayText(
                                  "PlaceOfBirth"
                                )}
                                maxLength={formFieldLength.PlaceOfBirth}
                              />
                              <label lbl-for="PlaceOfBirth">
                                {commonservices.LabelDisplayText(
                                  "PlaceOfBirth"
                                )}
                              </label>
                              <div className="text-danger-error">
                                {errors.PlaceOfBirth && (
                                  <>{errors.PlaceOfBirth}</>
                                )}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} className="from-bottom-margin">
                            <div className="form-floating">
                              <Field
                                as="select"
                                name="Position"
                                className="form-control down-arrow-icon"
                              >
                                <option value="">
                                  {commonservices.LabelDisplayText("Select")}
                                </option>
                                {projectItem.length > 0 &&
                                  projectItem.map(
                                    (item, index) =>
                                      item.Category === "Position" && (
                                        <option key={index} value={item.Term}>
                                          {item.Term}
                                        </option>
                                      )
                                  )}
                              </Field>
                              <label lbl-for="Position">
                                {commonservices.LabelDisplayText("Position")}
                              </label>
                              <div className="text-danger-error">
                                {errors.Position && <>{errors.Position}</>}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} className="from-bottom-margin">
                            <div className="form-floating">
                              <Field
                                as="select"
                                name="MaritalStatus"
                                className="form-control down-arrow-icon"
                              >
                                <option value="">
                                  {commonservices.LabelDisplayText("Select")}{" "}
                                </option>
                                {projectItem.length > 0 &&
                                  projectItem.map(
                                    (item, index) =>
                                      item.Category === "MaritalStatus" && (
                                        <option key={index} value={item.Term}>
                                          {item.Term}
                                        </option>
                                      )
                                  )}
                              </Field>
                              <label lbl-for="MaritalStatus">
                                {commonservices.LabelDisplayText(
                                  "MaritalStatus"
                                )}
                              </label>
                              <div className="text-danger-error">
                                {errors.MaritalStatus && (
                                  <>{errors.MaritalStatus}</>
                                )}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} className="from-bottom-margin">
                            <div className="form-floating multipleselect-form-floating">
                              <ReactMultiSelectCheckboxes
                                width={"100%"}
                                placeholderButtonLabel={"Select "}
                                isMulti
                                name="SkillTag"
                                onChange={fnSelectedOptionSkill}
                                options={optionSkill}
                                value={selectedSkill}
                              />
                              <label lbl-for="SkillTag">
                                {commonservices.LabelDisplayText(
                                  "Technologies"
                                )}
                              </label>
                              <div className="text-danger-error">
                                {errors.SkillTag && <>{errors.SkillTag}</>}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} className="from-bottom-margin">
                            <div className="form-floating">
                              <Field
                                as="select"
                                name="DepartmentID"
                                className="form-control down-arrow-icon"
                              >
                                <option value="">
                                  {commonservices.LabelDisplayText("Select")}
                                </option>
                                {projectItem.length > 0 &&
                                  projectItem.map(
                                    (item, index) =>
                                      item.Category === "Department" && (
                                        <option key={index} value={item.TermID}>
                                          {item.Term}
                                        </option>
                                      )
                                  )}
                              </Field>
                              <label lbl-for="DepartmentID">
                                {commonservices.LabelDisplayText("Department")}
                              </label>
                              <div className="text-danger-error">
                                {errors.DepartmentID && (
                                  <>{errors.DepartmentID}</>
                                )}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} className="from-bottom-margin">
                            <div className="form-floating">
                              <Field
                                type="text"
                                name="AdharNo"
                                className="form-control"
                                placeholder={commonservices.LabelDisplayText(
                                  "AdharNo"
                                )}
                                maxLength={formFieldLength.AdharNo}
                              />
                              <label lbl-for="AdharNo">
                                {commonservices.LabelDisplayText("AdharNo")}
                              </label>
                              <div className="text-danger-error">
                                {errors.AdharNo && <>{errors.AdharNo}</>}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} className="from-bottom-margin">
                            <div className="form-floating">
                              <Field
                                type="text"
                                name="EmplyeesNo"
                                className="form-control"
                                placeholder={commonservices.LabelDisplayText(
                                  "EmplyeesNo"
                                )}
                                maxLength={formFieldLength.EmplyeesNo}
                              />
                              <label lbl-for="EmplyeesNo">
                                {commonservices.LabelDisplayText("EmplyeesNo")}
                              </label>
                              <div className="text-danger-error">
                                {errors.EmplyeesNo && <>{errors.EmplyeesNo}</>}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} className="from-bottom-margin">
                            <div className="form-floating ">
                              <Field
                                type="date"
                                name="DateOfJoining"
                                className="form-control calendar-icon"
                                placeholder={commonservices.LabelDisplayText(
                                  "DateOfJoining"
                                )}
                              />
                              <label lbl-for="DateOfJoining">
                                {commonservices.LabelDisplayText(
                                  "DateOfJoining"
                                )}
                              </label>
                              <div className="text-danger-error">
                                {errors.DateOfJoining && (
                                  <>{errors.DateOfJoining}</>
                                )}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} className="from-bottom-margin">
                            <div className="form-floating">
                              <Field
                                type="text"
                                name="PancardNo"
                                className="form-control"
                                placeholder={commonservices.LabelDisplayText(
                                  "PancardNo"
                                )}
                                maxLength={formFieldLength.PancardNo}
                              />
                              <label lbl-for="PancardNo">
                                {commonservices.LabelDisplayText("PancardNo")}
                              </label>
                              <div className="text-danger-error">
                                {errors.PancardNo && <>{errors.PancardNo}</>}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} className="from-bottom-margin">
                            <div className="form-floating">
                              <Field
                                type="text"
                                name="Disabilities"
                                className="form-control"
                                placeholder={commonservices.LabelDisplayText(
                                  "Disabilities"
                                )}
                                maxLength={formFieldLength.Disabilities}
                              />
                              <label lbl-for="Disabilities">
                                {commonservices.LabelDisplayText(
                                  "Disabilities"
                                )}
                              </label>
                              <div className="text-danger-error">
                                {errors.Disabilities && (
                                  <>{errors.Disabilities}</>
                                )}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} className="from-bottom-margin">
                            <div className="form-floating">
                              <Field
                                type="date"
                                name="DateOfLeaving"
                                className="form-control calendar-icon"
                                placeholder="DateOfLeaving"
                              />
                              <label lbl-for="DateOfLeaving">
                                {commonservices.LabelDisplayText(
                                  "DateOfLeaving"
                                )}
                              </label>
                              <div className="text-danger-error">
                                {errors.DateOfLeaving && (
                                  <>{errors.DateOfLeaving}</>
                                )}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} className="from-bottom-margin">
                            <div className="form-floating multipleselect-form-floating">
                              <ReactMultiSelectCheckboxes
                                width={"100%"}
                                placeholderButtonLabel={"Select "}
                                isMulti
                                name="Languages"
                                onChange={fnSelectedOptionLang}
                                options={optionLang}
                                value={selectedLang}
                              />
                              <label lbl-for="Languages">
                                {commonservices.LabelDisplayText("Languages")}
                              </label>
                              <div className="text-danger-error">
                                {errors.Languages && <>{errors.Languages}</>}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} className="from-bottom-margin">
                            <div className="form-floating">
                              <div className="form-control browse-file">
                                <Field
                                  type="Hidden"
                                  name="ProfilePic"
                                  className="form-control"
                                  placeholder="ProfilePic"
                                  style={{
                                    overflow: "hidden",
                                  }}
                                />
                                <Field
                                  type="File"
                                  name="ProfilePicFile"
                                  className="form-control"
                                  placeholder="ProfilePicFile"
                                />
                                <label lbl-for="ProfilePicFile">
                                  {commonservices.LabelDisplayText(
                                    "ChooseProfilePic"
                                  )}
                                </label>
                                <div className="text-danger-error">
                                  {errors.ProfilePicFile && (
                                    <>{errors.ProfilePicFile}</>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Col>

                          <Col xs={6} className="from-bottom-margin">
                            <div className="form-floating multipleselect-form-floating">
                              <ReactMultiSelectCheckboxes
                                width={"100%"}
                                placeholderButtonLabel={"Select "}
                                isMulti
                                name="Leave"
                                onChange={fnSelectedOptionLeave}
                                options={optionLeave}
                                value={selectedLeave}
                              />
                              <label lbl-for="Leave">
                                {commonservices.LabelDisplayText("LeaveType")}
                              </label>
                              <div className="text-danger-error">
                                {errors.LeaveType && <>{errors.LeaveType}</>}
                              </div>
                            </div>
                          </Col>

                          <Col xs={12} className="from-bottom-margin">
                            <div className="form-floating">
                              <Field
                                as="textarea"
                                type="text"
                                name="ShortProfile"
                                maxLength={formFieldLength.ShortProfile}
                                className="form-control pt-2 pb-2"
                                placeholder={commonservices.LabelDisplayText(
                                  "EnterShortProfile"
                                )}
                              />
                              <label lbl-for="ShortProfile">
                                {commonservices.LabelDisplayText(
                                  "EnterShortProfile"
                                )}
                              </label>
                              <div className="text-danger-error">
                                {errors.ShortProfile && (
                                  <>{errors.ShortProfile}</>
                                )}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                      <Modal.Footer>
                        <Button
                          variant="secondary"
                          className="m-0"
                          onClick={props.onHide}
                          title="Close"
                        >
                          {commonservices.LabelDisplayText("Close")}
                        </Button>
                        <Button
                          variant="primary"
                          className="m-0 me-2"
                          title="Next"
                          onClick={(e) =>
                            fnnextButtonValidation(
                              "general",
                              "profile",
                              validateForm,
                              validateField,
                              [
                                "FirstName",
                                "MiddleName",
                                "LastName",
                                "Gender",
                                "Position",
                                "DepartmentID",
                                "AdharNo",
                                "EmplyeesNo",
                                "DateOfJoining",
                                "PancardNo",
                                "ProfilePicFile",
                                "PlaceOfBirth",
                              ]
                            )
                          }
                        >
                          {commonservices.LabelDisplayText("Next")}
                        </Button>
                      </Modal.Footer>
                    </Tab>
                    <Tab eventKey="profile" title="Profile">
                      <Container className="height-adjust-modal-body">
                        <Row>
                          <Col xs={12} className="from-bottom-margin">
                            <div className="form-floating">
                              <Field
                                as="textarea"
                                type="text"
                                name="Address1"
                                className="form-control pt-2 pb-2"
                                placeholder={commonservices.LabelDisplayText(
                                  "Address1"
                                )}
                                maxLength={formFieldLength.Address1}
                              />
                              <label lbl-for="Address1">
                                {commonservices.LabelDisplayText("Address1")}
                              </label>
                              <div className="text-danger-error">
                                {errors.Address1 && <>{errors.Address1}</>}
                              </div>
                            </div>
                          </Col>
                          <Col xs={12} className="from-bottom-margin">
                            <div className="form-floating">
                              <Field
                                as="textarea"
                                type="text"
                                name="Address2"
                                className="form-control pt-2 pb-2"
                                placeholder={commonservices.LabelDisplayText(
                                  "Address2"
                                )}
                                maxLength={formFieldLength.Address2}
                              />
                              <label lbl-for="Address2">
                                {commonservices.LabelDisplayText("Address2")}
                              </label>
                              <div className="text-danger-error">
                                {errors.Address2 && <>{errors.Address2}</>}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} className="from-bottom-margin">
                            <div className="form-floating">
                              <Field
                                type="text"
                                name="City"
                                className="form-control"
                                placeholder="Village or Town or City"
                                maxLength={formFieldLength.City}
                              />
                              <label lbl-for="City">
                                {commonservices.LabelDisplayText(
                                  "VillageOrTownOrCity"
                                )}
                              </label>
                              <div className="text-danger-error">
                                {errors.City && <>{errors.City}</>}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} className="from-bottom-margin">
                            <div className="form-floating">
                              <Field
                                type="text"
                                name="State"
                                className="form-control"
                                placeholder={commonservices.LabelDisplayText(
                                  "State"
                                )}
                                maxLength={formFieldLength.State}
                              />
                              <label lbl-for="State">
                                {commonservices.LabelDisplayText("State")}
                              </label>
                              <div className="text-danger-error">
                                {errors.State && <>{errors.State}</>}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} className="from-bottom-margin">
                            <div className="form-floating">
                              <Field
                                type="text"
                                name="ZipCode"
                                className="form-control"
                                placeholder={commonservices.LabelDisplayText(
                                  "ZipCode"
                                )}
                                maxLength={formFieldLength.ZipCode}
                              />
                              <label lbl-for="ZipCode">
                                {commonservices.LabelDisplayText("ZipCode")}
                              </label>
                              <div className="text-danger-error">
                                {errors.ZipCode && <>{errors.ZipCode}</>}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} className="from-bottom-margin">
                            <div className="form-floating">
                              <Field
                                type="text"
                                name="Country"
                                className="form-control"
                                placeholder={commonservices.LabelDisplayText(
                                  "Country"
                                )}
                                maxLength={formFieldLength.Country}
                              />
                              <label lbl-for="Country">
                                {commonservices.LabelDisplayText("Country")}
                              </label>
                              <div className="text-danger-error">
                                {errors.Country && <>{errors.Country}</>}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} className="from-bottom-margin">
                            <div className="form-floating">
                              <Field
                                type="email"
                                name="EmailID"
                                className="form-control"
                                placeholder={commonservices.LabelDisplayText(
                                  "EmailAddress"
                                )}
                                maxLength={formFieldLength.EmailID}
                              />
                              <label lbl-for="EmailID">
                                {commonservices.LabelDisplayText(
                                  "EmailAddress"
                                )}
                              </label>
                              <div className="text-danger-error">
                                {errors.EmailID && <>{errors.EmailID}</>}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} className="from-bottom-margin">
                            <div className="form-floating">
                              <Field
                                type="text"
                                name="MobileNo"
                                className="form-control"
                                placeholder={commonservices.LabelDisplayText(
                                  "MobileNo"
                                )}
                                maxLength={formFieldLength.MobileNo}
                              />
                              <label lbl-for="MobileNo">
                                {commonservices.LabelDisplayText("MobileNo")}
                              </label>
                              <div className="text-danger-error">
                                {errors.MobileNo && <>{errors.MobileNo}</>}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} className="from-bottom-margin">
                            <div className="form-floating">
                              <Field
                                as="select"
                                name="ResidenceType"
                                className="form-control down-arrow-icon"
                              >
                                <option value="">
                                  {commonservices.LabelDisplayText("Select")}
                                </option>
                                {projectItem.length > 0 &&
                                  projectItem.map(
                                    (item, index) =>
                                      item.Category === "ResidenceType" && (
                                        <option key={index} value={item.Term}>
                                          {item.Term}
                                        </option>
                                      )
                                  )}
                              </Field>
                              <label lbl-for="ResidenceType">
                                {commonservices.LabelDisplayText(
                                  "ResidenceType"
                                )}
                              </label>
                              <div className="text-danger-error">
                                {errors.ResidenceType && (
                                  <>{errors.ResidenceType}</>
                                )}
                              </div>
                            </div>
                          </Col>

                          <Col xs={6} className="from-bottom-margin">
                            <div className="form-floating">
                              <Field
                                type="email"
                                name="PersonalEmailID"
                                className="form-control"
                                placeholder={commonservices.LabelDisplayText(
                                  "EmailAddress"
                                )}
                                maxLength={formFieldLength.PersonalEmailID}
                              />
                              <label lbl-for="PersonalEmailID">
                                {commonservices.LabelDisplayText(
                                  "PersonalEmailID"
                                )}
                              </label>
                              <div className="text-danger-error">
                                {errors.PersonalEmailID && (
                                  <>{errors.PersonalEmailID}</>
                                )}
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={4} className="from-bottom-margin">
                            <div className="form-floating">
                              <Field
                                type="text"
                                name="Contact1Name"
                                className="form-control"
                                placeholder={commonservices.LabelDisplayText(
                                  "Contact1Name"
                                )}
                                maxLength={formFieldLength.Contact1Name}
                              />
                              <label lbl-for="Contact1Name">
                                {commonservices.LabelDisplayText(
                                  "Contact1Name"
                                )}
                              </label>
                              <div className="text-danger-error">
                                {errors.Contact1Name && (
                                  <>{errors.Contact1Name}</>
                                )}
                              </div>
                            </div>
                          </Col>
                          <Col xs={4} className="from-bottom-margin">
                            <div className="form-floating">
                              <Field
                                type="text"
                                name="Contact1Type"
                                className="form-control"
                                placeholder={commonservices.LabelDisplayText(
                                  "Contact1Type"
                                )}
                                maxLength={formFieldLength.Contact1Type}
                              />
                              <label lbl-for="Contact1Type">
                                {commonservices.LabelDisplayText(
                                  "Contact1Type"
                                )}
                              </label>
                              <div className="text-danger-error">
                                {errors.Contact1Type && (
                                  <>{errors.Contact1Type}</>
                                )}
                              </div>
                            </div>
                          </Col>
                          <Col xs={4} className="from-bottom-margin">
                            <div className="form-floating">
                              <Field
                                type="text"
                                name="Contact1"
                                className="form-control"
                                placeholder={commonservices.LabelDisplayText(
                                  "Contact1"
                                )}
                                maxLength={formFieldLength.Contact1}
                              />
                              <label lbl-for="Contact1">
                                {commonservices.LabelDisplayText("Contact1")}
                              </label>
                              <div className="text-danger-error">
                                {errors.Contact1 && <>{errors.Contact1}</>}
                              </div>
                            </div>
                          </Col>
                          <Col xs={4} className="from-bottom-margin">
                            <div className="form-floating">
                              <Field
                                type="text"
                                name="Contact2Name"
                                className="form-control"
                                placeholder={commonservices.LabelDisplayText(
                                  "Contact2Name"
                                )}
                                maxLength={formFieldLength.Contact2Name}
                              />
                              <label lbl-for="Contact2Name">
                                {commonservices.LabelDisplayText(
                                  "Contact2Name"
                                )}
                              </label>
                              <div className="text-danger-error">
                                {errors.Contact2Name && (
                                  <>{errors.Contact2Name}</>
                                )}
                              </div>
                            </div>
                          </Col>
                          <Col xs={4} className="from-bottom-margin">
                            <div className="form-floating">
                              <Field
                                type="text"
                                name="Contact2Type"
                                className="form-control"
                                placeholder={commonservices.LabelDisplayText(
                                  "Contact2Type"
                                )}
                                maxLength={formFieldLength.Contact2Type}
                              />
                              <label lbl-for="Contact2Type">
                                {commonservices.LabelDisplayText(
                                  "Contact2Type"
                                )}
                              </label>
                              <div className="text-danger-error">
                                {errors.Contact2Type && (
                                  <>{errors.Contact2Type}</>
                                )}
                              </div>
                            </div>
                          </Col>
                          <Col xs={4} className="from-bottom-margin">
                            <div className="form-floating">
                              <Field
                                type="text"
                                name="Contact2"
                                className="form-control"
                                placeholder={commonservices.LabelDisplayText(
                                  "Contact2"
                                )}
                                maxLength={formFieldLength.Contact2}
                              />
                              <label lbl-for="Contact2">
                                {commonservices.LabelDisplayText("Contact2")}
                              </label>
                              <div className="text-danger-error">
                                {errors.Contact2 && <>{errors.Contact2}</>}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                      <Modal.Footer>
                        <Button
                          variant="secondary"
                          className="m-0"
                          title="Previous"
                          onClick={(e) => setgeneral("general")}
                        >
                          {commonservices.LabelDisplayText("Previous")}
                        </Button>
                        <Button
                          variant="primary"
                          className="m-0 me-2"
                          title="Next"
                          onClick={(e) =>
                            fnnextButtonValidation(
                              "profile",
                              "bank",
                              validateForm,
                              validateField,
                              [
                                "EmailID",
                                "MobileNo",
                                "Address1",
                                "Address2",
                                "City",
                                "State",
                                "ZipCode",
                                "Country",
                                "EmailID",
                                "MobileNo",
                                "Contact1Name",
                                "Contact1Type",
                                "Contact1",
                                "Contact2Name",
                                "Contact2Type",
                                "Contact2",
                              ]
                            )
                          }
                        >
                          {commonservices.LabelDisplayText("Next")}
                        </Button>
                      </Modal.Footer>
                    </Tab>
                    <Tab eventKey="bank" title="Bank">
                      <Container className="height-adjust-modal-body">
                        <Row>
                          <Col xs={6} className="from-bottom-margin">
                            <div className="form-floating">
                              <Field
                                type="text"
                                name="BankName"
                                className="form-control"
                                placeholder={commonservices.LabelDisplayText(
                                  "BankName"
                                )}
                                maxLength={formFieldLength.BankName}
                              />
                              <label lbl-for="BankName">
                                {commonservices.LabelDisplayText("BankName")}
                              </label>
                              <div className="text-danger-error">
                                {errors.BankName && <>{errors.BankName}</>}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} className="from-bottom-margin">
                            <div className="form-floating">
                              <Field
                                type="text"
                                name="BankBranch"
                                className="form-control"
                                placeholder={commonservices.LabelDisplayText(
                                  "BankBranch"
                                )}
                                maxLength={formFieldLength.BankBranch}
                              />
                              <label lbl-for="BankBranch">
                                {commonservices.LabelDisplayText("BankBranch")}
                              </label>
                              <div className="text-danger-error">
                                {errors.BankBranch && <>{errors.BankBranch}</>}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} className="from-bottom-margin">
                            <div className="form-floating">
                              <Field
                                type="text"
                                name="BankAccountNo"
                                className="form-control"
                                placeholder={commonservices.LabelDisplayText(
                                  "BankAccountNo"
                                )}
                                maxLength={formFieldLength.BankAccountNo}
                              />
                              <label lbl-for="BankAccountNo">
                                {commonservices.LabelDisplayText(
                                  "BankAccountNo"
                                )}
                              </label>
                              <div className="text-danger-error">
                                {errors.BankAccountNo && (
                                  <>{errors.BankAccountNo}</>
                                )}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} className="from-bottom-margin">
                            <div className="form-floating">
                              <Field
                                type="text"
                                name="BankIFSCCode"
                                className="form-control"
                                placeholder={commonservices.LabelDisplayText(
                                  "BankIFSCCode"
                                )}
                                maxLength={formFieldLength.BankIFSCCode}
                              />
                              <label lbl-for="BankIFSCCode">
                                {commonservices.LabelDisplayText(
                                  "BankIFSCCode"
                                )}
                              </label>
                              <div className="text-danger-error">
                                {errors.BankIFSCCode && (
                                  <>{errors.BankIFSCCode}</>
                                )}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} className="from-bottom-margin">
                            <div className="form-floating">
                              <Field
                                type="text"
                                name="BankAccountName"
                                className="form-control"
                                placeholder={commonservices.LabelDisplayText(
                                  "BankAccountName"
                                )}
                                maxLength={formFieldLength.BankAccountName}
                              />
                              <label lbl-for="BankAccountName">
                                {commonservices.LabelDisplayText(
                                  "BankAccountName"
                                )}
                              </label>
                              <div className="text-danger-error">
                                {errors.BankAccountName && (
                                  <>{errors.BankAccountName}</>
                                )}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} className="from-bottom-margin">
                            <div className="form-floating">
                              <Field
                                type="text"
                                name="BankAccountType"
                                className="form-control"
                                placeholder={commonservices.LabelDisplayText(
                                  "BankAccountType"
                                )}
                                maxLength={formFieldLength.BankAccountType}
                              />
                              <label lbl-for="BankAccountType">
                                {commonservices.LabelDisplayText(
                                  "BankAccountType"
                                )}
                              </label>
                              <div className="text-danger-error">
                                {errors.BankAccountType && (
                                  <>{errors.BankAccountType}</>
                                )}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                      <Modal.Footer>
                        <Button
                          variant="secondary"
                          className="m-0"
                          title="Previous"
                          onClick={(e) => setgeneral("profile")}
                        >
                          {commonservices.LabelDisplayText("Previous")}
                        </Button>
                        <Button
                          variant="primary"
                          className="m-0 me-2"
                          title="Next"
                          onClick={(e) =>
                            fnnextButtonValidation(
                              "bank",
                              "documents",
                              validateForm,
                              validateField,
                              [
                                "BankName",
                                "BankBranch",
                                "BankAccountNo",
                                "BankIFSCCode",
                                "BankAccountName",
                                "BankAccountType",
                              ]
                            )
                          }
                        >
                          {commonservices.LabelDisplayText("Next")}
                        </Button>
                      </Modal.Footer>
                    </Tab>
                    <Tab
                      eventKey="documents"
                      title="Documents"
                      className="modal-class"
                    >
                      <Container className="height-adjust-modal-body">
                        <div className="documents-tab-col">
                          <div className="project-add-icon">
                            <Button
                              className="project-btn btn btn-primary"
                              onClick={addFields}
                              title="Add"
                            >
                              <i className="fa-regular fa-plus"></i>
                            </Button>
                          </div>
                          <div className="vault-tab vault-page employees-documents-tab">
                            <Row>
                              <div className="table-col">
                                <div className="recent-task-section">
                                  <div className="table-scroll">
                                    <Table className="table table-inner-section w-100">
                                      <thead>
                                        <tr>
                                          <th className="width-200px text-nowrap">
                                            {commonservices.LabelDisplayText(
                                              "Type"
                                            )}
                                            <span className="ms-2">
                                              <i className="fa-solid fa-sort"></i>
                                            </span>
                                          </th>
                                          <th className="width-200px text-nowrap">
                                            {commonservices.LabelDisplayText(
                                              "DocName"
                                            )}
                                            <span className="ms-2">
                                              <i className="fa-solid fa-sort"></i>
                                            </span>
                                          </th>
                                          <th className="text-nowrap">
                                            {commonservices.LabelDisplayText(
                                              "Details"
                                            )}
                                            <span className="ms-2">
                                              <i className="fa-solid fa-sort"></i>
                                            </span>
                                          </th>
                                          <th>
                                            <span className="ms-2"></span>
                                          </th>
                                          <th className="width-100px text-end text-nowrap">
                                            {commonservices.LabelDisplayText(
                                              "Action"
                                            )}
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody className="table-section-col">
                                        {lstDocuments.map((item, index) => (
                                          <tr key={index}>
                                            {item.docId === 0 ? (
                                              <>
                                                <td className="width-200px text-nowrap padding-top-bottom">
                                                  <div className="form-group">
                                                    <div className="form-floating">
                                                      <Field
                                                        as="select"
                                                        name={
                                                          "docType" + item.lstID
                                                        }
                                                        className="form-control down-arrow-icon"
                                                      >
                                                        <option value="">
                                                          {commonservices.LabelDisplayText(
                                                            "Select"
                                                          )}{" "}
                                                        </option>
                                                        {projectItem.length >
                                                          0 &&
                                                          projectItem.map(
                                                            (item, index) =>
                                                              item.Category ===
                                                                "Documents" && (
                                                                <option
                                                                  key={index}
                                                                  value={
                                                                    item.Term
                                                                  }
                                                                >
                                                                  {item.Term}
                                                                </option>
                                                              )
                                                          )}
                                                      </Field>
                                                      <label>
                                                        {commonservices.LabelDisplayText(
                                                          "Type"
                                                        )}
                                                      </label>
                                                    </div>
                                                  </div>
                                                </td>
                                                <td className="width-200px text-nowrap padding-top-bottom">
                                                  <div className="form-group">
                                                    <div className="form-floating">
                                                      <Field
                                                        type="text"
                                                        name={
                                                          "docName" + item.lstID
                                                        }
                                                        className="form-control"
                                                        placeholder={commonservices.LabelDisplayText(
                                                          "DocName"
                                                        )}
                                                        maxLength={167}
                                                      />
                                                      <label>
                                                        {commonservices.LabelDisplayText(
                                                          "DocName"
                                                        )}
                                                      </label>
                                                    </div>
                                                  </div>
                                                </td>
                                                <td className="width-200px text-nowrap padding-top-bottom">
                                                  <div className="form-group">
                                                    <div className="form-floating">
                                                      <Field
                                                        type="text"
                                                        name={
                                                          "details" + item.lstID
                                                        }
                                                        className="form-control"
                                                        placeholder={commonservices.LabelDisplayText(
                                                          "Details"
                                                        )}
                                                        maxLength={500}
                                                      />
                                                      <label>
                                                        {commonservices.LabelDisplayText(
                                                          "Details"
                                                        )}
                                                      </label>
                                                    </div>
                                                  </div>
                                                </td>
                                                <td className="width-200px text-nowrap padding-top-bottom">
                                                  <div className="form-group text-nowrap">
                                                    <div className="form-floating">
                                                      <div className="form-control browse-file">
                                                        <Field
                                                          name={
                                                            "file" + item.lstID
                                                          }
                                                          type="file"
                                                          className="form-control"
                                                        />
                                                        <label>
                                                          {commonservices.LabelDisplayText(
                                                            "ChooseFile"
                                                          )}
                                                        </label>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </td>
                                              </>
                                            ) : (
                                              <>
                                                <td className="padding-top-bottom text-nowrap">
                                                  <div className="common-class">
                                                    {item.docType}
                                                  </div>
                                                </td>
                                                <td className="padding-top-bottom text-nowrap">
                                                  <div className="common-class common-name">
                                                    {item.docName}
                                                  </div>
                                                </td>
                                                <td className="padding-top-bottom text-nowrap">
                                                  <p className="common-class detail-description mb-0">
                                                    {item.details}
                                                  </p>
                                                </td>
                                                <td className="padding-top-bottom text-nowrap">
                                                  {" "}
                                                </td>
                                              </>
                                            )}
                                            <td className="text-end padding-top-bottom text-nowrap">
                                              <div className="d-flex justify-content-end">
                                                {item.docId !== 0 && (
                                                  <Button
                                                    className="icon-btn"
                                                    onClick={(e) => {
                                                      viewFilePopupService.viewFilePopup(
                                                        {
                                                          data: [item.DataItem],
                                                        }
                                                      );
                                                    }}
                                                    title="Show"
                                                  >
                                                    <EyeshowPassword />
                                                  </Button>
                                                )}
                                                <Button
                                                  className="icon-btn"
                                                  title="Delete"
                                                  onClick={(e) => {
                                                    confirmAlert({
                                                      message:
                                                        commonservices.LabelDisplayText(
                                                          "AreYouSureToDoDelete"
                                                        ),
                                                      buttons: [
                                                        {
                                                          label:
                                                            commonservices.LabelDisplayText(
                                                              "Yes"
                                                            ),
                                                          onClick: () =>
                                                            removeFields(
                                                              index,
                                                              item.lstID,
                                                              item.docId
                                                            ),
                                                        },
                                                        {
                                                          label:
                                                            commonservices.LabelDisplayText(
                                                              "No"
                                                            ),
                                                        },
                                                      ],
                                                    });
                                                  }}
                                                >
                                                  <Trash />
                                                </Button>
                                              </div>
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </Table>
                                  </div>
                                </div>
                              </div>
                            </Row>
                            <Row>
                              <Col xs={12} className="from-bottom-margin">
                                <div className="form-floating">
                                  <Field
                                    as="textarea"
                                    type="text"
                                    name="OrignalDocDetails"
                                    className="form-control"
                                    placeholder={commonservices.LabelDisplayText(
                                      "EnterOrignalDocDetails"
                                    )}
                                    maxLength={
                                      formFieldLength.OrignalDocDetails
                                    }
                                  />
                                  <label>
                                    {commonservices.LabelDisplayText(
                                      "EnterOrignalDocDetails"
                                    )}
                                  </label>
                                  <div className="text-danger-error">
                                    {errors.OrignalDocDetails && (
                                      <>{errors.OrignalDocDetails}</>
                                    )}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Container>
                      <Modal.Footer>
                        <Button
                          variant="secondary"
                          className="m-0"
                          title="Previous"
                          onClick={(e) => setgeneral("bank")}
                        >
                          {commonservices.LabelDisplayText("Previous")}
                        </Button>
                        <Button
                          variant="primary"
                          className="m-0 me-2"
                          title="Next"
                          onClick={(e) =>
                            fnnextButtonValidation(
                              "documents",
                              "vault",
                              validateForm,
                              validateField,
                              ["OrignalDocDetails"]
                            )
                          }
                        >
                          {commonservices.LabelDisplayText("Next")}
                        </Button>
                      </Modal.Footer>
                    </Tab>
                    <Tab eventKey="vault" title="Vault" disabled>
                      <Container className="height-adjust-modal-body">
                        <div className="documents-tab-col">
                          <div className="project-add-icon">
                            <Button
                              className="project-btn btn btn-primary"
                              title="Add"
                              onClick={addpassFields}
                            >
                              <i className="fa-regular fa-plus"></i>
                            </Button>
                          </div>
                          <div className="vault-tab vault-page employees-documents-tab">
                            <div className="table-col">
                              <div className="recent-task-section">
                                <div className="table-scroll">
                                  <Table className="table table-inner-section w-100">
                                    <thead>
                                      <tr>
                                        <th className="width-200px text-nowrap">
                                          {commonservices.LabelDisplayText(
                                            "Type"
                                          )}
                                          <span className="ms-2">
                                            <i className="fa-solid fa-sort"></i>
                                          </span>
                                        </th>
                                        <th className="width-200px text-nowrap">
                                          {commonservices.LabelDisplayText(
                                            "UserName"
                                          )}
                                          <span className="ms-2">
                                            <i className="fa-solid fa-sort"></i>
                                          </span>
                                        </th>
                                        <th className="width-200px text-nowrap">
                                          {commonservices.LabelDisplayText(
                                            "Password"
                                          )}
                                          <span className="ms-2">
                                            <i className="fa-solid fa-sort"></i>
                                          </span>
                                        </th>
                                        <th className="text-nowrap">
                                          {commonservices.LabelDisplayText(
                                            "Details"
                                          )}
                                          <span className="ms-2">
                                            <i className="fa-solid fa-sort"></i>
                                          </span>
                                        </th>
                                        <th className="width-100px text-end text-nowrap">
                                          {commonservices.LabelDisplayText(
                                            "Action"
                                          )}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {lstPasswordManagment.map(
                                        (item, index) => (
                                          <tr key={index}>
                                            {item.passId === 0 ? (
                                              <>
                                                <td className="padding-top-bottom text-nowrap">
                                                  <div className="form-group">
                                                    <div className="form-floating">
                                                      <Field
                                                        as="select"
                                                        name={
                                                          "typeTerm" +
                                                          item.lstID
                                                        }
                                                        className="form-control"
                                                      >
                                                        <option value="">
                                                          {commonservices.LabelDisplayText(
                                                            "Select"
                                                          )}
                                                        </option>
                                                        {projectItem.length >
                                                          0 &&
                                                          projectItem.map(
                                                            (item, index) =>
                                                              item.Category ===
                                                                "PasswordManagmenType" && (
                                                                <option
                                                                  key={index}
                                                                  value={
                                                                    item.Term
                                                                  }
                                                                >
                                                                  {item.Term}
                                                                </option>
                                                              )
                                                          )}
                                                      </Field>
                                                      <label>
                                                        {commonservices.LabelDisplayText(
                                                          "Type"
                                                        )}
                                                      </label>
                                                    </div>
                                                  </div>
                                                </td>
                                                <td className="padding-top-bottom text-nowrap">
                                                  <div className="form-group">
                                                    <div className="form-floating">
                                                      <Field
                                                        type="text"
                                                        name={
                                                          "userName" +
                                                          item.lstID
                                                        }
                                                        className="form-control"
                                                        placeholder={commonservices.LabelDisplayText(
                                                          "UserName"
                                                        )}
                                                        maxLength={167}
                                                      />
                                                      <label>
                                                        {commonservices.LabelDisplayText(
                                                          "UserName"
                                                        )}
                                                      </label>
                                                    </div>
                                                  </div>
                                                </td>
                                                <td className="padding-top-bottom text-nowrap">
                                                  <div className="form-group">
                                                    <div className="form-floating">
                                                      <Field
                                                        type="password"
                                                        name={
                                                          "password" +
                                                          item.lstID
                                                        }
                                                        className="form-control"
                                                        placeholder="password"
                                                        maxLength={167}
                                                      />
                                                      <label>
                                                        {commonservices.LabelDisplayText(
                                                          "Password"
                                                        )}
                                                      </label>
                                                    </div>
                                                  </div>
                                                </td>
                                                <td className="padding-top-bottom text-nowrap">
                                                  <div className="form-group">
                                                    <div className="form-floating">
                                                      <Field
                                                        name={
                                                          "details" + item.lstID
                                                        }
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={commonservices.LabelDisplayText(
                                                          "Details"
                                                        )}
                                                        maxLength={500}
                                                      />
                                                      <label>
                                                        {commonservices.LabelDisplayText(
                                                          "Details"
                                                        )}
                                                      </label>
                                                    </div>
                                                  </div>
                                                </td>
                                              </>
                                            ) : (
                                              <>
                                                <td className="padding-top-bottom text-nowrap">
                                                  <div className="common-class">
                                                    {item.typeTerm}
                                                  </div>
                                                </td>
                                                <td className="padding-top-bottom text-nowrap">
                                                  <div className="common-class common-name">
                                                    {item.userName}
                                                  </div>
                                                </td>
                                                <td className="padding-top-bottom text-nowrap">
                                                  <div className="d-flex">
                                                    <div className="password-dots">
                                                      <i className="fa-solid fa-period"></i>
                                                      <i className="fa-solid fa-period"></i>
                                                      <i className="fa-solid fa-period"></i>
                                                      <i className="fa-solid fa-period"></i>
                                                      <i className="fa-solid fa-period"></i>
                                                      <i className="fa-solid fa-period"></i>
                                                      <i className="fa-solid fa-period"></i>
                                                      <i className="fa-solid fa-period"></i>
                                                      <i className="fa-solid fa-period"></i>
                                                      <i className="fa-solid fa-period"></i>
                                                    </div>
                                                  </div>
                                                </td>
                                                <td className="padding-top-bottom text-nowrap">
                                                  <p className="common-class detail-description mb-0">
                                                    {item.details}
                                                  </p>
                                                </td>
                                              </>
                                            )}

                                            <td className="text-end text-nowrap">
                                              <Button
                                                className="icon-btn"
                                                title="Delete"
                                                onClick={(e) => {
                                                  confirmAlert({
                                                    message:
                                                      commonservices.LabelDisplayText(
                                                        "AreYouSureToDoDelete"
                                                      ),
                                                    buttons: [
                                                      {
                                                        label:
                                                          commonservices.LabelDisplayText(
                                                            "Yes"
                                                          ),
                                                        onClick: () =>
                                                          removepassFields(
                                                            index,
                                                            item.lstID,
                                                            item.passId
                                                          ),
                                                      },
                                                      {
                                                        label:
                                                          commonservices.LabelDisplayText(
                                                            "No"
                                                          ),
                                                      },
                                                    ],
                                                  });
                                                }}
                                              >
                                                <Trash />
                                              </Button>
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </Table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Container>
                      <Modal.Footer>
                        <Button
                          variant="secondary"
                          className="m-0"
                          title="Previous"
                          onClick={(e) => setgeneral("documents")}
                        >
                          {commonservices.LabelDisplayText("Previous")}
                        </Button>
                        <Button
                          variant="primary"
                          className="m-0 me-2"
                          type="submit"
                          title="Next"
                          disabled={Submitting}
                        >
                          {Submitting && (
                            <>
                              <Spinner animation="border" />
                            </>
                          )}
                          {commonservices.LabelDisplayText("Save")}
                        </Button>
                      </Modal.Footer>
                    </Tab>
                  </Tabs>
                </Container>
              </Modal.Body>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default AddEmployeeModal;
