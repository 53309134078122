import React, { useEffect } from 'react'
import {
    Route,
    BrowserRouter as Router,
    Routes
} from "react-router-dom";

import Login from './views/Login/Login';
import Forgot from './views/Login/Forgot';
import UpdatePassword from './views/Login/UpdatePassword';
import $ from 'jquery';
import 'datatables.net';
import Layout from './layout/Layout';
import { ToastContainer } from "react-toastify";
import ViewFilePopup from './views/FileViewPopup/FileViewPopup';


function App() {    
    
    return (
        <>

        <ViewFilePopup/>
        <ToastContainer theme="colored" 
         containerId="an id"
         draggable={false}
         pauseOnFocusLoss={false}
         autoClose={3000}
        />

        <Router>            
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/forgot" element={<Forgot />} />
                <Route path="/updatepassword" element={<UpdatePassword />} />
                <Route path="/*" element={<Layout />}>
                </Route>
            </Routes>
        </Router>
        
        </>
    );
}

export default App;
