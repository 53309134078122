import React from "react";
import Form from "react-bootstrap/Form";
import { useState, useEffect } from "react";
import UserPlaceholder from "../assets/Icons/placeholder.svg";
import { useNavigate, Link } from "react-router-dom";
import { commonservices, AppConfigData } from "../Services/commonService";
import TaskTimer from "../views/Timer/TaskTimer";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import axios from "axios";
import { toast } from "react-toastify";
import ProjectUserList from "../assets/Images/project-user-list.png";
import { ReactComponent as NotificationBell } from "../assets/Icons/notification-bell.svg";
import Card from "react-bootstrap/Card";
import Loader, { LoaderType } from "../Loader";

function Header() {
  let data = commonservices.getLoginUserData();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [commonSearchModule, setCommonSearchModule] = React.useState(false);
  const [searchInput, setSearchInput] = React.useState("");
  const [TimerData, setTimerData] = useState([]);

  const [employeeData, setemployeeData] = useState({
    Data: [],
    IsLoading: true,
  });

  const [projectData, setprojectData] = useState({
    Data: [],
    IsLoading: true,
  });

  function getHeaderSearchData() {
    setCommonSearchModule(true);

    setemployeeData({
      Data: [],
      IsLoading: true,
    });

    setprojectData({
      Data: [],
      IsLoading: true,
    });

    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    axios
      .post(
        `${AppConfigData.APIBasePath}v1/employees/getlist`,
        {
          firstName: "",
          lastName: "",
          middleName: "",
          mobileNo: "",
          emplyeesNo: "",
          emailID: "",
          adharNo: "",
          pancardNo: "",
          searchText: searchInput,
        },
        axiosConfig
      )
      .then((res) => {
        if (res.data.status_code === 0) {
          setemployeeData({ Data: [], IsLoading: false });
        } else if (res.data.status_code === 1) {
          setemployeeData({ Data: res.data.data, IsLoading: false });
        } else if (res.data.status_code === 2) {
          commonservices.ClearLoginDetails();
          navigate("/");
        } else {
        }
      })
      .catch((err) => {});

    axios
      .post(
        `${AppConfigData.APIBasePath}v1/project/getlist`,
        {
          projectName: "",
          startDate: null,
          endDate: null,
          status: "",
          technologies: "",
          searchText: searchInput,
        },
        axiosConfig
      )
      .then((res) => {
        if (res.data.status_code === 0) {
          setprojectData({ Data: [], IsLoading: false });
        } else if (res.data.status_code === 1) {
          let projectList = res.data.data.projectList;
          if (
            res.data.data.projectUser !== null &&
            res.data.data.projectUser.length > 0
          ) {
            for (let i = 0; i < projectList.length; i++) {
              projectList[i].NoOfUser = res.data.data.projectUser.filter(
                (p) => p.ProjectID === projectList[i].ProjectID
              ).length;
            }
          }
          setprojectData({ Data: projectList, IsLoading: false });
        } else if (res.data.status_code === 2) {
          commonservices.ClearLoginDetails();
          navigate("/");
        } else {
        }
      })
      .catch((err) => {});
  }

  useEffect(() => {
    if (localStorage.getItem("token") === null) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (
      data != null &&
      data != null &&
      data.length !== 0 &&
      data[0].UserID !== 0
    ) {
      const connect = new HubConnectionBuilder()
        .withUrl(AppConfigData.SignalRHubPath, { withCredentials: false })
        .withAutomaticReconnect()
        .build();
      connect
        .start()
        .then(() => {
          connect.on("TaskStartStop", (data) => {
            let userdata = commonservices.getLoginUserData();
            if (data.userID === userdata[0].UserID) {
              const axiosConfig = {
                headers: {
                  Accept: "application/json",
                  "content-type": "multipart/form-data",
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              };
              axios
                .post(
                  `${AppConfigData.APIBasePath}v1/timer/getrunningtaskdetails`,
                  {},
                  axiosConfig
                )
                .then((res) => {
                  if (res.data.status_code === 1) {
                    setTimerData(res.data.data);                    
                  } else if (res.data.status_code === 2) {
                    commonservices.ClearLoginDetails();
                    navigate("/");
                  } else if (res.data.status_code === 0) {
                    setTimerData([]);
                  }
                })
                .catch((err) => {});
            }
          });
          connect.on("PushNotification", (data) => {
            let userdata = commonservices.getLoginUserData();
            if (data.userID === userdata[0].UserID) {
              toast(
                <>
                  <div className="card-title h6">{data.title}</div>
                  <p className="card-text pt-2">{data.message}</p>
                </>,
                {
                  position: "bottom-right",
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: false,
                  progress: undefined,
                  theme: "dark",
                }
              );
            }
          });
          connect.send("connect", data[0].UserID);
          connect.send("TaskStartStop", { UserID: data[0].UserID });
        })
        .catch((error) => {});
    }
  }, []);

  const concernedElement = document.querySelector(".notification");
  document.addEventListener("click", (event) => {
    if (concernedElement !== null) {
      if (
        !concernedElement.contains(event.target) &&
        document.contains(event.target)
      ) {
        setIsOpen(false);
      }
    }
  });

  function CloseClearHeaderSearch() {
    setCommonSearchModule(false);
    setSearchInput("");
    setemployeeData({ Data: [], IsLoading: false });
    setprojectData({ Data: [], IsLoading: false });
  }
  const handleChange = (e) => {
    setSearchInput(e.target.value);
    //if (e.target.value.length >= 3) {
    //    SearchInterval = setInterval(function () {
    //        clearInterval(SearchInterval);
    //        setCommonSearchModule(true);
    //        getHeaderSearchData();
    //    }, 1000)
    //}
  };

  const handleSearch = (e) => {
    if (e.keyCode === 27) {
      CloseClearHeaderSearch();
    } else {
      if (
        e.target.value !== null &&
        e.target.value !== undefined &&
        e.target.value !== ""
      ) {
        if (e.keyCode === 13) {
          setCommonSearchModule(true);
          getHeaderSearchData();
        }
        //if (e.target.value.length >= 3) {
        //    if (e.keyCode === 13) {
        //        setCommonSearchModule(true);
        //        getHeaderSearchData();
        //    }
        //    setSearchInput(e.target.value)
        //    if (!commonSearchModule) {
        //        setCommonSearchModule(true);
        //        getHeaderSearchData();
        //    }
        //    else {
        //        if (e.keyCode === 13) {
        //            setCommonSearchModule(true);
        //            getHeaderSearchData();
        //        }
        //    }
        //}
      } else {
        CloseClearHeaderSearch();
      }
    }
  };

  function fnRedirectToProfile(id) {
    CloseClearHeaderSearch();
    navigate("/profile/" + id);
  }

  function fnRedirectToProject(id) {
    CloseClearHeaderSearch();
    navigate("/project-details/" + id);
  }

  return (
    <>
      <div className="header">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-xxl-8 col-xl-9 col-lg-7 col-md-7">
              <div className="d-flex align-items-center h-100">
                <div className="header-search">
                  <div className="wrapper">
                    <div className="search-input z-adjust">
                      <Form.Control
                        id={"HeaderCommonSearch"}
                        placeholder="Search..."
                        aria-label="Search..."
                        aria-describedby="basic-addon1"
                        onChange={(e) => handleChange(e)}
                        onKeyUp={(e) => handleSearch(e)}
                        value={searchInput}
                      />
                      <div
                        className="icon"
                        onClick={(e) => getHeaderSearchData(e)}
                      >
                        <i className="fa-light fa-magnifying-glass"></i>
                      </div>
                    </div>
                    {commonSearchModule && (
                      <div className="header-search-box">
                        <div className="top-header">
                          <div className="header-search-div">
                            <button
                              className="btn-close"
                              onClick={() => {
                                CloseClearHeaderSearch();
                              }}
                              type="button"
                              title="close"
                            ></button>
                          </div>
                          <div className="container">
                            {employeeData.IsLoading && projectData.IsLoading ? (
                              <div className="header-search-modal mb-3">
                                <div className="row">
                                  <Loader
                                    type={LoaderType.HEADERSEARCH}
                                    count={8}
                                  />
                                </div>
                              </div>
                            ) : (
                              <>
                                {employeeData.Data.length > 0 ||
                                projectData.Data.length > 0 ? (
                                  <>
                                    {employeeData.Data.length > 0 && (
                                      <div className="header-search-modal mb-3">
                                        <h2 className="col-block-title">
                                          Employees
                                        </h2>
                                        <div className="row">
                                          {employeeData.Data.map(
                                            (item, index) => (
                                              <div
                                                className="mb-3 col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-12"
                                                onClick={(e) =>
                                                  fnRedirectToProfile(
                                                    item.EmployeeID
                                                  )
                                                }
                                              >
                                                <div className="card-col d-flex">
                                                  <Card.Body className="">
                                                    <div className="emplo-img d-flex justify-content-between align-items-start">
                                                      <img
                                                        className="project-img m-0"
                                                        src={item.ProfilePic}
                                                        alt={commonservices.getInitials(
                                                          item.FirstName +
                                                            " " +
                                                            item.LastName
                                                        )}
                                                        width={65}
                                                        height={65}
                                                      />
                                                      <div className="department ms-2">
                                                        {item.Department}
                                                      </div>
                                                    </div>
                                                    <Card.Title className="mb-1 d-flex align-items-center text-capitalize justify-content-between">
                                                      {item.FirstName}{" "}
                                                      {item.LastName}
                                                    </Card.Title>
                                                    <div className="emplo-details d-flex flex-wrap">
                                                      <label className="lables project-lable d-flex align-items-center justify-content-center mt-2 me-2">
                                                        {item.TotalProject}
                                                        <div className="content text-capitalize ms-1">
                                                          projects
                                                        </div>
                                                      </label>
                                                      <label className="lables project-lable d-flex align-items-center justify-content-center mt-2 me-2">
                                                        {item.TotalTask}
                                                        <div className="content text-capitalize ms-1">
                                                          task
                                                        </div>
                                                      </label>
                                                    </div>
                                                  </Card.Body>
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    )}
                                    {projectData.Data.length > 0 && (
                                      <div className="header-search-modal mb-3">
                                        <h2 className="col-block-title">
                                          Project
                                        </h2>
                                        <div className="row">
                                          {projectData.Data.map(
                                            (item, index) => (
                                              <div
                                                className="mb-3 col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-12"
                                                onClick={(e) =>
                                                  fnRedirectToProject(
                                                    item.ProjectID
                                                  )
                                                }
                                              >
                                                <div className="card-col d-flex">
                                                  <Card.Body className="">
                                                    <div className="emplo-img d-flex justify-content-between align-items-start">
                                                      <img
                                                        className="project-img m-0"
                                                        src={item.ProjectLogo}                                                        
                                                        width={65}
                                                        height={65}
                                                        alt={commonservices.getInitials(
                                                          item.ProjectName
                                                        )}
                                                      />
                                                      <div className="department ms-2">
                                                        {item.Status}
                                                      </div>
                                                    </div>
                                                    <Card.Title className="mb-1 d-flex align-items-center text-capitalize justify-content-between">
                                                      {item.ProjectName}
                                                    </Card.Title>
                                                    <div className="emplo-details d-flex flex-wrap">
                                                      <label className="lables project-lable d-flex align-items-center justify-content-center mt-2 me-2">
                                                        {item.NoOfUser === null
                                                          ? 0
                                                          : item.NoOfUser}
                                                        <div className="content text-capitalize ms-1">
                                                          Members
                                                        </div>
                                                      </label>
                                                      <label className="lables project-lable d-flex align-items-center justify-content-center mt-2 me-2">
                                                        {item.AllTaskCount}
                                                        <div className="content text-capitalize ms-1">
                                                          task
                                                        </div>
                                                      </label>
                                                    </div>
                                                  </Card.Body>
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <Loader
                                      type={LoaderType.NODATAFOUND_WHITE}
                                    />
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {TimerData.length > 0 && <TaskTimer details={TimerData} />}
              </div>
            </div>
            <div className="col-xxl-4 col-xl-3 col-lg-5 col-md-5 mt-auto mb-auto">
              <div className="dashboard-title d-flex align-items-center justify-content-end">
                <div className="notification">
                  {/* <button className="notification-col bg-transparent border-0" onClick={() => setIsOpen(!isOpen)}>
                                        <NotificationBell />
                                        <span>1</span>
                                    </button> */}
                  {isOpen && (
                    <div className="custom-notification-body">
                      <div className="notification-header">Notifications</div>
                      <div className="notification-lists">
                        <div className="notification-list">
                          <img
                            className="notification-img rounded-circle"
                            src={ProjectUserList}
                            alt=""
                            width="40"
                            height="40"
                          />
                          <div className="notification-content-col">
                            <div className="notification-name">
                              Farah Khan joined to final presentation
                            </div>
                            <div className="notification-time">1h ago</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="d-flex align-items-center justify-content-end">
                  <div className="text-end headadmin">
                    <span className="dashboard-top-title">
                      {commonservices.getUserLoginMessage()}
                    </span>
                    <span className="dashboard-main-title d-block">
                      {data[0].DisplayName}
                    </span>
                  </div>
                  {data[0].AssociationType.toLocaleUpperCase() ==
                  "SUPER ADMIN" ? (
                    <span className="user-profile d-block">
                      <img
                        className="user-profile-img rounded-circle me-0"
                        title={data[0].DisplayName}
                        src={
                          data[0].ProfilePic !== null &&
                          data[0].ProfilePic !== ""
                            ? data[0].ProfilePic
                            : UserPlaceholder
                        }
                        alt={commonservices.getInitials(data[0].DisplayName)}
                        width="60"
                        height="60"
                      />
                    </span>
                  ) : (
                    <Link
                      to={{ pathname: "/profile/" + data[0].AssociationID }}
                      className="user-profile-col"
                    >
                      <span className="user-profile d-block">
                        <img
                          className="user-profile-img rounded-circle me-0"
                          title={data[0].DisplayName}
                          src={
                            data[0].ProfilePic !== null &&
                            data[0].ProfilePic !== ""
                              ? data[0].ProfilePic
                              : UserPlaceholder
                          }
                          alt={commonservices.getInitials(data[0].DisplayName)}
                          width="60"
                          height="60"
                        />
                      </span>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
