import React, { useEffect, useState } from "react";
import { Tab, Row, Nav, Col, Container } from "react-bootstrap";

import { ReactComponent as NextIcon } from "../../assets/Icons/next.svg";
import UserWiseDailyReport from "./ReportsComponents/UserWiseDailyReport";
import UserwWseMonthlyReport from "./ReportsComponents/UserwWseMonthlyReport";
import ProjectrWiseUserReport from "./ReportsComponents/ProjectrWiseUserReport";
import TaskWiseUserReport from "./ReportsComponents/TaskWiseUserReport";
import UserWiseTaskListReport from "./ReportsComponents/UserWiseTaskListReport";
import EmployeeWiseLeaveReport from "./ReportsComponents/EmployeeWiseLeaveReport";


function Reports() {
    const [TabActiveKey, setTabActiveKey] = useState("1");

    return (
        <>
            <div className="setting-page">
                <Tab.Container id="left-tabs-example" activeKey={TabActiveKey}>
                    <Row className="m-0">
                        <Col sm={4} className="ps-0 pe-xl-4 pe-lg-4 pe-md-4 pe-sm-0">
                            <div className="table-section-col setting-page-tab">
                                <div className="setting-left-sidebar-title">Reports</div>
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item onClick={(e) => setTabActiveKey("1")}>
                                        <Nav.Link
                                            eventKey="1"
                                            className="d-flex align-items-center justify-content-between"
                                        >
                                            User Wise Daily Working Hours Report <NextIcon />
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item onClick={(e) => setTabActiveKey("2")}>
                                        <Nav.Link
                                            eventKey="2"
                                            className="d-flex align-items-center justify-content-between"
                                        >
                                            User Wise Monthly Working Hours Report <NextIcon />
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item onClick={(e) => setTabActiveKey("3")}>
                                        <Nav.Link
                                            eventKey="3"
                                            className="d-flex align-items-center justify-content-between"
                                        >
                                            Project Wise User Working Hours Report <NextIcon />
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item onClick={(e) => setTabActiveKey("4")}>
                                        <Nav.Link
                                            eventKey="4"
                                            className="d-flex align-items-center justify-content-between"
                                        >
                                            Task Wise User Working Hours Report <NextIcon />
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item onClick={(e) => setTabActiveKey("5")}>
                                        <Nav.Link
                                            eventKey="5"
                                            className="d-flex align-items-center justify-content-between"
                                        >
                                            User Wise Task List Report <NextIcon />
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item onClick={(e) => setTabActiveKey("6")}>
                                        <Nav.Link
                                            eventKey="6"
                                            className="d-flex align-items-center justify-content-between"
                                        >
                                            Employee Wise Leave Report <NextIcon />
                                        </Nav.Link>
                                    </Nav.Item>

                                </Nav>
                            </div>
                        </Col>
                        <Col sm={8} className="pe-0 pe-xl-0 pe-lg-0 pe-md-0 ps-sm-0">
                            <Tab.Content>
                                {TabActiveKey === "1" && (
                                    <Tab.Pane eventKey="1">
                                        <Container fluid className="p-0">
                                            <UserWiseDailyReport />
                                        </Container>
                                    </Tab.Pane>
                                )}
                                {TabActiveKey === "2" && (
                                    <Tab.Pane eventKey="2">
                                        <Container fluid className="p-0">
                                            <UserwWseMonthlyReport />
                                        </Container>
                                    </Tab.Pane>
                                )}
                                {TabActiveKey === "3" && (
                                    <Tab.Pane eventKey="3">
                                        <Container fluid className="p-0">
                                            <ProjectrWiseUserReport />
                                        </Container>
                                    </Tab.Pane>
                                )}
                                {TabActiveKey === "4" && (
                                    <Tab.Pane eventKey="4">
                                        <Container fluid className="p-0">
                                            <TaskWiseUserReport />
                                        </Container>
                                    </Tab.Pane>
                                )}
                                {TabActiveKey === "5" && (
                                    <Tab.Pane eventKey="5">
                                        <Container fluid className="p-0">
                                            <UserWiseTaskListReport />
                                        </Container>
                                    </Tab.Pane>
                                )}
                                {TabActiveKey === "6" && (
                                    <Tab.Pane eventKey="6">
                                        <Container fluid className="p-0">
                                            <EmployeeWiseLeaveReport />
                                        </Container>
                                    </Tab.Pane>
                                )}
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
        </>
    );
}

export default Reports;
