import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col, Container } from "react-bootstrap";
import { commonservices, AppConfigData } from "../Services/commonService";
import axios from "axios";
import { toast } from "react-toastify";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as yup from "yup";
const EditUserModal = (props) => {
    // console.log("props ::",props);
  
  const [UserRoleData, setuserRoleData] = React.useState(false);
  const formInitialSchema = {
    RoleID: "",
  };
  const [IsValidationMessageSet, setIsValidationMessageSet] = useState("");

  useEffect(() => {
    setIsValidationMessageSet(true);
  });

  const formValidation = yup.object().shape({
    RoleID: yup
      .string()
      .required(commonservices.MakeValidationMessage("required", "RoleID")),
  });
  useEffect(() => {
    GetRoleRightsList(props.id);
    if (
      props.EditUserData !== "" &&
      props.EditUserData !== undefined &&
      props.EditUserData !== null
    ) {
    }
  }, []);

  //Region GetUserRole
  function GetRoleRightsList(UserID) {
    let Data = {
      UserID: UserID,
      requestFrom: "Web",
    };
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(
        `${AppConfigData.APIBasePath}v1/user/GetUserRole`,
        Data,
        axiosConfig
      )
      .then((res) => {
        if (res.data.status_code === 0) {
        } else if (res.data.status_code === 1) {
          setuserRoleData(res.data.data);
        } else if (res.data.status_code === 2) {
        } else {
        }
      })
      .catch((err) => {});
  }
  //End Region
  return (
    <>
      <Modal
        {...props}
        size="xs"
        aria-labelledby="contained-modal-title-vcenter"
        // centered
        className="right-modal modal-new project-term-modal"
        scrollable={true}
        backdrop="static"
        keyboard={false}
      >
        <Formik
          enableReinitialize
          initialValues={formInitialSchema}
          validationSchema={formValidation}
          onSubmit={async (values) => {
            const formData = new FormData();
            formData.append("RoleID", values.RoleID);
            formData.append("UserID",  props.EditUserData !== "" &&
            props.EditUserData !== undefined &&
            props.EditUserData !== null &&  props.EditUserData.UserID);
            const axiosConfig = {
              headers: {
                "content-type": "multipart/form-data",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            };
            const response = await axios
              .post(
                `${AppConfigData.APIBasePath}v1/user/UpdateUserRole`,
                formData,
                axiosConfig
              )
              .then((res) => {
                if (res.data.status_code === 0) {
                  toast.error(res.data.message);
                } else if (res.data.status_code === 1) {
                  toast.success(res.data.message);
                  //setTimeout(() => {
                  //    props.onHide()
                  //  }, 1000);
                  props.bindList();
                  props.onHide();
                } else if (res.data.status_code === 2) {
                  toast.error(res.data.message);
                } else {
                  toast.error(res.data.message);
                }
              })
              .catch((error) => {});
          }}
        >
          <Form>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {commonservices.LabelDisplayText("UserRoleRights")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5 style={{ marginLeft: "10px" }}>
                <b>{props.EditUserData.DisplayName}</b>
              </h5>
              <Container style={{ marginTop: "15px" }}>
                <Row>
                  <Col xs={12}>
                    <div className="form-floating">
                      <Field
                        as="select"
                        name="RoleID"
                        className="form-control custom-select"
                      >
                        <option
                        //   value={
                        //     props.EditUserData !== "" &&
                        //     props.EditUserData !== undefined &&
                        //     props.EditUserData !== null
                        //       ? props.EditUserData.AssociationID
                        //       : ""
                        //   }
                        >
                          {props.EditUserData !== "" &&
                          props.EditUserData !== undefined &&
                          props.EditUserData !== null
                            ? props.EditUserData.RoleName
                            : commonservices.LabelDisplayText("Select")}
                        </option>
                        {UserRoleData.length > 0 &&
                          UserRoleData.map((item, index) => (
                            <option key={index} value={item.RoleID}>
                              {item.RoleName}
                            </option>
                          ))}
                      </Field>
                      <label lbl-for="RoleID">
                        {commonservices.LabelDisplayText("UserRole")}
                      </label>

                      <p className="error-msg" style={{ color: "red" }}>
                        <ErrorMessage name="RoleID" />
                      </p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={props.onHide} className="Cancel-btn" title="Close"> 
                {commonservices.LabelDisplayText("Close")}
              </Button>
              <Button className="btn btn-primary save-btn" type="submit" title="Submit">
                {commonservices.LabelDisplayText("Save")}
              </Button>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </>
  );
};
export default EditUserModal;
