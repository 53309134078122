import axios from "axios";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { commonservices, AppConfigData } from "../Services/commonService";
import UserPlaceholder from "../assets/Icons/placeholder.svg";
import {
    Col,
    Modal,
    Button,
    Container,
    Row,
    Tabs,
    Tab,
    Table,
} from "react-bootstrap";
import { viewFilePopupService } from "../Services/viewFilePopup.service";
import Select from "react-select";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useNavigate } from "react-router-dom";
import Loader, { LoaderType } from "../Loader";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { confirmAlert } from "react-confirm-alert";
import Spinner from "react-bootstrap/Spinner";
import { HubConnectionBuilder } from "@microsoft/signalr";

const TaskDetail = (props) => {
    const navigate = useNavigate();
    const [_projectTerm, setProjectTerm] = useState([]);
    const [_projectUserList, setProjectUserList] = useState([]);
    const [_task, setTask] = useState([]);
    const [_tasksubpoints, setTaskSubpoints] = useState([]);
    const [Submitting, setSubmitting] = useState(false);
    const [_taskuser, setTaskUser] = useState([]);
    const [_taskdocuments, setTaskDocuments] = useState([]);
    const [TimeDetailsData, setTimeDetailsData] = useState([]);
    const [_assigntouser, setAssigntouser] = useState(null);
    const [_taskLog, setTaskLog] = useState([]);
    const [_taskComment, setTaskComment] = useState({
        Data: [],
        IsLoading: true,
    });
    const [_taskCommentDocument, setTaskCommentDocument] = useState([]);
    const [addCommentsValidationMessage, setAddCommentsValidationMessage] =
        useState("");
    const [AddCommentAPICall, setAddCommentAPICall] = useState(false);
    const [editField, setEditField] = useState("");
    const [IsValidationMessageSet, setIsValidationMessageSet] = useState("");

    useEffect(() => {
        setIsValidationMessageSet(true);
    });
    let data = commonservices.getLoginUserData();
    const [_taskuserIDs, setTaskUserIDs] = useState([]);
    function BindProjectTermData() {
        setProjectTerm([]);
        const axiosConfig = {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        };
        axios
            .post(
                `${AppConfigData.APIBasePath}v1/projectterm/getlist`,
                {
                    term: "",
                    category: "TaskStatus,TaskPriority",
                },
                axiosConfig
            )
            .then((res) => {
                if (res.data.status_code === 1) {
                    setProjectTerm(res.data.data);
                } else if (res.data.status_code === 2) {
                    localStorage.removeItem("token");
                    navigate("/");
                }
            })
            .catch((err) => { });
    }
    function BindTimeDetailsData(ProjectID, TaskID) {
        // setProjectTerm([]);
        const axiosConfig = {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        };
        axios
            .post(
                `${AppConfigData.APIBasePath}v1/task/getlisttaskdatewisetimeandusertasktimer`,
                {
                    taskID: TaskID,
                    projectID: ProjectID,
                },
                axiosConfig
            )
            .then((res) => {
                if (res.data.status_code === 1) {
                    setTimeDetailsData(res.data.data);

                } else if (res.data.status_code === 2) {
                    localStorage.removeItem("token");
                    navigate("/");
                }
            })
            .catch((err) => { });
    }

    function BindProjectUserData(ProjectID) {
        setProjectUserList([]);
        const axiosConfig = {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        };
        axios
            .post(
                `${AppConfigData.APIBasePath}v1/projectuser/getlist`,
                {
                    id: ProjectID,
                    requestFrom: "web",
                },
                axiosConfig
            )
            .then((res) => {
                if (res.data.status_code === 1) {
                    let optionUser = [];
                    res.data.data.map((item, index) =>
                        optionUser.push({
                            label: item.DisplayName,
                            value: item.UserID,
                        })
                    );
                    setProjectUserList(optionUser);
                } else if (res.data.status_code === 2) {
                    localStorage.removeItem("token");
                    navigate("/");
                }
            })
            .catch((err) => { });
    }

    function BindTaskDetails(TaskID) {
        const axiosConfig = {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        };
        axios
            .post(
                `${AppConfigData.APIBasePath}v1/task/getdetailsbyid`,
                {
                    id: TaskID,
                    requestFrom: "Web",
                },
                axiosConfig
            )
            .then((res) => {
                if (res.data.status_code === 1) {
                    setTask([res.data.data.task]);
                    setTaskSubpoints(res.data.data.taskSubpoints);
                    setTaskUser(res.data.data.taskUser);
                    setTaskDocuments(res.data.data.documents);
                    BindTaskCommnetLog(true, TaskID);
                    let UserIDs = [];
                    res.data.data.taskUser.map(
                        (item, index) => item.IsAssign > 0 && UserIDs.push(item.UserID)
                    );
                    setTaskUserIDs(UserIDs);
                } else if (res.data.status_code === 2) {
                    localStorage.removeItem("token");
                    navigate("/");
                }
            })
            .catch((err) => { });
    }

    function fnAssignToUserChange(e) {
        setAssigntouser(e);
        const axiosConfig = {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        };
        axios
            .post(
                `${AppConfigData.APIBasePath}v1/taskuser/save`,
                [
                    {
                        taskUserID: 0,
                        taskID: _task[0].TaskID,
                        userID: e.value,
                        projectID: _task[0].ProjectID,
                        isActive: true,
                        isAssign: true,
                        createdBy: 0,
                        createdOn: null,
                        name: e.label,
                    },
                ],
                axiosConfig
            )
            .then((res) => {
                setAssigntouser(null);
                if (res.data.status_code === 1) {
                    if (data[0].UserID !== e.value) {
                        commonservices.SendPushNotification([
                            {
                                UserID: e.value,
                                Message: _task[0].Title,
                                Title: "New Task Assign To You",
                            },
                        ]);
                    }
                    toast.success(res.data.message);
                    BindTaskDetails(_task[0].TaskID);
                } else if (res.data.status_code === 0) {
                    toast.error(res.data.message);
                } else if (res.data.status_code === 2) {
                    localStorage.removeItem("token");
                    navigate("/");
                }
            })
            .catch((err) => { });
    }

    function UpdateTaskFieldValue(fieldName, fieldValue) {
        if (fieldValue !== null && fieldValue !== undefined && fieldValue !== "") {
            setEditField("");
            const axiosConfig = {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            };
            axios
                .post(
                    `${AppConfigData.APIBasePath}v1/task/taskupdatefield`,
                    {
                        taskID: _task[0].TaskID,
                        projectID: _task[0].ProjectID,
                        fieldName: fieldName,
                        fieldValue: fieldValue,
                    },
                    axiosConfig
                )
                .then((res) => {
                    if (res.data.status_code === 1) {
                        toast.success(res.data.message);
                        BindTaskDetails(_task[0].TaskID);
                        props.bindList();
                    } else if (res.data.status_code === 0) {
                        toast.error(res.data.message);
                    } else if (res.data.status_code === 2) {
                        localStorage.removeItem("token");
                        navigate("/");
                    }
                })
                .catch((err) => { });
        }
    }

    function StatusChange(item) {
        let IsAssignToMe = _taskuser.filter(
            (tu) => tu.UserID === data[0].UserID && tu.IsAssign === 1
        );
        if (IsAssignToMe !== null && IsAssignToMe.length > 0) {
            confirmAlert({
                message: "Do you want to start stop your timer ?",
                buttons: [
                    {
                        label: "Start",
                        onClick: () => {
                            UpdateTaskFieldValue("Status", item.Term);
                            StartTimer({
                                taskID: _task[0].TaskID,
                                projectID: _task[0].ProjectID,
                                stopTaskComment: "",
                            });
                        },
                    },
                    {
                        label: "Stop",
                        onClick: () => {
                            UpdateTaskFieldValue("Status", item.Term);
                            StopTimer({
                                taskID: _task[0].TaskID,
                                projectID: _task[0].ProjectID,
                                stopTaskComment: "",
                            });
                        },
                    },
                    {
                        label: "No",
                        onClick: () => {
                            UpdateTaskFieldValue("Status", item.Term);
                        },
                    },
                ],
            });
        } else {
            UpdateTaskFieldValue("Status", item.Term);
        }
    }

    function StartTimer(input) {
        const axiosConfig = {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        };
        axios
            .post(
                `${AppConfigData.APIBasePath}v1/task/taskstarttimer`,
                input,
                axiosConfig
            )
            .then((res) => {
                if (res.data.status_code === 0) {
                } else if (res.data.status_code === 1) {
                    fnCallTaskStartStopInHub();
                } else if (res.data.status_code === 2) {
                    commonservices.ClearLoginDetails();
                    navigate("/");
                }
            })
            .catch((err) => { });
    }

    function StopTimer(input) {
        const axiosConfig = {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        };
        axios
            .post(
                `${AppConfigData.APIBasePath}v1/task/taskstoptimer`,
                input,
                axiosConfig
            )
            .then((res) => {
                if (res.data.status_code === 0) {
                } else if (res.data.status_code === 1) {
                    fnCallTaskStartStopInHub();
                } else if (res.data.status_code === 2) {
                    commonservices.ClearLoginDetails();
                    navigate("/");
                }
            })
            .catch((err) => { });
    }

    function fnCallTaskStartStopInHub() {
        let userdata = commonservices.getLoginUserData();
        const connect = new HubConnectionBuilder()
            .withUrl(AppConfigData.SignalRHubPath, { withCredentials: false })
            .withAutomaticReconnect()
            .build();
        connect
            .start()
            .then(() => {
                connect.send("TaskStartStop", { UserID: userdata[0].UserID });
            })
            .catch((error) => { });
    }

    function BindTaskCommnetLog(IsLog, TaskID) {
        if (IsLog) {
            setTaskLog([]);
        } else {
            setTaskComment({ Data: [], IsLoading: false });

            setTaskCommentDocument([]);
        }
        const axiosConfig = {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        };
        axios
            .post(
                `${AppConfigData.APIBasePath}v1/tasklogdetails/getlistbytaskid`,
                {
                    taskID: TaskID,
                    isLog: IsLog,
                    requestFrom: "web",
                },
                axiosConfig
            )
            .then((res) => {
                if (res.data.status_code === 1) {
                    if (IsLog) {
                        setTaskLog(res.data.data.taskDetails);
                    } else {
                        // setTaskComment(res.data.data.taskDetails);
                        setTaskComment({
                            Data: res.data.data.taskDetails,
                            IsLoading: false,
                        });
                        setTaskCommentDocument(res.data.data.documents);
                    }
                } else if (res.data.status_code === 2) {
                    localStorage.removeItem("token");
                    navigate("/");
                }
            })
            .catch((err) => { });
    }

    useEffect(() => {
        if (props !== null && props !== undefined) {
            if (props.details !== null && props.details !== undefined) {
                if (
                    props.details.TaskID !== null &&
                    props.details.TaskID !== undefined &&
                    props.details.ProjectID !== null &&
                    props.details.ProjectID !== undefined
                ) {
                    if (props.details.TaskID > 0 && props.details.ProjectID > 0) {
                        BindProjectTermData();
                        BindTimeDetailsData(props.details.ProjectID, props.details.TaskID);
                        BindProjectUserData(props.details.ProjectID);
                        BindTaskDetails(props.details.TaskID);
                        BindTaskCommnetLog(false, props.details.TaskID);
                    }
                }
            }
        }
    }, []);

    function fnAddNewComment() {
        setAddCommentsValidationMessage("");
        let txtAddNewComments = document.getElementById("txtAddNewComments");
        let fileAddNewComments = document.getElementById("fileAddNewComments");
        if (txtAddNewComments.value !== "" || fileAddNewComments.files.length > 0) {
            setAddCommentAPICall(true);
            const formData = new FormData();
            let txtAddNewCommentsVal = txtAddNewComments.value;
            for (let key = 0; key < fileAddNewComments.files.length; key++) {
                formData.append(
                    "lstDocuments[" + key + "].docName",
                    fileAddNewComments.files[key].name
                );
                formData.append(
                    "lstDocuments[" + key + "].file",
                    fileAddNewComments.files[key]
                );
                formData.append("lstDocuments[" + key + "].details", "");
                formData.append("lstDocuments[" + key + "].docType", "");
            }
            if (txtAddNewCommentsVal === "" && fileAddNewComments.files.length > 0) {
                txtAddNewCommentsVal = fileAddNewComments.files.length + " Files Added";
            }
            formData.append("TaskLogDetailID", 0);
            formData.append("Details", txtAddNewCommentsVal);
            formData.append("IsActive", true);
            formData.append("TaskID", _task[0].TaskID);
            formData.append("ProjectID", _task[0].ProjectID);
            setSubmitting(true);
            const axiosConfig = {
                headers: {
                    "content-type": "multipart/form-data",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            };
            axios
                .post(
                    `${AppConfigData.APIBasePath}v1/tasklogdetails/save`,
                    formData,
                    axiosConfig
                )
                .then((res) => {
                    setSubmitting(false);

                    setAddCommentAPICall(false);
                    if (res.data.status_code === 1) {
                        toast.success(res.data.message);
                        txtAddNewComments.value = "";
                        fileAddNewComments.value = "";
                        BindTaskCommnetLog(false, _task[0].TaskID);
                        BindTaskCommnetLog(true, _task[0].TaskID);
                    } else if (res.data.status_code === 0) {
                        toast.error(res.data.message);
                    } else if (res.data.status_code === 2) {
                        localStorage.removeItem("token");
                        navigate("/");
                    }
                })
                .catch((err) => { });
        } else {
            setAddCommentsValidationMessage(
                commonservices.LabelDisplayText("AddCommentsOrSelectAtLeastOneFile")
            );
        }
    }

    function fnTaskSubPointFlagChange(event, ID) {
        const axiosConfig = {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        };
        axios
            .post(
                `${AppConfigData.APIBasePath}v1/tasksubpoint/checkuncheck`,
                {
                    taskSubPointID: ID,
                    isDone: event.target.checked,
                    requestFrom: "web",
                },
                axiosConfig
            )
            .then((res) => {
                if (res.data.status_code === 1) {
                    toast.success(res.data.message);
                    BindTaskDetails(_task[0].TaskID);
                    BindTaskCommnetLog(true, _task[0].TaskID);
                } else if (res.data.status_code === 0) {
                    toast.error(res.data.message);
                } else if (res.data.status_code === 2) {
                    localStorage.removeItem("token");
                    navigate("/");
                }
            })
            .catch((err) => { });
    }

    async function deleteDataFile(DocumentID, DeleteFrom) {
        const response = await commonservices.DeleteDocumentByID(
            DocumentID,
            _task[0].TaskID,
            "TASK"
        );
        if (response.data.status_code === 1) {
            if (DeleteFrom.toUpperCase() === "COMMNETFILE") {
                BindTaskCommnetLog(false, _task[0].TaskID);
                BindTaskCommnetLog(true, _task[0].TaskID);
            } else if (DeleteFrom.toUpperCase() === "TASKFILE") {
                BindTaskDetails(_task[0].TaskID);
            }
            toast.success(response.data.message);
        } else {
            toast.error(response.data.message);
        }
    }

    function fnDeleteCommnet(ID) {
        const axiosConfig = {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        };
        axios
            .post(
                `${AppConfigData.APIBasePath}v1/tasklogdetails/delete`,
                {
                    id: ID,
                    requestFrom: "web",
                },
                axiosConfig
            )
            .then((res) => {
                if (res.data.status_code === 1) {
                    toast.success(res.data.message);
                    BindTaskCommnetLog(false, _task[0].TaskID);
                    BindTaskCommnetLog(true, _task[0].TaskID);
                } else if (res.data.status_code === 0) {
                    toast.error(res.data.message);
                } else if (res.data.status_code === 2) {
                    localStorage.removeItem("token");
                    navigate("/");
                }
            })
            .catch((err) => { });
    }

    function ViewPostFilePopup(Files, index) {
        let PassedFiles = [];
        Files.map((itemFile, indexFile) => PassedFiles.push(itemFile));

        if (index !== 0) {
            const IndexFileObj = PassedFiles[index];
            PassedFiles.splice(index, 1);
            PassedFiles = [IndexFileObj].concat(PassedFiles);
        }
        viewFilePopupService.viewFilePopup({ data: PassedFiles });
    }

    function fnUpdateUserTime(obj, date, UpdateFor) {
        let APIPath = AppConfigData.APIBasePath + "v1/task/updateusertasktimeruserdatetime";

        let _StartTime = new Date()
        let _EndTime = new Date()

        let StartTime = "";
        let EndTime = "";

        let els = document.getElementById("userstarttime_" + obj.UserTaskTimerID);
        if (els !== null) {
            if (els.value !== null && els.value !== "") {
                StartTime = commonservices.getDateTimeInDBFormat(els.value);
                _StartTime = els.value;
            }
        }

        let ele = document.getElementById("userendtime_" + obj.UserTaskTimerID);
        if (ele !== null) {
            if (ele.value !== null && ele.value !== "") {
                EndTime = commonservices.getDateTimeInDBFormat(ele.value);
                _EndTime = ele.value;
            }
        }

        if (StartTime !== "" && EndTime !== "") {
            if (new Date(_StartTime) <= new Date() && new Date(_EndTime) <= new Date()) {
                if (new Date(_StartTime) < new Date(_EndTime)) {
                    if (StartTime !== commonservices.getDateTimeInDBFormat(obj.StartTime) || EndTime !== commonservices.getDateTimeInDBFormat(obj.EndTime)) {
                       
                        const formData = new FormData();
                        formData.append("UserTaskTimerID", obj.UserTaskTimerID);

                        formData.append("TimerDate", commonservices.getDateInDBFormat(StartTime));
                        formData.append("StartTime", StartTime);
                        formData.append("EndTime", EndTime);

                        formData.append("OldTimerDate", commonservices.getDateInDBFormat(obj.TimerDate));
                        formData.append("OldStartTime", commonservices.getDateTimeInDBFormat(obj.StartTime));
                        formData.append("OldEndTime", commonservices.getDateTimeInDBFormat(obj.EndTime));

                        formData.append("TaskID", obj.TaskID);
                        formData.append("TaskUserName", obj.DisplayName);
                        formData.append("ProjectID", obj.ProjectID);

                        const axiosConfig = {
                            headers: {
                                "content-type": "multipart/form-data",
                                Authorization: "Bearer " + localStorage.getItem("token"),
                            },
                        };

                        axios
                            .post(
                                APIPath,
                                formData,
                                axiosConfig
                            )
                            .then((res) => {
                                BindTimeDetailsData(obj.ProjectID, obj.TaskID);
                                if (res.data.status_code === 1) {
                                    toast.success(res.data.message);
                                } else if (res.data.status_code === 0) {
                                    toast.error(res.data.message);
                                } else if (res.data.status_code === 2) {
                                    localStorage.removeItem("token");
                                    navigate("/");
                                }
                            })
                            .catch((err) => { });
                    }
                }
                else {
                    toast.error("End date cannot be less then start date");
                }
            }
            else {
                toast.error("Date cannot be greater than current date time");
            }
        }
        else {
            toast.error("Time value cannot be blank");
        }
    }

    function fnUpdateClientTime(obj, date, UpdateFor) {

        let APIPath = AppConfigData.APIBasePath + "v1/task/updateusertasktimerclienttime";

        let TotalTimeInMinutes = "0";
        let OldTotalTimeInMinutes = 0;

        OldTotalTimeInMinutes = obj.TotalTimeInMinutesForClient !== null ? obj.TotalTimeInMinutesForClient : 0;
        let el = document.getElementById("client_" + obj.UserTaskTimerID);
        if (el !== null) {
            TotalTimeInMinutes = el.value;
        }

        if (TotalTimeInMinutes !== "0" && TotalTimeInMinutes !== 0) {
            if (parseInt(TotalTimeInMinutes) > 0) {
                if (OldTotalTimeInMinutes !== parseInt(TotalTimeInMinutes)) {
                    const formData = new FormData();
                    formData.append("UserTaskTimerID", obj.UserTaskTimerID);
                    formData.append("OldTotalTimeInMinutes", OldTotalTimeInMinutes);
                    formData.append("TotalTimeInMinutes", parseInt(TotalTimeInMinutes));
                    formData.append("TaskID", obj.TaskID);
                    formData.append("TaskDate", date);
                    formData.append("TaskUserName", obj.DisplayName);
                    formData.append("ProjectID", obj.ProjectID);

                    const axiosConfig = {
                        headers: {
                            "content-type": "multipart/form-data",
                            Authorization: "Bearer " + localStorage.getItem("token"),
                        },
                    };

                    axios
                        .post(
                            APIPath,
                            formData,
                            axiosConfig
                        )
                        .then((res) => {
                            BindTimeDetailsData(obj.ProjectID, obj.TaskID);
                            if (res.data.status_code === 1) {
                                toast.success(res.data.message);
                            } else if (res.data.status_code === 0) {
                                toast.error(res.data.message);
                            } else if (res.data.status_code === 2) {
                                localStorage.removeItem("token");
                                navigate("/");
                            }
                        })
                        .catch((err) => { });
                }
            }
            else {
                toast.error("Time value cannot be blank or zero");
            }
        }
        else {
            toast.error("Time value cannot be blank or zero");
        }
    }

    return (
        <>
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                className="right-modal modal-new modal-create-task"
                scrollable={true}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton className="border-0">
                    <Modal.Title id="contained-modal-title-vcenter">
                        {commonservices.LabelDisplayText("TaskDetails")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {_task.length === 0 ? (
                        <Container>
                            <Loader type={LoaderType.TaskDetails} count={1} />
                        </Container>
                    ) : (
                        <Container>
                            <Row>
                                <div className="task-detail-title editable-container taskcontantboxes">
                                    {editField.toUpperCase() === "TITLE" ? (
                                        <>
                                            <input
                                                type="text"
                                                id="txtTitle"
                                                name="txtTitle"
                                                defaultValue={_task[0].Title}
                                                className="form-control"
                                                maxLength={267}
                                            />
                                            <Button
                                                variant="edit-button"
                                                className="ms-auto"
                                                title="Save"
                                                onClick={(e) =>
                                                    UpdateTaskFieldValue(
                                                        "Title",
                                                        document.getElementById("txtTitle").value
                                                    )
                                                }
                                            >
                                                <i className="fa-regular fa-save"></i>
                                            </Button>
                                        </>
                                    ) : (
                                        <>
                                            {_task[0].Title}
                                            <Button
                                                variant="edit-button editbutton"
                                                className="ms-auto"
                                                title="Edit"
                                                onClick={(e) => setEditField("Title")}
                                            >
                                                <i className="fa-regular fa-edit"></i>
                                            </Button>
                                        </>
                                    )}
                                </div>
                                <Col className="">
                                    <div className="inner-header">
                                        {commonservices.LabelDisplayText("Details")}
                                    </div>
                                    <div className="tab-inner-box">
                                        <Row>
                                            <Col className="mb-2 col-lg-6 col-md-12">
                                                <Row>
                                                    <Col className="col-xl-4">
                                                        <div className="tab-inner-box-left">
                                                            {commonservices.LabelDisplayText("Project")}
                                                        </div>
                                                    </Col>
                                                    <Col className="col-xl-8">
                                                        <div className="tab-inner-box-right">
                                                            {_task[0].ProjectName}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col className="mb-2 col-lg-6 col-md-12">
                                                <Row>
                                                    <Col className="col-xl-4">
                                                        <div className="tab-inner-box-left">
                                                            {commonservices.LabelDisplayText("StartDate")}
                                                        </div>
                                                    </Col>
                                                    <Col className="col-xl-8">
                                                        <div className="tab-inner-box-right editable-container">
                                                            {editField.toUpperCase() === "STARTDATE" ? (
                                                                <>
                                                                    <input
                                                                        type="date"
                                                                        id="txtStartDate"
                                                                        name="txtStartDate"
                                                                        defaultValue={commonservices.getDateInDBFormat(
                                                                            _task[0].StartDate
                                                                        )}
                                                                        className="form-control"
                                                                    />
                                                                    <Button
                                                                        variant="edit-button"
                                                                        className="ms-auto"
                                                                        title="Save"
                                                                        onClick={(e) =>
                                                                            UpdateTaskFieldValue(
                                                                                "StartDate",
                                                                                document.getElementById("txtStartDate")
                                                                                    .value
                                                                            )
                                                                        }
                                                                    >
                                                                        <i className="fa-regular fa-save"></i>
                                                                    </Button>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {commonservices.getDateInFormat(
                                                                        _task[0].StartDate
                                                                    )}
                                                                    <Button
                                                                        variant="edit-button editbutton"
                                                                        className="ms-auto"
                                                                        title="Edit"
                                                                        onClick={(e) => setEditField("StartDate")}
                                                                    >
                                                                        <i className="fa-regular fa-edit"></i>
                                                                    </Button>
                                                                </>
                                                            )}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col className="mb-2 col-lg-6 col-md-12">
                                                <Row>
                                                    <Col className="col-xl-4">
                                                        <div className="tab-inner-box-left">
                                                            {commonservices.LabelDisplayText("Priority")}
                                                        </div>
                                                    </Col>
                                                    <Col className="col-xl-8">
                                                        <div className="tab-inner-box-right editable-container">
                                                            {editField.toUpperCase() === "PRIORITY" ? (
                                                                <>
                                                                    <DropdownButton
                                                                        as={ButtonGroup}
                                                                        title={_task[0].Priority}
                                                                        id={`ddlPriority`}
                                                                        variant={""}
                                                                        size={"sm"}
                                                                        className={"task-detail-status-btn"}
                                                                    >
                                                                        {_projectTerm.length !== 0 &&
                                                                            _projectTerm.map(
                                                                                (item, index) =>
                                                                                    item.Category.toUpperCase() ===
                                                                                    "TASKPRIORITY" && (
                                                                                        <Dropdown.Item
                                                                                            eventKey={index}
                                                                                            onClick={(e) =>
                                                                                                UpdateTaskFieldValue(
                                                                                                    "Priority",
                                                                                                    item.Term
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            {item.Term}
                                                                                        </Dropdown.Item>
                                                                                    )
                                                                            )}
                                                                    </DropdownButton>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {_task[0].Priority}
                                                                    <Button
                                                                        variant="edit-button editbutton"
                                                                        className="ms-auto"
                                                                        title="Edit"
                                                                        onClick={(e) => setEditField("Priority")}
                                                                    >
                                                                        <i className="fa-regular fa-edit"></i>
                                                                    </Button>
                                                                </>
                                                            )}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col className="mb-2 col-lg-6 col-md-12">
                                                <Row>
                                                    <Col className="col-xl-4">
                                                        <div className="tab-inner-box-left">
                                                            {commonservices.LabelDisplayText("EndDate")}
                                                        </div>
                                                    </Col>
                                                    <Col className="col-xl-8">
                                                        <div className="tab-inner-box-right editable-container">
                                                            {editField.toUpperCase() === "ENDDATE" ? (
                                                                <>
                                                                    <input
                                                                        type="date"
                                                                        id="txtEndDate"
                                                                        name="txtEndDate"
                                                                        defaultValue={commonservices.getDateInDBFormat(
                                                                            _task[0].EndDate
                                                                        )}
                                                                        className="form-control"
                                                                    />
                                                                    <Button
                                                                        variant="edit-button"
                                                                        className="ms-auto"
                                                                        title="Save"
                                                                        onClick={(e) =>
                                                                            UpdateTaskFieldValue(
                                                                                "EndDate",
                                                                                document.getElementById("txtEndDate")
                                                                                    .value
                                                                            )
                                                                        }
                                                                    >
                                                                        <i className="fa-regular fa-save"></i>
                                                                    </Button>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {commonservices.getDateInFormat(
                                                                        _task[0].EndDate
                                                                    )}
                                                                    <Button
                                                                        variant="edit-button editbutton"
                                                                        className="ms-auto"
                                                                        title="Edit"
                                                                        onClick={(e) => setEditField("EndDate")}
                                                                    >
                                                                        <i className="fa-regular fa-edit"></i>
                                                                    </Button>
                                                                </>
                                                            )}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col className="mb-2 col-lg-6 col-md-12">
                                                <Row>
                                                    <Col className="col-xl-4">
                                                        <div className="tab-inner-box-left">
                                                            {commonservices.LabelDisplayText("Tag")}
                                                        </div>
                                                    </Col>
                                                    <Col className="col-xl-8">
                                                        <div className="tab-inner-box-right">
                                                            {_task[0].Tag}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col className="mb-2 col-lg-6 col-md-12">
                                                <Row>
                                                    <Col className="col-xl-4">
                                                        <div className="tab-inner-box-left">
                                                            {commonservices.LabelDisplayText("Status")}
                                                        </div>
                                                    </Col>
                                                    <Col className="col-xl-8">
                                                        <div className="tab-inner-box-right">
                                                            <div className="form-floating">
                                                                <DropdownButton
                                                                    as={ButtonGroup}
                                                                    title={_task[0].Status}
                                                                    id={`ddlstatuschange`}
                                                                    variant={""}
                                                                    size={"sm"}
                                                                    className={
                                                                        "task-detail-status-btn status-btn"
                                                                    }
                                                                >
                                                                    {_projectTerm.length !== 0 &&
                                                                        _projectTerm.map(
                                                                            (item, index) =>
                                                                                item.Category.toUpperCase() ===
                                                                                "TASKSTATUS" && (
                                                                                    <Dropdown.Item
                                                                                        eventKey={index}
                                                                                        onClick={(e) =>
                                                                                            _task[0].Status !== item.Term &&
                                                                                            StatusChange(item)
                                                                                        }
                                                                                    >
                                                                                        {item.Term}
                                                                                    </Dropdown.Item>
                                                                                )
                                                                        )}
                                                                </DropdownButton>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col className="mb-2 col-12">
                                                <Row>
                                                    <Col className="col-xl-2 d-flex align-items-center">
                                                        <div className="tab-inner-box-left">
                                                            {commonservices.LabelDisplayText("AssignTo")}
                                                        </div>
                                                    </Col>
                                                    <Col className="col-xl-8">
                                                        <div className="tab-inner-box-right d-flex align-items-center">
                                                            <Select
                                                                placeholder={commonservices.LabelDisplayText(
                                                                    "Select"
                                                                )}
                                                                isSearchable={true}
                                                                name="ProjectUser"
                                                                options={_projectUserList.filter(
                                                                    (obj) => !_taskuserIDs.includes(obj.value)
                                                                )}
                                                                className="project-assign-salect"
                                                                value={_assigntouser}
                                                                onChange={(e) => fnAssignToUserChange(e)}
                                                            />
                                                            <div className="project-assign-profile ms-2">
                                                                <div className="project-details d-inline-flex">
                                                                    {_taskuser.map((item, index) => (
                                                                        <div className="form-floating relative ">
                                                                            <OverlayTrigger
                                                                                overlay={
                                                                                    <Tooltip>{item.DisplayName}</Tooltip>
                                                                                }
                                                                            >
                                                                                <img
                                                                                    className="project-img mt-0 mb-0 rounded-circle"
                                                                                    // src={item.ProfilePic}
                                                                                    src={
                                                                                        item.ProfilePic !==
                                                                                          null &&
                                                                                        item.ProfilePic !==
                                                                                          ""
                                                                                          ? item.ProfilePic
                                                                                          : UserPlaceholder
                                                                                      }
                                                                                    alt={commonservices.getInitials(
                                                                                        item.DisplayName
                                                                                    )}
                                                                                    width={44}
                                                                                    height={44}
                                                                                />
                                                                            </OverlayTrigger>
                                                                            {item.CreatedBy === data[0].UserID &&
                                                                                item.IsAssign === 1 && (
                                                                                    <Button
                                                                                        variant="btn btn-close-subpoint-icon"
                                                                                        title="Close"
                                                                                        onClick={(e) => {
                                                                                            confirmAlert({
                                                                                                message:
                                                                                                    commonservices.LabelDisplayText(
                                                                                                        "AreYouSureToDoDelete"
                                                                                                    ),
                                                                                                buttons: [
                                                                                                    {
                                                                                                        label:
                                                                                                            commonservices.LabelDisplayText(
                                                                                                                "Yes"
                                                                                                            ),
                                                                                                        onClick: function () {
                                                                                                            if (item.IsAssign === 1) {
                                                                                                                const axiosConfig = {
                                                                                                                    headers: {
                                                                                                                        Accept:
                                                                                                                            "application/json",
                                                                                                                        Authorization:
                                                                                                                            "Bearer " +
                                                                                                                            localStorage.getItem(
                                                                                                                                "token"
                                                                                                                            ),
                                                                                                                    },
                                                                                                                };
                                                                                                                axios
                                                                                                                    .post(
                                                                                                                        `${AppConfigData.APIBasePath}v1/taskuser/delete`,
                                                                                                                        {
                                                                                                                            id: item.TaskUserID,
                                                                                                                            name: item.DisplayName,
                                                                                                                            requestFrom:
                                                                                                                                "Web",
                                                                                                                        },
                                                                                                                        axiosConfig
                                                                                                                    )
                                                                                                                    .then((res) => {
                                                                                                                        if (
                                                                                                                            res.data
                                                                                                                                .status_code ===
                                                                                                                            1
                                                                                                                        ) {
                                                                                                                            toast.success(
                                                                                                                                res.data.message
                                                                                                                            );
                                                                                                                            BindTaskDetails(
                                                                                                                                _task[0].TaskID
                                                                                                                            );
                                                                                                                        } else if (
                                                                                                                            res.data
                                                                                                                                .status_code ===
                                                                                                                            0
                                                                                                                        ) {
                                                                                                                            toast.error(
                                                                                                                                res.data.message
                                                                                                                            );
                                                                                                                        } else if (
                                                                                                                            res.data
                                                                                                                                .status_code ===
                                                                                                                            2
                                                                                                                        ) {
                                                                                                                            localStorage.removeItem(
                                                                                                                                "token"
                                                                                                                            );
                                                                                                                            navigate("/");
                                                                                                                        }
                                                                                                                    })
                                                                                                                    .catch((err) => { });
                                                                                                            } else {
                                                                                                                toast.error(
                                                                                                                    "You Can Not Able To Delete This User"
                                                                                                                );
                                                                                                            }
                                                                                                        },
                                                                                                    },
                                                                                                    {
                                                                                                        label:
                                                                                                            commonservices.LabelDisplayText(
                                                                                                                "No"
                                                                                                            ),
                                                                                                    },
                                                                                                ],
                                                                                            });
                                                                                        }}
                                                                                    >
                                                                                        <i className="fa fa-close"></i>
                                                                                    </Button>
                                                                                )}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                {_tasksubpoints.length > 0 && (
                                    <Col xs={12} className="mt-2">
                                        <div className="inner-header">
                                            {commonservices.LabelDisplayText("SubPoint")}
                                        </div>
                                        <div className="tab-inner-box">
                                            <Row>
                                                {_tasksubpoints.map((item, index) => (
                                                    <Col className="mb-2 col-lg-12 position-relative sub-points">
                                                        {item.IsDone > 0 ? (
                                                            <input
                                                                type="checkbox"
                                                                id={item.TaskSubPointID}
                                                                className="me-2"
                                                                onChange={(e) =>
                                                                    fnTaskSubPointFlagChange(
                                                                        e,
                                                                        item.TaskSubPointID
                                                                    )
                                                                }
                                                                checked
                                                            />
                                                        ) : (
                                                            <input
                                                                type="checkbox"
                                                                id={item.TaskSubPointID}
                                                                className="me-2"
                                                                onChange={(e) =>
                                                                    fnTaskSubPointFlagChange(
                                                                        e,
                                                                        item.TaskSubPointID
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                        <label htmlFor={item.TaskSubPointID}>
                                                            {item.Title}
                                                        </label>
                                                        <span className="checkmark"></span>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </div>
                                    </Col>
                                )}

                                <Col xs={12} className="mt-2">
                                    <div
                                        className="form-floating taskdetailtest"
                                        dangerouslySetInnerHTML={{ __html: _task[0]?.Details?.replaceAll('\n','<br/>') }}
                                    ></div>
                                </Col>
                                <Col xs={12}>
                                    <Tabs id="uncontrolled-tab-example" className="mb-3 mt-4 p-0">
                                        <Tab eventKey="comments" title="Comments">
                                            <div className="comment-block">
                                                <div className="comments-col d-flex">
                                                    <div className="comment-profile me-3">
                                                        <img
                                                            className="comment-profile-img rounded-circle"
                                                            src={data[0].ProfilePic}
                                                            alt={commonservices.getInitials(
                                                                data[0].DisplayName
                                                            )}
                                                            title={data[0].DisplayName}
                                                            width={42}
                                                            height={42}
                                                        />
                                                    </div>
                                                    <div className="comment-field w-100">
                                                        <div className="form-floating">
                                                            <textarea
                                                                className="form-control pt-2 pb-2 h-auto"
                                                                placeholder="Add a comment......"
                                                                rows="2"
                                                                id="txtAddNewComments"
                                                                name="txtAddNewComments"
                                                            ></textarea>
                                                            <label>
                                                                {commonservices.LabelDisplayText("AddAComment")}
                                                            </label>
                                                            <div className="text-danger-error">
                                                                {addCommentsValidationMessage}
                                                            </div>
                                                        </div>
                                                        {!AddCommentAPICall && (
                                                            <div className="two-bottom-buttons">
                                                                <Button
                                                                    variant="add-file-btn p-0"
                                                                    title="Add files"
                                                                >
                                                                    <div className="documnet-attachment plus-open">
                                                                        <i className="fa-regular fa-plus"></i>
                                                                        <input
                                                                            type="file"
                                                                            id="fileAddNewComments"
                                                                            name="fileAddNewComments"
                                                                            multiple
                                                                        />
                                                                    </div>
                                                                </Button>
                                                                <Button
                                                                    variant="send-btn p-0 ms-3"
                                                                    title="Send"
                                                                    onClick={(e) => fnAddNewComment()}
                                                                    disabled={Submitting}
                                                                >
                                                                    <i className="fa-regular fa-paper-plane"></i>
                                                                </Button>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                {_taskComment.IsLoading ? (
                                                    <>
                                                        <Loader type={LoaderType.Table} count={4} />
                                                    </>
                                                ) : (
                                                    <>
                                                        {AddCommentAPICall && (
                                                            <Loader type={LoaderType.TaskComment} count={1} />
                                                        )}
                                                        {_taskComment.Data.length > 0 &&
                                                            _taskComment.Data.map((item, index) => (
                                                                <div className="comment-item mb-3 d-flex">
                                                                    <div className="comment-profile me-3">
                                                                        <img
                                                                            className="project-img mt-0 mb-0 rounded-circle"
                                                                            // src={item.ProfilePic}
                                                                            src={
                                                                                item.ProfilePic !==
                                                                                  null &&
                                                                                item.ProfilePic !==
                                                                                  ""
                                                                                  ? item.ProfilePic
                                                                                  : UserPlaceholder
                                                                              }
                                                                            alt={commonservices.getInitials(
                                                                                item.DisplayName
                                                                            )}
                                                                            width={42}
                                                                            height={42}
                                                                        />
                                                                    </div>
                                                                    <div className="comment-col w-100 mt-2">
                                                                        <div className="d-flex">
                                                                            <div className="user-comment-name">
                                                                                {item.DisplayName}
                                                                            </div>
                                                                            <div className="comment-time ms-3">
                                                                                {commonservices.getDateTimeInFormat(
                                                                                    item.CreatedOn
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className="comments"
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: item.Details.replaceAll('\n','<br/>'),
                                                                            }}
                                                                        ></div>
                                                                        <div className="attachment-files mt-2">
                                                                            <div className="attachmentimg">
                                                                                {_taskCommentDocument.length > 0 &&
                                                                                    _taskCommentDocument
                                                                                        .filter(
                                                                                            (D) =>
                                                                                                D.AssociationID ==
                                                                                                item.TaskLogDetailID &&
                                                                                                D.AssociationType.toUpperCase() ==
                                                                                                "TASKLOG"
                                                                                        )
                                                                                        .map((docItem, docIndex) => (
                                                                                            <div className="documnet-attachment close-btn p-0">
                                                                                                <span
                                                                                                    className="documnet-file"
                                                                                                    onClick={(e) =>
                                                                                                        ViewPostFilePopup(
                                                                                                            _taskCommentDocument,
                                                                                                            docIndex
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    {docItem.FileType ===
                                                                                                        "IMAGE" ? (
                                                                                                        <>
                                                                                                            <img
                                                                                                                className="documnet-img"
                                                                                                                src={docItem.FilePath}
                                                                                                                title={docItem.DocName}
                                                                                                                width={40}
                                                                                                                height={40}
                                                                                                            />
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            <img
                                                                                                                className="documnet-img"
                                                                                                                src={
                                                                                                                    AppConfigData
                                                                                                                        .FileTypePath[
                                                                                                                    docItem.FileType.toUpperCase()
                                                                                                                    ]
                                                                                                                }
                                                                                                                title={docItem.DocName}
                                                                                                                width={40}
                                                                                                                height={40}
                                                                                                            />
                                                                                                        </>
                                                                                                    )}

                                                                                                    {/* <img
                                                className="documnet-img"
                                                src={
                                                  AppConfigData.FileTypePath[
                                                    docItem.FileType.toUpperCase()
                                                  ]
                                                }
                                                title={docItem.DocName}
                                                width={40}
                                                height={40}
                                              /> */}
                                                                                                </span>
                                                                                                {docItem.CreatedBy ===
                                                                                                    data[0].UserID && (
                                                                                                        <Button
                                                                                                            variant="close-btn-icon"
                                                                                                            title="Close"
                                                                                                            onClick={(e) => {
                                                                                                                confirmAlert({
                                                                                                                    message:
                                                                                                                        commonservices.LabelDisplayText(
                                                                                                                            "AreYouSureToDoDelete"
                                                                                                                        ),
                                                                                                                    buttons: [
                                                                                                                        {
                                                                                                                            label:
                                                                                                                                commonservices.LabelDisplayText(
                                                                                                                                    "Yes"
                                                                                                                                ),
                                                                                                                            onClick: () =>
                                                                                                                                deleteDataFile(
                                                                                                                                    docItem.DocumentID,
                                                                                                                                    "COMMNETFILE"
                                                                                                                                ),
                                                                                                                        },
                                                                                                                        {
                                                                                                                            label:
                                                                                                                                commonservices.LabelDisplayText(
                                                                                                                                    "No"
                                                                                                                                ),
                                                                                                                        },
                                                                                                                    ],
                                                                                                                });
                                                                                                            }}
                                                                                                        >
                                                                                                            <i className="fa fa-close"></i>
                                                                                                        </Button>
                                                                                                    )}
                                                                                            </div>
                                                                                        ))}
                                                                            </div>
                                                                        </div>
                                                                        <div className="comment-col-button">
                                                                            {item.CreatedBy === data[0].UserID && (
                                                                                <Button
                                                                                    variant="delete p-0 ms-2"
                                                                                    title="Delete"
                                                                                    onClick={(e) => {
                                                                                        confirmAlert({
                                                                                            message:
                                                                                                commonservices.LabelDisplayText(
                                                                                                    "AreYouSureToDoDelete"
                                                                                                ),
                                                                                            buttons: [
                                                                                                {
                                                                                                    label:
                                                                                                        commonservices.LabelDisplayText(
                                                                                                            "Yes"
                                                                                                        ),
                                                                                                    onClick: (e) =>
                                                                                                        fnDeleteCommnet(
                                                                                                            item.TaskLogDetailID
                                                                                                        ),
                                                                                                },
                                                                                                {
                                                                                                    label:
                                                                                                        commonservices.LabelDisplayText(
                                                                                                            "No"
                                                                                                        ),
                                                                                                },
                                                                                            ],
                                                                                        });
                                                                                    }}
                                                                                >
                                                                                    {commonservices.LabelDisplayText(
                                                                                        "Delete"
                                                                                    )}
                                                                                </Button>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                    </>
                                                )}
                                            </div>
                                        </Tab>
                                        <Tab eventKey="file" title="File">
                                            <Row>
                                                <Col xs={12} className="attachment-files">
                                                    <div className="attachmentimg">
                                                        {_taskdocuments.length > 0 &&
                                                            _taskdocuments.map((item, index) =>
                                                                item.FileType === "IMAGE" ? (
                                                                    <>
                                                                        <div className="documnet-attachment close-btn p-0">
                                                                            <span
                                                                                className="documnet-file"
                                                                                onClick={(e) =>
                                                                                    ViewPostFilePopup(
                                                                                        _taskdocuments,
                                                                                        index
                                                                                    )
                                                                                }
                                                                            >
                                                                                <img
                                                                                    className="documnet-img"
                                                                                    src={
                                                                                        // AppConfigData.FileTypePath[
                                                                                        //   item.FileType.toUpperCase()
                                                                                        // ]
                                                                                        item.FilePath
                                                                                    }
                                                                                    title={item.DocName}
                                                                                    width={68}
                                                                                    height={66}
                                                                                    alt={item.DocName}
                                                                                />
                                                                            </span>
                                                                            {item.CreatedBy === data[0].UserID && (
                                                                                <Button
                                                                                    variant="close-btn-icon"
                                                                                    title="Close"
                                                                                    onClick={(e) => {
                                                                                        confirmAlert({
                                                                                            message:
                                                                                                commonservices.LabelDisplayText(
                                                                                                    "AreYouSureToDoDelete"
                                                                                                ),
                                                                                            buttons: [
                                                                                                {
                                                                                                    label:
                                                                                                        commonservices.LabelDisplayText(
                                                                                                            "Yes"
                                                                                                        ),
                                                                                                    onClick: () =>
                                                                                                        deleteDataFile(
                                                                                                            item.DocumentID,
                                                                                                            "TASKFILE"
                                                                                                        ),
                                                                                                },
                                                                                                {
                                                                                                    label:
                                                                                                        commonservices.LabelDisplayText(
                                                                                                            "No"
                                                                                                        ),
                                                                                                },
                                                                                            ],
                                                                                        });
                                                                                    }}
                                                                                >
                                                                                    <i className="fa fa-close"></i>
                                                                                </Button>
                                                                            )}
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <div className="documnet-attachment close-btn p-0">
                                                                            <span
                                                                                className="documnet-file"
                                                                                onClick={(e) =>
                                                                                    ViewPostFilePopup(
                                                                                        _taskdocuments,
                                                                                        index
                                                                                    )
                                                                                }
                                                                            >
                                                                                <img
                                                                                    className="documnet-img"
                                                                                    src={
                                                                                        AppConfigData.FileTypePath[
                                                                                        item.FileType.toUpperCase()
                                                                                        ]
                                                                                    }
                                                                                    title={item.DocName}
                                                                                    width={68}
                                                                                    height={66}
                                                                                    alt={item.DocName}
                                                                                />
                                                                            </span>
                                                                            {item.CreatedBy === data[0].UserID && (
                                                                                <Button
                                                                                    variant="close-btn-icon"
                                                                                    title="Close"
                                                                                    onClick={(e) => {
                                                                                        confirmAlert({
                                                                                            message:
                                                                                                commonservices.LabelDisplayText(
                                                                                                    "AreYouSureToDoDelete"
                                                                                                ),
                                                                                            buttons: [
                                                                                                {
                                                                                                    label:
                                                                                                        commonservices.LabelDisplayText(
                                                                                                            "Yes"
                                                                                                        ),
                                                                                                    onClick: () =>
                                                                                                        deleteDataFile(
                                                                                                            item.DocumentID,
                                                                                                            "TASKFILE"
                                                                                                        ),
                                                                                                },
                                                                                                {
                                                                                                    label:
                                                                                                        commonservices.LabelDisplayText(
                                                                                                            "No"
                                                                                                        ),
                                                                                                },
                                                                                            ],
                                                                                        });
                                                                                    }}
                                                                                >
                                                                                    <i className="fa fa-close"></i>
                                                                                </Button>
                                                                            )}
                                                                        </div>
                                                                    </>
                                                                )
                                                            )}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Tab>
                                        <Tab eventKey="logs" title="Logs">
                                            <div className="comment-block">
                                                {_taskLog.length > 0 &&
                                                    _taskLog.map((item, index) => (
                                                        <div className="comment-item mb-3 d-flex">
                                                            <div className="comment-profile me-3">
                                                                <img
                                                                    className="project-img mt-0 mb-0 rounded-circle"
                                                                    // src={item.ProfilePic}
                                                                    src={
                                                                        item.ProfilePic !==
                                                                          null &&
                                                                        item.ProfilePic !==
                                                                          ""
                                                                          ? item.ProfilePic
                                                                          : UserPlaceholder
                                                                      }
                                                                    alt={commonservices.getInitials(
                                                                        item.DisplayName
                                                                    )}
                                                                    width={42}
                                                                    height={42}
                                                                />
                                                            </div>
                                                            <div className="comment-col w-100 mt-2">
                                                                <div className="d-flex">
                                                                    <div className="user-comment-name">
                                                                        {item.DisplayName}
                                                                    </div>
                                                                    <div className="comment-time ms-3">
                                                                        {commonservices.getDateTimeInFormat(
                                                                            item.CreatedOn
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="comments"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: item.Details,
                                                                    }}
                                                                ></div>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </Tab>
                                        <Tab eventKey="Time Details" title="Time Details">
                                            <Table>
                                                <thead>
                                                    <tr>

                                                        <th>Date Time</th>
                                                        <th className="width-50px">Time</th>
                                                        <th className="width-200px">Client(Min)</th>
                                                        <th className="width-50px">User</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {TimeDetailsData?.taskDateWiseTime?.length > 0 &&
                                                        TimeDetailsData.taskDateWiseTime.map(
                                                            (item, index) => (
                                                                <>
                                                                    <tr key={index}>
                                                                        <td>
                                                                            <b>{commonservices.getDateInFormat(item.TaskDate)}</b>
                                                                        </td>
                                                                        <td><b>{commonservices.ConvertMinutesToTimeFormat(item.TotalTimeInMinites)}</b></td>
                                                                        <td><b>{commonservices.ConvertMinutesToTimeFormat(item.TotalTimeInMinutesForClient)}</b></td>
                                                                        <td>

                                                                        </td>
                                                                    </tr>

                                                                    {TimeDetailsData.userTaskTimer.length > 0 &&
                                                                        TimeDetailsData.userTaskTimer
                                                                            .filter(
                                                                                (f) =>
                                                                                    commonservices.getDateInFormat(
                                                                                        f.TimerDate
                                                                                    ) ===
                                                                                    commonservices.getDateInFormat(
                                                                                        item.TaskDate
                                                                                    )
                                                                            )
                                                                            .map((itemTask, rowindex) => (
                                                                                <tr key={rowindex}>

                                                                                    <td>
                                                                                        {
                                                                                            itemTask.IsAbleToUpdate === 1 ?
                                                                                                itemTask.UserTimeUpdateBy !== null && itemTask.UserTimeUpdateBy > 0 ?
                                                                                                    <div class="input-group">
                                                                                                        <input id={`userstarttime_${itemTask.UserTaskTimerID}`} type="datetime-local" defaultValue={commonservices.getDateTimeInDBFormat(itemTask.StartTime)} className="form-control border border-warning font-size-12px p-1" max={commonservices.getDateTimeInDBFormat(new Date())} />
                                                                                                        <input id={`userendtime_${itemTask.UserTaskTimerID}`} type="datetime-local" defaultValue={commonservices.getDateTimeInDBFormat(itemTask.EndTime)} className="form-control border border-warning font-size-12px p-1" max={commonservices.getDateTimeInDBFormat(new Date())} />
                                                                                                        <OverlayTrigger overlay={<Tooltip>Last Time Update By {itemTask.UserTimeUpdateByDisplayName}</Tooltip>}>
                                                                                                            <Button variant="edit-button" title="Save" className="ms-auto btn-dark" onClick={(e) => fnUpdateUserTime(itemTask, commonservices.getDateInDBFormat(itemTask.TimerDate), "USERTIME")}><i className="fa-regular fa-save"></i></Button>
                                                                                                        </OverlayTrigger>

                                                                                                    </div>
                                                                                                    :
                                                                                                    <div class="input-group">
                                                                                                        <input id={`userstarttime_${itemTask.UserTaskTimerID}`} type="datetime-local" defaultValue={commonservices.getDateTimeInDBFormat(itemTask.StartTime)} className="form-control font-size-12px p-1" max={commonservices.getDateTimeInDBFormat(new Date())} />
                                                                                                        <input id={`userendtime_${itemTask.UserTaskTimerID}`} type="datetime-local" defaultValue={commonservices.getDateTimeInDBFormat(itemTask.EndTime)} className="form-control font-size-12px p-1" max={commonservices.getDateTimeInDBFormat(new Date())} />
                                                                                                        <Button variant="edit-button" title="Save" className="ms-auto btn-dark" onClick={(e) => fnUpdateUserTime(itemTask, commonservices.getDateInDBFormat(itemTask.TimerDate), "USERTIME")}><i className="fa-regular fa-save"></i></Button>

                                                                                                    </div>
                                                                                                :
                                                                                                <>{commonservices.getDateTimeInDDMMFormat(itemTask.StartTime)}<b> To </b>{commonservices.getDateTimeInDDMMFormat(itemTask.EndTime)} ({commonservices.ConvertMinutesToTimeFormat(itemTask.TotalTimeInMinites)})</>
                                                                                        }
                                                                                    </td>
                                                                                    <td>{commonservices.ConvertMinutesToTimeFormat(itemTask.TotalTimeInMinites)}</td>
                                                                                    <td>
                                                                                        {
                                                                                            itemTask.IsAbleToUpdate === 1 ?
                                                                                                itemTask.ClientTimeUpdateBy !== null && itemTask.ClientTimeUpdateBy > 0 ?
                                                                                                    <div class="input-group">
                                                                                                        <input type="number" class="form-control border border-warning border-2" id={`client_${itemTask.UserTaskTimerID}`} placeholder="Time For Client(Min)" min="0" defaultValue={itemTask.TotalTimeInMinutesForClient} />
                                                                                                        <OverlayTrigger overlay={<Tooltip>Last Time Update By {itemTask.ClientTimeUpdateByDisplayName}</Tooltip>}>
                                                                                                            <Button variant="edit-button" title="Save" className="ms-auto btn-dark" onClick={(e) => fnUpdateClientTime(itemTask, commonservices.getDateInDBFormat(itemTask.TimerDate), "CLIENTTIME")}><i className="fa-regular fa-save"></i></Button>
                                                                                                        </OverlayTrigger>
                                                                                                    </div>
                                                                                                    :
                                                                                                    <div class="input-group">
                                                                                                        <input type="number" class="form-control" id={`client_${itemTask.UserTaskTimerID}`} placeholder="Time For Client(Min)" min="0" defaultValue={itemTask.TotalTimeInMinutesForClient} />
                                                                                                        <Button variant="edit-button" title="Save" className="ms-auto btn-dark" onClick={(e) => fnUpdateClientTime(itemTask, commonservices.getDateInDBFormat(itemTask.TimerDate), "CLIENTTIME")}><i className="fa-regular fa-save"></i></Button>
                                                                                                    </div>
                                                                                                :
                                                                                                itemTask.TotalTimeInMinutesForClient
                                                                                        }
                                                                                    </td>
                                                                                    <td>
                                                                                        <OverlayTrigger
                                                                                            overlay={
                                                                                                <Tooltip>{itemTask.DisplayName}</Tooltip>
                                                                                            }
                                                                                        >
                                                                                            <img
                                                                                                className="project-img mt-0 mb-0 rounded-circle"
                                                                                                src={itemTask.ProfilePic}
                                                                                                alt={commonservices.getInitials(
                                                                                                    itemTask.DisplayName
                                                                                                )}
                                                                                                width={44}
                                                                                                height={44}
                                                                                            />
                                                                                        </OverlayTrigger>
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                </>
                                                            )
                                                        )}
                                                </tbody>
                                            </Table>
                                        </Tab>
                                    </Tabs>
                                </Col>
                            </Row>
                        </Container>
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default TaskDetail;
