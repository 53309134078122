import axios from "axios";
import { useState, useEffect } from "react";
import { default as React } from "react";
import ProjectPlaceholder from '../../assets/Images/ProjectPlaceholder.png'
import { commonservices, AppConfigData } from "../../Services/commonService";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";

const TaskTimer = (props) => {
    let data = commonservices.getLoginUserData();
    /*const [time, setTime] = useState("");*/
    const [TimerData, setTimerData] = useState([]);
    let TaskDate = new Date();
    useEffect(() => {
        if (props !== null && props !== undefined) {
            if (props.details !== null && props.details !== undefined) {
                setTimerData(props.details);
                TaskDate = new Date(props.details[0].StartTime);
                /*setTime(commonservices.ConvertMinutesToTimeFormat(commonservices.diffMinutes(TaskDate, new Date())));*/
                if (document.getElementById("timertime") !== null) {
                    document.getElementById("timertime").innerHTML = commonservices.ConvertMinutesToTimeFormat(commonservices.diffMinutes(TaskDate, new Date()));
                }
                let ForClearInterval = setInterval(function (i) {
                    if (document.getElementById("timertime") !== null) {
                        document.getElementById("timertime").innerHTML = commonservices.ConvertMinutesToTimeFormat(commonservices.diffMinutes(TaskDate, new Date()));
                    }
                    else {
                        clearInterval(ForClearInterval);
                    }
                }, 60000)
            }
        }
    });

    return (
        <>
            {
                TimerData.length > 0 &&
                <>
                    <div className="task-time-col">
                        <div className="task-time">
                            <div className="task-time-working d-flex align-items-center">
                                <button
                                    type="button"
                                    title="Play-Pause"
                                    className="pause-play-button d-flex align-items-center justify-content-center btn btn-primary"
                                    onClick={(e) => {
                                        confirmAlert({
                                            message: commonservices.LabelDisplayText(
                                                "AreYouSureWantToStopTask"
                                            ),
                                            buttons: [
                                                {
                                                    label:
                                                        commonservices.LabelDisplayText("Yes"),
                                                    onClick: function () {
                                                        let input = {
                                                            taskID: TimerData[0].TaskID,
                                                            projectID: TimerData[0].ProjectID,
                                                            stopTaskComment: ""
                                                        };
                                                        const axiosConfig = {
                                                            headers: {
                                                                Accept: "application/json",
                                                                Authorization: "Bearer " + localStorage.getItem("token"),
                                                            },
                                                        };
                                                        axios
                                                            .post(
                                                                `${AppConfigData.APIBasePath}v1/task/taskstoptimer`,
                                                                input,
                                                                axiosConfig
                                                            )
                                                            .then((res) => {
                                                                if (res.data.status_code === 0) {
                                                                    toast.error(res.data.message);
                                                                } else if (res.data.status_code === 1) {
                                                                    toast.success(res.data.message);
                                                                    setTimerData([]);
                                                                    const connect = new HubConnectionBuilder()
                                                                        .withUrl(AppConfigData.SignalRHubPath, { withCredentials: false })
                                                                        .withAutomaticReconnect()
                                                                        .build();
                                                                    connect.start().then(() => {
                                                                        connect.send("TaskStartStop", { UserID: data[0].UserID });
                                                                    }).catch((error) => { });
                                                                    window.location.reload()
                                                                }
                                                            })
                                                            .catch((err) => {

                                                            });
                                                    },
                                                },
                                                {
                                                    label:
                                                        commonservices.LabelDisplayText("No"),
                                                },
                                            ],
                                        });
                                    }}>
                                    <i className="fa-regular fa-pause"></i>
                                </button>
                                {/*<span id="timertime">{time}</span>*/}
                                <span id="timertime">00:00</span>

                            </div>
                        </div>
                    </div>
                    <div className="header-project-col d-flex align-items-center">
                        <div className="project-img">
                            <img
                                className="user-profile-img rounded-circle"
                                title={TimerData[0].ProjectName}
                                // src={TimerData[0].ProjectLogo}
                                src={
                                    TimerData[0].ProjectLogo !==
                                      null &&
                                    TimerData[0].ProjectLogo !==
                                      ""
                                      ? TimerData[0].ProjectLogo
                                      : ProjectPlaceholder
                                  }
                                alt={commonservices.getInitials(TimerData[0].ProjectName)}
                                width="40"
                                height="40"
                            />
                        </div>
                        <div className="content-project-col">
                            <div className="project-name">{TimerData[0].ProjectName} </div>
                            <div className="project-description">
                                {TimerData[0].Title}
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
};

export default TaskTimer;
