import axios from "axios";
import { useState, useEffect } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { default as React } from "react";
import { toast } from "react-toastify";
import * as yup from "yup";
import moment from "moment";
import { commonservices, AppConfigData } from "../Services/commonService";
import { Col, Modal, Button, Container, Row, Table } from "react-bootstrap";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import Spinner from "react-bootstrap/Spinner";
import $ from "jquery";
const AddProject = (props) => {
    const [projectItem, setProjectItem] = useState([]);
    const [projectStatus, setProjectStatus] = useState([]);
    const [selectedSkill, setSelectedSkill] = useState([]);
    const [optionSkill, setoptionSkill] = useState([]);
    const [Submitting, setSubmitting] = useState(false);
    const [IsValidationMessageSet, setIsValidationMessageSet] = useState("");

    useEffect(() => {
        setIsValidationMessageSet(true);
    });
    const formFieldLength = {
        ProjectName: 167,
        ShortDetails: 500,
    };

    const formInitialSchema = {
        ProjectID: 0,
        ProjectName: "",
        ProjectLogoFile: "",
        ShortDetails: "",
        ClientID: "",
        StartDate: "",
        EndDate: "",
        Status: "",
        Technologies: "",
        IsFixCost: false,
    };

    const formValidation = yup.object().shape({
        ProjectName: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "ProjectName"))
            .test('len', commonservices.MakeValidationMessage("maxlength", ""), (value, obj) => { return value ? value.length <= formFieldLength.ProjectName : true }),
        ProjectLogoFile: yup
            .mixed()
            .test(
                "type",
                commonservices.MakeValidationMessage("custom", "ProjectLogoFile", commonservices.CustomValidationMessage("ValidationOnlyImage")),
                (value, obj) => {
                    if (
                        document.getElementsByName("ProjectLogoFile") !== null &&
                        document.getElementsByName("ProjectLogoFile") !== "" &&
                        document.getElementsByName("ProjectLogoFile") !== undefined &&
                        document.getElementsByName("ProjectLogoFile")[0].files.length > 0
                    ) {
                        return (
                            document.getElementsByName("ProjectLogoFile")[0].files[0].type ===
                            "image/jpeg" ||
                            document.getElementsByName("ProjectLogoFile")[0].files[0].type ===
                            "image/png"
                        );
                    } else {
                        return true;
                    }
                }
            ),
        ShortDetails: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "ShortDetails"))
            .test('len', commonservices.MakeValidationMessage("maxlength", ""), (value, obj) => { return value ? value.length <= formFieldLength.ShortDetails : true }),
        ClientID: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "ClientID")),

        StartDate: yup
            .date()
            .required(commonservices.MakeValidationMessage("required", "StartDate")),

        EndDate: yup
            .date()
            .required(commonservices.MakeValidationMessage("required", "EndDate"))
            .min(
                yup.ref("StartDate"),
                commonservices.MakeValidationMessage("custom", "EndDate", commonservices.CustomValidationMessage("ValidationData1CannotBeLessThanDate2").replace("{0}", $("[lbl-for='EndDate']").text().trim()).replace("{1}", $("[lbl-for='StartDate']").text().trim()))
            ),

        Status: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "Status")),
        IsFixCost: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "IsFixCost")),
    });

    useEffect(() => {
        const axiosConfig = {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        };
        axios
            .post(`${AppConfigData.APIBasePath}v1/client/getlist`, {}, axiosConfig)
            .then((res) => {
                if (res.data.status_code === 0) {
                } else if (res.data.status_code === 1) {
                    setProjectItem(res.data.data);
                    // props.bindList();
                } else if (res.data.status_code === 2) {
                } else {
                }
            })
            .catch((err) => { });
    }, []);

    function fnSelectedOptionSkill(value, event) {
        setSelectedSkill(value);
    }

    useEffect(() => {
        const axiosConfig = {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        };
        axios
            .post(
                `${AppConfigData.APIBasePath}v1/projectterm/getlist`,
                {
                    term: "",
                    category: "ProjectStatus,technologies",
                },
                axiosConfig
            )
            .then((res) => {
                if (res.data.status_code === 0) {
                } else if (res.data.status_code === 1) {
                    setProjectStatus(res.data.data);
                    let optionSkill = [];
                    res.data.data
                        .filter((t) => t.Category === "Technologies")
                        .forEach((element) => {
                            optionSkill.push({
                                label: element.Term,
                                value: element.Term,
                            });
                        });
                    setoptionSkill(optionSkill);
                } else if (res.data.status_code === 2) {
                } else {
                }
            })
            .catch((err) => { });
    }, []);

    return (
        <>
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                className="right-modal modal-class modal-new modal-add-project"
                scrollable={true}
                backdrop="static"
                keyboard={false}
            >
                <Formik
                    initialValues={formInitialSchema}
                    validationSchema={formValidation}
                    validateOnBlur={false}
                    onSubmit={async (values) => {
                        setSubmitting(true);

                        const formData = new FormData();

                        let data = commonservices.getLoginUserData();
                        if (data !== null && data !== undefined) {
                            if (data[0].AssociationType !== "Super Admin") {
                                formData.append("lstProjectUser[0].projectUserID", 0);
                                formData.append("lstProjectUser[0].userID", data[0].UserID);
                                formData.append("lstProjectUser[0].projectID", 0);
                                formData.append("lstProjectUser[0].isManager", false);
                                formData.append("lstProjectUser[0].isTeamLead", false);
                                formData.append("lstProjectUser[0].isActive", true);
                                formData.append("lstProjectUser[0].isOwner", false);
                                formData.append("lstProjectUser[0].projectRoleID", 0);
                            }
                        }

                        formData.append("ProjectID", values.ProjectID);
                        formData.append("ProjectName", values.ProjectName);
                        formData.append(
                            "ProjectLogoFile",
                            document.getElementsByName("ProjectLogoFile")[0].files[0]
                        );
                        formData.append("ShortDetails", values.ShortDetails);
                        formData.append("ClientID", values.ClientID);
                        formData.append("StartDate", values.StartDate);
                        formData.append("EndDate", values.EndDate);
                        formData.append("Status", values.Status);
                        formData.append("IsFixCost", values.IsFixCost);

                        let Technologies = "";
                        for (let i = 0; i < selectedSkill.length; i++) {
                            Technologies = Technologies + selectedSkill[i].value + ",";
                        }
                        formData.append("Technologies", Technologies);

                        const axiosConfig = {
                            headers: {
                                "content-type": "multipart/form-data",
                                Authorization: "Bearer " + localStorage.getItem("token"),
                            },
                        };
                        const response = await axios
                            .post(
                                `${AppConfigData.APIBasePath}v1/project/save`,
                                formData,
                                axiosConfig
                            )
                            .then((res) => {
                                setSubmitting(false);

                                if (res.data.status_code === 0) {
                                    toast.error(res.data.message);
                                } else if (res.data.status_code === 1) {
                                    toast.success(res.data.message);
                                    props.bindList();
                                    props.onHide();
                                    //setTimeout(() => {
                                    //    props.onHide();
                                    //}, 1000);
                                } else if (res.data.status_code === 2) {
                                    toast.error(res.data.message);
                                } else {
                                    toast.error(res.data.message);
                                }
                            })
                            .catch((err) => { });
                    }}
                >
                    <Form>
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                {commonservices.LabelDisplayText("AddProject")}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Container>
                                <Row>
                                    <Col xs={6} className="from-bottom-margin">
                                        <Field
                                            type="hidden"
                                            name="ProjectID"
                                            className="form-control"
                                            placeholder="ProjectID"
                                        />
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Field
                                                    type="text"
                                                    name="ProjectName"
                                                    className="form-control"
                                                    placeholder={commonservices.LabelDisplayText(
                                                        "ProjectName"
                                                    )}
                                                    maxLength={formFieldLength.ProjectName}
                                                />
                                                <label lbl-for="ProjectName">
                                                    {commonservices.LabelDisplayText("ProjectName")}
                                                </label>
                                                <div className="text-danger-error">
                                                    <ErrorMessage name="ProjectName" />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={6} className="from-bottom-margin">
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Field
                                                    as="select"
                                                    name="ClientID"
                                                    className="form-control down-arrow-icon"
                                                >
                                                    <option value="">
                                                        {commonservices.LabelDisplayText("Select")}
                                                    </option>
                                                    {projectItem.length > 0 &&
                                                        projectItem.map((item, index) => (
                                                            <option key={index} value={item.ClientID}>
                                                                {item.Name}
                                                            </option>
                                                        ))}
                                                </Field>
                                                <label lbl-for="ClientID">
                                                    {commonservices.LabelDisplayText("Client")}
                                                </label>
                                                <div className="text-danger-error">
                                                    <ErrorMessage name="ClientID" />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={6} className="from-bottom-margin">
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Field
                                                    type="date"
                                                    name="StartDate"
                                                    className="form-control calendar-icon"
                                                />
                                                <label lbl-for="StartDate">
                                                    {commonservices.LabelDisplayText("StartDate")}
                                                </label>
                                                <div className="text-danger-error">
                                                    <ErrorMessage name="StartDate" />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={6} className="from-bottom-margin">
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <div className="form-control browse-file">
                                                    <Field
                                                        type="file"
                                                        name="ProjectLogoFile"
                                                        className="form-control"
                                                    />
                                                    <label lbl-for="ProjectLogoFile">
                                                        {commonservices.LabelDisplayText(
                                                            "ChooseProjectLogoFile"
                                                        )}
                                                    </label>
                                                    <div className="text-danger-error">
                                                        <ErrorMessage name="ProjectLogoFile" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={6} className="from-bottom-margin">
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Field
                                                    type="date"
                                                    name="EndDate"
                                                    className="form-control calendar-icon"
                                                />
                                                <label lbl-for="EndDate">
                                                    {commonservices.LabelDisplayText("EndDate")}
                                                </label>
                                                <div className="text-danger-error">
                                                    <ErrorMessage name="EndDate" />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={6} className="from-bottom-margin">
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Field
                                                    as="select"
                                                    name="Status"
                                                    className="form-control down-arrow-icon"
                                                >
                                                    <option value="">
                                                        {commonservices.LabelDisplayText("Select")}
                                                    </option>
                                                    {projectStatus.length > 0 &&
                                                        projectStatus.map(
                                                            (item, index) =>
                                                                item.Category === "ProjectStatus" && (
                                                                    <option key={index} value={item.Term}>
                                                                        {item.Term}
                                                                    </option>
                                                                )
                                                        )}
                                                </Field>
                                                <label lbl-for="Status">
                                                    {commonservices.LabelDisplayText("Status")}
                                                </label>
                                                <div className="text-danger-error">
                                                    <ErrorMessage name="Status" />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={6} className="from-bottom-margin add_project_modal_selector">
                                        <div className="form-group">
                                            <div className="form-floating multipleselect-form-floating">
                                                <ReactMultiSelectCheckboxes
                                                    width={"100%"}
                                                    placeholderButtonLabel={commonservices.LabelDisplayText(
                                                        "Select"
                                                    )}
                                                    isMulti
                                                    name="Technologies"
                                                    onChange={fnSelectedOptionSkill}
                                                    options={optionSkill}
                                                    value={selectedSkill}
                                                />
                                                <label lbl-for="Technologies">
                                                    {commonservices.LabelDisplayText("Technologies")}
                                                </label>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xs={12} className="from-bottom-margin">
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Field
                                                    as="textarea"
                                                    name="ShortDetails"
                                                    className="form-control"
                                                    placeholder={commonservices.LabelDisplayText(
                                                        "ShortDetails"
                                                    )}
                                                    maxLength={formFieldLength.ShortDetails}
                                                />
                                                <label lbl-for="ShortDetails">
                                                    {commonservices.LabelDisplayText("ShortDetails")}
                                                </label>
                                                <div className="text-danger-error">
                                                    <ErrorMessage name="ShortDetails" />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xs={6}>
                                        <div className="sub-points position-relative form-group pt-2">
                                            <Field
                                                type="checkbox"
                                                name="IsFixCost"
                                                className="me-2"
                                                id="IsFixCost"
                                            />
                                            <label htmlFor="IsFixCost" lbl-for="IsFixCost">
                                                {commonservices.LabelDisplayText("IsFixCost")}
                                            </label>
                                            <span className="checkmark"></span>
                                            <div className="text-danger-error">
                                                <ErrorMessage name="IsFixCost" />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={props.onHide} className="Cancel-btn" title="Cancel">
                                {commonservices.LabelDisplayText("Cancel")}
                            </Button>
                            <Button
                                className="btn btn-primary save-btn"
                                type="submit"
                                title="Submit"
                                disabled={Submitting}
                            >
                                {Submitting && (
                                    <>
                                        <Spinner animation="border" />
                                    </>
                                )}
                                {commonservices.LabelDisplayText("Save")}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Formik>
            </Modal>
        </>
    );
};

export default AddProject;
