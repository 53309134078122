import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { default as React, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Modal, Button, Container, Row, Table } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";
import { AppConfigData, commonservices } from "../Services/commonService";
import * as yup from "yup";

const AddDocument = (props) => {
    const [projectItem, setProjectItem] = useState([]);
    const [optionSkill, setoptionSkill] = useState([]);
    const [Submitting, setSubmitting] = useState(false);
    const [IsValidationMessageSet, setIsValidationMessageSet] = useState("");
    useEffect(() => {
        setIsValidationMessageSet(true);
    });


    const formFieldLength = {
        docName: 167,
        details: 500,
    }

    const formInitialSchema = {
        associationID: props.associationID,
        docName: "",
        file: "",
        details: "",
        docType: "",
        associationType: props.associationType,
    };

    const formValidation = yup.object().shape({
        file: yup.string().required(commonservices.MakeValidationMessage("required", "file")),
        docType: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "docType")),
        docName: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "docName"))
            .test('len', commonservices.MakeValidationMessage("maxlength", ""), (value, obj) => { return value ? value.length <= formFieldLength.docName : true }),
        details: yup
            .string()
            .test('len', commonservices.MakeValidationMessage("maxlength", ""), (value, obj) => { return value ? value.length <= formFieldLength.details : true }),
    });

    useEffect(() => {
        const axiosConfig = {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        };
        axios
            .post(
                `${AppConfigData.APIBasePath}v1/projectterm/getlist`,
                {
                    term: "",
                    category: "Documents",
                },
                axiosConfig
            )
            .then((res) => {
                if (res.data.status_code === 0) {
                } else if (res.data.status_code === 1) {
                    setProjectItem(res.data.data);
                    let optionSkill = [];
                    res.data.data
                        .filter((t) => t.Category === "Technologies")
                        .forEach((element) => {
                            optionSkill.push({
                                label: element.Term,
                                value: element.Term,
                            });
                        });
                    setoptionSkill(optionSkill);
                } else if (res.data.status_code === 2) {
                } else {
                }
            })
            .catch((err) => { });
    }, []);
    return (
        <>
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                className="right-modal modal-new modal-tabs"
                scrollable={true}
                backdrop="static"
                keyboard={false}
            >
                <Formik
                    initialValues={formInitialSchema}
                    validationSchema={formValidation}
                    validateOnBlur={false}
                    onSubmit={async (values) => {
                        setSubmitting(true);
                        const Document = new FormData();
                        Document.append(
                            "Document[0].File",
                            document.getElementsByName("file")[0].files[0]
                        );
                        Document.append("Document[0].AssociationID", props.associationID);
                        Document.append(
                            "Document[0].AssociationType",
                            props.associationType
                        );
                        Document.append("Document[0].DocName", values.docName);
                        Document.append("Document[0].Details", values.details);
                        Document.append("Document[0].DocType", values.docType);

                        const axiosConfig = {
                            headers: {
                                "Content-Type": "multipart/form-data",
                                Authorization: "Bearer " + localStorage.getItem("token"),
                            },
                        };
                        const response = await axios
                            .post(
                                `${AppConfigData.APIBasePath}v1/documents/save`,
                                Document,

                                axiosConfig
                            )
                            .then((response) => {
                                setSubmitting(false);
                                if (response.data.status_code === 1) {
                                    toast.success(response.data.message);
                                    props.bindList();
                                    props.onHide();
                                    //setTimeout(() => {
                                    //    props.onHide();
                                    //}, 1000);
                                } else if (response.data.status_code === 2) {
                                    toast.error(response.data.message);
                                } else if (response.data.status_code === 0) {
                                    toast.error(response.data.message);
                                } else {
                                    toast.error(response.data.message);
                                }
                            })
                            .catch((error) => { });
                    }}
                >
                    <Form>
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                {commonservices.LabelDisplayText("AddEmployeesDocument")}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Container>
                                <div className="documents-tab-col">
                                    <Row>
                                        <Col xs={6} className="from-bottom-margin">
                                            <div className="form-floating">
                                                <Field
                                                    as="select"
                                                    name="docType"
                                                    className="form-control down-arrow-icon"
                                                >
                                                    <option value="">
                                                        {commonservices.LabelDisplayText("Select")}{" "}
                                                    </option>
                                                    {projectItem.length > 0 &&
                                                        projectItem.map(
                                                            (item, index) =>
                                                                item.Category === "Documents" && (
                                                                    <option key={index} value={item.Term}>
                                                                        {item.Term}
                                                                    </option>
                                                                )
                                                        )}
                                                </Field>
                                                <label lbl-for="docType">
                                                    {commonservices.LabelDisplayText("DocType")}
                                                </label>
                                                <div className="text-danger-error">
                                                    <ErrorMessage name="docType" />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={6} className="from-bottom-margin">
                                            <Field
                                                type="hidden"
                                                name="associationID"
                                                className="form-control"
                                            />
                                            <div className="form-floating">
                                                <Field
                                                    type="text"
                                                    name="docName"
                                                    className="form-control"
                                                    placeholder={commonservices.LabelDisplayText(
                                                        "DocName"
                                                    )}
                                                    maxLength={formFieldLength.docName}
                                                />
                                                <label lbl-for="docName">
                                                    {commonservices.LabelDisplayText("DocName")}
                                                </label>
                                                <div className="text-danger-error">
                                                    <ErrorMessage name="docName" />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={6} className="from-bottom-margin">
                                            <div className="form-floating browse-file">
                                                <div className="form-control">
                                                    <Field
                                                        type="file"
                                                        name="file"
                                                        className="form-control"
                                                    />
                                                    <label lbl-for="file">
                                                        {commonservices.LabelDisplayText("ChooseFile")}
                                                    </label>
                                                    <div className="text-danger-error">
                                                        <ErrorMessage name="file" />
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={6} className="from-bottom-margin">
                                            <div className="form-floating">
                                                <Field
                                                    as="textarea"
                                                    name="details"
                                                    className="form-control"
                                                    placeholder={commonservices.LabelDisplayText(
                                                        "Details"
                                                    )}
                                                    maxLength={formFieldLength.details}
                                                />
                                                <label lbl-for="details">
                                                    {commonservices.LabelDisplayText("Details")}
                                                </label>
                                                <div className="text-danger-error">
                                                    <ErrorMessage name="details" />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Container>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={props.onHide} className="Cancel-btn" title="Cancel">
                                {commonservices.LabelDisplayText("Cancel")}
                            </Button>
                            <Button
                                className="btn btn-primary save-btn"
                                type="submit"
                                title="Submit"
                                disabled={Submitting}
                            >
                                {Submitting && (
                                    <>
                                        <Spinner animation="border" />
                                    </>
                                )}
                                {commonservices.LabelDisplayText("Save")}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Formik>
            </Modal>
        </>
    );
};

export default AddDocument;
