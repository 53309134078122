import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import { toast } from 'react-toastify';
import { commonservices, AppConfigData } from "../../Services/commonService";
import { ReactComponent as Logo } from "../../assets/Images/login-logo.svg";
import $ from "jquery";

function UpdatePassword() {

    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const formInitialSchema = {
        password: '',
        confirm_password: '',
    }

    const formValidation = yup.object().shape({
        password: yup.string().required(commonservices.MakeValidationMessage("required", "password")),
        confirm_password: yup.string().when("password", {
            is: val => (val && val.length > 0 ? true : false),
            then: yup.string().oneOf(
                [yup.ref("password")],
                commonservices.MakeValidationMessage("custom", "confirm_password", commonservices.CustomValidationMessage("ValidationField1MustBeSameField1").replace("{0}", $("[lbl-for='password']").text().trim()).replace("{1}", $("[lbl-for='confirm_password']").text().trim()))
            )
        }),
    });
    return (
        <div className="login-page">
            <div className="container-fluid p-0 h-100">
                <div className="login-box h-100">
                    <div className="row m-0 h-100">
                        <div className="col-12 p-0 d-flex justify-content-center align-items-center">
                            <div className="login-right-col">
                                <div className="login-logo text-center">
                                    <Logo />
                                </div>
                                <Formik initialValues={formInitialSchema}
                                    validationSchema={formValidation}
                                    onSubmit={async (values) => {
                                        commonservices.ClearLoginDetails();
                                        const formData = new FormData();
                                        formData.append("Password", values.password);
                                        formData.append("VerifyPassword", values.confirm_password);
                                        formData.append("o", searchParams.get("o"));
                                        formData.append("t", searchParams.get("t"));
                                        const axiosConfig = {
                                            headers: {
                                                "content-type": "multipart/form-data",
                                            },
                                        }
                                        const response = await axios.post(
                                            `${AppConfigData.APIBasePath}v1/user/updatepassword`,
                                            formData,
                                            axiosConfig)

                                            .then((response) => {

                                                if (response.data.status_code === 0) {

                                                    toast.error(response.data.message, {
                                                        theme: "colored",
                                                    });

                                                } else if (response.data.status_code === 1) {
                                                    toast.success(response.data.message);
                                                    navigate("/");

                                                } else if (response.data.status_code === 2) {

                                                    toast.error(response.data.message, {
                                                        theme: "colored",
                                                    });
                                                }
                                            })
                                            .catch((error) => {
                                            });

                                    }
                                    }
                                >
                                    <Form>
                                        <div className='form-group'>
                                            <Field
                                                type="password"
                                                className="form-control"
                                                name="password"
                                                placeholder={commonservices.LabelDisplayText('EnterYourPassword')}
                                            />
                                            <label lbl-for="password">{commonservices.LabelDisplayText('EnterYourPassword')}</label>
                                            <p className='text-danger-error'>
                                                <ErrorMessage name='password' />
                                            </p>
                                        </div>

                                        <div className='form-group'>

                                            <Field
                                                type="password"
                                                className="form-control"
                                                name="confirm_password"
                                                placeholder={commonservices.LabelDisplayText('EnterYourConfirmPassword')}
                                            />
                                            <label lbl-for="confirm_password">{commonservices.LabelDisplayText('EnterYourConfirmPassword')}</label>
                                            <p className='text-danger-error'>
                                                <ErrorMessage name='confirm_password' />
                                            </p>
                                        </div>
                                        <div className="login-btn-class">
                                            <button
                                                className="btn btn-for-login"
                                                type='submit' title="Submit">{commonservices.LabelDisplayText('UpdatePassword')}</button>
                                        </div>
                                    </Form>
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UpdatePassword;
