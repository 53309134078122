import React from "react";
// import { Link } from "react-router-dom";
import ProjectImg from "../assets/Images/project-img.png";
import ProjectUserList from "../assets/Images/project-user-list.png";
import ProjectLogoImg from "../assets/Images/project-img.svg";
import ProjectPlaceholder from "../assets/Images/ProjectPlaceholder.png";
import UserPlaceholder from "../assets/Icons/placeholder.svg";
import whiteSmallLogo from "../assets/Images/white-small-logo.svg";
import axios from "axios";
import { useState, useEffect } from "react";
import {
  Breadcrumb,
  Button,
  Col,
  Container,
  OverlayTrigger,
  Row, // Modal,
  Tooltip,
  Table,
  Card,
} from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import Popover from "react-bootstrap/Popover";
import Loader, { LoaderType } from "../Loader";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { toast } from "react-toastify";
import Slider from "react-slick";
import TaskDetail from "../Modal/TaskDetail";
import placeholderImg from "../assets/Images/placeholder.jpg";
import { ReactComponent as CalendarIcon } from "../assets/Icons/calendar-icon.svg";
import ProgressBar from "react-bootstrap/ProgressBar";
import { AppConfigData, commonservices } from "../Services/commonService";
import AddProject from "../Modal/AddProject";
import Chart from "react-apexcharts";
import AddTaskModal from "../Modal/AddTaskModal";
import AddleaveModal from "../Modal/AddleaveModal";
import ApproveRejectLeaveModal from "../Modal/ApproveRejectLeaveModal";
import moment from "moment";

function Dashboard() {
  let ProjectIndex = 1;
  const [modalAddProject, setModalAddProject] = useState(false);
  const [modalTaskAdd, setModalTaskAdd] = useState(false);
  const [modalShowTask, setModalShowTask] = useState(false);
  const [modalShowDetails, setModalShowDetails] = useState(false);
  const [TaskDataForShowDetails, setTaskDataForShowDetails] = useState({
    TaskID: 0,
    ProjectID: 0,
  });
  const navigate = useNavigate();
  const [projectUser, setProjectUser] = useState(false);
  const [projectCount, setProjectCount] = useState({
    Data: [],
    IsLoading: true,
  });
  const [projectData, setProjectData] = useState({
    Data: [],
    IsLoading: false,
  });
  const [BirthDayData, setBirthDayData] = useState({
    Data: [],
    IsLoading: false,
  });
  const [taskData, settaskData] = useState({
    Data: [],
    IsLoading: true,
  });

  const [searchobj, setSearchobj] = useState({
    projectName: "",
    startDate: null,
    endDate: null,
    status: "",
    technologies: "",
    searchText: "",
    ProfilePicFile: "",
  });

  const [leaveData, setleaveData] = useState({ Data: [], IsLoading: true });
  const [modalLeaveShow, setModalLeaveShow] = useState(false);

  const [IsShowApproveRejectLeaveModal, setIsShowApproveRejectLeaveModal] =
    useState(false);
  const [LeaveApplicationID, setLeaveApplicationID] = useState(0);

  function bindList() {
    setProjectData({
      Data: [],
      IsLoading: true,
    });
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(
        `${AppConfigData.APIBasePath}v1/project/getlist`,
        searchobj,
        axiosConfig
      )
      .then((res) => {
        if (res.data.status_code === 0) {
          setProjectData({ Data: [], IsLoading: false });
        } else if (res.data.status_code === 1) {
          setProjectUser(res.data.data.projectUser);
          setProjectData({ Data: res.data.data.projectList, IsLoading: false });
        } else if (res.data.status_code === 2) {
          commonservices.ClearLoginDetails();
          navigate("/");
        } else {
          setProjectData({ Data: [], IsLoading: false });
        }
      })
      .catch((err) => {
        setProjectData({ Data: [], IsLoading: false });
      });
  }

  function UpcomingBirthday() {
    setBirthDayData({
      Data: [],
      IsLoading: true,
    });
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(
        `${AppConfigData.APIBasePath}v1/employees/getbirthdaylistforhome`,
        searchobj,
        axiosConfig
      )
      .then((res) => {
        // console.log("Upcoming b'day ::", res);
        if (res.data.status_code === 0) {
          setBirthDayData({ Data: [], IsLoading: false });
        } else if (res.data.status_code === 1) {
          setBirthDayData({ Data: res.data.data, IsLoading: false });
        } else if (res.data.status_code === 2) {
          commonservices.ClearLoginDetails();
          navigate("/");
        } else {
          setBirthDayData({ Data: [], IsLoading: false });
        }
      })
      .catch((err) => {
        setBirthDayData({ Data: [], IsLoading: false });
      });
  }

  function deleteData(id) {
    let input = {
      id: id,
      requestFrom: "string",
    };
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(`${AppConfigData.APIBasePath}v1/project/delete`, input, axiosConfig)
      .then((res) => {
        if (res.data.status_code === 0) {
          toast.error(res.data.message);
        } else if (res.data.status_code === 1) {
          toast.success(res.data.message);
          bindList();
        } else if (res.data.status_code === 2) {
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {});
  }

  function CountList() {
    setProjectCount({
      Data: [],
      IsLoading: true,
    });
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(
        `${AppConfigData.APIBasePath}v1/task/getstatuswisecount`,
        {
          projectID: null,
          requestFrom: "string",
        },
        axiosConfig
      )
      .then((res) => {
        if (res.data.status_code === 0) {
          setProjectCount({ Data: [], IsLoading: false });
        } else if (res.data.status_code === 1) {
          setProjectCount({
            Data: res.data.data,
            IsLoading: false,
          });
        } else if (res.data.status_code === 2) {
          commonservices.ClearLoginDetails();
          navigate("/");
        } else {
          setProjectCount({ Data: [], IsLoading: false });
        }
      })
      .catch((err) => {
        setProjectCount({ Data: [], IsLoading: false });
      });
  }

  function TaskbindList() {
    settaskData({
      Data: [],
      IsLoading: true,
    });
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(
        `${AppConfigData.APIBasePath}v1/task/getlistwithtimedata`,
        {
          title: null,
          status: "In Process,In Testing,Closed",
          priority: null,
          startDate: commonservices.getDateInDBFormat(
            new Date().setDate(new Date().getDate() - 7)
          ),
          endDate: commonservices.getDateInDBFormat(new Date()),
          projectID: null,
          userID: null,
          associationID: null,
          associationType: null,
        },
        axiosConfig
      )
      .then((res) => {
        if (res.data.status_code === 0) {
          settaskData({ Data: [], IsLoading: false });
        } else if (res.data.status_code === 1) {
          const groups = res.data.data.taskList.reduce((groups, task) => {
            const date = task.DateRang;
            if (!groups[date]) {
              groups[date] = {
                Date: date,
                tasklist: [],
                TotalTimeInMinites: 0,
              };
            }
            task.TaskUserList = res.data.data.taskUserList.filter(
              (U) => U.TaskID == task.TaskID
            );
            task.RunningTaskList = res.data.data.runningTaskList.filter(
              (R) => R.TaskID == task.TaskID && R.TimerDate == task.DateRang
            );

            groups[date].tasklist.push(task);
            groups[date].TotalTimeInMinites =
              groups[date].TotalTimeInMinites + task.TotalTimeInMinites;

            return groups;
          }, {});

          settaskData({ Data: groups, IsLoading: false });
        } else if (res.data.status_code === 2) {
          commonservices.ClearLoginDetails();
          navigate("/");
        } else {
        }
      })
      .catch((err) => {
        settaskData({
          Data: [],
          IsLoading: false,
        });
      });
  }

  function fnOpenTaskAddEditPopup(id) {
    // setTASKID(id);
    setModalTaskAdd(true);
  }

  function fnCreateTaskButtonAndTimeUI(task) {
    if (task.IsAssign > 0) {
      if (task.RunningCount > 0) {
        return (
          <>
            <div className="task-time text-nowrap me-3">
              {commonservices.getTimeInFormat(
                task.RunningTaskList[0].StartTime
              )}
            </div>
            {/* <button
                            type="button"
                            className="pause-play-button"
                            title={"Stop Timer"}
                            onClick={(e) => {
                                confirmAlert({
                                    message: commonservices.LabelDisplayText(
                                        "AreYouSureWantToStopTask"
                                    ),
                                    buttons: [
                                        {
                                            label: commonservices.LabelDisplayText("Yes"),
                                            onClick: function () {
                                                let input = {
                                                    taskID: task.TaskID,
                                                    projectID: task.ProjectID,
                                                    stopTaskComment: "",
                                                };
                                                const axiosConfig = {
                                                    headers: {
                                                        Accept: "application/json",
                                                        Authorization:
                                                            "Bearer " + localStorage.getItem("token"),
                                                    },
                                                };
                                                axios
                                                    .post(
                                                        `${AppConfigData.APIBasePath}v1/task/taskstoptimer`,
                                                        input,
                                                        axiosConfig
                                                    )
                                                    .then((res) => {
                                                        if (res.data.status_code === 0) {
                                                            toast.error(res.data.message);
                                                        } else if (res.data.status_code === 1) {
                                                            toast.success(res.data.message);
                                                            // fnCallTaskStartStopInHub();
                                                            // taskBind();
                                                        } else if (res.data.status_code === 2) {
                                                            localStorage.removeItem("token");
                                                            navigate("/login");
                                                        }
                                                    })
                                                    .catch((err) => { });
                                            },
                                        },
                                        {
                                            label: commonservices.LabelDisplayText("No"),
                                        },
                                    ],
                                });
                            }}
                        >
                            <i className="fa-regular fa-pause"></i>
                        </button> */}
          </>
        );
      } else {
        if (task.IsTaskRunningByMe === 0) {
          return (
            <>
              <div className="task-time text-nowrap me-3">
                {task.TotalTimeInMinites > 0 &&
                  commonservices.ConvertMinutesToTimeFormat(
                    task.TotalTimeInMinites
                  ) + " hrs"}
              </div>
              {/* <button
                                type="button"
                                className="pause-play-button"
                                title={"Start Timer"}
                                onClick={(e) => {
                                    confirmAlert({
                                        message: commonservices.LabelDisplayText(
                                            "AreYouSureWantToStartTask"
                                        ),
                                        buttons: [
                                            {
                                                label: commonservices.LabelDisplayText("Yes"),
                                                onClick: function () {
                                                    let input = {
                                                        taskID: task.TaskID,
                                                        projectID: task.ProjectID,
                                                        stopTaskComment: "",
                                                    };
                                                    const axiosConfig = {
                                                        headers: {
                                                            Accept: "application/json",
                                                            Authorization:
                                                                "Bearer " + localStorage.getItem("token"),
                                                        },
                                                    };
                                                    axios
                                                        .post(
                                                            `${AppConfigData.APIBasePath}v1/task/taskstarttimer`,
                                                            input,
                                                            axiosConfig
                                                        )
                                                        .then((res) => {
                                                            if (res.data.status_code === 0) {
                                                                toast.error(res.data.message);
                                                            } else if (res.data.status_code === 1) {
                                                                toast.success(res.data.message);
                                                                // fnCallTaskStartStopInHub();
                                                                // taskBind();
                                                            } else if (res.data.status_code === 2) {
                                                                localStorage.removeItem("token");
                                                                navigate("/login");
                                                            }
                                                        })
                                                        .catch((err) => { });
                                                },
                                            },
                                            {
                                                label: commonservices.LabelDisplayText("No"),
                                            },
                                        ],
                                    });
                                }}
                            >
                                <i className="fa-regular fa-play"></i>
                            </button> */}
            </>
          );
        }
      }
    } else {
      if (task.RunningCount > 0) {
        if (task.RunningCount == 1 && task.RunningTaskList.length == 1) {
          return (
            <>
              <div className="task-time text-nowrap me-3">
                {commonservices.getTimeInFormat(
                  task.RunningTaskList[0].StartTime
                )}
              </div>
              {/* <button
                                type="button"
                                className="pause-play-button"
                                title={"Running By " + task.RunningTaskList[0].DisplayName + ""}
                            >
                                <i className="fa-regular fa-timer"></i>
                            </button> */}
            </>
          );
        } else {
          return (
            <>
              <OverlayTrigger
                trigger="click"
                key={"bottom"}
                placement={"bottom"}
                overlay={
                  <Popover id={`popover-positioned-bottom`}>
                    <Popover.Body>
                      {task.RunningTaskList.map((item, index) => (
                        <div
                          className="task-time-status d-flex align-items-center justify-content-end"
                          key={index}
                        >
                          <img
                            className="project-img me-2"
                            src={item.ProfilePic}
                            alt={commonservices.getInitials(item.DisplayName)}
                            title={item.DisplayName}
                            width={30}
                            height={30}
                          />
                          <div className="task-time text-nowrap me-3">
                            {commonservices.getTimeInFormat(item.StartTime)}
                          </div>
                        </div>
                      ))}
                    </Popover.Body>
                  </Popover>
                }
              >
                <button
                  type="button"
                  className="pause-play-button"
                  title={"Running By " + task.RunningCount + " User"}
                >
                  <i className="fa-regular fa-timer"></i>
                </button>
              </OverlayTrigger>
              <div className="task-time text-nowrap me-3">
                {task.RunningCount} user
              </div>
            </>
          );
        }
      } else {
        if (task.TotalTimeInMinites > 0) {
          return (
            <>
              <div className="task-time text-nowrap me-3">
                {commonservices.ConvertMinutesToTimeFormat(
                  task.TotalTimeInMinites
                )}{" "}
                hrs
              </div>
            </>
          );
        }
      }
    }
  }

  var projectslider = {
    autoplay: false,
    autoplaySpeed: 5000,
    pauseOnFocus: true,
    pauseOnHover: true,
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    draggable: true,
    variableWidth: true,
    slide: ".black-card",
    centerMargin: "15px",
  };

  let data = commonservices.getLoginUserData();
  const [WorkingHoursChart, setWorkingHoursChart] = useState({
    Data: {
      options: {
        markers: {
          size: 5,
          strokeWidth: 3,
          strokeColors: "#345BF8",
          colors: "#ffffff",
          hover: {
            size: 7,
            strokeWidth: 8,
          },
        },
        xaxis: {
          categories: [],
          axisBorder: { show: true },
          axisTicks: { show: false },
          labels: { show: true },
        },
        yaxis: { show: true },
        grid: { show: true },
        stroke: {
          width: 5,
          curve: "smooth",
        },
        title: {
          text: "Working Hours",
          align: "left",
          style: {
            fontSize: "22px",
            lineHeight: "28px",
            fontWeight: "700",
            fontFamily: "Source Sans Pro",
            color: "#1C212D",
          },
        },
        colors: ["#345BF8"],
        chart: {
          sparkline: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
          background: "#fff",
          type: "line",
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          enabled: true,
          style: {
            fontSize: "20px",
            lineHeight: "24px",
            fontWeight: "700",
            fontFamily: "Source Sans Pro",
            color: "#1C212D",
            textAlign: "center",
          },
          x: {
            show: false,
          },
          marker: {
            show: false,
          },
          fixed: {
            enabled: false,
            position: "topLeft",
            offsetX: "0",
            offsetY: "0",
          },
        },
      },
      series: [
        {
          name: "",
          data: [],
        },
      ],
    },
    IsLoading: true,
  });
  const [WorkingHoursChartMode, setWorkingHoursChartMode] = useState("Week");
  function fnBindWorkingHoursChart(chartMode) {
    setWorkingHoursChartMode(chartMode);
    let ChartDate = WorkingHoursChart.Data;
    let startDate = new Date().toUTCString();
    let endDate = new Date().toUTCString();
    ChartDate.options.xaxis.categories = [];
    ChartDate.series[0].data = [];
    setWorkingHoursChart({ Data: ChartDate, IsLoading: true });
    let curr = new Date();
    if (chartMode.toUpperCase() === "WEEK") {
      let first = curr.getDate() - curr.getDay();
      let last = first + 6;
      startDate = new Date(curr.setDate(first));
      endDate = new Date(curr.setDate(last));
    } else if (chartMode.toUpperCase() === "MONTH") {
      startDate = new Date(curr.getFullYear(), curr.getMonth(), 1);
      endDate = new Date(curr.getFullYear(), curr.getMonth() + 1, 0);
    } else if (chartMode.toUpperCase() === "YEAR") {
      startDate = new Date(curr.getFullYear(), 0, 1);
      endDate = new Date(curr.getFullYear(), 11, 31);
    }

    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(
        `${AppConfigData.APIBasePath}v1/reports/workinghourschartdata`,
        {
          employeeID: data[0].AssociationID,
          startDate: commonservices.getDateInDBFormat(startDate),
          endDate: commonservices.getDateInDBFormat(endDate),
          chartMode: chartMode,
        },
        axiosConfig
      )
      .then((res) => {
        if (res.data.status_code === 0) {
          setWorkingHoursChart({ Data: ChartDate, IsLoading: false });
        } else if (res.data.status_code === 1) {
          let xaxis_categories = [];
          let series_data = [];
          for (let i = 0; i < res.data.data.length; i++) {
            xaxis_categories.push(res.data.data[i].Text);
            series_data.push(res.data.data[i].Value);
          }
          ChartDate.options.xaxis.categories = xaxis_categories;
          ChartDate.series[0].data = series_data;
          setWorkingHoursChart({ Data: ChartDate, IsLoading: false });
        } else if (res.data.status_code === 2) {
          commonservices.ClearLoginDetails();
          navigate("/");
        }
      })
      .catch((err) => {
        setWorkingHoursChart({ Data: ChartDate, IsLoading: false });
      });
  }

  useEffect(() => {
    TaskbindList();
    bindList();
    CountList();
    bindLeaveData();
    fnBindWorkingHoursChart("WEEK");
    UpcomingBirthday();
  }, []);

  function bindLeaveData() {
    setleaveData({
      Data: [],
      IsLoading: true,
    });
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(
        `${AppConfigData.APIBasePath}v1/leaveapplication/getlistforapprove`,
        {
          startDate: null,
          endDate: null,
          title: "",
          leaveType: "",
          applicationStatus: "Pending",
          myApprovalStatus: "Pending",
          searchText: "",
        },
        axiosConfig
      )
      .then((res) => {
        if (res.data.status_code === 0) {
          setleaveData({
            Data: [],
            IsLoading: false,
          });
        } else if (res.data.status_code === 1) {
          setleaveData({ Data: res.data.data, IsLoading: false });
        } else if (res.data.status_code === 2) {
          commonservices.ClearLoginDetails();
          navigate("/");
        }
      })
      .catch((err) => {});
  }

  function fnLeaveOpen() {
    setModalLeaveShow(true);
  }

  // console.log("BirthDayData ::", BirthDayData);

  return (
    <>
      <div className="dashboard">
        <div className="container-fluid p-0">
          <div className="row m-0">
            <div className="col-xxl-8 col-xl-8 col-lg-8 ps-0 pe-xl-4 pe-lg-4 pe-md-0 pe-sm-0">
              <div className="cards">
                <div className="row">
                  <div className="col-4">
                    <Card className="today-task blue-crayola">
                      <Link
                        className={"remove-hover"}
                        to={{ pathname: "/task" }}
                        state={{
                          startDate: commonservices.getDateInDBFormat(
                            new Date()
                          ),
                          endDate: commonservices.getDateInDBFormat(new Date()),
                          lststatus: [
                            { label: "In Process", value: "In Process" },
                            { label: "Not Started", value: "Not Started" },
                          ],
                          status: "In Process,Not Started",
                        }}
                      >
                        <Card.Body className="d-flex justify-content-center align-items-center">
                          <Card.Title>
                            {projectCount.Data.length > 0 &&
                              projectCount.Data.map(
                                (item, index) =>
                                  item.Status === "TodayTask" && (
                                    <>{item.TaskCount}</>
                                  )
                              )}
                          </Card.Title>
                          <Card.Text>
                            Today’s<span className="d-block">Task</span>
                          </Card.Text>
                        </Card.Body>
                      </Link>
                    </Card>
                  </div>
                  <div className="col-4">
                    <Card className="balance-leave go-green">
                      <Link
                        className={"remove-hover"}
                        to={{ pathname: "/task" }}
                        state={{ status: "Closed" }}
                      >
                        <Card.Body className="d-flex justify-content-center align-items-center">
                          <Card.Title>
                            {projectCount.Data.length > 0 &&
                              projectCount.Data.map(
                                (item, index) =>
                                  item.Status === "Closed" && (
                                    <>{item.TaskCount}</>
                                  )
                              )}
                          </Card.Title>
                          <Card.Text>
                            Completed<span className="d-block">task</span>
                          </Card.Text>
                        </Card.Body>
                      </Link>
                    </Card>
                  </div>
                  <div className="col-4">
                    <Card className="profile-complete pastel-orange">
                      <Link
                        className={"remove-hover"}
                        to={{ pathname: "/leave" }}
                      >
                        <Card.Body className="d-flex justify-content-center align-items-center">
                          <Card.Title>
                            {projectCount.Data.length > 0 &&
                              projectCount.Data.map(
                                (item, index) =>
                                  item.Status === "Leave Count" && (
                                    <>{item.TaskCount}</>
                                  )
                              )}
                          </Card.Title>
                          <Card.Text>
                            Leave<span className="d-block">Request</span>
                          </Card.Text>
                        </Card.Body>
                      </Link>
                    </Card>
                  </div>
                </div>
              </div>
              {BirthDayData.Data?.length > 0 && (
                <div className="my-3">
                  <div className="project-title mb-2">Upcoming Birthdays</div>
                  <div className="upcoming-birthday-events-main d-grid">
                    {BirthDayData.Data?.length > 0 &&
                      BirthDayData.Data?.map((item, index) => (
                        // <Card className="birthdToday blue-crayola">
                        <Card
                          className={`birthdToday ${
                            moment(item?.DOB).format("MM DD") ===
                            moment().format("MM DD")
                              ? "blue-crayola"
                              : "pastel-orange"
                          }`}
                        >
                          <Card.Img
                            variant="top"
                            src={
                              item?.ProfilePic !== ""
                                ? item?.ProfilePic
                                : placeholderImg
                            }
                            alt="placeholder"
                          />
                          <Card.Body>
                            <Card.Title>
                              {item?.FirstName} {item?.LastName}
                              <p
                                className="mb-0"
                                style={{
                                  fontSize: "15px",
                                  fontStyle: "italic",
                                }}
                              >
                                ({item?.Position})
                              </p>
                            </Card.Title>
                            <Card.Text>
                              <p className="mb-0" style={{ fontSize: "17px" }}>
                                {/* 25/03/1990 */}
                                {commonservices.getDateInDDMMYYYY(item?.DOB)}
                              </p>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      ))}

                    {/* <Card className="pastel-orange">
                      <Card.Img
                        variant="top"
                        src={placeholderImg}
                        alt="placeholder"
                      />
                      <Card.Body>
                        <Card.Title>
                          Nilima Javade{" "}
                          <p
                            className="mb-0"
                            style={{ fontSize: "15px", fontStyle: "italic" }}
                          >
                            (Designer)
                          </p>
                        </Card.Title>
                        <Card.Text>
                          <p className="mb-0" style={{ fontSize: "17px" }}>
                            18/06/1997
                          </p>
                        </Card.Text>
                      </Card.Body>
                    </Card>

                    <Card className="pastel-orange">
                      <Card.Img
                        variant="top"
                        src={placeholderImg}
                        alt="placeholder"
                      />
                      <Card.Body>
                        <Card.Title>
                          Rinkal Khatri{" "}
                          <p
                            className="mb-0"
                            style={{ fontSize: "15px", fontStyle: "italic" }}
                          >
                            (Software Developer)
                          </p>
                        </Card.Title>
                        <Card.Text>
                          <p className="mb-0" style={{ fontSize: "17px" }}>
                            07/09/1997
                          </p>
                        </Card.Text>
                      </Card.Body>
                    </Card>

                    <Card className="pastel-orange">
                      <Card.Img
                        variant="top"
                        src={placeholderImg}
                        alt="placeholder"
                      />
                      <Card.Body>
                        <Card.Title>
                          Jay Shah{" "}
                          <p
                            className="mb-0"
                            style={{ fontSize: "15px", fontStyle: "italic" }}
                          >
                            (Front end developer)
                          </p>
                        </Card.Title>
                        <Card.Text>
                          <p className="mb-0" style={{ fontSize: "17px" }}>
                            24/02/1998
                          </p>
                        </Card.Text>
                      </Card.Body>
                    </Card> */}
                  </div>
                </div>
              )}

              <div className="my-2">
                {WorkingHoursChart.IsLoading ? (
                  <Loader type={LoaderType.Line} />
                ) : (
                  <div className="home-chart">
                    <div className="home-charts-buttons">
                      <div className="selector">
                        <div className="selecotr-item">
                          <input
                            type="radio"
                            id="WorkingHoursYear"
                            name="WorkingHoursChartType"
                            className="selector-item_radio"
                            checked={
                              WorkingHoursChartMode.toUpperCase() === "YEAR" &&
                              "checked"
                            }
                            onChange={(e) => fnBindWorkingHoursChart("YEAR")}
                          />
                          <label
                            htmlFor="WorkingHoursYear"
                            className="selector-item_label"
                          >
                            Year
                          </label>
                        </div>
                        <div className="selecotr-item">
                          <input
                            type="radio"
                            id="WorkingHoursMonth"
                            name="WorkingHoursChartType"
                            className="selector-item_radio"
                            checked={
                              WorkingHoursChartMode.toUpperCase() === "MONTH" &&
                              "checked"
                            }
                            onChange={(e) => fnBindWorkingHoursChart("MONTH")}
                          />
                          <label
                            htmlFor="WorkingHoursMonth"
                            className="selector-item_label"
                          >
                            Month
                          </label>
                        </div>
                        <div className="selecotr-item">
                          <input
                            type="radio"
                            id="WorkingHoursWeek"
                            name="WorkingHoursChartType"
                            className="selector-item_radio"
                            checked={
                              WorkingHoursChartMode.toUpperCase() === "WEEK" &&
                              "checked"
                            }
                            onChange={(e) => fnBindWorkingHoursChart("WEEK")}
                          />
                          <label
                            htmlFor="WorkingHoursWeek"
                            className="selector-item_label"
                          >
                            Week
                          </label>
                        </div>
                      </div>
                    </div>
                    {WorkingHoursChart.Data.series[0].data.length > 0 ? (
                      <Chart
                        type="line"
                        options={WorkingHoursChart.Data.options}
                        series={WorkingHoursChart.Data.series}
                        className="w-100"
                        height={380}
                      />
                    ) : (
                      <Loader type={LoaderType.NODATAFOUND} />
                    )}
                  </div>
                )}
              </div>
              <div className="project-carts-col">
                <Row>
                  <Col xs={12}>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <div className="project-title me-3">
                        {commonservices.LabelDisplayText("Projects")}
                      </div>
                      {commonservices.getLoginRoleData(
                        "Project",
                        "IsCreate"
                      ) && (
                        <div className="project-add-icon">
                          <Button
                            className="project-btn"
                            title="Add"
                            onClick={(e) => setModalAddProject(true)}
                          >
                            <i className="fa-regular fa-plus"></i>
                          </Button>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <Row>
                      <Col xs={12}>
                        <div className="project-cards">
                          {projectData.IsLoading ? (
                            <>
                              <Loader
                                type={LoaderType.PROJECTSLIDER}
                                count={3}
                              />
                            </>
                          ) : (
                            <>
                              {projectData.Data.length > 0 ? (
                                <Slider {...projectslider}>
                                  {projectData.Data.map((item, index) => (
                                    <Card
                                      className={`${
                                        ProjectIndex === 1 &&
                                        "ultramarine-blue-card ultramarine-blue"
                                      } ${
                                        ProjectIndex === 2 &&
                                        "orange-peel-card orange-peel"
                                      } ${
                                        ProjectIndex === 3 &&
                                        "carmine-pink-card carmine-pink"
                                      } ${
                                        ProjectIndex === 4 &&
                                        "caribbean-green-card caribbean-green"
                                      } ${
                                        ProjectIndex > 3
                                          ? (ProjectIndex = 1)
                                          : (ProjectIndex = ProjectIndex + 1)
                                      }`}
                                    >
                                      <Link
                                        className={"remove-hover"}
                                        to={{
                                          pathname:
                                            "/project-details/" +
                                            item.ProjectID,
                                        }}
                                      >
                                        <Card.Body>
                                          <div className="d-flex justify-content-between ">
                                            <div className="d-flex">
                                              <img
                                                className="card-img-center center rounded-circle"
                                                // src={item.ProjectLogo}
                                                src={
                                                  item.ProjectLogo !== null &&
                                                  item.ProjectLogo !== ""
                                                    ? item.ProjectLogo
                                                    : ProjectPlaceholder
                                                }
                                                alt={item.ProjectName}
                                                width="80"
                                                height="80"
                                              />
                                              <div>
                                                <Card.Title>
                                                  {item.ProjectName}
                                                </Card.Title>
                                                <div className="task-date">
                                                  <div className="d-flex align-items-center">
                                                    <CalendarIcon />
                                                    <div className="ms-2">
                                                      {commonservices.getDateInDDMMYYYY(
                                                        item.StartDate
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="project-cards-labels">
                                                  {item.Technologies &&
                                                    item.Technologies.split(
                                                      ","
                                                    ).map(
                                                      (
                                                        Technoitem,
                                                        Technoindex
                                                      ) =>
                                                        Technoitem !== "" && (
                                                          <label
                                                            key={Technoindex}
                                                          >
                                                            {Technoitem}
                                                          </label>
                                                        )
                                                    )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="mt-3 mb-3 card-body-inner">
                                            <div className="ProgressBar-content mb-1 d-flex align-items-center justify-content-between">
                                              <div className="ProgressBar-text">
                                                {commonservices.LabelDisplayText(
                                                  "Progress"
                                                )}
                                              </div>
                                              <div className="ProgressBar-percentage">
                                                {item.AllTaskCount !== 0
                                                  ? (
                                                      (item.ClosedTaskCount *
                                                        100) /
                                                      item.AllTaskCount
                                                    ).toFixed(2)
                                                  : item.AllTaskCount.toFixed(
                                                      2
                                                    )}
                                                %
                                              </div>
                                            </div>
                                            <ProgressBar
                                              now={
                                                item.AllTaskCount !== 0
                                                  ? (
                                                      (item.ClosedTaskCount *
                                                        100) /
                                                      item.AllTaskCount
                                                    ).toFixed(2)
                                                  : item.AllTaskCount
                                              }
                                            />
                                          </div>
                                          <div className="d-flex justify-content-between align-items-center">
                                            <div className="d-flex justify-content-between align-items-center me-3">
                                              <div>
                                                <label className="label-badges in-progress">
                                                  {item.Status}
                                                </label>
                                              </div>
                                              <div className="task-label">
                                                <i className="fa-solid fa-list-check"></i>
                                                <span>
                                                  {item.AllTaskCount}{" "}
                                                  {commonservices.LabelDisplayText(
                                                    "Task"
                                                  )}
                                                </span>
                                              </div>
                                            </div>
                                            <div className="project-details">
                                              <div className="users">
                                                {projectUser.length > 0 &&
                                                  projectUser
                                                    .filter(
                                                      (P) =>
                                                        P.ProjectID ==
                                                        item.ProjectID
                                                    )
                                                    .map(
                                                      (useritem, index) =>
                                                        index < 3 && (
                                                          <>
                                                            <img
                                                              className="project-img mt-0 mb-0 rounded-circle"
                                                              // src={
                                                              //   useritem.ProfilePic
                                                              // }
                                                              src={
                                                                useritem.ProfilePic !==
                                                                  null &&
                                                                useritem.ProfilePic !==
                                                                  ""
                                                                  ? useritem.ProfilePic
                                                                  : UserPlaceholder
                                                              }
                                                              alt={commonservices.getInitials(
                                                                useritem.DisplayName
                                                              )}
                                                              width={50}
                                                              height={50}
                                                              key={index}
                                                            />
                                                          </>
                                                        )
                                                    )}
                                                {projectUser.filter(
                                                  (P) =>
                                                    P.ProjectID ==
                                                    item.ProjectID
                                                ).length > 3 && (
                                                  <div className="add-user">
                                                    <span>
                                                      {projectUser.filter(
                                                        (P) =>
                                                          P.ProjectID ==
                                                          item.ProjectID
                                                      ).length - 3}
                                                      +
                                                    </span>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </Card.Body>
                                      </Link>
                                    </Card>
                                  ))}
                                </Slider>
                              ) : (
                                <Loader type={LoaderType.NODATAFOUND} />
                              )}
                            </>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 scroll pe-0 pe-xl-0 pe-lg-0 pe-md-0 ps-sm-0">
              <div className="table-section-col leave-request-col">
                <div className="d-flex align-items-center justify-content-between table-header-col">
                  <div className="project-title me-3">
                    {commonservices.LabelDisplayText("LeaveRequest")}
                  </div>
                  {data[0].AssociationID !== 0 && (
                    <div className="project-add-icon">
                      <button
                        type="button"
                        className="project-btn btn btn-primary"
                        title="Add"
                        onClick={(e) => fnLeaveOpen()}
                      >
                        <i className="fa-regular fa-plus"></i>
                      </button>
                    </div>
                  )}
                </div>
                {!leaveData.IsLoading &&
                  (leaveData.Data.length === 0 ? (
                    <Loader type={LoaderType.NODATAFOUND} />
                  ) : (
                    <Table responsive className="leave-request-table mb-0 pb-0">
                      <tbody>
                        {leaveData.Data.map((itemLeave, rowindex) => (
                          <tr
                            className="table-hight-remove"
                            onClick={(e) => {
                              setLeaveApplicationID(
                                itemLeave.LeaveApplicationID
                              );
                              setIsShowApproveRejectLeaveModal(true);
                            }}
                          >
                            <td className="width-50px padding-top-bottom pe-0">
                              <img
                                className="leave-request-user-img rounded-circle"
                                // src={itemLeave.ProfilePic}
                                src={
                                  itemLeave.ProfilePic !== null &&
                                  itemLeave.ProfilePic !== ""
                                    ? itemLeave.ProfilePic
                                    : UserPlaceholder
                                }
                                alt={commonservices.getInitials(
                                  itemLeave.EmployeeName
                                )}
                                title={itemLeave.EmployeeName}
                                width="50"
                                height="50"
                              />
                            </td>
                            <td className="text-nowrap padding-top-bottom">
                              <div className="leave-reason">
                                {itemLeave.Title}
                              </div>
                            </td>
                            <td className="text-end width-125px">
                              <div className="d-flex text-nowrap justify-content-end">
                                <div className="apply-date">
                                  {commonservices.getDateInDDMMYYYY(
                                    itemLeave.StartDate
                                  )}
                                </div>
                                <div
                                  className="status-icon"
                                  title={itemLeave.ApplicationStatus}
                                >
                                  <img
                                    src={commonservices.GetLeaveStatusIcon(
                                      itemLeave.ApplicationStatus
                                    )}
                                  />
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ))}
              </div>
              <div className="table-section-col recent-task-col mt-3">
                <div className="d-flex align-items-center justify-content-between table-header-col">
                  <div className="project-title me-3">recent tasks</div>
                  <div className="project-add-icon">
                    <button
                      type="button"
                      title="Add"
                      className="project-btn btn btn-primary"
                      onClick={(e) => fnOpenTaskAddEditPopup()}
                    >
                      <i className="fa-regular fa-plus"></i>
                    </button>
                  </div>
                </div>

                <div className="">
                  {Object.keys(taskData.Data)
                    .sort()
                    .reverse()
                    .map((item, index) => (
                      <div className="recent-task-section table-inner-section-col">
                        <div className="table-scroll">
                          <div className="recent-task-date-time">
                            <div className="task-date-time">
                              {commonservices.getDays(
                                commonservices.getDateInDDMMYYYY(
                                  taskData.Data[item].Date
                                )
                              )}
                            </div>
                            <div className="total-time">
                              {commonservices.ConvertMinutesToTimeFormat(
                                taskData.Data[item].TotalTimeInMinites
                              )}{" "}
                              {commonservices.LabelDisplayText("Hrs")}
                            </div>
                          </div>
                          <Table
                            responsive
                            className="table-inner-section mb-0 pb-0"
                          >
                            <tbody>
                              {taskData.Data[item].tasklist.length > 0 &&
                                taskData.Data[item].tasklist.map(
                                  (itemTask, rowindex) => (
                                    <tr>
                                      <td
                                        className="text-nowrap padding-top-bottom"
                                        onClick={(e) => {
                                          setTaskDataForShowDetails({
                                            TaskID: itemTask.TaskID,
                                            ProjectID: itemTask.ProjectID,
                                          });
                                          setModalShowDetails(true);
                                        }}
                                      >
                                        <div className="d-flex align-items-center">
                                          <img
                                            className="project-short-img rounded-circle"
                                            // src={itemTask.ProjectLogo}
                                            src={
                                              itemTask.ProjectLogo !== null &&
                                              itemTask.ProjectLogo !== ""
                                                ? itemTask.ProjectLogo
                                                : ProjectPlaceholder
                                            }
                                            alt=""
                                            width="50"
                                            height="50"
                                          />
                                          <div className="content-project-col w-100">
                                            <div className="project-name">
                                              {itemTask.ProjectName}
                                            </div>
                                            <div className="project-description project-desh-incontant">
                                              {itemTask.Title}
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                      <td className="text-end pe-0">
                                        <div className="">
                                          <img
                                            src={commonservices.GetPriorityIcon(
                                              itemTask.Priority
                                            )}
                                            alt={itemTask.Priority}
                                            title={itemTask.Priority}
                                          />
                                        </div>
                                      </td>
                                      <td className="text-center padding-top-bottom width-150px">
                                        <div className="d-flex justify-content-end">
                                          <div className="task-time">
                                            {fnCreateTaskButtonAndTimeUI(
                                              itemTask
                                            )}
                                          </div>
                                          <div
                                            className="status-icon"
                                            title={itemTask.Status}
                                          >
                                            {commonservices.GetTaskStatusIcon(
                                              itemTask.Status
                                            )}
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                )}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modalShowDetails && (
        <TaskDetail
          details={TaskDataForShowDetails}
          show={modalShowDetails}
          onHide={() => setModalShowDetails(false)}
        />
      )}
      {modalAddProject && (
        <AddProject
          show={modalAddProject}
          bindList={bindList}
          onHide={() => setModalAddProject(false)}
        />
      )}

      {modalTaskAdd && (
        <AddTaskModal
          show={modalTaskAdd}
          bindList={TaskbindList}
          onHide={() => setModalTaskAdd(false)}
        />
      )}

      {modalLeaveShow && (
        <AddleaveModal
          show={modalLeaveShow}
          associationID={data[0].AssociationID}
          associationType={"EMPLOYEES"}
          bindList={bindLeaveData}
          onHide={() => setModalLeaveShow(false)}
        />
      )}

      {IsShowApproveRejectLeaveModal && (
        <ApproveRejectLeaveModal
          show={IsShowApproveRejectLeaveModal}
          leaveApplicationID={LeaveApplicationID}
          bindList={bindLeaveData}
          onHide={() => setIsShowApproveRejectLeaveModal(false)}
        />
      )}
    </>
  );
}

export default Dashboard;
