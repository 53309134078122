import axios from "axios";
import { useState, useEffect } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { default as React } from "react";
import { toast } from "react-toastify";
import * as yup from "yup";
import { commonservices, AppConfigData } from "../Services/commonService";
import { Col, Modal, Button, Container, Row, Table } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";

const AddRoleModal = (props) => {
    const [IsGetDetailsDone, setIsGetDetailsDone] = useState(false);
    const [lstRoleRight, setlstRoleRight] = useState([]);
    const [Submitting, setSubmitting] = useState(false);
    const [checkedRole, setCheckedRole] = useState(false);
    const [IsValidationMessageSet, setIsValidationMessageSet] = useState("");

    useEffect(() => {
        setIsValidationMessageSet(true);
    });
    const handleChange = () => {
        setCheckedRole(!checkedRole);
    };


    const formFieldLength = {
        RoleName: 67,
    };

    const formInitialSchema = {
        RoleID: 0,
        RoleName: "",
        IsProjectRole: false,
    };

    const formValidation = yup.object().shape({
        RoleName: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "RoleName"))
            .test('len', commonservices.MakeValidationMessage("maxlength", ""), (value, obj) => { return value ? value.length <= formFieldLength.RoleName : true }),
        IsProjectRole: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "IsProjectRole")),
    });

    useEffect(() => {
        const axiosConfig = {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        };
        axios
            .post(`${AppConfigData.APIBasePath}v1/role/getrightlist`, {}, axiosConfig)
            .then((res) => {
                if (res.data.status_code === 0) {
                } else if (res.data.status_code === 1) {
                    let lst = [];
                    res.data.data.forEach((element) => {
                        lst.push({
                            RoleRightID: 0,
                            RoleID: 0,
                            RightID: element.RightID,
                            IsView: false,
                            IsCreate: false,
                            IsUpdate: false,
                            IsDelete: false,
                            IsDownload: false,
                            RightName: element.RightName,
                            MenuName: element.MenuName,
                            IsProjectSubMenu: element.IsProjectSubMenu,
                        });
                    });

                    if (props.id > 0) {
                        axios
                            .post(
                                `${AppConfigData.APIBasePath}v1/role/getdetailsbyid`,
                                {
                                    id: props.id,
                                    requestFrom: "Web",
                                },
                                axiosConfig
                            )
                            .then((res) => {
                                if (res.data.status_code === 0) {
                                } else if (res.data.status_code === 1) {
                                    formInitialSchema.RoleID = res.data.data.role.RoleID;
                                    formInitialSchema.RoleName = commonservices.CheckNullAndReturnBlank(res.data.data.role.RoleName);
                                    formInitialSchema.IsProjectRole =
                                        res.data.data.role.IsProjectRole === 1 ? true : false;

                                    setCheckedRole(
                                        res.data.data.role.IsProjectRole === 1 ? true : false
                                    );

                                    res.data.data.rightLst.forEach((element) => {
                                        let index = lst.indexOf(
                                            lst.find((e) => e.RightID === element.RightID)
                                        );
                                        lst[index].RightID = element.RightID;
                                        lst[index].RoleRightID = element.RoleRightID;
                                        lst[index].IsProjectSubMenu = element.IsProjectSubMenu;
                                        lst[index].IsView = element.IsView === 1 ? true : false;
                                        lst[index].IsCreate = element.IsCreate === 1 ? true : false;
                                        lst[index].IsDelete = element.IsDelete === 1 ? true : false;
                                        lst[index].IsUpdate = element.IsUpdate === 1 ? true : false;
                                        lst[index].IsDownload =
                                            element.IsDownload === 1 ? true : false;
                                    });
                                    setlstRoleRight(lst);

                                    setIsGetDetailsDone(true);
                                } else if (res.data.status_code === 2) {
                                } else {
                                }
                            })
                            .catch((err) => { });
                    } else {
                        setlstRoleRight(lst);
                        setIsGetDetailsDone(true);
                    }
                } else if (res.data.status_code === 2) {
                } else {
                }
            })
            .catch((err) => { });
    }, []);

    function fnCheckUncheck(e, RightID, checkon) {
        let lst = [...lstRoleRight];
        lst.forEach((element) => {
            if (element.RightID === RightID) {
                element[checkon] = !element[checkon];

                if (checkon === "IsCreate") {
                    if (element[checkon]) {
                        element["IsView"] = element[checkon];
                    } else {
                        element["IsUpdate"] = false;
                        element["IsDelete"] = false;
                    }
                }

                if (checkon === "IsView") {
                    if (element[checkon]) {
                        // element["IsView"] = element[checkon];
                    } else {
                        element["IsCreate"] = false;
                        element["IsUpdate"] = false;
                        element["IsDelete"] = false;
                    }
                }

                if (checkon === "IsUpdate") {
                    if (element[checkon]) {
                        element["IsView"] = element[checkon];
                        element["IsCreate"] = element[checkon];
                    } else {
                        element["IsDelete"] = false;
                    }
                }

                if (checkon === "IsDelete") {
                    if (element[checkon]) {
                        element["IsView"] = element[checkon];
                        element["IsCreate"] = element[checkon];
                        element["IsUpdate"] = element[checkon];
                    } else {
                        // element["IsDelete"]=false;
                    }
                }
            }
        });
        setlstRoleRight(lst);
    }

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                // centered
                className="right-modal modal-new role-modal"
                scrollable={true}
                backdrop="static"
                keyboard={false}
            >
                <Formik
                    initialValues={formInitialSchema}
                    validationSchema={formValidation}
                    onSubmit={async (values) => {
                        setSubmitting(true);
                        const formData = new FormData();
                        formData.append("RoleID", values.RoleID);
                        formData.append("RoleName", values.RoleName);
                        formData.append("IsProjectRole", values.IsProjectRole);

                        let NoOfDoc = 0;
                        lstRoleRight.forEach((element) => {
                            if (element.IsProjectSubMenu === (checkedRole ? 1 : 0)) {
                                formData.append(
                                    "lstRight[" + NoOfDoc + "].roleRightID",
                                    element.RoleRightID
                                );
                                formData.append(
                                    "lstRight[" + NoOfDoc + "].roleID",
                                    element.RoleID
                                );
                                formData.append(
                                    "lstRight[" + NoOfDoc + "].rightID",
                                    element.RightID
                                );
                                formData.append(
                                    "lstRight[" + NoOfDoc + "].isView",
                                    element.IsView ? 1 : 0
                                );
                                formData.append(
                                    "lstRight[" + NoOfDoc + "].isCreate",
                                    element.IsCreate ? 1 : 0
                                );
                                formData.append(
                                    "lstRight[" + NoOfDoc + "].isUpdate",
                                    element.IsUpdate ? 1 : 0
                                );
                                formData.append(
                                    "lstRight[" + NoOfDoc + "].isDelete",
                                    element.IsDelete ? 1 : 0
                                );
                                formData.append(
                                    "lstRight[" + NoOfDoc + "].isDownload",
                                    element.IsDownload ? 1 : 0
                                );
                                NoOfDoc = NoOfDoc + 1;
                            }
                        });

                        const axiosConfig = {
                            headers: {
                                "content-type": "multipart/form-data",
                                Authorization: "Bearer " + localStorage.getItem("token"),
                            },
                        };
                        const response = await axios
                            .post(
                                `${AppConfigData.APIBasePath}v1/role/save`,
                                formData,
                                axiosConfig
                            )
                            .then((response) => {
                                setSubmitting(false);
                                if (response.data.status_code === 0) {
                                    toast.error(response.data.message);
                                } else if (response.data.status_code === 1) {
                                    toast.success(response.data.message);
                                    props.bindList();
                                    props.onHide();
                                    //setTimeout(() => {
                                    //  props.onHide();
                                    //}, 1000);
                                } else if (response.data.status_code === 2) {
                                    toast.error(response.data.message);
                                } else {
                                    toast.error(response.data.message);
                                }
                            })
                            .catch((error) => { });
                    }}
                >
                    <Form>
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                {commonservices.LabelDisplayText("AddRoleDetails")}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Container>
                                <Row>
                                    <Row className="mb-3">
                                        <Col xs={6}>
                                            <Field
                                                type="hidden"
                                                name="RoleID"
                                                className="form-control"
                                                placeholder="RoleID"
                                            />
                                            <div className="form-floating">
                                                <Field
                                                    type="text"
                                                    name="RoleName"
                                                    className="form-control"
                                                    placeholder={commonservices.LabelDisplayText(
                                                        "RoleName"
                                                    )}
                                                    maxLength={formFieldLength.RoleName}
                                                />
                                                <label lbl-for="RoleName">
                                                    {commonservices.LabelDisplayText("RoleName")}
                                                </label>
                                                <div className="text-danger-error">
                                                    <ErrorMessage name="RoleName" />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={6} className="mt-auto mb-auto role-label">
                                            <div className="form-group">
                                                <Field
                                                    type="checkbox"
                                                    name="IsProjectRole"
                                                    onClick={handleChange}
                                                    id="IsProjectRole"
                                                />
                                                <label className="ms-2" lbl-for="IsProjectRole">
                                                    {commonservices.LabelDisplayText("IsProjectRole")}
                                                </label>
                                                <span className="checkmark"></span>

                                                <div className="text-danger-error">
                                                    <ErrorMessage name="IsProjectRole" />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Col xs={12}>
                                        <div className="DocumentsHeading">
                                            <h3>Roles</h3>
                                        </div>
                                        <div className="form-group">
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th className="width-200px text-nowrap">
                                                            {commonservices.LabelDisplayText("RightName")}
                                                        </th>
                                                        <th className="text-nowrap">
                                                            {commonservices.LabelDisplayText("View")}
                                                        </th>
                                                        <th className="text-nowrap">
                                                            {commonservices.LabelDisplayText("Create")}
                                                        </th>
                                                        <th className="text-nowrap">
                                                            {commonservices.LabelDisplayText("Update")}
                                                        </th>
                                                        <th className="text-nowrap">
                                                            {commonservices.LabelDisplayText("Delete")}
                                                        </th>
                                                        <th className="text-nowrap">
                                                            {commonservices.LabelDisplayText("Download")}
                                                        </th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {lstRoleRight.length > 0 &&
                                                        (checkedRole === true || checkedRole === false) &&
                                                        lstRoleRight.map((item, index) =>
                                                            item.IsProjectSubMenu ===
                                                                (checkedRole ? 1 : 0) ? (
                                                                <tr key={index}>
                                                                    <td className="text-nowrap">
                                                                        <Field
                                                                            type="hidden"
                                                                            name={`RoleRightID` + item.RightID}
                                                                            values={item.RoleRightID}
                                                                        />
                                                                        <Field
                                                                            type="hidden"
                                                                            name={`RoleID` + item.RightID}
                                                                            values={item.RoleID}
                                                                        />
                                                                        <Field
                                                                            type="hidden"
                                                                            name={`RightID` + item.RightID}
                                                                            values={item.RightID}
                                                                        />
                                                                        {item.MenuName}
                                                                    </td>
                                                                    <td className="text-nowrap">
                                                                        <Field
                                                                            type="checkbox"
                                                                            name={`IsView` + item.RightID}
                                                                            checked={item.IsView}
                                                                            onChange={(e) =>
                                                                                fnCheckUncheck(
                                                                                    e,
                                                                                    item.RightID,
                                                                                    "IsView"
                                                                                )
                                                                            }
                                                                        />
                                                                        <span className="checkmark"></span>
                                                                    </td>

                                                                    <td className="text-nowrap">
                                                                        <Field
                                                                            type="checkbox"
                                                                            name={`IsCreate` + item.RightID}
                                                                            checked={item.IsCreate}
                                                                            onChange={(e) =>
                                                                                fnCheckUncheck(
                                                                                    e,
                                                                                    item.RightID,
                                                                                    "IsCreate"
                                                                                )
                                                                            }
                                                                        />
                                                                        <span className="checkmark"></span>
                                                                    </td>
                                                                    <td className="text-nowrap">
                                                                        <Field
                                                                            type="checkbox"
                                                                            name={`IsUpdate` + item.RightID}
                                                                            checked={item.IsUpdate}
                                                                            onChange={(e) =>
                                                                                fnCheckUncheck(
                                                                                    e,
                                                                                    item.RightID,
                                                                                    "IsUpdate"
                                                                                )
                                                                            }
                                                                        />
                                                                        <span className="checkmark"></span>
                                                                    </td>
                                                                    <td className="text-nowrap">
                                                                        <Field
                                                                            type="checkbox"
                                                                            name={`IsDelete` + item.RightID}
                                                                            checked={item.IsDelete}
                                                                            onChange={(e) =>
                                                                                fnCheckUncheck(
                                                                                    e,
                                                                                    item.RightID,
                                                                                    "IsDelete"
                                                                                )
                                                                            }
                                                                        />
                                                                        <span className="checkmark"></span>
                                                                    </td>
                                                                    <td className="text-nowrap">
                                                                        <Field
                                                                            type="checkbox"
                                                                            name={`IsDownload` + item.RightID}
                                                                            checked={item.IsDownload}
                                                                            onChange={(e) =>
                                                                                fnCheckUncheck(
                                                                                    e,
                                                                                    item.RightID,
                                                                                    "IsDownload"
                                                                                )
                                                                            }
                                                                        />
                                                                        <span className="checkmark"></span>
                                                                    </td>
                                                                </tr>
                                                            ) : (
                                                                <></>
                                                            )
                                                        )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                </Row>
                                <Row></Row>
                            </Container>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={props.onHide} className="Cancel-btn" title="Cancel">
                                {commonservices.LabelDisplayText("Cancel")}
                            </Button>
                            <Button
                                className="btn btn-primary save-btn"
                                type="submit"
                                title="Submit"
                                disabled={Submitting}
                            >
                                {Submitting && (
                                    <>
                                        <Spinner animation="border" />
                                    </>
                                )}
                                {commonservices.LabelDisplayText("Save")}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Formik>
            </Modal>
        </>
    );
};

export default AddRoleModal;
