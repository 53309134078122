import axios from "axios";
import React, { useState, useEffect } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import * as yup from "yup";
import { commonservices , AppConfigData } from "../Services/commonService";
import { Col, Modal, Button, Container, Row, Table } from "react-bootstrap";
import { useSearchParams, useNavigate, useParams } from "react-router-dom";
import Select from 'react-select';

const ModalFiles = (props) => {

  const [Files, setFiles] = useState([]);

  useEffect(() => {
    if (props) {  
      setFiles(props.id);
    }
  }, []);

  return (
    <>
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          {Files.DocName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe className="allFile_iframe" src={Files.FilePath} title="files" />
        </Modal.Body>
        <Modal.Footer>
          <Button className="Cancel-btn my-3" title="Close" onClick={props.onHide}>{commonservices.LabelDisplayText('Close')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalFiles;
