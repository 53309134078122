import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

const viewFilePopupSubject = new Subject();
const defaultId = 'default-viewFilePopup';

export const viewFilePopupService = {
    onViewFilePopup,
    viewFilePopup,
    clear
};

// enable subscribing to viewFilePopup observable
function onViewFilePopup(id = defaultId) {
    return viewFilePopupSubject.asObservable().pipe(filter(x => x && x.id === id));
}

// core viewFilePopup method
function viewFilePopup(viewFilePopup) {
    viewFilePopup.id = viewFilePopup.id || defaultId;
    viewFilePopupSubject.next(viewFilePopup);
}

// clear viewFilePopup
function clear(id = defaultId) {
    viewFilePopupSubject.next({ id });
}