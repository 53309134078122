import axios from "axios";
import React, { useState, useEffect } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import * as yup from "yup";
import { commonservices, AppConfigData } from "../Services/commonService";
import { Col, Modal, Button, Container, Row, Table } from "react-bootstrap";
import { useSearchParams, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import Spinner from "react-bootstrap/Spinner";

const AddExperienceModal = (props) => {
    let param = useParams();
    const [Submitting, setSubmitting] = useState(false);
    const [IsGetDetailsDone, setIsGetDetailsDone] = useState(false);
    const [IsValidationMessageSet, setIsValidationMessageSet] = useState("");

    useEffect(() => {
        setIsValidationMessageSet(true);
    });

    const formFieldLength = {
        CompanyName: 67,
        Address: 267,
        Duration: 67,
        Designation: 67,
        GrossIncome: 18,
        ResonForLeving: 167,
        Responsibilities: 167,
    }

    const formInitialSchema = {
        EmpWorkExperienceID: 0,
        CompanyName: "",
        Address: "",
        Duration: "",
        Designation: "",
        GrossIncome: "",
        ResonForLeving: "",
        Responsibilities: "",
        EmployeeID: "",
    };

    const formValidation = yup.object().shape({
        CompanyName: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "CompanyName"))
            .test('len', commonservices.MakeValidationMessage("maxlength", ""), (value, obj) => { return value ? value.length <= formFieldLength.CompanyName : true }),
        Address: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "Address"))
            .test('len', commonservices.MakeValidationMessage("maxlength", ""), (value, obj) => { return value ? value.length <= formFieldLength.Address : true }),
        Duration: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "Duration"))
            .test('len', commonservices.MakeValidationMessage("maxlength", ""), (value, obj) => { return value ? value.length <= formFieldLength.Duration : true }),
        Designation: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "Designation"))
            .test('len', commonservices.MakeValidationMessage("maxlength", ""), (value, obj) => { return value ? value.length <= formFieldLength.Designation : true }),
        GrossIncome: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "GrossIncome"))
            .test('len', commonservices.MakeValidationMessage("maxlength", ""), (value, obj) => { return value ? value.length <= formFieldLength.GrossIncome : true }),
        ResonForLeving: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "ResonForLeving"))
            .test('len', commonservices.MakeValidationMessage("maxlength", ""), (value, obj) => { return value ? value.length <= formFieldLength.ResonForLeving : true }),
        Responsibilities: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "Responsibilities"))
            .test('len', commonservices.MakeValidationMessage("maxlength", ""), (value, obj) => { return value ? value.length <= formFieldLength.Responsibilities : true }),
    });

    useEffect(() => {
        if (props.id > 0) {
            const axiosConfig = {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            };
            axios
                .post(
                    `${AppConfigData.APIBasePath}v1/empworkexperience/getdetailsbyid`,
                    {
                        id: props.id,
                        requestFrom: "Web",
                    },
                    axiosConfig
                )
                .then((res) => {
                    if (res.data.status_code === 0) {
                        toast.error(res.data.message);
                    } else if (res.data.status_code === 1) {
                        commonservices.CheckNullAndReturnBlankInObjectKey(
                            res.data.data.empworkexperience
                        );
                        formInitialSchema.EmpWorkExperienceID =
                            res.data.data.empworkexperience.empWorkExperienceID;
                        formInitialSchema.CompanyName =
                           res.data.data.empworkexperience.companyName;
                        formInitialSchema.Address = res.data.data.empworkexperience.address;
                        formInitialSchema.Duration =
                            res.data.data.empworkexperience.duration;
                        formInitialSchema.Designation =
                            res.data.data.empworkexperience.designation;
                        formInitialSchema.GrossIncome =
                            res.data.data.empworkexperience.grossIncome;
                        formInitialSchema.ResonForLeving =
                            res.data.data.empworkexperience.resonForLeving;
                        formInitialSchema.Responsibilities =
                           res.data.data.empworkexperience.responsibilities;
                        formInitialSchema.EmployeeID =
                            res.data.data.empworkexperience.employeeID;
                        setIsGetDetailsDone(true);
                    } else if (res.data.status_code === 2) {
                        toast.error(res.data.message);
                    } else {
                        toast.error(res.data.message);
                    }
                })
                .catch((err) => { });
        }
    }, []);

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                className="right-modal modal-new"
                // centered
                scrollable={true}
                backdrop="static"
                keyboard={false}
            >
                <Formik
                    initialValues={formInitialSchema}
                    validationSchema={formValidation}
                    onSubmit={async (values) => {
                        setSubmitting(true);
                        const formData = new FormData();
                        formData.append("EmpWorkExperienceID", values.EmpWorkExperienceID);
                        formData.append("CompanyName", values.CompanyName);
                        formData.append("Address", values.Address);
                        formData.append("Duration", values.Duration);
                        formData.append("Designation", values.Designation);
                        formData.append("GrossIncome", values.GrossIncome);
                        formData.append("ResonForLeving", values.ResonForLeving);
                        formData.append("Responsibilities", values.Responsibilities);
                        formData.append("EmployeeID", parseInt(param.id));

                        const axiosConfig = {
                            headers: {
                                "content-type": "multipart/form-data",
                                Authorization: "Bearer " + localStorage.getItem("token"),
                            },
                        };
                        const response = await axios
                            .post(
                                `${AppConfigData.APIBasePath}v1/empworkexperience/save`,
                                formData,
                                axiosConfig
                            )
                            .then((res) => {
                                setSubmitting(false);
                                if (res.data.status_code === 0) {
                                    toast.error(res.data.message);
                                } else if (res.data.status_code === 1) {
                                    toast.success(res.data.message);
                                    //setTimeout(() => {
                                    //  props.onHide()
                                    //}, 1000);
                                    props.bindList();
                                    props.onHide();
                                } else if (res.data.status_code === 2) {
                                    toast.error(res.data.message);
                                } else {
                                    toast.error(res.data.message);
                                }
                            })
                            .catch((error) => { });
                    }}
                >
                    <Form>
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                {commonservices.LabelDisplayText("AddExperienceDetail")}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Container>
                                <Row>
                                    <Field
                                        type="hidden"
                                        name="EmployeeID"
                                        className="form-control"
                                    />
                                    <Field
                                        type="hidden"
                                        name="EmpWorkExperienceID"
                                        className="form-control"
                                    />

                                    <Col xs={6} lg={6} md={12} className="mb-3">
                                        <div className="form-floating">
                                            <Field
                                                type="text"
                                                name="CompanyName"
                                                className="form-control"
                                                placeholder={commonservices.LabelDisplayText(
                                                    "CompanyName"
                                                )}
                                                maxLength={formFieldLength.CompanyName}
                                            />
                                            <label lbl-for="CompanyName">
                                                {commonservices.LabelDisplayText("CompanyName")}
                                            </label>
                                            <div className="text-danger-error">
                                                <ErrorMessage name="CompanyName" />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xs={6} lg={6} md={12} className="mb-3">
                                        <div className="form-floating">
                                            <Field
                                                type="text"
                                                name="Address"
                                                className="form-control"
                                                placeholder={commonservices.LabelDisplayText("Address")}
                                                maxLength={formFieldLength.Address}
                                            />
                                            <label lbl-for="Address">
                                                {commonservices.LabelDisplayText("Address")}
                                            </label>
                                            <div className="text-danger-error">
                                                <ErrorMessage name="Address" />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xs={6} lg={6} md={12} className="mb-3">
                                        <div className="form-floating">
                                            <Field
                                                type="text"
                                                className="form-control"
                                                name="Duration"
                                                placeholder={commonservices.LabelDisplayText(
                                                    "Duration"
                                                )}
                                                maxLength={formFieldLength.Duration}
                                            />
                                            <label lbl-for="Duration">
                                                {commonservices.LabelDisplayText("Duration")}
                                            </label>
                                            <div className="text-danger-error">
                                                <ErrorMessage name="Duration" />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xs={6} lg={6} md={12} className="mb-3">
                                        <div className="form-floating">
                                            <Field
                                                type="text"
                                                name="Designation"
                                                className="form-control"
                                                placeholder={commonservices.LabelDisplayText(
                                                    "Designation"
                                                )}
                                                maxLength={formFieldLength.Designation}

                                            />
                                            <label lbl-for="Designation">
                                                {commonservices.LabelDisplayText("Designation")}
                                            </label>
                                            <div className="text-danger-error">
                                                <ErrorMessage name="Designation" />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xs={6} lg={6} md={12} className="mb-3">
                                        <div className="form-floating">
                                            <Field
                                                type="number"
                                                name="GrossIncome"
                                                className="form-control"
                                                placeholder={commonservices.LabelDisplayText(
                                                    "GrossIncome"
                                                )}
                                                maxLength={formFieldLength.GrossIncome}

                                            />
                                            <label lbl-for="GrossIncome">
                                                {commonservices.LabelDisplayText("GrossIncome")}
                                            </label>
                                            <div className="text-danger-error">
                                                <ErrorMessage name="GrossIncome" />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xs={6} lg={6} md={12} className="mb-3">
                                        <div className="form-floating">
                                            <Field
                                                type="text"
                                                name="ResonForLeving"
                                                className="form-control"
                                                placeholder={commonservices.LabelDisplayText(
                                                    "ReasonForLeaving"
                                                )}
                                                maxLength={formFieldLength.ResonForLeving}

                                            />
                                            <label lbl-for="ResonForLeving">
                                                {commonservices.LabelDisplayText("ReasonForLeaving")}
                                            </label>
                                            <div className="text-danger-error">
                                                <ErrorMessage name="ResonForLeving" />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xs={12} className="mb-3">
                                        <div className="form-floating">
                                            <Field
                                                type="text"
                                                name="Responsibilities"
                                                className="form-control"
                                                placeholder={commonservices.LabelDisplayText(
                                                    "Responsibilities"
                                                )}
                                                maxLength={formFieldLength.Responsibilities}

                                            />
                                            <label lbl-for="Responsibilities">
                                                {commonservices.LabelDisplayText("Responsibilities")}
                                            </label>
                                            <div className="text-danger-error">
                                                <ErrorMessage name="Responsibilities" />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={props.onHide} title="close"
>
                                {commonservices.LabelDisplayText("Close")}
                            </Button>
                            <Button
                                className="btn btn-primary"
                                title="Submit"
                                type="submit"
                                disabled={Submitting}
                            >
                                {Submitting && (
                                    <>
                                        <Spinner animation="border" />
                                    </>
                                )}
                                {commonservices.LabelDisplayText("Save")}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Formik>
            </Modal>
        </>
    );
};

export default AddExperienceModal;
