import axios from "axios";
import { useState, useEffect } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { default as React } from "react";
import { toast } from "react-toastify";
import * as yup from "yup";
import { commonservices, AppConfigData } from "../Services/commonService";
import { confirmAlert } from "react-confirm-alert";
import { Col, Modal, Button, Container, Row, Table } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { viewFilePopupService } from "../Services/viewFilePopup.service";
import { ReactComponent as Trash } from "../assets/Icons/trash.svg";
import { ReactComponent as EyeshowPassword } from "../assets/Icons/eyeshow-password.svg";
const AddLeaveMasterModal = (props) => {
    const [Submitting, setSubmitting] = useState(false);
    const [IsGetDetailsDone, setIsGetDetailsDone] = useState(false);
    const [IsCreditRuleApply, setIsCreditRuleApply] = useState(false);
    const [CreditFrequency, setCreditFrequency] = useState("");
    const [IsCarryForward, setIsCarryForward] = useState(false);
    const [CarryForwordFrequency, setCarryForwordFrequency] = useState("");
    const [IsValidationMessageSet, setIsValidationMessageSet] = useState("");

    useEffect(() => {
        setIsValidationMessageSet(true);
    });

    const formFieldLength = {
        LeaveCode: 7,
        LeaveName: 67,
    };

    const formInitialSchema = {
        LeaveMasterID: 0,
        LeaveCode: "",
        LeaveName: "",
        IsDebitRuleApply: false,
        MaxDebitLimit: "",
        IsCreditRuleApply: false,
        CreditFrequency: "",
        CreditLimit: "",
        CreditDay: "",
        CreditMonth: "",
        IsCarryForward: false,
        CarryForwordFrequency: "",
        MaxCarryForwordLimit: "",
        CarryForwordDay: "",
        CarryForwordMonth: "",
        SalaryDeductedHeadID: 0,
        SalaryCreditHeadID: 0,
        IsSandwichApply: false,
        IsPaidLeave: false,
    };

    const formValidation = yup.object().shape({
        LeaveCode: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "LeaveCode"))
            .test(
                "len",
                commonservices.MakeValidationMessage("maxlength", ""),
                (value, obj) => {
                    return value ? value.length <= formFieldLength.LeaveCode : true;
                }
            ),
        LeaveName: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "LeaveName"))
            .test(
                "len",
                commonservices.MakeValidationMessage("maxlength", ""),
                (value, obj) => {
                    return value ? value.length <= formFieldLength.LeaveName : true;
                }
            ),

        CreditFrequency: yup
            .string()
            .test(
                "required",
                commonservices.MakeValidationMessage("required", "CreditFrequency"),
                (value, obj) => {
                    if (obj.from[0].value.IsCreditRuleApply) {
                        return value !== null && value !== undefined && value !== ""
                            ? true
                            : false;
                    } else {
                        return true;
                    }
                }
            ),
        // CreditLimit: yup
        //     .number()
        //     .test(
        //         "is-decimal",
        //         commonservices.LabelDisplayText("EnterDecimalValue"),
        //         (value) => (value + "").match(/^\d+(\.)?\d/)
        //     ),
        //CreditLimit: yup
        //    .string()
        //    .required(commonservices.MakeValidationMessage("required", "CreditLimit"))
        //    .test(
        //        "len",
        //        commonservices.MakeValidationMessage("maxlength", ""),
        //        (value, obj) => {
        //            return value ? value.length <= formFieldLength.LeaveName : true;
        //        }
        //    ),
        CreditLimit: yup
            .number()
            .max(999, "Too Long!")
            .test(
                "required",
                commonservices.MakeValidationMessage(
                    "required",
                    "CreditLimit"
                ),
                (value, obj) => {
                    if (obj.from[0].value.IsCreditRuleApply) {
                        return value !== null && value !== undefined && value !== ""
                            ? true
                            : false;
                    } else {
                        return true;
                    }
                }
            ),
        CreditMonth: yup
            .string()
            .test(
                "required",
                commonservices.MakeValidationMessage("required", "CreditMonth"),
                (value, obj) => {
                    if (
                        obj.from[0].value.IsCreditRuleApply &&
                        obj.from[0].value.CreditFrequency === "Yearly"
                    ) {
                        return value !== null && value !== undefined && value !== ""
                            ? true
                            : false;
                    } else {
                        return true;
                    }
                }
            ),
        CreditDay: yup
            .string()
            .test(
                "required",
                commonservices.MakeValidationMessage("required", "CreditDay"),
                (value, obj) => {
                    if (obj.from[0].value.IsCreditRuleApply) {
                        return value !== null && value !== undefined && value !== ""
                            ? true
                            : false;
                    } else {
                        return true;
                    }
                }
            ),

        CarryForwordFrequency: yup
            .string()
            .test(
                "required",
                commonservices.MakeValidationMessage(
                    "required",
                    "CarryForwordFrequency"
                ),
                (value, obj) => {
                    if (obj.from[0].value.IsCarryForward) {
                        return value !== null && value !== undefined && value !== ""
                            ? true
                            : false;
                    } else {
                        return true;
                    }
                }
            ),
        MaxCarryForwordLimit: yup
            .number()
            .max(999, "Too Long!")
            .test(
                "required",
                commonservices.MakeValidationMessage(
                    "required",
                    "MaxCarryForwordLimit"
                ),
                (value, obj) => {
                    if (obj.from[0].value.IsCarryForward) {
                        return value !== null && value !== undefined && value !== ""
                            ? true
                            : false;
                    } else {
                        return true;
                    }
                }
            ),
        CarryForwordMonth: yup
            .string()
            .test(
                "required",
                commonservices.MakeValidationMessage("required", "CarryForwordMonth"),
                (value, obj) => {
                    if (
                        obj.from[0].value.IsCarryForward &&
                        obj.from[0].value.CarryForwordFrequency === "Yearly"
                    ) {
                        return value !== null && value !== undefined && value !== ""
                            ? true
                            : false;
                    } else {
                        return true;
                    }
                }
            ),
        CarryForwordDay: yup
            .string()
            .test(
                "required",
                commonservices.MakeValidationMessage("required", "CarryForwordDay"),
                (value, obj) => {
                    if (obj.from[0].value.IsCarryForward) {
                        return value !== null && value !== undefined && value !== ""
                            ? true
                            : false;
                    } else {
                        return true;
                    }
                }
            ),
    });

    useEffect(() => {
        if (props.id > 0) {
            const axiosConfig = {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            };
            axios
                .post(
                    `${AppConfigData.APIBasePath}v1/leavemaster/getdetailsbyid`,
                    {
                        id: props.id,
                        requestFrom: "Web",
                    },
                    axiosConfig
                )
                .then((res) => {
                    formInitialSchema.LeaveMasterID =
                        res.data.data.leavemaster.leaveMasterID;
                    formInitialSchema.LeaveCode = res.data.data.leavemaster.leaveCode
                        ? res.data.data.leavemaster.leaveCode
                        : "";
                    formInitialSchema.LeaveName = res.data.data.leavemaster.leaveName
                        ? res.data.data.leavemaster.leaveName
                        : "";
                    formInitialSchema.IsDebitRuleApply = res.data.data.leavemaster
                        .isDebitRuleApply
                        ? res.data.data.leavemaster.isDebitRuleApply > 0
                            ? true
                            : false
                        : false;
                    formInitialSchema.MaxDebitLimit = res.data.data.leavemaster
                        .maxDebitLimit
                        ? res.data.data.leavemaster.maxDebitLimit
                        : 0;

                    formInitialSchema.IsCreditRuleApply = res.data.data.leavemaster
                        .isCreditRuleApply
                        ? res.data.data.leavemaster.isCreditRuleApply > 0
                            ? true
                            : false
                        : false;
                    formInitialSchema.CreditFrequency = res.data.data.leavemaster
                        .creditFrequency
                        ? res.data.data.leavemaster.creditFrequency
                        : "";
                    formInitialSchema.CreditLimit = res.data.data.leavemaster.creditLimit
                        ? res.data.data.leavemaster.creditLimit
                        : 0;
                    formInitialSchema.CreditDay = res.data.data.leavemaster.creditDay
                        ? res.data.data.leavemaster.creditDay
                        : 1;
                    formInitialSchema.CreditMonth = res.data.data.leavemaster.creditMonth
                        ? res.data.data.leavemaster.creditMonth
                        : "";
                    setIsCreditRuleApply(formInitialSchema.IsCreditRuleApply);
                    setCreditFrequency(formInitialSchema.CreditFrequency);

                    formInitialSchema.IsCarryForward = res.data.data.leavemaster
                        .isCarryForward
                        ? res.data.data.leavemaster.isCarryForward > 0
                            ? true
                            : false
                        : false;
                    formInitialSchema.CarryForwordFrequency = res.data.data.leavemaster
                        .carryForwordFrequency
                        ? res.data.data.leavemaster.carryForwordFrequency
                        : "";
                    formInitialSchema.MaxCarryForwordLimit = res.data.data.leavemaster
                        .maxCarryForwordLimit
                        ? res.data.data.leavemaster.maxCarryForwordLimit
                        : 0;
                    formInitialSchema.CarryForwordDay = res.data.data.leavemaster
                        .carryForwordDay
                        ? res.data.data.leavemaster.carryForwordDay
                        : 1;
                    formInitialSchema.CarryForwordMonth = res.data.data.leavemaster
                        .carryForwordMonth
                        ? res.data.data.leavemaster.carryForwordMonth
                        : "";
                    setIsCarryForward(formInitialSchema.IsCarryForward);
                    setCarryForwordFrequency(formInitialSchema.CarryForwordFrequency);

                    formInitialSchema.SalaryDeductedHeadID = res.data.data.leavemaster
                        .salaryDeductedHeadID
                        ? res.data.data.leavemaster.salaryDeductedHeadID
                        : 0;
                    formInitialSchema.SalaryCreditHeadID = res.data.data.leavemaster
                        .SalaryCreditHeadID
                        ? res.data.data.leavemaster.SalaryCreditHeadID
                        : 0;
                    formInitialSchema.IsSandwichApply = res.data.data.leavemaster
                        .isSandwichApply
                        ? res.data.data.leavemaster.isSandwichApply > 0
                            ? true
                            : false
                        : false;
                    formInitialSchema.IsPaidLeave = res.data.data.leavemaster.isPaidLeave
                        ? res.data.data.leavemaster.isPaidLeave > 0
                            ? true
                            : false
                        : false;
                    setIsGetDetailsDone(true);
                })
                .catch((err) => { });
        } else {
            setIsGetDetailsDone(true);
        }
    }, []);

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                // centered
                className="right-modal modal-new clint-modal"
                scrollable={true}
                backdrop="static"
                keyboard={false}
            >
                <Formik
                    initialValues={formInitialSchema}
                    validationSchema={formValidation}
                    onSubmit={async (values) => {
                        setSubmitting(true);
                        const FD = new FormData();
                        FD.append("LeaveMasterID", values.LeaveMasterID);
                        FD.append("LeaveCode", values.LeaveCode ? values.LeaveCode : "");
                        FD.append("LeaveName", values.LeaveName ? values.LeaveName : "");
                        FD.append(
                            "IsDebitRuleApply",
                            values.IsDebitRuleApply ? values.IsDebitRuleApply : false
                        );
                        FD.append(
                            "MaxDebitLimit",
                            values.MaxDebitLimit ? values.MaxDebitLimit : 0
                        );
                        FD.append(
                            "IsCreditRuleApply",
                            values.IsCreditRuleApply ? values.IsCreditRuleApply : false
                        );
                        FD.append(
                            "CreditFrequency",
                            values.CreditFrequency ? values.CreditFrequency : ""
                        );
                        FD.append(
                            "CreditLimit",
                            values.CreditLimit ? values.CreditLimit : 0
                        );
                        FD.append("CreditDay", values.CreditDay ? values.CreditDay : 1);
                        FD.append(
                            "CreditMonth",
                            values.CreditMonth ? values.CreditMonth : ""
                        );
                        FD.append(
                            "IsCarryForward",
                            values.IsCarryForward ? values.IsCarryForward : false
                        );
                        FD.append(
                            "CarryForwordFrequency",
                            values.CarryForwordFrequency ? values.CarryForwordFrequency : ""
                        );
                        FD.append(
                            "MaxCarryForwordLimit",
                            values.MaxCarryForwordLimit ? values.MaxCarryForwordLimit : 0
                        );
                        FD.append(
                            "CarryForwordDay",
                            values.CarryForwordDay ? values.CarryForwordDay : 1
                        );
                        FD.append(
                            "CarryForwordMonth",
                            values.CarryForwordMonth ? values.CarryForwordMonth : ""
                        );
                        FD.append("SalaryDeductedHeadID", 0);
                        FD.append("SalaryCreditHeadID", 0);
                        FD.append(
                            "IsSandwichApply",
                            values.IsSandwichApply ? values.IsSandwichApply : false
                        );
                        FD.append(
                            "IsPaidLeave",
                            values.IsPaidLeave ? values.IsPaidLeave : false
                        );

                        const axiosConfig = {
                            headers: {
                                "Content-Type": "multipart/form-data",
                                Authorization: "Bearer " + localStorage.getItem("token"),
                            },
                        };
                        const response = await axios
                            .post(
                                `${AppConfigData.APIBasePath}v1/leavemaster/save`,
                                FD,
                                axiosConfig
                            )
                            .then((response) => {
                                setSubmitting(false);
                                if (response.data.status_code === 1) {
                                    toast.success(response.data.message);
                                    props.bindList();
                                    props.onHide();
                                } else if (response.data.status_code === 2) {
                                    toast.error(response.data.message);
                                } else if (response.data.status_code === 0) {
                                    toast.error(response.data.message);
                                } else {
                                    toast.error(response.data.message);
                                }
                            })
                            .catch((error) => { });
                    }}
                >
                    {({
                        handleChange,
                        setFieldValue,
                        setFieldTouched,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        values,
                    }) => {
                        const handleCreditFrequency = (e) => {

                            setCreditFrequency(e.target.value);
                            setFieldValue("CreditFrequency", e.target.value);
                            setFieldTouched("CreditFrequency", true, false);
                            //   fnGetProjectStory(ProjectID);
                        };
                        const handleCarryForwordFrequency = (e) => {

                            setCarryForwordFrequency(e.target.value);
                            setFieldValue("CarryForwordFrequency", e.target.value);
                            setFieldTouched("CarryForwordFrequency", true, false);
                            //   fnGetProjectStory(ProjectID);
                        };
                        return (
                            <Form>
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">
                                        Leave Master
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Container>
                                        <Row>
                                            <Col xs={6} className="mb-3">
                                                <Field
                                                    type="hidden"
                                                    name="LeaveMasterID"
                                                    className="form-control"
                                                    placeholder="LeaveMasterID"
                                                />
                                                <div className="form-floating ms-3">
                                                    <Field
                                                        type="text"
                                                        name="LeaveCode"
                                                        className="form-control"
                                                        placeholder={"Code"}
                                                        maxLength={formFieldLength.LeaveCode}
                                                    />
                                                    <label lbl-for="LeaveCode">Code</label>
                                                    <div className="text-danger-error">
                                                        <ErrorMessage name="LeaveCode" />
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xs={6} className="mb-3">
                                                <div className="form-floating ">
                                                    <Field
                                                        type="text"
                                                        name="LeaveName"
                                                        className="form-control"
                                                        placeholder={"Name"}
                                                        maxLength={formFieldLength.LeaveName}
                                                    />
                                                    <label lbl-for="LeaveName">Name</label>
                                                    <div className="text-danger-error">
                                                        <ErrorMessage name="LeaveName" />
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col xs={6} className="mb-3">
                                                <div className="form-group checkbox-class mb-0 ps-3">
                                                    <Field
                                                        type="checkbox"
                                                        name="IsSandwichApply"
                                                        id="IsSandwichApply"
                                                        className="me-2"
                                                    />
                                                    <label
                                                        htmlFor="IsSandwichApply"
                                                        lbl-for="IsSandwichApply"
                                                    >
                                                        Is Sandwich Apply ?
                                                    </label>
                                                    <div className="text-danger-error">
                                                        <ErrorMessage name="IsSandwichApply" />
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xs={6} className="mb-3">
                                                <div className="form-group checkbox-class mb-0">
                                                    <Field
                                                        type="checkbox"
                                                        name="IsPaidLeave"
                                                        id="IsPaidLeave"
                                                        className="me-2"
                                                    />
                                                    <label htmlFor="IsPaidLeave" lbl-for="IsPaidLeave">
                                                        Is Paid Leave ?
                                                    </label>
                                                    <div className="text-danger-error">
                                                        <ErrorMessage name="IsPaidLeave" />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="">
                                                <div className="inner-header">
                                                    <div className="form-group checkbox-class pt-2 mb-0">
                                                        <Field
                                                            type="checkbox"
                                                            name="IsCreditRuleApply"
                                                            id="IsCreditRuleApply"
                                                            className="me-2"
                                                            onClick={(e) =>
                                                                setIsCreditRuleApply(e.target.checked)
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="IsCreditRuleApply"
                                                            lbl-for="IsCreditRuleApply"
                                                        >
                                                            Is Credit Rule Apply ?
                                                        </label>
                                                        <div className="text-danger-error">
                                                            <ErrorMessage name="IsCreditRuleApply" />
                                                        </div>
                                                    </div>
                                                </div>
                                                {IsCreditRuleApply && (
                                                    <div className="tab-inner-box pt-2">
                                                        <Row>
                                                            <Col xs={6} className="mb-3">
                                                                <div className="form-floating">
                                                                    <Field
                                                                        as="select"
                                                                        name="CreditFrequency"
                                                                        className="form-control down-arrow-icon"
                                                                        onChange={handleCreditFrequency}
                                                                    >
                                                                        <option
                                                                            value=""
                                                                        // onClick={(e) => setCreditFrequency("")}
                                                                        >
                                                                            {commonservices.LabelDisplayText(
                                                                                "Select"
                                                                            )}
                                                                        </option>
                                                                        <option
                                                                            value={"Monthly"}
                                                                        // onClick={(e) => setCreditFrequency("Monthly")}
                                                                        >
                                                                            Monthly
                                                                        </option>
                                                                        <option
                                                                            value={"Yearly"}
                                                                        // onClick={(e) => setCreditFrequency("Yearly")}
                                                                        >
                                                                            Yearly
                                                                        </option>
                                                                    </Field>
                                                                    <label lbl-for="CreditFrequency">
                                                                        Credit Frequency
                                                                    </label>
                                                                    <div className="text-danger-error">
                                                                        <ErrorMessage name="CreditFrequency" />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col xs={6} className="mb-3">
                                                                <div className="form-floating">
                                                                    <Field
                                                                        type="number"
                                                                        name="CreditLimit"
                                                                        className="form-control"
                                                                        placeholder={"Credit Limit"}
                                                                    // min="0"
                                                                    />
                                                                    <label lbl-for="CreditLimit">
                                                                        Credit Limit
                                                                    </label>
                                                                    <div className="text-danger-error">
                                                                        <ErrorMessage name="CreditLimit" />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            {CreditFrequency === "Yearly" && (
                                                                <Col xs={6} className="mb-3">
                                                                    <div className="form-floating">
                                                                        <Field
                                                                            as="select"
                                                                            name="CreditMonth"
                                                                            className="form-control down-arrow-icon"
                                                                        >
                                                                            <option value="">
                                                                                {commonservices.LabelDisplayText(
                                                                                    "Select"
                                                                                )}
                                                                            </option>
                                                                            {commonservices
                                                                                .GetMonthNameList()
                                                                                .map((item, index) => (
                                                                                    <option key={index} value={item}>
                                                                                        {item}
                                                                                    </option>
                                                                                ))}
                                                                        </Field>
                                                                        <label lbl-for="CreditMonth">
                                                                            Credit Month
                                                                        </label>
                                                                        <div className="text-danger-error">
                                                                            <ErrorMessage name="CreditMonth" />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            )}

                                                            <Col xs={6} className="mb-3">
                                                                <div className="form-floating">
                                                                    <Field
                                                                        as="select"
                                                                        name="CreditDay"
                                                                        className="form-control down-arrow-icon"
                                                                    >
                                                                        <option value="">
                                                                            {commonservices.LabelDisplayText(
                                                                                "Select"
                                                                            )}
                                                                        </option>
                                                                        {commonservices
                                                                            .GetMonthNoOfDayList("")
                                                                            .map((item, index) => (
                                                                                <option key={index} value={item}>
                                                                                    {item}
                                                                                </option>
                                                                            ))}
                                                                    </Field>
                                                                    <label lbl-for="CreditDay">Credit Day</label>
                                                                    <div className="text-danger-error">
                                                                        <ErrorMessage name="CreditDay" />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col className="">
                                                <div className="inner-header">
                                                    <div className="form-group checkbox-class pt-2 mb-0">
                                                        <Field
                                                            type="checkbox"
                                                            name="IsCarryForward"
                                                            id="IsCarryForward"
                                                            className="me-2"
                                                            onClick={(e) =>
                                                                setIsCarryForward(e.target.checked)
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="IsCarryForward"
                                                            lbl-for="IsCarryForward"
                                                        >
                                                            Is Carry Forward ?
                                                        </label>
                                                        <div className="text-danger-error">
                                                            <ErrorMessage name="IsCarryForward" />
                                                        </div>
                                                    </div>
                                                </div>
                                                {IsCarryForward && (
                                                    <div className="tab-inner-box pt-2">
                                                        <Row>
                                                            <Col xs={6} className="mb-3">
                                                                <div className="form-floating">
                                                                    <Field
                                                                        as="select"
                                                                        name="CarryForwordFrequency"
                                                                        className="form-control down-arrow-icon"
                                                                        onChange={handleCarryForwordFrequency}
                                                                    >
                                                                        <option
                                                                            value=""
                                                                        // onClick={(e) => setCarryForwordFrequency("")}
                                                                        >
                                                                            {commonservices.LabelDisplayText(
                                                                                "Select"
                                                                            )}
                                                                        </option>
                                                                        <option
                                                                            value={"Monthly"}
                                                                        //  onClick={(e) => setCarryForwordFrequency("Monthly")}
                                                                        >
                                                                            Monthly
                                                                        </option>
                                                                        <option
                                                                            value={"Yearly"}
                                                                        // onClick={(e) => setCarryForwordFrequency("Yearly")}
                                                                        >
                                                                            Yearly
                                                                        </option>
                                                                    </Field>
                                                                    <label lbl-for="CarryForwordFrequency">
                                                                        Carry Forword Frequency
                                                                    </label>
                                                                    <div className="text-danger-error">
                                                                        <ErrorMessage name="CarryForwordFrequency" />
                                                                    </div>
                                                                </div>
                                                            </Col>

                                                            <Col xs={6} className="mb-3">
                                                                <div className="form-floating">
                                                                    <Field
                                                                        type="number"
                                                                        name="MaxCarryForwordLimit"
                                                                        className="form-control"
                                                                        placeholder={"Credit Limit"}
                                                                        min="0"
                                                                    />
                                                                    <label lbl-for="MaxCarryForwordLimit">
                                                                        Max Carry Forword Limit
                                                                    </label>
                                                                    <div className="text-danger-error">
                                                                        <ErrorMessage name="MaxCarryForwordLimit" />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            {CarryForwordFrequency === "Yearly" && (
                                                                <Col xs={6} className="mb-3">
                                                                    <div className="form-floating">
                                                                        <Field
                                                                            as="select"
                                                                            name="CarryForwordMonth"
                                                                            className="form-control down-arrow-icon"
                                                                        >
                                                                            <option value="">
                                                                                {commonservices.LabelDisplayText(
                                                                                    "Select"
                                                                                )}
                                                                            </option>
                                                                            {commonservices
                                                                                .GetMonthNameList()
                                                                                .map((item, index) => (
                                                                                    <option key={index} value={item}>
                                                                                        {item}
                                                                                    </option>
                                                                                ))}
                                                                        </Field>
                                                                        <label lbl-for="CarryForwordMonth">
                                                                            Carry Forword Month
                                                                        </label>
                                                                        <div className="text-danger-error">
                                                                            <ErrorMessage name="CarryForwordMonth" />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            )}

                                                            <Col xs={6} className="mb-3">
                                                                <div className="form-floating">
                                                                    <Field
                                                                        as="select"
                                                                        name="CarryForwordDay"
                                                                        className="form-control down-arrow-icon"
                                                                    >
                                                                        <option value="">
                                                                            {commonservices.LabelDisplayText(
                                                                                "Select"
                                                                            )}
                                                                        </option>
                                                                        {commonservices
                                                                            .GetMonthNoOfDayList("")
                                                                            .map((item, index) => (
                                                                                <option key={index} value={item}>
                                                                                    {item}
                                                                                </option>
                                                                            ))}
                                                                    </Field>
                                                                    <label lbl-for="CarryForwordDay">
                                                                        Carry Forword Day
                                                                    </label>
                                                                    <div className="text-danger-error">
                                                                        <ErrorMessage name="CarryForwordDay" />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>
                                    </Container>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button
                                        onClick={props.onHide}
                                        className="Cancel-btn"
                                        title="Cancel"
                                    >
                                        {commonservices.LabelDisplayText("Cancel")}
                                    </Button>
                                    <Button
                                        className="btn btn-primary save-btn"
                                        type="submit"
                                        title="Submit"
                                        disabled={Submitting}
                                    >
                                        {Submitting && (
                                            <>
                                                <Spinner animation="border" />
                                            </>
                                        )}
                                        {commonservices.LabelDisplayText("Save")}
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        );
                    }}
                </Formik>
            </Modal>
        </>
    );
};

export default AddLeaveMasterModal;
