import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { default as React, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Modal, Button, Container, Row, Table } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";
import { AppConfigData, commonservices } from "../Services/commonService";
import * as yup from "yup";

const AddLeaveTransactionModal = (props) => {
    let data = commonservices.getLoginUserData();
    const [optionLeave, setOptionLeave] = useState([]);
    const [Submitting, setSubmitting] = useState(false);
    const [optionEmployee, setOptionEmployee] = useState([]);
    const [IsValidationMessageSet, setIsValidationMessageSet] = useState("");

    useEffect(() => {
        setIsValidationMessageSet(true);
    });

    const formInitialSchema = {
        EmployeeID: "",
        LeaveID: "",
        NoOfDays: "1",
        TransactionDate: commonservices.getDateInDBFormat(new Date()),
        TransactionNote: "",
        TransactionType: "",
    };

    let TodayDate = new Date();
    TodayDate = TodayDate.setDate(TodayDate.getDate() - 1);
    const formValidation = yup.object().shape({
        LeaveID: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "LeaveID")),
        EmployeeID: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "EmployeeID")),
        TransactionDate: yup
            .date()
            .required(commonservices.MakeValidationMessage("required", "TransactionDate")),
        NoOfDays: yup
            .number()
            .required(commonservices.MakeValidationMessage("required", "NoOfDays"))
            .min(0.5, commonservices.MakeValidationMessage("custom", "NoOfDays", commonservices.CustomValidationMessage("ValidationCanNotBeZero"))),
        TransactionNote: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "TransactionNote")),
        TransactionType: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "TransactionType")),
    });

    useEffect(() => {
        const axiosConfig = {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        };
        axios
            .post(
                `${AppConfigData.APIBasePath}v1/employees/getlist`,
                {
                    "firstName": "",
                    "lastName": "",
                    "middleName": "",
                    "mobileNo": "",
                    "emplyeesNo": "",
                    "emailID": "",
                    "adharNo": "",
                    "pancardNo": "",
                    "searchText": ""
                },
                axiosConfig
            )
            .then((res) => {
                if (res.data.status_code === 0) {
                } else if (res.data.status_code === 1) {
                    setOptionEmployee(res.data.data);
                } else if (res.data.status_code === 2) {
                } else {
                }
            })
            .catch((err) => { });
    }, []);


    function GetEmployeesLeaveData(EmployeeID) {
        setOptionLeave([]);
        if (EmployeeID !== null && EmployeeID !== undefined && EmployeeID !== "") {
            const axiosConfig = {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            };
            axios
                .post(
                    `${AppConfigData.APIBasePath}v1/leavemaster/getemployeeleavelist`,
                    {
                        "employeeID": parseInt(EmployeeID)
                    },
                    axiosConfig
                )
                .then((res) => {
                    if (res.data.status_code === 0) {
                    } else if (res.data.status_code === 1) {
                        setOptionLeave(res.data.data);
                    } else if (res.data.status_code === 2) {
                    } else {
                    }
                })
                .catch((err) => { });
        }
    }
    function fnGetEmployeesLeaveData(e) {
        
        setOptionLeave([]);
        if (e.target.value !== null && e.target.value !== undefined && e.target.value !== "") {
            const axiosConfig = {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            };
            axios
                .post(
                    `${AppConfigData.APIBasePath}v1/leavemaster/getemployeeleavelist`,
                    {
                        "employeeID": parseInt(e.target.value)
                    },
                    axiosConfig
                )
                .then((res) => {
                    if (res.data.status_code === 0) {
                    } else if (res.data.status_code === 1) {
                        setOptionLeave(res.data.data);
                    } else if (res.data.status_code === 2) {
                    } else {
                    }
                })
                .catch((err) => { });
        }
    }

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                className="right-modal modal-new modal-add-project-user"
                scrollable={true}
                backdrop="static"
                keyboard={false}
            >
                <Formik
                    initialValues={formInitialSchema}
                    validationSchema={formValidation}
                    validateOnBlur={false}
                    onSubmit={async (values) => {
                        setSubmitting(true);
                        const FD = new FormData();
                        FD.append("EmployeeID", values.EmployeeID);
                        FD.append("LeaveID", values.LeaveID);
                        FD.append("NoOfDays", values.NoOfDays);
                        FD.append("TransactionDate", values.TransactionDate);
                        FD.append("TransactionNote", values.TransactionNote);
                        FD.append("TransactionType", values.TransactionType);

                        const axiosConfig = {
                            headers: {
                                "Content-Type": "multipart/form-data",
                                Authorization: "Bearer " + localStorage.getItem("token"),
                            },
                        };
                        const response = await axios
                            .post(
                                `${AppConfigData.APIBasePath}v1/leavetransaction/save`,
                                FD,
                                axiosConfig
                            )
                            .then((response) => {
                                setSubmitting(false);
                                if (response.data.status_code === 1) {
                                    toast.success(response.data.message);
                                    props.bindList();
                                    props.onHide();
                                } else {
                                    toast.error(response.data.message);
                                }
                            })
                            .catch((error) => { setSubmitting(false); });
                    }}
                    enableReinitialize={true}
                >
                    {({
            handleChange,
            setFieldValue,
            setFieldTouched,
            handleBlur,
            handleSubmit,
            isSubmitting,
            values,
          }) => {
            const setProductValue = (e) => {
              
              setFieldValue("EmployeeID", e.target.value);
              setFieldTouched("EmployeeID", true, false);
              fnGetEmployeesLeaveData(e)
            //   fnGetProjectStory(ProjectID);
            };
            return (
                    <Form>
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                Leave Transaction
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Container>
                                <Row>
                                    <Col xs={12} className="mb-3">
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Field
                                                    as="select"
                                                    name="EmployeeID"
                                                    className="form-control down-arrow-icon ddd"
                                                    // onClick={(e) => GetEmployeesLeaveData(item.EmployeeID)}
                                                    // onChange={(e) => fnGetEmployeesLeaveData(e)}
                                                    onChange={setProductValue}
                                                >
                                                    <option value="" 
                                                    // onClick={(e) => GetEmployeesLeaveData("")}
                                                    >
                                                        {commonservices.LabelDisplayText("Select")}
                                                    </option>
                                                    {optionEmployee.length > 0 &&
                                                        optionEmployee.map((item, index) => (
                                                            <option key={index} value={item.EmployeeID}
                                                            //  onClick={(e) => GetEmployeesLeaveData(item.EmployeeID)}
                                                             >
                                                                {item.FirstName + "  " + item.MiddleName + "  " + item.LastName}
                                                            </option>
                                                        ))}
                                                </Field>
                                                <label lbl-for="EmployeeID">Employee</label>
                                                <div className="text-danger-error">
                                                    <ErrorMessage name="EmployeeID" />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={12} className="mb-3">
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Field
                                                    as="select"
                                                    name="LeaveID"
                                                    className="form-control down-arrow-icon"
                                                >
                                                    <option value="">
                                                        {commonservices.LabelDisplayText("Select")}
                                                    </option>
                                                    {optionLeave.length > 0 &&
                                                        optionLeave.map((item, index) => (
                                                            <option key={index} value={item.LeaveID}>
                                                                {item.LeaveCode +
                                                                    " - " +
                                                                    item.LeaveName
                                                                }
                                                            </option>
                                                        ))}
                                                </Field>
                                                <label lbl-for="LeaveID">Leave</label>
                                                <div className="text-danger-error">
                                                    <ErrorMessage name="LeaveID" />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={12} className="mb-3">
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Field
                                                    as="select"
                                                    name="TransactionType"
                                                    className="form-control down-arrow-icon"
                                                >
                                                    <option value="">
                                                        {commonservices.LabelDisplayText("Select")}
                                                    </option>
                                                    <option value={"Credit"}>Credit</option>
                                                    <option value={"Debit"}>Debit</option>
                                                </Field>
                                                <label lbl-for="TransactionType">Transaction Type</label>
                                                <div className="text-danger-error">
                                                    <ErrorMessage name="TransactionType" />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={12} className="mb-3">
                                        <div className="form-group">
                                            <div className="form-floating ">
                                                <Field
                                                    type="number"
                                                    name="NoOfDays"
                                                    className="form-control"
                                                    placeholder={"No Of Days"}

                                                />
                                                <label lbl-for="NoOfDays">No Of Days</label>
                                                <div className="text-danger-error">
                                                    <ErrorMessage name="NoOfDays" />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={12} className="mb-3">
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Field
                                                    type="date"
                                                    name="TransactionDate"
                                                    className="form-control calendar-icon"
                                                />
                                                <label lbl-for="TransactionDate">Transaction Date</label>
                                                <div className="text-danger-error">
                                                    <ErrorMessage name="TransactionDate" />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="form-group">
                                            <div className="form-floating ">
                                                <Field
                                                    as="textarea"
                                                    name="TransactionNote"
                                                    rows="5"
                                                    className="form-control"
                                                    placeholder={commonservices.LabelDisplayText(
                                                        "Details"
                                                    )}
                                                />
                                                <label lbl-for="TransactionNote">
                                                    {commonservices.LabelDisplayText("Details")}
                                                </label>
                                                <div className="text-danger-error">
                                                    <ErrorMessage name="TransactionNote" />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="Cancel-btn" onClick={props.onHide} title="Cancel">
                                {commonservices.LabelDisplayText("Cancel")}
                            </Button>
                            <Button
                                className="btn btn-primary save-btn"
                                type="submit"
                                title="Submit"
                                disabled={Submitting}
                            >
                                {Submitting && (
                                    <>
                                        <Spinner animation="border" />
                                    </>
                                )}
                                {commonservices.LabelDisplayText("Save")}
                            </Button>
                        </Modal.Footer>
                    </Form>
                     );
                    }}
                </Formik>
            </Modal>
        </>
    );
};

export default AddLeaveTransactionModal;
