import axios from "axios";
import React, { useState, useEffect } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import * as yup from "yup";
import { commonservices, AppConfigData } from "../Services/commonService";
import { Col, Modal, Button, Container, Row, Table } from "react-bootstrap";
import { useSearchParams, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import Spinner from "react-bootstrap/Spinner";

const AddProjectUser = (props) => {
  let param = useParams();

  const [projectRoleIds, setProjectRoleIds] = useState([]);
  const [selectedUser, setselectedUser] = useState([]);
  const [selectedOption, setSelectedOption] = useState("0");
  const [isValid, setIsValid] = useState(false);
  const [isValidUserrole, setIsValidUserrole] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [Submitting, setSubmitting] = useState(false);
  const [radioFalse, setRadioFalse] = useState(false);
  const [RadioMember, setRadioMember] = useState(false);
  const [radio, setRadio] = useState(true);
  const [radioGroup, setRadioGroup] = useState(false);

  const [isDisabledValue, setIsDisabledValue] = useState("");
  const [options, setOptions] = useState([]);
  const [IsValidationMessageSet, setIsValidationMessageSet] = useState("");

  useEffect(() => {
    setIsValidationMessageSet(true);
  });
  const formInitialSchema = {
    projectUserID: 0,
    userID: "",
    projectID: 0,
    isManager: false,
    isTeamLead: false,
    isMember: true,
    isActive: true,
    isOwner: false,
    projectRoleID: "",
  };

  const formValidation = yup.object().shape({
    // userID: yup.string().required("select the user"),
    projectRoleID: yup
      .string()
      .required("select the User Role"),
    // userID:
    // yup
    // .object()
    // .shape({
    //   label: yup.string().required("status is required (from label)"),
    //   value: yup.string().required("status is required")
    // })
    // .nullable("Please select User")
    // .required("Please select User")
  });

  // function deleteItem(value)
  // {
  //   setItems(items.filter((single) => single.id !== getID));
  //   setOptions(options.filter((single) => single.id !== getID));
  // };
  function getData(UserList, UserID) {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(
        `${AppConfigData.APIBasePath}v1/user/searchforaddmember`,
        {
          id: parseInt(param.id),
          requestFrom: "string",
        },
        axiosConfig
      )
      .then((res) => {
        if (res.data.status_code === 0) {
        } else if (res.data.status_code === 1) {
          let selectedUser = [];
          res.data.data.forEach((element) => {
            let IsInList = UserList.filter((U) => U.UserID === element.UserID);
            if (IsInList !== null && IsInList !== undefined) {
              if (IsInList.length === 0) {
                selectedUser.push({
                  label: element.DisplayName,
                  value: element.UserID,
                });
              } else {
                if (IsInList[0].UserID === UserID) {
                  selectedUser.push({
                    label: element.DisplayName,
                    value: element.UserID,
                  });
                }
              }
            }
          });
          setselectedUser(selectedUser);
        } else if (res.data.status_code === 2) {
        } else {
        }
      })
      .catch((err) => {});
  }
  const handleTypeSelect = (e) => {
    setSelectedOption(e.value);
    // if (e.value !== null && e.value !== undefined && e.value !== "") {
    //   setIsValid(false);
    // } else {
    //   setIsValid(true);
    // }
  };

  function getRoledata() {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(
        `${AppConfigData.APIBasePath}v1/role/getlist`,
        {
          field: "",
          requestFrom: "",
        },
        axiosConfig
      )
      .then((res) => {
        setProjectRoleIds(res.data.data);
        if (
          formInitialSchema.projectRoleID !== null &&
          formInitialSchema.projectRoleID !== undefined &&
          formInitialSchema.projectRoleID !== ""
        ) {
          isValidUserrole(false);
        }

        let abc = [];
        res.data.data
          .filter((t) => t.IsProjectRole === 1)
          .forEach((element) => {
            abc.push({
              a: element.RoleID,
            });
          });
      })
      .catch((err) => {});
  }

  function fnChangeRole(e) {
    // const signInForm = document.querySelector("form.login");
  }

  function handleChange(event) {
    // setRadio(event.target.value)
    if (event.target.value === "isManager") {
      setRadio(false);
    } else if (event.target.value === "isTeamLead") {
      setRadio(false);
    } else if (event.target.value === "isMember") {
      setRadioGroup(false);
    } else {
      setRadio(false);
    }
  }

  useEffect(() => {
    getRoledata();

    if (props) {
      formInitialSchema.projectUserID = props.id.ProjectUserID;
      formInitialSchema.projectID = props.id.ProjectID;
      formInitialSchema.isOwner = props.id.IsOwner === 1 ? true : false;
      formInitialSchema.projectRoleID = props.id.ProjectRoleID;

      formInitialSchema.radioRole = "";
      if (props.id.IsManager === 1) {
        formInitialSchema.radioRole = "isManager";
      } else if (props.id.IsTeamLead === 1) {
        formInitialSchema.radioRole = "isTeamLead";
      } else {
        formInitialSchema.radioRole = "isMember";
      }

      if (props.id.UserID > 0) {
        setIsDisabled(true);
      }
      setSelectedOption(props.id.UserID);

      getData(props.UserList.Data, props.id.UserID);
    } else {
      getData(props.UserList.Data, 0);
    }

    // if (formInitialSchema.isMember === true) {
    //   setRadioFalse(false);
    // }else{
    //   setRadioFalse(true);
    // }

    // if (formInitialSchema.radioRole === true) {
    //   setRadioMember(false);
    // }else{
    //   setRadioMember(true);
    // }
  }, []);

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="right-modal modal-new modal-add-project-user"
        scrollable={true}
        backdrop="static"
        keyboard={false}
      >
        <Formik
          initialValues={formInitialSchema}
          validationSchema={formValidation}
          onSubmit={async (values) => {
            if (
              selectedOption !== null &&
              selectedOption !== undefined &&
              selectedOption !== ""
            ) {
              setIsValid(false);
            } else {
              setIsValid(true);
            }

            if (values.projectUserID > 0) {
              setSubmitting(true);

              const formData = new FormData();
              formData.append("ProjectUserID", values.projectUserID);
              formData.append("DisplayName", values.DisplayName);
              formData.append("UserID", selectedOption);
              formData.append("ProjectID", values.projectID);
              formData.append("IsManager", values.radioRole === "isManager");
              formData.append("IsTeamLead", values.radioRole === "isTeamLead");
              formData.append("IsOwner", values.isOwner);
              formData.append("ProjectRoleID", values.projectRoleID);

              const axiosConfig = {
                headers: {
                  "content-type": "multipart/form-data",
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              };
              const response = await axios
                .post(
                  `${AppConfigData.APIBasePath}v1/projectuser/update`,
                  formData,
                  axiosConfig
                )
                .then((res) => {
                  setSubmitting(false);
                  if (res.data.status_code === 0) {
                    toast.error(res.data.message);
                  } else if (res.data.status_code === 1) {
                    toast.success(res.data.message);
                    //setTimeout(() => {
                    //  props.onHide();
                    //}, 1000);
                    props.bindList();
                    props.onHide();
                  } else if (res.data.status_code === 2) {
                  } else {
                  }
                })
                .catch((error) => {});
            } else {
              if (
                selectedOption !== null &&
                selectedOption !== "" &&
                selectedOption !== undefined &&
                values.projectRoleID !== null &&
                values.projectRoleID !== "" &&
                values.projectRoleID !== undefined
              ) {
                setIsValidUserrole(false);
                setSubmitting(true);

                let input = [
                  {
                    projectUserID: 0,
                    userID: selectedOption,
                    projectID: parseInt(param.id),
                    isManager: values.radioRole === "isManager",
                    isTeamLead: values.radioRole === "isTeamLead",
                    isActive: true,
                    isOwner: false,
                    projectRoleID: parseInt(values.projectRoleID),
                  },
                ];

                const axiosConfig = {
                  headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                  },
                };
                const response = await axios
                  .post(
                    `${AppConfigData.APIBasePath}v1/projectuser/save`,
                    input,
                    axiosConfig
                  )
                  .then((res) => {
                    if (res.data.status_code === 0) {
                    } else if (res.data.status_code === 1) {
                      toast.success(res.data.message);
                      setTimeout(() => {
                        props.onHide();
                      }, 1000);
                    } else if (res.data.status_code === 2) {
                    } else {
                    }
                    props.bindList();
                  })
                  .catch((error) => {});
              } else {
                setIsValidUserrole(true);
              }
            }
          }}
        >
          <Form>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {commonservices.LabelDisplayText("AddProjectUser")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container>
                <Row>
                  <Col xs={6}>
                    <Field
                      type="hidden"
                      name="projectUserID"
                      className="form-control"
                    />
                  </Col>

                  <Col xs={12} className="mb-3">
                    <div className="form-group">
                      <div className="form-floating Select-form-floating">
                        <label>{commonservices.LabelDisplayText("User")}</label>
                        <Select
                          placeholder={commonservices.LabelDisplayText(
                            "Select"
                          )}
                          isSearchable={true}
                          name="userID"
                          isDisabled={isDisabled}
                          options={selectedUser}
                          onChange={handleTypeSelect}
                          value={selectedUser.find(function (option) {
                            return option.value === selectedOption;
                          })}
                        />
                        <label lbl-for="userID">
                          {commonservices.LabelDisplayText("userID")}
                        </label>
                        {isValid ? (
                          <div className="text-danger-error">
                            {commonservices.LabelDisplayText(
                              "SelectTheUserName"
                            )}
                          </div>
                        ) : (
                          <div className="text-danger-error"></div>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="form-group pt-3">
                      <div className="form-floating ">
                        <Field
                          as="select"
                          name="projectRoleID"
                          className="form-control"
                        >
                          <option value="">
                            {commonservices.LabelDisplayText("Select")}
                          </option>
                          {projectRoleIds.length > 0 &&
                            projectRoleIds.map(
                              (item, index) =>
                                item.IsProjectRole === 1 && (
                                  <option key={index} value={item.RoleID}>
                                    {item.RoleName}
                                  </option>
                                )
                            )}
                        </Field>
                        <label lbl-for="projectRoleID">
                          {commonservices.LabelDisplayText("UserRole")}
                        </label>
                        {/* {isValidUserrole ? (
                                                    <div className="text-danger-error">
                                                        {commonservices.LabelDisplayText(
                                                            "SelectTheUserRole"
                                                        )}
                                                    </div>
                                                ) : (
                                                    <div className="text-danger-error"></div>
                                                )} */}
                        <div className="text-danger-error">
                          <ErrorMessage name="projectRoleID" />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <div
                    role="group"
                    className="radio-btn-wrap"
                    aria-labelledby="my-radio-group"
                  >
                    <label className="mt-3">
                      {commonservices.LabelDisplayText("IsMember")}
                      <Field
                        className="ms-2"
                        type="radio"
                        name="radioRole"
                        value="isMember"
                      />
                      <span class="checkmark-radio"></span>
                    </label>

                    <label className="mt-3 me-3 ms-3">
                      {commonservices.LabelDisplayText("IsManager")}
                      <Field
                        className="ms-2"
                        type="radio"
                        name="radioRole"
                        value="isManager"
                      />
                      <span class="checkmark-radio"></span>
                    </label>
                    <label className="mt-2">
                      {commonservices.LabelDisplayText("IsTeamLead")}
                      <Field
                        className="ms-2"
                        type="radio"
                        name="radioRole"
                        value="isTeamLead"
                      />
                      <span class="checkmark-radio"></span>
                    </label>
                  </div>
                </Row>
              </Container>
            </Modal.Body>
            <Modal.Footer>
              <Button className="Cancel-btn" onClick={props.onHide} title="Cancel">
                {commonservices.LabelDisplayText("Cancel")}
              </Button>
              <Button
                className="btn btn-primary save-btn"
                type="submit"
                title="Submit"
                disabled={Submitting}
              >
                {Submitting && (
                  <>
                    <Spinner animation="border" />
                  </>
                )}
                {commonservices.LabelDisplayText("Save")}
              </Button>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </>
  );
};

export default AddProjectUser;
