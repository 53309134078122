import axios from "axios";
import Moment from "moment";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import $ from "jquery";
// import { ReactComponent as Highest } from "../assets/Icons/highest.svg";
import Highest from "../assets/Icons/highest.svg";
import Low from "../assets/Icons/low.svg";
import High from "../assets/Icons/high.svg";
import Normal from "../assets/Icons/normal.svg";
import { ReactComponent as InProcess } from "../assets/Icons/in-process.svg";
import { ReactComponent as OnHold } from "../assets/Icons/on-hold.svg";
import { ReactComponent as Closed } from "../assets/Icons/closed.svg";
import { ReactComponent as Stop } from "../assets/Icons/stop.svg";
import { ReactComponent as NotStarted } from "../assets/Icons/not-started.svg";
import { ReactComponent as InTesting } from "../assets/Icons/in-testing.svg";
import { ReactComponent as InQueue } from "../assets/Icons/in-queue.svg";
import { ReactComponent as Running } from "../assets/Icons/running.svg";
import Pdf from "../assets/Icons/pdf-icon.svg";
import File from "../assets/Icons/code.svg";
import Csv from "../assets/Icons/csv.svg";
import Audio from "../assets/Icons/music.svg";
import Video from "../assets/Icons/video.svg";
import Word from "../assets/Icons/word.svg";
import Excel from "../assets/Icons/excel.svg";
import Ppt from "../assets/Icons/ppt.svg";
import Gmail from "../assets/Icons/gmail.svg";
import Microsoft from "../assets/Icons/microsoft.svg";
import Figma from "../assets/Icons/figma.svg";
import general from "../assets/Icons/general.svg";
import Slack from "../assets/Icons/slack.svg";
import Microsoft_Team from "../assets/Icons/microsoft-teams.svg";
import google_drive from "../assets/Icons/google-drive.svg";
import filezilla from "../assets/Icons/filezilla.svg";
import app_store from "../assets/Icons/app-store.svg";
import aws from "../assets/Icons/aws.svg";
import dribbble from "../assets/Icons/dribbble.svg";
import anydesk from "../assets/Icons/anydesk.svg";
import Git from "../assets/Icons/git.svg";
import Skype from "../assets/Icons/skype.svg";
import In_Queue from "../assets/Icons/in-queue.svg";
import Stop_r from "../assets/Icons/stop.svg";
import Running_r from "../assets/Icons/running.svg";
import Hold_r from "../assets/Icons/on-hold.svg";
import Completed_r from "../assets/Icons/closed.svg";
import CircleCheckIcon from "../assets/Icons/circle-check-icon.svg";
import CrossCircleIcon from "../assets/Icons/cross-circle-icon.svg";
import NotStarted_LS from "../assets/Icons/not-started.svg";
import Stop_LS from "../assets/Icons/stop.svg";
const cryptoKey = "SqT_cL@SsRoOm_S@T!Sh_393987";

export const commonservices = {
    setItem,
    getItem,
    LabelDisplayText,
    createGuid,
    DeleteDocumentByID,
    DeletePasswordByID,
    HidePassword,
    getLoginRoleData,
    CheckNullAndReturnBlank,
    CheckNullAndReturnBlankInObjectKey,
    CreateColor,
    getTimeInFormat,
    getDateInFormat,
    getDateInDBFormat,
    getDateTimeInDBFormat,
    getDateTimeInFormat,
    getDateInDDThMMMYYYY,
    GetTaskStatusIcon,
    ConvertMinutesToTimeFormat,
    getInitials,
    getLoginUserData,
    diffMinutes,
    SendPushNotification,
    StatusLables,
    getUserLoginMessage,
    getDateInDDMMYYYY,
    getDays,
    GetPriorityIcon,
    GetVaultMailIcon,
    ClearLoginDetails,
    GetLeaveStatusIcon,
    GetMonthNameList,
    GetMonthNoOfDayList,
    MakeValidationMessage,
    CustomValidationMessage,
    getDateTimeInDDMMFormat,
};

export const AppConfigData = {
    CryptoKey: cryptoKey,

    ////////Local
    //APIBasePath: "https://localhost:44384/api/",
    //SignalRHubPath: "https://localhost:44384/hubs/notifications",
    // APIFileAccessURL: "https://localhost:44384/wwwroot/",

    //////////QA
    //APIBasePath: "http://146.0.249.62:8282/api/",
    //SignalRHubPath: "http://146.0.249.62:8282/hubs/notifications",
    //APIFileAccessURL: "http://146.0.249.62:8282/wwwroot/",

    //////Production
    APIBasePath: "http://146.0.249.62:8484/api/",
    SignalRHubPath: "http://146.0.249.62:8484/hubs/notifications",
    APIFileAccessURL: "http://146.0.249.62:8484/wwwroot/",

    FileTypePath: {
        FILE: `${File}`,
        CODE: `${File}`,
        CSV: `${Csv}`,
        AUDIO: `${Audio}`,
        VIDEO: `${Video}`,
        PDF: `${Pdf}`,
        WORD: `${Word}`,
        EXCEL: `${Excel}`,
        PPT: `${Ppt}`,
    },

    MailTypePath: {
        GMAIL: `${Gmail}`,
        FIGMA: `${Figma}`,
        SKYPE: `${Skype}`,
        "MICROSOFT OFFICE": `${Microsoft}`,
        GIT: `${Git}`,
        SLACK: `${Slack}`,
        "MICROSOFT TEAMS": `${Microsoft_Team}`,
        "GOOGLE DRIVE": `${google_drive}`,
        FILEZILLA: `${filezilla}`,
        "APP STORE": `${app_store}`,
        DRIBBLE: `${dribbble}`,
        AWS: `${aws}`,
        ANYDESK: `${anydesk}`,
    },

    TaskIconPath: {
        "In Queue": `${In_Queue}`,
        Running: `${Running_r}`,
        Stop: `${Stop_r}`,
        OnHold: `${Hold_r}`,
        Completed: `${Completed_r}`,
    },
};

function GetVaultMailIcon(MailTypePath) {
    if (
        MailTypePath !== "" &&
        MailTypePath !== null &&
        MailTypePath !== undefined
    ) {
        return MailTypePath;
    } else {
        return `${general}`;
    }
}

function MakeValidationMessage(ValidationType, FieldName, CustomMessage) {
    let ValidationMessage = "";
    let This = "This";
    let RequiredMessage = "{0} is a required";
    let MaxLimitExceeded = "Max limit exceeded";
    let Email = "Enter valid email address";

    if (ValidationType.toUpperCase() === "REQUIRED") {
        if ($("[lbl-for='" + FieldName + "']").length > 0) {
            if (
                $("[lbl-for='" + FieldName + "']")
                    .text()
                    .trim() !== ""
            )
                ValidationMessage = RequiredMessage.replace(
                    "{0}",
                    $("[lbl-for='" + FieldName + "']")
                        .text()
                        .trim()
                );
            else ValidationMessage = RequiredMessage.replace("{0}", This);
        } else {
            ValidationMessage = RequiredMessage.replace("{0}", This);
        }
    } else if (ValidationType.toUpperCase() === "MAXLENGTH") {
        ValidationMessage = MaxLimitExceeded;
    } else if (ValidationType.toUpperCase() === "CUSTOM") {
        ValidationMessage = CustomMessage;
    } else if (ValidationType.toUpperCase() === "EMAIL") {
        ValidationMessage = Email;
    }
    return (
        ValidationMessage.charAt(0).toUpperCase() +
        ValidationMessage.slice(1).toLowerCase()
    );
}

const DisplayText = {
    AchievementDetail: "Achievement detail",
    Name: "Name",
    Email: "Email",
    No: "No",
    SrNo: "Sr.no.",
    Yes: "Yes",
    Tasks: "Tasks",
    Password: "Password",
    StartDateMustBeStartFromToday: "Start Date must be Start from today",
    EndDateCantBeBeforeStartDate: "end date can't be before start date",
    Hrs: "hrs",
    Working: "Working",
    EmployeeInformation: "Employee Information",
    Previous: "Previous",
    Work: "Work",
    TeamMembers: "Team Members",
    SearchAnything: "Search anything...",
    EmailAddress: "Email Address",
    AboutProject: "About Project",
    FirstName: "First Name",
    LastName: "Last Name",
    DOB: "DOB",
    MiddleName: "Middle Name",
    TermName: "Term Name",
    EmplyeesNo: "Emplyees No.",
    Position: "Position",
    Select: "Select",
    City: "City",
    Country: "Country",
    ContactNo: "Contact No",
    IsDependent: "Is Dependent ?",
    Type: "Type",
    Task: "Task",
    InProgress: "In Progress",
    ExportExcel: "Export Excel",
    ExportPDFs: "Export PDFs",
    Contact1: "Contact Number 1",
    Contact2: "Contact Number 2",
    Contact1Name: "Contact Number 1 Name",
    Contact2Name: "Contact Number 2 Name",
    Contact1Type: "Contact Number 1 Type",
    Contact2Type: "Contact Number 2 Type",
    State: "State",
    BankName: "Bank Name",
    BankBranch: "Bank Branch",
    Details: "Details",
    Disabilities: "Disabilities",
    Action: "Action",
    Dashboard: "Dashboard",
    AddProjectVault: "Project Vault",
    Category: "Category",
    TypeTerm: "Type Term",
    DefaultValues: "Default Values",
    Term: "Term",
    Technology: "Technology",
    DueDate: "Due Date",
    Users: "Users",
    TaskCount: "Task Count",
    Leave: "Leave",
    Hours: "Hours",
    Task: "Task",
    Todays: "Today�s",
    Completed: "Completed",
    ViewFile: "View File",
    ProjectTerm: "Project Term",
    Project: "Project",
    Projects: "Projects",
    ProjectName: "Project Name",
    ProjectStory: "Project Story",
    CommentedOn: "Commented on",
    Story: "Story",
    TotalCostAmt: "Total Cost Amt.",
    LeaveRequest: "Leave Request",
    CL: "CL",
    ReasonForLeave: "Reason For Leave",
    PaternityLeave: "Paternity Leave",
    Total: "Total",
    CasualLeave: "Casual Leave",
    OverCostAmt: "Over Cost Amt.",
    NoOfUser: "No. Of User",
    Of: "Of",
    CreatedBy: "Created By",
    CreatedOn: "Created On",
    Vault: "Vault",
    ActualEndDate: "Actual End Date",
    ClientID: "Client",
    AdharNo: "Adhar No.",
    Close: "Close",
    Next: "Next",
    AddQualificationDetail: "Qualification Detail",
    AddAchievementDetail: "Achievement Detail",
    AddExperienceDetail: "Experience Detail",
    AddFamilyDetail: "Family Detail",
    Save: "Save",
    PancardNo: "Pancard No.",
    Signin: "Sign In",
    MobileNo: "Mobile No.",
    DateOfJoining: "Date Of Joining",
    DateOfBirth: "Date Of Birth",
    PlaceOfBirth: "Place Of Birth",
    RoleName: "Role Name",
    Progress: "Progress",
    View: "View",
    Create: "Create",
    Update: "Update",
    Delete: "Delete",
    Download: "Download",
    RightName: "Right Name",
    IsProjectRole: "Is Project Role ?",
    EnterTermName: "Enter Term Name",
    UserRoleRights: "User Role Rights",
    EnterYourCategory: "Enter Category",
    AddProjectTermDetails: "Project Term Details",
    ResidenceType: "Residence Type",
    Residence: "Residence",
    Language: "Language",
    Languages: "Languages",
    ZipCode: "Zip Code",
    VillageOrTownOrCity: "Village or Town or City",
    QualificationDetail: "Qualification Detail",
    DegreeDiploma: "Degree/Diploma",
    Period: "Period",
    Occupation: "Occupation",
    WorkDetail: "Work Detail",
    Dependent: "Dependent",
    NameOfEmployer: "Name of Employer",
    Designation: "Designation",
    DesignationAndMajor: "Designation & Major",
    Responsibilities: "Responsibilities",
    Relationship: "Relationship",
    ReasonForLeaving: "Reason for Leaving",
    FamilyDetail: "Family Detail",
    Year: "Year",
    GrossIncome: "Gross Income",
    SubjectStudied: "Subject Studied",
    LeaveDetail: "Leave Detail",
    PercentofMarks: "% of Marks",
    SchoolCollageUniversity: "School/Collage/University",
    DateOfLeaving: "Date Of Leaving",
    Address: "Address",
    Address1: "Address Line 1",
    Address2: "Address Line 2",
    MaritalStatus: "Marital Status",
    DocType: "Doc Type",
    Tag: "Tag",
    DocName: "Doc Name",
    ShowPassword: "Show Password",
    UserName: "User Name",
    EmployeeType: "Employee Type",
    EnterShortProfile: "Enter Short Profile",
    EnterOrignalDocDetails: "Enter Orignal Document Details",
    ChooseProfilePic: "Choose Profile Picture",
    ChooseDocumentType: "Choose Document Type",
    ChooseProjectLogoFile: "Choose Project Logo File",
    ChooseFile: "Choose File",
    SelectYourSkillTag: "Select Skill Tag",
    Employee: "Employee",
    Gender: "Gender",
    Dedicated: "Dedicated",
    FixCost: "FixCost",
    StartDate: "Start Date",
    Technologies: "Technologies",
    EndDate: "End Date",
    Request: "Request",
    Status: "Status",
    Documents: "Documents",
    IsFixCost: "Is Fix Cost ?",
    IsManager: "Is Manager ?",
    IsTeamLead: "Is Team Leader ?",
    IsMember: "Is Member ?",
    IsArchive: "Is Archive ?",
    Personal: "Personal",
    ProjectRole: "Project Role",
    UserRole: "User Role",
    Share: "Share",
    SharePassword: "Share Password",
    ShortDetails: "Short Details",
    ProjectCreatedOn: "Project Created On",
    ProjectStatusOn: "Project Status On",
    Deadline: "Deadline",
    Department: "Department",
    BankAccountNo: "Bank Account No.",
    BankIFSCCode: "Bank IFSC Code",
    BankAccountName: "Bank Account Name",
    BankAccountType: "Bank Account Type",
    CompanyName: "Company Name",
    IssueDate: "Issue Date",
    WorkExperience: "Work Experience",
    Credentials: "Credentials",
    Members: "Members",
    AddProjectUser: "Project User",
    User: "User",
    UserID: "User",
    ConfirmYourPassword: "Confirm Password",
    Priority: "Priority",
    DisplayName: "Display Name",
    AssociationType: "Asscociation Type",
    IsBlock: "Is Block ?",
    Title: "Title",
    Description: "Description",
    AddProjectStory: "Project Story",
    BrowserNotSupportAudio: "Browser Not Support Audio",
    BrowserNotSupportVideo: "Browser Not Support Video",
    UnableToLoadContent: "Unable To Load Content, Click Here For Download",
    Profile: "Profile",
    ContactDetail: "Contact Detail",
    OfficeEmail: "Office Email",
    Office: "Office",
    TypeOfDegree: "Type Of Degree",
    Institute: "Institute",
    Duration: "Duration",
    Salary: "Salary",
    Result: "Result",
    MainSubject: "Main Subject",
    SkypeId: "Skype Id",
    WorkedOn: "Worked On",
    You: "You",
    CreatedThis: "Created This",
    Activities: "Activities",
    AddRoleDetails: "Role Details",
    DaysLeft: "Days Left",
    UpdatePassword: "Update Password",
    MoNo: "Mobile No.",
    EmpNo: "Emp No.",
    AreYouSureToDoDelete: "Are you sure to do delete ?",
    ForgotPassword: "Forgot Password",
    EnterregisteredEmail: "Enter Registered Email",
    GetPassword: "Get Password",
    GotoLogin: "Go to Login",
    InvalidDetails: "Invalid Details",
    AddEmployeesDetails: "Employees Details",
    AddEmployeesDocument: "Employees Document",
    AddClientDetails: "Client Details",
    AddProject: "Project",
    Client: "Client",
    Role: "Role",
    TaskDetails: "Task Details",
    AddTaskDetails: "Task Details",
    CreateTask: "Create Task",
    AssignTo: "Assign To",
    LicenseAndCertification: "License & Certification",
    Institution: "Institution",
    Skills: "Skills",
    Document: "Document",
    Family: "Family",
    Experience: "Experience",
    Achievement: "Achievement",
    SubPoint: "Sub Point",
    AreYouSureWantToStartTask: "Are You Sure Want To Start Task ?",
    AreYouSureWantToStopTask: "Are You Sure Want To Stop Task ?",
    Confirmation: "Confirmation",
    AddAComment: "Add a comment",
    AddCommentsOrSelectAtLeastOneFile: "Add Comments Or Select at least one file",
    Cancel: "Cancel",
    SMTPHost: "SMTP Host",
    Port: "Port",
    TempleteFor: "Templete For",
    MailSendFrom: "Mail Send From",
    MailSubject: "Subject",
    AreYouSureToLogout: "Are you sure you want to logout ?",
    LeaveType: "Leave Type",
    From: "From",
    To: "To",
    AreYouSureToCancel: "Are You Sure To Cancel",
    Comment: "Comment",
    UserConfirmation: "User Confirmation",
    AreYouSureToApproved: "Are You Sure To Change Status As Approved",
    AreYouSureToRejected: "Are You Sure To Change Status As Rejected",
    TransactionDetails: "Transaction Details",
    LeaveApplicationStatus: "Leave Application Status",
    MyApprovalStatus: "My Approval Status",
    EnterYourEmail: "Enter Email",
    EnterYourPassword: "Enter Password",
    EnterYourConfirmPassword: "Enter Confirm Password",
    SelectTheUserName: "Assign to is a required",
    SelectProject: "Project name is a required",
    GoForProcess: "Go For Process",
    PersonalEmailID: "Personal Email ID",
    YourUserName: "Your User Name",
    YourPassword: "Your Password",

    //// validations
    //EnterTypeOfDegree: "Enter Type Of Degree",
    //EnterInstitute: "Enter Institute",
    //EnterDuration: "Enter Duration",
    //EnterResult: "Enter Result",
    //EnterMainSubject: "Enter Main Subject",
    //EnterSkills: "Enter Skills",
    //EnterCredentials: "Enter Credentials",
    //EnterIssueDate: "Enter Issue Date",
    //EnterCompanyName: "Enter Company Name",
    //EnterAddress: "Enter Address",
    //EnterDesignation: "Enter Designation",
    //EnterGrossIncome: "Enter Gross Income",
    //EnterResonForLeaving: "Enter Reson for Leaving",
    //EnterResponsibilities: "Enter Responsibilities",
    //EnterName: "Enter Name",
    //EnterRelationship: "Enter Relationship",
    //SelectDOB: "Select DOB",
    //EnterOccupation: "Enter Occupation",
    //EnterNameOfEmployer: "Enter Name Of Employer",
    //EnterContactNo: "Enter Contact No.",
    //PleaseUploadLessThan3FilesOrSmallerThan20MBOfFileSize: "Please upload less than 3 files or smaller than 20 MB of File Size",
    //EnterType: "Enter Type",
    //EnterYourUserName: "Enter User Name",
    //SelectTheTypeTerm: "Select The Type Term",
    //SelectTheUserRole: "Select The User Role",

    //SelectCategory: "Select Category",
    //SelectTheProjectRoleId: "Select The Project Role",
    //EnterYourDetails: "Enter Details",
    //EnterYourContactNo: "Enter Contact No",
    //EnterYourFirstName: "Enter First Name",
    //EnterYourName: "Enter Name",
    //EnterYourAddress: "Enter Address",
    //EnterYourCountry: "Enter Country",
    //EnterYourMiddleName: "Enter Middle Name",
    //EnterYourLastName: "Enter Last name",
    //EnterYourMobileNo: "Enter Mobile No",

    //EnterSMTPHost: "Enter SMTP Host",
    //EnterPort: "Enter Port",
    //SelectYourGender: "Select Gender",
    //SelectYourDateOfJoining: "Select Date Of Joining",
    //EnterYourEmplyeesNo: "Enter Emplyee No",
    //EnterYourRoleName: "Enter Role Name",
    //TickForIsProjectRole: "Tick For Is Project Role",
    //SelectYourPosition: "Select Position",
    //SelectYourDepartment: "Select Department",
    //EnterYourAdharNo: "Enter Adhar No.",
    //EnterYourPancardNo: "Enter Pancard No.",
    //EnterProjectName: "Enter Project Name",
    //EnterShortDetails: "Enter Short Details",
    //EnterClientID: "Select Client",
    //SelectStartDate: "Select Start Date",
    //SelectTheClient: "Select The Client",
    //SelectEndDate: "Select End Date",
    //SelectActualEndDate: "Select Actual End Date",
    //SelectStatus: "Select Status",
    //PleaseCheckIsFixCost: "Please Check If IsFixCost",
    //EnterYourTitle: "Enter Title",
    //ValidationEmailRequired: "Email is required",
    //ValidationEmailInvalid: "Please Enter Valid Email Address",
    //PleaseEnterTheTitle: "Please Enter The Title",
    //EnterDetails: "Enter Details",
    //ValidationPasswordRequired: "Password is required",
    //ValidationPasswordInvalid: "Both password need to be the same",
    //SelectYourPriority: "Select Priority",
    //SelectProject: "Select Project",
    //SelectProjectStory: "Select Project Story",
    //SelectLeave: "Select Leave",
    //EnterFromDate: "Enter From Date",
    //EnterToDate: "Enter To Date",
    //EnterSubject: "Enter Subject",
    //SelectLeaveType: "Select Leave Type",
    //EnterDecimalValue: "Enter Decimal Value",
    //SelectMailSendFrom: "Select Mail Send From",
    //EnterMailSubject: "Enter Subject",
};

function LabelDisplayText(lable) {
    return DisplayText[lable];
}

function CustomValidationMessage(lable) {
    let Message = {
        ValidationOnlyImage:
            "Only the following formats are accepted: .jpeg, .jpg, .png",
        ValidationDateCanBeLessThanOrEqualTodayDate:
            "Date can be less than or equal today date",
        ValidationDateCanBeGreaterThanOrEqualTodayDate:
            "Date can be greater than or equal today date",
        ValidationCanNotBeZero: "Values can not be zero",
        ValidationData1CannotBeLessThanDate2: "{0} cannot be less than {1}",
        ValidationData1CannotBeGreaterThanDate2: "{0} cannot be greater than {1}",
        ValidationField1MustBeSameField1: "{0} and {1} must be same",
    };
    return Message[lable];
}

function ClearLoginDetails() {
    localStorage.removeItem("token");
    localStorage.removeItem("roleData");
    localStorage.removeItem("userData");
}

// Crypto convert for userdata and roleData
function setItem(key, strString) {
    let CryptoJS = require("crypto-js");
    localStorage.setItem(key, CryptoJS.AES.encrypt(strString, cryptoKey));
}

function getItem(key) {
    let CryptoJS = require("crypto-js");
    let dataValues = localStorage.getItem(key) || "";
    let dataStr = "";
    if (dataValues !== "") {
        var bytes = CryptoJS.AES.decrypt(dataValues, cryptoKey);
        dataStr = bytes.toString(CryptoJS.enc.Utf8);
    }
    return dataStr;
}

// createGuid
function createGuid() {
    function S4() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }
    return (
        S4() +
        S4() +
        "-" +
        S4() +
        "-4" +
        S4().substr(0, 3) +
        "-" +
        S4() +
        "-" +
        S4() +
        S4() +
        S4()
    ).toLowerCase();
}

//  removeDocuments
async function DeleteDocumentByID(ID, OtherID, OtherIDType) {
    if (ID !== "" && ID !== "0" && ID !== 0 && ID !== null && ID !== undefined) {
        let request = {
            id: ID,
            OtherID: OtherID,
            OtherIDType: OtherIDType,
            requestFrom: "Web",
        };
        const axiosConfig = {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        };
        return await axios.post(
            `${AppConfigData.APIBasePath}v1/documents/delete`,
            request,
            axiosConfig
        );
    } else {
        return { data: { status_code: 0, message: DisplayText.InvalidDetails } };
    }
}

//  removePassword
async function DeletePasswordByID(ID) {
    if (ID !== "" && ID !== "0" && ID !== 0 && ID !== null && ID !== undefined) {
        let request = {
            id: ID,
            requestFrom: "Web",
        };
        const axiosConfig = {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        };
        return await axios.post(
            `${AppConfigData.APIBasePath}v1/passwordmanagment/delete`,
            request,
            axiosConfig
        );
    } else {
        return { data: { status_code: 0, message: DisplayText.InvalidDetails } };
    }
}

// HidePassword
function HidePassword(password) {
    let str = "";
    if (password !== "" && password !== null && password !== undefined) {
        for (let i = 0; i < password.length; i++) {
            str = str + "*";
        }
    }
    return str;
}

// getLoginRoleData
function getLoginRoleData(PageName, ActionType) {
    let IsValidAction = false;
    var roledata = [];
    let roledataStr = getItem("roleData");

    if (roledataStr !== "") {
        roledata = JSON.parse(roledataStr);
        for (let i = 0; i < roledata.length; i++) {
            if (
                roledata[i].RightName.toUpperCase().replaceAll(" ", "") ===
                PageName.toUpperCase().replaceAll(" ", "")
            ) {
                if (ActionType.toUpperCase() === "ISVIEW") {
                    return roledata[i].IsView === 1 ? true : false;
                } else if (ActionType.toUpperCase() === "ISCREATE") {
                    return roledata[i].IsCreate === 1 ? true : false;
                } else if (ActionType.toUpperCase() === "ISDELETE") {
                    return roledata[i].IsDelete === 1 ? true : false;
                } else if (ActionType.toUpperCase() === "ISDOWNLOAD") {
                    return roledata[i].IsDownload === 1 ? true : false;
                } else if (ActionType.toUpperCase() === "ISUPDATE") {
                    return roledata[i].IsUpdate === 1 ? true : false;
                }
            }
        }
    }

    return IsValidAction;
}
// check Null and return blank in formik field value separatly
function CheckNullAndReturnBlank(Value) {
    if (Value !== null && Value !== undefined) {
        if (Value.toLowerCase() !== "null") {
            return Value;
        }
    }
    return "";
}
// check Null and return blank in formik field value in Object
function CheckNullAndReturnBlankInObjectKey(Object) {
    for (let key in Object) {
        let Val = Object[key];
        if (Val !== null && Val !== undefined) {
            if (Val.toString().toLowerCase() !== "null") {
            } else {
                Object[key] = "";
            }
        } else {
            Object[key] = "";
        }
    }
}

function CreateColor(str) {
    let BGRGB = "000000";
    let FRGB = "FFFFFF";
    if (str !== undefined) {
        str = str.replace(".", "");
        if (str !== "") {
            str = str + "000000";
            let hash = hashCode(str);
            BGRGB = intToRGB(hash);
            FRGB = invertColor(BGRGB);
        }
    }
    return { BGColor: BGRGB, FontColor: FRGB };
}

function hashCode(str) {
    // java String#hashCode
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
}

function intToRGB(i) {
    var c = (i & 0x00ffffff).toString(16).toUpperCase();
    return "#" + "000000".substring(0, 6 - c.length) + c;
}

function invertColor(hex) {
    if (hex.indexOf("#") === 0) {
        hex = hex.slice(1);
    }
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
        throw new Error("Invalid HEX color.");
    }

    var r = (255).toString(16),
        g = (255).toString(16),
        b = (255).toString(16);
    return "#" + padZero(r) + padZero(g) + padZero(b);
}

function padZero(str, len) {
    len = len || 2;
    var zeros = new Array(len).join("0");
    return (zeros + str).slice(-len);
}

function getDateTimeInDBFormat(date) {
    if (date !== null && date !== "" && date !== undefined)
        return Moment(date).format("YYYY-MM-DD HH:mm:ss");
    else return "";
}

function getDateInDBFormat(date) {
    if (date !== null && date !== "" && date !== undefined)
        return Moment(date).format("YYYY-MM-DD");
    else return "";
}

function getDateInFormat(date) {
    if (date !== null && date !== "" && date !== undefined)
        return Moment(date).format("DD-MM-yyyy");
    else return "";
}

function getTimeInFormat(date) {
    if (date !== null && date !== "" && date !== undefined)
        return Moment(date).format("hh:mm a");
    else return "";
}

function getDateTimeInFormat(date) {
    if (date !== null && date !== "" && date !== undefined)
        return Moment(date).format("DD-MM-yyyy hh:mm a");
    else return "";
}

function getDateTimeInDDMMFormat(date) {
    if (date !== null && date !== "" && date !== undefined)
        return Moment(date).format("DD-MM hh:mm a");
    else return "";
}

function getDateInDDThMMMYYYY(date) {
    if (date !== null && date !== "" && date !== undefined)
        return Moment(date).format("Do MMM yyyy");
    else return "";
}

// Final New Date Format UI
function getDateInDDMMYYYY(date) {
    if (date !== null && date !== "" && date !== undefined)
        return Moment(date).format("DD MMM YYYY");
    else return "";
}

function StatusLables(status) {
    if (status.toUpperCase() === "IN TESTING") {
        return "label-badges in-testing";
    } else if (status.toUpperCase() === "IN QUEUE") {
        return "label-badges in-queue";
    } else if (status.toUpperCase() === "IN PROCESS") {
        return "label-badges in-progress";
    } else if (status.toUpperCase() === "ON HOLD") {
        return "label-badges on-hold";
    } else if (status.toUpperCase() === "NOT STARTED") {
        return "label-badges not-started";
    } else if (status.toUpperCase() === "CLOSED") {
        return "label-badges closed";
    } else if (status.toUpperCase() === "COMPLETED") {
        return "label-badges completed";
    } else if (status.toUpperCase() === "STOP") {
        return "label-badges stop";
    } else if (status.toUpperCase() === "RUNNING") {
        return "label-badges running";
    } else {
        return "";
    }
}

function GetTaskStatusIcon(status) {
    if (status.toUpperCase() === "NOT STARTED") {
        return <NotStarted />;
    } else if (status.toUpperCase() === "IN PROCESS") {
        return <InProcess />;
    } else if (status.toUpperCase() === "ON HOLD") {
        return <OnHold />;
    } else if (status.toUpperCase() === "IN TESTING") {
        return <InTesting />;
    } else if (status.toUpperCase() === "CLOSED") {
        return <Closed />;
    } else if (status.toUpperCase() === "IN QUEUE") {
        return <InQueue />;
    } else if (status.toUpperCase() === "RUNNING") {
        return <Running />;
    } else if (status.toUpperCase() === "STOP") {
        return <Stop />;
    } else if (status.toUpperCase() === "COMPLETED") {
        return <Closed />;
    } else {
        return "";
    }
}

function GetPriorityIcon(Priority) {
    if (Priority.toUpperCase() === "HIGHEST") {
        return `${Highest}`;
    } else if (Priority.toUpperCase() === "LOW") {
        return `${Low}`;
    } else if (Priority.toUpperCase() === "NORMAL") {
        return `${Normal}`;
    } else if (Priority.toUpperCase() === "HIGH") {
        return `${High}`;
    }
    // else if (status.toUpperCase() === "IN TESTING") {
    //     return <i className="fa-solid fa-user-gear"></i>;
    // }
    // else if (status.toUpperCase() === "CLOSED") {
    //     return <i className="fa-regular fa-circle-check"></i>;
    // }
    else {
        return "";
    }
}

function GetLeaveStatusIcon(Status) {
    if (Status.toUpperCase() === "PENDING") {
        return `${NotStarted_LS}`;
    } else if (Status.toUpperCase() === "APPROVED") {
        return `${CircleCheckIcon}`;
    } else if (Status.toUpperCase() === "REJECTED") {
        return `${CrossCircleIcon}`;
    } else if (Status.toUpperCase() === "CANCELLED") {
        return `${Stop_LS}`;
    } else {
        return "";
    }
}

// hrs
function ConvertMinutesToTimeFormat(Minutes) {
    if (Minutes !== null && Minutes !== "" && Minutes !== undefined) {
        return (
            Math.floor(Minutes / 60)
                .toString()
                .padStart(2, "0") +
            ":" +
            (Minutes % 60).toString().padStart(2, "0")
        );
    } else {
        return "00:00";
    }
}

function getInitials(name) {
    var initials = "";
    if (name) {
        var parts = name.split(" ");
        for (var i = 0; i < parts.length; i++) {
            if (parts[i].length > 0 && parts[i] !== "" && i < 2) {
                initials += parts[i][0];
            }
        }
    }
    return initials;
}

function getLoginUserData() {
    var data = [
        {
            UserID: 0,
            DisplayName: "",
            AssociationID: 0,
            AssociationType: "",
            ProfilePic: "",
        },
    ];
    let dataStr = getItem("userData");
    if (dataStr !== "") {
        data = JSON.parse(dataStr);
    }
    return data;
}

// different-minutes
function diffMinutes(dt2, dt1) {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
}

function SendPushNotification(data) {
    if (data !== null && data !== undefined && data.length !== 0) {
        const connect = new HubConnectionBuilder()
            .withUrl(AppConfigData.SignalRHubPath, { withCredentials: false })
            .withAutomaticReconnect()
            .build();
        connect
            .start()
            .then(() => {
                connect.send("SendPushNotification", data);
            })
            .catch((error) => { });
    }
}

function getUserLoginMessage() {
    var today = new Date();
    var curHr = today.getHours();

    if (curHr < 12) {
        return (
            <>
                <i className="fa-regular fa-sun-bright"></i> Good Morning!
            </>
        );
    } else if (curHr < 18) {
        return (
            <>
                <i className="fa-sharp fa-solid fa-sun-bright"></i> Good Afternoon!
            </>
        );
    } else {
        return (
            <>
                <i className="fa-light fa-brightness-low"></i> Good Evening!
            </>
        );
    }
}

function getDays(date) {
    let today = getDateInDDMMYYYY(new Date());

    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    let prevDay = getDateInDDMMYYYY(yesterday);

    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    let nextDay = getDateInDDMMYYYY(tomorrow);

    if (date === today) {
        return <>Today</>;
    } else if (date === nextDay) {
        return <>Tomorrow</>;
    } else if (date === prevDay) {
        return <>Yesterday</>;
    } else {
        return <>{date}</>;
    }
}

function GetMonthNameList() {
    return [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
}

function GetMonthNoOfDayList(MonthName) {
    let lst = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
        22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
    ];

    if (MonthName !== null && MonthName !== undefined && MonthName !== "") {
        lst = [];
        let LastDateDay = new Date(
            new Date().getFullYear(),
            GetMonthNameList().indexOf(MonthName) + 1,
            0
        ).getDate();
        for (let i = 1; i <= LastDateDay; i++) {
            lst.push(i);
        }
    }
    return lst;
}
