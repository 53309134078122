import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Breadcrumb,
  Button,
  Col,
  Container,
  OverlayTrigger,
  Row,
  // Modal,
  Tooltip,
  Table,
} from "react-bootstrap";
import AddEmployeeModal from "../../Modal/AddEmployeeModal";
import { AppConfigData } from "../../Services/commonService";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import { useNavigate } from "react-router-dom";
import { commonservices } from "../../Services/commonService";
import placeholdericon from "../../assets/Icons/placeholder.svg";
import $ from "jquery";
import Loader, { LoaderType } from "../../Loader";
import { ReactComponent as Pencil } from "../../assets/Icons/pencil.svg";
import { ReactComponent as Trash } from "../../assets/Icons/trash.svg";
import { ReactComponent as DownloadIcon } from "../../assets/Icons/download-icon.svg";
import { ReactComponent as ExportIocn } from "../../assets/Icons/export-iocn.svg";
import { downloadExcel } from "react-export-table-to-excel";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

function Employees() {
  const [modalShow, setModalShow] = useState(false);
  const [empData, setEmpData] = useState({
    Data: [],
    IsLoading: true,
  });
  const [EMPID, setEMPID] = useState(0);
  const navigate = useNavigate();

  const [searchobj, setSearchobj] = useState({
    firstName: "",
    lastName: "",
    middleName: "",
    mobileNo: "",
    emplyeesNo: "",
    emailID: "",
    adharNo: "",
    pancardNo: "",
    searchText: "",
  });

  function bindList() {
    setEmpData({
      Data: [],
      IsLoading: true,
    });
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(
        `${AppConfigData.APIBasePath}v1/employees/getlist`,
        searchobj,
        axiosConfig
      )
      .then((res) => {
        if (res.data.status_code === 0) {
          setEmpData({ Data: [], IsLoading: false });
        } else if (res.data.status_code === 1) {
          setEmpData({ Data: res.data.data, IsLoading: false });
        } else if (res.data.status_code === 2) {
          commonservices.ClearLoginDetails();
          navigate("/");
        } else {
          setEmpData({ Data: [], IsLoading: false });
        }
      })
      .catch((err) => {
        setEmpData({ Data: [], IsLoading: false });
      });
  }

  function fnOpenEmployeeAddEditPopup(id) {
    setEMPID(id);
    setModalShow(true);
  }

  function deleteData(id) {
    let input = {
      id: id,
      requestFrom: "Web",
    };
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(
        `${AppConfigData.APIBasePath}v1/employees/delete`,
        input,
        axiosConfig
      )
      .then((res) => {
        if (res.data.status_code === 0) {
          toast.error(res.data.message);
        } else if (res.data.status_code === 1) {
          toast.success(res.data.message);
          bindList();
        } else if (res.data.status_code === 2) {
          toast.error(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {});
  }

  const header = [
    "EmplyeesNo",
    "FirstName",
    "MobileNo",
    "Department",
    "EmailID",
  ];
  function handleDownloadExcel() {
    let data = [];
    empData.Data.forEach((item) => {
      let arrayToAppend = [];
      arrayToAppend = [
        ...arrayToAppend,
        item.FirstName,
        item.EmplyeesNo,
        item.MobileNo,
        item.Department,
        item.EmailID,
      ];
      data = [...data, arrayToAppend];
    });
    downloadExcel({
      fileName: "Employees Details",
      sheet: "Employees Details",
      tablePayload: {
        header,
        body: data,
      },
    });
  }
  function PDFDownload() {
    let filename = "Employees Details";
    let data = [];
    empData.Data.forEach((item) => {
      let arrayToAppend = [];
      arrayToAppend = [
        ...arrayToAppend,
        item.FirstName,
        item.EmplyeesNo,
        item.MobileNo,
        item.Department,
        item.EmailID,
      ];
      data = [...data, arrayToAppend];
    });
    const doc = new jsPDF();
    //autoTable(doc, { html: '.table' })
    autoTable(doc, {
      head: [header],
      body: data,
      headStyles: {
        textColor: "#000",
        halign: "center",
        valign: "middle",
        lineWidth: 0.1,
        lineColor: "#ABABAB",
        fillColor: "#fff",
        font: "times",
        fontStyle: "bold",
      },
      styles: {
        textColor: "#fff",
        halign: "center",
        valign: "middle",
        font: "times",
        size: "10px",
      },
      bodyStyles: {
        textColor: "#000",
        halign: "center",
        valign: "middle",
        font: "times",
        size: "10px",
      },
      margin: { top: 0, bottom: 0, left: 5, right: 5 },
      theme: "grid",
    });
    doc.save(filename + ".pdf");
  }

  useEffect(() => {
    bindList();
  }, []);

  // For Datatable
  $(document).ready(function () {
    $("table#EmployeestblData").DataTable({
      destroy: true,
      searching: false,
      dom: "ft<'tbl-info'<'length-info'li<'paggination'p>>>",
      aoColumnDefs: [
        {
          bSortable: false,
          aTargets: [-1] /* 1st one, start by the right */,
        },
      ],
    });
  });
  useEffect(() => {
    $(document).ready(function () {
      $("table#EmployeestblData")
        .DataTable({
          destroy: true,
          searching: false,
          dom: "ft<'tbl-info'<'length-info'li<'paggination'p>>>",
          aoColumnDefs: [
            {
              bSortable: false,
              aTargets: [-1] /* 1st one, start by the right */,
            },
          ],
        })
        .destroy();
    });
  }, []);

  return (
    <>
      <div className="table-section-col common-header-page empheadmain">
        <Row>
          <Col xl={7} lg={4} md={4}>
            <div className="page-title-left">
              {commonservices.LabelDisplayText("Employee")}
            </div>
          </Col>
          <Col xl={5} lg={8} md={8} className="mt-auto mb-auto">
            <div className="d-flex align-items-center justify-content-end">
              <div className="inner-search-bar inner-search-grey w-100">
                <input
                  type="text"
                  className="form-control"
                  placeholder="SearchAnything"
                  name="searchData"
                  onKeyUp={(e) => e.keyCode === 13 && bindList()}
                  onChange={(e) =>
                    setSearchobj({
                      ...searchobj,
                      ["searchText"]: e.target.value,
                    })
                  }
                />

                <Button
                  variant="btn btn-icon-btn-lg search-button"
                  title="Search"
                  onClick={(e) => bindList()}
                >
                  <i className="fa-light fa-magnifying-glass"></i>
                </Button>
              </div>
              {commonservices.getLoginRoleData("Employees", "IsCreate") && (
                <div className="project-add-icon">
                  <Button
                    className="project-btn"
                    title="Add"
                    onClick={(e) => fnOpenEmployeeAddEditPopup(0)}
                  >
                    <i className="fa-regular fa-plus"></i>
                  </Button>
                </div>
              )}
              {commonservices.getLoginRoleData("Employees", "IsDownload") && (
                <div className="expor-download-button d-flex">
                  <Button variant="project-btn ms-2" title="Export">
                    <ExportIocn onClick={handleDownloadExcel} />
                  </Button>
                  <Button variant="project-btn pe-0 ps-1" title="Download">
                    <DownloadIcon onClick={PDFDownload} />
                  </Button>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
      <div className="vault-tab vault-page">
        <div className="table-col">
          <div className="recent-task-section">
            {empData.IsLoading ? (
              <>
                <Loader type={LoaderType.Table} count={4} />
              </>
            ) : (
              <div className="table-scroll">
                <Table
                  id="EmployeestblData"
                  className="table table-inner-section"
                  responsive
                >
                  <thead>
                    <tr>
                      <th className="width-200px">
                        {commonservices.LabelDisplayText("EmpNo")}
                      </th>
                      <th className="width-300px">
                        <div className="username">
                          {commonservices.LabelDisplayText("Name")}
                        </div>
                      </th>
                      <th className="width-300px">
                        {commonservices.LabelDisplayText("MoNo")}
                      </th>
                      <th className="width-200px">
                        {commonservices.LabelDisplayText("Department")}
                      </th>
                      <th>{commonservices.LabelDisplayText("Email")}</th>
                      <th className="action-class text-end width-100px">
                        {commonservices.LabelDisplayText("Action")}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="table-section-col">
                    {empData.Data.length > 0 &&
                      empData.Data.map((item, index) => (
                        <tr key={index}>
                          <td className="padding-top-bottom text-nowrap width-200px">
                            <div className="project-description">
                              {item.EmplyeesNo}
                            </div>
                          </td>
                          <td className="padding-top-bottom text-nowrap width-300px">
                            <div className="d-flex align-items-center">
                              <img
                                className="rounded-circle"
                                src={
                                  item.ProfilePic
                                    ? item.ProfilePic
                                    : placeholdericon
                                }
                                alt={commonservices.getInitials(item.FirstName)}
                                width="34"
                                height="34"
                              />
                              <div className="username project-description">
                                {item.FirstName}
                              </div>
                            </div>
                          </td>
                          <td className="text-nowrap width-300px padding-top-bottom">
                            <div className="table-text-common">
                              {item.MobileNo}
                            </div>
                          </td>
                          <td className="width-200px">
                            <div className="d-flex table-text-common">
                              {item.Department}
                            </div>
                          </td>
                          <td className="text-nowrap padding-top-bottom">
                            {" "}
                            <div className="table-text-common">
                              {item.EmailID}
                            </div>
                          </td>
                          <td className="text-end text-nowrap">
                            {commonservices.getLoginRoleData(
                              "Employees",
                              "IsUpdate"
                            ) && (
                              <Button
                                className="icon-btn me-1"
                                title="Edit"
                                onClick={(e) =>
                                  fnOpenEmployeeAddEditPopup(item.EmployeeID)
                                }
                              >
                                <Pencil />
                              </Button>
                            )}
                            {commonservices.getLoginRoleData(
                              "Employees",
                              "IsDelete"
                            ) && (
                              <Button
                                className="icon-btn"
                                title="Delete"
                                onClick={(e) => {
                                  confirmAlert({
                                    message: commonservices.LabelDisplayText(
                                      "AreYouSureToDoDelete"
                                    ),
                                    buttons: [
                                      {
                                        label:
                                          commonservices.LabelDisplayText(
                                            "Yes"
                                          ),
                                        onClick: () =>
                                          deleteData(item.EmployeeID),
                                      },
                                      {
                                        label:
                                          commonservices.LabelDisplayText("No"),
                                      },
                                    ],
                                  });
                                }}
                              >
                                <Trash />
                              </Button>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            )}
          </div>
        </div>
      </div>

      {modalShow && (
        <AddEmployeeModal
          id={EMPID}
          show={modalShow}
          bindList={bindList}
          onHide={() => setModalShow(false)}
        />
      )}
    </>
  );
}

export default Employees;
