import axios from "axios";
import { useState, useEffect } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { default as React } from "react";
import { toast } from "react-toastify";
import * as yup from "yup";
import { commonservices, AppConfigData } from "../Services/commonService";
import { Col, Modal, Button, Container, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Spinner from "react-bootstrap/Spinner";

const AddMailTemplatesModal = (props) => {
    const [IsGetDetailsDone, setIsGetDetailsDone] = useState(false);
    const [MailSetID, setMailSetID] = useState([]);
    const [TAGName, setTAGName] = useState([]);
    const [data, setData] = useState("");
    const [Submitting, setSubmitting] = useState(false);
    const navigate = useNavigate();
    const [IsValidationMessageSet, setIsValidationMessageSet] = useState("");

    useEffect(() => {
        setIsValidationMessageSet(true);
    });

    const formFieldLength = {
        Title: 167,
    };

    const formInitialSchema = {
        MailTemplatesID: 0,
        Body: "",
        MailSettingID: "",
        Title: "",
    };

    const formValidation = yup.object().shape({
        Title: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "Title"))
            .test('len', commonservices.MakeValidationMessage("maxlength", ""), (value, obj) => { return value ? value.length <= formFieldLength.Title : true }),
        MailSettingID: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "MailSettingID")),
    });

    function bindMailId() {
        const axiosConfig = {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        };
        axios
            .post(
                `${AppConfigData.APIBasePath}v1/mailsetting/getlist`,
                {
                    MailSettingID: "",
                },
                axiosConfig
            )
            .then((res) => {
                if (res.data.status_code === 0) {
                } else if (res.data.status_code === 1) {
                    setMailSetID(res.data.data);
                } else if (res.data.status_code === 2) {
                    localStorage.removeItem("token");
                    navigate("/login");
                } else {
                }
            })
            .catch((err) => { });
    }

    useEffect(() => {
        bindMailId();

        if (props.id > 0) {
            const axiosConfig = {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            };
            axios
                .post(
                    `${AppConfigData.APIBasePath}v1/mailtemplates/getdetailsbyid`,
                    {
                        id: props.id,
                        requestFrom: "Web",
                    },
                    axiosConfig
                )
                .then((res) => {
                    if (res.data.status_code === 0) {
                    } else if (res.data.status_code === 1) {

                        commonservices.CheckNullAndReturnBlankInObjectKey(
                            res.data.data.mailtemplates
                        );

                        formInitialSchema.MailTemplatesID =
                            res.data.data.mailtemplates.mailTemplatesID;
                        formInitialSchema.Title = res.data.data.mailtemplates.title;
                        formInitialSchema.Body = res.data.data.mailtemplates.body;
                        formInitialSchema.MailSettingID =
                            res.data.data.mailtemplates.mailSettingID;
                        setTAGName(res.data.data.mailtemplates.tagName);
                        setData(res.data.data.mailtemplates.body);

                        setIsGetDetailsDone(true);
                    } else if (res.data.status_code === 2) {
                    } else {
                    }
                })
                .catch((err) => { });
        }
    }, []);

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                className="right-modal modal-new modal-class"
                // centered
                scrollable={true}
                backdrop="static"
                keyboard={false}
            >
                <Formik
                    initialValues={formInitialSchema}
                    validationSchema={formValidation}
                    onSubmit={async (values) => {
                        setSubmitting(true);
                        const formData = new FormData();
                        formData.append("MailTemplatesID", values.MailTemplatesID);
                        formData.append("Body", data);
                        formData.append("MailSettingID", values.MailSettingID);
                        formData.append("Title", values.Title);
                        const axiosConfig = {
                            headers: {
                                "content-type": "multipart/form-data",
                                Authorization: "Bearer " + localStorage.getItem("token"),
                            },
                        };
                        await axios
                            .post(
                                `${AppConfigData.APIBasePath}v1/mailtemplates/save`,
                                formData,
                                axiosConfig
                            )

                            .then((res) => {
                                setSubmitting(false);
                                if (res.data.status_code === 0) {
                                    toast.error(res.data.message);
                                } else if (res.data.status_code === 1) {
                                    toast.success(res.data.message);
                                    props.bindList();
                                    props.onHide();
                                    //setTimeout(() => {
                                    //    props.onHide();
                                    //}, 1000);
                                } else if (res.data.status_code === 2) {
                                    toast.error(res.data.message);
                                } else {
                                    toast.error(res.data.message);
                                }
                            })
                            .catch((error) => { });
                    }}
                >
                    <Form>
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                Mail Templates Setting
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Container>
                                <Row>
                                    <Col xs={6} className="mb-3">
                                        <Field type="hidden" name="MailTemplatesID" />
                                        <div className="form-floating">
                                            <Field
                                                type="text"
                                                name="Title"
                                                className="form-control"
                                                placeholder={commonservices.LabelDisplayText(
                                                    "MailSubject"
                                                )}
                                                maxLength={formFieldLength.Title}
                                            />
                                            <label lbl-for="Title">
                                                {commonservices.LabelDisplayText("MailSubject")}
                                            </label>
                                            <div className="text-danger-error">
                                                <ErrorMessage name="Title" />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xs={6} className="from-bottom-margin">
                                        <div className="form-floating">
                                            <Field
                                                as="select"
                                                name="MailSettingID"
                                                className="form-control down-arrow-icon"
                                            >
                                                <option value="">
                                                    {commonservices.LabelDisplayText("Select")}
                                                </option>
                                                {MailSetID.length > 0 &&
                                                    MailSetID.map((item, index) => (
                                                        <option key={index} value={item.MailSettingID}>
                                                            {item.EmailID}
                                                        </option>
                                                    ))}
                                            </Field>
                                            <label lbl-for="MailSettingID">
                                                {commonservices.LabelDisplayText("MailSendFrom")}
                                            </label>
                                            <div className="text-danger-error">
                                                <ErrorMessage name="MailSettingID" />
                                            </div>
                                        </div>
                                    </Col>
                                    <div className="d-flex">
                                        <b>{TAGName}</b>
                                    </div>
                                    <Col xs={12} className="mb-3">
                                        <CKEditor
                                            editor={ClassicEditor}
                                            id="header"
                                            data={data}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                setData(data);
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Container>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={props.onHide} className="Cancel-btn" title="Cancel">
                                {commonservices.LabelDisplayText("Cancel")}
                            </Button>
                            <Button
                                className="btn btn-primary save-btn"
                                type="submit"
                                title="Submit"
                                disabled={Submitting}
                            >
                                {Submitting && (
                                    <>
                                        <Spinner animation="border" />
                                    </>
                                )}
                                {commonservices.LabelDisplayText("Save")}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Formik>
            </Modal>
        </>
    );
};

export default AddMailTemplatesModal;
