import React, { useEffect, useState } from "react";
import {
    Breadcrumb,
    Button,
    Col,
    Container,
    OverlayTrigger,
    Row,
    // Modal,
    Table,
    Tooltip,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AddProjectTermModal from "../../Modal/AddProjectTermModal";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import $ from "jquery";
// import { AppConfigData, commonservices } from "../../commonService";
import { commonservices, AppConfigData } from "../../Services/commonService";
import Loader, { LoaderType } from "../../Loader";
import { ReactComponent as Pencil } from "../../assets/Icons/pencil.svg";
import { ReactComponent as Trash } from "../../assets/Icons/trash.svg";
import { ReactComponent as DownloadIcon } from "../../assets/Icons/download-icon.svg";
import { ReactComponent as ExportIocn } from "../../assets/Icons/export-iocn.svg";
import { downloadExcel } from 'react-export-table-to-excel';
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import AddMailModal from "../../Modal/AddMailModal";

function Mail() {
    const [modalShow, setModalShow] = useState(false);
    const navigate = useNavigate();
    const [MailData, setMailData] = useState({
        Data: [], IsLoading: true
    });

    const [MAILID, setMAILID] = useState(0);
    const [searchobj, setSearchobj] = useState({
        emailID: "",
        smtpHost: "",
        port: "",
        searchText: "",
    });


    function bindList() {
        setMailData({
            Data: [], IsLoading: true
        })
        const axiosConfig = {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        };
        axios
            .post(
                `${AppConfigData.APIBasePath}v1/mailsetting/getlist`,
                searchobj,
                axiosConfig
            )
            .then((res) => {


                if (res.data.status_code === 0) {

                    setMailData({
                        Data: [],
                        IsLoading: false,
                    });

                } else if (res.data.status_code === 1) {
                    setMailData({
                        Data: res.data.data,
                        IsLoading: false,
                    });

                } else if (res.data.status_code === 2) {

                    localStorage.removeItem("token");
                    navigate("/login");

                }
                else {

                }

            })
            .catch((err) => {
                setMailData({ Data: [], IsLoading: true })
            });
    }

    function fnOpenProjectTermAddEditPopup(id) {
        setMAILID(id);
        setModalShow(true);
    }
    function deleteData(id) {
        let input = {
            id: id,
            requestFrom: "Web",
        };
        const axiosConfig = {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        };
        axios
            .post(
                `${AppConfigData.APIBasePath}v1/mailsetting/delete`,
                input,
                axiosConfig
            )
            .then((res) => {

                if (res.data.status_code === 0) {
                    toast.error(res.data.message);
                } else if (res.data.status_code === 1) {
                    toast.success(res.data.message);
                    bindList();

                } else if (res.data.status_code === 2) {
                    toast.error(res.data.message);
                }
                else {
                    toast.error(res.data.message);
                }
            })
            .catch((err) => {

            });
    }

    const header = ["EmailID", "SMTPHost", "Port"];
    function handleDownloadExcel() {
        let data = [];
        MailData.Data.forEach((item) => {
            let arrayToAppend = []
            arrayToAppend = [...arrayToAppend, item.EmailID, item.SMTPHost, item.Port]
            data = [...data, arrayToAppend];
        })
        downloadExcel({
            fileName: "mailsetting Details",
            sheet: "mailsetting Details",
            tablePayload: {
                header,
                body: data,
            },
        });
    }
    function PDFDownload() {
        let filename = "mailsetting Details";
        let data = [];
        MailData.Data.forEach((item) => {
            let arrayToAppend = []
            arrayToAppend = [...arrayToAppend, item.EmailID, item.SMTPHost, item.Port]
            data = [...data, arrayToAppend];
        })
        const doc = new jsPDF()
        autoTable(doc, {
            head: [header],
            body: data,
            headStyles: {
                textColor: '#000', halign: 'center', valign: 'middle', lineWidth: 0.1,
                lineColor: '#ABABAB', fillColor: "#fff", font: 'times', fontStyle: 'bold'
            },
            styles: { textColor: '#fff', halign: 'center', valign: 'middle', font: 'times', size: '10px' },
            bodyStyles: { textColor: '#000', halign: 'center', valign: 'middle', font: 'times', size: '10px' },
            margin: { top: 0, bottom: 0, left: 5, right: 5 },
            theme: 'grid',
        })
        doc.save(filename + '.pdf')
    }
    useEffect(() => {
        bindList();
    }, []);

    // For Datatable 
    $(document).ready(function () {
        $("table#ProjectTermtblData").DataTable({
            destroy: true,
            searching: false,
            dom: "ft<'tbl-info'<'length-info'li<'paggination'p>>>",
            aoColumnDefs: [
                {
                    bSortable: false,
                    aTargets: [-1] /* 1st one, start by the right */,
                },
            ],
        });
    });
    useEffect(() => {
        $(document).ready(function () {
            $("table#ProjectTermtblData")
                .DataTable({
                    destroy: true,
                    searching: false,
                    dom: "ft<'tbl-info'<'length-info'li<'paggination'p>>>",
                    aoColumnDefs: [
                        {
                            bSortable: false,
                            aTargets: [-1] /* 1st one, start by the right */,
                        },
                    ],
                })
                .destroy();
        });
    }, []);

    $(document).ready(function () {
        $("table#MailClData").DataTable({
            destroy: true,
            searching: false,
            dom: "ft<'tbl-info'<'length-info'li<'paggination'p>>>",
            aoColumnDefs: [
                {
                    bSortable: false,
                    aTargets: [-1] /* 1st one, start by the right */,
                },
            ],
        });
    });
    useEffect(() => {
        $(document).ready(function () {
            $("table#MailClData")
                .DataTable({
                    destroy: true,
                    searching: false,
                    dom: "ft<'tbl-info'<'length-info'li<'paggination'p>>>",
                    aoColumnDefs: [
                        {
                            bSortable: false,
                            aTargets: [-1] /* 1st one, start by the right */,
                        },
                    ],
                })
                .destroy();
        });
    }, []);

    return (
        <>
            <div className="table-section-col common-header-page empheadmain">
                <Row>
                    <Col lg={7} md={4}>
                        <div className="page-title-left">
                            Mail Setting
                        </div>
                    </Col>
                    <Col lg={5} md={8} className="mt-auto mb-auto">
                        <div className="d-flex align-items-center justify-content-end">
                            <div className="inner-search-bar inner-search-grey w-100 ">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="SearchAnything"
                                    onKeyUp={(e) => e.keyCode === 13 && bindList()}
                                    name="searchData"
                                    onChange={(e) =>
                                        setSearchobj({
                                            ...searchobj,
                                            ["searchText"]: e.target.value,
                                        })
                                    }
                                />
                                <Button
                                    variant="btn btn-icon-btn-lg search-button"
                                    title="Search"
                                    onClick={(e) => bindList()}
                                >
                                    <i className="fa-light fa-magnifying-glass"></i>
                                </Button>
                            </div>
                            {
                                commonservices.getLoginRoleData("MailSetting", "IsCreate") &&
                                <div className="project-add-icon">
                                    <Button className="project-btn" title="Search" onClick={(e) => fnOpenProjectTermAddEditPopup(0)}>
                                        <i className="fa-regular fa-plus"></i>
                                    </Button>
                                </div>
                            }
                            {
                                commonservices.getLoginRoleData("MailSetting", "IsDownload") &&
                                <div className="expor-download-button d-flex">
                                    <Button variant="project-btn ms-2" title="Export">
                                        <ExportIocn onClick={handleDownloadExcel} />
                                    </Button>
                                    <Button variant="project-btn pe-0 ps-1" title="Download">
                                        <DownloadIcon onClick={PDFDownload} />
                                    </Button>
                                </div>
                            }
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="vault-tab vault-page">
                <div className="table-col">
                    <div className="recent-task-section">
                        {
                            MailData.IsLoading ?
                                <>
                                    <Loader
                                        type={LoaderType.Table}
                                        count={5}
                                    />
                                </>
                                :
                                <div className="recent-task-item">
                                    <Table
                                        id="MailClData"
                                        className="table table-inner-section"
                                    >
                                        <thead>
                                            <tr>
                                                <th className="width-300px">
                                                    {commonservices.LabelDisplayText("Email")}
                                                </th>
                                                <th className="width-300px">
                                                    {commonservices.LabelDisplayText("SMTPHost")}
                                                </th>
                                                <th className="width-300px">
                                                    {commonservices.LabelDisplayText("Port")}
                                                </th>
                                                <th className="text-end width-100px">
                                                    {commonservices.LabelDisplayText("Action")}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="table-section-col">
                                            {MailData.Data.length > 0 &&
                                                MailData.Data.map((item, index) => (
                                                    <tr key={index}>
                                                        <td className="padding-top-bottom width-300px">
                                                            <div className="project-description">{item.EmailID}</div>
                                                        </td>
                                                        <td className="padding-top-bottom width-300px">
                                                            <div className="d-flex table-text-common">
                                                                {item.SMTPHost}
                                                            </div>
                                                        </td>
                                                        <td className="padding-top-bottom width-300px">
                                                            <div className="d-flex table-text-common">
                                                                {item.Port}
                                                            </div>
                                                        </td>
                                                        <td className="text-end padding-top-bottom width-100px">
                                                            <div className="d-flex justify-content-end">
                                                                {item.IsDefault === 0 &&
                                                                    <>
                                                                        {
                                                                        commonservices.getLoginRoleData("MailSetting", "IsUpdate") &&
                                                                            <Button
                                                                                className="icon-btn me-1 h-auto"
                                                                                title="Edit"
                                                                                onClick={(e) =>
                                                                                    fnOpenProjectTermAddEditPopup(item.MailSettingID)
                                                                                }
                                                                            >
                                                                                <Pencil />
                                                                            </Button>
                                                                        }
                                                                        {
                                                                        commonservices.getLoginRoleData("MailSetting", "IsDelete") &&
                                                                            <Button
                                                                                className="icon-btn h-auto w-auto"
                                                                                title="Delete"
                                                                                onClick={(e) => {
                                                                                    confirmAlert({
                                                                                        message: commonservices.LabelDisplayText(
                                                                                            "AreYouSureToDoDelete"
                                                                                        ),
                                                                                        buttons: [
                                                                                            {
                                                                                                label: commonservices.LabelDisplayText("Yes"),
                                                                                                onClick: () => deleteData(item.MailSettingID),
                                                                                            },
                                                                                            {
                                                                                                label: commonservices.LabelDisplayText("No"),
                                                                                            },
                                                                                        ],
                                                                                    });
                                                                                }}
                                                                            >
                                                                                <Trash />
                                                                            </Button>
                                                                        }

                                                                    </>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </Table>
                                </div>
                        }
                    </div>
                </div>
            </div>
            <Container className="pt-4">

                {modalShow && (
                    <AddMailModal
                        id={MAILID}
                        show={modalShow}
                        bindList={bindList}
                        onHide={() => setModalShow(false)}
                    />
                )}
            </Container>
        </>
    );
}

export default Mail;
