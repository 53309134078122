import axios from "axios";
import { useState, useEffect } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { default as React } from "react";
import { toast } from "react-toastify";
import * as yup from "yup";
import $ from "jquery";
import { commonservices, AppConfigData } from "../Services/commonService";
import { confirmAlert } from "react-confirm-alert";
import { Col, Modal, Button, Container, Row, Table } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { viewFilePopupService } from "../Services/viewFilePopup.service";
import { ReactComponent as Trash } from "../assets/Icons/trash.svg";
import { ReactComponent as EyeshowPassword } from "../assets/Icons/eyeshow-password.svg";
const AddClientModal = (props) => {
  const [IsValidationMessageSet, setIsValidationMessageSet] = useState("");
  const [projectItem, setProjectItem] = useState([]);
  const [lstDocuments, setlstDocuments] = useState([]);
  const [Submitting, setSubmitting] = useState(false);

  const formFieldLength = {
    Name: 225,
    Address: 500,
    Country: 67,
    EmailID: 167,
    ContactNo: 17,
    Details: 500,
  };

  const formInitialSchema = {
    ClientID: 0,
    Name: "",
    Address: "",
    Country: "",
    EmailID: "",
    ContactNo: "",
    Details: "",
  };

  const formValidation = yup.object().shape({
    Name: yup
      .string()
      .required(commonservices.MakeValidationMessage("required", "Name"))
      .test(
        "len",
        commonservices.MakeValidationMessage("maxlength", ""),
        (value, obj) => {
          return value ? value.length <= formFieldLength.Name : true;
        }
      ),
    Address: yup
      .string()
      .required(commonservices.MakeValidationMessage("required", "Address"))
      .test(
        "len",
        commonservices.MakeValidationMessage("maxlength", ""),
        (value, obj) => {
          return value ? value.length <= formFieldLength.Address : true;
        }
      ),
    Country: yup
      .string()
      .required(commonservices.MakeValidationMessage("required", "Country"))
      .test(
        "len",
        commonservices.MakeValidationMessage("maxlength", ""),
        (value, obj) => {
          return value ? value.length <= formFieldLength.Country : true;
        }
      ),
    EmailID: yup
      .string()
      .email()
      .required(commonservices.MakeValidationMessage("required", "EmailID"))
      .test(
        "len",
        commonservices.MakeValidationMessage("maxlength", ""),
        (value, obj) => {
          return value ? value.length <= formFieldLength.EmailID : true;
        }
      ),
    ContactNo: yup
      .string()
      .required(commonservices.MakeValidationMessage("required", "ContactNo"))
      .test(
        "len",
        commonservices.MakeValidationMessage("maxlength", ""),
        (value, obj) => {
          return value ? value.length <= formFieldLength.ContactNo : true;
        }
      ),
    Details: yup
      .string()
      .test(
        "len",
        commonservices.MakeValidationMessage("maxlength", ""),
        (value, obj) => {
          return value ? value.length <= formFieldLength.Details : true;
        }
      ),
  });

  useEffect(() => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(
        `${AppConfigData.APIBasePath}v1/projectterm/getlist`,
        {
          term: "",
          category: "Documents",
        },
        axiosConfig
      )
      .then((res) => {
        setProjectItem(res.data.data);
      })
      .catch((err) => {});

    if (props.id > 0) {
      axios
        .post(
          `${AppConfigData.APIBasePath}v1/client/getdetailsbyid`,
          {
            id: props.id,
            requestFrom: "Web",
          },
          axiosConfig
        )
        .then((res) => {
          commonservices.CheckNullAndReturnBlankInObjectKey(
            res.data.data.client
          );
          formInitialSchema.ClientID = res.data.data.client.ClientID;
          formInitialSchema.Name = res.data.data.client.Name;
          formInitialSchema.Address = res.data.data.client.Address;
          formInitialSchema.Country = res.data.data.client.Country;
          formInitialSchema.EmailID = res.data.data.client.EmailID;
          formInitialSchema.ContactNo = res.data.data.client.ContactNo;
          formInitialSchema.Details = res.data.data.client.Details;
          let lstDoc = [];
          for (let i = 0; i < res.data.data.documents.length; i++) {
            lstDoc.push({
              lstDocumentID: commonservices.createGuid(),
              DocumentID: res.data.data.documents[i].DocumentID,
              DocType: res.data.data.documents[i].DocType,
              DocName: res.data.data.documents[i].DocName,
              Details: res.data.data.documents[i].Details,
              File: res.data.data.documents[i].FilePath,
              DataItem: res.data.data.documents[i],
            });
          }
          setlstDocuments(lstDoc);
        })
        .catch((err) => {});
    }
  }, []);

  useEffect(() => {
    setIsValidationMessageSet(true);
  });

  function fnAddNewDoc() {
    const newList = lstDocuments.concat({
      lstDocumentID: commonservices.createGuid(),
      DocumentID: 0,
      DocType: "",
      DocName: "",
      Details: "",
      File: "",
      DataItem: {},
    });
    
    setlstDocuments(newList);
  }

  async function deleteDataFile(index, DocumentID) {
    if (DocumentID === 0) {
      let _doclst = [...lstDocuments];
      _doclst.splice(index, 1);
      setlstDocuments(_doclst);
    } else {
      const response = await commonservices.DeleteDocumentByID(
        DocumentID,
        0,
        ""
      );
      if (response.data.status_code === 1) {
        let data = [...lstDocuments];
        data.splice(index, 1);
        setlstDocuments(data);
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    }
  }
  $("input[type='file']").on("change", function () {
    if (this.files !== null && this.files.length !== 0) {
      $("[lbl-for='" + $(this).attr("name") + "']").text(this.files[0].name);
    } else {
      $("[lbl-for='" + $(this).attr("name") + "']").text(
        commonservices.LabelDisplayText("ChooseFile")
      );
    }
  });
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        // centered
        className="right-modal modal-new clint-modal"
        scrollable={true}
        backdrop="static"
        keyboard={false}
      >
        <Formik
          initialValues={formInitialSchema}
          validationSchema={formValidation}
          onSubmit={async (values) => {
            setSubmitting(true);
            const formData = new FormData();
            formData.append("ClientID", values.ClientID);
            formData.append("Name", values.Name);
            formData.append("Address", values.Address);
            formData.append("Country", values.Country);
            formData.append("EmailID", values.EmailID);
            formData.append("ContactNo", values.ContactNo);
            formData.append("Details", values.Details);

            let NoOfDoc = 0;
            lstDocuments.forEach((element) => {
              if (element.DocumentID === 0) {
                formData.append(
                  "lstDocuments[" + NoOfDoc + "].DocType",
                  values["DocType" + element.lstDocumentID]
                );
                formData.append(
                  "lstDocuments[" + NoOfDoc + "].DocName",
                  values["DocName" + element.lstDocumentID]
                );

                formData.append(
                  "lstDocuments[" + NoOfDoc + "].Details",
                  values["Details" + element.lstDocumentID]
                );

                formData.append(
                  "lstDocuments[" + NoOfDoc + "].File",
                  document.getElementsByName("File" + element.lstDocumentID)[0]
                    .files[0]
                );
                NoOfDoc = NoOfDoc + 1;
              }
            });

            const axiosConfig = {
              headers: {
                "content-type": "multipart/form-data",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            };
            const response = await axios
              .post(
                `${AppConfigData.APIBasePath}v1/client/save`,
                formData,
                axiosConfig
              )
              .then((response) => {
                setSubmitting(false);
                if (response.data.status_code === 0) {
                  toast.error(response.data.message);
                } else if (response.data.status_code === 1) {
                  toast.success(response.data.message);
                  //setTimeout(() => {
                  //    props.onHide();
                  //}, 1000);
                  props.bindList();
                  props.onHide();
                } else {
                  toast.error(response.data.message);
                }
              })
              .catch((error) => {});
          }}
        >
          <Form>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {commonservices.LabelDisplayText("AddClientDetails")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container>
                <Row>
                  <Col xs={6} className="mb-3">
                    <Field
                      type="hidden"
                      name="ClientID"
                      className="form-control"
                      placeholder="ClientID"
                    />
                    <div className="form-floating">
                      <Field
                        type="text"
                        name="Name"
                        className="form-control"
                        placeholder={commonservices.LabelDisplayText("Name")}
                        maxLength={formFieldLength.Name}
                      />
                      <label lbl-for="Name">
                        {commonservices.LabelDisplayText("Name")}
                      </label>
                      <div className="text-danger-error">
                        <ErrorMessage name="Name" />
                      </div>
                    </div>
                  </Col>
                  <Col xs={6} className="mb-3">
                    <div className="form-floating">
                      <Field
                        type="text"
                        name="EmailID"
                        className="form-control"
                        placeholder={commonservices.LabelDisplayText("Email")}
                        maxLength={formFieldLength.EmailID}
                      />
                      <label lbl-for="EmailID">
                        {commonservices.LabelDisplayText("Email")}
                      </label>
                      <div className="text-danger-error">
                        <ErrorMessage name="EmailID" />
                      </div>
                    </div>
                  </Col>
                  <Col xs={6} className="mb-3">
                    <div className="form-floating">
                      <Field
                        type="text"
                        name="Country"
                        className="form-control"
                        placeholder={commonservices.LabelDisplayText("Country")}
                        maxLength={formFieldLength.Country}
                      />
                      <label lbl-for="Country">
                        {commonservices.LabelDisplayText("Country")}
                      </label>
                      <div className="text-danger-error">
                        <ErrorMessage name="Country" />
                      </div>
                    </div>
                  </Col>
                  <Col xs={6} className="mb-3">
                    <div className="form-floating">
                      <Field
                        type="text"
                        name="ContactNo"
                        className="form-control"
                        placeholder={commonservices.LabelDisplayText(
                          "ContactNo"
                        )}
                        maxLength={formFieldLength.ContactNo}
                      />
                      <label lbl-for="ContactNo">
                        {commonservices.LabelDisplayText("ContactNo")}
                      </label>
                      <div className="text-danger-error">
                        <ErrorMessage name="ContactNo" />
                      </div>
                    </div>
                  </Col>
                  <Col xs={6} className="mb-3">
                    <div className="form-floating">
                      <Field
                        as="textarea"
                        name="Address"
                        className="form-control pt-2 pb-2 h-auto"
                        rows="5"
                        placeholder={commonservices.LabelDisplayText("Address")}
                        maxLength={formFieldLength.Address}
                      />
                      <label lbl-for="Address">
                        {commonservices.LabelDisplayText("Address")}
                      </label>
                      <div className="text-danger-error">
                        <ErrorMessage name="Address" />
                      </div>
                    </div>
                  </Col>
                  <Col xs={6} className="mb-3">
                    <div className="form-floating">
                      <Field
                        as="textarea"
                        name="Details"
                        className="form-control pt-2 pb-2 h-auto"
                        rows="5"
                        placeholder={commonservices.LabelDisplayText("Details")}
                        maxLength={formFieldLength.Details}
                      />
                      <label lbl-for="Details">
                        {commonservices.LabelDisplayText("Details")}
                      </label>
                      <div className="text-danger-error">
                        <ErrorMessage name="Details" />
                      </div>
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="DocumentsHeading">
                      <h3>{commonservices.LabelDisplayText("Documents")}</h3>
                      <div className="project-add-icon">
                        <Button
                          className="project-btn btn btn-primary"
                          onClick={(e) => fnAddNewDoc()}
                          title="Add"
                        >
                          <i className="fa-regular fa-plus"></i>
                        </Button>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="form-group">
                      <div className="table-responsive">
                        <Table>
                          <thead>
                            <tr>
                              <th className="text-nowrap">
                                {commonservices.LabelDisplayText("DocType")}
                              </th>
                              <th className="text-nowrap">
                                {commonservices.LabelDisplayText("DocName")}
                              </th>
                              <th>
                                {commonservices.LabelDisplayText("Details")}
                              </th>
                              <th>
                                <></>
                              </th>
                              <th className="text-end">
                                {commonservices.LabelDisplayText("Action")}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {lstDocuments.length > 0 &&
                              lstDocuments.map((item, index) =>
                                item.DocumentID === 0 ? (
                                  <tr key={index}>
                                    <td className="text-nowrap">
                                      <div className="form-group my-2">
                                        <div className="form-floating">
                                          <Field
                                            as="select"
                                            name={
                                              `DocType` + item.lstDocumentID
                                            }
                                            className="form-control"
                                          >
                                            <option value="">
                                              {commonservices.LabelDisplayText(
                                                "Select"
                                              )}{" "}
                                            </option>
                                            {projectItem.length > 0 &&
                                              projectItem.map(
                                                (item, index) =>
                                                  item.Category ===
                                                    "Documents" && (
                                                    <option
                                                      key={index}
                                                      value={item.Term}
                                                    >
                                                      {item.Term}
                                                    </option>
                                                  )
                                              )}
                                          </Field>
                                          <label
                                            lbl-for={
                                              `DocType` + item.lstDocumentID
                                            }
                                          >
                                            {commonservices.LabelDisplayText(
                                              "DocType"
                                            )}
                                          </label>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="text-nowrap">
                                      <div className="form-group my-2">
                                        <div className="form-floating">
                                          <Field
                                            type="text"
                                            name={
                                              `DocName` + item.lstDocumentID
                                            }
                                            className="form-control"
                                            placeholder={commonservices.LabelDisplayText(
                                              "DocName"
                                            )}
                                            maxLength={167}
                                          />
                                          <label
                                            lbl-for={
                                              `DocName` + item.lstDocumentID
                                            }
                                          >
                                            {commonservices.LabelDisplayText(
                                              "DocName"
                                            )}
                                          </label>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="text-nowrap">
                                      <div className="form-group my-2">
                                        <div className="form-floating">
                                          <Field
                                            type="text"
                                            name={
                                              `Details` + item.lstDocumentID
                                            }
                                            className="form-control"
                                            placeholder={commonservices.LabelDisplayText(
                                              "Details"
                                            )}
                                            maxLength={500}
                                          />
                                          <label
                                            lbl-for={
                                              `Details` + item.lstDocumentID
                                            }
                                          >
                                            {commonservices.LabelDisplayText(
                                              "Details"
                                            )}
                                          </label>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="text-nowrap">
                                      <div className="form-group my-2">
                                        <div className="form-floating browse-file">
                                          <div
                                            className="form-control"
                                            style={{
                                              maxWidth: "150px",
                                              overflow: "hidden",
                                            }}
                                          >
                                            <Field
                                              type="file"
                                              name={`File` + item.lstDocumentID}
                                              className="form-control"
                                            />
                                            <label
                                              lbl-for={
                                                `File` + item.lstDocumentID
                                              }
                                            >
                                              {commonservices.LabelDisplayText(
                                                "ChooseFile"
                                              )}
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="text-end text-nowrap">
                                      <Button
                                        className="icon-btn"
                                        title="Delete"
                                        onClick={(e) => {
                                          confirmAlert({
                                            message:
                                              commonservices.LabelDisplayText(
                                                "AreYouSureToDoDelete"
                                              ),
                                            buttons: [
                                              {
                                                label:
                                                  commonservices.LabelDisplayText(
                                                    "Yes"
                                                  ),
                                                onClick: () =>
                                                  deleteDataFile(
                                                    index,
                                                    item.DocumentID
                                                  ),
                                              },
                                              {
                                                label:
                                                  commonservices.LabelDisplayText(
                                                    "No"
                                                  ),
                                              },
                                            ],
                                          });
                                        }}
                                      >
                                        <Trash />
                                      </Button>
                                    </td>
                                  </tr>
                                ) : (
                                  <tr key={index}>
                                    <td className="text-nowrap">
                                      {item.DocType}
                                    </td>
                                    <td className="DocNameData text-nowrap">
                                      {item.DocName}
                                    </td>
                                    <td>
                                      <span className="Details">
                                        {item.Details}
                                      </span>
                                    </td>
                                    <td></td>
                                    <td className="text-end text-nowrap">
                                      <Button
                                        className="icon-btn"
                                        onClick={(e) => {
                                          viewFilePopupService.viewFilePopup({
                                            data: [item.DataItem],
                                          });
                                        }}
                                        title="Show"
                                      >
                                        <EyeshowPassword />
                                      </Button>
                                      <Button
                                        className="icon-btn"
                                        title="Delete"
                                        onClick={(e) => {
                                          confirmAlert({
                                            message:
                                              commonservices.LabelDisplayText(
                                                "AreYouSureToDoDelete"
                                              ),
                                            buttons: [
                                              {
                                                label:
                                                  commonservices.LabelDisplayText(
                                                    "Yes"
                                                  ),
                                                onClick: () =>
                                                  deleteDataFile(
                                                    index,
                                                    item.DocumentID
                                                  ),
                                              },
                                              {
                                                label:
                                                  commonservices.LabelDisplayText(
                                                    "No"
                                                  ),
                                              },
                                            ],
                                          });
                                        }}
                                      >
                                        <Trash />
                                      </Button>
                                    </td>
                                  </tr>
                                )
                              )}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row></Row>
              </Container>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={props.onHide} className="Cancel-btn" title="Cancel">
                {commonservices.LabelDisplayText("Cancel")}
              </Button>
              <Button
                className="btn btn-primary save-btn"
                type="submit"
                title="Submit"
                disabled={Submitting}
              >
                {Submitting && (
                  <>
                    <Spinner animation="border" />
                  </>
                )}
                {commonservices.LabelDisplayText("Save")}
              </Button>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </>
  );
};

export default AddClientModal;
