import axios from "axios";
import { default as React, useEffect, useState } from "react";
import { Col, Modal, Button, Container, Row, Table } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";
import { AppConfigData, commonservices } from "../Services/commonService";
import Loader, { LoaderType } from "../Loader";
import { confirmAlert } from "react-confirm-alert";
import UserPlaceholder from "../assets/Icons/placeholder.svg";
const ApproveRejectLeaveModal = (props) => {
  const [Loading, setLoading] = useState(false)
  let data = commonservices.getLoginUserData();
  let TodayDate = new Date();
  TodayDate = TodayDate.setDate(TodayDate.getDate() - 1);
  const [LeaveApplication, setLeaveApplication] = useState({
    Data: [],
    IsLoading: true,
  });

  const [LeaveTransaction, setLeaveTransaction] = useState([]);
  const [IsValidationMessageSet, setIsValidationMessageSet] = useState("");

  useEffect(() => {
    setIsValidationMessageSet(true);
  });
  useEffect(() => {
    if (props !== null && props !== undefined) {
      if (
        props.leaveApplicationID !== null &&
        props.leaveApplicationID !== undefined &&
        props.leaveApplicationID > 0
      ) {
        setLeaveApplication({
          Data: [],
          IsLoading: true,
        });

        const axiosConfig = {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        };

        axios
          .post(
            `${AppConfigData.APIBasePath}v1/leaveapplication/getdetailsbyid`,
            {
              id: props.leaveApplicationID,
              requestFrom: "Web",
            },
            axiosConfig
          )
          .then((res) => {
            if (res.data.status_code === 1) {
              setLeaveApplication({
                Data: res.data.data,
                IsLoading: false,
              });

              setLeaveTransaction(res.data.data.transaction);
            } else {
              toast.error(res.data.message);
              props.onHide();
            }
          })
          .catch((err) => {
            props.onHide();
          });
      } else {
        props.onHide();
      }
    } else {
      props.onHide();
    }
  }, []);

  function fnUserApproveReject(LeaveApplicationApprovalFromID, Status) {
   
    confirmAlert({
      message: commonservices.LabelDisplayText("AreYouSureTo" + Status),
      buttons: [
        {
          label: commonservices.LabelDisplayText("Yes"),
          onClick: () => {
            const axiosConfig = {
              headers: {
                "content-type": "multipart/form-data",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            };
            const formData = new FormData();
            formData.append(
              "LeaveApplicationApprovalFromID",
              LeaveApplicationApprovalFromID
            );
            formData.append("ApprovalStatus", Status);
            formData.append(
              "ApprovalNote",
              document.getElementsByName(
                "ApprovalNote" + LeaveApplicationApprovalFromID
              )[0].value
            );
            axios
              .post(
                `${AppConfigData.APIBasePath}v1/leaveapplication/userleaveapprovereject`,
                formData,
                axiosConfig
              )
              .then((response) => {
                if (response.data.status_code === 0) {
                  toast.error(response.data.message);
                } else if (response.data.status_code === 1) {
                  toast.success(response.data.message);
                  props.bindList();
                  props.onHide();
                } else {
                  toast.error(response.data.message);
                }
              })
              .catch((error) => {});
          },
        },
        {
          label: commonservices.LabelDisplayText("No"),
        },
      ],
    });
  }

  function fnFinalApproveReject(Status, LeaveApplicationID) {
    setLoading(true)
    let LeaveTransactionCount = 0;
    let PaidTransactionCount = 0;
    let NoDebitTransactionCount = 0;

    LeaveTransaction.map((item) => {
      PaidTransactionCount =
        parseFloat(PaidTransactionCount) + parseFloat(item.PaidNoOfDays);
      LeaveTransactionCount =
        parseFloat(LeaveTransactionCount) + parseFloat(item.LeaveNoOfDays);
      NoDebitTransactionCount =
        parseFloat(NoDebitTransactionCount) + parseFloat(item.DefaultNoOfDays);
    });

    NoDebitTransactionCount =
      parseFloat(NoDebitTransactionCount) -
      (parseFloat(PaidTransactionCount) + parseFloat(LeaveTransactionCount));
    if (NoDebitTransactionCount < 0) {
      NoDebitTransactionCount = 0;
    }
    confirmAlert({
      title:
        Status.toUpperCase() === "APPROVED"
          ? LeaveTransactionCount +
            " As Leave , " +
            PaidTransactionCount +
            " As Paid , " +
            NoDebitTransactionCount +
            " As No Debit"
          : "",
      message: commonservices.LabelDisplayText("AreYouSureTo" + Status),
      buttons: [
        {
          label: commonservices.LabelDisplayText("Yes"),
          onClick: () => {
            const axiosConfig = {
              headers: {
                "content-type": "multipart/form-data",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            };
            const formData = new FormData();
            formData.append("LeaveApplicationID", LeaveApplicationID);
            formData.append("ApplicationStatus", Status);
            formData.append(
              "StatusChangeNote",
              document.getElementsByName("ApprovalNote")[0].value
            );
            if (Status.toUpperCase() === "APPROVED") {
              let index = 0;
              LeaveTransaction.map((item) => {
                if (
                  parseFloat(item.LeaveNoOfDays) +
                    parseFloat(item.PaidNoOfDays) >
                  0
                ) {
                  formData.append(
                    "lstTransaction[" + index + "].transactionDate",
                    commonservices.getDateInDBFormat(item.TransactionDate)
                  );
                  formData.append(
                    "lstTransaction[" + index + "].LeaveNoOfDays",
                    item.LeaveNoOfDays
                  );
                  formData.append(
                    "lstTransaction[" + index + "].PaidNoOfDays",
                    item.PaidNoOfDays
                  );
                  index++;
                }
              });
            }
            axios
              .post(
                `${AppConfigData.APIBasePath}v1/leaveapplication/approvereject`,
                formData,
                axiosConfig
              )
              .then((response) => {
                setLoading(false)
                if (response.data.status_code === 0) {
                  toast.error(response.data.message);
                } else if (response.data.status_code === 1) {
                  toast.success(response.data.message);
                  props.bindList();
                  props.onHide();
                } else {
                  toast.error(response.data.message);
                }
              })
              .catch((error) => {});
          },
        },
        {
          label: commonservices.LabelDisplayText("No"),
        },
      ],
    });
  }

  function fnTransactionRadioChange(index, value) {
    let lst = [...LeaveTransaction];
    lst[index].IsPaidTransaction = value;
    setLeaveTransaction(lst);
  }

  function fnTransactionLeaveNoOfDaysChange(index, value, DefaultNoOfDays) {
    if (value !== null && value !== undefined && value !== "") {
      if (isNaN(value)) {
        value = DefaultNoOfDays;
      }
    } else {
      value = DefaultNoOfDays;
    }

    if (parseFloat(value) > DefaultNoOfDays || parseFloat(value) < 0) {
      value = DefaultNoOfDays;
    }

    let lst = [...LeaveTransaction];
    lst[index].LeaveNoOfDays = value;
    setLeaveTransaction(lst);
  }

  function fnTransactionPaidNoOfDaysChange(index, value, DefaultNoOfDays) {
    if (value !== null && value !== undefined && value !== "") {
      if (isNaN(value)) {
        value = DefaultNoOfDays;
      }
    } else {
      value = DefaultNoOfDays;
    }

    if (parseFloat(value) > DefaultNoOfDays || parseFloat(value) < 0) {
      value = DefaultNoOfDays;
    }

    let lst = [...LeaveTransaction];
    lst[index].PaidNoOfDays = value;
    setLeaveTransaction(lst);
  }

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="right-modal modal-new modal-apply-leave"
        scrollable={true}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Leave Request
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-class">
          {LeaveApplication.IsLoading ? (
            <Container>
              <Loader type={LoaderType.TaskDetails} count={1} />
            </Container>
          ) : (
            <Container
              className={
                "leave-application-" +
                LeaveApplication.Data.leaveapplication.ApplicationStatus.toLowerCase()
              }
            >
              <Row>
                <Col>
                  <div className="inner-header">
                    {commonservices.LabelDisplayText("Details")}
                    <span className="float-end">
                      Leave Request On{" "}
                      {commonservices.getDateTimeInFormat(
                        LeaveApplication.Data.leaveapplication.CreatedOn
                      )}
                    </span>
                  </div>
                  <div className="tab-inner-box">
                    <Row>
                      <Col className="mb-2 col-lg-12 col-md-12">
                        <Row>
                          <Col className="col-xl-2">
                            <div className="tab-inner-box-left">Subject</div>
                          </Col>
                          <Col className="col-xl-10">
                            <div className="tab-inner-box-right">
                              {LeaveApplication.Data.leaveapplication.Title}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mb-2 col-lg-6 col-md-12">
                        <Row>
                          <Col className="col-xl-4">
                            <div className="tab-inner-box-left">Name</div>
                          </Col>
                          <Col className="col-xl-8">
                            <div className="tab-inner-box-right">
                              <img
                                className="project-short-img rounded-circle"
                                width="30"
                                height="30"
                                alt={commonservices.getInitials(
                                  LeaveApplication.Data.leaveapplication
                                    .EmployeeName
                                )}                               
                                src={
                                  LeaveApplication.Data.leaveapplication
                                    .ProfilePic !==
                                    null &&
                                  LeaveApplication.Data.leaveapplication
                                    .ProfilePic !==
                                    ""
                                    ? LeaveApplication.Data.leaveapplication
                                    .ProfilePic
                                    : UserPlaceholder
                                }
                                // src={
                                //   LeaveApplication.Data.leaveapplication
                                //     .ProfilePic
                                // }
                              />{" "}
                              {
                                LeaveApplication.Data.leaveapplication
                                  .EmployeeName
                              }
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col className="mb-2 col-lg-6 col-md-12">
                        <Row>
                          <Col className="col-xl-4">
                            <div className="tab-inner-box-left">No Of Days</div>
                          </Col>
                          <Col className="col-xl-8">
                            <div className="tab-inner-box-right">
                              {LeaveApplication.Data.leaveapplication.NoOfDays}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col className="mb-2 col-lg-6 col-md-12">
                        <Row>
                          <Col className="col-xl-4">
                            <div className="tab-inner-box-left">Leave</div>
                          </Col>
                          <Col className="col-xl-8">
                            <div className="tab-inner-box-right">
                              {LeaveApplication.Data.leaveapplication.LeaveName}{" "}
                              (
                              {
                                LeaveApplication.Data.leaveapplication
                                  .TotalLeave
                              }
                              )
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col className="mb-2 col-lg-6 col-md-12">
                        <Row>
                          <Col className="col-xl-4">
                            <div className="tab-inner-box-left">Leave Type</div>
                          </Col>
                          <Col className="col-xl-8">
                            <div className="tab-inner-box-right">
                              {LeaveApplication.Data.leaveapplication.LeaveType}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col className="mb-2 col-lg-6 col-md-12">
                        <Row>
                          <Col className="col-xl-4">
                            <div className="tab-inner-box-left">Start Date</div>
                          </Col>
                          <Col className="col-xl-8">
                            <div className="tab-inner-box-right">
                              {commonservices.getDateInFormat(
                                LeaveApplication.Data.leaveapplication.StartDate
                              )}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col className="mb-2 col-lg-6 col-md-12">
                        <Row>
                          <Col className="col-xl-4">
                            <div className="tab-inner-box-left">End Date</div>
                          </Col>
                          <Col className="col-xl-8">
                            <div className="tab-inner-box-right">
                              {commonservices.getDateInFormat(
                                LeaveApplication.Data.leaveapplication.EndDate
                              )}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mb-2 col-lg-12 col-md-12">
                        <Row>
                          <Col className="col-xl-2">
                            <div className="tab-inner-box-left">Details</div>
                          </Col>
                          <Col className="col-xl-10">
                            {/* <div className="tab-inner-box-right">
                                                                {LeaveApplication.Data.leaveapplication.Details}
                                                            </div> */}
                            <div
                              className="tab-inner-box-right"
                              dangerouslySetInnerHTML={{
                                __html:
                                  LeaveApplication.Data.leaveapplication.Details.replaceAll(
                                    "\n",
                                    "<br/>"
                                  ),
                              }}
                            ></div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              {LeaveApplication.Data.approvalfrom.filter(
                (item) => item.IsAbleToFinalApproval === 0
              ).length > 0 && (
                <Row>
                  <Col>
                    <div className="inner-header">
                      {commonservices.LabelDisplayText("UserConfirmation")}
                    </div>
                    <div className="tab-inner-box">
                      <Table
                        responsive
                        className="table-inner-section mb-0 pb-0"
                      >
                        <tbody>
                          {LeaveApplication.Data.approvalfrom.map(
                            (item, index) =>
                              item.IsAbleToFinalApproval === 0 && (
                                <tr>
                                  <td className="text-nowrap padding-top-bottom">
                                    <div
                                      className="d-flex align-items-center"
                                      title={item.DisplayName}
                                    >
                                      <img
                                        className="project-short-img rounded-circle"
                                        width="30"
                                        height="30"
                                        alt={commonservices.getInitials(
                                          item.DisplayName
                                        )}
                                        // src={item.ProfilePic}
                                        src={
                                          item.ProfilePic !==
                                            null &&
                                          item.ProfilePic !==
                                            ""
                                            ? item.ProfilePic
                                            : UserPlaceholder
                                        }
                                      />{" "}
                                      <div className="project-description">
                                        {item.DisplayName}
                                      </div>
                                    </div>
                                  </td>

                                  <td className="padding-top-bottom">
                                    {data[0].UserID === item.ApprovalUserID &&
                                    LeaveApplication.Data.leaveapplication.ApplicationStatus.toUpperCase() ===
                                      "PENDING" ? (
                                      <>
                                        <textarea
                                          name={
                                            "ApprovalNote" +
                                            item.LeaveApplicationApprovalFromID
                                          }
                                          className="form-control pt-2 pb-2"
                                          placeholder="Approve/Reject Note Here"
                                          maxLength={500}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <div className="table-text-common">
                                          {item.ApprovalNote}
                                        </div>
                                      </>
                                    )}
                                  </td>
                                  <td className="text-end text-nowrap">
                                    {data[0].UserID === item.ApprovalUserID &&
                                    LeaveApplication.Data.leaveapplication.ApplicationStatus.toUpperCase() ===
                                      "PENDING" ? (
                                      <>
                                        <Button
                                          variant="success"
                                          title="Approve"
                                          size="sm"
                                          className="m-0"
                                          onClick={(e) =>
                                            fnUserApproveReject(
                                              item.LeaveApplicationApprovalFromID,
                                              "Approved"
                                            )
                                          }
                                        >
                                          Approve
                                        </Button>
                                        <Button
                                          variant="danger"
                                          title="Reject"
                                          size="sm"
                                          className="m-0 ms-2"
                                          onClick={(e) =>
                                            fnUserApproveReject(
                                              item.LeaveApplicationApprovalFromID,
                                              "Rejected"
                                            )
                                          }
                                        >
                                          Reject
                                        </Button>
                                      </>
                                    ) : (
                                      <>
                                        <div className="leave-status">
                                          <label
                                            className={`leave-label ${item.ApprovalStatus.toLowerCase()}`}
                                          >
                                            {item.ApprovalStatus}
                                          </label>
                                        </div>
                                      </>
                                    )}
                                  </td>
                                </tr>
                              )
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              )}
              {LeaveApplication.Data.leaveapplication.IsAbleToFinalApproval >
              0 ? (
                <>
                  <Row>
                    <Col>
                      <div className="inner-header">
                        {commonservices.LabelDisplayText("TransactionDetails")}
                      </div>
                      <div className="tab-inner-box">
                        <Table
                          responsive
                          className="table-inner-section mb-2 pb-0"
                        >
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>Day</th>
                              <th className="width-100px text-center">Leave</th>
                              <th className="width-100px text-center">Paid</th>
                              <th className="width-100px text-center">
                                Status
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {LeaveTransaction.map((item, index) => (
                              <tr>
                                <td
                                  className={`text-nowrap transaction-${item.DaysName.toLowerCase()}`}
                                >
                                  <div className="d-flex table-text-common">
                                    {commonservices.getDateInFormat(
                                      item.TransactionDate
                                    )}
                                  </div>
                                </td>
                                <td
                                  className={`text-nowrap transaction-${item.DaysName.toLowerCase()}`}
                                >
                                  <div className="d-flex table-text-common">
                                    {item.DaysName}
                                  </div>
                                </td>
                                <td
                                  className={`text-nowrap transaction-${item.DaysName.toLowerCase()}`}
                                >
                                  <input
                                    className={"form-control"}
                                    type="number"
                                    name={
                                      "LeaveNoOfDays_" +
                                      commonservices.getDateInDBFormat(
                                        item.TransactionDate
                                      )
                                    }
                                    value={item.LeaveNoOfDays}
                                    onChange={(e) =>
                                      fnTransactionLeaveNoOfDaysChange(
                                        index,
                                        e.target.value,
                                        item.DefaultNoOfDays
                                      )
                                    }
                                    style={{ height: "25px" }}
                                  />
                                </td>
                                <td
                                  className={`text-nowrap transaction-${item.DaysName.toLowerCase()}`}
                                >
                                  <input
                                    className={"form-control"}
                                    type="number"
                                    name={
                                      "NoOfDays_" +
                                      commonservices.getDateInDBFormat(
                                        item.TransactionDate
                                      )
                                    }
                                    value={item.PaidNoOfDays}
                                    onChange={(e) =>
                                      fnTransactionPaidNoOfDaysChange(
                                        index,
                                        e.target.value,
                                        item.DefaultNoOfDays
                                      )
                                    }
                                    style={{ height: "25px" }}
                                  />
                                </td>
                                <td
                                  className={`text-nowrap text-center transaction-${item.DaysName.toLowerCase()}`}
                                >
                                  {parseFloat(item.LeaveNoOfDays) +
                                    parseFloat(item.PaidNoOfDays) ===
                                  0
                                    ? "No Debit"
                                    : parseFloat(item.LeaveNoOfDays) > 0 &&
                                      parseFloat(item.PaidNoOfDays) > 0
                                    ? "Both"
                                    : parseFloat(item.LeaveNoOfDays) > 0
                                    ? "Leave"
                                    : parseFloat(item.PaidNoOfDays) > 0
                                    ? "Paid"
                                    : ""}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="form-floating from-bottom-margin">
                        <textarea
                          name="ApprovalNote"
                          className="form-control pt-2 pb-2"
                          placeholder="Approve/Reject Note Here"
                          defaultValue={
                            LeaveApplication.Data.leaveapplication
                              .StatusChangeNote
                          }
                          maxLength={500}
                        />
                        <label>Approve/Reject Note Here</label>
                      </div>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <div className="tab-inner-box">
                    <Row>
                      <Col className="mb-2 col-lg-12 col-md-12">
                        <Row>
                          <Col className="col-xl-4">
                            <div className="tab-inner-box-left">
                              Approve/Reject Note
                            </div>
                          </Col>
                          <Col className="col-xl-8">
                            <div className="tab-inner-box-right">
                              {
                                LeaveApplication.Data.leaveapplication
                                  .StatusChangeNote
                              }
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </>
              )}
            </Container>
          )}
        </Modal.Body>
        {!LeaveApplication.IsLoading &&
          LeaveApplication.Data.leaveapplication.ApplicationStatus.toUpperCase() ===
            "PENDING" &&
          LeaveApplication.Data.leaveapplication.IsAbleToFinalApproval > 0 && (
            <Modal.Footer>
              <Button
                variant="danger"
                title="Reject"
                className="m-0 ms-2"
                onClick={(e) =>
                  fnFinalApproveReject(
                    "Rejected",
                    LeaveApplication.Data.leaveapplication.LeaveApplicationID
                  )
                }
              >
                Reject
              </Button>
              <Button
                variant="success"
                title="Approve"
                className="m-0"
                onClick={(e) =>
                  fnFinalApproveReject(
                    "Approved",
                    LeaveApplication.Data.leaveapplication.LeaveApplicationID
                  )
                }
              >
               Approve
              </Button>
            </Modal.Footer>
          )}
      </Modal>
    </>
  );
};

export default ApproveRejectLeaveModal;
