import axios from "axios";
import React, { useState, useEffect } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import * as yup from "yup";
import { commonservices, AppConfigData } from "../Services/commonService";
import { Col, Modal, Button, Container, Row, Table } from "react-bootstrap";
import { useSearchParams, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import Spinner from "react-bootstrap/Spinner";

const ModalProjectShowPasswordVault = (props) => {
    const [isDisabled, setIsDisabled] = useState(false);
    // const [Submitting, setSubmitting] = useState(false);
    const [projectItem, setProjectItem] = useState([]);
    const [passData, setPassData] = useState({});
    const [PasswordID, setPasswordID] = useState([]);
    const [IsValidationMessageSet, setIsValidationMessageSet] = useState("");

    useEffect(() => {
        setIsValidationMessageSet(true);
    });
    var USERLIST = commonservices.getLoginUserData();
    let ASSOCIATIONTYPE = 0;
    let ASSOCIATIONID = 0;
    if (USERLIST.length !== 0) {
        ASSOCIATIONTYPE = USERLIST[0].AssociationType;
        ASSOCIATIONID = USERLIST[0].AssociationID;
    }

    const formInitialSchema = {
        passwordManagmentID: 0,
        userName: "",
        password: "",
    };

    const formValidation = yup.object().shape({
        userName: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "userName")),
        password: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "password")),
    });

    function getTypeterm() {
        const axiosConfig = {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        };
        axios
            .post(
                `${AppConfigData.APIBasePath}v1/projectterm/getlist`,
                {
                    term: "",
                    category: "PasswordManagmenType",
                },
                axiosConfig
            )
            .then((res) => {
                if (res.data.status_code === 0) {
                } else if (res.data.status_code === 1) {
                    setProjectItem(res.data.data);
                } else if (res.data.status_code === 2) {
                } else {
                }
            })
            .catch((err) => { });
    }

    useEffect(() => {
        getTypeterm();

        if (props) {
            setPasswordID(props.id.PasswordManagmentID);
        }
    }, []);

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                // centered
                className="right-modal modal-new"
                scrollable={true}
                backdrop="static"
                keyboard={false}
            >
                <Formik
                    initialValues={formInitialSchema}
                    validationSchema={formValidation}
                    onSubmit={async (values) => {
                        let input = {
                            passwordManagmentID: PasswordID,
                            userName: values.userName,
                            password: values.password,
                        };
                        const axiosConfig = {
                            headers: {
                                Accept: "application/json",
                                Authorization: "Bearer " + localStorage.getItem("token"),
                            },
                        };
                        const response = await axios
                            .post(
                                `${AppConfigData.APIBasePath}v1/passwordmanagment/seepassword`,
                                input,
                                axiosConfig
                            )
                            .then((res) => {
                                if (res.data.status_code === 0) {
                                    toast.error(res.data.message);
                                } else if (res.data.status_code === 1) {
                                    toast.success(res.data.message);
                                    setPassData(res.data.data.passwordData);
                                } else if (res.data.status_code === 2) {
                                    toast.error(res.data.message);
                                }
                            })
                            .catch((error) => { });
                    }}
                >
                    <Form>
                        <Modal.Body>
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    {commonservices.LabelDisplayText("ShowPassword")}
                                </Modal.Title>
                            </Modal.Header>
                            <Container>
                                <Row>
                                    <Field
                                        type="hidden"
                                        name="passwordManagmentID"
                                        className="form-control"
                                    />

                                    <Col xs={6} className="mb-3">
                                        <div className="form-floating">
                                            <Field
                                                type="text"
                                                name="userName"
                                                className="form-control"
                                                placeholder={commonservices.LabelDisplayText(
                                                    "YourUserName"
                                                )}
                                            />
                                            <label lbl-for="userName">
                                                {commonservices.LabelDisplayText("YourUserName")}
                                            </label>
                                            <div className="text-danger-error">
                                                <ErrorMessage name="userName" />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xs={6} className="mb-3">
                                        <div className="form-floating">
                                            <Field
                                                type="password"
                                                name="password"
                                                className="form-control"
                                                placeholder={commonservices.LabelDisplayText(
                                                    "YourPassword"
                                                )}
                                            />
                                            <label lbl-for="password">
                                                {commonservices.LabelDisplayText("YourPassword")}
                                            </label>
                                            <div className="text-danger-error">
                                                <ErrorMessage name="password" />
                                            </div>
                                        </div>
                                    </Col>
                                    <div className="table-responsive">
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th className="text-nowrap">
                                                        {commonservices.LabelDisplayText("AssociationType")}
                                                    </th>
                                                    <th className="text-nowrap">
                                                        {commonservices.LabelDisplayText("UserName")}
                                                    </th>
                                                    <th>{commonservices.LabelDisplayText("Password")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="text-nowrap">
                                                        {passData.associationType}
                                                    </td>
                                                    <td className="text-nowrap">{passData.userName}</td>
                                                    <td>{passData.password}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </Row>
                            </Container>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="Cancel-btn" onClick={props.onHide} title="Close">
                                {commonservices.LabelDisplayText("Close")}
                            </Button>
                            <Button className="btn btn-primary save-btn" type="submit" title="Submit">
                                {commonservices.LabelDisplayText("ShowPassword")}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Formik>
            </Modal>
        </>
    );
};

export default ModalProjectShowPasswordVault;
