import axios from "axios";
import React, { useState, useEffect } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import * as yup from "yup";
import { commonservices, AppConfigData } from "../Services/commonService";
import { Col, Modal, Button, Container, Row, Table } from "react-bootstrap";
import { useSearchParams, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import Spinner from "react-bootstrap/Spinner";

const AddProjectStory = (props) => {
    let param = useParams();

    const [projectStatus, setProjectStatus] = useState([]);
    const [Submitting, setSubmitting] = useState(false);
    const [IsValidationMessageSet, setIsValidationMessageSet] = useState("");

    useEffect(() => {
        setIsValidationMessageSet(true);
    });
    const formFieldLength = {
        title: 167,
    };

    const formInitialSchema = {
        projectStoryID: 0,
        projectID: 0,
        title: "",
        details: "",
        startDate: "",
        endDate: "",
        status: "",
        actualEndDate: "",
        isActive: true,
    };

    const formValidation = yup.object().shape({
        title: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "title"))
            .test('len', commonservices.MakeValidationMessage("maxlength", ""), (value, obj) => { return value ? value.length <= formFieldLength.title : true }),
        startDate: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "startDate")),
        endDate: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "endDate")),
        status: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "status")),
    });

    function getStatus() {
        const axiosConfig = {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        };
        axios
            .post(
                `${AppConfigData.APIBasePath}v1/projectterm/getlist`,
                {
                    term: "",
                    category: "ProjectStatus,technologies",
                },
                axiosConfig
            )
            .then((res) => {
                if (res.data.status_code === 0) {
                } else if (res.data.status_code === 1) {
                    setProjectStatus(res.data.data);
                } else if (res.data.status_code === 2) {
                } else {
                }
            })
            .catch((err) => { });
    }

    useEffect(() => {
        getStatus();

        if (props) {
            commonservices.CheckNullAndReturnBlankInObjectKey(props.id);
            formInitialSchema.projectStoryID = props.id.ProjectStoryID;
            formInitialSchema.projectID = props.id.ProjectID;
            formInitialSchema.title = props.id.Title;
            formInitialSchema.details = props.id.Details;
            formInitialSchema.startDate = commonservices.getDateInDBFormat(
                props.id.StartDate
            );
            formInitialSchema.endDate = commonservices.getDateInDBFormat(
                props.id.EndDate
            );
            formInitialSchema.status = props.id.Status;
            formInitialSchema.actualEndDate = commonservices.getDateInDBFormat(
                props.id.ActualEndDate
            );
            formInitialSchema.isActive = props.id.IsActive;
        }
    }, []);

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                className="right-modal modal-new modal-add-project-stroy"
                scrollable={true}
                backdrop="static"
                keyboard={false}
            >
                <Formik
                    initialValues={formInitialSchema}
                    validationSchema={formValidation}
                    onSubmit={async (values) => {
                        setSubmitting(true);

                        if (values.projectStoryID > 0) {
                            const formData = new FormData();
                            formData.append("ProjectStoryID", values.projectStoryID);
                            formData.append("ProjectID", values.projectID);
                            formData.append("Title", values.title);
                            formData.append("Details", values.details);
                            formData.append("StartDate", values.startDate);
                            formData.append("EndDate", values.endDate);
                            formData.append("Status", values.status);

                            if (
                                values.actualEndDate !== null &&
                                values.actualEndDate !== ""
                            ) {
                                formData.append("actualEndDate", values.actualEndDate);
                            }

                            const axiosConfig = {
                                headers: {
                                    "content-type": "multipart/form-data",
                                    Authorization: "Bearer " + localStorage.getItem("token"),
                                },
                            };
                            const response = await axios
                                .post(
                                    `${AppConfigData.APIBasePath}v1/projectstory/update`,
                                    formData,
                                    axiosConfig
                                )
                                .then((res) => {
                                    setSubmitting(false);
                                    if (res.data.status_code === 0) {
                                        toast.error(res.data.message);
                                    } else if (res.data.status_code === 1) {
                                        toast.success(res.data.message);
                                        //setTimeout(() => {
                                        //  props.onHide();
                                        //}, 1000);
                                        props.bindList();
                                        props.onHide();
                                    } else if (res.data.status_code === 2) {
                                        toast.error(res.data.message);
                                    } else {
                                        toast.error(res.data.message);
                                    }
                                })
                                .catch((error) => { });
                        } else {
                            let input = [
                                {
                                    projectStoryID: 0,
                                    projectID: parseInt(param.id),
                                    title: values.title,
                                    details: values.details,
                                    startDate: values.startDate,
                                    endDate: values.endDate,
                                    status: values.status,
                                    actualEndDate:
                                        values.actualEndDate !== "" ? values.actualEndDate : null,
                                    isActive: true,
                                },
                            ];

                            const axiosConfig = {
                                headers: {
                                    Accept: "application/json",
                                    Authorization: "Bearer " + localStorage.getItem("token"),
                                },
                            };
                            const response = await axios
                                .post(
                                    `${AppConfigData.APIBasePath}v1/projectstory/save`,
                                    input,
                                    axiosConfig
                                )
                                .then((res) => {
                                    if (res.data.status_code === 0) {
                                        toast.error(res.data.message);
                                    } else if (res.data.status_code === 1) {
                                        toast.success(res.data.message);
                                        setTimeout(() => {
                                            props.onHide();
                                        }, 1000);
                                    } else if (res.data.status_code === 2) {
                                        toast.error(res.data.message);
                                    } else {
                                        toast.error(res.data.message);
                                    }
                                    props.bindList();
                                })
                                .catch((error) => { });
                        }
                    }}
                >
                    <Form>
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                {commonservices.LabelDisplayText("AddProjectStory")}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Container>
                                <Row>
                                    <Field
                                        type="hidden"
                                        name="projectStoryID"
                                        className="form-control"
                                    />
                                    <Col xs={6} className="mb-3">
                                        <div className="form-group">
                                            <div className="form-floating ">
                                                <Field
                                                    type="text"
                                                    name="title"
                                                    className="form-control"
                                                    placeholder={commonservices.LabelDisplayText("Title")}
                                                    maxLength={formFieldLength.title}

                                                />
                                                <label lbl-for="title">
                                                    {commonservices.LabelDisplayText("Title")}
                                                </label>
                                                <div className="text-danger-error">
                                                    <ErrorMessage name="title" />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={6} className="mb-3">
                                        <div className="form-group">
                                            <div className="form-floating ">
                                                <Field
                                                    type="date"
                                                    name="startDate"
                                                    className="form-control calendar-icon"
                                                />
                                                <label lbl-for="startDate">
                                                    {commonservices.LabelDisplayText("StartDate")}
                                                </label>
                                                <div className="text-danger-error">
                                                    <ErrorMessage name="startDate" />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={6} className="mb-3">
                                        <div className="form-group">
                                            <div className="form-floating ">
                                                <Field
                                                    type="date"
                                                    name="endDate"
                                                    className="form-control calendar-icon"
                                                    placeholder="endDate"
                                                />
                                                <label lbl-for="endDate">
                                                    {commonservices.LabelDisplayText("EndDate")}
                                                </label>
                                                <div className="text-danger-error">
                                                    <ErrorMessage name="endDate" />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={6} className="mb-3">
                                        <div className="form-group">
                                            <div className="form-floating ">
                                                <Field
                                                    as="select"
                                                    name="status"
                                                    className="form-control down-arrow-icon"
                                                >
                                                    <option value="">
                                                        {commonservices.LabelDisplayText("Select")}
                                                    </option>
                                                    {projectStatus.length > 0 &&
                                                        projectStatus.map(
                                                            (item, index) =>
                                                                item.Category === "ProjectStatus" && (
                                                                    <option key={index} value={item.Term}>
                                                                        {item.Term}
                                                                    </option>
                                                                )
                                                        )}
                                                </Field>
                                                <label lbl-for="status">
                                                    {commonservices.LabelDisplayText("Status")}
                                                </label>
                                                <div className="text-danger-error">
                                                    <ErrorMessage name="status" />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xs={6} className="mb-3">
                                        <div className="form-group">
                                            <div className="form-floating ">
                                                <Field
                                                    type="date"
                                                    name="actualEndDate"
                                                    className="form-control calendar-icon"
                                                />
                                                <label lbl-for="actualEndDate">
                                                    {commonservices.LabelDisplayText("ActualEndDate")}
                                                </label>
                                                <div className="text-danger-error">
                                                    <ErrorMessage name="actualEndDate" />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="form-group">
                                            <div className="form-floating ">
                                                <Field
                                                    as="textarea"
                                                    name="details"
                                                    rows="5"
                                                    className="form-control"
                                                    placeholder={commonservices.LabelDisplayText(
                                                        "Details"
                                                    )}
                                                />
                                                <label lbl-for="details">
                                                    {commonservices.LabelDisplayText("Details")}
                                                </label>
                                                <div className="text-danger-error">
                                                    <ErrorMessage name="details" />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={props.onHide} className="Cancel-btn" title="Cancel">
                                {commonservices.LabelDisplayText("Cancel")}
                            </Button>
                            <Button
                                className="btn btn-primary save-btn"
                                type="submit"
                                title="Submit"
                                disabled={Submitting}
                            >
                                {Submitting && (
                                    <>
                                        <Spinner animation="border" />
                                    </>
                                )}
                                {commonservices.LabelDisplayText("Save")}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Formik>
            </Modal>
        </>
    );
};

export default AddProjectStory;
