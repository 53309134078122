import axios from "axios";
import React, { useState, useEffect } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import * as yup from "yup";
import { commonservices, AppConfigData } from "../Services/commonService";
import { Col, Modal, Button, Container, Row, Table } from "react-bootstrap";
import { useSearchParams, useNavigate, useParams } from "react-router-dom";

import Select from "react-select";
import Spinner from "react-bootstrap/Spinner";

const AddQualificationModal = (props) => {
    let param = useParams();
    const [Submitting, setSubmitting] = useState(false);
    const [IsGetDetailsDone, setIsGetDetailsDone] = useState(false);
    const [IsValidationMessageSet, setIsValidationMessageSet] = useState("");

    useEffect(() => {
        setIsValidationMessageSet(true);
    });
    const formFieldLength = {
        DegreeType: 167,
        Institute: 167,
        Duration: 67,
        Result: 67,
        MainSubject: 67,
        Details: 367,
    };

    const formInitialSchema = {
        EmpQualificationID: 0,
        DegreeType: "",
        Institute: "",
        Duration: "",
        Result: "",
        MainSubject: "",
        Details: "",
        EmployeeID: "",
    };

    const formValidation = yup.object().shape({
        DegreeType: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "DegreeType"))
            .test('len', commonservices.MakeValidationMessage("maxlength", ""), (value, obj) => { return value ? value.length <= formFieldLength.DegreeType : true }),
        Institute: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "Institute"))
            .test('len', commonservices.MakeValidationMessage("maxlength", ""), (value, obj) => { return value ? value.length <= formFieldLength.Institute : true }),
        Duration: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "Duration"))
            .test('len', commonservices.MakeValidationMessage("maxlength", ""), (value, obj) => { return value ? value.length <= formFieldLength.Duration : true }),
        Result: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "Result"))
            .test('len', commonservices.MakeValidationMessage("maxlength", ""), (value, obj) => { return value ? value.length <= formFieldLength.Result : true }),
        MainSubject: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "MainSubject"))
            .test('len', commonservices.MakeValidationMessage("maxlength", ""), (value, obj) => { return value ? value.length <= formFieldLength.MainSubject : true }),
        Details: yup
            .string()
            .test('len', commonservices.MakeValidationMessage("maxlength", ""), (value, obj) => { return value ? value.length <= formFieldLength.Details : true }),
    });

    useEffect(() => {
        if (props.id > 0) {
            const axiosConfig = {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            };
            axios
                .post(
                    `${AppConfigData.APIBasePath}v1/empqualification/getdetailsbyid`,
                    {
                        id: props.id,
                        requestFrom: "Web",
                    },
                    axiosConfig
                )
                .then((res) => {
                    if (res.data.status_code === 0) {
                        toast.error(res.data.message);
                    } else if (res.data.status_code === 1) {

                        commonservices.CheckNullAndReturnBlankInObjectKey(
                            res.data.data.empqualification
                        );

                        formInitialSchema.EmpQualificationID =
                            res.data.data.empqualification.empQualificationID;
                        formInitialSchema.DegreeType =
                            res.data.data.empqualification.degreeType;
                        formInitialSchema.Institute =
                            res.data.data.empqualification.institute;
                        formInitialSchema.Duration =
                            res.data.data.empqualification.duration;
                        formInitialSchema.Result = res.data.data.empqualification.result;
                        formInitialSchema.MainSubject =
                            res.data.data.empqualification.mainSubject;
                        formInitialSchema.Details = res.data.data.empqualification.details;
                        formInitialSchema.EmployeeID =
                            res.data.data.empqualification.employeeID;
                        setIsGetDetailsDone(true);
                    } else if (res.data.status_code === 2) {
                        toast.error(res.data.message);
                    } else {
                        toast.error(res.data.message);
                    }
                })
                .catch((err) => { });
        }
    }, []);

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                // centered
                className="right-modal modal-new"
                scrollable={true}
                backdrop="static"
                keyboard={false}
            >
                <Formik
                    initialValues={formInitialSchema}
                    validationSchema={formValidation}
                    onSubmit={async (values) => {
                        setSubmitting(true);
                        const formData = new FormData();
                        formData.append("EmpQualificationID", values.EmpQualificationID);
                        formData.append("DegreeType", values.DegreeType);
                        formData.append("Institute", values.Institute);
                        formData.append("Duration", values.Duration);
                        formData.append("Result", values.Result);
                        formData.append("MainSubject", values.MainSubject);
                        formData.append("Details", values.Details);
                        formData.append("EmployeeID", parseInt(param.id));

                        const axiosConfig = {
                            headers: {
                                "content-type": "multipart/form-data",
                                Authorization: "Bearer " + localStorage.getItem("token"),
                            },
                        };
                        const response = await axios
                            .post(
                                `${AppConfigData.APIBasePath}v1/empqualification/save`,
                                formData,
                                axiosConfig
                            )
                            .then((res) => {
                                setSubmitting(false);
                                if (res.data.status_code === 0) {
                                    toast.error(res.data.message);
                                } else if (res.data.status_code === 1) {
                                    toast.success(res.data.message);
                                    //setTimeout(() => {
                                    //  props.onHide()
                                    //}, 1000);
                                    props.bindList();
                                    props.onHide();
                                } else if (res.data.status_code === 2) {
                                    toast.error(res.data.message);
                                } else {
                                    toast.error(res.data.message);
                                }
                            })
                            .catch((error) => { });
                    }}
                >
                    <Form>
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                {commonservices.LabelDisplayText("AddQualificationDetail")}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Container>
                                <Row>
                                    <Field
                                        type="hidden"
                                        name="EmployeeID"
                                        className="form-control"
                                    />
                                    <Field
                                        type="hidden"
                                        name="EmpQualificationID"
                                        className="form-control"
                                    />

                                    <Col xs={6} lg={6} md={12} className="mb-3">
                                        <div className="form-floating">
                                            <Field
                                                type="text"
                                                name="DegreeType"
                                                className="form-control"
                                                placeholder={commonservices.LabelDisplayText(
                                                    "TypeOfDegree"
                                                )}
                                                maxLength={formFieldLength.DegreeType}
                                            />
                                            <label lbl-for="DegreeType">
                                                {commonservices.LabelDisplayText("TypeOfDegree")}
                                            </label>
                                            <div className="text-danger-error">
                                                <ErrorMessage name="DegreeType" />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xs={6} lg={6} md={12} className="mb-3">
                                        <div className="form-floating">
                                            <Field
                                                type="test"
                                                name="Institute"
                                                className="form-control"
                                                placeholder={commonservices.LabelDisplayText(
                                                    "Institute"
                                                )}
                                                maxLength={formFieldLength.Institute}
                                            />
                                            <label lbl-for="Institute">
                                                {commonservices.LabelDisplayText("Institute")}
                                            </label>
                                            <div className="text-danger-error">
                                                <ErrorMessage name="Institute" />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xs={6} lg={6} md={12} className="mb-3">
                                        <div className="form-floating">
                                            <Field
                                                type="text"
                                                className="form-control"
                                                name="Duration"
                                                placeholder={commonservices.LabelDisplayText(
                                                    "Duration"
                                                )}
                                                maxLength={formFieldLength.Duration}
                                            />
                                            <label lbl-for="Duration">
                                                {commonservices.LabelDisplayText("Duration")}
                                            </label>
                                            <div className="text-danger-error">
                                                <ErrorMessage name="Duration" />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xs={6} lg={6} md={12} className="mb-3">
                                        <div className="form-floating">
                                            <Field
                                                type="text"
                                                name="Result"
                                                className="form-control"
                                                placeholder={commonservices.LabelDisplayText("Result")}
                                                maxLength={formFieldLength.Result}

                                            />
                                            <label lbl-for="Result">{commonservices.LabelDisplayText("Result")}</label>
                                            <div className="text-danger-error">
                                                <ErrorMessage name="Result" />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xs={6} lg={6} md={12} className="mb-3">
                                        <div className="form-floating">
                                            <Field
                                                type="text"
                                                name="MainSubject"
                                                className="form-control"
                                                placeholder={commonservices.LabelDisplayText(
                                                    "MainSubject"
                                                )}
                                                maxLength={formFieldLength.MainSubject}
                                            />
                                            <label lbl-for="MainSubject">
                                                {commonservices.LabelDisplayText("MainSubject")}
                                            </label>
                                            <div className="text-danger-error">
                                                <ErrorMessage name="MainSubject" />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xs={12} className="mb-3">
                                        <div className="form-floating">
                                            <Field
                                                as="textarea"
                                                name="Details"
                                                className="form-control"
                                                placeholder={commonservices.LabelDisplayText("Details")}
                                                maxLength={formFieldLength.Details}

                                            />
                                            <label lbl-for="Details">
                                                {commonservices.LabelDisplayText("Details")}
                                            </label>
                                            <div className="text-danger-error">
                                                <ErrorMessage name="Details" />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={props.onHide} title="Cancel">
                                {commonservices.LabelDisplayText("Close")}
                            </Button>
                            <Button
                                className="btn btn-primary"
                                type="submit"
                                title="Submit"
                                disabled={Submitting}
                            >
                                {Submitting && (
                                    <>
                                        <Spinner animation="border" />
                                    </>
                                )}
                                {commonservices.LabelDisplayText("Save")}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Formik>
            </Modal>
        </>
    );
};

export default AddQualificationModal;
