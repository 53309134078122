import React, { useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { AppConfigData, commonservices } from "../../../Services/commonService";
import axios from "axios";
import { useEffect } from "react";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";

const UserWiseDailyReport = () => {
  const [Submitting, setSubmitting] = useState(false);
  const [optionUser, setoptionUser] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [isvalidUser, setisvalidUser] = useState(false);
  let initialFormInputs = {
    startDate: "",
    endDate: "",
    userSelected: "",
  };
  const [formInputs, setFormInputs] = useState(initialFormInputs);

  let initialFormInputEr = {
    startDate: "",
    endDate: "",
    userSelected: "",
  };

  const validation_msg = {
    startDate: "This field is required.",
    endDate: "This field is required.",
    userSelected: "This field is required.",
  };

  const [formInputsEr, setFormInputsEr] = useState(initialFormInputEr);

  function getTypeterm() {
    let useList = {
      displayName: "",
      userName: "",
      associationType: "",
      mobileNo: "",
      searchText: "",
      requestFrom: "web",
    };
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(
        `${AppConfigData.APIBasePath}v1/user/getuserlist`,
        useList,
        axiosConfig
      )
      .then((res) => {
        if (res.data.status_code === 0) {
        } else if (res.data.status_code === 1) {
          let optionUser = [];
          res.data.data.forEach((element) => {
            optionUser.push({
              label: element.DisplayName,
              value: element.UserID,
            });
          });
          setoptionUser(optionUser);
        } else if (res.data.status_code === 2) {
        } else {
        }
      })
      .catch((err) => {});
  }

  function fnSelectedOptionUser(value, event) {
    setFormInputs({
      ...formInputs,
      ["userSelected"]: value.length !== 0 ? value : "",
    });

    setSelectedUser(value);
    if (value !== null && value !== undefined && value !== "") {
      setisvalidUser(false);
    } else {
      setisvalidUser(true);
    }
  }

  useEffect(() => {
    getTypeterm();
  }, []);

  const validateForm = () => {
    let isValid = true;
    let error_json = {};

    Object.keys(formInputsEr).forEach((form_key) => {
      error_json = {
        ...error_json,
        [form_key]: "",
      };
    });

    Object.keys(formInputsEr).forEach((form_key) => {
      if (formInputs[form_key] === "" || !formInputs[form_key]) {
        error_json = {
          ...error_json,
          [form_key]: validation_msg[form_key],
        };
        isValid = false;
      }
    });
    setFormInputsEr(error_json);
    return isValid;
  };

  const handleGenerateReport = async () => {
    let isValidateForm = validateForm();

    if (isValidateForm) {
      let tempValue = [];
      selectedUser.map((itm) => {
        tempValue.push(itm.value);
      });

      let input = {
        userID: tempValue.toString(),
        startDate: commonservices.getDateInDBFormat(formInputs.startDate), // "2023-10-31T10:13:12.841Z",
        endDate: commonservices.getDateInDBFormat(formInputs.endDate), //"2023-10-31T10:13:12.841Z",
        projectID: 0,
        month: "",
        year: "",
      };

      const axiosConfig = {
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      setSubmitting(true);
      const response = await axios
        .post(
          `${AppConfigData.APIBasePath}v1/reports/userwiseDailyWorkingHours`,
          input,
          axiosConfig
        )
        .then((response) => {
          setSubmitting(false);
          if (response.data.status_code === 1) {
            window.open(
              AppConfigData.APIFileAccessURL + response.data.data,
              "_blank"
            );
            toast.success(response.data.message);
          } else if (response.data.status_code === 2) {
            setSubmitting(false);
            toast.error(response.data.message);
          } else if (response.data.status_code === 0) {
            setSubmitting(false);
            toast.error(response.data.message);
          } else {
            setSubmitting(false);
            toast.error(response.data.message);
          }
        })
        .catch((error) => {
          setSubmitting(false);
          toast.error("Something went wrong.");
        });
    }
  };

  return (
    <>
      <div className="table-section-col common-header-page empheadmain">
        <Row>
          <Col xl={12} lg={12} md={12}>
            <div className="page-title-left">
              User wise Daily Working Hours Report
              {/* {commonservices.LabelDisplayText("Employee")} */}
            </div>
          </Col>
          <Col xl={0} lg={0} md={0} className="mt-auto mb-auto"></Col>
        </Row>
        <Row>
          <Col xl={6} lg={8} md={4} className="mt-3 mb-auto">
            <div className="form-floating">
              <DatePicker
                className="form-control form-control-date datepicker-react"
                placeholderText="DD-MM-YYYY"
                // minDate={new Date("2010-01-01")}
                maxDate={new Date("9999-12-31")}
                dateFormat="dd/MM/yyyy"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                selected={formInputs.startDate && formInputs.startDate}
                onChange={(date) => {
                  setFormInputs({
                    ...formInputs,
                    ["startDate"]: date,
                  });
                }}
              />
            </div>
            <small style={{ color: "#dc3545" }}>
              {formInputsEr.startDate && formInputsEr.startDate}{" "}
            </small>
          </Col>
          <Col xl={6} lg={8} md={4} className="mt-3 mb-auto">
            <div className="form-floating">
              <DatePicker
                className="form-control form-control-date datepicker-react"
                placeholderText="DD-MM-YYYY"
                minDate={formInputs.startDate}
                maxDate={new Date("9999/12/31")}
                dateFormat="dd/MM/yyyy"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                selected={formInputs.endDate && formInputs.endDate}
                onChange={(date) => {
                  setFormInputs({
                    ...formInputs,
                    ["endDate"]: date,
                  });
                }}
              />
            </div>
            <small style={{ color: "#dc3545" }}>
              {formInputsEr.endDate && formInputsEr.endDate}{" "}
            </small>
          </Col>
        </Row>
        <Row>
          <Col xl={6} lg={8} md={4} className="mt-3 mb-auto">
            <div className="form-floating multipleselect-form-floating">
              <ReactMultiSelectCheckboxes
                width={"100%"}
                placeholderButtonLabel={"Select users"}
                isMulti
                name="ProjectUser"
                onChange={fnSelectedOptionUser}
                options={optionUser}
                value={selectedUser}
              />
              <label lbl-for="ProjectUser">
                {commonservices.LabelDisplayText("Select")}
              </label>
              {isvalidUser ? (
                <div className="text-danger-error">
                  {commonservices.LabelDisplayText("SelectTheUserName")}
                </div>
              ) : (
                <div className="text-danger-error"></div>
              )}
            </div>
            <small style={{ color: "#dc3545" }}>
              {formInputsEr.userSelected && formInputsEr.userSelected}{" "}
            </small>
          </Col>
        </Row>
        <Row>
          <Col xl={6} lg={8} md={4} className="mt-3 mb-auto">
            <Button
              variant="primary"
              className="m-0 me-2"
              type="submit"
              title="Next"
              disabled={Submitting}
              onClick={handleGenerateReport}
            >
              {Submitting && (
                <>
                  <Spinner animation="border" />
                </>
              )}
              Generate Report
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default UserWiseDailyReport;
