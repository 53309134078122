import React, { useEffect, useState } from "react";
import {
    Button,
    Col,
    Row,
    Table,
} from "react-bootstrap";
import axios from "axios";
import AddLeaveMasterModal from "../../Modal/AddLeaveMasterModal";
import LeaveCreditProcess from "../../Modal/LeaveCreditProcess";
import $ from "jquery";
import Loader, { LoaderType } from "../../Loader";
import { commonservices, AppConfigData } from "../../Services/commonService";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Pencil } from "../../assets/Icons/pencil.svg";
import { ReactComponent as CheckList } from "../../assets/Icons/task-list-check.svg";

function LeaveMaster() {

    const navigate = useNavigate();
    const [modalShow, setModalShow] = useState(false);
    const [leavemasterData, setleavemasterData] = useState({
        Data: [], IsLoading: true
    });
    const [leavemasterID, setleavemasterID] = useState(0);
    const [ModalShowLeaveCreditProcess, setModalShowLeaveCreditProcess] = useState(false);
    const [searchobj, setSearchobj] = useState({
        "leaveCode": "",
        "leaveName": "",
        "searchText": ""
    });

    function bindList() {

        setleavemasterData({
            Data: [],
            IsLoading: true
        })
        const axiosConfig = {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        };
        axios
            .post(
                `${AppConfigData.APIBasePath}v1/leavemaster/getlist`,
                searchobj,
                axiosConfig
            )
            .then((res) => {

                if (res.data.status_code === 0) {

                    setleavemasterData({ Data: [], IsLoading: false })

                } else if (res.data.status_code === 1) {

                    setleavemasterData({ Data: res.data.data, IsLoading: false })
                }
                else if (res.data.status_code === 2) {

                    commonservices.ClearLoginDetails();
                    navigate("/");
                }
                else {
                    setleavemasterData({ Data: [], IsLoading: false })
                }
            })
            .catch((err) => {
                setleavemasterData({ Data: [], IsLoading: false })
            });
    }
    function fnOpenleavemasterAddEditPopup(id) {
        setleavemasterID(id);
        setModalShow(true);
    }

    function fnViewTransactionDetails(id) {
        setleavemasterID(id);
        setModalShowLeaveCreditProcess(true);
    }

    useEffect(() => {
        bindList();
    }, []);

    // For Datatable
    $(document).ready(function () {
        $("table#leavemastertblData").DataTable({
            destroy: true,
            searching: false,
            dom: "ft<'tbl-info'<'length-info'li<'paggination'p>>>",
            aoColumnDefs: [
                {
                    bSortable: false,
                    aTargets: [-1] /* 1st one, start by the right */,
                },
            ],
        });
    });
    useEffect(() => {
        $(document).ready(function () {
            $("table#leavemastertblData")
                .DataTable({
                    destroy: true,
                    searching: false,
                    dom: "ft<'tbl-info'<'length-info'li<'paggination'p>>>",
                    aoColumnDefs: [
                        {
                            bSortable: false,
                            aTargets: [-1] /* 1st one, start by the right */,
                        },
                    ],
                })
                .destroy();
        });
    }, []);

    return (
        <>
            <div className="table-section-col common-header-page empheadmain">
                <Row>
                    <Col xl={4} lg={3} md={3}>
                        <div className="page-title-left">
                            Leave Master
                        </div>
                    </Col>
                    <Col xl={8} lg={9} md={9} className="mt-auto mb-auto">
                        <div className="d-flex align-items-center justify-content-end">
                            <div className="inner-search-bar inner-search-grey w-100">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="SearchAnything"
                                    onKeyUp={(e) => e.keyCode === 13 && bindList()}
                                    name="searchData"
                                    onChange={(e) =>
                                        setSearchobj({
                                            ...searchobj,
                                            ["searchText"]: e.target.value,
                                        })
                                    }
                                />
                                <Button
                                    variant="btn btn-icon-btn-lg search-button"
                                    onClick={(e) => bindList()}
                                    title="Search"
                                >
                                    <i className="fa-light fa-magnifying-glass"></i>
                                </Button>
                            </div>
                            {
                                commonservices.getLoginRoleData("LeaveMaster", "IsCreate") &&
                                <div className="project-add-icon">
                                    <Button className="project-btn" title="Add" onClick={(e) => fnOpenleavemasterAddEditPopup(0)}>
                                        <i className="fa-regular fa-plus"></i>
                                    </Button>
                                </div>
                            }

                        </div>
                    </Col>
                </Row>
            </div>
            <div className="vault-tab vault-page">
                <div className="table-col">
                    <div className="recent-task-section">
                        {
                            leavemasterData.IsLoading ?
                                <>
                                    <Loader
                                        type={LoaderType.Table}
                                        count={4}
                                    />
                                </>
                                :
                                <div className="table-scroll">
                                    <Table
                                        id="leavemastertblData"
                                        className="table table-inner-section"
                                    >
                                        <thead>
                                            <tr>
                                                <th className="width-100px">
                                                    Code
                                                </th>
                                                <th className="width-300px">
                                                    Name
                                                </th>
                                                <th className="width-200px">
                                                    Credit Rule
                                                </th>
                                                <th className="width-200px">
                                                    Carry Forward
                                                </th>
                                                <th className="width-100px">
                                                    Paid
                                                </th>
                                                <th className="text-end width-100px">
                                                    {commonservices.LabelDisplayText("Action")}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="table-section-col">
                                            {leavemasterData.Data.length > 0 &&
                                                leavemasterData.Data.map((item, index) => (
                                                    <tr key={index}>
                                                        <td className="width-100px " title={item.LeaveName}>
                                                            <span className={`rounded-circle leave-request-type  ${item.LeaveCode.toLowerCase()}`}>
                                                                {item.LeaveCode}
                                                            </span>
                                                        </td>
                                                        <td className="width-300px">
                                                            <div className="d-flex table-text-common">
                                                                {item.LeaveName}
                                                            </div>
                                                        </td>
                                                        <td className="width-200px">
                                                            <div className="d-flex table-text-common">
                                                                {item.IsCreditRuleApply ? item.IsCreditRuleApply > 0 ? "Yes" : "No" : "No"}
                                                            </div>
                                                        </td>
                                                        <td className="width-200px">
                                                            <div className="d-flex table-text-common">
                                                                {item.IsCarryForward ? item.IsCarryForward > 0 ? "Yes" : "No" : "No"}
                                                            </div>
                                                        </td>
                                                        <td className="width-100px">
                                                            <div className="d-flex table-text-common">
                                                                {item.IsPaidLeave ? item.IsPaidLeave > 0 ? "Yes" : "No" : "No"}
                                                            </div>
                                                        </td>
                                                        <td className="text-end padding-top-bottom width-100px">
                                                            <div className="d-flex justify-content-end">
                                                                {
                                                                    commonservices.getLoginRoleData("LeaveMaster", "IsUpdate") &&
                                                                    <Button
                                                                        className="icon-btn me-1 h-auto"
                                                                        onClick={(e) =>
                                                                            fnOpenleavemasterAddEditPopup(item.LeaveMasterID)
                                                                        }
                                                                        title="Edit"
                                                                    >
                                                                        <Pencil />
                                                                    </Button>
                                                                }
                                                                {
                                                                    commonservices.getLoginRoleData("LeaveMaster", "IsDelete") &&
                                                                    <Button
                                                                        title="Go For Manual Transaction"
                                                                        className="icon-btn h-auto w-auto"
                                                                        onClick={(e) => { fnViewTransactionDetails(item.LeaveMasterID)}}
                                                                    >
                                                                        <CheckList />
                                                                    </Button>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </Table>
                                </div>
                        }
                    </div>
                </div>
            </div>

            {modalShow && (
                <AddLeaveMasterModal
                    id={leavemasterID}
                    show={modalShow}
                    bindList={bindList}
                    onHide={() => setModalShow(false)}
                />
            )}

            {ModalShowLeaveCreditProcess && (
                <LeaveCreditProcess
                    id={leavemasterID}
                    show={ModalShowLeaveCreditProcess}
                    onHide={() => setModalShowLeaveCreditProcess(false)}
                />
            )}
        </>
    );
}

export default LeaveMaster;
