import axios from "axios";
import { useState, useEffect } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { default as React } from "react";
import { toast } from "react-toastify";
import * as yup from "yup";
import { commonservices, AppConfigData } from "../Services/commonService";
import { Col, Modal, Button, Container, Row, Table } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import $ from "jquery";
const AddMailModal = (props) => {
    const [IsGetDetailsDone, setIsGetDetailsDone] = useState(false);
    const [Submitting, setSubmitting] = useState(false);
    const [IsValidationMessageSet, setIsValidationMessageSet] = useState("");

    useEffect(() => {
        setIsValidationMessageSet(true);
    });

    const formFieldLength = {
        EmailID: 267,
        Password: 267,
        Confirm_password: 267,
        SMTPHost: 267,
        Port: 8,
    };

    const formInitialSchema = {
        MailSettingID: 0,
        EmailID: "",
        Password: "",
        Confirm_password: "",
        SMTPHost: "",
        Port: "",
    };

    const formValidation = yup.object().shape({
        EmailID: yup
            .string()
            .email(commonservices.MakeValidationMessage("email", "EmailID"))
            .required(commonservices.MakeValidationMessage("required", "EmailID"))
            .test('len', commonservices.MakeValidationMessage("maxlength", ""), (value, obj) => { return value ? value.length <= formFieldLength.EmailID : true }),
        Password: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "Password"))
            .test('len', commonservices.MakeValidationMessage("maxlength", ""), (value, obj) => { return value ? value.length <= formFieldLength.Password : true }),
        Confirm_password: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "Confirm_password"))
            .when("Password", {
                is: (val) => (val && val.length > 0 ? true : false),
                then: yup
                    .string()
                    .oneOf(
                        [yup.ref("Password")],
                        commonservices.MakeValidationMessage("custom", "Confirm_password", commonservices.CustomValidationMessage("ValidationField1MustBeSameField1").replace("{0}", $("[lbl-for='Password']").text().trim()).replace("{1}", $("[lbl-for='Confirm_password']").text().trim()))
                    ),
            })
            .test('len', commonservices.MakeValidationMessage("maxlength", ""), (value, obj) => { return value ? value.length <= formFieldLength.Confirm_password : true }),
        SMTPHost: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "SMTPHost"))
            .test('len', commonservices.MakeValidationMessage("maxlength", ""), (value, obj) => { return value ? value.length <= formFieldLength.SMTPHost : true }),
        Port: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "Port"))
            .test('len', commonservices.MakeValidationMessage("maxlength", ""), (value, obj) => { return value ? value.length <= formFieldLength.Port : true }),
    });

    useEffect(() => {
        const axiosConfig = {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        };

        if (props.id > 0) {
            axios
                .post(
                    `${AppConfigData.APIBasePath}v1/mailsetting/getdetailsbyid`,
                    {
                        id: props.id,
                        requestFrom: "Web",
                    },
                    axiosConfig
                )
                .then((res) => {
                    if (res.data.status_code === 0) {
                    } else if (res.data.status_code === 1) {

                        commonservices.CheckNullAndReturnBlankInObjectKey(
                            res.data.data.mailsetting
                        );

                        formInitialSchema.MailSettingID =
                            res.data.data.mailsetting.mailSettingID;
                        formInitialSchema.EmailID = res.data.data.mailsetting.emailID;
                        //formInitialSchema.Password = res.data.data.mailsetting.password;
                        formInitialSchema.SMTPHost = res.data.data.mailsetting.smtpHost;
                        formInitialSchema.Port = res.data.data.mailsetting.port;

                        setIsGetDetailsDone(true);
                    } else if (res.data.status_code === 2) {
                    } else {
                    }
                })
                .catch((err) => { });
        }
        else {
            setIsGetDetailsDone(true);
        }
    }, []);

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                // centered
                className="right-modal modal-new project-term-modal"
                scrollable={true}
                backdrop="static"
                keyboard={false}
            >
                <Formik
                    id="frmMailModel"
                    initialValues={formInitialSchema}
                    validationSchema={formValidation}
                    validateOnBlur={false}
                    onSubmit={async (values) => {
                        setSubmitting(true);
                        const formData = new FormData();
                        formData.append("MailSettingID", values.MailSettingID);
                        formData.append("EmailID", values.EmailID);
                        formData.append("Password", values.Password);
                        formData.append("SMTPHost", values.SMTPHost);
                        formData.append("Port", values.Port);
                        const axiosConfig = {
                            headers: {
                                "content-type": "multipart/form-data",
                                Authorization: "Bearer " + localStorage.getItem("token"),
                            },
                        };
                        await axios
                            .post(
                                `${AppConfigData.APIBasePath}v1/mailsetting/save`,
                                formData,
                                axiosConfig
                            )

                            .then((res) => {
                                setSubmitting(false);
                                if (res.data.status_code === 0) {
                                    toast.error(res.data.message);
                                } else if (res.data.status_code === 1) {
                                    toast.success(res.data.message);
                                    props.bindList();
                                    props.onHide();
                                    //setTimeout(() => {
                                    //    props.onHide();
                                    //}, 1000);
                                } else if (res.data.status_code === 2) {
                                    toast.error(res.data.message);
                                } else {
                                    toast.error(res.data.message);
                                }
                            })
                            .catch((error) => { });
                    }}
                >
                    <Form>
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                Mail Setting
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Container>
                                <Row>
                                    <Col xs={12} className="mb-3">
                                        <Field type="hidden" name="MailSettingID" />
                                        <div className="form-floating">
                                            <Field
                                                type="text"
                                                name="EmailID"
                                                className="form-control"
                                                placeholder={commonservices.LabelDisplayText("Email")}
                                                maxLength={formFieldLength.EmailID}
                                            />
                                            <label lbl-for="EmailID">{commonservices.LabelDisplayText("Email")}</label>
                                            <div className="text-danger-error">
                                                <ErrorMessage name="EmailID" />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xs={12} className="mb-3">
                                        <div className="form-floating">
                                            <Field
                                                type="password"
                                                name="Password"
                                                className="form-control"
                                                placeholder={commonservices.LabelDisplayText(
                                                    "Password"
                                                )}
                                                maxLength={formFieldLength.Password}
                                            />
                                            <label lbl-for="Password">
                                                {commonservices.LabelDisplayText("Password")}
                                            </label>
                                            <div className="text-danger-error">
                                                <ErrorMessage name="Password" />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xs={12} className="mb-3">
                                        <div className="form-floating">
                                            <Field
                                                type="password"
                                                name="Confirm_password"
                                                className="form-control"
                                                placeholder={commonservices.LabelDisplayText(
                                                    "ConfirmYourPassword"
                                                )}
                                                maxLength={formFieldLength.Confirm_password}

                                            />
                                            <label lbl-for="Confirm_password">
                                                {commonservices.LabelDisplayText("ConfirmYourPassword")}
                                            </label>
                                            <div className="text-danger-error">
                                                <ErrorMessage name="Confirm_password" />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xs={12} className="mb-3">
                                        <div className="form-floating">
                                            <Field
                                                type="text"
                                                name="SMTPHost"
                                                className="form-control"
                                                placeholder={commonservices.LabelDisplayText(
                                                    "SMTPHost"
                                                )}
                                                maxLength={formFieldLength.SMTPHost}
                                            />
                                            <label lbl-for="SMTPHost">
                                                {commonservices.LabelDisplayText("SMTPHost")}
                                            </label>
                                            <div className="text-danger-error">
                                                <ErrorMessage name="SMTPHost" />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xs={12} className="mb-3">
                                        <div className="form-floating">
                                            <Field
                                                type="text"
                                                name="Port"
                                                className="form-control"
                                                placeholder={commonservices.LabelDisplayText("Port")}
                                                maxLength={formFieldLength.Port}

                                            />
                                            <label lbl-for="Port">{commonservices.LabelDisplayText("Port")}</label>
                                            <div className="text-danger-error">
                                                <ErrorMessage name="Port" />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={props.onHide} className="Cancel-btn" title="Cancel">
                                {commonservices.LabelDisplayText("Cancel")}
                            </Button>
                            <Button
                                className="btn btn-primary save-btn"
                                type="submit"
                                title="Submit"
                                disabled={Submitting}
                            >
                                {Submitting && (
                                    <>
                                        <Spinner animation="border" />
                                    </>
                                )}
                                {commonservices.LabelDisplayText("Save")}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Formik>
            </Modal>
        </>
    );
};

export default AddMailModal;
