import axios from "axios";
import React, { useState, useEffect } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import * as yup from "yup";
import { commonservices, AppConfigData } from "../Services/commonService";
import { Col, Modal, Button, Container, Row, Table } from "react-bootstrap";
import { useSearchParams, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import Spinner from "react-bootstrap/Spinner";
import $ from "jquery";

const AddVault = (props) => {
    let param = useParams();
    const [isDisabled, setIsDisabled] = useState(false);
    const [Submitting, setSubmitting] = useState(false);
    const [projectItem, setProjectItem] = useState([]);
    const [IsValidationMessageSet, setIsValidationMessageSet] = useState("");

    useEffect(() => {
        setIsValidationMessageSet(true);
    });
    const formFieldLength = {
        userName: 167,
        password: 167,
        confirm_password: 167,
        details: 500,
    };

    const formInitialSchema = {
        passwordManagmentID: 0,
        userName: "",
        password: "",
        confirm_password: "",
        details: "",
        typeTerm: "",
        associationID: "",
        associationType: "",
        isActive: true,
    };

    const formValidation = yup.object().shape({
        userName: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "userName"))
            .test('len', commonservices.MakeValidationMessage("maxlength", ""), (value, obj) => { return value ? value.length <= formFieldLength.userName : true }),
        typeTerm: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "typeTerm")),
        password: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "password"))
            .test('len', commonservices.MakeValidationMessage("maxlength", ""), (value, obj) => { return value ? value.length <= formFieldLength.password : true }),
        confirm_password: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "confirm_password"))
            .when("password", {
                is: (val) => (val && val.length > 0 ? true : false),
                then: yup
                    .string()
                    .oneOf(
                        [yup.ref("password")],
                        commonservices.MakeValidationMessage("custom", "confirm_password", commonservices.CustomValidationMessage("ValidationField1MustBeSameField1").replace("{0}", $("[lbl-for='password']").text().trim()).replace("{1}", $("[lbl-for='confirm_password']").text().trim()))
                    ),
            })
            .test('len', commonservices.MakeValidationMessage("maxlength", ""), (value, obj) => { return value ? value.length <= formFieldLength.confirm_password : true }),
        details: yup
            .string()
            .test('len', commonservices.MakeValidationMessage("maxlength", ""), (value, obj) => { return value ? value.length <= formFieldLength.details : true }),
    });

    function getTypeterm() {
        const axiosConfig = {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        };
        axios
            .post(
                `${AppConfigData.APIBasePath}v1/projectterm/getlist`,
                {
                    term: "",
                    category: "PasswordManagmenType",
                },
                axiosConfig
            )
            .then((res) => {
                if (res.data.status_code === 0) {
                } else if (res.data.status_code === 1) {
                    setProjectItem(res.data.data);
                } else if (res.data.status_code === 2) {
                } else {
                }
            })
            .catch((err) => { });
    }

    useEffect(() => {
        getTypeterm();

        if (props) {
            commonservices.CheckNullAndReturnBlankInObjectKey(
                props.id
            );
            formInitialSchema.passwordManagmentID = props.id.PasswordManagmentID;
            formInitialSchema.userName = props.id.UserName;
            formInitialSchema.password = props.id.Password;
            formInitialSchema.details = props.id.Details;
            formInitialSchema.typeTerm = props.id.TypeTerm;
            formInitialSchema.associationID = props.id.AssociationID;
            formInitialSchema.associationType = props.id.AssociationType;
            formInitialSchema.isActive = props.id.IsActive;

            if (props.id.PasswordManagmentID > 0) {
                setIsDisabled(true);
            }
        }
    }, []);

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                className="right-modal modal-new"
                // centered
                scrollable={true}
                backdrop="static"
                keyboard={false}
            >
                <Formik
                    initialValues={formInitialSchema}
                    validationSchema={formValidation}
                    onSubmit={async (values) => {
                        // setSubmitting(true);

                        if (values.passwordManagmentID > 0) {
                            setSubmitting(true);

                            const formData = new FormData();
                            formData.append(
                                "PasswordManagmentID",
                                values.passwordManagmentID
                            );
                            formData.append("UserName", values.userName);
                            formData.append("Password", values.password);
                            formData.append("Details", values.details);

                            const axiosConfig = {
                                headers: {
                                    "content-type": "multipart/form-data",
                                    Authorization: "Bearer " + localStorage.getItem("token"),
                                },
                            };
                            const response = await axios
                                .post(
                                    `${AppConfigData.APIBasePath}v1/passwordmanagment/update`,
                                    formData,
                                    axiosConfig
                                )
                                .then((res) => {
                                    setSubmitting(false);
                                    if (res.data.status_code === 0) {
                                        toast.error(response.data.message);
                                    } else if (res.data.status_code === 1) {
                                        toast.success(response.data.message);
                                        //setTimeout(() => {
                                        //  props.onHide()
                                        //}, 1000);

                                        props.bindList();
                                        props.onHide();
                                    } else if (res.data.status_code === 2) {
                                        toast.error(response.data.message);
                                    } else {
                                        toast.error(response.data.message);
                                    }
                                })
                                .catch((error) => { });
                        } else {
                            setSubmitting(true);
                            let input = [
                                {
                                    passwordManagmentID: 0,
                                    userName: values.userName,
                                    password: values.password,
                                    details: values.details,
                                    typeTerm: values.typeTerm,
                                    associationID: parseInt(param.id),
                                    associationType: "Project",
                                    isActive: true,
                                },
                            ];
                            const axiosConfig = {
                                headers: {
                                    Accept: "application/json",
                                    Authorization: "Bearer " + localStorage.getItem("token"),
                                },
                            };
                            const response = await axios
                                .post(
                                    `${AppConfigData.APIBasePath}v1/passwordmanagment/save`,
                                    input,
                                    axiosConfig
                                )
                                .then((res) => {
                                    if (res.data.status_code === 0) {
                                        toast.error(res.data.message);
                                    } else if (res.data.status_code === 1) {
                                        toast.success(res.data.message);

                                        setTimeout(() => {
                                            props.onHide();
                                        }, 1000);

                                        props.bindList();
                                    } else if (res.data.status_code === 2) {
                                        toast.error(res.data.message);
                                    } else {
                                        toast.error(res.data.message);
                                    }
                                })
                                .catch((error) => { });
                        }
                    }}
                >
                    <Form>
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                {commonservices.LabelDisplayText("AddProjectVault")}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Container>
                                <Row>
                                    <Field
                                        type="hidden"
                                        name="passwordManagmentID"
                                        className="form-control"
                                    />
                                    <Col xs={6} className="mb-3">
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Field
                                                    disabled={isDisabled}
                                                    as="select"
                                                    name="typeTerm"
                                                    className="form-control down-arrow-icon"
                                                >
                                                    <option value="">
                                                        {commonservices.LabelDisplayText("Select")}
                                                    </option>
                                                    {projectItem.length > 0 &&
                                                        projectItem.map(
                                                            (item, index) =>
                                                                item.Category === "PasswordManagmenType" && (
                                                                    <option key={index} value={item.Term}>
                                                                        {item.Term}
                                                                    </option>
                                                                )
                                                        )}
                                                </Field>
                                                <label lbl-for="typeTerm">
                                                    {commonservices.LabelDisplayText("TypeTerm")}
                                                </label>
                                                <div className="text-danger-error">
                                                    <ErrorMessage name="typeTerm" />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xs={6} className="mb-3">
                                        <div className="form-floating">
                                            <Field
                                                type="text"
                                                name="userName"
                                                className="form-control"
                                                placeholder={commonservices.LabelDisplayText(
                                                    "UserName"
                                                )}
                                                maxLength={formFieldLength.userName}
                                            />
                                            <label lbl-for="userName">
                                                {commonservices.LabelDisplayText("UserName")}
                                            </label>
                                            <div className="text-danger-error">
                                                <ErrorMessage name="userName" />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xs={6} className="mb-3">
                                        <div className="form-floating">
                                            <Field
                                                type="password"
                                                name="password"
                                                className="form-control"
                                                placeholder={commonservices.LabelDisplayText(
                                                    "Password"
                                                )}
                                                maxLength={formFieldLength.password}
                                            />
                                            <label lbl-for="password">
                                                {commonservices.LabelDisplayText("Password")}
                                            </label>
                                            <div className="text-danger-error">
                                                <ErrorMessage name="password" />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xs={6} className="mb-3">
                                        <div className="form-floating">
                                            <Field
                                                type="password"
                                                className="form-control"
                                                name="confirm_password"
                                                placeholder={commonservices.LabelDisplayText(
                                                    "ConfirmYourPassword"
                                                )}
                                                maxLength={formFieldLength.confirm_password}
                                            />
                                            <label lbl-for="confirm_password">
                                                {commonservices.LabelDisplayText("ConfirmYourPassword")}
                                            </label>
                                            <div className="text-danger-error">
                                                <ErrorMessage name="confirm_password" />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xs={12} className="mb-3">
                                        <div className="form-floating">
                                            <Field
                                                as="textarea"
                                                name="details"
                                                className="form-control"
                                                placeholder={commonservices.LabelDisplayText("Details")}
                                                maxLength={formFieldLength.details}
                                            />
                                            <label lbl-for="details">
                                                {commonservices.LabelDisplayText("Details")}
                                            </label>
                                            <div className="text-danger-error">
                                                <ErrorMessage name="details" />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={props.onHide} title="Cancel">
                                {commonservices.LabelDisplayText("Close")}
                            </Button>
                            <Button
                                className="btn btn-primary"
                                type="submit"
                                title="Submit"
                                disabled={Submitting}
                            >
                                {Submitting && (
                                    <>
                                        <Spinner animation="border" />
                                    </>
                                )}
                                {commonservices.LabelDisplayText("Save")}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Formik>
            </Modal>
        </>
    );
};

export default AddVault;
