import axios from "axios";
import { useState, useEffect } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { default as React } from "react";
import { toast } from "react-toastify";
import * as yup from "yup";
import { commonservices, AppConfigData } from "../Services/commonService";
import { Col, Modal, Button, Container, Row, Table } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";

const AddProjectTermModal = (props) => {
    const [IsGetDetailsDone, setIsGetDetailsDone] = useState(false);
    const [lstCategory, setlstCategory] = useState([]);
    const [Submitting, setSubmitting] = useState(false);
    const [IsValidationMessageSet, setIsValidationMessageSet] = useState("");

    useEffect(() => {
        setIsValidationMessageSet(true);
    });
    const formFieldLength = {
        Term: 67,
    };

    const formInitialSchema = {
        TermID: 0,
        Term: "",
        Category: "",
    };

    const formValidation = yup.object().shape({
        Term: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "Term"))
            .test('len', commonservices.MakeValidationMessage("maxlength", ""), (value, obj) => { return value ? value.length <= formFieldLength.Term : true }),
        Category: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "Category")),
    });

    useEffect(() => {
        const axiosConfig = {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        };
        axios
            .post(
                `${AppConfigData.APIBasePath}v1/projectterm/gettermcategorylist`,
                null,
                axiosConfig
            )
            .then((res) => {
                if (res.data.status_code === 0) {
                } else if (res.data.status_code === 1) {
                    setlstCategory(res.data.data);
                } else if (res.data.status_code === 2) {
                } else {
                }
            })
            .catch((err) => { });

        if (props.id > 0) {
            axios
                .post(
                    `${AppConfigData.APIBasePath}v1/projectterm/getdetailsbyid`,
                    {
                        id: props.id,
                        requestFrom: "Web",
                    },
                    axiosConfig
                )
                .then((res) => {
                    if (res.data.status_code === 0) {
                    } else if (res.data.status_code === 1) {
                        commonservices.CheckNullAndReturnBlankInObjectKey(
                            res.data.data.projectterm
                        );
                        formInitialSchema.TermID = res.data.data.projectterm.termID;
                        formInitialSchema.Term = res.data.data.projectterm.term;
                        formInitialSchema.Category = res.data.data.projectterm.category;
                        // formInitialSchema.BGColor = res.data.data.projectterm.bGColor;
                        // formInitialSchema.FontColor = res.data.data.projectterm.fontColor;

                        setIsGetDetailsDone(true);
                    } else if (res.data.status_code === 2) {
                    } else {
                    }
                })
                .catch((err) => { });
        }
    }, []);

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                // centered
                className="right-modal modal-new project-term-modal"
                scrollable={true}
                backdrop="static"
                keyboard={false}
            >
                <Formik
                    initialValues={formInitialSchema}
                    validationSchema={formValidation}
                    onSubmit={async (values) => {
                        setSubmitting(true);
                        const formData = new FormData();
                        formData.append("TermID", values.TermID);
                        formData.append("Term", values.Term);
                        formData.append("Category", values.Category);
                        // formData.append("BGColor", values.BGColor);
                        // formData.append("FontColor", values.FontColor);

                        const axiosConfig = {
                            headers: {
                                "content-type": "multipart/form-data",
                                Authorization: "Bearer " + localStorage.getItem("token"),
                            },
                        };
                        await axios
                            .post(
                                `${AppConfigData.APIBasePath}v1/projectterm/save`,
                                formData,
                                axiosConfig
                            )

                            .then((res) => {
                                setSubmitting(false);
                                if (res.data.status_code === 0) {
                                    toast.error(res.data.message);
                                } else if (res.data.status_code === 1) {
                                    toast.success(res.data.message);
                                    props.bindList();
                                    props.onHide();
                                    //setTimeout(() => {
                                    //  props.onHide();
                                    //}, 1000);
                                } else if (res.data.status_code === 2) {
                                    toast.error(res.data.message);
                                } else {
                                    toast.error(res.data.message);
                                }
                            })
                            .catch((error) => { });
                    }}
                >
                    <Form>
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                {commonservices.LabelDisplayText("AddProjectTermDetails")}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Container>
                                <Row>
                                    <Col xs={12} className="mb-3">
                                        <div className="form-floating ">
                                            <Field
                                                as="select"
                                                name="Category"
                                                className="form-control down-arrow-icon"
                                            >
                                                <option value="">
                                                    {commonservices.LabelDisplayText("Select")}
                                                </option>
                                                {lstCategory.length > 0 &&
                                                    lstCategory.map((item, index) => (
                                                        <option key={index} value={item.value}>
                                                            {item.text}
                                                        </option>
                                                    ))}
                                            </Field>
                                            <label lbl-for="Category">
                                                {commonservices.LabelDisplayText("Category")}
                                            </label>
                                            <div className="text-danger-error">
                                                <ErrorMessage name="Category" />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={12} className="mb-3">
                                        <Field
                                            type="hidden"
                                            name="TermID"
                                            className="form-control"
                                            placeholder="TermID"
                                        />
                                        <div className="form-floating">
                                            <Field
                                                type="text"
                                                name="Term"
                                                className="form-control"
                                                placeholder={commonservices.LabelDisplayText(
                                                    "TermName"
                                                )}
                                                maxLength={formFieldLength.Term}
                                            />
                                            <label lbl-for="Term">
                                                {commonservices.LabelDisplayText("TermName")}
                                            </label>
                                            <div className="text-danger-error">
                                                <ErrorMessage name="Term" />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={props.onHide} className="Cancel-btn" title="Cancel">
                                {commonservices.LabelDisplayText("Cancel")}
                            </Button>
                            <Button
                                className="btn btn-primary save-btn"
                                type="submit"
                                title="Submit"
                                disabled={Submitting}
                            >
                                {Submitting && (
                                    <>
                                        <Spinner animation="border" />
                                    </>
                                )}
                                {commonservices.LabelDisplayText("Save")}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Formik>
            </Modal>
        </>
    );
};

export default AddProjectTermModal;
