import React, { useState, useEffect } from 'react'
import { ErrorMessage, Field, Form, Formik } from 'formik';
import axios from 'axios';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { Breadcrumb, Button, Col, Container, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import AddRoleModal from '../../Modal/AddRoleModal';
// import { AppConfigData, commonservices } from '../../commonService';
import { commonservices, AppConfigData } from '../../Services/commonService';
import { confirmAlert } from 'react-confirm-alert';
import $ from 'jquery';
import Loader, { LoaderType } from "../../Loader";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Pencil } from "../../assets/Icons/pencil.svg";
import { ReactComponent as Trash } from "../../assets/Icons/trash.svg";
import { ReactComponent as DownloadIcon } from "../../assets/Icons/download-icon.svg";
import { ReactComponent as ExportIocn } from "../../assets/Icons/export-iocn.svg";
import { downloadExcel } from 'react-export-table-to-excel';
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";


const Role = () => {

    const navigate = useNavigate();
    const [modalShow, setModalShow] = useState(false);
    const [roleData, setRoleData] = useState({
        Data: [], IsLoading: true
    });
    const [ROLEIDs, setROLEIDs] = useState(0);

    const [searchobj, setSearchobj] = useState({
        "field": "",
        "requestFrom": "",
    });

    function fnOpenRoleAddEditPopup(id) {
        setROLEIDs(id);
        setModalShow(true);
    }

    function bindList() {
        setRoleData({
            Data: [], IsLoading: true
        })
        const axiosConfig = {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem("token"),
            }
        }
        axios
            .post(`${AppConfigData.APIBasePath}v1/role/getlist`, searchobj, axiosConfig)
            .then((res) => {

                if (res.data.status_code === 0) {
                    setRoleData({ Data: [], IsLoading: false })

                } else if (res.data.status_code === 1) {
                    setRoleData({ Data: res.data.data, IsLoading: false });

                } else if (res.data.status_code === 2) {
                    commonservices.ClearLoginDetails();
                    navigate("/");
                }
                else {
                }
            })
            .catch((err) => {
                setRoleData({ Data: [], IsLoading: false })
            });
    }

    const header = ["RoleName", "IsProjectRole"];
    function handleDownloadExcel() {
        let data = [];
        roleData.Data.forEach((item) => {
            let arrayToAppend = []
            arrayToAppend = [...arrayToAppend, item.RoleName, item.IsProjectRole === 1 ? "Yes" : "No"]
            data = [...data, arrayToAppend];
        })
        downloadExcel({
            fileName: "RoleRight Details",
            sheet: "RoleRight Details",
            tablePayload: {
                header,
                body: data,
            },
        });
    }
    function PDFDownload() {
        let filename = "RoleRight Details";
        let data = [];
        roleData.Data.forEach((item) => {
            let arrayToAppend = []
            arrayToAppend = [...arrayToAppend, item.RoleName, item.IsProjectRole === 1 ? "Yes" : "No"]
            data = [...data, arrayToAppend];
        })
        const doc = new jsPDF()
        autoTable(doc, {
            head: [header],
            body: data,
            headStyles: {
                textColor: '#000', halign: 'center', valign: 'middle', lineWidth: 0.1,
                lineColor: '#ABABAB', fillColor: "#fff", font: 'times', fontStyle: 'bold'
            },
            styles: { textColor: '#fff', halign: 'center', valign: 'middle', font: 'times', size: '10px' },
            bodyStyles: { textColor: '#000', halign: 'center', valign: 'middle', font: 'times', size: '10px' },
            margin: { top: 0, bottom: 0, left: 5, right: 5 },
            theme: 'grid',
        })
        doc.save(filename + '.pdf')
    }

    useEffect(() => {
        bindList();
    }, []);

    // For Datatable
    $(document).ready(function () {
        $("table#RoletblData").DataTable({
            destroy: true,
            searching: false,
            dom: "ft<'tbl-info'<'length-info'li<'paggination'p>>>",
            aoColumnDefs: [
                {
                    bSortable: false,
                    aTargets: [-1] /* 1st one, start by the right */,
                },
            ],
        });
    });
    useEffect(() => {
        $(document).ready(function () {
            $("table#RoletblData").DataTable({
                destroy: true,
                searching: false,
                dom: "ft<'tbl-info'<'length-info'li<'paggination'p>>>",
                aoColumnDefs: [
                    {
                        bSortable: false,
                        aTargets: [-1] /* 1st one, start by the right */,
                    },
                ],
            }).destroy();
        });
    }, []);

    function deleteData(id) {
        let input = {
            "id": id,
            "requestFrom": "Web"
        }
        const axiosConfig = {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem("token"),
            }
        }
        axios
            .post(`${AppConfigData.APIBasePath}v1/role/delete`, input, axiosConfig)
            .then((res) => {

                if (res.data.status_code === 0) {
                    toast.error(res.data.message)
                } else if (res.data.status_code === 1) {
                    toast.success(res.data.message);
                    bindList()

                } else if (res.data.status_code === 2) {
                    toast.error(res.data.message)
                }
                else {
                    toast.error(res.data.message)
                }
            })
            .catch((err) => {

            });
    }

    return (
        <>

            <div className="table-section-col common-header-page empheadmain">
                <Row>
                    <Col lg={7} md={4}>
                        <div className="page-title-left">
                            {commonservices.LabelDisplayText('Role')}
                        </div>
                    </Col>
                    <Col lg={5} md={8} className="mt-auto mb-auto">
                        <div className="d-flex align-items-center justify-content-end">
                            <div className="inner-search-bar inner-search-grey w-100">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="SearchAnything"
                                    onKeyUp={(e) => e.keyCode === 13 && bindList()}
                                    name="searchData"
                                    onChange={(e) =>
                                        setSearchobj({
                                            ...searchobj,
                                            //   ["searchText"]: e.target.value,
                                        })
                                    }
                                />
                                <Button
                                    variant="btn btn-icon-btn-lg search-button"
                                    onClick={(e) => bindList()}
                                    title="Search"
                                >
                                    <i className="fa-light fa-magnifying-glass"></i>
                                </Button>
                            </div>
                            {
                                commonservices.getLoginRoleData("Role", "IsCreate") &&
                                <div className="project-add-icon">
                                    <Button className="project-btn" onClick={(e) => fnOpenRoleAddEditPopup(0)} title="Add">
                                        <i className="fa-regular fa-plus"></i>
                                    </Button>
                                </div>
                            }
                            {
                                commonservices.getLoginRoleData("Role", "IsDownload") &&
                                <div className="expor-download-button d-flex">
                                    <Button variant="project-btn ms-2" title="Export">
                                        <ExportIocn onClick={handleDownloadExcel} />
                                    </Button>
                                    <Button variant="project-btn pe-0 ps-1" title="Download">
                                        <DownloadIcon onClick={PDFDownload} />
                                    </Button>
                                </div>
                            }
                        </div>
                    </Col>
                </Row>
            </div>

            <div className="vault-tab vault-page">
                <div className="table-col">
                    <div className="recent-task-section">
                        <Formik>
                            <Form>
                                {
                                    roleData.IsLoading ?
                                        <>
                                            <Loader
                                                type={LoaderType.Table}
                                                count={4}
                                            />
                                        </>
                                        :
                                        <div className="table-scroll">
                                            <Table
                                                id="RoletblData"
                                                className="table table-inner-section"
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            {commonservices.LabelDisplayText('RoleName')}
                                                        </th>
                                                        <th>
                                                            {commonservices.LabelDisplayText('IsProjectRole')}
                                                        </th>
                                                        <th className="text-end width-100px">
                                                            {commonservices.LabelDisplayText("Action")}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="table-section-col ">
                                                    {roleData.Data.length > 0 &&
                                                        roleData.Data.map((item, index) =>
                                                            <tr key={index}>
                                                                <td className="padding-top-bottom">
                                                                    <div className="project-description">{item.RoleName}</div>
                                                                </td>
                                                                <td className="padding-top-bottom">
                                                                    <div className="d-flex table-text-common">
                                                                        {item.IsProjectRole === 1 ? "Yes" : "No"}
                                                                    </div>
                                                                </td>
                                                                <td className="text-end padding-top-bottom width-100px">
                                                                    {item.IsDefault === null &&

                                                                        <div className="d-flex justify-content-end">
                                                                            <>
                                                                                {
                                                                                    commonservices.getLoginRoleData("Role", "IsUpdate") &&

                                                                                    <Button
                                                                                        className="icon-btn me-1 h-auto"
                                                                                        title="Edit"
                                                                                        onClick={(e) => fnOpenRoleAddEditPopup(item.RoleID)}
                                                                                    >
                                                                                        <Pencil />
                                                                                    </Button>
                                                                                }
                                                                                {
                                                                                    commonservices.getLoginRoleData("Role", "IsDelete") &&
                                                                                    <Button className="icon-btn h-auto w-auto"
                                                                                    title="Delete"
                                                                                        onClick=
                                                                                        {(e) => {
                                                                                            confirmAlert({
                                                                                                message: commonservices.LabelDisplayText('AreYouSureToDoDelete'),
                                                                                                buttons: [
                                                                                                    {
                                                                                                        label: commonservices.LabelDisplayText('Yes'),
                                                                                                        onClick: () =>
                                                                                                            deleteData(item.RoleID)
                                                                                                    },
                                                                                                    {
                                                                                                        label: commonservices.LabelDisplayText('No'),
                                                                                                    }
                                                                                                ]
                                                                                            });

                                                                                        }}

                                                                                    >
                                                                                        <Trash />
                                                                                    </Button>
                                                                                }
                                                                            </>
                                                                        </div>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )}
                                                </tbody>
                                            </Table>
                                        </div>
                                }
                            </Form>
                        </Formik>
                    </div>
                </div>
            </div>
            {modalShow && (
                <AddRoleModal
                    id={ROLEIDs}
                    show={modalShow}
                    bindList={bindList}
                    onHide={() => setModalShow(false)}
                />
            )}
        </>
    )
}

export default Role;