import React, { useEffect, useState } from "react";
import {
  Tabs,
  Tab,
  Table,
  Row,
  Nav,
  Col,
  Container,
  Button,
} from "react-bootstrap";

import { ReactComponent as NextIcon } from "../../assets/Icons/next.svg";
import Employees from "../Employees/Employees";
import Role from "../Role/Role";
import Client from "../Client/Client";
import ProjectTerm from "../ProjectTerm/ProjectTerm";
import Mail from "../Mail/Mail";
import MailTemplates from "../MailTemplates/MailTemplates";
import LeaveTransaction from "../Leave/LeaveTransaction";
import LeaveMaster from "../Leave/LeaveMaster";
import { commonservices } from "../../Services/commonService";
import GenerateSalarySlip from "../SalarySlip/GenerateSalarySlip";
function Setting() {
  const [TabActiveKey, setTabActiveKey] = useState("employee");

  useEffect(() => {
    if (commonservices.getLoginRoleData("Employees", "IsView")) {
      setTabActiveKey("employee");
    } else if (commonservices.getLoginRoleData("Client", "IsView")) {
      setTabActiveKey("client");
    } else if (commonservices.getLoginRoleData("Role", "IsView")) {
      setTabActiveKey("role");
    } else if (commonservices.getLoginRoleData("ProjectTerm", "IsView")) {
      setTabActiveKey("projectterm");
    } else if (commonservices.getLoginRoleData("MailSetting", "IsView")) {
      setTabActiveKey("mailconfig");
    } else if (
      commonservices.getLoginRoleData("MailTemplateSetting", "IsView")
    ) {
      setTabActiveKey("templatesconfig");
    } else if (commonservices.getLoginRoleData("LeaveMaster", "IsView")) {
      setTabActiveKey("leavemaster");
    } else if (commonservices.getLoginRoleData("LeaveTransaction", "IsView")) {
      setTabActiveKey("leavetransaction");
    } else if (
      commonservices.getLoginRoleData("GenerateSalarySlip", "IsView")
    ) {
      setTabActiveKey("GenerateSalarySlip");
    }
  }, []);
  return (
    <>
      <div className="setting-page">
        <Tab.Container id="left-tabs-example" activeKey={TabActiveKey}>
          <Row className="m-0">
            <Col sm={3} className="ps-0 pe-xl-4 pe-lg-4 pe-md-4 pe-sm-0">
              <div className="table-section-col setting-page-tab">
                <div className="setting-left-sidebar-title">Settings</div>
                <Nav variant="pills" className="flex-column">
                  {commonservices.getLoginRoleData("Employees", "IsView") && (
                    <Nav.Item onClick={(e) => setTabActiveKey("employee")}>
                      <Nav.Link
                        eventKey="employee"
                        className="d-flex align-items-center justify-content-between"
                      >
                        Employees <NextIcon />
                      </Nav.Link>
                    </Nav.Item>
                  )}
                  {commonservices.getLoginRoleData("Client", "IsView") && (
                    <Nav.Item onClick={(e) => setTabActiveKey("client")}>
                      <Nav.Link
                        eventKey="client"
                        className="d-flex align-items-center justify-content-between"
                      >
                        Client <NextIcon />
                      </Nav.Link>
                    </Nav.Item>
                  )}
                  {commonservices.getLoginRoleData("Role", "IsView") && (
                    <Nav.Item onClick={(e) => setTabActiveKey("role")}>
                      <Nav.Link
                        eventKey="role"
                        className="d-flex align-items-center justify-content-between"
                      >
                        Role <NextIcon />
                      </Nav.Link>
                    </Nav.Item>
                  )}
                  {commonservices.getLoginRoleData("ProjectTerm", "IsView") && (
                    <Nav.Item onClick={(e) => setTabActiveKey("projectterm")}>
                      <Nav.Link
                        eventKey="projectterm"
                        className="d-flex align-items-center justify-content-between"
                      >
                        Project Term <NextIcon />
                      </Nav.Link>
                    </Nav.Item>
                  )}
                  {commonservices.getLoginRoleData("MailSetting", "IsView") && (
                    <Nav.Item onClick={(e) => setTabActiveKey("mailconfig")}>
                      <Nav.Link
                        eventKey="mailconfig"
                        className="d-flex align-items-center justify-content-between"
                      >
                        Mail Setting <NextIcon />
                      </Nav.Link>
                    </Nav.Item>
                  )}
                  {commonservices.getLoginRoleData(
                    "MailTemplateSetting",
                    "IsView"
                  ) && (
                    <Nav.Item
                      onClick={(e) => setTabActiveKey("templatesconfig")}
                    >
                      <Nav.Link
                        eventKey="templatesconfig"
                        className="d-flex align-items-center justify-content-between"
                      >
                        Mail Templates Setting
                        <NextIcon />
                      </Nav.Link>
                    </Nav.Item>
                  )}
                  {commonservices.getLoginRoleData("LeaveMaster", "IsView") && (
                    <Nav.Item onClick={(e) => setTabActiveKey("leavemaster")}>
                      <Nav.Link
                        eventKey="leavemaster"
                        className="d-flex align-items-center justify-content-between"
                      >
                        Leave Master
                        <NextIcon />
                      </Nav.Link>
                    </Nav.Item>
                  )}
                  {commonservices.getLoginRoleData(
                    "LeaveTransaction",
                    "IsView"
                  ) && (
                    <Nav.Item
                      onClick={(e) => setTabActiveKey("leavetransaction")}
                    >
                      <Nav.Link
                        eventKey="leavetransaction"
                        className="d-flex align-items-center justify-content-between"
                      >
                        Leave Transaction
                        <NextIcon />
                      </Nav.Link>
                    </Nav.Item>
                  )}
                  {commonservices.getLoginRoleData(
                    "GenerateSalarySlip",
                    "IsView"
                  ) && (
                    <Nav.Item
                      onClick={(e) => setTabActiveKey("generatesalaryslip")}
                    >
                      <Nav.Link
                        eventKey="generatesalaryslip"
                        className="d-flex align-items-center justify-content-between"
                      >
                        Generate Salary Slip
                        <NextIcon />
                      </Nav.Link>
                    </Nav.Item>
                  )}
                </Nav>
              </div>
            </Col>
            <Col sm={9} className="pe-0 pe-xl-0 pe-lg-0 pe-md-0 ps-sm-0">
              <Tab.Content>
                {commonservices.getLoginRoleData("Employees", "IsView") &&
                  TabActiveKey === "employee" && (
                    <Tab.Pane eventKey="employee">
                      <Container fluid className="p-0">
                        <Employees />
                      </Container>
                    </Tab.Pane>
                  )}
                {commonservices.getLoginRoleData("Client", "IsView") &&
                  TabActiveKey === "client" && (
                    <Tab.Pane eventKey="client">
                      <Container fluid className="p-0">
                        <Client />
                      </Container>
                    </Tab.Pane>
                  )}
                {commonservices.getLoginRoleData("Role", "IsView") &&
                  TabActiveKey === "role" && (
                    <Tab.Pane eventKey="role">
                      <Container fluid className="p-0">
                        <Role />
                      </Container>
                    </Tab.Pane>
                  )}
                {commonservices.getLoginRoleData("ProjectTerm", "IsView") &&
                  TabActiveKey === "projectterm" && (
                    <Tab.Pane eventKey="projectterm">
                      <Container fluid className="p-0">
                        <ProjectTerm />
                      </Container>
                    </Tab.Pane>
                  )}
                {commonservices.getLoginRoleData("MailSetting", "IsView") &&
                  TabActiveKey === "mailconfig" && (
                    <Tab.Pane eventKey="mailconfig">
                      <Container fluid className="p-0">
                        <Mail />
                      </Container>
                    </Tab.Pane>
                  )}
                {commonservices.getLoginRoleData(
                  "MailTemplateSetting",
                  "IsView"
                ) &&
                  TabActiveKey === "templatesconfig" && (
                    <Tab.Pane eventKey="templatesconfig">
                      <Container fluid className="p-0">
                        <MailTemplates />
                      </Container>
                    </Tab.Pane>
                  )}
                {commonservices.getLoginRoleData("LeaveMaster", "IsView") &&
                  TabActiveKey === "leavemaster" && (
                    <Tab.Pane eventKey="leavemaster">
                      <Container fluid className="p-0">
                        <LeaveMaster />
                      </Container>
                    </Tab.Pane>
                  )}
                {commonservices.getLoginRoleData(
                  "LeaveTransaction",
                  "IsView"
                ) &&
                  TabActiveKey === "leavetransaction" && (
                    <Tab.Pane eventKey="leavetransaction">
                      <Container fluid className="p-0">
                        <LeaveTransaction />
                      </Container>
                    </Tab.Pane>
                  )}
                {commonservices.getLoginRoleData(
                  "GenerateSalarySlip",
                  "IsView"
                ) &&
                  TabActiveKey === "generatesalaryslip" && (
                    <Tab.Pane eventKey="generatesalaryslip">
                      <Container fluid className="p-0">
                        <GenerateSalarySlip />
                      </Container>
                    </Tab.Pane>
                  )}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </>
  );
}

export default Setting;
