import React from "react";
import SideBar from "./SideBar";
import Header from "./Header";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../views/Index";
import { Suspense } from "react";
import Project from "../views/projects/Project";
import ProjectDetails from "../views/projects/ProjectDetails";
import Vault from "../views/Vault/Vault";
import Task from "../views/Task/Task";
import { commonservices } from "../Services/commonService";
import User from "../views/User/User";
import Setting from "../views/Setting/Setting";
import Profile from "../views/Profile/Profile";
import Leave from "../views/Leave/Leave";
import Reports from "../views/Reports/Reports";

function Layout() {
  return (
    <div id="layout-wrapper">
      <SideBar />
      <main className="main-content">
        <Header />
        <section className="dashboard-area">
          <Routes>
            <Route
              path="/dashboard"
              element={
                <Suspense fallback={"Loader..."}>
                  <Dashboard />
                </Suspense>
              }
            ></Route>
            {commonservices.getLoginRoleData("Project", "IsView") && (
              <Route
                path="/project"
                element={
                  <Suspense fallback={"Loader..."}>
                    <Project />
                  </Suspense>
                }
              ></Route>
            )}
            <Route
              path="/project-details/:id"
              element={
                <Suspense fallback={"Loader..."}>
                  <ProjectDetails />
                </Suspense>
              }
            />
            <Route
              path="/profile/:id"
              element={
                <Suspense fallback={"Loader..."}>
                  <Profile />
                </Suspense>
              }
            ></Route>
            {commonservices.getLoginRoleData("Vault", "IsView") && (
              <Route
                path="/vault"
                element={
                  <Suspense fallback={"Loader..."}>
                    <Vault />
                  </Suspense>
                }
              ></Route>
            )}
            {commonservices.getLoginRoleData("User", "IsView") && (
              <Route
                path="/user"
                element={
                  <Suspense fallback={"Loader..."}>
                    <User />
                  </Suspense>
                }
              ></Route>
            )}
            {commonservices.getLoginRoleData("Task", "IsView") && (
              <Route
                path="/task"
                element={
                  <Suspense fallback={"Loader..."}>
                    <Task />
                  </Suspense>
                }
              ></Route>
            )}
            {commonservices.getLoginRoleData("Setting", "IsView") && (
              <Route
                path="/setting"
                element={
                  <Suspense fallback={"Loader..."}>
                    <Setting />
                  </Suspense>
                }
              ></Route>
            )}
            {commonservices.getLoginRoleData("Leave", "IsView") && (
              <Route
                path="/leave"
                element={
                  <Suspense fallback={"Loader..."}>
                    <Leave />
                  </Suspense>
                }
              ></Route>
            )}

            {/* Reports Route */}
            <Route
              path="/reports"
              element={
                <Suspense fallback={"Loader..."}>
                  <Reports />
                </Suspense>
              }
            ></Route>

            {/*This All Page And Menu Move Inside Of Setting Page*/}

            {/*{*/}
            {/*    commonservices.getLoginRoleData("Employees", "IsView") &&*/}
            {/*    <Route*/}
            {/*        path="/employees"*/}
            {/*        element={*/}
            {/*            <Suspense fallback={"Loader..."}>*/}
            {/*                <Employees />*/}
            {/*            </Suspense>*/}
            {/*        }*/}
            {/*    ></Route>*/}
            {/*}*/}
            {/*{commonservices.getLoginRoleData("Client", "IsView") &&*/}
            {/*    <Route*/}
            {/*        path="/client"*/}
            {/*        element={*/}
            {/*            <Suspense fallback={"Loader..."}>*/}
            {/*                <Client />*/}
            {/*            </Suspense>*/}
            {/*        }*/}
            {/*    ></Route>*/}
            {/*}*/}

            {/*{*/}
            {/*    commonservices.getLoginRoleData("MailSetting", "IsView") &&*/}
            {/*    <Route*/}
            {/*        path="/mail"*/}
            {/*        element={*/}
            {/*            <Suspense fallback={"Loader..."}>*/}
            {/*                <Mail />*/}
            {/*            </Suspense>*/}
            {/*        }*/}
            {/*    ></Route>*/}
            {/*}*/}
            {/*{*/}
            {/*    commonservices.getLoginRoleData("MailTemplateSetting", "IsView") &&*/}
            {/*    <Route*/}
            {/*        path="/mailTemplates"*/}
            {/*        element={*/}
            {/*            <Suspense fallback={"Loader..."}>*/}
            {/*                <MailTemplates />*/}
            {/*            </Suspense>*/}
            {/*        }*/}
            {/*    ></Route>*/}
            {/*}*/}

            {/*{*/}
            {/*    commonservices.getLoginRoleData("Role", "IsView") &&*/}
            {/*    <Route*/}
            {/*        path="/role"*/}
            {/*        element={*/}
            {/*            <Suspense fallback={"Loader..."}>*/}
            {/*                <Role />*/}
            {/*            </Suspense>*/}
            {/*        }*/}
            {/*    ></Route>*/}
            {/*}*/}

            {/*{*/}
            {/*    commonservices.getLoginRoleData("LeaveTransaction", "IsView") &&*/}
            {/*    <Route*/}
            {/*        path="/leavetransaction"*/}
            {/*        element={*/}
            {/*            <Suspense fallback={"Loader..."}>*/}
            {/*                <LeaveTransaction />*/}
            {/*            </Suspense>*/}
            {/*        }*/}
            {/*    ></Route>*/}
            {/*}*/}
          </Routes>
        </section>
      </main>
    </div>
  );
}

export default Layout;
