import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  OverlayTrigger,
  Row, // Modal,
  Table,
  Card,
} from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";
import ProjectPlaceholder from "../../assets/Images/ProjectPlaceholder.png";
import UserPlaceholder from "../../assets/Icons/placeholder.svg";
import AddTaskModal from "../../Modal/AddTaskModal";
import { confirmAlert } from "react-confirm-alert";
import $ from "jquery";
import Popover from "react-bootstrap/Popover";
import { HubConnectionBuilder } from "@microsoft/signalr";
import Loader, { LoaderType } from "../../Loader";
import { commonservices, AppConfigData } from "../../Services/commonService";
import TaskDetail from "../../Modal/TaskDetail";
import { ReactComponent as DownloadIcon } from "../../assets/Icons/download-icon.svg";
import { ReactComponent as ExportIocn } from "../../assets/Icons/export-iocn.svg";
import { ReactComponent as TimeQuarterPast } from "../../assets/Icons/time-quarter-past.svg";
import { ReactComponent as CalendarIcon } from "../../assets/Icons/calendar-icon.svg";
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";
import { ReactComponent as Trash } from "../../assets/Icons/trash.svg";
import { ReactComponent as FilterIcon } from "../../assets/Icons/filter-icon.svg";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import _ from "lodash";
import { downloadExcel } from "react-export-table-to-excel";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import Chart from "react-apexcharts";
import { ReactComponent as DeleteIconNew } from "../../assets/Icons/delete-icon-new.svg";

function Task() {
  const location = useLocation();
  console.log("location ::", location);
  const alltaskData1 = useRef([]);
  let param = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [modalShowDetails, setModalShowDetails] = useState(false);
  const [modalTaskAdd, setModalTaskAdd] = useState(false);
  const [projectTerm, setProjectTerm] = useState([]);
  const [Countlst, setCountlst] = useState({
    Data: [],
    IsLoading: true,
  });
  const [projectStatus, setProjectStatus] = useState([]);
  const [alltaskData, setalltaskData] = useState([]);
  const [taskData, settaskData] = useState({
    Data: [],
    IsLoading: false,
  });

  const [IsTaskLoading, setIsTaskLoading] = useState(true);
  const [TASKID, setTASKID] = useState(0);
  const [TaskDataForShowDetails, setTaskDataForShowDetails] = useState({
    TaskID: 0,
    ProjectID: 0,
  });
  const [searchobj, setSearchobj] = useState({
    title: "",
    status: "In Process,Not Started",
    priority: "",
    startDate: null,
    endDate: null,
    projectID: "",
    lststatus: [
      { label: "In Process", value: "In Process" },
      { label: "Not Started", value: "Not Started" },
    ],
    lstpriority: [],
    pageNum: 1,
    pageSize: 20,
  });
  let searchObjref = useRef({
    title: "",
    status: "",
    priority: "",
    startDate: null,
    endDate: null,
    projectID: "",
    lststatus: [
      { label: "In Process", value: "In Process" },
      { label: "Not Started", value: "Not Started" },
    ],
    lstpriority: [],
    pageNum: 1,
    pageSize: 20,
  });
  const pageNumRef = useRef(1);
  const isLoading = useRef(false);
  // let isLoading = false;
  function bindList(objSearchPara) {
    setIsTaskLoading(true);
    isLoading.current = true;
    setIsOpen(false);
    if (objSearchPara === null || objSearchPara === undefined) {
      objSearchPara = { ...searchobj };
      objSearchPara.pageNum = 1;
      searchObjref.current = objSearchPara;
      // objSearchPara.pageNum = 1;
    } else {
      // objSearchPara.pageNum = pageNumRef.current;
      setSearchobj(objSearchPara);
    }
    if (objSearchPara.pageNum === 1) {
      settaskData({ Data: [], IsLoading: false });
    }

    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    // Use a loading state to prevent multiple calls
    if (objSearchPara.loading) {
      return;
    }

    objSearchPara.loading = true;
    axios
      .post(
        `${AppConfigData.APIBasePath}v1/task/getlistwithtimedetailsdatewiseusingpagination`,
        objSearchPara,
        axiosConfig
      )
      .then((res) => {
        setIsTaskLoading(false);
        objSearchPara.loading = false;

        if (res.data.status_code === 0) {
          isLoading.current = false;
          /* settaskData({ Data: [], IsLoading: false });*/
        } else if (res.data.status_code === 1) {
            debugger
          isLoading.current = false;
          if (objSearchPara.pageNum === 1) {
            searchObjref.current.pageNum = 1;
            alltaskData1.current = res.data.data;
          } else {
            alltaskData1.current.runningTaskList = [
              ...alltaskData1.current.runningTaskList,
              ...res.data.data.runningTaskList,
            ];
            alltaskData1.current.taskList = [
              ...alltaskData1.current.taskList,
              ...res.data.data.taskList,
            ];
            alltaskData1.current.taskUserList = [
              ...alltaskData1.current.taskUserList,
              ...res.data.data.taskUserList,
            ];
            // alltaskData1.current = [...alltaskData1.current, ...res.data.data];
          }

          const groups = alltaskData1.current.taskList.reduce(
            (groups, task) => {
              const date = task.DateRang;
              if (!groups[date]) {
                groups[date] = {
                  Date: date,
                  tasklist: [],
                  TotalTimeInMinites: 0,
                };
              }
              task.TaskUserList = alltaskData1.current.taskUserList.filter(
                (U) => U.TaskID === task.TaskID
              );
              task.RunningTaskList =
                alltaskData1.current.runningTaskList.filter(
                  (R) =>
                    R.TaskID === task.TaskID && R.TimerDate === task.DateRang
                );

              groups[date].tasklist.push(task);
              groups[date].TotalTimeInMinites += task.TotalTimeInMinites;

              return groups;
            },
            {}
          );
          settaskData({ Data: groups, IsLoading: false });
        } else if (res.data.status_code === 2) {
          commonservices.ClearLoginDetails();
          navigate("/");
        }
      })
      .catch((err) => {
        isLoading.current = false;
        // isLoading = false;
        objSearchPara.loading = false;
        // Handle error and update the state if necessary
      });
  }

  $(document).ready(function () {
    $("table#tblData").DataTable({
      destroy: true,
      dom: "ft<'tbl-info'<'length-info'li<'paggination'p>>>",
      aoColumnDefs: [
        {
          bSortable: false,
          aTargets: [-1],
        },
      ],
    });

    const mainContentElement = document.getElementsByClassName("main-content");
    if (mainContentElement.length > 0) {
      mainContentElement[0].addEventListener("scroll", WindowScrolling);
    } else {
      console.error('Element with class "main-content" not found.');
    }
  });

  function WindowScrolling(event) {
    if (
      event.srcElement.scrollTop + window.innerHeight - 10 >=
        event.srcElement.scrollHeight - 1 &&
      isLoading.current === false
    ) {
      let obj = { ...searchObjref };
      obj.pageNum = obj.pageNum + 1;
      searchObjref.current.pageNum++;
      // pageNumRef.current++;
      bindList(searchObjref.current);
      // setSearchobj((prev) => ({ ...prev, pageNum: prev.pageNum++ }));
    }
  }

  function bindCountList() {
    setCountlst({
      Data: [],
      IsLoading: true,
    });
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(
        `${AppConfigData.APIBasePath}v1/task/getstatuswisecount`,
        {
          projectID: null,
        },
        axiosConfig
      )
      .then((res) => {
        if (res.data.status_code === 0) {
          setCountlst({ Data: [], IsLoading: false });
        } else if (res.data.status_code === 1) {
          setCountlst({ Data: res.data.data, IsLoading: false });
        } else if (res.data.status_code === 2) {
          commonservices.ClearLoginDetails();
          navigate("/");
        } else {
        }
      })
      .catch((err) => {
        setCountlst({
          Data: [],
          IsLoading: false,
        });
      });
  }

  function fnOpenTaskAddEditPopup(id) {
    setTASKID(id);
    setModalTaskAdd(true);
  }

  function deleteData(id) {
    let input = {
      id: id,
      requestFrom: "Web",
    };
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(`${AppConfigData.APIBasePath}v1/task/delete`, input, axiosConfig)
      .then((res) => {
        if (res.data.status_code === 0) {
          toast.error(res.data.message);
        } else if (res.data.status_code === 1) {
          bindList();
          toast.success(res.data.message);
        } else if (res.data.status_code === 2) {
          toast.error(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {});
  }

  const header = [
    "Date",
    "ProjectName",
    "Title",
    "StartDate",
    "EndDate",
    "Priority",
    "Status",
  ];
  function handleDownloadExcel() {
    let data = [];
    Object.keys(taskData.Data).forEach((item) => {
      let arrayToAppend = [];
      {
        taskData.Data[item].tasklist.length > 0 &&
          taskData.Data[item].tasklist.map(
            (itemTask, rowindex) =>
              (arrayToAppend = [
                ...arrayToAppend,
                commonservices.getDateInDDMMYYYY(taskData.Data[item].Date),
                itemTask.ProjectName,
                itemTask.Title,
                commonservices.getDateInDDMMYYYY(itemTask.StartDate),
                commonservices.getDateInDDMMYYYY(itemTask.EndDate),
                itemTask.Priority,
                itemTask.Status,
              ])
          );
      }
      data = [...data, arrayToAppend];
    });
    downloadExcel({
      fileName: "Task Details",
      sheet: "Task Details",
      tablePayload: {
        header,
        body: data,
      },
    });
  }
  function PDFDownload() {
    let filename = "Task Details";
    let data = [];
    Object.keys(taskData.Data).forEach((item) => {
      let arrayToAppend = [];
      {
        taskData.Data[item].tasklist.length > 0 &&
          taskData.Data[item].tasklist.map(
            (itemTask, rowindex) =>
              (arrayToAppend = [
                ...arrayToAppend,
                commonservices.getDateInDDMMYYYY(taskData.Data[item].Date),
                itemTask.ProjectName,
                itemTask.Title,
                commonservices.getDateInDDMMYYYY(itemTask.StartDate),
                commonservices.getDateInDDMMYYYY(itemTask.EndDate),
                itemTask.Priority,
                itemTask.Status,
              ])
          );
      }
      data = [...data, arrayToAppend];
    });
    const doc = new jsPDF();
    autoTable(doc, {
      head: [header],
      body: data,
      headStyles: {
        textColor: "#000",
        halign: "center",
        valign: "middle",
        lineWidth: 0.1,
        lineColor: "#ABABAB",
        fillColor: "#fff",
        font: "times",
        fontStyle: "bold",
      },
      styles: {
        textColor: "#fff",
        halign: "center",
        valign: "middle",
        font: "times",
        size: "10px",
      },
      bodyStyles: {
        textColor: "#000",
        halign: "center",
        valign: "middle",
        font: "times",
        size: "10px",
      },
      margin: { top: 0, bottom: 0, left: 5, right: 5 },
      theme: "grid",
    });
    doc.save(filename + ".pdf");
  }

  let data = commonservices.getLoginUserData();
  const [WorkingHoursChart, setWorkingHoursChart] = useState({
    Data: {
      options: {
        markers: {
          size: 5,
          strokeWidth: 3,
          strokeColors: "#345BF8",
          colors: "#ffffff",
          hover: {
            size: 7,
            strokeWidth: 8,
          },
        },
        xaxis: {
          categories: [],
          axisBorder: { show: true },
          axisTicks: { show: false },
          labels: { show: true },
        },
        yaxis: { show: true },
        grid: { show: true },
        stroke: {
          width: 5,
          curve: "smooth",
        },
        title: {
          text: "Working Hours",
          align: "left",
          style: {
            fontSize: "22px",
            lineHeight: "28px",
            fontWeight: "700",
            fontFamily: "Source Sans Pro",
            color: "#1C212D",
          },
        },
        colors: ["#345BF8"],
        chart: {
          sparkline: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
          background: "#fff",
          type: "line",
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          enabled: true,
          style: {
            fontSize: "20px",
            lineHeight: "24px",
            fontWeight: "700",
            fontFamily: "Source Sans Pro",
            color: "#1C212D",
            textAlign: "center",
          },
          x: {
            show: false,
          },
          marker: {
            show: false,
          },
          fixed: {
            enabled: false,
            position: "topLeft",
            offsetX: "0",
            offsetY: "0",
          },
        },
      },
      series: [
        {
          name: "",
          data: [],
        },
      ],
    },
    IsLoading: true,
  });

  const [WorkingHoursChartMode, setWorkingHoursChartMode] = useState("Week");
  function fnBindWorkingHoursChart(chartMode) {
    setWorkingHoursChartMode(chartMode);
    let ChartDate = WorkingHoursChart.Data;
    let startDate = new Date().toUTCString();
    let endDate = new Date().toUTCString();
    ChartDate.options.xaxis.categories = [];
    ChartDate.series[0].data = [];
    setWorkingHoursChart({ Data: ChartDate, IsLoading: true });
    let curr = new Date();
    if (chartMode.toUpperCase() === "WEEK") {
      let first = curr.getDate() - curr.getDay() - 3;
      let last = first + 6;
      startDate = new Date(curr.setDate(first));
      endDate = new Date(curr.setDate(last));
    }

    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(
        `${AppConfigData.APIBasePath}v1/reports/workinghourschartdata`,
        {
          employeeID: data[0].AssociationID,
          startDate: commonservices.getDateInDBFormat(startDate),
          endDate: commonservices.getDateInDBFormat(endDate),
          chartMode: chartMode,
        },
        axiosConfig
      )
      .then((res) => {
        if (res.data.status_code === 0) {
          setWorkingHoursChart({ Data: ChartDate, IsLoading: false });
        } else if (res.data.status_code === 1) {
          let xaxis_categories = [];
          let series_data = [];
          for (let i = 0; i < res.data.data.length; i++) {
            xaxis_categories.push(res.data.data[i].Text);
            series_data.push(res.data.data[i].Value);
          }
          ChartDate.options.xaxis.categories = xaxis_categories;
          ChartDate.series[0].data = series_data;
          setWorkingHoursChart({ Data: ChartDate, IsLoading: false });
        } else if (res.data.status_code === 2) {
          commonservices.ClearLoginDetails();
          navigate("/");
        }
      })
      .catch((err) => {
        setWorkingHoursChart({ Data: ChartDate, IsLoading: false });
      });
  }

  useEffect(() => {
    if (location.state !== null && location.state !== undefined) {
      let obj = {
        title: "",
        status: location.state.status ? location.state.status : "",
        priority: "",
        startDate: location.state.startDate ? location.state.startDate : null,
        endDate: location.state.endDate ? location.state.endDate : null,
        projectID: "",
        lststatus: location.state.lststatus ? location.state.lststatus : [],
        lstpriority: [],
        pageNum: 1,
        pageSize: 20,
      };
      bindList(obj);
    } else {
      bindList();
    }
    bindCountList();
    fnBindWorkingHoursChart("WEEK");
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(
        `${AppConfigData.APIBasePath}v1/projectterm/getlist`,
        {
          term: "",
          category: "Documents,TaskStatus,TaskPriority",
        },
        axiosConfig
      )
      .then((res) => {
        if (res.data.status_code === 0) {
        } else if (res.data.status_code === 1) {
          let optionUser = [];
          res.data.data
            .filter((t) => t.Category === "TaskPriority")
            .forEach((element) => {
              optionUser.push({
                label: element.Term,
                value: element.Term,
              });
            });
          setProjectTerm(optionUser);

          let status = [];
          res.data.data
            .filter((t) => t.Category === "TaskStatus")
            .forEach((element) => {
              status.push({
                label: element.Term,
                value: element.Term,
              });
            });
          setProjectStatus(status);
        } else if (res.data.status_code === 2) {
        } else {
        }
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    $(document).ready(function () {
      $("table#tblData")
        .DataTable({
          destroy: true,
          dom: "ft<'tbl-info'<'length-info'li<'paggination'p>>>",
          aoColumnDefs: [
            {
              bSortable: false,
              aTargets: [-1] /* 1st one, start by the right */,
            },
          ],
        })
        .destroy();
    });
  }, []);

  function fnCreateTaskTimeUI(task) {
    if (task.IsAssign > 0) {
      if (task.RunningCount > 0) {
        return (
          <>
            <div className="task-time text-nowrap me-3">
              {commonservices.getTimeInFormat(
                task.RunningTaskList[0].StartTime
              )}
            </div>
          </>
        );
      } else {
        if (task.IsTaskRunningByMe === 0) {
          return (
            <>
              <div className="task-time text-nowrap me-3">
                {task.TotalTimeInMinites > 0 &&
                  commonservices.ConvertMinutesToTimeFormat(
                    task.TotalTimeInMinites
                  ) + " hrs"}
              </div>
            </>
          );
        }
      }
    } else {
      if (task.RunningCount > 0) {
        if (task.RunningCount == 1 && task.RunningTaskList.length == 1) {
          return (
            <>
              <div className="task-time text-nowrap me-3">
                {commonservices.getTimeInFormat(
                  task.RunningTaskList[0].StartTime
                )}
              </div>
            </>
          );
        } else {
          return (
            <>
              <div className="task-time text-nowrap me-3">
                {task.RunningCount} user
              </div>
            </>
          );
        }
      } else {
        if (task.TotalTimeInMinites > 0) {
          return (
            <>
              <div className="task-time text-nowrap me-3">
                {commonservices.ConvertMinutesToTimeFormat(
                  task.TotalTimeInMinites
                )}{" "}
                hrs
              </div>
            </>
          );
        }
      }
    }
  }

  function fnCreateTaskButtonUI(task) {
    if (task.IsAssign > 0) {
      if (task.RunningCount > 0) {
        return (
          <>
            <button
              type="button"
              className="pause-play-button me-1"
              title={"Stop Timer"}
              onClick={(e) => {
                confirmAlert({
                  message: commonservices.LabelDisplayText(
                    "AreYouSureWantToStopTask"
                  ),
                  buttons: [
                    {
                      label: commonservices.LabelDisplayText("Yes"),
                      onClick: function () {
                        let input = {
                          taskID: task.TaskID,
                          projectID: task.ProjectID,
                          stopTaskComment: "",
                        };
                        const axiosConfig = {
                          headers: {
                            Accept: "application/json",
                            Authorization:
                              "Bearer " + localStorage.getItem("token"),
                          },
                        };
                        axios
                          .post(
                            `${AppConfigData.APIBasePath}v1/task/taskstoptimer`,
                            input,
                            axiosConfig
                          )
                          .then((res) => {
                            if (res.data.status_code === 0) {
                              toast.error(res.data.message);
                            } else if (res.data.status_code === 1) {
                              toast.success(res.data.message);
                              fnCallTaskStartStopInHub();

                              bindList();
                            } else if (res.data.status_code === 2) {
                              commonservices.ClearLoginDetails();
                              navigate("/");
                            }
                          })
                          .catch((err) => {});
                      },
                    },
                    {
                      label: commonservices.LabelDisplayText("No"),
                    },
                  ],
                });
              }}
            >
              <i className="fa-regular fa-pause"></i>
            </button>
          </>
        );
      } else {
        if (task.IsTaskRunningByMe === 0) {
          return (
            <>
              <button
                type="button"
                className="pause-play-button me-1"
                title={"Start Timer"}
                onClick={(e) => {
                  confirmAlert({
                    message: commonservices.LabelDisplayText(
                      "AreYouSureWantToStartTask"
                    ),
                    buttons: [
                      {
                        label: commonservices.LabelDisplayText("Yes"),
                        onClick: function () {
                          let input = {
                            taskID: task.TaskID,
                            projectID: task.ProjectID,
                            stopTaskComment: "",
                          };
                          const axiosConfig = {
                            headers: {
                              Accept: "application/json",
                              Authorization:
                                "Bearer " + localStorage.getItem("token"),
                            },
                          };
                          axios
                            .post(
                              `${AppConfigData.APIBasePath}v1/task/taskstarttimer`,
                              input,
                              axiosConfig
                            )
                            .then((res) => {
                              if (res.data.status_code === 0) {
                                toast.error(res.data.message);
                              } else if (res.data.status_code === 1) {
                                toast.success(res.data.message);
                                fnCallTaskStartStopInHub();
                                // taskBind();
                                bindList();
                              } else if (res.data.status_code === 2) {
                                commonservices.ClearLoginDetails();
                                navigate("/");
                              }
                            })
                            .catch((err) => {});
                        },
                      },
                      {
                        label: commonservices.LabelDisplayText("No"),
                      },
                    ],
                  });
                }}
              >
                <i className="fa-regular fa-play"></i>
              </button>
            </>
          );
        }
      }
    } else {
      if (task.RunningCount > 0) {
        if (task.RunningCount == 1 && task.RunningTaskList.length == 1) {
          return (
            <>
              <button
                type="button"
                className="pause-play-button me-1"
                title={"Running By " + task.RunningTaskList[0].DisplayName + ""}
              >
                <i className="fa-regular fa-timer"></i>
              </button>
            </>
          );
        } else {
          return (
            <>
              <OverlayTrigger
                trigger="click"
                key={"bottom"}
                placement={"bottom"}
                overlay={
                  <Popover id={`popover-positioned-bottom`}>
                    <Popover.Body>
                      {task.RunningTaskList.map((item, index) => (
                        <div
                          className="task-time-status d-flex align-items-center justify-content-end"
                          key={index}
                        >
                          <img
                            className="project-img me-2"
                            // src={item.ProfilePic}
                            src={
                              item.ProfilePic !== null && item.ProfilePic !== ""
                                ? item.ProfilePic
                                : UserPlaceholder
                            }
                            alt={commonservices.getInitials(item.DisplayName)}
                            title={item.DisplayName}
                            width={30}
                            height={30}
                          />
                          <div className="task-time text-nowrap me-3">
                            {commonservices.getTimeInFormat(item.StartTime)}
                          </div>
                        </div>
                      ))}
                    </Popover.Body>
                  </Popover>
                }
              >
                <button
                  type="button"
                  className="pause-play-button me-1"
                  title={"Running By " + task.RunningCount + " User"}
                >
                  <i className="fa-regular fa-timer"></i>
                </button>
              </OverlayTrigger>
            </>
          );
        }
      }
    }
  }

  function fnCallTaskStartStopInHub() {
    let userdata = commonservices.getLoginUserData();
    const connect = new HubConnectionBuilder()
      .withUrl(AppConfigData.SignalRHubPath, { withCredentials: false })
      .withAutomaticReconnect()
      .build();
    connect
      .start()
      .then(() => {
        connect.send("TaskStartStop", { UserID: userdata[0].UserID });
      })
      .catch((error) => {});
  }

  const concernedElement = document.querySelector(".filter-col");

  document.addEventListener("click", (event) => {
    if (concernedElement !== null) {
      if (
        !concernedElement.contains(event.target) &&
        document.contains(event.target)
      ) {
        setIsOpen(false);
      }
    }
  });

  return (
    <>
      <div className="position-relative">
        <Container fluid className="p-0">
          <div className="table-section-col common-header-page">
            <Row>
              <Col xl={4} lg={4} md={4}>
                <div className="page-title-left">
                  {commonservices.LabelDisplayText("Task")}
                </div>
              </Col>
              <Col xl={4} lg={4} md={4}>
                <div className="text-end">
                  <b>Status</b> &nbsp;:&nbsp;{" "}
                  <b className={"search-display"}>
                    {searchobj.status ? searchobj.status : "ALL"}
                  </b>
                  <br />
                  <b>Date</b> &nbsp;:&nbsp;{" "}
                  <b className={"search-display"}>
                    {searchobj.startDate ? searchobj.startDate : "#"} -{" "}
                    {searchobj.endDate ? searchobj.endDate : "#"}
                  </b>
                </div>
              </Col>
              <Col xl={4} lg={4} md={4} className="mt-auto mb-auto">
                <div className="d-flex align-items-center justify-content-end">
                  <div className="inner-search-bar inner-search-grey w-100">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={commonservices.LabelDisplayText(
                        "SearchAnything"
                      )}
                      name="searchData"
                      value={searchobj.title}
                      onChange={(e) => {
                        setSearchobj({
                          ...searchobj,
                          ["title"]: e.target.value,
                        });
                      }}
                      onKeyUp={(e) => e.keyCode === 13 && bindList()}
                    />
                    <Button
                      variant="btn btn-icon-btn-lg search-button"
                      onClick={(e) => bindList()}
                      title="Search"
                    >
                      <i className="fa-light fa-magnifying-glass"></i>
                    </Button>
                  </div>
                  <div className="project-add-icon">
                    <Button
                      className="project-btn"
                      title="Add"
                      onClick={(e) => fnOpenTaskAddEditPopup(0)}
                    >
                      <i className="fa-regular fa-plus"></i>
                    </Button>
                  </div>
                  <div className="filter-col">
                    <div className="custom-modal">
                      <button
                        className="ms-3 bg-transparent border-0"
                        title="Filter"
                        onClick={() => setIsOpen(!isOpen)}
                      >
                        <FilterIcon />
                      </button>

                      {isOpen && (
                        <div className="custom-modal-body">
                          <div>
                            <Row>
                              <Col lg={12} className="from-bottom-margin">
                                <div className="form-floating ">
                                  <input
                                    type="text"
                                    name="Title"
                                    className="form-control"
                                    onKeyUp={(e) =>
                                      e.keyCode === 13 && bindList()
                                    }
                                    onClick={(e) => {
                                      window.addEventListener(
                                        "keydown",
                                        function (e) {
                                          if (
                                            e.keyIdentifier == "U+000A" ||
                                            e.keyIdentifier == "Enter" ||
                                            e.keyCode == 13
                                          ) {
                                            // bindList()
                                            if (
                                              e.target.nodeName == "INPUT" &&
                                              e.target.type == "text"
                                            ) {
                                              e.preventDefault();

                                              return false;
                                            }
                                          }
                                        },
                                        true
                                      );
                                    }}
                                    value={searchobj.title}
                                    placeholder={commonservices.LabelDisplayText(
                                      "Title"
                                    )}
                                    onChange={(e) =>
                                      setSearchobj({
                                        ...searchobj,
                                        ["title"]: e.target.value,
                                      })
                                    }
                                  />
                                  <label>
                                    {commonservices.LabelDisplayText("Title")}
                                  </label>
                                </div>
                              </Col>
                              <Col xs={6} className="from-bottom-margin">
                                <div className="form-floating">
                                  <input
                                    type="date"
                                    name="StartDate"
                                    className="form-control calendar-icon"
                                    placeholder={commonservices.LabelDisplayText(
                                      "StartDate"
                                    )}
                                    onChange={(e) =>
                                      setSearchobj({
                                        ...searchobj,
                                        ["startDate"]: e.target.value,
                                      })
                                    }
                                    value={searchobj.startDate}
                                  />
                                  <label>
                                    {commonservices.LabelDisplayText(
                                      "StartDate"
                                    )}
                                  </label>
                                </div>
                              </Col>
                              <Col xs={6} className="from-bottom-margin">
                                <div className="form-floating">
                                  <input
                                    type="date"
                                    name="EndDate"
                                    className="form-control calendar-icon"
                                    placeholder={commonservices.LabelDisplayText(
                                      "EndDate"
                                    )}
                                    onChange={(e) =>
                                      setSearchobj({
                                        ...searchobj,
                                        ["endDate"]: e.target.value,
                                      })
                                    }
                                    value={searchobj.endDate}
                                  />
                                  <label>
                                    {commonservices.LabelDisplayText("EndDate")}
                                  </label>
                                </div>
                              </Col>
                              <Col xs={6} className="from-bottom-margin">
                                <div className="form-floating multipleselect-form-floating filter-form">
                                  <ReactMultiSelectCheckboxes
                                    width={"100%"}
                                    hideSearch="true"
                                    placeholderButtonLabel={"Priority"}
                                    isMulti
                                    name="ProjectUser"
                                    options={projectTerm}
                                    onChange={(e) =>
                                      setSearchobj({
                                        ...searchobj,
                                        ["priority"]: e
                                          .map((item) => item.value)
                                          .toString(),
                                        ["lstpriority"]: e,
                                      })
                                    }
                                    value={searchobj.lstpriority}
                                  />
                                  <label>
                                    {commonservices.LabelDisplayText("Select")}
                                  </label>
                                </div>
                              </Col>
                              <Col xs={6} className="from-bottom-margin">
                                <div className="form-floating multipleselect-form-floating filter-form">
                                  <ReactMultiSelectCheckboxes
                                    width={"100%"}
                                    hideSearch="true"
                                    placeholderButtonLabel={"Status"}
                                    isMulti
                                    name="ProjectUser"
                                    options={projectStatus}
                                    onChange={(e) =>
                                      setSearchobj({
                                        ...searchobj,
                                        ["status"]: e
                                          .map((item) => item.value)
                                          .toString(),
                                        ["lststatus"]: e,
                                      })
                                    }
                                    value={searchobj.lststatus}
                                  />
                                  <label>
                                    {commonservices.LabelDisplayText("Select")}
                                  </label>
                                </div>
                              </Col>
                            </Row>
                          </div>
                          <div className="custom-modal-footer">
                            <Button
                              className="btn btn-secondary"
                              title="Clear"
                              onClick={(e) => {
                                bindList({
                                  title: "",
                                  status: "",
                                  priority: "",
                                  startDate: null,
                                  endDate: null,
                                  projectID: "",
                                  lststatus: [],
                                  lstpriority: [],
                                });
                              }}
                            >
                              Clear
                            </Button>
                            <Button
                              className="btn btn-primary"
                              title="Filter"
                              onClick={(e) => bindList()}
                            >
                              Filter
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="expor-download-button d-flex">
                    <Button variant="project-btn ms-2" title="Export">
                      <ExportIocn onClick={handleDownloadExcel} />
                    </Button>
                    <Button variant="project-btn pe-0 ps-1" title="Download">
                      <DownloadIcon onClick={PDFDownload} />
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <Container fluid className="p-0">
          <div className="task-page">
            <Row className="m-0">
              <Col
                // lg={8}
                className="ps-0 pe-xl-4 pe-lg-4 pe-md-0 pe-sm-0 task-left-col "
              >
                {Object.keys(taskData.Data)
                  .sort()
                  .reverse()
                  .map((item, index) => (
                    <div className="table-col task-table-tab" key={index}>
                      <div className="recent-task-section">
                        <div className="d-flex align-items-center justify-content-between table-header-col">
                          <div className="project-title me-3">
                            {commonservices.getDays(
                              commonservices.getDateInDDMMYYYY(
                                taskData.Data[item].Date
                              )
                            )}
                          </div>
                          {/* <div className="total-time">
                                                          {commonservices.ConvertMinutesToTimeFormat(
                                                              taskData.Data[item].TotalTimeInMinites
                                                          )}{" "}
                                                          {commonservices.LabelDisplayText("Hrs")}
                                                      </div> */}
                        </div>
                        <div className="recent-task-item table-section-col">
                          <Table
                            responsive
                            className="table-inner-section mb-0 pb-0"
                          >
                            <tbody>
                              {taskData.Data[item].tasklist.length > 0 &&
                                taskData.Data[item].tasklist.map(
                                  (itemTask, rowindex) => (
                                    <tr>
                                      <td
                                        onClick={(e) => {
                                          setTaskDataForShowDetails({
                                            TaskID: itemTask.TaskID,
                                            ProjectID: itemTask.ProjectID,
                                          });
                                          setModalShowDetails(true);
                                        }}
                                        className="text-nowrap cursorpointer padding-top-bottom width-300px small-screen-width-adjust"
                                      >
                                        <div className="d-flex align-items-center">
                                          <img
                                            className="project-short-img rounded-circle"
                                            // src={itemTask.ProjectLogo}
                                            src={
                                              itemTask.ProjectLogo !== null &&
                                              itemTask.ProjectLogo !== ""
                                                ? itemTask.ProjectLogo
                                                : ProjectPlaceholder
                                            }
                                            alt={commonservices.getInitials(
                                              itemTask.ProjectName
                                            )}
                                            width="44"
                                            height="44"
                                          />
                                          <Link className="content-project-col">
                                            <div className="project-name">
                                              {itemTask.ProjectName}
                                            </div>
                                            <div className="project-description">
                                              {itemTask.Title}
                                            </div>
                                          </Link>
                                        </div>
                                      </td>
                                      <td
                                        onClick={(e) => {
                                          setTaskDataForShowDetails({
                                            TaskID: itemTask.TaskID,
                                            ProjectID: itemTask.ProjectID,
                                          });
                                          setModalShowDetails(true);
                                        }}
                                        className="text-nowrap cursorpointer padding-top-bottom width-200px"
                                      >
                                        <div className="project-details">
                                          <div className="d-flex align-items-center users">
                                            {itemTask.TaskUserList.map(
                                              (useritem, userindex) =>
                                                userindex < 3 && (
                                                  <img
                                                    title={useritem.DisplayName}
                                                    className="project-img"
                                                    // src={useritem.ProfilePic}
                                                    src={
                                                      useritem.ProfilePic !==
                                                        null &&
                                                      useritem.ProfilePic !== ""
                                                        ? useritem.ProfilePic
                                                        : UserPlaceholder
                                                    }
                                                    alt={commonservices.getInitials(
                                                      useritem.DisplayName
                                                    )}
                                                    width={34}
                                                    height={34}
                                                    key={userindex}
                                                  />
                                                )
                                            )}
                                            {itemTask.TaskUserList.length >
                                              3 && (
                                              <div className="add-user task-list">
                                                <span>
                                                  {itemTask.TaskUserList
                                                    .length - 3}
                                                  +
                                                </span>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </td>
                                      <td
                                        onClick={(e) => {
                                          setTaskDataForShowDetails({
                                            TaskID: itemTask.TaskID,
                                            ProjectID: itemTask.ProjectID,
                                          });
                                          setModalShowDetails(true);
                                        }}
                                        className="width-150px cursorpointer"
                                      >
                                        <div className="table-text-common text-nowrap">
                                          <CalendarIcon />
                                          <span>
                                            {commonservices.getDateInDDMMYYYY(
                                              itemTask.StartDate
                                            )}
                                          </span>
                                        </div>
                                      </td>
                                      <td
                                        onClick={(e) => {
                                          setTaskDataForShowDetails({
                                            TaskID: itemTask.TaskID,
                                            ProjectID: itemTask.ProjectID,
                                          });
                                          setModalShowDetails(true);
                                        }}
                                        className="text-start cursorpointer width-150px"
                                      >
                                        <div className="table-text-common text-nowrap">
                                          <TimeQuarterPast />
                                          <span>
                                            {commonservices.getDateInDDMMYYYY(
                                              itemTask.EndDate
                                            )}
                                          </span>
                                        </div>
                                      </td>
                                      <td
                                        onClick={(e) => {
                                          setTaskDataForShowDetails({
                                            TaskID: itemTask.TaskID,
                                            ProjectID: itemTask.ProjectID,
                                          });
                                          setModalShowDetails(true);
                                        }}
                                        className="text-end width-75px cursorpointer"
                                      >
                                        <div>
                                          <img
                                            src={commonservices.GetPriorityIcon(
                                              itemTask.Priority
                                            )}
                                            alt={itemTask.Priority}
                                            title={itemTask.Priority}
                                          />
                                        </div>
                                      </td>
                                      <td className="text-center text-nowrap padding-top-bottom width-200px">
                                        <div className="d-flex align-items-center justify-content-between">
                                          <div className="d-flex align-items-end justify-content-end">
                                            <div className="task-time-status me-3 d-flex align-items-center justify-content-end">
                                              {fnCreateTaskTimeUI(itemTask)}
                                            </div>
                                          </div>
                                          <div className="d-flex align-items-end justify-content-end">
                                            <div className="task-time-status d-flex align-items-center justify-content-end">
                                              {fnCreateTaskButtonUI(itemTask)}
                                            </div>
                                            <div
                                              className="status-icon me-0"
                                              title={itemTask.Status}
                                            >
                                              {commonservices.GetTaskStatusIcon(
                                                itemTask.Status
                                              )}
                                            </div>
                                            {commonservices.getLoginRoleData(
                                              "Task",
                                              "IsDelete"
                                            ) &&
                                              data[0].UserID ===
                                                itemTask.CreatedBy && (
                                                <Button
                                                  className="icon-btn ms-2"
                                                  title="Delete"
                                                  onClick={(e) => {
                                                    confirmAlert({
                                                      message:
                                                        commonservices.LabelDisplayText(
                                                          "AreYouSureToDoDelete"
                                                        ),
                                                      buttons: [
                                                        {
                                                          label:
                                                            commonservices.LabelDisplayText(
                                                              "Yes"
                                                            ),
                                                          onClick: () =>
                                                            deleteData(
                                                              itemTask.TaskID
                                                            ),
                                                        },
                                                        {
                                                          label:
                                                            commonservices.LabelDisplayText(
                                                              "No"
                                                            ),
                                                        },
                                                      ],
                                                    });
                                                  }}
                                                >
                                                  <DeleteIconNew />
                                                </Button>
                                              )}
                                          </div>
                                        </div>
                                      </td>
                                      {/* <td>
                                                                                      {fnCreateTaskTimeUI(itemTask)}
                                                                                  </td>   
                                                                                            
                                                                                  <td className="text-center text-nowrap padding-top-bottom width-200px">
                                                                                      <div className="d-flex align-items-end justify-content-end">
                                                                                          <div className="task-time-status d-flex align-items-center justify-content-end">
                                                                                              {fnCreateTaskButtonUI(itemTask)}
                                                                                          </div>
                                                                                         
                                                                                          
                                                                                      </div>
                                                                                  </td>
                                                                                  <td>
                                                                                  <div
                                                                                              className="status-icon me-0"
                                                                                              title={itemTask.Status}
                                                                                          >
                                                                                              {commonservices.GetTaskStatusIcon(
                                                                                                  itemTask.Status
                                                                                              )}
                                                                                          </div>
                                                                                  </td>    
                                                                                  <td>
                                                                                  {commonservices.getLoginRoleData(
                                                                                              "Task",
                                                                                              "IsDelete"
                                                                                          ) && data[0].UserID === itemTask.CreatedBy && (
                                                                                                  <Button
                                                                                                      className="icon-btn ms-2"
                                                                                                      onClick={(e) => {
                                                                                                          confirmAlert({
                                                                                                              message: commonservices.LabelDisplayText(
                                                                                                                  "AreYouSureToDoDelete"
                                                                                                              ),
                                                                                                              buttons: [
                                                                                                                  {
                                                                                                                      label:
                                                                                                                          commonservices.LabelDisplayText("Yes"),
                                                                                                                      onClick: () => deleteData(itemTask.TaskID),
                                                                                                                  },
                                                                                                                  {
                                                                                                                      label:
                                                                                                                          commonservices.LabelDisplayText("No"),
                                                                                                                  },
                                                                                                              ],
                                                                                                          });
                                                                                                      }}
                                                                                                  >
                                                                                                      <DeleteIconNew />
                                                                                                  </Button>
                                                                                              )}
                                                                                  </td>     */}
                                    </tr>
                                  )
                                )}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  ))}

                {IsTaskLoading && <Loader type={LoaderType.InfScrollLoading} />}
              </Col>
              {/* <Col
                                lg={4}
                                className="pe-0 pe-xl-0 pe-lg-0 pe-md-0 ps-sm-0 task-right-col"
                            >
                                <div className="cards p-0 bg-transparent rounded-0">
                                    {Countlst.IsLoading ? (
                                        <Row>
                                            <Col lg={6} md={6} className="mb-3 ">
                                                <Card
                                                    className={
                                                        "today-task caribbean-green-box square-bg-img"
                                                    }
                                                >
                                                    <Card.Body>
                                                        <Card.Title className="ms-auto me-0">
                                                            ...
                                                        </Card.Title>
                                                        <Card.Text>
                                                            Task
                                                            <span className="d-block">In Process</span>
                                                        </Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                            <Col lg={6} md={6} className="mb-3 ">
                                                <Card
                                                    className={
                                                        "profile-complete pastel-orange hexagon-bg-img"
                                                    }
                                                >
                                                    <Card.Body>
                                                        <Card.Title className="ms-auto me-0">
                                                            ...
                                                        </Card.Title>
                                                        <Card.Text>
                                                            Task
                                                            <span className="d-block">Closed</span>
                                                        </Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                    ) : (
                                        <Row className="m-0">
                                            {Countlst.Data.length > 0 &&
                                                Countlst.Data.map((item, index) => (
                                                    <>
                                                        {item.Status === "In Process" ||
                                                            item.Status === "Closed" ? (
                                                            <>
                                                                <Col
                                                                    lg={6}
                                                                    md={6}
                                                                    className="mb-3 p-0 ps-2 pe-2"
                                                                    key={index}
                                                                >
                                                                    <Card
                                                                        className={
                                                                            index % 2 === 0
                                                                                ? "profile-complete pastel-orange hexagon-bg-img"
                                                                                : "today-task caribbean-green-box square-bg-img"
                                                                        }
                                                                    >
                                                                        <Card.Body>
                                                                            <Card.Title className="ms-auto me-0">
                                                                                {item.TaskCount}
                                                                            </Card.Title>
                                                                            <Card.Text>
                                                                                Task
                                                                                <span className="d-block">
                                                                                    {item.Status}
                                                                                </span>
                                                                            </Card.Text>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </Col>
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </>
                                                ))}
                                        </Row>
                                    )}
                                </div>
                                <div className="tast-chart">
                                    <div className="home-chart">
                                        {WorkingHoursChart.IsLoading ? (
                                            <Loader type={LoaderType.Line} />
                                        ) : WorkingHoursChart.Data.series[0].data.length > 0 ? (
                                            <Chart
                                                type="line"
                                                options={WorkingHoursChart.Data.options}
                                                series={WorkingHoursChart.Data.series}
                                                className="w-100"
                                                height={230}
                                            />
                                        ) : (
                                            <Loader type={LoaderType.NODATAFOUND} />
                                        )}
                                    </div>
                                </div>
                            </Col> */}
            </Row>
          </div>
        </Container>
      </div>

      {modalShowDetails && (
        <TaskDetail
          details={TaskDataForShowDetails}
          show={modalShowDetails}
          bindList={bindList}
          onHide={() => setModalShowDetails(false)}
        />
      )}

      {modalTaskAdd && (
        <AddTaskModal
          // id={TASKID}
          projectid={parseInt(param.id)}
          show={modalTaskAdd}
          bindList={bindList}
          onHide={() => setModalTaskAdd(false)}
        />
      )}
    </>
  );
}

export default Task;
