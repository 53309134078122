import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Row,
  Tabs,
  Tab,
  Table,
  Form,
  FloatingLabel,
  Modal,
  Nav,
} from "react-bootstrap";
import ProjectPlaceholder from "../../assets/Images/ProjectPlaceholder.png";
import UserPlaceholder from "../../assets/Icons/placeholder.svg";
import {
  Link,
  NavLink,
  useNavigate,
  createSearchParams,
} from "react-router-dom";
import AddProject from "../../Modal/AddProject";
import { commonservices, AppConfigData } from "../../Services/commonService";
import axios from "axios";
import { toast } from "react-toastify";
import $ from "jquery";
import Loader, { LoaderType } from "../../Loader";
import { confirmAlert } from "react-confirm-alert";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import ProgressBar from "react-bootstrap/ProgressBar";
import { ReactComponent as CalendarIcon } from "../../assets/Icons/calendar-icon.svg";

function Project() {
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [NoDataMsg, setNoDataMsg] = useState("");
  const [projectUser, setProjectUser] = useState(false);
  const [projectData, setProjectData] = useState({
    Data: [],
    IsLoading: false,
  });

  const [searchobj, setSearchobj] = useState({
    projectName: "",
    startDate: null,
    endDate: null,
    status: "",
    technologies: "",
    searchText: "",
    ProfilePicFile: "",
  });

  function bindList() {
    setProjectData({
      Data: [],
      IsLoading: true,
    });
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(
        `${AppConfigData.APIBasePath}v1/project/getlist`,
        searchobj,
        axiosConfig
      )
      .then((res) => {
        if (res.data.status_code === 0) {
          setProjectData({ Data: [], IsLoading: false });
        } else if (res.data.status_code === 1) {
          setProjectUser(res.data.data.projectUser);
          setProjectData({ Data: res.data.data.projectList, IsLoading: false });
        } else if (res.data.status_code === 2) {
          commonservices.ClearLoginDetails();
          navigate("/");
        } else {
          setProjectData({ Data: [], IsLoading: false });
        }
      })
      .catch((err) => {
        setProjectData({ Data: [], IsLoading: false });
      });
  }

  function deleteData(id) {
    let input = {
      id: id,
      requestFrom: "string",
    };
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(`${AppConfigData.APIBasePath}v1/project/delete`, input, axiosConfig)
      .then((res) => {
        if (res.data.status_code === 0) {
          toast.error(res.data.message);
        } else if (res.data.status_code === 1) {
          toast.success(res.data.message);
          bindList();
        } else if (res.data.status_code === 2) {
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {});
  }

  function TaskbindList() {
    // settaskData({
    //     Data: [],
    //     IsLoading: true,
    // });
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(
        `${AppConfigData.APIBasePath}v1/task/getlistwithtimedata`,
        {
          title: null,
          status: "In Process,In Testing,Closed",
          priority: null,
          startDate: commonservices.getDateInDBFormat(
            new Date().setDate(new Date().getDate() - 7)
          ),
          endDate: commonservices.getDateInDBFormat(new Date()),
          projectID: null,
          userID: null,
          associationID: null,
          associationType: null,
        },
        axiosConfig
      )
      .then((res) => {
        if (res.data.status_code === 0) {
          // settaskData({ Data: [], IsLoading: false })
        } else if (res.data.status_code === 1) {
          const groups = res.data.data.taskList.reduce((groups, task) => {
            const date = task.DateRang;
            if (!groups[date]) {
              groups[date] = {
                Date: date,
                tasklist: [],
                TotalTimeInMinites: 0,
              };
            }
            task.TaskUserList = res.data.data.taskUserList.filter(
              (U) => U.TaskID == task.TaskID
            );
            task.RunningTaskList = res.data.data.runningTaskList.filter(
              (R) => R.TaskID == task.TaskID && R.TimerDate == task.DateRang
            );

            groups[date].tasklist.push(task);
            groups[date].TotalTimeInMinites =
              groups[date].TotalTimeInMinites + task.TotalTimeInMinites;

            return groups;
          }, {});

          // settaskData({ Data: groups, IsLoading: false });
        } else if (res.data.status_code === 2) {
          commonservices.ClearLoginDetails();
          navigate("/");
        } else {
        }
      })
      .catch((err) => {
        // settaskData({
        //     Data: [],
        //     IsLoading: false,
        // });
      });
  }

  useEffect(() => {
    bindList();
    TaskbindList();
  }, []);

  // For Datatable
  $(document).ready(function () {
    $("table#tblData").DataTable({
      destroy: true,
      dom: "ft<'tbl-info'<'length-info'li<'paggination'p>>>",
      aoColumnDefs: [
        {
          bSortable: false,
          aTargets: [-1] /* 1st one, start by the right */,
        },
      ],
    });
  });
  useEffect(() => {
    $(document).ready(function () {
      $("table#tblData")
        .DataTable({
          destroy: true,
          dom: "ft<'tbl-info'<'length-info'li<'paggination'p>>>",
          aoColumnDefs: [
            {
              bSortable: false,
              aTargets: [-1] /* 1st one, start by the right */,
            },
          ],
        })
        .destroy();
    });
  }, []);

  return (
    <>
      <Container fluid className="ps-0 pe-0">
        <Tab.Container id="left-tabs-example" defaultActiveKey="active">
          <div className="inner-page-top-col">
            <Row>
              <Col xl={4} lg={6} md={6} className="projectsecadmin">
                <div className="inner-page-title">
                  {commonservices.LabelDisplayText("Projects")}
                </div>
              </Col>
              <Col xl={4} lg={12} md={12} className="text-center projectsectab">
                <Nav variant="pills custom-tabs d-inline-flex">
                  <Nav.Item>
                    <Nav.Link eventKey="active">Active</Nav.Link>
                  </Nav.Item>
                  {/*<Nav.Item>*/}
                  {/*    <Nav.Link eventKey="archive">Archive</Nav.Link>*/}
                  {/*</Nav.Item>*/}
                  {/*<Nav.Item>*/}
                  {/*    <Nav.Link eventKey="deleted">Deleted</Nav.Link>*/}
                  {/*</Nav.Item>*/}
                </Nav>
              </Col>
              <Col xl={4} lg={6} md={6} className="text-center projectsecserch">
                <div className="d-flex align-items-center">
                  <div className="inner-search-bar w-100">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search anything..."
                      name="searchData"
                      onChange={(e) =>
                        setSearchobj({
                          ...searchobj,
                          ["searchText"]: e.target.value,
                        })
                      }
                      onKeyUp={(e) => e.keyCode === 13 && bindList()}
                    />
                    <Button
                      variant="btn btn-icon-btn-lg search-button"
                      onClick={(e) => bindList()}
                      title="Search"
                    >
                      <i className="fa-light fa-magnifying-glass"></i>
                    </Button>
                  </div>
                  {commonservices.getLoginRoleData("Project", "IsCreate") && (
                    <div className="project-add-icon">
                      <Button
                        className="project-btn"
                        title="Add"
                        onClick={(e) => setModalShow(true)}
                      >
                        <i className="fa-regular fa-plus"></i>
                      </Button>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </div>
          <Row>
            <Col>
              <Tab.Content>
                <Tab.Pane eventKey="active">
                  <Row className="project-cards projects-cards">
                    {projectData.IsLoading ? (
                      <>
                        <Loader type={LoaderType.PROJECTLIST} count={3} />
                      </>
                    ) : (
                      <>
                        {projectData.Data.length > 0 ? (
                          projectData.Data.map((item, index) => (
                            <>
                              <Col className="mb-3 col-xxl-4 col-xl-6 col-lg-6 col-md-12">
                                <Card className="card-white-bg">
                                  <Link
                                    key={index}
                                    className={"remove-hover"}
                                    to={{
                                      pathname:
                                        "/project-details/" + item.ProjectID,
                                    }}
                                  >
                                    <Card.Body>
                                      <div className="d-flex justify-content-between w-100">
                                        <div className="d-flex w-100">
                                          <Link
                                            className={"remove-hover"}
                                            to={{
                                              pathname:
                                                "/project-details/" +
                                                item.ProjectID,
                                            }}
                                          >
                                            <img
                                              className="card-img-center rounded-circle"
                                              width="80"
                                              height="80"
                                              src={item.ProjectLogo !== null && item.ProjectLogo !== '' ? item.ProjectLogo : ProjectPlaceholder}
                                              alt={commonservices.getInitials(
                                                item.ProjectName
                                              )}
                                            />
                                          </Link>
                                          <Link
                                            className={"remove-hover w-100"}
                                            to={{
                                              pathname:
                                                "/project-details/" +
                                                item.ProjectID,
                                            }}
                                          >
                                            <div className="project-card-content-right">
                                              <Card.Title>
                                                {item.ProjectName}
                                              </Card.Title>
                                              <div className="task-date">
                                                <div className="d-flex align-items-center">
                                                  <CalendarIcon />
                                                  <div className="ms-2">
                                                    {commonservices.getDays(
                                                      commonservices.getDateInDDMMYYYY(
                                                        item.StartDate
                                                      )
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="project-cards-labels">
                                                {item.Technologies !== null &&
                                                item.Technologies !== "" ? (
                                                  item.Technologies.split(
                                                    ","
                                                  ).map(
                                                    (item, index) =>
                                                      item !== "" && (
                                                        <label
                                                          key={index}
                                                          className=""
                                                        >
                                                          {item}
                                                        </label>
                                                      )
                                                  )
                                                ) : (
                                                  <label className="d-none"></label>
                                                )}
                                              </div>
                                            </div>
                                          </Link>
                                        </div>
                                      </div>
                                      <Link
                                        className={"remove-hover"}
                                        to={{
                                          pathname:
                                            "/project-details/" +
                                            item.ProjectID,
                                        }}
                                      >
                                        <div className="mt-3 mb-3">
                                          <div className="ProgressBar-content mb-1 d-flex align-items-center justify-content-between">
                                            <div className="ProgressBar-text">
                                              {commonservices.LabelDisplayText(
                                                "Progress"
                                              )}
                                            </div>
                                            <div className="ProgressBar-percentage">
                                              {item.AllTaskCount !== 0
                                                ? (
                                                    (item.ClosedTaskCount *
                                                      100) /
                                                    item.AllTaskCount
                                                  ).toFixed(2)
                                                : "0.00"}
                                              %
                                            </div>
                                          </div>
                                          <ProgressBar
                                            now={
                                              item.AllTaskCount !== 0
                                                ? (
                                                    (item.ClosedTaskCount *
                                                      100) /
                                                    item.AllTaskCount
                                                  ).toFixed(2)
                                                : item.AllTaskCount
                                            }
                                          />
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center">
                                          <div className="d-flex justify-content-between align-items-center me-3">
                                            <div>
                                              <label
                                                className={commonservices.StatusLables(
                                                  item.Status
                                                )}
                                              >
                                                {item.Status}
                                              </label>
                                            </div>
                                            <div className="task-label">
                                              <i className="fa-solid fa-list-check"></i>
                                              <span>
                                                {item.AllTaskCount}{" "}
                                                {commonservices.LabelDisplayText(
                                                  "Task"
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                          <div className="project-details">
                                            <div className="users">
                                              {projectUser.length > 0 &&
                                                projectUser
                                                  .filter(
                                                    (P) =>
                                                      P.ProjectID ==
                                                      item.ProjectID
                                                  )
                                                  .map(
                                                    (useritem, index) =>
                                                      index < 3 && (
                                                        <>
                                                          <img
                                                            className="project-img mt-0 mb-0 rounded-circle"
                                                            // src={
                                                            //   useritem.ProfilePic
                                                            // }
                                                            src={
                                                              useritem.ProfilePic !==
                                                                null &&
                                                              useritem.ProfilePic !==
                                                                ""
                                                                ? useritem.ProfilePic
                                                                : UserPlaceholder
                                                            }
                                                            alt={commonservices.getInitials(
                                                              useritem.DisplayName
                                                            )}
                                                            width={50}
                                                            height={50}
                                                            key={index}
                                                          />
                                                        </>
                                                      )
                                                  )}
                                              {projectUser.filter(
                                                (P) =>
                                                  P.ProjectID == item.ProjectID
                                              ).length > 3 && (
                                                <div className="add-user">
                                                  <span>
                                                    {projectUser.filter(
                                                      (P) =>
                                                        P.ProjectID ==
                                                        item.ProjectID
                                                    ).length - 3}
                                                    +
                                                  </span>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </Link>
                                    </Card.Body>
                                  </Link>
                                  {commonservices.getLoginRoleData(
                                    "Project",
                                    "IsDelete"
                                  ) && (
                                    <div className="projects-cards-dropdown mb-auto ms-2">
                                      <DropdownButton
                                        title=""
                                        align="end"
                                        id="dropdown-menu-align-responsive-1"
                                        variant="ellipsis-btn p-0"
                                      >
                                        {/*<Dropdown.Item>*/}
                                        {/*    <i className="fa-regular fa-box-archive me-2"></i>{" "}*/}
                                        {/*    Archive*/}
                                        {/*</Dropdown.Item>*/}
                                        {/* {
                                                                                commonservices.getLoginRoleData("Project", "IsDelete") && */}
                                        <Dropdown.Item
                                          onClick={(e) => {
                                            confirmAlert({
                                              message:
                                                commonservices.LabelDisplayText(
                                                  "AreYouSureToDoDelete"
                                                ),
                                              buttons: [
                                                {
                                                  label:
                                                    commonservices.LabelDisplayText(
                                                      "Yes"
                                                    ),
                                                  onClick: () =>
                                                    deleteData(item.ProjectID),
                                                },
                                                {
                                                  label:
                                                    commonservices.LabelDisplayText(
                                                      "No"
                                                    ),
                                                },
                                              ],
                                            });
                                          }}
                                        >
                                          <i className="fa-regular fa-trash-can me-2"></i>{" "}
                                          Delete
                                        </Dropdown.Item>
                                        {/* } */}
                                      </DropdownButton>
                                    </div>
                                  )}
                                </Card>
                              </Col>
                            </>
                          ))
                        ) : (
                          <>
                            <Loader type={LoaderType.NODATAFOUND} />
                          </>
                        )}
                      </>
                    )}
                  </Row>
                </Tab.Pane>
                {/*<Tab.Pane eventKey="archive">Functionality coming soon</Tab.Pane>*/}
                {/*<Tab.Pane eventKey="deleted">Functionality coming soon</Tab.Pane>*/}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
      {modalShow && (
        <AddProject
          show={modalShow}
          bindList={bindList}
          onHide={() => setModalShow(false)}
        />
      )}
    </>
  );
}

export default Project;
