import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { default as React, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Modal, Button, Container, Row, Table } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";
import { AppConfigData, commonservices } from "../Services/commonService";
import * as yup from "yup";
import $ from "jquery";

const AddleaveModal = (props) => {
    let data = commonservices.getLoginUserData();
    const [optionLeave, setOptionLeave] = useState([]);
    const [Submitting, setSubmitting] = useState(false);
    const [IsValidationMessageSet, setIsValidationMessageSet] = useState("");
    const formFieldLength = {
        Title: 267,
    };

    const formInitialSchema = {
        LeaveID: "",
        StartDate: commonservices.getDateInDBFormat(new Date()),
        EndDate: commonservices.getDateInDBFormat(new Date()),
        Title: "",
        Details: "",
        LeaveType: "",
    };
    let TodayDate = new Date();
    TodayDate = TodayDate.setDate(TodayDate.getDate() - 1);
    const formValidation = yup.object().shape({
        LeaveID: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "LeaveID")),
        StartDate: yup
            .date()
            .required(commonservices.MakeValidationMessage("required", "StartDate"))
            .min(new Date(TodayDate), commonservices.MakeValidationMessage("custom", "StartDate", commonservices.CustomValidationMessage("ValidationDateCanBeGreaterThanOrEqualTodayDate"))),
        EndDate: yup
            .date()
            .required(commonservices.MakeValidationMessage("required", "EndDate"))
            .min(new Date(TodayDate), commonservices.MakeValidationMessage("custom", "EndDate", commonservices.CustomValidationMessage("ValidationDateCanBeGreaterThanOrEqualTodayDate")))
            .test("type", commonservices.MakeValidationMessage("custom", "EndDate", commonservices.CustomValidationMessage("ValidationData1CannotBeLessThanDate2").replace("{0}", $("[lbl-for='EndDate']").text().trim()).replace("{1}", $("[lbl-for='StartDate']").text().trim())), (value, obj) => {
                if (
                    document.getElementsByName("StartDate") !== null &&
                    document.getElementsByName("StartDate") !== "" &&
                    document.getElementsByName("StartDate") !== undefined &&
                    document.getElementsByName("StartDate")[0].value !== null &&
                    document.getElementsByName("StartDate")[0].value !== "" &&
                    document.getElementsByName("StartDate")[0].value !== undefined
                ) {
                    return (
                        new Date(document.getElementsByName("EndDate")[0].value) >=
                        new Date(document.getElementsByName("StartDate")[0].value)
                    );
                } else {
                    return true;
                }
            })
            .test(
                "type",
                commonservices.MakeValidationMessage("custom", "EndDate", commonservices.CustomValidationMessage("ValidationField1MustBeSameField1").replace("{0}", $("[lbl-for='StartDate']").text().trim()).replace("{1}", $("[lbl-for='EndDate']").text().trim())),
                (value, obj) => {
                    if (
                        document.getElementsByName("StartDate") !== null &&
                        document.getElementsByName("StartDate") !== "" &&
                        document.getElementsByName("StartDate") !== undefined &&
                        document.getElementsByName("StartDate")[0].value !== null &&
                        document.getElementsByName("StartDate")[0].value !== "" &&
                        document.getElementsByName("StartDate")[0].value !== undefined &&
                        document.getElementsByName("LeaveType") !== null &&
                        document.getElementsByName("LeaveType") !== "" &&
                        document.getElementsByName("LeaveType") !== undefined &&
                        document.getElementsByName("LeaveType")[0].value !== null &&
                        document.getElementsByName("LeaveType")[0].value !== "" &&
                        document.getElementsByName("LeaveType")[0].value !== undefined &&
                        (
                            document.getElementsByName("LeaveType")[0].value.toUpperCase() ===
                            "SECOND HALF" || document.getElementsByName("LeaveType")[0].value.toUpperCase() ===
                            "FIRST HALF"
                        )
                    ) {
                        return (
                            document.getElementsByName("EndDate")[0].value ===
                            document.getElementsByName("StartDate")[0].value
                        );
                    } else {
                        return true;
                    }
                }
            ),
        Title: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "Title"))
            .test('len', commonservices.MakeValidationMessage("maxlength", ""), (value, obj) => { return value ? value.length <= formFieldLength.Title : true }),
        Details: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "Details")),
        LeaveType: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "LeaveType")),
    });

    useEffect(() => {
        const axiosConfig = {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        };
        axios
            .post(
                `${AppConfigData.APIBasePath}v1/leavemaster/getemployeeleavelist`,
                {},
                axiosConfig
            )
            .then((res) => {
                if (res.data.status_code === 0) {
                } else if (res.data.status_code === 1) {
                    setOptionLeave(res.data.data);
                    
                } else if (res.data.status_code === 2) {
                } else {
                }
            })
            .catch((err) => { });
    }, []);
    useEffect(() => {
        setIsValidationMessageSet(true);
    });
    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                className="right-modal modal-new modal-apply-leave"
                scrollable={true}
                backdrop="static"
                keyboard={false}
            >
                <Formik
                    initialValues={formInitialSchema}
                    validationSchema={formValidation}
                    validateOnBlur={false}
                    onSubmit={async (values) => {
                        setSubmitting(true);
                        const FD = new FormData();
                        FD.append("LeaveID", values.LeaveID);
                        FD.append("StartDate", values.StartDate);
                        FD.append("EndDate", values.EndDate);
                        FD.append("Title", values.Title);
                        FD.append("Details", values.Details);
                        FD.append("LeaveType", values.LeaveType);

                        const axiosConfig = {
                            headers: {
                                "Content-Type": "multipart/form-data",
                                Authorization: "Bearer " + localStorage.getItem("token"),
                            },
                        };
                        const response = await axios
                            .post(
                                `${AppConfigData.APIBasePath}v1/leaveapplication/save`,
                                FD,
                                axiosConfig
                            )
                            .then((response) => {
                                setSubmitting(false);
                                if (response.data.status_code === 1) {
                                    toast.success(response.data.message);
                                    let PushNotificationData = [];
                                    response.data.data.user.map((item) => {
                                        if (data[0].UserID !== item.UserID) {
                                            PushNotificationData.push({
                                                UserID: item.UserID,
                                                Message: values.Title,
                                                Title: data[0].DisplayName + " Applied For Leave",
                                            });
                                        }
                                    });
                                    commonservices.SendPushNotification(PushNotificationData);
                                    props.bindList();
                                    props.onHide();
                                } else if (response.data.status_code === 2) {
                                    toast.error(response.data.message);
                                } else if (response.data.status_code === 0) {
                                    toast.error(response.data.message);
                                } else {
                                    toast.error(response.data.message);
                                }
                            })
                            .catch((error) => { });
                    }}
                >
                    <Form>
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                Apply leave
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Container>
                                <Row>
                                    <Col xs={12} className="mb-3">
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Field
                                                    type="text"
                                                    name="Title"
                                                    className="form-control"
                                                    placeholder="Subject"
                                                    maxLength={formFieldLength.Title}
                                                />
                                                <label lbl-for="Title">Subject</label>
                                                <div className="text-danger-error">
                                                    <ErrorMessage name="Title" />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={6} className="mb-3">
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Field
                                                    as="select"
                                                    name="LeaveID"
                                                    className="form-control down-arrow-icon"
                                                >
                                                    <option value="">
                                                        {commonservices.LabelDisplayText("Select")}
                                                    </option>
                                                    {optionLeave.length > 0 &&
                                                        optionLeave.map((item, index) => (
                                                            <option key={index} value={item.LeaveID}>
                                                                {item.LeaveCode +
                                                                    " - " +
                                                                    item.LeaveName +
                                                                    " (" +
                                                                    item.TotalLeave +
                                                                    ")"}
                                                            </option>
                                                        ))}
                                                </Field>
                                                <label lbl-for="LeaveID">Leave</label>
                                                <div className="text-danger-error">
                                                    <ErrorMessage name="LeaveID" />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={6} className="mb-3">
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Field
                                                    as="select"
                                                    name="LeaveType"
                                                    className="form-control down-arrow-icon"
                                                >
                                                    <option value="">
                                                        {commonservices.LabelDisplayText("Select")}
                                                    </option>
                                                    <option value={"FULL DAY"}>Full Day</option>
                                                    <option value={"FIRST HALF"}>First Half</option>
                                                    <option value={"SECOND HALF"}>Second Half</option>
                                                </Field>
                                                <label lbl-for="LeaveType">Leave Type</label>
                                                <div className="text-danger-error">
                                                    <ErrorMessage name="LeaveType" />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={6} className="mb-3">
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Field
                                                    type="date"
                                                    name="StartDate"
                                                    className="form-control calendar-icon"
                                                />
                                                <label lbl-for="StartDate">{commonservices.LabelDisplayText("From")}</label>
                                                <div className="text-danger-error">
                                                    <ErrorMessage name="StartDate" />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={6} className="mb-3">
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Field
                                                    type="date"
                                                    name="EndDate"
                                                    className="form-control calendar-icon"
                                                />
                                                <label lbl-for="EndDate">{commonservices.LabelDisplayText("To")}</label>
                                                <div className="text-danger-error">
                                                    <ErrorMessage name="EndDate" />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="form-group">
                                            <div className="form-floating ">
                                                <Field
                                                    as="textarea"
                                                    name="Details"
                                                    rows="5"
                                                    className="form-control"
                                                    placeholder={commonservices.LabelDisplayText(
                                                        "Details"
                                                    )}
                                                />
                                                <label lbl-for="Details">
                                                    {commonservices.LabelDisplayText("Details")}
                                                </label>
                                                <div className="text-danger-error">
                                                    <ErrorMessage name="Details" />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="Cancel-btn" onClick={props.onHide} title="Cancel">
                                {commonservices.LabelDisplayText("Cancel")}
                            </Button>
                            <Button
                                className="btn btn-primary save-btn"
                                type="submit"
                                title="Submit"
                                disabled={Submitting}
                            >
                                {Submitting && (
                                    <>
                                        <Spinner animation="border" />
                                    </>
                                )}
                                {commonservices.LabelDisplayText("Save")}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Formik>
            </Modal>
        </>
    );
};

export default AddleaveModal;
