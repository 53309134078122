import axios from "axios";
import { useState, useEffect } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { default as React } from "react";
import { toast } from "react-toastify";
import * as yup from "yup";
import { commonservices, AppConfigData } from "../Services/commonService";
import { confirmAlert } from "react-confirm-alert";
import ProjectPlaceholder from "../assets/Images/ProjectPlaceholder.png";
import UserPlaceholder from "../assets/Icons/placeholder.svg";
import { Col, Modal, Button, Container, Row, Table } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import Loader, { LoaderType } from "../Loader";
const LeaveManualCreditProcess = (props) => {
    const navigate = useNavigate();
    const [Submitting, setSubmitting] = useState(false);
    const [Leave, setLeave] = useState({
        Data: [], IsLoading: true
    });
    const [Employee, setEmployee] = useState([]);
    const [IsValidationMessageSet, setIsValidationMessageSet] = useState("");

    useEffect(() => {
        setIsValidationMessageSet(true);
    });
    useEffect(() => {
        if (props.id > 0) {
            setLeave({
                Data: [],
                IsLoading: true,
            });
            const axiosConfig = {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            };
            axios
                .post(
                    `${AppConfigData.APIBasePath}v1/leavemaster/getforleavecreditprocess`,
                    {
                        id: props.id,
                        requestFrom: "Web",
                    },
                    axiosConfig
                )
                .then((res) => {
                    if (res.data.status_code === 1) {
                        setLeave({ Data: [res.data.data.leave], IsLoading: false });
                        setEmployee(res.data.data.employee)
                    }
                    else if (res.data.status_code === 0) {
                        setLeave({ Data: [], IsLoading: false });

                    } else if (res.data.status_code === 2) {
                        commonservices.ClearLoginDetails();
                        navigate("/");

                    }
                })
                .catch((err) => { setLeave({ Data: [], IsLoading: false }); });
        }
    }, []);

    function fnGoForProcess(LeaveMasterID) {
        setSubmitting(true);
        const axiosConfig = {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        };
        axios
            .post(
                `${AppConfigData.APIBasePath}v1/leavemaster/leavecreditprocess`,
                {
                    id: LeaveMasterID,
                    requestFrom: "Web",
                },
                axiosConfig
            )
            .then((response) => {
                setSubmitting(false);
                if (response.data.status_code === 1) {
                    toast.success(response.data.message);
                    props.onHide();
                }
                else if (response.data.status_code === 0) {
                    toast.error(response.data.message);
                } else if (response.data.status_code === 2) {
                    toast.error(response.data.message);
                    commonservices.ClearLoginDetails();
                    navigate("/");
                }
            })
            .catch((err) => { });
    }

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                className="right-modal modal-new clint-modal"
                scrollable={true}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Leave Credit Process
                    </Modal.Title>
                </Modal.Header>
                {
                    Leave.IsLoading ?
                        <Modal.Body><Container><Loader type={LoaderType.TaskDetails} count={1} /></Container></Modal.Body>
                        :
                        <>
                            {
                                Leave.Data.length == 0 ?
                                    <Modal.Body><Container><Loader type={LoaderType.NODATAFOUND} /></Container></Modal.Body>
                                    :
                                    <>
                                        <Modal.Body>
                                            <Container>
                                                <Col className="">
                                                    <div className="inner-header">
                                                        {commonservices.LabelDisplayText("Details")}
                                                    </div>
                                                    <div className="tab-inner-box">
                                                        <Row>
                                                            <Col className="mb-2 col-lg-12 col-md-12">
                                                                <Row>
                                                                    <Col className="col-xl-2">
                                                                        <div className="tab-inner-box-left">
                                                                            Name
                                                                        </div>
                                                                    </Col>
                                                                    <Col className="col-xl-10">
                                                                        <div className="tab-inner-box-right">
                                                                            {Leave.Data[0].LeaveName}
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col className="mb-2 col-lg-6 col-md-12">
                                                                <Row>
                                                                    <Col className="col-xl-4">
                                                                        <div className="tab-inner-box-left">
                                                                            Code
                                                                        </div>
                                                                    </Col>
                                                                    <Col className="col-xl-8">
                                                                        <div className="tab-inner-box-right">
                                                                            {Leave.Data[0].LeaveCode}
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col className="mb-2 col-lg-6 col-md-12">
                                                                <Row>
                                                                    <Col className="col-xl-4">
                                                                        <div className="tab-inner-box-left">
                                                                            Is Paid Leave ?
                                                                        </div>
                                                                    </Col>
                                                                    <Col className="col-xl-8">
                                                                        <div className="tab-inner-box-right">
                                                                            {Leave.Data[0].IsPaidLeave === 1 ? "Yes" : "No"}
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>
                                                <Col className="">
                                                    <div className="inner-header">
                                                        Credit Rule (Credit : {Leave.Data[0].CreditLimit} Leave) {Leave.Data[0].IsAbleToCredit === 1 ? <span className="text-success">(Able To Process)</span> : <span className="text-danger">(Not Able To Process)</span>}
                                                    </div>
                                                    <div className="tab-inner-box">
                                                        <Row>
                                                            <Col className="mb-2 col-lg-6 col-md-12">
                                                                <Row>
                                                                    <Col className="col-xl-4">
                                                                        <div className="tab-inner-box-left">
                                                                            Last Apply On
                                                                        </div>
                                                                    </Col>
                                                                    <Col className="col-xl-8">
                                                                        <div className="tab-inner-box-right">
                                                                            {commonservices.getDateInFormat(Leave.Data[0].LastCreditRuleApplyOn)}
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col className="mb-2 col-lg-6 col-md-12">
                                                                <Row>
                                                                    <Col className="col-xl-4">
                                                                        <div className="tab-inner-box-left">
                                                                            Next Apply On
                                                                        </div>
                                                                    </Col>
                                                                    <Col className="col-xl-8">
                                                                        <div className="tab-inner-box-right">
                                                                            {commonservices.getDateInFormat(Leave.Data[0].NextCreditRuleApplyOn)}
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>

                                                <Col className="">
                                                    <div className="inner-header">
                                                        Carry Forward (Max Limit : {Leave.Data[0].MaxCarryForwordLimit} Leave) {Leave.Data[0].IsAbleToCarryForward === 1 ? <span className="text-success">(Able To Process)</span> : <span className="text-danger">(Not Able To Process)</span>}
                                                    </div>
                                                    <div className="tab-inner-box">
                                                        <Row>
                                                            <Col className="mb-2 col-lg-6 col-md-12">
                                                                <Row>
                                                                    <Col className="col-xl-4">
                                                                        <div className="tab-inner-box-left">
                                                                            Last Apply On
                                                                        </div>
                                                                    </Col>
                                                                    <Col className="col-xl-8">
                                                                        <div className="tab-inner-box-right">
                                                                            {commonservices.getDateInFormat(Leave.Data[0].LastCarryForwardOn)}
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col className="mb-2 col-lg-6 col-md-12">
                                                                <Row>
                                                                    <Col className="col-xl-4">
                                                                        <div className="tab-inner-box-left">
                                                                            Next Apply On
                                                                        </div>
                                                                    </Col>
                                                                    <Col className="col-xl-8">
                                                                        <div className="tab-inner-box-right">
                                                                            {commonservices.getDateInFormat(Leave.Data[0].NextCarryForwardOn)}
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>

                                                <Col className="">
                                                    <div className="inner-header">
                                                        Employees
                                                    </div>
                                                    <div className="tab-inner-box">
                                                        <Table
                                                            id="tblData"
                                                            className="table table-inner-section"
                                                        >
                                                            <thead>
                                                                <tr>
                                                                    <th>
                                                                        {commonservices.LabelDisplayText("Name")}
                                                                    </th>
                                                                    <th className="width-100px text-center">
                                                                        Balance
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="table-section-col">
                                                                {Employee.length > 0 &&
                                                                    Employee.map((item, index) => (
                                                                        <tr key={index}>
                                                                            <td className="padding-top-bottom text-nowrap">
                                                                                <div className="d-flex align-items-center">
                                                                                    <img
                                                                                        className="rounded-circle"
                                                                                        // src={item.ProfilePic}
                                                                                        src={
                                                                                            item.ProfilePic !==
                                                                                              null &&
                                                                                            item.ProfilePic !==
                                                                                              ""
                                                                                              ? item.ProfilePic
                                                                                              : UserPlaceholder
                                                                                          }
                                                                                        alt={commonservices.getInitials(item.EmployeeName)}
                                                                                        width="34"
                                                                                        height="34"
                                                                                    />
                                                                                    <div className="username project-description ms-2">
                                                                                        {item.EmployeeName}
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div className="text-center table-text-common">{item.TotalLeave}</div>
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </Col>
                                            </Container>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button onClick={props.onHide} className="Cancel-btn">
                                                {commonservices.LabelDisplayText("Cancel")}
                                            </Button>
                                            {
                                                (Leave.Data[0].IsAbleToCarryForward === 1 || Leave.Data[0].IsAbleToCredit === 1) &&
                                                <>
                                                    {
                                                        Submitting ?
                                                            <Button
                                                                className="btn btn-primary save-btn"
                                                                type="submit"
                                                                title="Save"
                                                                disabled={Submitting}
                                                            >
                                                                {Submitting && (
                                                                    <>
                                                                        <Spinner animation="border" />
                                                                    </>
                                                                )}
                                                                {commonservices.LabelDisplayText("GoForProcess")}
                                                            </Button>
                                                            :
                                                            <Button
                                                                className="btn btn-primary save-btn"
                                                                type="submit"
                                                                title="Save"
                                                                disabled={Submitting}
                                                                onClick={(e) => fnGoForProcess(Leave.Data[0].LeaveMasterID)}
                                                            >
                                                                {commonservices.LabelDisplayText("GoForProcess")}
                                                            </Button>

                                                    }
                                                </>
                                            }

                                        </Modal.Footer>
                                    </>
                            }
                        </>
                }

            </Modal>
        </>
    );
};

export default LeaveManualCreditProcess;
