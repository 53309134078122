import React, { useEffect, useState } from "react";
import {
    Col,
    Container,
    Row,
    Table,
    Button
} from "react-bootstrap";
import { ReactComponent as FilterIcon } from "../../assets/Icons/filter-icon.svg";
import $ from "jquery";
import axios from "axios";
import { commonservices, AppConfigData } from "../../Services/commonService";
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import Loader, { LoaderType } from "../../Loader";
import ApproveRejectLeaveModal from "../../Modal/ApproveRejectLeaveModal";
import AddLeaveTransactionModal from "../../Modal/AddLeaveTransactionModal";

function LeaveTransaction() {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [leaveData, setleaveData] = useState({ Data: [], IsLoading: true });
    let Next7DayDate = new Date();
    Next7DayDate = new Date(Next7DayDate.setDate(Next7DayDate.getDate() + 7));
    let Past7DayDate = new Date();
    Past7DayDate = new Date(Past7DayDate.setDate(Past7DayDate.getDate() - 7));

    const [IsShowApproveRejectLeaveModal, setIsShowApproveRejectLeaveModal] = useState(false);
    const [LeaveApplicationID, setLeaveApplicationID] = useState(0);
    const [IsShowAddLeaveTransactionModal, setIsShowAddLeaveTransactionModal] = useState(false);

    const [searchobj, setSearchobj] = useState({
        "EmployeeID": null,
        "TransactionType": null,
        "IsTransaction": null,
        "TransactionStartDate": commonservices.getDateInDBFormat(Past7DayDate),
        "TransactionEndDate": commonservices.getDateInDBFormat(Next7DayDate),
        "SearchText": null
    });

    function bindList(objSearchPara) {
        setIsOpen(false);
        if (objSearchPara === null || objSearchPara === undefined) {
            objSearchPara = searchobj;
        }
        else {
            setSearchobj(objSearchPara);
        }
        setleaveData({
            Data: [],
            IsLoading: true,
        });
        const axiosConfig = {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        };
        axios
            .post(
                `${AppConfigData.APIBasePath}v1/leavetransaction/getlist`,
                objSearchPara,
                axiosConfig
            )
            .then((res) => {

                if (res.data.status_code === 0) {
                    setleaveData({
                        Data: [],
                        IsLoading: false,
                    });
                } else if (res.data.status_code === 1) {
                    setleaveData({ Data: res.data.data, IsLoading: false });
                } else if (res.data.status_code === 2) {
                    commonservices.ClearLoginDetails();
                    navigate("/");
                }
                BindDataTables("table#LeavetblData");
            })
            .catch((err) => { });
    }

    useEffect(() => {
        BindDataTables("table#LeavetblData");
        bindList();
    }, []);

    function BindDataTables(selecter) {
        $(document).ready(function () {
            $(selecter)
                .DataTable({
                    destroy: true,
                    searching: false,
                    dom: "ft<'tbl-info'<'length-info'li<'paggination'p>>>",
                    aoColumnDefs: [
                        {
                            bSortable: false,
                            /*aTargets: [-1],*/
                        },
                    ],
                })
        });
    }

    const concernedElement = document.querySelector(".leave-filter-col");
    document.addEventListener("click", (event) => {
        if (concernedElement !== null) {
            if (!concernedElement.contains(event.target) && document.contains(event.target)) {
                setIsOpen(false)
            }
        }
    });

    return (  <>
            <div className="position-relative leave-page">
                <Container fluid className="p-0">
                    <div className="table-section-col common-header-page">
                        <Row>
                            <Col xl={8} lg={6} md={4}>
                                <div className="page-title-left">Leave Transaction</div>
                            </Col>
                            <Col xl={4} lg={6} md={8} className="mt-auto mb-auto">
                                <div class="d-flex align-items-center justify-content-end">
                                    <div className="inner-search-bar inner-search-grey w-100">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={commonservices.LabelDisplayText('SearchAnything')}
                                            name="searchData"
                                            value={searchobj.SearchText}
                                            onChange={(e) =>
                                                setSearchobj({
                                                    ...searchobj,
                                                    ["SearchText"]: e.target.value,
                                                })
                                            }
                                            onKeyUp={(e) => e.keyCode === 13 && bindList()}
                                        />
                                        <Button
                                            variant="btn btn-icon-btn-lg search-button"
                                            title="Search"
                                            onClick={(e) => bindList()}
                                        >
                                            <i className="fa-light fa-magnifying-glass"></i>
                                        </Button>
                                    </div>
                                    {
                                        commonservices.getLoginRoleData("LeaveTransaction", "IsCreate") &&
                                        <div className="project-add-icon me-3">
                                            <Button className="project-btn" title="Add" onClick={(e) => { setIsShowAddLeaveTransactionModal(true); }}>
                                                <i className="fa-regular fa-plus"></i>
                                            </Button>
                                        </div>
                                    }
                                    <div className="leave-filter-col">
                                        <div className="custom-modal">
                                            <button className="bg-transparent border-0" title="Filter" onClick={() => setIsOpen(!isOpen)}>
                                                <FilterIcon />
                                            </button>
                                            {isOpen && (
                                                <div className="custom-modal-body">
                                                    <Formik>
                                                        <Form>
                                                            <Row>
                                                                <Col lg={12} className="from-bottom-margin">
                                                                    <div className="form-floating ">
                                                                        <input
                                                                        onKeyUp={(e) => e.keyCode === 13 && bindList()}
                                                                        onClick={(e)=> {
                                                                                window.addEventListener('keydown',function(e) {
                                                                                    if (e.keyIdentifier=='U+000A' || e.keyIdentifier=='Enter' || e.keyCode==13) {
                                                                                        if (e.target.nodeName=='INPUT' && e.target.type=='text') {
                                                                                            e.preventDefault();
                                                                            
                                                                                            return false;
                                                                                        }
                                                                                    }
                                                                                }, true);
                                                                            }}
                                                                            type="text"
                                                                            name="Title"
                                                                            className="form-control"
                                                                            value={searchobj.SearchText}
                                                                            placeholder={commonservices.LabelDisplayText('SearchAnything')}
                                                                            onChange={(e) =>
                                                                                setSearchobj({
                                                                                    ...searchobj,
                                                                                    ["SearchText"]: e.target.value,
                                                                                })
                                                                            }
                                                                        />
                                                                        <label>{commonservices.LabelDisplayText('SearchAnything')}</label>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={6} className="from-bottom-margin">
                                                                    <div className="form-floating">
                                                                        <input

                                                                            type="date"
                                                                            name="StartDate"
                                                                            className="form-control calendar-icon"
                                                                            placeholder={commonservices.LabelDisplayText('StartDate')}
                                                                            onChange={(e) =>
                                                                                setSearchobj({
                                                                                    ...searchobj,
                                                                                    ["TransactionStartDate"]: e.target.value,
                                                                                })
                                                                            }
                                                                            value={searchobj.TransactionStartDate}
                                                                        />
                                                                        <label>{commonservices.LabelDisplayText('StartDate')}</label>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={6} className="from-bottom-margin">
                                                                    <div className="form-floating">
                                                                        <input
                                                                            type="date"
                                                                            name="EndDate"
                                                                            className="form-control calendar-icon"
                                                                            placeholder={commonservices.LabelDisplayText('EndDate')}
                                                                            onChange={(e) =>
                                                                                setSearchobj({
                                                                                    ...searchobj,
                                                                                    ["TransactionEndDate"]: e.target.value,
                                                                                })
                                                                            }
                                                                            value={searchobj.TransactionEndDate}
                                                                        />
                                                                        <label>{commonservices.LabelDisplayText('EndDate')}</label>
                                                                    </div>
                                                                </Col>

                                                                <Col xs={6} className="from-bottom-margin">
                                                                    <div className="form-floating">
                                                                        <Field as="select" name="TransactionType" className="form-control down-arrow-icon" value={searchobj.TransactionType}
                                                                            onChange={(e) =>
                                                                                setSearchobj({
                                                                                    ...searchobj,
                                                                                    ["TransactionType"]: e.target.value,
                                                                                })
                                                                            }
                                                                        >
                                                                            <option value="">{commonservices.LabelDisplayText("Select")}</option>
                                                                            <option value="Credit">Credit</option>
                                                                            <option value="Debit">Debit</option>
                                                                        </Field>
                                                                        <label>Type</label>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={6} className="from-bottom-margin">
                                                                    <div className="form-floating">
                                                                        <Field as="select" name="IsTransaction" className="form-control down-arrow-icon" value={searchobj.IsTransaction}
                                                                            onChange={(e) =>
                                                                                setSearchobj({
                                                                                    ...searchobj,
                                                                                    ["IsTransaction"]: e.target.value !== "" ? e.target.value === "true" : null,
                                                                                })
                                                                            }
                                                                        >
                                                                            <option value="">{commonservices.LabelDisplayText("Select")}</option>
                                                                            <option value="true">Transaction</option>
                                                                            <option value="false">Leave Application</option>
                                                                        </Field>
                                                                        <label>From</label>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <div className="custom-modal-footer">
                                                                <Button className="btn btn-secondary"
                                                                title="Filter"
                                                                    onClick={(e) => {
                                                                        bindList({
                                                                            "EmployeeID": null,
                                                                            "TransactionType": null,
                                                                            "IsTransaction": null,
                                                                            "TransactionStartDate": commonservices.getDateInDBFormat(Past7DayDate),
                                                                            "TransactionEndDate": commonservices.getDateInDBFormat(Next7DayDate),
                                                                            "SearchText": null
                                                                        });
                                                                    }}
                                                                >Clear</Button>
                                                                <Button className="btn btn-primary" title="Filter" onClick={(e) => bindList()}>Filter</Button>
                                                            </div>
                                                        </Form>
                                                    </Formik>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
                <Container className="p-0">
                    <div className="vault-tab vault-page">
                        <div className="table-col leave-request-col">
                            <div className="recent-task-section">
                                {
                                    leaveData.IsLoading ?
                                        <>
                                            <Loader
                                                type={LoaderType.Table}
                                                count={4}
                                            />
                                        </>
                                        :
                                        <div className="table-scroll">
                                            <Table id="LeavetblData" className="table table-inner-section">
                                                <thead>
                                                    <tr>
                                                        <th className="width-150px">Date</th>
                                                        <th className="width-100px ">Leave</th>
                                                        <th className="width-100px ">#Days</th>
                                                        <th className="width-100px">Type</th>
                                                        <th className="width-150px">From</th>
                                                        <th className="width-200px"><div className="username ps-0">Name</div></th>
                                                        <th className="width-300px">Note</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="table-section-col">
                                                    {
                                                        leaveData.Data.length > 0 &&
                                                        leaveData.Data.map((itemLeave, index) => (
                                                            <tr>
                                                                <td className="width-150px">
                                                                    <div className="d-flex table-text-common">{commonservices.getDateInDDMMYYYY(itemLeave.TransactionDate)}</div>
                                                                </td>
                                                                <td className="width-60px " title={itemLeave.LeaveName}>
                                                                    <span className={`rounded-circle leave-request-type  ${itemLeave.LeaveCode.toLowerCase()}`}>
                                                                        {itemLeave.LeaveCode}
                                                                    </span>
                                                                </td>
                                                                <td className="width-50px ">
                                                                    <div className="table-text-common"> {itemLeave.NoOfDays}</div>
                                                                </td>
                                                                <td className="width-50px">
                                                                    <span title={itemLeave.TransactionType} className={`leave-label ${itemLeave.TransactionType.toLowerCase() === "credit" ? "approved" : "rejected"}`}>
                                                                        {itemLeave.TransactionType}
                                                                    </span>
                                                                </td>
                                                                <td className="width-150px">{
                                                                    itemLeave.TransactionFrom === "Leave Application" && itemLeave.LeaveApplicationID > 0 ?
                                                                        <a href="#" onClick={(e) => { setLeaveApplicationID(itemLeave.LeaveApplicationID); setIsShowApproveRejectLeaveModal(true); }}>{itemLeave.TransactionFrom}</a> : <>{itemLeave.TransactionFrom}</>
                                                                }</td>
                                                                <td className="padding-top-bottom text-nowrap width-200px" title={itemLeave.EmployeeName}>
                                                                    <div className="username project-description">
                                                                        {itemLeave.EmployeeName}
                                                                    </div>
                                                                </td>
                                                                <td className="text-nowrap width-300px padding-top-bottom" title={itemLeave.TransactionNote}>
                                                                    <div className="vault-description table-text-common">{itemLeave.TransactionNote}</div>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>

                                }


                            </div>
                        </div>
                    </div>
                </Container>
            </div>
      
            {IsShowApproveRejectLeaveModal && (
                <ApproveRejectLeaveModal
                    show={IsShowApproveRejectLeaveModal}
                    leaveApplicationID={LeaveApplicationID}
                    bindList={bindList}
                    onHide={() => setIsShowApproveRejectLeaveModal(false)}
                />
            )}

            {IsShowAddLeaveTransactionModal && (
                <AddLeaveTransactionModal
                    show={IsShowAddLeaveTransactionModal}
                    leaveTransactionID={0}
                    bindList={bindList}
                    onHide={() => setIsShowAddLeaveTransactionModal(false)}
                />
            )}
        </>
    );
}
export default LeaveTransaction;