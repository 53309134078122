import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { AppConfigData, commonservices } from "../Services/commonService";

const AddFamilyModal = (props) => {
    let param = useParams();
    const [Submitting, setSubmitting] = useState(false);
    const [IsGetDetailsDone, setIsGetDetailsDone] = useState(false);
    const [IsValidationMessageSet, setIsValidationMessageSet] = useState("");

    useEffect(() => {
        setIsValidationMessageSet(true);
    });

    const formFieldLength = {
        Name: 167,
        Relationship: 67,
        Occupation: 67,
        NameOfEmployer: 67,
        ContactNo: 20,
        Address: 167,
    };


    const formInitialSchema = {
        EmpFamilyDetailID: 0,
        Name: "",
        Relationship: "",
        DOB: "",
        Occupation: "",
        NameOfEmployer: "",
        IsDependent: false,
        EmployeeID: "",
        ContactNo: "",
        Address: "",
    };

    const formValidation = yup.object().shape({
        Name: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "Name"))
            .test('len', commonservices.MakeValidationMessage("maxlength", ""), (value, obj) => { return value ? value.length <= formFieldLength.Name : true }),
        Relationship: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "Relationship"))
            .test('len', commonservices.MakeValidationMessage("maxlength", ""), (value, obj) => { return value ? value.length <= formFieldLength.Relationship : true }),
        DOB: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "DOB")),
        Occupation: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "Occupation"))
            .test('len', commonservices.MakeValidationMessage("maxlength", ""), (value, obj) => { return value ? value.length <= formFieldLength.Occupation : true }),
        NameOfEmployer: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "NameOfEmployer"))
            .test('len', commonservices.MakeValidationMessage("maxlength", ""), (value, obj) => { return value ? value.length <= formFieldLength.NameOfEmployer : true }),
        ContactNo: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "ContactNo"))
            .test('len', commonservices.MakeValidationMessage("maxlength", ""), (value, obj) => { return value ? value.length <= formFieldLength.ContactNo : true }),
        Address: yup
            .string()
            .test('len', commonservices.MakeValidationMessage("maxlength", ""), (value, obj) => { return value ? value.length <= formFieldLength.Address : true }),
    });
    useEffect(() => {
        if (props.id > 0) {
            const axiosConfig = {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            };
            axios
                .post(
                    `${AppConfigData.APIBasePath}v1/empfamilydetails/getdetailsbyid`,
                    {
                        id: props.id,
                        requestFrom: "Web",
                    },
                    axiosConfig
                )
                .then((res) => {
                    if (res.data.status_code === 0) {
                        toast.error(res.data.message);
                    } else if (res.data.status_code === 1) {

                        commonservices.CheckNullAndReturnBlankInObjectKey(
                            res.data.data.empfamilydetails
                        );

                        formInitialSchema.EmpFamilyDetailID =
                            res.data.data.empfamilydetails.empFamilyDetailID;
                        formInitialSchema.Name = res.data.data.empfamilydetails.name;
                        formInitialSchema.Relationship =
                            res.data.data.empfamilydetails.relationship;
                        formInitialSchema.DOB = res.data.data.empfamilydetails.dob;
                        formInitialSchema.Occupation =
                            res.data.data.empfamilydetails.occupation;
                        formInitialSchema.NameOfEmployer =
                            res.data.data.empfamilydetails.nameOfEmployer;
                        formInitialSchema.IsDependent =
                            res.data.data.empfamilydetails.isDependent;
                        formInitialSchema.ContactNo =
                            res.data.data.empfamilydetails.contactNo;
                        formInitialSchema.Address = res.data.data.empfamilydetails.address;
                        formInitialSchema.EmployeeID =
                            res.data.data.empfamilydetails.employeeID;
                        setIsGetDetailsDone(true);
                    } else if (res.data.status_code === 2) {
                        toast.error(res.data.message);
                    } else {
                        toast.error(res.data.message);
                    }
                })
                .catch((err) => { });
        }
    }, []);

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                className="right-modal modal-new"
                // centered
                scrollable={true}
                backdrop="static"
                keyboard={false}
            >
                <Formik
                    initialValues={formInitialSchema}
                    validationSchema={formValidation}
                    onSubmit={async (values) => {
                        setSubmitting(true);
                        const formData = new FormData();
                        formData.append("EmpFamilyDetailID", values.EmpFamilyDetailID);
                        formData.append("Name", values.Name);
                        formData.append("Relationship", values.Relationship);
                        formData.append("DOB", values.DOB);
                        formData.append("Occupation", values.Occupation);
                        formData.append("NameOfEmployer", values.NameOfEmployer);
                        formData.append("IsDependent", values.IsDependent);
                        formData.append("ContactNo", values.ContactNo);
                        formData.append("Address", values.Address);
                        formData.append("EmployeeID", parseInt(param.id));

                        const axiosConfig = {
                            headers: {
                                "content-type": "multipart/form-data",
                                Authorization: "Bearer " + localStorage.getItem("token"),
                            },
                        };
                        const response = await axios
                            .post(
                                `${AppConfigData.APIBasePath}v1/empfamilydetails/save`,
                                formData,
                                axiosConfig
                            )
                            .then((res) => {
                                setSubmitting(false);
                                if (res.data.status_code === 0) {
                                    toast.error(res.data.message);
                                } else if (res.data.status_code === 1) {
                                    toast.success(res.data.message);
                                    //setTimeout(() => {
                                    //  props.onHide()
                                    //}, 1000);
                                    props.bindList();
                                    props.onHide();
                                } else if (res.data.status_code === 2) {
                                    toast.error(res.data.message);
                                } else {
                                    toast.error(res.data.message);
                                }
                            })
                            .catch((error) => { });
                    }}
                >
                    <Form>
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                {commonservices.LabelDisplayText("AddFamilyDetail")}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Container>
                                <Row>
                                    <Field
                                        type="hidden"
                                        name="EmployeeID"
                                        className="form-control"
                                    />
                                    <Field
                                        type="hidden"
                                        name="EmpFamilyDetailID"
                                        className="form-control"
                                    />

                                    <Col xs={6} lg={6} md={12} className="mb-3">
                                        <div className="form-floating">
                                            <Field
                                                type="text"
                                                name="Name"
                                                className="form-control"
                                                placeholder={commonservices.LabelDisplayText("Name")}
                                                maxLength={formFieldLength.Name}
                                            />
                                            <label lbl-for="Name">{commonservices.LabelDisplayText("Name")}</label>
                                            <div className="text-danger-error">
                                                <ErrorMessage name="Name" />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xs={6} lg={6} md={12} className="mb-3">
                                        <div className="form-floating">
                                            <Field
                                                type="text"
                                                name="Relationship"
                                                className="form-control"
                                                placeholder={commonservices.LabelDisplayText(
                                                    "Relationship"
                                                )}
                                                maxLength={formFieldLength.Relationship}
                                            />
                                            <label lbl-for="Relationship">
                                                {commonservices.LabelDisplayText("Relationship")}
                                            </label>
                                            <div className="text-danger-error">
                                                <ErrorMessage name="Relationship" />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xs={6} lg={6} md={12} className="mb-3">
                                        <div className="form-floating">
                                            <Field
                                                type="date"
                                                className="form-control"
                                                name="DOB"
                                                placeholder={commonservices.LabelDisplayText("DOB")}
                                            />
                                            <label lbl-for="DOB">{commonservices.LabelDisplayText("DOB")}</label>
                                            <div className="text-danger-error">
                                                <ErrorMessage name="DOB" />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xs={6} lg={6} md={12} className="mb-3">
                                        <div className="form-floating">
                                            <Field
                                                type="text"
                                                name="Occupation"
                                                className="form-control"
                                                placeholder={commonservices.LabelDisplayText(
                                                    "Occupation"
                                                )}
                                                maxLength={formFieldLength.Occupation}

                                            />
                                            <label lbl-for="Occupation">
                                                {commonservices.LabelDisplayText("Occupation")}
                                            </label>
                                            <div className="text-danger-error">
                                                <ErrorMessage name="Occupation" />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xs={6} lg={6} md={12} className="mb-3">
                                        <div className="form-floating">
                                            <Field
                                                type="text"
                                                name="NameOfEmployer"
                                                className="form-control"
                                                placeholder={commonservices.LabelDisplayText(
                                                    "NameOfEmployer"
                                                )}
                                                maxLength={formFieldLength.NameOfEmployer}

                                            />
                                            <label lbl-for="NameOfEmployer">
                                                {commonservices.LabelDisplayText("NameOfEmployer")}
                                            </label>
                                            <div className="text-danger-error">
                                                <ErrorMessage name="NameOfEmployer" />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xs={6} lg={6} md={12} className="mb-3">
                                        <div className="form-floating">
                                            <Field
                                                type="text"
                                                name="ContactNo"
                                                className="form-control"
                                                placeholder={commonservices.LabelDisplayText(
                                                    "ContactNo"
                                                )}
                                                maxLength={formFieldLength.ContactNo}

                                            />
                                            <label lbl-for="ContactNo">
                                                {commonservices.LabelDisplayText("ContactNo")}
                                            </label>
                                            <div className="text-danger-error">
                                                <ErrorMessage name="ContactNo" />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xs={12} className="mb-3">
                                        <div className="form-floating">
                                            <Field
                                                type="text"
                                                name="Address"
                                                className="form-control"
                                                placeholder={commonservices.LabelDisplayText("Address")}
                                                maxLength={formFieldLength.Address}

                                            />
                                            <label lbl-for="Address">
                                                {commonservices.LabelDisplayText("Address")}
                                            </label>
                                            <div className="text-danger-error">
                                                <ErrorMessage name="Address" />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xs={6} className="from-bottom-margin">
                                        <div className="form-group checkbox-class pt-2">
                                            <Field
                                                type="checkbox"
                                                name="IsDependent"
                                                className="me-2"
                                                id="IsDependent"
                                            />
                                            <label htmlFor="IsDependent" lbl-for="IsDependent">
                                                {commonservices.LabelDisplayText("IsDependent")}
                                            </label>
                                            <div className="text-danger-error">
                                                <ErrorMessage name="IsDependent" />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={props.onHide}  title="close">
                                {commonservices.LabelDisplayText("Close")}
                            </Button>
                            <Button
                                className="btn btn-primary"
                                type="submit"
                                title="Submit"
                                disabled={Submitting}
                            >
                                {Submitting && (
                                    <>
                                        <Spinner animation="border" />
                                    </>
                                )}
                                {commonservices.LabelDisplayText("Save")}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Formik>
            </Modal>
        </>
    );
};

export default AddFamilyModal;
