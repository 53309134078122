import axios from "axios";
import React from "react";
import { useState } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { AppConfigData } from "../../Services/commonService";

const GenerateSalarySlip = () => {
  const [LogoUpload, setLogoUpload] = useState(null);
  const [Submitting, setSubmitting] = useState(false);

  function SelectLogo(e) {
    if (e.target.files.length !== 0) {
      //   const fileLoaded = URL.createObjectURL(e.target.files[0]);
      setLogoUpload(e.target.files[0]);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    const formData = new FormData();
    formData.append("file", LogoUpload);
    if (LogoUpload) {
      const axiosConfig = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      const response = await axios
        .post(
          `${AppConfigData.APIBasePath}v1/salary/uploadsalarysheet`,
          formData,
          axiosConfig
        )
        .then((response) => {
          let value = { ...LogoUpload };
          value = null;
          setLogoUpload(value);
          setSubmitting(false);
          if (response.data.status_code === 1) {
            toast.success(response.data.message);
          } else if (response.data.status_code === 2) {
            toast.error(response.data.message);
          } else if (response.data.status_code === 0) {
            toast.error(response.data.message);
          } else {
            toast.error(response.data.message);
          }
        })
        .catch((error) => {
          setSubmitting(false);
          toast.error("Something went wrong.");
          //   let value = { ...LogoUpload };
          //   value = null;
          //   setLogoUpload(value);
        });
    } else {
      toast.error("Please select atleast one file.");
      setSubmitting(false);
    }
  };

  return (
    <>
      <div className="position-relative leave-page">
        <Container fluid className="p-0">
          <div className="table-section-col common-header-page">
            <Row>
              <Col xl={8} lg={6} md={4}>
                <div className="page-title-left">Generate Salary Slip</div>
              </Col>
              <Col xl={4} lg={6} md={8} className="mt-auto mb-auto"></Col>
            </Row>
          </div>
        </Container>
        <Container className="p-0">
          <Row>
            <Col xl={4} lg={4} md={4}>
              <div className="vault-tab vault-page">
                <div className="table-col leave-request-col">
                  <div className="recent-task-section">
                    <div className="form-group text-nowrap">
                      <div className="form-floating">
                        <div className="form-control browse-file">
                          {LogoUpload && LogoUpload?.name}
                          <input
                            accept=".xls,.xlsx"
                            type="file"
                            onChange={SelectLogo}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={8} lg={8} md={8} className="mt-auto mb-auto">
              <Button
                variant="primary"
                className="m-0 mt-3"
                title="Previous"
                onClick={(e) => handleSubmit(e)}
                disabled={Submitting}
              >
                {Submitting ? (
                  <>
                    <Spinner animation="border" />
                  </>
                ) : (
                  <soan> Submit </soan>
                )}
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default GenerateSalarySlip;
