import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { commonservices, AppConfigData } from "../../Services/commonService";
import { ReactComponent as Logo } from "../../assets/Images/login-logo.svg";
import { Spinner } from "react-bootstrap";

function Forgot() {
  const navigate = useNavigate();
  const [Loading, setLoading] = useState(false);

  const formInitialSchema = {
    email: "",
  };

  const formValidation = yup.object().shape({
    email: yup
      .string()
      .required(commonservices.MakeValidationMessage("required", "email"))
      .email(commonservices.MakeValidationMessage("email", "email")),
  });

  return (
    <div className="login-page">
      <div className="container-fluid p-0 h-100">
        <div className="login-box h-100">
          <div className="row m-0 h-100">
            <div className="col-12 p-0 d-flex justify-content-center align-items-center">
              <div className="login-right-col">
                <div className="login-logo text-center">
                  <Logo />
                </div>
                <Formik
                  initialValues={formInitialSchema}
                  validationSchema={formValidation}
                  onSubmit={async (values) => {
                   
                    setLoading(true);
                    commonservices.ClearLoginDetails();
                    const formData = new FormData();
                    formData.append("UserName", values.email);
                    const axiosConfig = {
                      headers: {
                        "content-type": "multipart/form-data",
                      },
                    };
                    const response = await axios
                      .post(
                        `${AppConfigData.APIBasePath}v1/user/forgotpassword`,
                        formData,
                        axiosConfig
                      )

                      .then((response) => {
                        setLoading(false);
                        if (response.data.status_code === 0) {
                          toast.error(response.data.message);
                        } else if (response.data.status_code === 1) {
                          toast.success(response.data.message);
                          navigate("/");
                        } else if (response.data.status_code === 2) {
                          toast.error(response.data.message);
                        }
                      })
                      .catch((error) => {});
                  }}
                >
                  <Form>
                    <div className="form-group">
                      <Field
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder={commonservices.LabelDisplayText(
                          "EnterregisteredEmail"
                        )}
                      />
                      <label lbl-for="email">
                        {commonservices.LabelDisplayText(
                          "EnterregisteredEmail"
                        )}
                      </label>
                      <span className="user-icon">
                        <i className="fa-solid fa-user"></i>
                      </span>
                      <p className="text-danger-error">
                        <ErrorMessage name="email" />
                      </p>
                    </div>

                    <div className="bottom-button">
                      <button
                        className="btn btn-password"
                        //   title="Get Password"
                        title={Loading ? "Loading..." : "Get Password"}
                        type="submit"
                      >
                        {Loading && <Spinner animation="border" />}Get Password
                      </button>
                      <Link to="/" className="btn btn-cancel">
                        {commonservices.LabelDisplayText("GotoLogin")}
                      </Link>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Forgot;
