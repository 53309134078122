/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import UserPlaceholder from "../../assets/Icons/placeholder.svg";
import Chart from "react-apexcharts";
import {
  Button,
  Card,
  Col,
  Container,
  Nav,
  OverlayTrigger,
  Popover,
  Tooltip,
  Row,
  Tab,
  Table,
} from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ReactComponent as GmailBlack } from "../../assets/Icons/black-gmail.svg";
import { ReactComponent as LocationBlack } from "../../assets/Icons/black-location.svg";
import { ReactComponent as PhoneBlack } from "../../assets/Icons/black-phone.svg";
import { ReactComponent as SkypeBlack } from "../../assets/Icons/black-skype.svg";
import BlueRight from "../../assets/Icons/blue-right.svg";
import { ReactComponent as DownloadIcon } from "../../assets/Icons/download-icon.svg";
import { ReactComponent as NextIcon } from "../../assets/Icons/next.svg";
import { ReactComponent as Pencil } from "../../assets/Icons/pencil.svg";
import { ReactComponent as Trash } from "../../assets/Icons/trash.svg";
import { ReactComponent as EyeshowPassword } from "../../assets/Icons/eyeshow-password.svg";
import Loader, { LoaderType } from "../../Loader";
import AddAchievementModal from "../../Modal/AddAchievementModal";
import AddDocument from "../../Modal/AddDocument";
import AddExperienceModal from "../../Modal/AddExperienceModal";
import AddFamilyModal from "../../Modal/AddFamilyModal";
import AddleaveModal from "../../Modal/AddleaveModal";
import AddQualificationModal from "../../Modal/AddQualificationModal";
import ApproveRejectLeaveModal from "../../Modal/ApproveRejectLeaveModal";
import { AppConfigData, commonservices } from "../../Services/commonService";
import { viewFilePopupService } from "../../Services/viewFilePopup.service";
import EditProfileModal from "../../Modal/EditProfileModal";

function Profile() {
  let data = commonservices.getLoginUserData();

  let param = useParams();
  const navigate = useNavigate();
  const [EmployeeID, setEmployeeID] = useState(data[0].AssociationID);
  const [ProfileProcessbar, setProfileProcessbar] = useState([1, 2, 3, 4, 5]);
  const [ProfileProcessbarCompleted, setProfileProcessbarCompleted] = useState([
    "profile",
  ]);
  const [profileDetails, setProfileDetails] = useState({
    Data: [],
    IsLoading: true,
  });
  const [modalShow, setModalShow] = useState(false);
  const [ProfileModalShow, setProfileModalShow] = useState(false);
  const [EditProfileData, setEditProfileData] = useState();
  const [modalQualificationShow, setModalQualificationShow] = useState(false);
  const [modalAchievementShow, setModalAchievementShow] = useState(false);
  const [modalExperienceShow, setModalExperienceShow] = useState(false);
  const [modalFamilyShow, setModalFamilyShow] = useState(false);
  const [modalLeaveShow, setModalLeaveShow] = useState(false);
  const [documentDetails, setDocumentDetails] = useState({
    Data: [],
    IsLoading: true,
  });
  const [SkypeId, setSkypeId] = useState([]);
  const [FamilyDetails, setFamilyDetails] = useState({
    Data: [],
    IsLoading: true,
  });
  const [qualificationDetails, setQualificationDetails] = useState({
    Data: [],
    IsLoading: true,
  });
  const [experienceDetails, setExperienceDetails] = useState({
    Data: [],
    IsLoading: true,
  });
  const [achievementDetails, setAchievementDetails] = useState({
    Data: [],
    IsLoading: true,
  });
  const [taskData, settaskData] = useState({ Data: [], IsLoading: true });
  const [EMPID, setEMPID] = useState(0);
  const [QUALIFICATIONID, setQUALIFICATIONID] = useState(0);
  const [ACHIEVEMENTID, setACHIEVEMENTID] = useState(0);
  const [EXPERIENCEID, setEXPERIENCEID] = useState(0);
  const [FAMILYID, setFAMILYID] = useState(0);
  const [ActiveTab, setActiveTab] = useState("personal");
  const [WorkingHoursChart, setWorkingHoursChart] = useState({
    Data: {
      options: {
        markers: {
          size: 5,
          strokeWidth: 3,
          strokeColors: "#345BF8",
          colors: "#ffffff",
          hover: {
            size: 7,
            strokeWidth: 8,
          },
        },
        xaxis: {
          categories: [],
          axisBorder: { show: true },
          axisTicks: { show: false },
          labels: { show: true },
        },
        yaxis: { show: true },
        grid: { show: true },
        stroke: {
          width: 5,
          curve: "smooth",
        },
        title: {
          text: "Working Hours",
          align: "left",
          style: {
            fontSize: "22px",
            lineHeight: "28px",
            fontWeight: "700",
            fontFamily: "Source Sans Pro",
            color: "#1C212D",
          },
        },
        colors: ["#345BF8"],
        chart: {
          sparkline: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
          background: "#F3F6F9",
          type: "line",
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          enabled: true,
          style: {
            fontSize: "20px",
            lineHeight: "24px",
            fontWeight: "700",
            fontFamily: "Source Sans Pro",
            color: "#1C212D",
            textAlign: "center",
          },
          x: {
            show: false,
          },
          marker: {
            show: false,
          },
          fixed: {
            enabled: false,
            position: "topLeft",
            offsetX: "0",
            offsetY: "0",
          },
        },
      },
      series: [
        {
          name: "",
          data: [],
        },
      ],
    },
    IsLoading: true,
  });
  const [WorkingHoursChartMode, setWorkingHoursChartMode] = useState("Week");
  const [ProjectsWiseTaskChart, setProjectsWiseTaskChart] = useState({
    Data: {
      options: {
        labels: [],
        chart: {
          sparkline: { enabled: false },
        },
        stroke: {
          width: ["0"],
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
          },
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: "gradient",
        },
        legend: {
          position: "bottom",
          horizontalAlign: "left",
          fontSize: "16px",
          fontWeight: "400",
          lineHeight: "20px",
          color: "#000000",
          itemMargin: {
            horizontal: 5,
            vertical: 5,
          },
        },
      },
      series: [
        {
          name: "",
          data: [],
        },
      ],
    },
    IsLoading: true,
  });
  const [leaveData, setleaveData] = useState({ Data: [], IsLoading: true });
  const [LeaveCount, setLeaveCount] = useState({ Data: [], IsLoading: true });
  const [IsShowApproveRejectLeaveModal, setIsShowApproveRejectLeaveModal] =
    useState(false);
  const [LeaveApplicationID, setLeaveApplicationID] = useState(0);
  function bindList() {
    setProfileDetails({
      Data: [],
      IsLoading: true,
    });
    setDocumentDetails({
      Data: [],
      IsLoading: true,
    });
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(
        `${AppConfigData.APIBasePath}v1/employees/getprofiledetailsbyid`,
        {
          id: parseInt(param.id),
          requestFrom: "string",
        },
        axiosConfig
      )
      .then((res) => {
        if (res.data.status_code === 0) {
          setProfileDetails({ Data: [], IsLoading: false });
          setDocumentDetails({ Data: [], IsLoading: false });
        } else if (res.data.status_code === 1) {
          let d = [...data];
          d[0].ProfilePic = res.data.data.employees.ProfilePic;
          commonservices.setItem("userData", JSON.stringify(d));    

          setProfileDetails({
            Data: res.data.data.employees,
            IsLoading: false,
          });
          setDocumentDetails({
            Data: res.data.data.documents,
            IsLoading: false,
          });

          let lst = [];
          res.data.data.passwordmanagment.forEach((element) => {
            if (element.TypeTerm === "Skype") {
              lst.push({
                skype: element.UserName,
              });
            } else if (element.TypeTerm === "Gmail") {
              lst.push({
                gmail: element.UserName,
              });
            }
          });
          setSkypeId(lst);
          if (res.data.data.documents.length > 0) {
            if (
              !ProfileProcessbarCompleted.filter((i) => i === "document").length
            ) {
              ProfileProcessbarCompleted.push("document");
              setProfileProcessbarCompleted(ProfileProcessbarCompleted);
            }
          }
        } else if (res.data.status_code === 2) {
          commonservices.ClearLoginDetails();
          navigate("/");
        } else {
          setProfileDetails({ Data: [], IsLoading: false });
          setDocumentDetails({ Data: [], IsLoading: false });
        }
      })
      .catch((err) => {});
  }

  function bindFamilyDetails() {
    setFamilyDetails({
      Data: [],
      IsLoading: true,
    });
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(
        `${AppConfigData.APIBasePath}v1/empfamilydetails/getlist`,
        {
          employeeID: parseInt(param.id),
          searchText: null,
        },
        axiosConfig
      )
      .then((res) => {
        if (res.data.status_code === 0) {
          setFamilyDetails({ Data: [], IsLoading: false });
        } else if (res.data.status_code === 1) {
          setFamilyDetails({ Data: res.data.data, IsLoading: false });

          if (res.data.data.length > 0) {
            if (
              !ProfileProcessbarCompleted.filter((i) => i === "family").length
            ) {
              ProfileProcessbarCompleted.push("family");
              setProfileProcessbarCompleted(ProfileProcessbarCompleted);
            }
          }
        } else if (res.data.status_code === 2) {
          commonservices.ClearLoginDetails();
          navigate("/");
        } else {
          setFamilyDetails({ Data: [], IsLoading: false });
        }
      })
      .catch((err) => {});
  }

  function bindQualificationDetails() {
    setQualificationDetails({
      Data: [],
      IsLoading: true,
    });
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(
        `${AppConfigData.APIBasePath}v1/empqualification/getlist`,
        {
          employeeID: parseInt(param.id),
          searchText: null,
        },
        axiosConfig
      )
      .then((res) => {
        if (res.data.status_code === 0) {
          setQualificationDetails({ Data: [], IsLoading: false });
        } else if (res.data.status_code === 1) {
          setQualificationDetails({ Data: res.data.data, IsLoading: false });

          if (res.data.data.length > 0) {
            if (
              !ProfileProcessbarCompleted.filter((i) => i === "qualification")
                .length
            ) {
              ProfileProcessbarCompleted.push("qualification");
              setProfileProcessbarCompleted(ProfileProcessbarCompleted);
            }
          }
        } else if (res.data.status_code === 2) {
          commonservices.ClearLoginDetails();
          navigate("/");
        } else {
          setQualificationDetails({ Data: [], IsLoading: false });
        }
      })
      .catch((err) => {});
  }

  function bindExperienceDetails() {
    setExperienceDetails({
      Data: [],
      IsLoading: true,
    });
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(
        `${AppConfigData.APIBasePath}v1/empworkexperience/getlist`,
        {
          employeeID: parseInt(param.id),
          searchText: null,
        },
        axiosConfig
      )
      .then((res) => {
        if (res.data.status_code === 0) {
          setExperienceDetails({ Data: [], IsLoading: false });
        } else if (res.data.status_code === 1) {
          setExperienceDetails({ Data: res.data.data, IsLoading: false });

          if (res.data.data.length > 0) {
            if (
              !ProfileProcessbarCompleted.filter((i) => i === "workexperience")
                .length
            ) {
              ProfileProcessbarCompleted.push("workexperience");
              setProfileProcessbarCompleted(ProfileProcessbarCompleted);
            }
          }
        } else if (res.data.status_code === 2) {
          commonservices.ClearLoginDetails();
          navigate("/");
        } else {
          setExperienceDetails({ Data: [], IsLoading: false });
        }
      })
      .catch((err) => {});
  }

  function bindAchievementDetails() {
    setAchievementDetails({
      Data: [],
      IsLoading: true,
    });
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(
        `${AppConfigData.APIBasePath}v1/empachievement/getlist`,
        {
          employeeID: parseInt(param.id),
          searchText: null,
        },
        axiosConfig
      )
      .then((res) => {
        if (res.data.status_code === 0) {
          setAchievementDetails({ Data: [], IsLoading: false });
        } else if (res.data.status_code === 1) {
          setAchievementDetails({ Data: res.data.data, IsLoading: false });
        } else if (res.data.status_code === 2) {
          commonservices.ClearLoginDetails();
          navigate("/");
        } else {
          setAchievementDetails({ Data: [], IsLoading: false });
        }
      })
      .catch((err) => {});
  }

  const removeDoc = async (id) => {
    const response = await commonservices.DeleteDocumentByID(id, 0, "");
    if (response.data.status_code === 1) {
      toast.success(response.data.message);
      bindList();
    } else {
      toast.error(response.data.message);
    }
  };

  function fnOpenEmployeeAddEditPopup(id) {
    setEMPID(id);
    setModalShow(true);
  }

  function fnOpenQualificationAddEditPopup(id) {
    setQUALIFICATIONID(id);
    setModalQualificationShow(true);
  }

  function deleteQualification(id) {
    let input = {
      id: id,
      requestFrom: "Web",
    };
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(
        `${AppConfigData.APIBasePath}v1/empqualification/delete`,
        input,
        axiosConfig
      )
      .then((res) => {
        if (res.data.status_code === 0) {
          toast.error(res.data.message);
        } else if (res.data.status_code === 1) {
          toast.success(res.data.message);
          bindQualificationDetails();
        } else if (res.data.status_code === 2) {
          toast.error(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {});
  }

  function fnOpenachievementAddEditPopup(id) {
    setACHIEVEMENTID(id);
    setModalAchievementShow(true);
  }

  function deleteAchievement(id) {
    let input = {
      id: id,
      requestFrom: "Web",
    };
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(
        `${AppConfigData.APIBasePath}v1/empachievement/delete`,
        input,
        axiosConfig
      )
      .then((res) => {
        if (res.data.status_code === 0) {
          toast.error(res.data.message);
        } else if (res.data.status_code === 1) {
          toast.success(res.data.message);
          bindAchievementDetails();
        } else if (res.data.status_code === 2) {
          toast.error(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {});
  }

  function fnOpenExperienceAddEditPopup(id) {
    setEXPERIENCEID(id);
    setModalExperienceShow(true);
  }

  function deleteExperience(id) {
    let input = {
      id: id,
      requestFrom: "Web",
    };
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(
        `${AppConfigData.APIBasePath}v1/empworkexperience/delete`,
        input,
        axiosConfig
      )
      .then((res) => {
        if (res.data.status_code === 0) {
          toast.error(res.data.message);
        } else if (res.data.status_code === 1) {
          toast.success(res.data.message);
          bindExperienceDetails();
        } else if (res.data.status_code === 2) {
          toast.error(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {});
  }

  function fnOpenFamilyAddEditPopup(id) {
    setFAMILYID(id);
    setModalFamilyShow(true);
  }

  function deleteFamily(id) {
    let input = {
      id: id,
      requestFrom: "Web",
    };
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(
        `${AppConfigData.APIBasePath}v1/empfamilydetails/delete`,
        input,
        axiosConfig
      )
      .then((res) => {
        if (res.data.status_code === 0) {
          toast.error(res.data.message);
        } else if (res.data.status_code === 1) {
          toast.success(res.data.message);
          bindFamilyDetails();
        } else if (res.data.status_code === 2) {
          toast.error(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {});
  }

  function fnLeaveOpen() {
    setModalLeaveShow(true);
  }

  function TaskbindList() {
    settaskData({
      Data: [],
      IsLoading: true,
    });
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(
        `${AppConfigData.APIBasePath}v1/task/getlistwithtimedata`,
        {
          title: null,
          status: "Closed",
          priority: null,
          startDate: commonservices.getDateInDBFormat(
            new Date().setDate(new Date().getDate() - 30)
          ),
          endDate: commonservices.getDateInDBFormat(new Date()),
          projectID: null,
          userID: null,
          associationID: null,
          associationType: null,
        },
        axiosConfig
      )
      .then((res) => {
        if (res.data.status_code === 0) {
          settaskData({ Data: [], IsLoading: false });
        } else if (res.data.status_code === 1) {
          let UniqeTaskID = [];
          let UniqeTaskList = [];
          for (let i = 0; i < res.data.data.taskList.length; i++) {
            if (!UniqeTaskID.includes(res.data.data.taskList[i].TaskID)) {
              if (res.data.data.taskList[i].TotalTimeInMinites > 0) {
                UniqeTaskID.push(res.data.data.taskList[i].TaskID);
                UniqeTaskList.push(res.data.data.taskList[i]);
              }
            } else {
              if (res.data.data.taskList[i].TotalTimeInMinites > 0) {
                UniqeTaskList.find(
                  (obj) => obj.TaskID === res.data.data.taskList[i].TaskID
                ).TotalTimeInMinites =
                  UniqeTaskList.find(
                    (obj) => obj.TaskID === res.data.data.taskList[i].TaskID
                  ).TotalTimeInMinites +
                  res.data.data.taskList[i].TotalTimeInMinites;
              }
            }
          }
          settaskData({ Data: UniqeTaskList, IsLoading: false });
        } else if (res.data.status_code === 2) {
          commonservices.ClearLoginDetails();
          navigate("/");
        } else {
        }
      })
      .catch((err) => {
        settaskData({
          Data: [],
          IsLoading: false,
        });
      });
  }

  function fnCreateTaskButtonAndTimeUI(task) {
    if (task.IsAssign > 0) {
      if (task.RunningCount > 0) {
        return (
          <>
            <div className="task-time text-nowrap me-3">
              {commonservices.getTimeInFormat(
                task.RunningTaskList[0].StartTime
              )}
            </div>
          </>
        );
      } else {
        if (task.IsTaskRunningByMe === 0) {
          return (
            <>
              <div className="task-time text-nowrap me-3">
                {task.TotalTimeInMinites > 0 &&
                  commonservices.ConvertMinutesToTimeFormat(
                    task.TotalTimeInMinites
                  ) + " hrs"}
              </div>
            </>
          );
        }
      }
    } else {
      if (task.RunningCount > 0) {
        if (task.RunningCount === 1 && task.RunningTaskList.length === 1) {
          return (
            <>
              <div className="task-time text-nowrap me-3">
                {commonservices.getTimeInFormat(
                  task.RunningTaskList[0].StartTime
                )}
              </div>
            </>
          );
        } else {
          return (
            <>
              <OverlayTrigger
                trigger="click"
                key={"bottom"}
                placement={"bottom"}
                overlay={
                  <Popover id={`popover-positioned-bottom`}>
                    <Popover.Body>
                      {task.RunningTaskList.map((item, index) => (
                        <div
                          className="task-time-status d-flex align-items-center justify-content-end"
                          key={index}
                        >
                          <img
                            className="project-img me-2"
                            src={item.ProfilePic}
                            alt={commonservices.getInitials(item.DisplayName)}
                            title={item.DisplayName}
                            width={30}
                            height={30}
                          />
                          <div className="task-time text-nowrap me-3">
                            {commonservices.getTimeInFormat(item.StartTime)}
                          </div>
                        </div>
                      ))}
                    </Popover.Body>
                  </Popover>
                }
              >
                <button
                  type="button"
                  className="pause-play-button"
                  title={"Running By " + task.RunningCount + " User"}
                >
                  <i className="fa-regular fa-timer"></i>
                </button>
              </OverlayTrigger>
              <div className="task-time text-nowrap me-3">
                {task.RunningCount} user
              </div>
            </>
          );
        }
      } else {
        if (task.TotalTimeInMinites > 0) {
          return (
            <>
              <div className="task-time text-nowrap me-3">
                {commonservices.ConvertMinutesToTimeFormat(
                  task.TotalTimeInMinites
                )}{" "}
                hrs
              </div>
            </>
          );
        }
      }
    }
  }

  function fnBindWorkingHoursChart(chartMode) {
    setWorkingHoursChartMode(chartMode);
    let ChartDate = WorkingHoursChart.Data;
    let startDate = new Date().toUTCString();
    let endDate = new Date().toUTCString();
    ChartDate.options.xaxis.categories = [];
    ChartDate.series[0].data = [];
    setWorkingHoursChart({ Data: ChartDate, IsLoading: true });
    let curr = new Date();
    if (chartMode.toUpperCase() === "WEEK") {
      let first = curr.getDate() - curr.getDay();
      let last = first + 6;
      startDate = new Date(curr.setDate(first));
      endDate = new Date(curr.setDate(last));
    } else if (chartMode.toUpperCase() === "MONTH") {
      startDate = new Date(curr.getFullYear(), curr.getMonth(), 1);
      endDate = new Date(curr.getFullYear(), curr.getMonth() + 1, 0);
    } else if (chartMode.toUpperCase() === "YEAR") {
      startDate = new Date(curr.getFullYear(), 0, 1);
      endDate = new Date(curr.getFullYear(), 11, 31);
    }

    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(
        `${AppConfigData.APIBasePath}v1/reports/workinghourschartdata`,
        {
          employeeID: parseInt(param.id),
          startDate: commonservices.getDateInDBFormat(startDate),
          endDate: commonservices.getDateInDBFormat(endDate),
          chartMode: chartMode,
        },
        axiosConfig
      )
      .then((res) => {
        if (res.data.status_code === 0) {
          setWorkingHoursChart({ Data: ChartDate, IsLoading: false });
        } else if (res.data.status_code === 1) {
          let xaxis_categories = [];
          let series_data = [];
          for (let i = 0; i < res.data.data.length; i++) {
            xaxis_categories.push(res.data.data[i].Text);
            series_data.push(res.data.data[i].Value);
          }
          ChartDate.options.xaxis.categories = xaxis_categories;
          ChartDate.series[0].data = series_data;
          setWorkingHoursChart({ Data: ChartDate, IsLoading: false });
        } else if (res.data.status_code === 2) {
          commonservices.ClearLoginDetails();
          navigate("/");
        }
      })
      .catch((err) => {
        setWorkingHoursChart({ Data: ChartDate, IsLoading: false });
      });
  }

  function fnBindProjectWiseCompletedTaskChart() {
    let ChartDate = ProjectsWiseTaskChart.Data;
    ChartDate.options.labels = [];
    ChartDate.series[0].data = [];
    setProjectsWiseTaskChart({ Data: ChartDate, IsLoading: true });

    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(
        `${AppConfigData.APIBasePath}v1/reports/completedtaskbyprojectchartdata`,
        {
          employeeID: parseInt(param.id),
        },
        axiosConfig
      )
      .then((res) => {
        if (res.data.status_code === 0) {
          setProjectsWiseTaskChart({ Data: ChartDate, IsLoading: false });
        } else if (res.data.status_code === 1) {
          let labels = [];
          let series_data = [];
          for (let i = 0; i < res.data.data.length; i++) {
            labels.push(res.data.data[i].Text);
            series_data.push(res.data.data[i].Value);
          }
          ChartDate.options.labels = labels;
          ChartDate.series[0].data = series_data;
          setProjectsWiseTaskChart({ Data: ChartDate, IsLoading: false });
        } else if (res.data.status_code === 2) {
          commonservices.ClearLoginDetails();
          navigate("/");
        }
      })
      .catch((err) => {
        setProjectsWiseTaskChart({ Data: ChartDate, IsLoading: false });
      });
  }

  function bindLeaveData() {
    setleaveData({
      Data: [],
      IsLoading: true,
    });

    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(
        `${AppConfigData.APIBasePath}v1/leaveapplication/getlist`,
        {
          startDate: null,
          endDate: null,
          title: "",
          leaveType: "",
          applicationStatus: "",
          searchText: "",
        },
        axiosConfig
      )
      .then((res) => {
        if (res.data.status_code === 0) {
          setleaveData({
            Data: [],
            IsLoading: false,
          });
        } else if (res.data.status_code === 1) {
          setleaveData({ Data: res.data.data, IsLoading: false });
        } else if (res.data.status_code === 2) {
          commonservices.ClearLoginDetails();
          navigate("/");
        }
      })
      .catch((err) => {});
  }

  function GetLeaveCountData() {
    setLeaveCount({
      Data: [],
      IsLoading: true,
    });

    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(
        `${AppConfigData.APIBasePath}v1/leavemaster/getemployeeleavelist`,
        {},
        axiosConfig
      )
      .then((res) => {
        if (res.data.status_code === 0) {
          setLeaveCount({
            Data: [],
            IsLoading: false,
          });
        } else if (res.data.status_code === 1) {
          setLeaveCount({
            Data: res.data.data,
            IsLoading: false,
          });
        } else if (res.data.status_code === 2) {
          setLeaveCount({
            Data: [],
            IsLoading: false,
          });
        } else {
          setLeaveCount({
            Data: [],
            IsLoading: false,
          });
        }
      })
      .catch((err) => {
        setLeaveCount({
          Data: [],
          IsLoading: false,
        });
      });
  }

  function BindAllData() {
    setEmployeeID(param.id);
    setProfileProcessbarCompleted(["profile"]);
    setActiveTab("personal");
    TaskbindList();
    bindAchievementDetails();

    if (parseInt(data[0].AssociationID) === parseInt(param.id)) {
      bindExperienceDetails();
      bindFamilyDetails();
      bindLeaveData();
      GetLeaveCountData();
    }

    bindQualificationDetails();

    bindList();
    fnBindWorkingHoursChart("WEEK");
    fnBindProjectWiseCompletedTaskChart();
  }

  function CancelLeaveRequest(id) {
    let input = {
      id: id,
      requestFrom: "Web",
    };
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(
        `${AppConfigData.APIBasePath}v1/leaveapplication/cancel`,
        input,
        axiosConfig
      )
      .then((res) => {
        if (res.data.status_code === 0) {
          toast.error(res.data.message);
        } else if (res.data.status_code === 1) {
          toast.success(res.data.message);
          bindLeaveData();
        } else if (res.data.status_code === 2) {
          toast.error(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {});
  }

  function fnOpenProfileModal(item) {
    setProfileModalShow(true);
    setEditProfileData(item);
  }

  useEffect(() => {
    if (EmployeeID !== param.id) {
      BindAllData();
    }
  });

  // For Datatable
  $(document).ready(function () {
    $("table#tblData").DataTable({
      destroy: true,
      searching: false,
      dom: "ft<'tbl-info'<'length-info'li<'paggination'p>>>",
      aoColumnDefs: [
        {
          bSortable: false,
          aTargets: [-1] /* 1st one, start by the right */,
        },
      ],
    });
  });

  useEffect(() => {
    $(document).ready(function () {
      $("table#tblData")
        .DataTable({
          destroy: true,
          searching: false,
          dom: "ft<'tbl-info'<'length-info'li<'paggination'p>>>",
          aoColumnDefs: [
            {
              bSortable: false,
              aTargets: [-1],
            },
          ],
        })
        .destroy();
    });
  }, []);

  // For Datatable
  $(document).ready(function () {
    $("table#familytblData").DataTable({
      destroy: true,
      searching: false,
      dom: "ft<'tbl-info'<'length-info'li<'paggination'p>>>",
      aoColumnDefs: [
        {
          bSortable: false,
          aTargets: [-1] /* 1st one, start by the right */,
        },
      ],
    });
  });

  useEffect(() => {
    $(document).ready(function () {
      $("table#familytblData")
        .DataTable({
          destroy: true,
          searching: false,
          dom: "ft<'tbl-info'<'length-info'li<'paggination'p>>>",
          aoColumnDefs: [
            {
              bSortable: false,
              aTargets: [-1],
            },
          ],
        })
        .destroy();
    });
  }, []);

  // For Datatable
  $(document).ready(function () {
    $("table#achievementtblData").DataTable({
      destroy: true,
      searching: false,
      dom: "ft<'tbl-info'<'length-info'li<'paggination'p>>>",
      aoColumnDefs: [
        {
          bSortable: false,
          aTargets: [-1] /* 1st one, start by the right */,
        },
      ],
    });
  });

  useEffect(() => {
    $(document).ready(function () {
      $("table#achievementtblData")
        .DataTable({
          destroy: true,
          searching: false,
          dom: "ft<'tbl-info'<'length-info'li<'paggination'p>>>",
          aoColumnDefs: [
            {
              bSortable: false,
              aTargets: [-1],
            },
          ],
        })
        .destroy();
    });
  }, []);

  // For Datatable
  $(document).ready(function () {
    $("table#experiencetblData").DataTable({
      destroy: true,
      searching: false,
      dom: "ft<'tbl-info'<'length-info'li<'paggination'p>>>",
      aoColumnDefs: [
        {
          bSortable: false,
          aTargets: [-1] /* 1st one, start by the right */,
        },
      ],
    });
  });

  useEffect(() => {
    $(document).ready(function () {
      $("table#experiencetblData")
        .DataTable({
          destroy: true,
          searching: false,
          dom: "ft<'tbl-info'<'length-info'li<'paggination'p>>>",
          aoColumnDefs: [
            {
              bSortable: false,
              aTargets: [-1],
            },
          ],
        })
        .destroy();
    });
  }, []);

  return (
    <>
      <div className="profile-page">
        <Container fluid className="p-0">
          <Row className="m-0">
            <Col lg={3} className="ps-0 pe-xl-4 pe-lg-4 pe-md-0 pe-sm-0">
              <div className="table-section-col scroll">
                <div className="profile-section-left">
                  <div className="profile-top-col">
                    <div className="profile-title">
                      {commonservices.LabelDisplayText("Profile")}
                    </div>
                    <div className="profile-edit-icon"></div>
                  </div>
                  <div className="user-profile-col text-center">
                    <img
                      className="user-profile-img rounded-circle"
                      // src={profileDetails.Data.ProfilePic}
                      src={
                        profileDetails.Data.ProfilePic !== null &&
                        profileDetails.Data.ProfilePic !== ""
                          ? profileDetails.Data.ProfilePic
                          : UserPlaceholder
                      }
                      alt={commonservices.getInitials(
                        profileDetails.Data.FirstName +
                          " " +
                          profileDetails.Data.LastName
                      )}
                      width="110"
                      height="110"
                    />
                    <div className="user-profile-name">
                      {profileDetails.Data.FirstName}{" "}
                      {profileDetails.Data.LastName}
                    </div>
                    <div className="user-profile-department">
                      {profileDetails.Data.Position}
                    </div>
                  </div>
                  <div className="profile-bar-col">
                    <div className="profile-bar-status-col d-flex align-content-center justify-content-between">
                      <span className="profile-bar-status">
                        {commonservices.LabelDisplayText("Status")}
                      </span>
                      <span className="profile-bar-percentage">
                        {20 * ProfileProcessbarCompleted.length}%
                      </span>
                    </div>
                    <div className="profile-bar">
                      {ProfileProcessbarCompleted.map((item, i) => (
                        <span className="active"></span>
                      ))}
                      {ProfileProcessbar.map(
                        (item, i) =>
                          item > ProfileProcessbarCompleted.length && (
                            <span></span>
                          )
                      )}
                    </div>
                  </div>
                  <div className="profile-contact">
                    <div className="profile-contact-title">
                      {commonservices.LabelDisplayText("ContactDetail")}
                    </div>
                    <div className="contact-items">
                      <div className="contact-item d-flex">
                        <div className="contact-item-icon">
                          <GmailBlack />
                        </div>
                        <div className="contact-item-detail">
                          <span className="contact-item-top">
                            {commonservices.LabelDisplayText("OfficeEmail")}
                          </span>
                          <span className="contact-item-bottom">
                            {profileDetails.Data.EmailID}
                          </span>
                        </div>
                      </div>
                      <div className="contact-item d-flex">
                        <div className="contact-item-icon">
                          <GmailBlack />
                        </div>
                        <div className="contact-item-detail">
                          <span className="contact-item-top">
                            {commonservices.LabelDisplayText("Email")}
                          </span>
                          {SkypeId.map((item, index) => (
                            <span className="contact-item-bottom" key={index}>
                              {item.gmail}
                            </span>
                          ))}
                        </div>
                      </div>
                      <div className="contact-item d-flex">
                        <div className="contact-item-icon">
                          <SkypeBlack />
                        </div>
                        <div className="contact-item-detail">
                          <span className="contact-item-top">
                            {commonservices.LabelDisplayText("SkypeId")}
                          </span>
                          {SkypeId.map((item, index) => (
                            <span className="contact-item-bottom" key={index}>
                              {item.skype}
                            </span>
                          ))}
                        </div>
                      </div>
                      {parseInt(data[0].AssociationID) ===
                        parseInt(param.id) && (
                        <>
                          <div className="contact-item d-flex">
                            <div className="contact-item-icon">
                              <PhoneBlack />
                            </div>
                            <div className="contact-item-detail">
                              <span className="contact-item-top">
                                {commonservices.LabelDisplayText("MobileNo")}
                              </span>
                              <span className="contact-item-bottom">
                                {profileDetails.Data.MobileNo}
                              </span>
                            </div>
                          </div>
                          <div className="contact-item d-flex">
                            <div className="contact-item-icon">
                              <LocationBlack />
                            </div>
                            <div className="contact-item-detail">
                              <span className="contact-item-top">
                                {commonservices.LabelDisplayText("Address")}
                              </span>
                              <span className="contact-item-bottom">
                                {profileDetails.Data.Address1}{" "}
                                {profileDetails.Data.Address2}
                              </span>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={9} className="pe-0 pe-xl-0 pe-lg-0 pe-md-0 ps-sm-0">
              <div className="table-section-col scroll mt-xl-0 mt-lg-0 mt-md-3 mt-sm-3">
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey="personal"
                  activeKey={ActiveTab}
                >
                  <div className="text-center mt-auto mb-auto">
                    <Nav
                      variant="pills custom-tabs custom-tabs-grey d-inline-flex"
                      id={"DetailsPageMainTab"}
                    >
                      <Nav.Item onClick={(e) => setActiveTab("personal")}>
                        <Nav.Link eventKey="personal">
                          {commonservices.LabelDisplayText("Personal")}
                        </Nav.Link>
                      </Nav.Item>
                      {parseInt(data[0].AssociationID) ===
                        parseInt(param.id) && (
                        <>
                          <Nav.Item onClick={(e) => setActiveTab("leave")}>
                            <Nav.Link eventKey="leave">
                              {commonservices.LabelDisplayText("Leave")}
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item onClick={(e) => setActiveTab("document")}>
                            <Nav.Link eventKey="document">
                              {commonservices.LabelDisplayText("Document")}
                            </Nav.Link>
                          </Nav.Item>
                        </>
                      )}

                      <Nav.Item onClick={(e) => setActiveTab("achievement")}>
                        <Nav.Link eventKey="achievement">
                          {commonservices.LabelDisplayText("Achievement")}
                        </Nav.Link>
                      </Nav.Item>
                      {parseInt(data[0].AssociationID) ===
                        parseInt(param.id) && (
                        <>
                          <Nav.Item onClick={(e) => setActiveTab("experience")}>
                            <Nav.Link eventKey="experience">
                              {commonservices.LabelDisplayText("Experience")}
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item onClick={(e) => setActiveTab("family")}>
                            <Nav.Link eventKey="family">
                              {commonservices.LabelDisplayText("Family")}
                            </Nav.Link>
                          </Nav.Item>
                        </>
                      )}

                      <Nav.Item onClick={(e) => setActiveTab("work")}>
                        <Nav.Link eventKey="work">
                          {commonservices.LabelDisplayText("Work")}
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                  <Tab.Content className="pro-details-tab-content">
                    <Tab.Pane eventKey="personal">
                      <div className="profile-common-header d-flex align-content-center justify-content-between">
                        <div className="profile-common-header-title mt-auto mb-auto">
                          {commonservices.LabelDisplayText(
                            "EmployeeInformation"
                          )}
                        </div>
                        <div className="project-add-icon">
                          {parseInt(data[0].AssociationID) ===
                            parseInt(param.id) && (
                            <button
                              type="button"
                              className="project-btn btn btn-primary"
                              title="Add"
                              onClick={() =>
                                fnOpenProfileModal(profileDetails.Data)
                              }
                            >
                              <i className="fa-regular fa-plus"></i>
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="empl-information">
                        <div className="empl-infor-col">
                          <div className="empl-infor-top">
                            {commonservices.LabelDisplayText("FirstName")}
                          </div>
                          <div className="empl-infor-bottom">
                            {profileDetails.Data.FirstName}
                          </div>
                        </div>
                        <div className="empl-infor-col">
                          <div className="empl-infor-top">
                            {commonservices.LabelDisplayText("MiddleName")}
                          </div>
                          <div className="empl-infor-bottom">
                            {profileDetails.Data.MiddleName}
                          </div>
                        </div>
                        <div className="empl-infor-col">
                          <div className="empl-infor-top">
                            {commonservices.LabelDisplayText("LastName")}
                          </div>
                          <div className="empl-infor-bottom">
                            {profileDetails.Data.LastName}
                          </div>
                        </div>
                        <div className="empl-infor-col">
                          <div className="empl-infor-top">
                            {commonservices.LabelDisplayText("DOB")}
                          </div>
                          <div className="empl-infor-bottom">
                            {commonservices.getDateInDDMMYYYY(
                              profileDetails.Data.DOB
                            )}
                          </div>
                        </div>
                        <div className="empl-infor-col">
                          <div className="empl-infor-top">
                            {commonservices.LabelDisplayText("Gender")}
                          </div>
                          <div className="empl-infor-bottom">
                            {profileDetails.Data.Gender}
                          </div>
                        </div>
                        <div className="empl-infor-col">
                          <div className="empl-infor-top">
                            {commonservices.LabelDisplayText("MaritalStatus")}
                          </div>
                          <div className="empl-infor-bottom">
                            {profileDetails.Data.MaritalStatus}
                          </div>
                        </div>
                        {parseInt(data[0].AssociationID) ===
                          parseInt(param.id) && (
                          <div className="empl-infor-col">
                            <div className="empl-infor-top">
                              {commonservices.LabelDisplayText("Residence")}
                            </div>
                            <div className="empl-infor-bottom">
                              {profileDetails.Data.ResidenceType}
                            </div>
                          </div>
                        )}

                        <div className="empl-infor-col">
                          <div className="empl-infor-top">
                            {commonservices.LabelDisplayText("Language")}
                          </div>
                          <div className="empl-infor-bottom">
                            {profileDetails.Data.Languages &&
                              profileDetails.Data.Languages.split(",").map(
                                (item, index) =>
                                  item && (
                                    <label
                                      className="empl-infor-label"
                                      key={index}
                                    >
                                      {item}
                                    </label>
                                  )
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="profile-common-header qualifications d-flex align-content-center justify-content-between border-bottom-0">
                        <div className="profile-common-header-title mt-auto mb-auto">
                          {commonservices.LabelDisplayText(
                            "QualificationDetail"
                          )}
                        </div>
                        <div className="project-add-icon">
                          {parseInt(data[0].AssociationID) ===
                            parseInt(param.id) && (
                            <button
                              type="button"
                              className="project-btn btn btn-primary"
                              title="Add"
                              onClick={() =>
                                fnOpenQualificationAddEditPopup(null)
                              }
                            >
                              <i className="fa-regular fa-plus"></i>
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="vault-tab vault-page">
                        <div className="table-col">
                          <div className="recent-task-section">
                            {qualificationDetails.IsLoading ? (
                              <>
                                <Loader type={LoaderType.Table} count={4} />
                              </>
                            ) : (
                              <div className="table-scroll">
                                <Table
                                  id="tblData"
                                  className="table table-inner-section"
                                >
                                  <thead>
                                    <tr>
                                      <th className="width-400px padding-top-bottom">
                                        {commonservices.LabelDisplayText(
                                          "TypeOfDegree"
                                        )}
                                      </th>
                                      <th className="text-nowrap padding-top-bottom">
                                        {commonservices.LabelDisplayText(
                                          "Institute"
                                        )}
                                      </th>
                                      <th className="width-150px padding-top-bottom">
                                        {commonservices.LabelDisplayText(
                                          "Duration"
                                        )}
                                      </th>
                                      <th className="width-150px text-nowrap padding-top-bottom">
                                        {commonservices.LabelDisplayText(
                                          "Result"
                                        )}
                                      </th>
                                      <th className="text-nowrap padding-top-bottom width-150px">
                                        {commonservices.LabelDisplayText(
                                          "MainSubject"
                                        )}
                                      </th>
                                      <th className="text-nowrap text-end padding-top-bottom width-50px">
                                        {commonservices.LabelDisplayText(
                                          "Action"
                                        )}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="table-section-col bg-transparent">
                                    {qualificationDetails.Data.length > 0 &&
                                      qualificationDetails.Data.map(
                                        (item, index) => (
                                          <tr key={index}>
                                            <td className="padding-top-bottom width-400px">
                                              <div className="project-description">
                                                {item.DegreeType}
                                              </div>
                                            </td>
                                            <td className="text-nowrap padding-top-bottom">
                                              <div className="d-flex align-items-center">
                                                <div className="table-text-common">
                                                  {item.Institute}
                                                </div>
                                              </div>
                                            </td>
                                            <td className="width-150px padding-top-bottom">
                                              <div className="table-text-common">
                                                {item.Duration}
                                              </div>
                                            </td>
                                            <td className="width-150px padding-top-bottom">
                                              <div className="d-flex table-text-common">
                                                {item.Result}
                                              </div>
                                            </td>
                                            <td className="padding-top-bottom width-150px">
                                              <div className="table-text-common">
                                                {item.MainSubject}
                                              </div>
                                            </td>
                                            <td className="text-end padding-top-bottom width-150px">
                                              {parseInt(
                                                data[0].AssociationID
                                              ) === parseInt(param.id) && (
                                                <>
                                                  <Button
                                                    className="icon-btn h-auto btn btn-primary me-1"
                                                    type="button"
                                                    title="Edit"
                                                    onClick={(e) =>
                                                      fnOpenQualificationAddEditPopup(
                                                        item.EmpQualificationID
                                                      )
                                                    }
                                                  >
                                                    <Pencil />
                                                  </Button>
                                                  <Button
                                                    className="icon-btn h-auto"
                                                    title="Delete"
                                                    onClick={(e) => {
                                                      confirmAlert({
                                                        message:
                                                          commonservices.LabelDisplayText(
                                                            "AreYouSureToDoDelete"
                                                          ),
                                                        buttons: [
                                                          {
                                                            label:
                                                              commonservices.LabelDisplayText(
                                                                "Yes"
                                                              ),
                                                            onClick: () =>
                                                              deleteQualification(
                                                                item.EmpQualificationID
                                                              ),
                                                          },
                                                          {
                                                            label:
                                                              commonservices.LabelDisplayText(
                                                                "No"
                                                              ),
                                                          },
                                                        ],
                                                      });
                                                    }}
                                                  >
                                                    <Trash />
                                                  </Button>
                                                </>
                                              )}
                                            </td>
                                          </tr>
                                        )
                                      )}
                                  </tbody>
                                </Table>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>

                    {parseInt(data[0].AssociationID) === parseInt(param.id) && (
                      <>
                        <Tab.Pane eventKey="leave">
                          <div className="profile-common-header d-flex align-content-center justify-content-between">
                            <div className="profile-common-header-title mt-auto mb-auto">
                              {commonservices.LabelDisplayText("LeaveDetail")}
                            </div>
                          </div>
                          <div className="cards">
                            <Row>
                              {LeaveCount.IsLoading ? (
                                <Loader type={LoaderType.CountBox} count={2} />
                              ) : (
                                LeaveCount.Data.map((item, index) => (
                                  <Col xl={3} lg={4} md={6}>
                                    <Card
                                      className={
                                        index % 2 === 0
                                          ? "today-task blue-crayola square-bg-img"
                                          : "profile-complete pastel-orange hexagon-bg-img"
                                      }
                                    >
                                      <Card.Body>
                                        <Card.Title className="ms-auto me-0">
                                          {item.TotalLeave}
                                        </Card.Title>
                                        <Card.Text>
                                          {commonservices.LabelDisplayText(
                                            "Total"
                                          )}
                                          <span className="d-block">
                                            {item.LeaveName}
                                          </span>
                                        </Card.Text>
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                ))
                              )}
                            </Row>
                          </div>
                          <div className="profile-common-header add-button d-flex align-content-center justify-content-between border-bottom-0">
                            <div className="profile-common-header-title mt-auto mb-auto">
                              {commonservices.LabelDisplayText("LeaveRequest")}
                            </div>
                            <div className="project-add-icon">
                              {parseInt(data[0].AssociationID) ===
                                parseInt(param.id) && (
                                <Button
                                  className="project-btn btn btn-primary"
                                  title="Add"
                                  type="button"
                                  onClick={(e) => fnLeaveOpen()}
                                >
                                  <i className="fa-regular fa-plus"></i>
                                </Button>
                              )}
                            </div>
                          </div>
                          <div className="vault-tab vault-page">
                            <div className="table-col">
                              <div className="recent-task-section">
                                <div className="table-scroll">
                                  {leaveData.IsLoading ? (
                                    <Loader type={LoaderType.Table} />
                                  ) : (
                                    <>
                                      {leaveData.Data.length === 0 ? (
                                        <Loader type={LoaderType.NODATAFOUND} />
                                      ) : (
                                        <Table
                                          id="leaverequesttblData"
                                          className="table table-inner-section w-100"
                                        >
                                          <tbody>
                                            {leaveData.Data.map(
                                              (itemLeave, rowindex) => (
                                                <tr className="table-hight-remove">
                                                  <td className="width-60px padding-top-bottom pe-0">
                                                    <span
                                                      className={`rounded-circle leave-request-type ${itemLeave.LeaveCode.toLowerCase()}`}
                                                    >
                                                      {itemLeave.LeaveCode}
                                                    </span>
                                                  </td>
                                                  <td className="text-nowrap padding-top-bottom">
                                                    <div className="leave-reason">
                                                      {itemLeave.Title}
                                                    </div>
                                                  </td>
                                                  <td className="text-end width-220px">
                                                    <div className="d-flex text-nowrap justify-content-end">
                                                      <div className="apply-date mt-auto mb-auto">
                                                        {commonservices.getDateInDDMMYYYY(
                                                          itemLeave.StartDate
                                                        )}{" "}
                                                        <b>
                                                          For{" "}
                                                          {itemLeave.NoOfDays}{" "}
                                                          Days
                                                        </b>
                                                      </div>
                                                      <div className="leave-status border-start">
                                                        <label
                                                          className={`leave-label ${itemLeave.ApplicationStatus.toLowerCase()}`}
                                                        >
                                                          {
                                                            itemLeave.ApplicationStatus
                                                          }
                                                        </label>
                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td className="text-end width-50px">
                                                    {itemLeave.ApplicationStatus.toUpperCase() ===
                                                    "PENDING" ? (
                                                      <Button
                                                        className="icon-btn h-auto w-auto"
                                                        title="Delete"
                                                        onClick={(e) => {
                                                          confirmAlert({
                                                            message:
                                                              commonservices.LabelDisplayText(
                                                                "AreYouSureToCancel"
                                                              ),
                                                            buttons: [
                                                              {
                                                                label:
                                                                  commonservices.LabelDisplayText(
                                                                    "Yes"
                                                                  ),
                                                                onClick: () =>
                                                                  CancelLeaveRequest(
                                                                    itemLeave.LeaveApplicationID
                                                                  ),
                                                              },
                                                              {
                                                                label:
                                                                  commonservices.LabelDisplayText(
                                                                    "No"
                                                                  ),
                                                              },
                                                            ],
                                                          });
                                                        }}
                                                      >
                                                        <Trash />
                                                      </Button>
                                                    ) : (
                                                      <Button
                                                        className="icon-btn h-auto w-auto"
                                                        title="Show"
                                                        onClick={(e) => {
                                                          setLeaveApplicationID(
                                                            itemLeave.LeaveApplicationID
                                                          );
                                                          setIsShowApproveRejectLeaveModal(
                                                            true
                                                          );
                                                        }}
                                                      >
                                                        <EyeshowPassword />
                                                      </Button>
                                                    )}
                                                  </td>
                                                </tr>
                                              )
                                            )}
                                          </tbody>
                                        </Table>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="document">
                          <div className="profile-common-header add-button d-flex align-content-center justify-content-between border-bottom-0">
                            <div className="profile-common-header-title mt-auto mb-auto">
                              {commonservices.LabelDisplayText("Documents")}
                            </div>
                            <div className="project-add-icon">
                              {parseInt(data[0].AssociationID) ===
                                parseInt(param.id) && (
                                <Button
                                  className="project-btn btn btn-primary"
                                  title="Add"
                                  type="button"
                                  onClick={(e) =>
                                    fnOpenEmployeeAddEditPopup(
                                      profileDetails.Data.EmployeeID
                                    )
                                  }
                                >
                                  <i className="fa-regular fa-plus"></i>
                                </Button>
                              )}
                            </div>
                          </div>
                          <div className="profile-documents-tabs">
                            <Tab.Container
                              id="left-tabs-example"
                              defaultActiveKey="personal"
                            >
                              <Row>
                                <Col sm={3}>
                                  <div className="table-section-col documents-page-tab">
                                    <Nav
                                      variant="pills"
                                      className="flex-column"
                                    >
                                      <Nav.Item>
                                        <Nav.Link
                                          eventKey="personal"
                                          className="d-flex align-items-center justify-content-between"
                                        >
                                          {commonservices.LabelDisplayText(
                                            "Office"
                                          )}{" "}
                                          <NextIcon />
                                        </Nav.Link>
                                      </Nav.Item>
                                      {/*<Nav.Item>*/}
                                      {/*  <Nav.Link*/}
                                      {/*    eventKey="office"*/}
                                      {/*    className="d-flex align-items-center justify-content-between"*/}
                                      {/*  >*/}
                                      {/*    {commonservices.LabelDisplayText(*/}
                                      {/*      "Office"*/}
                                      {/*    )}{" "}*/}
                                      {/*    <NextIcon />*/}
                                      {/*  </Nav.Link>*/}
                                      {/*</Nav.Item>*/}
                                      <Nav.Item>
                                        <Nav.Link
                                          eventKey="salary"
                                          className="d-flex align-items-center justify-content-between"
                                        >
                                          {commonservices.LabelDisplayText(
                                            "Salary"
                                          )}{" "}
                                          <NextIcon />
                                        </Nav.Link>
                                      </Nav.Item>
                                    </Nav>
                                  </div>
                                </Col>
                                <Col sm={9}>
                                  <Tab.Content>
                                    <Tab.Pane eventKey="personal">
                                      <Container fluid className="p-0">
                                        {documentDetails.IsLoading ? (
                                          <>
                                            <Loader
                                              type={LoaderType.Line}
                                              count={4}
                                            />
                                          </>
                                        ) : (
                                          <div className="profile-document-lists">
                                            {/*Personal Documents Are Here*/}
                                            {documentDetails.Data.length > 0 &&
                                              documentDetails.Data?.filter(
                                                (itm) =>
                                                  itm.AssociationType?.toLowerCase() ===
                                                  ("employees" || "personal")
                                              ).map((item, index) => (
                                                <div
                                                  title={item.DocName}
                                                  className="profile-document-list"
                                                >
                                                  <div
                                                    className="profile-document-file"
                                                    onClick={(e) =>
                                                      viewFilePopupService.viewFilePopup(
                                                        {
                                                          data: [item],
                                                        }
                                                      )
                                                    }
                                                  >
                                                    {item.FileType ===
                                                    "IMAGE" ? (
                                                      <img
                                                        className="profile-files-img"
                                                        src={item.FilePath}
                                                        alt=""
                                                      />
                                                    ) : (
                                                      <img
                                                        className="profile-files-img"
                                                        src={
                                                          AppConfigData
                                                            .FileTypePath[
                                                            item.FileType.toUpperCase()
                                                          ]
                                                        }
                                                        alt=""
                                                      />
                                                    )}
                                                  </div>
                                                  <div className="profile-document-bottom">
                                                    <div className="document-details">
                                                      <div className="document-name">
                                                        {item.DocName}
                                                      </div>
                                                      <div className="document-file-size-data">
                                                        <div className="document-file-size">
                                                          {item.SizeInMB} MB
                                                        </div>
                                                        <div className="document-file-data">
                                                          {commonservices.getDays(
                                                            commonservices.getDateInDDMMYYYY(
                                                              item.CreatedOn
                                                            )
                                                          )}
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="document-file-icons d-flex">
                                                      {parseInt(
                                                        data[0].AssociationID
                                                      ) ===
                                                        parseInt(param.id) && (
                                                        <>
                                                          <a
                                                            href={item.FilePath}
                                                            target="_blank"
                                                            className="p-0 download-button border-0 me-3 bg-transparent"
                                                            type="button"
                                                          >
                                                            <DownloadIcon />
                                                          </a>
                                                          <button
                                                            className="p-0 remove-button border-0 bg-transparent"
                                                            type="button"
                                                            title="Delete"
                                                            onClick={(e) => {
                                                              confirmAlert({
                                                                message:
                                                                  commonservices.LabelDisplayText(
                                                                    "AreYouSureToDoDelete"
                                                                  ),
                                                                buttons: [
                                                                  {
                                                                    label:
                                                                      commonservices.LabelDisplayText(
                                                                        "Yes"
                                                                      ),
                                                                    onClick:
                                                                      () =>
                                                                        removeDoc(
                                                                          item.DocumentID
                                                                        ),
                                                                  },
                                                                  {
                                                                    label:
                                                                      commonservices.LabelDisplayText(
                                                                        "No"
                                                                      ),
                                                                  },
                                                                ],
                                                              });
                                                            }}
                                                          >
                                                            <Trash />
                                                          </button>
                                                        </>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              ))}
                                          </div>
                                        )}
                                      </Container>
                                    </Tab.Pane>
                                    {/*<Tab.Pane eventKey="office">*/}
                                    {/*  <Container fluid className="p-0">*/}
                                    {/*    {documentDetails.IsLoading ? (*/}
                                    {/*      <>*/}
                                    {/*        <Loader*/}
                                    {/*          type={LoaderType.Line}*/}
                                    {/*          count={4}*/}
                                    {/*        />*/}
                                    {/*      </>*/}
                                    {/*    ) : (*/}
                                    {/*      <div className="profile-document-lists">*/}
                                    {/*        */}
                                    {/*Office Documents Are Here*/}
                                    {/*        {documentDetails.Data.length > 0 &&*/}
                                    {/*          documentDetails.Data?.filter(*/}
                                    {/*            (itm) =>*/}
                                    {/*              itm.AssociationType?.toLowerCase() ===*/}
                                    {/*              "office"*/}
                                    {/*          ).map((item, index) => (*/}
                                    {/*            <div title={item.DocName} className="profile-document-list">*/}
                                    {/*              <div*/}
                                    {/*                className="profile-document-file"*/}
                                    {/*                onClick={(e) =>*/}
                                    {/*                  viewFilePopupService.viewFilePopup(*/}
                                    {/*                    {*/}
                                    {/*                      data: [item],*/}
                                    {/*                    }*/}
                                    {/*                  )*/}
                                    {/*                }*/}
                                    {/*              >*/}
                                    {/*                {item.FileType ===*/}
                                    {/*                  "IMAGE" ? (*/}
                                    {/*                  <img*/}
                                    {/*                    className="profile-files-img"*/}
                                    {/*                    src={item.FilePath}*/}
                                    {/*                    alt=""*/}
                                    {/*                  />*/}
                                    {/*                ) : (*/}
                                    {/*                  <img*/}
                                    {/*                    className="profile-files-img"*/}
                                    {/*                    src={*/}
                                    {/*                      AppConfigData*/}
                                    {/*                        .FileTypePath[*/}
                                    {/*                      item.FileType.toUpperCase()*/}
                                    {/*                      ]*/}
                                    {/*                    }*/}
                                    {/*                    alt=""*/}
                                    {/*                  />*/}
                                    {/*                )}*/}
                                    {/*              </div>*/}
                                    {/*              <div className="profile-document-bottom">*/}
                                    {/*                <div className="document-details">*/}
                                    {/*                  <div className="document-name">*/}
                                    {/*                    {item.DocName}*/}
                                    {/*                  </div>*/}
                                    {/*                  <div className="document-file-size-data">*/}
                                    {/*                    <div className="document-file-size">*/}
                                    {/*                      {item.SizeInMB} MB*/}
                                    {/*                    </div>*/}
                                    {/*                    <div className="document-file-data">*/}
                                    {/*                      {commonservices.getDays(*/}
                                    {/*                        commonservices.getDateInDDMMYYYY(*/}
                                    {/*                          item.CreatedOn*/}
                                    {/*                        )*/}
                                    {/*                      )}*/}
                                    {/*                    </div>*/}
                                    {/*                  </div>*/}
                                    {/*                </div>*/}
                                    {/*                <div className="document-file-icons d-flex">*/}
                                    {/*                  {parseInt(*/}
                                    {/*                    data[0].AssociationID*/}
                                    {/*                  ) ===*/}
                                    {/*                    parseInt(param.id) && (*/}
                                    {/*                      <>*/}
                                    {/*                        <a*/}
                                    {/*                          href={item.FilePath}*/}
                                    {/*                          target="_blank"*/}
                                    {/*                          className="p-0 download-button border-0 me-3 bg-transparent"*/}
                                    {/*                          type="button"*/}
                                    {/*                        >*/}
                                    {/*                          <DownloadIcon />*/}
                                    {/*                        </a>*/}
                                    {/*                        <button*/}
                                    {/*                          className="p-0 remove-button border-0 bg-transparent"*/}
                                    {/*                          type="button"*/}
                                    {/*                          title="Delete"*/}
                                    {/*                          onClick={(e) => {*/}
                                    {/*                            confirmAlert({*/}
                                    {/*                              message:*/}
                                    {/*                                commonservices.LabelDisplayText(*/}
                                    {/*                                  "AreYouSureToDoDelete"*/}
                                    {/*                                ),*/}
                                    {/*                              buttons: [*/}
                                    {/*                                {*/}
                                    {/*                                  label:*/}
                                    {/*                                    commonservices.LabelDisplayText(*/}
                                    {/*                                      "Yes"*/}
                                    {/*                                    ),*/}
                                    {/*                                  onClick:*/}
                                    {/*                                    () =>*/}
                                    {/*                                      removeDoc(*/}
                                    {/*                                        item.DocumentID*/}
                                    {/*                                      ),*/}
                                    {/*                                },*/}
                                    {/*                                {*/}
                                    {/*                                  label:*/}
                                    {/*                                    commonservices.LabelDisplayText(*/}
                                    {/*                                      "No"*/}
                                    {/*                                    ),*/}
                                    {/*                                },*/}
                                    {/*                              ],*/}
                                    {/*                            });*/}
                                    {/*                          }}*/}
                                    {/*                        >*/}
                                    {/*                          <Trash />*/}
                                    {/*                        </button>*/}
                                    {/*                      </>*/}
                                    {/*                    )}*/}
                                    {/*                </div>*/}
                                    {/*              </div>*/}
                                    {/*            </div>*/}
                                    {/*          ))}*/}
                                    {/*      </div>*/}
                                    {/*    )}*/}
                                    {/*  </Container>*/}
                                    {/*</Tab.Pane>*/}
                                    <Tab.Pane eventKey="salary">
                                      <Container fluid className="p-0">
                                        {documentDetails.IsLoading ? (
                                          <>
                                            <Loader
                                              type={LoaderType.Line}
                                              count={4}
                                            />
                                          </>
                                        ) : (
                                          <div className="profile-document-lists">
                                            {/*Salary Documents Are Here*/}

                                            {documentDetails.Data.length > 0 &&
                                              documentDetails.Data?.filter(
                                                (itm) =>
                                                  itm.AssociationType?.toLowerCase() ===
                                                  "salary slip"
                                              ).map((item, index) => (
                                                <div
                                                  title={item.DocName}
                                                  className="profile-document-list"
                                                >
                                                  <div
                                                    className="profile-document-file"
                                                    onClick={(e) =>
                                                      viewFilePopupService.viewFilePopup(
                                                        {
                                                          data: [item],
                                                        }
                                                      )
                                                    }
                                                  >
                                                    {item.FileType ===
                                                    "IMAGE" ? (
                                                      <img
                                                        className="profile-files-img"
                                                        src={item.FilePath}
                                                        alt=""
                                                      />
                                                    ) : (
                                                      <img
                                                        className="profile-files-img"
                                                        src={
                                                          AppConfigData
                                                            .FileTypePath[
                                                            item.FileType.toUpperCase()
                                                          ]
                                                        }
                                                        alt=""
                                                      />
                                                    )}
                                                  </div>
                                                  <div className="profile-document-bottom">
                                                    <div className="document-details">
                                                      <div className="document-name">
                                                        {item.DocName}
                                                      </div>
                                                      <div className="document-file-size-data">
                                                        <div className="document-file-size">
                                                          {item.SizeInMB} MB
                                                        </div>
                                                        <div className="document-file-data">
                                                          {commonservices.getDays(
                                                            commonservices.getDateInDDMMYYYY(
                                                              item.CreatedOn
                                                            )
                                                          )}
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="document-file-icons d-flex">
                                                      {parseInt(
                                                        data[0].AssociationID
                                                      ) ===
                                                        parseInt(param.id) && (
                                                        <>
                                                          <a
                                                            href={item.FilePath}
                                                            target="_blank"
                                                            className="p-0 download-button border-0 me-3 bg-transparent"
                                                            type="button"
                                                          >
                                                            <DownloadIcon />
                                                          </a>
                                                          <button
                                                            className="p-0 remove-button border-0 bg-transparent"
                                                            type="button"
                                                            title="Delete"
                                                            onClick={(e) => {
                                                              confirmAlert({
                                                                message:
                                                                  commonservices.LabelDisplayText(
                                                                    "AreYouSureToDoDelete"
                                                                  ),
                                                                buttons: [
                                                                  {
                                                                    label:
                                                                      commonservices.LabelDisplayText(
                                                                        "Yes"
                                                                      ),
                                                                    onClick:
                                                                      () =>
                                                                        removeDoc(
                                                                          item.DocumentID
                                                                        ),
                                                                  },
                                                                  {
                                                                    label:
                                                                      commonservices.LabelDisplayText(
                                                                        "No"
                                                                      ),
                                                                  },
                                                                ],
                                                              });
                                                            }}
                                                          >
                                                            <Trash />
                                                          </button>
                                                        </>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              ))}
                                          </div>
                                        )}
                                      </Container>
                                    </Tab.Pane>
                                  </Tab.Content>
                                </Col>
                              </Row>
                            </Tab.Container>
                          </div>
                        </Tab.Pane>
                      </>
                    )}

                    <Tab.Pane eventKey="achievement">
                      <div className="profile-common-header qualifications d-flex align-content-center justify-content-between border-bottom-0">
                        <div className="profile-common-header-title mt-auto mb-auto">
                          {commonservices.LabelDisplayText("Achievement")}
                        </div>
                        <div className="project-add-icon">
                          {parseInt(data[0].AssociationID) ===
                            parseInt(param.id) && (
                            <button
                              type="button"
                              title="Add"
                              className="project-btn btn btn-primary"
                              onClick={() =>
                                fnOpenachievementAddEditPopup(null)
                              }
                            >
                              <i className="fa-regular fa-plus"></i>
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="vault-tab vault-page">
                        <div className="table-col">
                          <div className="recent-task-section">
                            {achievementDetails.IsLoading ? (
                              <>
                                <Loader type={LoaderType.Table} count={4} />
                              </>
                            ) : (
                              <div className="table-scroll">
                                <Table
                                  id="achievementtblData"
                                  className="table table-inner-section"
                                >
                                  <thead>
                                    <tr>
                                      <th className="padding-top-bottom text-nowrap width-300px">
                                        {commonservices.LabelDisplayText(
                                          "LicenseAndCertification"
                                        )}
                                      </th>
                                      <th className="width-250px padding-top-bottom">
                                        {commonservices.LabelDisplayText(
                                          "Institution"
                                        )}
                                      </th>
                                      <th className="padding-top-bottom">
                                        {commonservices.LabelDisplayText(
                                          "Skills"
                                        )}
                                      </th>
                                      <th className="padding-top-bottom">
                                        {commonservices.LabelDisplayText(
                                          "Credentials"
                                        )}
                                      </th>
                                      <th className="text-nowrap padding-top-bottom width-100px">
                                        {commonservices.LabelDisplayText(
                                          "IssueDate"
                                        )}
                                      </th>
                                      <th className="text-nowrap text-end padding-top-bottom width-100px">
                                        {commonservices.LabelDisplayText(
                                          "Action"
                                        )}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="table-section-col bg-transparent">
                                    {achievementDetails.Data.length > 0 &&
                                      achievementDetails.Data.map(
                                        (item, index) => (
                                          <tr key={index}>
                                            <td className="padding-top-bottom width-300px">
                                              <div className="table-text-common">
                                                {item.Type}
                                              </div>
                                            </td>
                                            <td className="text-nowrap padding-top-bottom width-250px">
                                              <div className="d-flex align-items-center">
                                                <div className="table-text-common">
                                                  {item.Institute}
                                                </div>
                                              </div>
                                            </td>
                                            <td className="padding-top-bottom">
                                              <div className="table-text-common text-nowrap">
                                                {item.Skills}
                                              </div>
                                            </td>
                                            <td className="padding-top-bottom">
                                              <div className="d-flex table-text-common credentials">
                                                {item.Credentials}
                                              </div>
                                            </td>
                                            <td className="padding-top-bottom width-100px">
                                              <div className="table-text-common">
                                                {commonservices.getDateInDDMMYYYY(
                                                  item.IssueDate
                                                )}
                                              </div>
                                            </td>
                                            <td className="text-end padding-top-bottom width-150px">
                                              {parseInt(
                                                data[0].AssociationID
                                              ) === parseInt(param.id) && (
                                                <>
                                                  <Button
                                                    className="icon-btn h-auto btn btn-primary me-1"
                                                    type="button"
                                                    title="Edit"
                                                    onClick={(e) =>
                                                      fnOpenachievementAddEditPopup(
                                                        item.EmpAchievementID
                                                      )
                                                    }
                                                  >
                                                    <Pencil />
                                                  </Button>
                                                  <Button
                                                    className="icon-btn h-auto"
                                                    title="Delete"
                                                    onClick={(e) => {
                                                      confirmAlert({
                                                        message:
                                                          commonservices.LabelDisplayText(
                                                            "AreYouSureToDoDelete"
                                                          ),
                                                        buttons: [
                                                          {
                                                            label:
                                                              commonservices.LabelDisplayText(
                                                                "Yes"
                                                              ),
                                                            onClick: () =>
                                                              deleteAchievement(
                                                                item.EmpAchievementID
                                                              ),
                                                          },
                                                          {
                                                            label:
                                                              commonservices.LabelDisplayText(
                                                                "No"
                                                              ),
                                                          },
                                                        ],
                                                      });
                                                    }}
                                                  >
                                                    <Trash />
                                                  </Button>
                                                </>
                                              )}
                                            </td>
                                          </tr>
                                        )
                                      )}
                                  </tbody>
                                </Table>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    {parseInt(data[0].AssociationID) === parseInt(param.id) && (
                      <>
                        <Tab.Pane eventKey="experience">
                          <div className="profile-common-header qualifications d-flex align-content-center justify-content-between border-bottom-0">
                            <div className="profile-common-header-title mt-auto mb-auto">
                              {commonservices.LabelDisplayText(
                                "WorkExperience"
                              )}
                            </div>
                            <div className="project-add-icon">
                              {parseInt(data[0].AssociationID) ===
                                parseInt(param.id) && (
                                <button
                                  type="button"
                                  className="project-btn btn btn-primary"
                                  title="Add"
                                  onClick={() =>
                                    fnOpenExperienceAddEditPopup(null)
                                  }
                                >
                                  <i className="fa-regular fa-plus"></i>
                                </button>
                              )}
                            </div>
                          </div>
                          <div className="vault-tab vault-page">
                            <div className="table-col">
                              <div className="recent-task-section">
                                {experienceDetails.IsLoading ? (
                                  <>
                                    <Loader type={LoaderType.Table} count={4} />
                                  </>
                                ) : (
                                  <div className="table-scroll">
                                    <Table
                                      id="experiencetblData"
                                      className="table table-inner-section"
                                    >
                                      <thead>
                                        <tr>
                                          <th className="padding-top-bottom text-nowrap width-200px">
                                            {commonservices.LabelDisplayText(
                                              "CompanyName"
                                            )}
                                          </th>
                                          <th className="width-150px padding-top-bottom">
                                            {commonservices.LabelDisplayText(
                                              "Address"
                                            )}
                                          </th>
                                          <th className="padding-top-bottom width-150px">
                                            {commonservices.LabelDisplayText(
                                              "Period"
                                            )}
                                          </th>
                                          <th className="text-nowrap padding-top-bottom width-250px">
                                            {commonservices.LabelDisplayText(
                                              "DesignationAndMajor"
                                            )}
                                            <span className="d-block">
                                              {commonservices.LabelDisplayText(
                                                "Responsibilities"
                                              )}
                                            </span>
                                          </th>
                                          <th className="text-nowrap padding-top-bottom width-150px">
                                            {commonservices.LabelDisplayText(
                                              "GrossIncome"
                                            )}{" "}
                                            <span className="d-block">
                                              (
                                              {commonservices.LabelDisplayText(
                                                "Year"
                                              )}
                                              )
                                            </span>
                                          </th>
                                          <th className="text-nowrap padding-top-bottom width-200px">
                                            {commonservices.LabelDisplayText(
                                              "ReasonForLeaving"
                                            )}
                                          </th>
                                          <th className="text-nowrap text-end padding-top-bottom width-100px">
                                            {commonservices.LabelDisplayText(
                                              "Action"
                                            )}
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody className="table-section-col bg-transparent">
                                        {experienceDetails.Data.length > 0 &&
                                          experienceDetails.Data.map(
                                            (item, index) => (
                                              <tr>
                                                <td className="padding-top-bottom width-200px">
                                                  <div className="table-text-common">
                                                    {item.CompanyName}
                                                  </div>
                                                </td>
                                                <td className="text-nowrap padding-top-bottom width-150px">
                                                  <div className="d-flex align-items-center">
                                                    <div className="table-text-common">
                                                      {item.Address}
                                                    </div>
                                                  </div>
                                                </td>
                                                <td className="padding-top-bottom width-150px">
                                                  <div className="table-text-common">
                                                    {item.Duration}
                                                  </div>
                                                </td>
                                                <td className="padding-top-bottom width-250px">
                                                  <div className="d-flex table-text-common">
                                                    {item.Designation}
                                                    {" & "}
                                                    {item.Responsibilities}
                                                  </div>
                                                </td>
                                                <td className="padding-top-bottom width-150px">
                                                  <div className="d-flex table-text-common">
                                                    {item.GrossIncome}
                                                  </div>
                                                </td>
                                                <td className="padding-top-bottom width-200px">
                                                  <div className="table-text-common">
                                                    {item.ResonForLeving}
                                                  </div>
                                                </td>
                                                <td className="text-end padding-top-bottom width-100px">
                                                  {parseInt(
                                                    data[0].AssociationID
                                                  ) === parseInt(param.id) && (
                                                    <>
                                                      <Button
                                                        className="icon-btn h-auto btn btn-primary me-1"
                                                        type="button"
                                                        title="Edit"
                                                        onClick={(e) =>
                                                          fnOpenExperienceAddEditPopup(
                                                            item.EmpWorkExperienceID
                                                          )
                                                        }
                                                      >
                                                        <Pencil />
                                                      </Button>
                                                      <Button
                                                        className="icon-btn h-auto"
                                                        title="Delete"
                                                        onClick={(e) => {
                                                          confirmAlert({
                                                            message:
                                                              commonservices.LabelDisplayText(
                                                                "AreYouSureToDoDelete"
                                                              ),
                                                            buttons: [
                                                              {
                                                                label:
                                                                  commonservices.LabelDisplayText(
                                                                    "Yes"
                                                                  ),
                                                                onClick: () =>
                                                                  deleteExperience(
                                                                    item.EmpWorkExperienceID
                                                                  ),
                                                              },
                                                              {
                                                                label:
                                                                  commonservices.LabelDisplayText(
                                                                    "No"
                                                                  ),
                                                              },
                                                            ],
                                                          });
                                                        }}
                                                      >
                                                        <Trash />
                                                      </Button>
                                                    </>
                                                  )}
                                                </td>
                                              </tr>
                                            )
                                          )}
                                      </tbody>
                                    </Table>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="family">
                          <div className="profile-common-header qualifications d-flex align-content-center justify-content-between border-bottom-0">
                            <div className="profile-common-header-title mt-auto mb-auto">
                              {commonservices.LabelDisplayText("FamilyDetail")}
                            </div>
                            <div className="project-add-icon">
                              {parseInt(data[0].AssociationID) ===
                                parseInt(param.id) && (
                                <button
                                  type="button"
                                  className="project-btn btn btn-primary"
                                  title="Add"
                                  onClick={() => fnOpenFamilyAddEditPopup(null)}
                                >
                                  <i className="fa-regular fa-plus"></i>
                                </button>
                              )}
                            </div>
                          </div>
                          <div className="vault-tab vault-page">
                            <div className="table-col">
                              <div className="recent-task-section">
                                {FamilyDetails.IsLoading ? (
                                  <>
                                    <Loader type={LoaderType.Table} count={4} />
                                  </>
                                ) : (
                                  <div className="table-scroll">
                                    <Table
                                      id="familytblData"
                                      className="table table-inner-section"
                                    >
                                      <thead>
                                        <tr>
                                          <th className="padding-top-bottom">
                                            Name
                                          </th>
                                          <th className="width-150px padding-top-bottom">
                                            {commonservices.LabelDisplayText(
                                              "Relationship"
                                            )}
                                          </th>
                                          <th className="width-150px padding-top-bottom">
                                            {commonservices.LabelDisplayText(
                                              "DOB"
                                            )}
                                          </th>
                                          <th className="width-150px padding-top-bottom">
                                            {commonservices.LabelDisplayText(
                                              "Occupation"
                                            )}
                                          </th>
                                          <th className="text-nowrap padding-top-bottom width-200px">
                                            {commonservices.LabelDisplayText(
                                              "NameOfEmployer"
                                            )}
                                          </th>
                                          <th className="text-nowrap padding-top-bottom width-100px">
                                            {commonservices.LabelDisplayText(
                                              "Dependent"
                                            )}
                                          </th>
                                          <th className="text-nowrap text-end padding-top-bottom width-100px">
                                            {commonservices.LabelDisplayText(
                                              "Action"
                                            )}
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody className="table-section-col bg-transparent">
                                        {FamilyDetails.Data.length > 0 &&
                                          FamilyDetails.Data.map(
                                            (item, index) => (
                                              <tr>
                                                <td className="padding-top-bottom">
                                                  <div className="table-text-common">
                                                    {item.Name}
                                                  </div>
                                                </td>
                                                <td className="text-nowrap padding-top-bottom width-150px">
                                                  <div className="d-flex align-items-center">
                                                    <div className="table-text-common">
                                                      {item.Relationship}
                                                    </div>
                                                  </div>
                                                </td>
                                                <td className="width-150px padding-top-bottom">
                                                  <div className="table-text-common">
                                                    {commonservices.getDateInDDMMYYYY(
                                                      item.DOB
                                                    )}
                                                  </div>
                                                </td>
                                                <td className="width-150px padding-top-bottom">
                                                  <div className="d-flex table-text-common">
                                                    {item.Occupation}
                                                  </div>
                                                </td>
                                                <td className="padding-top-bottom width-200px">
                                                  <div className="table-text-common">
                                                    {item.NameOfEmployer}
                                                  </div>
                                                </td>
                                                <td className="padding-top-bottom width-100px">
                                                  <div className="table-text-common">
                                                    {item.IsDependent === 1
                                                      ? "Yes"
                                                      : "No"}
                                                  </div>
                                                </td>
                                                <td className="text-end padding-top-bottom width-150px">
                                                  {parseInt(
                                                    data[0].AssociationID
                                                  ) === parseInt(param.id) && (
                                                    <>
                                                      <Button
                                                        className="icon-btn h-auto btn btn-primary me-1"
                                                        title="Edit"
                                                        type="button"
                                                        onClick={(e) =>
                                                          fnOpenFamilyAddEditPopup(
                                                            item.EmpFamilyDetailID
                                                          )
                                                        }
                                                      >
                                                        <Pencil />
                                                      </Button>
                                                      <Button
                                                        className="icon-btn h-auto"
                                                        title="Delete"
                                                        onClick={(e) => {
                                                          confirmAlert({
                                                            message:
                                                              commonservices.LabelDisplayText(
                                                                "AreYouSureToDoDelete"
                                                              ),
                                                            buttons: [
                                                              {
                                                                label:
                                                                  commonservices.LabelDisplayText(
                                                                    "Yes"
                                                                  ),
                                                                onClick: () =>
                                                                  deleteFamily(
                                                                    item.EmpFamilyDetailID
                                                                  ),
                                                              },
                                                              {
                                                                label:
                                                                  commonservices.LabelDisplayText(
                                                                    "No"
                                                                  ),
                                                              },
                                                            ],
                                                          });
                                                        }}
                                                      >
                                                        <Trash />
                                                      </Button>
                                                    </>
                                                  )}
                                                </td>
                                              </tr>
                                            )
                                          )}
                                      </tbody>
                                    </Table>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                      </>
                    )}
                    <Tab.Pane eventKey="work">
                      <div className="work-detail-tab">
                        <div className="profile-common-header qualifications d-flex align-content-center justify-content-between border-bottom-0">
                          <div className="profile-common-header-title mt-auto mb-auto">
                            {commonservices.LabelDisplayText("WorkDetail")}
                          </div>
                        </div>
                        <div className="working-hours-chart mt-2 mb-4">
                          {WorkingHoursChart.IsLoading ? (
                            <Loader type={LoaderType.Line} />
                          ) : (
                            <div className="home-chart">
                              <div className="home-charts-buttons">
                                <div className="selector">
                                  <div className="selecotr-item">
                                    <input
                                      type="radio"
                                      id="WorkingHoursYear"
                                      name="WorkingHoursChartType"
                                      className="selector-item_radio"
                                      checked={
                                        WorkingHoursChartMode.toUpperCase() ===
                                          "YEAR" && "checked"
                                      }
                                      onChange={(e) =>
                                        fnBindWorkingHoursChart("YEAR")
                                      }
                                    />
                                    <label
                                      htmlFor="WorkingHoursYear"
                                      className="selector-item_label"
                                    >
                                      Year
                                    </label>
                                  </div>
                                  <div className="selecotr-item">
                                    <input
                                      type="radio"
                                      id="WorkingHoursMonth"
                                      name="WorkingHoursChartType"
                                      className="selector-item_radio"
                                      checked={
                                        WorkingHoursChartMode.toUpperCase() ===
                                          "MONTH" && "checked"
                                      }
                                      onChange={(e) =>
                                        fnBindWorkingHoursChart("MONTH")
                                      }
                                    />
                                    <label
                                      htmlFor="WorkingHoursMonth"
                                      className="selector-item_label"
                                    >
                                      Month
                                    </label>
                                  </div>
                                  <div className="selecotr-item">
                                    <input
                                      type="radio"
                                      id="WorkingHoursWeek"
                                      name="WorkingHoursChartType"
                                      className="selector-item_radio"
                                      checked={
                                        WorkingHoursChartMode.toUpperCase() ===
                                          "WEEK" && "checked"
                                      }
                                      onChange={(e) =>
                                        fnBindWorkingHoursChart("WEEK")
                                      }
                                    />
                                    <label
                                      htmlFor="WorkingHoursWeek"
                                      className="selector-item_label"
                                    >
                                      Week
                                    </label>
                                  </div>
                                </div>
                              </div>
                              {WorkingHoursChart.Data.series[0].data.length >
                              0 ? (
                                <Chart
                                  type="line"
                                  options={WorkingHoursChart.Data.options}
                                  series={WorkingHoursChart.Data.series}
                                  className="w-100"
                                  height={380}
                                />
                              ) : (
                                <Loader type={LoaderType.NODATAFOUND} />
                              )}
                            </div>
                          )}
                        </div>
                        <Row>
                          <Col xl="9">
                            <div className="Worked-tab">
                              <div className="profile-common-header d-flex align-content-center justify-content-between border-bottom-0">
                                <div className="profile-common-header-title mt-auto mb-auto">
                                  {commonservices.LabelDisplayText("WorkedOn")}
                                </div>
                                <Button
                                  className="icon-btn h-auto w-auto view-all-button"
                                  title="View All"
                                  type="button"
                                >
                                  view all
                                </Button>
                              </div>
                              <div className="vault-tab vault-page">
                                <div className="table-col">
                                  <div className="recent-task-section">
                                    <div className="table-scroll">
                                      {taskData.IsLoading ? (
                                        <Loader type={LoaderType.Table} />
                                      ) : (
                                        <>
                                          {taskData.Data.length === 0 ? (
                                            <Loader
                                              type={LoaderType.NODATAFOUND}
                                            />
                                          ) : (
                                            <Table
                                              id="table#workedontblData"
                                              className="table table-inner-section w-100"
                                            >
                                              <tbody>
                                                {taskData.Data.map(
                                                  (itemTask, rowindex) => (
                                                    <tr className="table-hight-remove">
                                                      <td className="text-nowrap padding-top-bottom">
                                                        <div className="d-flex align-items-center">
                                                          <img
                                                            className="project-short-img"
                                                            src={BlueRight}
                                                            alt=""
                                                          />
                                                          <div className="content-project-col">
                                                            <div className="project-description">
                                                              {itemTask.Title}
                                                            </div>
                                                            <div className="project-name">
                                                              <span>
                                                                {
                                                                  itemTask.ProjectName
                                                                }
                                                              </span>
                                                              <span className="created-on">
                                                                {data.filter(
                                                                  (D) =>
                                                                    D.AssociationID ===
                                                                    itemTask.CreatedBy
                                                                ) ? (
                                                                  <>
                                                                    {commonservices.LabelDisplayText(
                                                                      "You"
                                                                    )}
                                                                  </>
                                                                ) : (
                                                                  <>
                                                                    {
                                                                      data[0]
                                                                        .DisplayName
                                                                    }
                                                                  </>
                                                                )}{" "}
                                                                {commonservices.LabelDisplayText(
                                                                  "CreatedThis"
                                                                )}{" "}
                                                                {commonservices.getDays(
                                                                  commonservices.getDateInDDMMYYYY(
                                                                    itemTask.StartDate
                                                                  )
                                                                )}
                                                              </span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td className="text-end width-150px">
                                                        <div className="d-flex text-nowrap justify-content-end">
                                                          <div className="task-time">
                                                            {fnCreateTaskButtonAndTimeUI(
                                                              itemTask
                                                            )}
                                                          </div>
                                                          <div
                                                            className="status-icon"
                                                            title={
                                                              itemTask.Status
                                                            }
                                                          >
                                                            {commonservices.GetTaskStatusIcon(
                                                              itemTask.Status
                                                            )}
                                                          </div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  )
                                                )}
                                              </tbody>
                                            </Table>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col xl="3">
                            <div className="pie-chart-profile-col">
                              <div className="pie-chart-header d-flex align-content-center justify-content-between">
                                <div className="pie-chart-titel">Projects</div>
                                <Button
                                  className="icon-btn h-auto w-auto view-all-button"
                                  type="button"
                                  title="View All"
                                >
                                  view all
                                </Button>
                              </div>
                              {ProjectsWiseTaskChart.IsLoading ? (
                                <Loader type={LoaderType.NODATAFOUND} />
                              ) : (
                                <>
                                  {ProjectsWiseTaskChart.Data.series[0].data
                                    .length > 0 ? (
                                    <Chart
                                      type="donut"
                                      options={
                                        ProjectsWiseTaskChart.Data.options
                                      }
                                      series={
                                        ProjectsWiseTaskChart.Data.series[0]
                                          .data
                                      }
                                      width="300"
                                    />
                                  ) : (
                                    <Loader type={LoaderType.NODATAFOUND} />
                                  )}
                                </>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {modalShow && (
        <AddDocument
          associationID={EMPID}
          associationType={"EMPLOYEES"}
          show={modalShow}
          bindList={bindList}
          onHide={() => setModalShow(false)}
        />
      )}

      {modalQualificationShow && (
        <AddQualificationModal
          id={QUALIFICATIONID}
          show={modalQualificationShow}
          bindList={bindQualificationDetails}
          onHide={() => setModalQualificationShow(false)}
        />
      )}

      {modalAchievementShow && (
        <AddAchievementModal
          id={ACHIEVEMENTID}
          show={modalAchievementShow}
          bindList={bindAchievementDetails}
          onHide={() => setModalAchievementShow(false)}
        />
      )}

      {modalExperienceShow && (
        <AddExperienceModal
          id={EXPERIENCEID}
          show={modalExperienceShow}
          bindList={bindExperienceDetails}
          onHide={() => setModalExperienceShow(false)}
        />
      )}

      {modalFamilyShow && (
        <AddFamilyModal
          id={FAMILYID}
          show={modalFamilyShow}
          bindList={bindFamilyDetails}
          onHide={() => setModalFamilyShow(false)}
        />
      )}

      {modalLeaveShow && (
        <AddleaveModal
          show={modalLeaveShow}
          associationID={EMPID}
          associationType={"EMPLOYEES"}
          bindList={bindLeaveData}
          onHide={() => setModalLeaveShow(false)}
        />
      )}

      {IsShowApproveRejectLeaveModal && (
        <ApproveRejectLeaveModal
          show={IsShowApproveRejectLeaveModal}
          leaveApplicationID={LeaveApplicationID}
          bindList={bindLeaveData}
          onHide={() => setIsShowApproveRejectLeaveModal(false)}
        />
      )}
      {ProfileModalShow && (
        <EditProfileModal
          show={ProfileModalShow}
          EditProfileData={EditProfileData}
          // leaveApplicationID={LeaveApplicationID}
          bindList={bindList}
          onHide={() => setProfileModalShow(false)}
        />
      )}
    </>
  );
}

export default Profile;
