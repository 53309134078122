import React from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as LogoWhite } from "../assets/Images/logo-white.svg";
import { useNavigate } from "react-router-dom";
import { commonservices } from "../Services/commonService";
import { ReactComponent as DashboardHome } from "../assets/Icons/dashboard-home.svg";
import { ReactComponent as ProjectIcon } from "../assets/Icons/project-icon.svg";
import { ReactComponent as SignOut } from "../assets/Icons/sign-out.svg";
import { ReactComponent as KeyIcon } from "../assets/Icons/key-icon.svg";
import { ReactComponent as TaskListCheck } from "../assets/Icons/task-list-check.svg";
import { ReactComponent as SettingIcon } from "../assets/Icons/setting-icon.svg";
import { ReactComponent as ReportsIcon } from "../assets/Icons/reports-icon.svg";
import { confirmAlert } from "react-confirm-alert";
import { ReactComponent as LeaveIcon } from "../assets/Icons/leave-icon.svg";

function SideBar() {
  const navigate = useNavigate();

  const removeToken = () => {
    commonservices.ClearLoginDetails();
    navigate("/");
  };

  return (
    <div className="vertical-menu">
      <NavLink
        to="/dashboard"
        className="short-logo w-100 d-flex align-items-center justify-content-center mb-4"
      >
        <LogoWhite />
      </NavLink>

      <div className="vertical-list">
        <ul className="p-0 mb-0">
          <li>
            <NavLink
              to="/dashboard"
              className="vertical-menu-icon"
              title="Home"
            >
              <DashboardHome />
            </NavLink>
          </li>
          {commonservices.getLoginRoleData("Project", "IsView") && (
            <li>
              <NavLink
                to="/project"
                className="vertical-menu-icon"
                title="Project"
              >
                <ProjectIcon />
              </NavLink>
            </li>
          )}
          {commonservices.getLoginRoleData("Task", "IsView") && (
            <li>
              <NavLink to="/task" className="vertical-menu-icon" title="Task">
                <TaskListCheck />
              </NavLink>
            </li>
          )}

          {commonservices.getLoginRoleData("Vault", "IsView") && (
            <li>
              <NavLink to="/vault" className="vertical-menu-icon" title="Vault">
                <KeyIcon />
              </NavLink>
            </li>
          )}

          {commonservices.getLoginRoleData("Leave", "IsView") && (
            <li>
              <NavLink to="/leave" className="vertical-menu-icon" title="Leave">
                <LeaveIcon />
              </NavLink>
            </li>
          )}
          {commonservices.getLoginRoleData("User", "IsView") && (
            <li>
              <NavLink to="/user" className="vertical-menu-icon" title="User">
                <i className="fa-solid fa-user"></i>
              </NavLink>
            </li>
          )}
          {commonservices.getLoginRoleData("Setting", "IsView") && (
            <li>
              <NavLink
                to="/setting"
                className="vertical-menu-icon"
                title="Setting"
              >
                <SettingIcon />
              </NavLink>
            </li>
          )}
          <li>
            <NavLink
              to="/reports"
              className="vertical-menu-icon"
              title="Reports"
            >
              <ReportsIcon />
            </NavLink>
          </li>
        </ul>
        
        <ul className="p-0 mb-0 bottom-icon-col">
          <li
            onClick={(e) => {
              confirmAlert({
                message: commonservices.LabelDisplayText("AreYouSureToLogout"),
                buttons: [
                  {
                    label: commonservices.LabelDisplayText("Yes"),
                    onClick: () => removeToken(),
                  },
                  {
                    label: commonservices.LabelDisplayText("No"),
                  },
                ],
              });
            }}
          >
            <a className="vertical-menu-icon" title="Sign Out" href="#">
              <SignOut />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default SideBar;
