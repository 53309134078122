import React, { useEffect, useState, useRef } from "react";
import {
    Breadcrumb,
    Button,
    Col,
    Container,
    OverlayTrigger,
    Row,
    // Modal,
    Table,
    Tooltip,
} from "react-bootstrap";
import EditUserModal from '../../Modal/EditUserModal';
import axios from "axios";
import { toast } from 'react-toastify';
import Switch from "react-switch";
import { downloadExcel } from 'react-export-table-to-excel';
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import Loader, { LoaderType } from "../../Loader";
import { commonservices, AppConfigData } from "../../Services/commonService";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import { ReactComponent as DownloadIcon } from "../../assets/Icons/download-icon.svg";
import { ReactComponent as ExportIocn } from "../../assets/Icons/export-iocn.svg";
import { ReactComponent as Pencil } from "../../assets/Icons/pencil.svg";

function User() {
    const navigate = useNavigate();
    const [modalShow, setModalShow] = React.useState(false);
    const [EditUserData, setEditUserData] = React.useState('');
    // const [USERID, setUSERID] = React.useState(0);
    // const [USERNAME, setUSERNAME] = React.useState(0);
    // const [RoleName, setRoleName] = React.useState("");
    const [loading, setLoading] = useState(false);
    const [UserData, setuserData] = React.useState({ Data: [], IsLoading: true });
    const [searchobj, setSearchobj] = React.useState({
        DisplayName: "",
        UserName: "",
        AssociationType: "",
        MobileNo: "",
        searchText: ""
    });
    const header = ["DisplayName", "UserName", "AssociationType", "MobileNo", "IsBlock"];
    // Region EXPORT TO EXEL
    function handleDownloadExcel() {
        let data = [];
        UserData.Data.forEach((item) => {
            let arrayToAppend = []
            arrayToAppend = [...arrayToAppend, item.UserName, item.DisplayName, item.AssociationType, item.MobileNo, parseInt(item.IsBlock) ? "InActive" : "Active"]
            data = [...data, arrayToAppend];
        })
        downloadExcel({
            fileName: "UserList",
            sheet: "UserList",
            tablePayload: {
                header,
                body: data,
            },
        });
    }
    //End Region

    // Region UserList
    function bindList() {
        setuserData({
            Data: [],
            IsLoading: true,
        });
        const axiosConfig = {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        };
        axios
            .post(
                `${AppConfigData.APIBasePath}v1/user/getuserlist`,
                searchobj,
                axiosConfig
            )
            .then((res) => {
                if (res.data.status_code === 0) {
                    setuserData({ Data: [], IsLoading: false })

                } else if (res.data.status_code === 1) {
                    setuserData({ Data: res.data.data, IsLoading: false });

                } else if (res.data.status_code === 2) {
                    commonservices.ClearLoginDetails();
                    navigate("/");
                }
                else {
                }
            })
            .catch((err) => {
                setuserData({
                    Data: [],
                    IsLoading: false,
                });
            });
    }
    //End Region

    // Region UserChangeStatus
    function ChangeStatus(UserID, IsBlock) {

        setLoading(true);
        let input = {
            "UserID": UserID,
            "IsBlock": parseInt(IsBlock) ? true : false,
            "requestFrom": "Web",
        };
        const axiosConfig = {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        };
        axios
            .post(`${AppConfigData.APIBasePath}v1/user/userwisechangestatus`, input, axiosConfig)
            .then((res) => {

                if (res.data.status_code === 0) {
                } else if (res.data.status_code === 1) {
                    toast.success(res.data.message);
                    setLoading(false);
                    bindList();

                } else if (res.data.status_code === 2) {
                }
                else {
                }
            })
            .catch((err) => {

            });
    }
    // End Region

    //Region PDF
    function PDFDownload() {
        let filename = "UserList";
        let data = [];
        UserData.Data.forEach((item) => {
            let arrayToAppend = []
            arrayToAppend = [...arrayToAppend, item.UserName, item.DisplayName, item.AssociationType, item.MobileNo, parseInt(item.IsBlock) ? "InActive" : "Active"]
            data = [...data, arrayToAppend];
        })
        const doc = new jsPDF()
        //autoTable(doc, { html: '.table' })
        autoTable(doc, {
            head: [header],
            body: data,
            headStyles: {
                textColor: '#000', halign: 'center', valign: 'middle', lineWidth: 0.1,
                lineColor: '#ABABAB', fillColor: "#fff", font: 'times', fontStyle: 'bold'
            },
            styles: { textColor: '#fff', halign: 'center', valign: 'middle', font: 'times', size: '10px' },
            bodyStyles: { textColor: '#000', halign: 'center', valign: 'middle', font: 'times', size: '10px' },
            margin: { top: 0, bottom: 0, left: 5, right: 5 },
            theme: 'grid',
        })
        doc.save(filename + '.pdf')
    }
    //end Region

    //Region EditUser
    function fnOpenUserEditPopup(item) {
         
        setEditUserData(item)
        // setUSERID(id);
        // setUSERNAME(UserName);
        // setRoleName(RoleName)
        setModalShow(true);

    }
    //End Region

    // For Datatable
    $(document).ready(function () {
        $("table#tblData").DataTable({
            destroy: true,
            searching: false,
            dom: "ft<'tbl-info'<'length-info'li<'paggination'p>>>",
            aoColumnDefs: [
                {
                    bSortable: false,
                    aTargets: [-1] /* 1st one, start by the right */,
                },
            ],
        });
    });

    useEffect(() => {
        bindList();
    }, []);

    return (
        <>
            <div className="table-section-col common-header-page">
                <Row>
                    <Col lg={8} md={6}>
                        <div className="page-title-left">
                            {commonservices.LabelDisplayText("User")}
                        </div>
                    </Col>
                    <Col lg={4} md={6} className="mt-auto mb-auto">
                        <div className="d-flex align-items-center justify-content-end">
                            <div className="inner-search-bar inner-search-grey w-100">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="SearchAnything"
                                    name="searchData"
                                    onChange={(e) =>
                                        setSearchobj({
                                            ...searchobj,
                                            ["searchText"]: e.target.value,
                                        })
                                    }
                                    onKeyUp={(e) => e.keyCode === 13 && bindList()}
                                />
                                <Button
                                    variant="btn btn-icon-btn-lg search-button"
                                    title="Search"
                                    onClick={(e) => bindList()}
                                >
                                    <i className="fa-light fa-magnifying-glass"></i>
                                </Button>
                            </div>
                            {
                                commonservices.getLoginRoleData("User", "IsDownload") &&
                                <div className="expor-download-button d-flex">
                                    <Button variant="project-btn">
                                        <ExportIocn onClick={handleDownloadExcel} title="Export"/>
                                    </Button>
                                    <Button variant="project-btn pe-0 ps-1" title="Download">
                                        <DownloadIcon onClick={PDFDownload} />
                                    </Button>
                                </div>
                            }
                        </div>
                    </Col>
                </Row>
            </div>
            <Container>
                <div className="vault-tab vault-page">
                    <div className="table-col">
                        <div className="recent-task-section">
                            {UserData.IsLoading ? (
                                <>
                                    <Loader type={LoaderType.Table} count={4} />
                                </>
                            ) : (
                                <div className="table-scroll">
                                    <Table id="tblData" className="table table-inner-section">
                                        <thead>
                                            <tr>
                                                <th>{commonservices.LabelDisplayText("DisplayName")}</th>
                                                <th>{commonservices.LabelDisplayText("UserName")}</th>
                                                <th>{commonservices.LabelDisplayText("Role")}</th>
                                                <th>{commonservices.LabelDisplayText("MobileNo")}</th>
                                                <th>{commonservices.LabelDisplayText("IsBlock")}</th>
                                                {/* {
                                                    commonservices.getLoginRoleData("User", "IsUpdate") && */}
                                                    <th className="action-class text-end width-100px">
                                                        {commonservices.LabelDisplayText("Action")}
                                                    </th>
                                                {/* } */}
                                            </tr>
                                        </thead>
                                        <tbody className="table-section-col">
                                            {UserData.Data.length > 0 &&
                                                UserData.Data.map((item, index) => (
                                                    <tr key={index}>
                                                        <td className="padding-top-bottom text-nowrap"><div className="project-description">{item.DisplayName}</div></td>
                                                        <td className="padding-top-bottom text-nowrap"><div className="table-text-common">{item.UserName}</div></td>

                                                        <td className="padding-top-bottom text-nowrap"><div className="table-text-common">{item.RoleName}</div></td>
                                                        <td className="padding-top-bottom text-nowrap"><div className="table-text-common">{item.MobileNo}</div></td>
                                                        {
                                                            commonservices.getLoginRoleData("User", "IsUpdate") ?
                                                                <>
                                                                    {(item.IsDefault) ? (<td></td>) : (
                                                                        (item.IsBlock) ? (
                                                                            <td><span className="d-none">InActive</span>
                                                                                <Switch className="align-middle" onChange={(e) => ChangeStatus(item.UserID, item.IsBlock)} disabled={loading}></Switch>
                                                                            </td>
                                                                        ) : (
                                                                            <td><span className="d-none">Active</span>
                                                                                <Switch className="align-middle" onChange={(e) => ChangeStatus(item.UserID, item.IsBlock)} checked disabled={loading}></Switch>
                                                                            </td>
                                                                        )
                                                                    )}
                                                                </>
                                                                :
                                                                (item.IsBlock) ? (
                                                                    <td className="padding-top-bottom text-nowrap"><div className="table-text-common">Yes</div>
                                                                    </td>
                                                                ) : (
                                                                    <td className="padding-top-bottom text-nowrap">
                                                                        <div className="table-text-common">No</div>
                                                                    </td>
                                                                )
                                                        }
                                                        <td className="text-nowrap text-end padding-top-bottom">
                                                            {
                                                                commonservices.getLoginRoleData("User", "IsUpdate") && !item.IsDefault &&
                                                                <Button
                                                                    className="icon-btn"
                                                                    onClick={(e) => fnOpenUserEditPopup(item)}
                                                                    title="Edit"
                                                                >
                                                                    <Pencil />
                                                                </Button>
                                                            }

                                                        </td>

                                                    </tr>
                                                ))}
                                        </tbody>
                                    </Table>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {modalShow && (
                    <EditUserModal
                        // id={USERID}
                        // UserName={USERNAME}
                        EditUserData={EditUserData}
                        show={modalShow}
                        bindList={bindList}
                        onHide={() => setModalShow(false)}
                    />
                )}
            </Container>
        </>
    );
}
export default User;