import React, { useState, useEffect } from "react";
import {
    Table,
    Button,
    Form,
    Container,
    Col,
    Row,
    Breadcrumb,
    OverlayTrigger,
    Tooltip,
    Modal,
    FloatingLabel,
} from "react-bootstrap";
import axios from "axios";
import autoTable from "jspdf-autotable";
import { commonservices, AppConfigData } from "../../Services/commonService";
import { toast } from "react-toastify";
import { downloadExcel } from 'react-export-table-to-excel';
import jsPDF from "jspdf";
import { useSearchParams, useNavigate, useParams } from "react-router-dom";
import AddVaultMain from "../../Modal/AddVaultMain";
import ModalShareVaultMain from "../../Modal/ModalShareVaultMain";
import $ from "jquery";
import { confirmAlert } from "react-confirm-alert";
import Loader, { LoaderType } from "../../Loader";
import ModalShowPasswordVault from "../../Modal/ModalShowPasswordVault";
import { ReactComponent as DownloadIcon } from "../../assets/Icons/download-icon.svg";
import { ReactComponent as ExportIocn } from "../../assets/Icons/export-iocn.svg";
import { ReactComponent as EyeshowPassword } from "../../assets/Icons/eyeshow-password.svg";
import { ReactComponent as Pencil } from "../../assets/Icons/pencil.svg";
import { ReactComponent as Trash } from "../../assets/Icons/trash.svg";
import { ReactComponent as Share } from "../../assets/Icons/share.svg";
import ModalProjectShowPasswordVault from "../../Modal/ModalProjectShowPasswordVault";
function Vault() {

    const [VaultShow, setVaultShow] = useState(false);
    const [ProjectVault, setProjectVault] = useState({});
    const [ProjectShare, setProjectShare] = useState([]);
    const [modalVaultPasswordShow, setModalVaultPasswordShow] = useState(false);
    const [modalShareShow, setModalShareShow] = useState(false);
    const [ProjectPassowrdSeeData, setProjectPassowrdSeeData] = useState([]);
    const [modalShowPassword, setModalShowPassword] = useState(false);
    const [ProjectSeePassword, setProjectSeePassword] = useState([]);

    const [ProjectVaultData, setProjectVaultData] = useState({
        Data: [], IsLoading: true
    });

    var USERLIST = commonservices.getLoginUserData();
    let USERID = 0;
    if (USERLIST.length !== 0) {
        USERID = USERLIST[0].UserID;
    }

    const navigate = useNavigate();

    const [searchobj, setSearchobj] = useState({
        searchText: "",
        requestFrom: "Web"
    });

    function bindList() {

        setProjectVaultData({
            Data: [],
            IsLoading: true,
        });
        const axiosConfig = {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        };
        axios
            .post(
                `${AppConfigData.APIBasePath}v1/passwordmanagment/getmysharelist`,
                searchobj,
                axiosConfig)
            .then((res) => {

                if (res.data.status_code === 0) {
                    setProjectVaultData({ Data: [], IsLoading: false });

                } else if (res.data.status_code === 1) {
                    setProjectVaultData({ Data: res.data.data, IsLoading: false });

                } else if (res.data.status_code === 2) {
                    commonservices.ClearLoginDetails();
                    navigate("/");

                }
                else {
                }
            })
            .catch((err) => {
                setProjectVaultData({ Data: [], IsLoading: false });
            });
    }


    function fnOpenAddVaultMainPopup(item) {
        if (item === null) {
            item = {
                passwordManagmentID: 0,
                userName: "",
                password: "",
                details: "",
                typeTerm: "",
                associationID: "",
                associationType: "",
                isActive: true,
            };
        }
        setProjectVault(item);
        setVaultShow(true);
    }

    function deleteVault(id) {
        let input = {
            id: id,
            requestFrom: "string",
        };
        const axiosConfig = {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        };
        axios
            .post(
                `${AppConfigData.APIBasePath}v1/passwordmanagment/delete`,
                input,
                axiosConfig
            )
            .then((res) => {

                if (res.data.status_code === 0) {
                    toast.error(res.data.message);
                } else if (res.data.status_code === 1) {
                    toast.success(res.data.message);
                    bindList();

                } else if (res.data.status_code === 2) {
                    toast.error(res.data.message);
                }
                else {
                    toast.error(res.data.message);
                }
            })
            .catch((err) => {

            });
    }

    function showPasswordModalopen(item) {
        setProjectSeePassword(item);
        setModalShowPassword(true);
    }

    function fnOpenSharePopup(item) {
        setProjectShare(item);
        setModalShareShow(true);
    }

    function fnOpenVaultPasswordPopup(item) {
        setProjectPassowrdSeeData(item);
        setModalVaultPasswordShow(true);
    }

    const header = ["AssociationType", "Details", "TypeTerm", "UserName"];
    function handleDownloadExcel() {
        let data = [];
        ProjectVaultData.Data.forEach((item) => {
            let arrayToAppend = []
            arrayToAppend = [...arrayToAppend, item.AssociationType, item.Details, item.TypeTerm, item.UserName]
            data = [...data, arrayToAppend];
        })
        downloadExcel({
            fileName: "ProjectVaultData",
            sheet: "ProjectVaultData",
            tablePayload: {
                header,
                body: data,
            },
        });
    }

    function PDFDownload() {
        let filename = "ProjectVaultData";
        let data = [];
        ProjectVaultData.Data.forEach((item) => {
            let arrayToAppend = []
            arrayToAppend = [...arrayToAppend, item.AssociationType, item.Details, item.TypeTerm, item.UserName]
            data = [...data, arrayToAppend];
        })
        const doc = new jsPDF()
        autoTable(doc, {
            head: [header],
            body: data,
            headStyles: {
                textColor: '#000', halign: 'center', valign: 'middle', lineWidth: 0.1,
                lineColor: '#ABABAB', fillColor: "#fff", font: 'times', fontStyle: 'bold'
            },
            styles: { textColor: '#fff', halign: 'center', valign: 'middle', font: 'times', size: '10px' },
            bodyStyles: { textColor: '#000', halign: 'center', valign: 'middle', font: 'times', size: '10px' },
            margin: { top: 0, bottom: 0, left: 5, right: 5 },
            theme: 'grid',
        })
        doc.save(filename + '.pdf')
    }

    useEffect(() => {
        bindList();

    }, []);


    // For Datatable
    $(document).ready(function () {
        $("table#tblData").DataTable({
            destroy: true,
            searching: false,
            scrollX: true,
            dom: "ft<'tbl-info'<'length-info'li<'paggination'p>>>",
            aoColumnDefs: [
                {
                    bSortable: false,
                    aTargets: [-1, -3] /* 1st one, start by the right */,
                },
            ],
        });
    });
    useEffect(() => {
        $(document).ready(function () {
            $("table#tblData")
                .DataTable({
                    destroy: true,
                    searching: false,
                    scrollX: true,
                    dom: "ft<'tbl-info'<'length-info'li<'paggination'p>>>",
                    aoColumnDefs: [
                        {
                            bSortable: false,
                            aTargets: [-1, -3] /* 1st one, start by the right */,
                        },
                    ],
                })
                .destroy();
        });
    }, []);


    return (
        <>
            <Container fluid className="p-0">
                <div className="table-section-col common-header-page">
                    <Row>
                        <Col xl={8} lg={6} md={4}>
                            <div className="page-title-left">{commonservices.LabelDisplayText("Vault")}</div>
                        </Col>
                        <Col xl={4} lg={6} md={8} className="mt-auto mb-auto">
                            <div className="d-flex align-items-center justify-content-end">
                                <div className="inner-search-bar inner-search-grey w-100">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={commonservices.LabelDisplayText('SearchAnything')}
                                        name="searchData"
                                        onKeyUp={(e) => e.keyCode === 13 && bindList()}
                                        onChange={(e) =>
                                            setSearchobj({
                                                ...searchobj,
                                                ["searchText"]: e.target.value,
                                            })
                                        }

                                    />
                                    <Button
                                        variant="btn btn-icon-btn-lg search-button"
                                        onClick={(e) => bindList()}
                                        title="Search"
                                    >
                                        <i className="fa-light fa-magnifying-glass"></i>
                                    </Button>
                                </div>
                                {
                                    commonservices.getLoginRoleData("Vault", "IsCreate") &&
                                    <div className="project-add-icon">
                                        <Button className="project-btn"
                                            onClick={() => fnOpenAddVaultMainPopup(null)}
                                            title="Add"
                                        >
                                            <i className="fa-regular fa-plus"></i>
                                        </Button>
                                    </div>
                                }
                                <div className="expor-download-button d-flex">
                                    <Button variant="project-btn ms-2" title="Export">
                                        <ExportIocn onClick={handleDownloadExcel} />
                                    </Button>
                                    <Button variant="project-btn pe-0 ps-1" title="Download">
                                        <DownloadIcon onClick={PDFDownload} />
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
            <Container className="ps-0 pe-0">
                <>
                    <div className="vault-tab vault-page">
                        {ProjectVaultData.IsLoading ? (
                            <>
                                <Loader type={LoaderType.Table} count={4} />
                            </>
                        ) : (
                            <div className="table-col leave-request-col">
                                <div className="recent-task-section">
                                    <div className="table-scroll">
                                        <Table
                                            id="tblData"
                                            className="table table-inner-section"
                                        >
                                            <thead>
                                                <tr>
                                                    <th className="width-100px text-center">
                                                        {commonservices.LabelDisplayText("SrNo")}
                                                    </th>
                                                    <th className="width-300px">
                                                        <div className="username">
                                                            {commonservices.LabelDisplayText(
                                                                "UserName"
                                                            )}
                                                        </div>
                                                    </th>
                                                    <th className="width-300px text-nowrap">
                                                        {commonservices.LabelDisplayText("Type")}
                                                    </th>
                                                    <th className="width-200px text-nowrap">
                                                        {commonservices.LabelDisplayText("Password")}
                                                    </th>
                                                    <th>
                                                        {commonservices.LabelDisplayText(
                                                            "Description"
                                                        )}
                                                    </th>
                                                    <th className="text-end width-100px">
                                                        {commonservices.LabelDisplayText("Action")}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="table-section-col">
                                                {ProjectVaultData.Data.length > 0 &&
                                                    ProjectVaultData.Data.map((item, index) => (
                                                        <tr key={index}>
                                                            <td className="text-center padding-top-bottom width-100px">
                                                                <div className="sr-no">{index + 1}</div>
                                                            </td>
                                                            <td className="text-nowrap padding-top-bottom width-300px">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        className=""
                                                                        src={commonservices.GetVaultMailIcon(AppConfigData.MailTypePath[item.TypeTerm.toUpperCase()])}
                                                                        alt={item.DocName}
                                                                        width="20"
                                                                        height="20"
                                                                    />
                                                                    <div className="project-description username ms-2">
                                                                        {item.UserName}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="width-300px padding-top-bottom text-nowrap">
                                                                <div className="table-text-common">
                                                                    {item.TypeTerm}
                                                                </div>
                                                            </td>
                                                            <td className="width-200px text-nowrap padding-top-bottom">
                                                                <div className="d-flex align-items-baseline">
                                                                    <div className="password-dots">
                                                                        <i className="fa-solid fa-period"></i>
                                                                        <i className="fa-solid fa-period"></i>
                                                                        <i className="fa-solid fa-period"></i>
                                                                        <i className="fa-solid fa-period"></i>
                                                                        <i className="fa-solid fa-period"></i>
                                                                        <i className="fa-solid fa-period"></i>
                                                                        <i className="fa-solid fa-period"></i>
                                                                        <i className="fa-solid fa-period"></i>
                                                                        <i className="fa-solid fa-period"></i>
                                                                        <i className="fa-solid fa-period"></i>
                                                                        {/* {commonservices.HidePassword("password")} */}
                                                                    </div>
                                                                    <div>
                                                                        <Button
                                                                            type="button"
                                                                            className="icon-btn ms-2 h-auto w-auto btn btn-primary"
                                                                            title="Show"
                                                                            onClick={() =>
                                                                                fnOpenVaultPasswordPopup(
                                                                                    item
                                                                                )
                                                                            }
                                                                        >
                                                                            <EyeshowPassword />
                                                                        </Button>
                                                                        {/*{commonservices.getLoginRoleData(*/}
                                                                        {/*    "Vault",*/}
                                                                        {/*    "IsUpdate"*/}
                                                                        {/*) && (*/}
                                                                        {/*        <>*/}
                                                                        {/*            {item.CreatedBy === USERID && (*/}
                                                                        {/*                <Button*/}
                                                                        {/*                    type="button"*/}
                                                                        {/*                    className="icon-btn ms-2 h-auto w-auto btn btn-primary"*/}
                                                                        {/*                    onClick={() =>*/}
                                                                        {/*                        fnOpenVaultPasswordPopup(*/}
                                                                        {/*                            item*/}
                                                                        {/*                        )*/}
                                                                        {/*                    }*/}
                                                                        {/*                >*/}
                                                                        {/*                    <EyeshowPassword />*/}
                                                                        {/*                </Button>*/}
                                                                        {/*            )}*/}
                                                                        {/*        </>*/}
                                                                        {/*    )}*/}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="padding-top-bottom">
                                                                <div className="vault-description">
                                                                    {item.Details}
                                                                </div>
                                                            </td>
                                                            <td className="text-end padding-top-bottom width-100px">
                                                                {commonservices.getLoginRoleData(
                                                                    "Vault",
                                                                    "IsUpdate"
                                                                ) && (
                                                                        <>
                                                                            {item.CreatedBy === USERID && (
                                                                                <div className="d-flex justify-content-end">
                                                                                    <Button
                                                                                        className="icon-btn me-1 h-auto"
                                                                                        onClick={() =>
                                                                                            fnOpenAddVaultMainPopup(item)
                                                                                        }
                                                                                        title="Edit"
                                                                                    >
                                                                                        <Pencil />
                                                                                    </Button>
                                                                                    <Button
                                                                                        className="icon-btn me-1 h-auto"
                                                                                        onClick={(e) =>
                                                                                            fnOpenSharePopup(item)
                                                                                        }
                                                                                        title="Share"
                                                                                    >
                                                                                        <Share />
                                                                                    </Button>
                                                                                    <Button
                                                                                        className="icon-btn h-auto w-auto"
                                                                                        title="Delete"
                                                                                        onClick={(e) => {
                                                                                            confirmAlert({
                                                                                                message:
                                                                                                    commonservices.LabelDisplayText(
                                                                                                        "AreYouSureToDoDelete"
                                                                                                    ),
                                                                                                buttons: [
                                                                                                    {
                                                                                                        label:
                                                                                                            commonservices.LabelDisplayText(
                                                                                                                "Yes"
                                                                                                            ),
                                                                                                        onClick: () =>
                                                                                                            deleteVault(
                                                                                                                item.PasswordManagmentID
                                                                                                            ),
                                                                                                    },
                                                                                                    {
                                                                                                        label:
                                                                                                            commonservices.LabelDisplayText(
                                                                                                                "No"
                                                                                                            ),
                                                                                                    },
                                                                                                ],
                                                                                            });
                                                                                        }}
                                                                                    >
                                                                                        <Trash />
                                                                                    </Button>
                                                                                </div>
                                                                            )}
                                                                        </>
                                                                    )}
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </>
            </Container>

            {VaultShow && (
                <AddVaultMain
                    id={ProjectVault}
                    show={VaultShow}
                    bindList={bindList}
                    onHide={() => setVaultShow(false)}
                />
            )}

            {modalShareShow && (
                <ModalShareVaultMain
                    id={ProjectShare}
                    show={modalShareShow}
                    // bindList={bindList}
                    onHide={() => setModalShareShow(false)}
                />
            )}

            {modalShowPassword && (
                <ModalShowPasswordVault
                    id={ProjectSeePassword}
                    show={modalShowPassword}
                    // bindList={bindList}
                    onHide={() => setModalShowPassword(false)}
                />
            )}

            {modalVaultPasswordShow && (
                <ModalProjectShowPasswordVault
                    id={ProjectPassowrdSeeData}
                    show={modalVaultPasswordShow}
                    // bindList={bindList}
                    onHide={() => setModalVaultPasswordShow(false)}
                />
            )}
        </>
    );
}

export default Vault;
