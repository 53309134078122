import axios from "axios";
import React, { useState, useEffect } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import * as yup from "yup";
import { commonservices, AppConfigData } from "../Services/commonService";
import { Col, Modal, Button, Container, Row, Table } from "react-bootstrap";
import { useSearchParams, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import Spinner from "react-bootstrap/Spinner";

const ModalShare = (props) => {
    let param = useParams();

    const [Submitting, setSubmitting] = useState(false);
    const [user, setUser] = useState([]);
    const [PasswordID, setPasswordID] = useState([]);
    const [IsValidationMessageSet, setIsValidationMessageSet] = useState("");

    useEffect(() => {
        setIsValidationMessageSet(true);
    });
    const formInitialSchema = {
        passwordManagmentID: "",
        shareToUserID: "",
        startDate: null,
        endDate: null,
    };

    const formValidation = yup.object().shape({
        shareToUserID: yup
            .string()
            .required(commonservices.MakeValidationMessage("required", "shareToUserID")),
    });

    function getTypeterm() {
        const axiosConfig = {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        };
        axios
            .post(
                `${AppConfigData.APIBasePath}v1/projectuser/getlist`,
                {
                    id: parseInt(param.id),
                    requestFrom: "string",
                },
                axiosConfig
            )
            .then((res) => {
                if (res.data.status_code === 0) {
                } else if (res.data.status_code === 1) {
                    setUser(res.data.data);
                } else if (res.data.status_code === 2) {
                } else {
                }
            })
            .catch((err) => { });
    }

    useEffect(() => {
        getTypeterm();
        if (props) {
            setPasswordID(props.id.PasswordManagmentID);
        }
    }, []);

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                // centered
                scrollable={true}
                className="right-modal modal-new"
                backdrop="static"
                keyboard={false}
            >
                <Formik
                    initialValues={formInitialSchema}
                    validationSchema={formValidation}
                    onSubmit={async (values) => {
                        setSubmitting(true);

                        let input = {
                            passwordManagmentID: PasswordID,
                            shareToUserID: parseInt(values.shareToUserID),
                            startDate: null,
                            endDate: null,
                        };
                        const axiosConfig = {
                            headers: {
                                Accept: "application/json",
                                Authorization: "Bearer " + localStorage.getItem("token"),
                            },
                        };
                        const response = await axios
                            .post(
                                `${AppConfigData.APIBasePath}v1/passwordmanagment/sharepassword`,
                                input,
                                axiosConfig
                            )
                            .then((res) => {
                                setSubmitting(false);
                                if (res.data.status_code === 0) {
                                    toast.error(res.data.message);
                                } else if (res.data.status_code === 1) {
                                    toast.success(res.data.message);
                                    //setTimeout(() => {
                                    //  props.onHide()
                                    //}, 1000);

                                    props.bindList();
                                    props.onHide();
                                } else if (res.data.status_code === 2) {
                                    toast.error(res.data.message);
                                } else {
                                    toast.error(res.data.message);
                                }
                            })
                            .catch((error) => { });
                    }}
                >
                    <Form>
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                {commonservices.LabelDisplayText("SharePassword")}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Container>
                                <Row>
                                    <Field
                                        type="hidden"
                                        name="passwordManagmentID"
                                        className="form-control"
                                    />
                                    <Col xs={12}>
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Field
                                                    as="select"
                                                    name="shareToUserID"
                                                    className="form-control"
                                                >
                                                    <option value="">
                                                        {commonservices.LabelDisplayText("Select")}
                                                    </option>
                                                    {user.length > 0 &&
                                                        user.map((item, index) => (
                                                            <option key={index} value={item.UserID}>
                                                                {item.DisplayName}
                                                            </option>
                                                        ))}
                                                </Field>
                                                <label lbl-for="shareToUserID">
                                                    {commonservices.LabelDisplayText("User")}
                                                </label>
                                                <div className="text-danger-error">
                                                    <ErrorMessage name="shareToUserID" />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={props.onHide} title="Close">
                                {commonservices.LabelDisplayText("Close")}
                            </Button>
                            <Button
                                className="btn btn-primary"
                                type="submit"
                                title="Submit"
                                disabled={Submitting}
                            >
                                {Submitting && (
                                    <>
                                        <Spinner animation="border" />
                                    </>
                                )}
                                {commonservices.LabelDisplayText("Share")}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Formik>
            </Modal>
        </>
    );
};
export default ModalShare;
