/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-computed-key */
import axios from "axios";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import {
    Button,
    Col,
    Container, Row,
    // Modal,
    Table
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
// import { AppConfigData, commonservices } from "../../commonService";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { downloadExcel } from 'react-export-table-to-excel';
import { ReactComponent as DownloadIcon } from "../../assets/Icons/download-icon.svg";
import { ReactComponent as ExportIocn } from "../../assets/Icons/export-iocn.svg";
import { ReactComponent as Pencil } from "../../assets/Icons/pencil.svg";
import Loader, { LoaderType } from "../../Loader";
import AddMailTemplatesModal from "../../Modal/AddMailTemplatesModal";
import { AppConfigData, commonservices } from "../../Services/commonService";

function MailTemplates() {
    const [modalShow, setModalShow] = useState(false);
    const navigate = useNavigate();
    const [MailTemplatesData, setMailTemplatesData] = useState({
        Data: [], IsLoading: true
    });

    const [MAITEMPID, setMAITEMPID] = useState(0);
    const [searchobj, setSearchobj] = useState({
        mailSendType: "",
        title: "",
        searchText: "",
    });


    function bindList() {
        setMailTemplatesData({
            Data: [], IsLoading: true
        })
        const axiosConfig = {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        };
        axios
            .post(
                `${AppConfigData.APIBasePath}v1/mailtemplates/getlist`,
                searchobj,
                axiosConfig
            )
            .then((res) => {

                if (res.data.status_code === 0) {
                    setMailTemplatesData({
                        Data: [],
                        IsLoading: false,
                    });

                } else if (res.data.status_code === 1) {
                    setMailTemplatesData({
                        Data: res.data.data,
                        IsLoading: false,
                    });

                } else if (res.data.status_code === 2) {

                    localStorage.removeItem("token");
                    navigate("/login");

                }
                else {

                }

            })
            .catch((err) => {
                setMailTemplatesData({ Data: [], IsLoading: false })
            });
    }

    function fnOpenProjectTermAddEditPopup(id) {
        setMAITEMPID(id);
        setModalShow(true);
    }


    const header = ["Title", "MailSendType"];
    function handleDownloadExcel() {
        let data = [];
        MailTemplatesData.Data.forEach((item) => {
            let arrayToAppend = []
            arrayToAppend = [...arrayToAppend, item.Title, item.MailSendType]
            data = [...data, arrayToAppend];
        })
        downloadExcel({
            fileName: "MailTemplates Details",
            sheet: "MailTemplates Details",
            tablePayload: {
                header,
                body: data,
            },
        });
    }
    function PDFDownload() {
        let filename = "MailTemplates Details";
        let data = [];
        MailTemplatesData.Data.forEach((item) => {
            let arrayToAppend = []
            arrayToAppend = [...arrayToAppend, item.Title, item.MailSendType]
            data = [...data, arrayToAppend];
        })
        const doc = new jsPDF()
        autoTable(doc, {
            head: [header],
            body: data,
            headStyles: {
                textColor: '#000', halign: 'center', valign: 'middle', lineWidth: 0.1,
                lineColor: '#ABABAB', fillColor: "#fff", font: 'times', fontStyle: 'bold'
            },
            styles: { textColor: '#fff', halign: 'center', valign: 'middle', font: 'times', size: '10px' },
            bodyStyles: { textColor: '#000', halign: 'center', valign: 'middle', font: 'times', size: '10px' },
            margin: { top: 0, bottom: 0, left: 5, right: 5 },
            theme: 'grid',
        })
        doc.save(filename + '.pdf')
    }
    useEffect(() => {
        bindList();
    }, []);

    // For Datatable 
    $(document).ready(function () {
        $("table#ProjectTermtblData").DataTable({
            destroy: true,
            searching: false,
            dom: "ft<'tbl-info'<'length-info'li<'paggination'p>>>",
            aoColumnDefs: [
                {
                    bSortable: false,
                    aTargets: [-1] /* 1st one, start by the right */,
                },
            ],
        });
    });
    useEffect(() => {
        $(document).ready(function () {
            $("table#ProjectTermtblData")
                .DataTable({
                    destroy: true,
                    searching: false,
                    dom: "ft<'tbl-info'<'length-info'li<'paggination'p>>>",
                    aoColumnDefs: [
                        {
                            bSortable: false,
                            aTargets: [-1] /* 1st one, start by the right */,
                        },
                    ],
                })
                .destroy();
        });
    }, []);

    $(document).ready(function () {
        $("table#MailTlData").DataTable({
            destroy: true,
            searching: false,
            dom: "ft<'tbl-info'<'length-info'li<'paggination'p>>>",
            aoColumnDefs: [
                {
                    bSortable: false,
                    aTargets: [-1] /* 1st one, start by the right */,
                },
            ],
        });
    });
    useEffect(() => {
        $(document).ready(function () {
            $("table#MailTlData")
                .DataTable({
                    destroy: true,
                    searching: false,
                    dom: "ft<'tbl-info'<'length-info'li<'paggination'p>>>",
                    aoColumnDefs: [
                        {
                            bSortable: false,
                            aTargets: [-1] /* 1st one, start by the right */,
                        },
                    ],
                })
                .destroy();
        });
    }, []);

    return (
        <>
            <div className="table-section-col common-header-page empheadmain">
                <Row>
                    <Col lg={7} md={5}>
                        <div className="page-title-left">
                            Mail Templates Setting
                        </div>
                    </Col>
                    <Col lg={5} md={7} className="mt-auto mb-auto">
                        <div className="d-flex align-items-center justify-content-end">
                            <div className="inner-search-bar inner-search-grey w-100 empspacedown">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="SearchAnything"
                                    onKeyUp={(e) => e.keyCode === 13 && bindList()}
                                    name="searchData"
                                    onChange={(e) =>
                                        setSearchobj({
                                            ...searchobj,
                                            ["searchText"]: e.target.value,
                                        })
                                    }
                                />
                                <Button
                                    variant="btn btn-icon-btn-lg search-button"
                                    title="Search"
                                    onClick={(e) => bindList()}
                                >
                                    <i className="fa-light fa-magnifying-glass"></i>
                                </Button>
                            </div>
                            {
                                commonservices.getLoginRoleData("MailTemplateSetting", "IsDownload") &&
                                <div className="expor-download-button d-flex">
                                    <Button variant="project-btn ms-2" title="Export">
                                        <ExportIocn onClick={handleDownloadExcel} />
                                    </Button>
                                    <Button variant="project-btn pe-0 ps-1" title="Download">
                                        <DownloadIcon onClick={PDFDownload} />
                                    </Button>
                                </div>
                            }
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="vault-tab vault-page">
                <div className="table-col">
                    <div className="recent-task-section">
                        {
                            MailTemplatesData.IsLoading ?
                                <>
                                    <Loader
                                        type={LoaderType.Table}
                                        count={5}
                                    />
                                </>
                                :
                                <div className="table-scroll">
                                    <Table
                                        id="MailTlData"
                                        className="table table-inner-section"
                                    >
                                        <thead>
                                            <tr>
                                                <th className="width-300px">
                                                    {commonservices.LabelDisplayText("MailSubject")}
                                                </th>
                                                <th className="width-300px">
                                                    {commonservices.LabelDisplayText("TempleteFor")}
                                                </th>
                                                <th className="text-end width-100px">
                                                    {commonservices.LabelDisplayText("Action")}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="table-section-col">
                                            {MailTemplatesData.Data.length > 0 &&
                                                MailTemplatesData.Data.map((item, index) => (
                                                    <tr key={index}>
                                                        <td className="padding-top-bottom width-300px">
                                                            <div className="project-description">{item.Title}</div>
                                                        </td>
                                                        <td className="padding-top-bottom width-300px">
                                                            <div className="d-flex table-text-common">
                                                                {item.MailSendType}
                                                            </div>
                                                        </td>
                                                        <td className="text-end padding-top-bottom width-100px">
                                                            <div className="d-flex justify-content-end">
                                                                {
                                                                    commonservices.getLoginRoleData("MailTemplateSetting", "IsUpdate") &&
                                                                    <Button
                                                                        className="icon-btn me-1 h-auto"
                                                                        title="Edit"
                                                                        onClick={(e) =>
                                                                            fnOpenProjectTermAddEditPopup(item.MailTemplatesID)
                                                                        }
                                                                    >
                                                                        <Pencil />
                                                                    </Button>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </Table>
                                </div>
                        }
                    </div>
                </div>
            </div>
            <Container className="pt-4">

                {modalShow && (
                    <AddMailTemplatesModal
                        id={MAITEMPID}
                        show={modalShow}
                        bindList={bindList}
                        onHide={() => setModalShow(false)}
                    />
                )}
            </Container>
        </>
    );
}

export default MailTemplates;
