import React, { useEffect, useState } from "react";
import {
    Col,
    Container,
    Row,
    Table,
    Button, Spinner
} from "react-bootstrap";
import axios from "axios";
import { AppConfigData, commonservices } from "../../../Services/commonService";
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
const EmployeeWiseLeaveReport = () => {
    const [formInputsEr, setFormInputsEr] = useState({
        TransactionStartDate: "",
        TransactionEndDate: "",
    });

    const [Submitting, setSubmitting] = useState(false);
    const [optionUser, setoptionUser] = useState([]);
    const [selectedUser, setSelectedUser] = useState([]);
    const [searchobj, setSearchobj] = useState({
        "EmployeeID": null,
        "TransactionType": null,
        "IsTransaction": null,
        "TransactionStartDate": "",
        "TransactionEndDate": "",
    });

    function getEMP() {
        let empList = {
            "firstName": "",
            "lastName": "",
            "middleName": "",
            "mobileNo": "",
            "emplyeesNo": "",
            "emailID": "",
            "adharNo": "",
            "pancardNo": "",
            "searchText": ""
        };
        const axiosConfig = {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        };
        axios
            .post(
                `${AppConfigData.APIBasePath}v1/employees/getlist`,
                empList,
                axiosConfig
            )
            .then((res) => {
                if (res.data.status_code === 0) {
                } else if (res.data.status_code === 1) {
                    let optionUser = [];
                    res.data.data.forEach((element) => {
                        optionUser.push({
                            label: element?.FirstName + " " + element?.MiddleName + " " + element?.LastName + " (" + element?.EmailID + ")",
                            value: element.EmployeeID,
                        });
                    });
                    setoptionUser(optionUser);
                } else if (res.data.status_code === 2) {
                } else {
                }
            })
            .catch((err) => { });
    }

    useEffect(() => {
        getEMP();
    }, []);

    function fnSelectedOptionUser(value, event) {
        setSearchobj({
            ...searchobj,
            ["EmployeeID"]: value.length !== 0 ? value : "",
        });

        setSelectedUser(value);
    }

    const handleGenerateReport = async () => {
        let IsValid = true;
        let errorMessage = {
            TransactionStartDate: "",
            TransactionEndDate: "",
        }
        if (searchobj.TransactionStartDate === "") {
            IsValid = false;
            errorMessage.TransactionStartDate = "This field is required.";
        }

        if (searchobj.TransactionEndDate === "") {
            IsValid = false;
            errorMessage.TransactionEndDate = "This field is required.";
        }

        setFormInputsEr(errorMessage);

        if (IsValid) {
            let empid = "";
            if (searchobj.EmployeeID !== null && searchobj.EmployeeID !== undefined) {
                if (searchobj.EmployeeID.length > 0) {
                    for (let i = 0; i < searchobj.EmployeeID.length; i++) {
                        empid = empid + "," + searchobj.EmployeeID[i].value;
                    }
                }
            }
            let  searchby = {
                "EmployeeID": empid,
                "TransactionType": searchobj.TransactionType,
                "IsTransaction": searchobj.IsTransaction,
                "TransactionStartDate": searchobj.TransactionStartDate,
                "TransactionEndDate": searchobj.TransactionEndDate,
            }

            const axiosConfig = {
                headers: {
                    "content-type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            };
            setSubmitting(true);
            const response = await axios
                .post(
                    `${AppConfigData.APIBasePath}v1/reports/employeeswiseleavereport`,
                    searchby,
                    axiosConfig
                )
                .then((response) => {
                    setSubmitting(false);
                    if (response.data.status_code === 1) {
                        window.open(
                            AppConfigData.APIFileAccessURL + response.data.data,
                            "_blank"
                        );
                        toast.success(response.data.message);
                    } else if (response.data.status_code === 2) {
                        setSubmitting(false);
                        toast.error(response.data.message);
                    } else if (response.data.status_code === 0) {
                        setSubmitting(false);
                        toast.error(response.data.message);
                    } else {
                        setSubmitting(false);
                        toast.error(response.data.message);
                    }
                })
                .catch((error) => {
                    // window.open("/reports");
                    setSubmitting(false);
                    toast.error("Something went wrong.");
                });
        }
    };

    return (
        <>
            <div className="table-section-col common-header-page empheadmain">
                <Row>
                    <Col xl={12} lg={12} md={12}>
                        <div className="page-title-left">
                            Employee Wise Leave Report
                        </div>
                    </Col>
                    <Col xl={0} lg={0} md={0} className="mt-auto mb-auto"></Col>
                </Row>
                <Row>
                    <Col xl={6} lg={8} md={4} className="mt-3 mb-auto">
                        <div className="form-floating">
                            <input

                                type="date"
                                name="StartDate"
                                className="form-control calendar-icon"
                                placeholder={commonservices.LabelDisplayText('StartDate')}
                                onChange={(e) =>
                                    setSearchobj({
                                        ...searchobj,
                                        ["TransactionStartDate"]: e.target.value,
                                    })
                                }
                                value={searchobj.TransactionStartDate}
                            />
                            <label>{commonservices.LabelDisplayText('StartDate')}</label>
                        </div>
                        {
                            formInputsEr.TransactionStartDate &&
                            <small style={{ color: "#dc3545" }}>
                                {formInputsEr.TransactionStartDate}
                            </small>
                        }

                    </Col>
                    <Col xl={6} lg={8} md={4} className="mt-3 mb-auto">
                        <div className="form-floating">
                            <input
                                type="date"
                                name="EndDate"
                                className="form-control calendar-icon"
                                placeholder={commonservices.LabelDisplayText('EndDate')}
                                onChange={(e) =>
                                    setSearchobj({
                                        ...searchobj,
                                        ["TransactionEndDate"]: e.target.value,
                                    })
                                }
                                value={searchobj.TransactionEndDate}
                            />
                            <label>{commonservices.LabelDisplayText('EndDate')}</label>
                        </div>
                        {
                            formInputsEr.TransactionEndDate &&
                            <small style={{ color: "#dc3545" }}>
                                {formInputsEr.TransactionEndDate}
                            </small>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col xl={6} lg={8} md={4} className="mt-3 mb-auto">
                        <div className="form-floating multipleselect-form-floating">
                            <select name="TransactionType" className="form-control down-arrow-icon" value={searchobj.TransactionType}
                                onChange={(e) =>
                                    setSearchobj({
                                        ...searchobj,
                                        ["TransactionType"]: e.target.value,
                                    })
                                }
                            >
                                <option value="">{commonservices.LabelDisplayText("Select")}</option>
                                <option value="Credit">Credit</option>
                                <option value="Debit">Debit</option>
                            </select>
                            <label>Type</label>
                        </div>
                    </Col>
                    <Col xl={6} lg={8} md={4} className="mt-3 mb-auto">
                        <div className="form-floating multipleselect-form-floating">
                            <select name="IsTransaction" className="form-control down-arrow-icon" value={searchobj.IsTransaction}
                                onChange={(e) =>
                                    setSearchobj({
                                        ...searchobj,
                                        ["IsTransaction"]: e.target.value !== "" ? e.target.value === "true" : null,
                                    })
                                }
                            >
                                <option value="">{commonservices.LabelDisplayText("Select")}</option>
                                <option value="true">Transaction</option>
                                <option value="false">Leave Application</option>
                            </select>
                            <label>From</label>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xl={6} lg={8} md={4} className="mt-3 mb-auto">
                        <div className="form-floating multipleselect-form-floating">
                            <ReactMultiSelectCheckboxes
                                width={"100%"}
                                placeholderButtonLabel={"Select employees"}
                                isMulti
                                name="ProjectUser"
                                onChange={fnSelectedOptionUser}
                                options={optionUser}
                                value={selectedUser}
                            />
                            <label lbl-for="ProjectUser">
                                {commonservices.LabelDisplayText("Select")}
                            </label>
                        </div>

                    </Col>
                </Row>
                <Row>
                    <Col xl={6} lg={8} md={4} className="mt-3 mb-auto">
                        <Button
                            variant="primary"
                            className="m-0 me-2"
                            type="submit"
                            title="Next"
                            disabled={Submitting}
                            onClick={handleGenerateReport}
                        >
                            {Submitting && (
                                <>
                                    <Spinner animation="border" />
                                </>
                            )}
                            Generate Report
                        </Button>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default EmployeeWiseLeaveReport;
