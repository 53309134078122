import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  Col,
  Container,
  OverlayTrigger,
  Row, // Modal,
  Table,
  Tooltip,
} from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";
import AddClientModal from "../../Modal/AddClientModal";
import { confirmAlert } from "react-confirm-alert";
import $ from "jquery";
import Loader, { LoaderType } from "../../Loader";
import { commonservices, AppConfigData } from "../../Services/commonService";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Pencil } from "../../assets/Icons/pencil.svg";
import { ReactComponent as Trash } from "../../assets/Icons/trash.svg";
import { ReactComponent as DownloadIcon } from "../../assets/Icons/download-icon.svg";
import { ReactComponent as ExportIocn } from "../../assets/Icons/export-iocn.svg";
import { downloadExcel } from "react-export-table-to-excel";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

function Client() {
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [clientData, setclientData] = useState({
    Data: [],
    IsLoading: true,
  });
  const [CLIENTID, setCLIENTID] = useState(0);
  const [searchobj, setSearchobj] = useState({
    Name: "",
    mobileNo: "",
    emailID: "",
    country: "",
    searchText: "",
  });

  function bindList() {
    setclientData({
      Data: [],
      IsLoading: true,
    });
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(
        `${AppConfigData.APIBasePath}v1/client/getlist`,
        searchobj,
        axiosConfig
      )
      .then((res) => {
        if (res.data.status_code === 0) {
          setclientData({ Data: [], IsLoading: false });
        } else if (res.data.status_code === 1) {
          setclientData({ Data: res.data.data, IsLoading: false });
        } else if (res.data.status_code === 2) {
          commonservices.ClearLoginDetails();
          navigate("/");
        } else {
          setclientData({ Data: [], IsLoading: false });
        }
      })
      .catch((err) => {
        setclientData({ Data: [], IsLoading: false });
      });
  }
  function fnOpenClientAddEditPopup(id) {
    setCLIENTID(id);
    setModalShow(true);
  }
  function deleteData(id) {
    let input = {
      id: id,
      requestFrom: "Web",
    };
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .post(`${AppConfigData.APIBasePath}v1/client/delete`, input, axiosConfig)
      .then((res) => {
        if (res.data.status_code === 0) {
          toast.error(res.data.message);
        } else if (res.data.status_code === 1) {
          bindList();
          toast.success(res.data.message);
        } else if (res.data.status_code === 2) {
          toast.error(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {});
  }

  const header = ["Name", "ContactNo", "Country", "EmailID"];
  function handleDownloadExcel() {
    let data = [];
    clientData.Data.forEach((item) => {
      let arrayToAppend = [];
      arrayToAppend = [
        ...arrayToAppend,
        item.Name,
        item.ContactNo,
        item.Country,
        item.EmailID,
      ];
      data = [...data, arrayToAppend];
    });
    downloadExcel({
      fileName: "Clients Details",
      sheet: "Clients Details",
      tablePayload: {
        header,
        body: data,
      },
    });
  }
  function PDFDownload() {
    let filename = "Clients Details";
    let data = [];
    clientData.Data.forEach((item) => {
      let arrayToAppend = [];
      arrayToAppend = [
        ...arrayToAppend,
        item.Name,
        item.ContactNo,
        item.Country,
        item.EmailID,
      ];
      data = [...data, arrayToAppend];
    });
    const doc = new jsPDF();
    autoTable(doc, {
      head: [header],
      body: data,
      headStyles: {
        textColor: "#000",
        halign: "center",
        valign: "middle",
        lineWidth: 0.1,
        lineColor: "#ABABAB",
        fillColor: "#fff",
        font: "times",
        fontStyle: "bold",
      },
      styles: {
        textColor: "#fff",
        halign: "center",
        valign: "middle",
        font: "times",
        size: "10px",
      },
      bodyStyles: {
        textColor: "#000",
        halign: "center",
        valign: "middle",
        font: "times",
        size: "10px",
      },
      margin: { top: 0, bottom: 0, left: 5, right: 5 },
      theme: "grid",
    });
    doc.save(filename + ".pdf");
  }

  useEffect(() => {
    bindList();
  }, []);

  // For Datatable
  $(document).ready(function () {
    $("table#ClienttblData").DataTable({
      destroy: true,
      searching: false,
      dom: "ft<'tbl-info'<'length-info'li<'paggination'p>>>",
      aoColumnDefs: [
        {
          bSortable: false,
          aTargets: [-1] /* 1st one, start by the right */,
        },
      ],
    });
  });
  useEffect(() => {
    $(document).ready(function () {
      $("table#ClienttblData")
        .DataTable({
          destroy: true,
          searching: false,
          dom: "ft<'tbl-info'<'length-info'li<'paggination'p>>>",
          aoColumnDefs: [
            {
              bSortable: false,
              aTargets: [-1] /* 1st one, start by the right */,
            },
          ],
        })
        .destroy();
    });
  }, []);

  return (
    <>
      <div className="table-section-col common-header-page empheadmain">
        <Row>
          <Col xl={4} lg={3} md={3}>
            <div className="page-title-left">
              {commonservices.LabelDisplayText("Client")}
            </div>
          </Col>
          <Col xl={8} lg={9} md={9} className="mt-auto mb-auto">
            <div className="d-flex align-items-center justify-content-end">
              <div className="inner-search-bar inner-search-grey w-100">
                <input
                  type="text"
                  className="form-control"
                  placeholder="SearchAnything"
                  onKeyUp={(e) => e.keyCode === 13 && bindList()}
                  name="searchData"
                  onChange={(e) =>
                    setSearchobj({
                      ...searchobj,
                      ["searchText"]: e.target.value,
                    })
                  }
                />
                <Button
                  variant="btn btn-icon-btn-lg search-button"
                  title="Search"
                  onClick={(e) => bindList()}
                >
                  <i className="fa-light fa-magnifying-glass"></i>
                </Button>
              </div>
              {commonservices.getLoginRoleData("Client", "IsCreate") && (
                <div className="project-add-icon">
                  <Button
                    className="project-btn"
                    title="Add"
                    onClick={(e) => fnOpenClientAddEditPopup(0)}
                  >
                    <i className="fa-regular fa-plus"></i>
                  </Button>
                </div>
              )}
              {commonservices.getLoginRoleData("Client", "IsDownload") && (
                <div className="expor-download-button d-flex">
                  <Button variant="project-btn ms-2" title="Export">
                    <ExportIocn onClick={handleDownloadExcel} />
                  </Button>
                  <Button variant="project-btn pe-0 ps-1" title="Download">
                    <DownloadIcon onClick={PDFDownload} />
                  </Button>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
      <div className="vault-tab vault-page">
        <div className="table-col">
          <div className="recent-task-section">
            {clientData.IsLoading ? (
              <>
                <Loader type={LoaderType.Table} count={4} />
              </>
            ) : (
              <div className="table-scroll">
                <Table id="ClienttblData" className="table table-inner-section">
                  <thead>
                    <tr>
                      <th className="width-300px">
                        {commonservices.LabelDisplayText("Name")}
                      </th>
                      <th className="width-300px">
                        {commonservices.LabelDisplayText("MobileNo")}
                      </th>
                      <th className="width-300px">
                        {commonservices.LabelDisplayText("Country")}
                      </th>
                      <th>{commonservices.LabelDisplayText("Email")}</th>
                      <th className="text-end width-100px">
                        {commonservices.LabelDisplayText("Action")}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="table-section-col">
                    {clientData.Data.length > 0 &&
                      clientData.Data.map((item, index) => (
                        <tr key={index}>
                          <td className="padding-top-bottom width-300px">
                            <div className="project-description">
                              {item.Name}
                            </div>
                          </td>
                          <td className="padding-top-bottom width-300px">
                            <div className="d-flex table-text-common">
                              {item.ContactNo}
                            </div>
                          </td>
                          <td className="padding-top-bottom width-300px">
                            <div className="d-flex table-text-common">
                              {item.Country}
                            </div>
                          </td>
                          <td className="padding-top-bottom">
                            <div className="d-flex table-text-common">
                              {item.EmailID}
                            </div>
                          </td>
                          <td className="text-end padding-top-bottom width-100px">
                            <div className="d-flex justify-content-end">
                              {commonservices.getLoginRoleData(
                                "Client",
                                "IsUpdate"
                              ) && (
                                <Button
                                  className="icon-btn me-1 h-auto"
                                  title="Edit"
                                  onClick={(e) =>
                                    fnOpenClientAddEditPopup(item.ClientID)
                                  }
                                >
                                  <Pencil />
                                </Button>
                              )}
                              {commonservices.getLoginRoleData(
                                "Client",
                                "IsDelete"
                              ) && (
                                <Button
                                  className="icon-btn h-auto w-auto"
                                  title="Delete"
                                  onClick={(e) => {
                                    confirmAlert({
                                      message: commonservices.LabelDisplayText(
                                        "AreYouSureToDoDelete"
                                      ),
                                      buttons: [
                                        {
                                          label:
                                            commonservices.LabelDisplayText(
                                              "Yes"
                                            ),
                                          onClick: () =>
                                            deleteData(item.ClientID),
                                        },
                                        {
                                          label:
                                            commonservices.LabelDisplayText(
                                              "No"
                                            ),
                                        },
                                      ],
                                    });
                                  }}
                                >
                                  <Trash />
                                </Button>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            )}
          </div>
        </div>
      </div>

      <Container className="pt-4">
        {/* <Row>
          <Col xs={12} md={6} lg={6}>
            <Breadcrumb>
              <Breadcrumb.Item href="/">
                <i className="fa-regular fa-house-chimney"></i>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                {commonservices.LabelDisplayText("Client")}
              </Breadcrumb.Item>
              {
                commonservices.getLoginRoleData("Client", "IsCreate") &&
                <div className="project-add-icon ms-2">
                  <Button
                    className="project-btn"
                    onClick={(e) => fnOpenClientAddEditPopup(0)}
                  >
                    <i className="fa-regular fa-plus"></i>
                  </Button>
                </div>
              }
            </Breadcrumb>
          </Col>
          <Col xs={12} md={6} lg={6} className="text-end">
            {
              commonservices.getLoginRoleData("Client","IsDownload") &&
            <div className="share-download-btn">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="button-tooltip">{commonservices.LabelDisplayText('ExportExcel')}</Tooltip>}
              >
                <Button className="icon-btn-lg text-dark mx-1">
                  <i className="fa-regular fa-file-export"></i>
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="button-tooltip">{commonservices.LabelDisplayText('ExportPDFs')}</Tooltip>}
              >
                <Button className="icon-btn-lg text-dark mx-1">
                  <i className="fa-regular fa-download"></i>
                </Button>
              </OverlayTrigger>
            </div>
            }
            
          </Col>
        </Row> */}
        {/* <Row>
        {
              clientData.IsLoading ?
              <>
                <Loader
                  type={LoaderType.Table}
                  count={4}
                />
                </>
                :
          <Col>
                <table className="table" id="tblData">
                  <thead>
                    <tr>
                      <th>{commonservices.LabelDisplayText("Name")}</th>
                      <th>{commonservices.LabelDisplayText("MobileNo")}</th>
                      <th>{commonservices.LabelDisplayText("Email")}</th>
                      <th>{commonservices.LabelDisplayText("Country")}</th>
                      <th className="text-end">{commonservices.LabelDisplayText("Action")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {clientData.Data.length > 0 &&
                      clientData.Data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-nowrap">{item.Name}</td>
                          <td className="text-nowrap">{item.ContactNo}</td>
                          <td className="text-nowrap">{item.EmailID}</td>
                          <td className="text-nowrap">{item.Country}</td>
                          <td className="text-end text-nowrap">
                            {
                              commonservices.getLoginRoleData("Client", "IsUpdate") &&
                              <Button
                                className="icon-btn me-1"
                                onClick={(e) =>
                                  fnOpenClientAddEditPopup(item.ClientID)
                                }
                              >
                                <i className="fa-regular fa-pen-to-square"></i>
                              </Button>
                            }
                            {
                              commonservices.getLoginRoleData("Client","IsDelete") &&
                            <Button
                              className="icon-btn"
                              onClick={(e) => {
                                confirmAlert({                                 
                                  message: commonservices.LabelDisplayText(
                                    "AreYouSureToDoDelete"
                                  ),
                                  buttons: [
                                    {
                                      label: commonservices.LabelDisplayText("Yes"),
                                      onClick: () => deleteData(item.ClientID),
                                    },
                                    {
                                      label: commonservices.LabelDisplayText("No"),
                                    },
                                  ],
                                });
                              }}
                            >
                              <i className="fa-regular fa-trash-can"></i>
                            </Button>
                            }
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                </Col>
            }
          
        </Row> */}

        {modalShow && (
          <AddClientModal
            id={CLIENTID}
            show={modalShow}
            bindList={bindList}
            onHide={() => setModalShow(false)}
          />
        )}
      </Container>
    </>
  );
}

export default Client;
