import React, { useEffect, useState } from "react";
import {
    Col,
    Container,
    Row,
    Table,
    Button
} from "react-bootstrap";
import UserPlaceholder from "../../assets/Icons/placeholder.svg";
import { ReactComponent as FilterIcon } from "../../assets/Icons/filter-icon.svg";
import { ReactComponent as EyeshowPassword } from "../../assets/Icons/eyeshow-password.svg";
import $ from "jquery";
import axios from "axios";
import { commonservices, AppConfigData } from "../../Services/commonService";
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import Loader, { LoaderType } from "../../Loader";
import ApproveRejectLeaveModal from "../../Modal/ApproveRejectLeaveModal";

function Leave() {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [leaveData, setleaveData] = useState({ Data: [], IsLoading: true });
    const [searchobj, setSearchobj] = useState({
        "startDate": null,
        "endDate": null,
        "title": "",
        "leaveType": "",
        "applicationStatus": "Pending",
        "myApprovalStatus": "Pending",
        "searchText": ""
    });
    const [IsShowApproveRejectLeaveModal, setIsShowApproveRejectLeaveModal] = useState(false);
    const [LeaveApplicationID, setLeaveApplicationID] = useState(0);

    function bindList(objSearchPara) {
        setIsOpen(false);
        if (objSearchPara === null || objSearchPara === undefined) {
            objSearchPara = searchobj;
        }
        else {
            setSearchobj(objSearchPara);
        }
        setleaveData({
            Data: [],
            IsLoading: true,
        });
        const axiosConfig = {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        };
        axios
            .post(
                `${AppConfigData.APIBasePath}v1/leaveapplication/getlistforapprove`,
                objSearchPara,
                axiosConfig
            )
            .then((res) => {

                if (res.data.status_code === 0) {
                    setleaveData({
                        Data: [],
                        IsLoading: false,
                    });
                } else if (res.data.status_code === 1) {
                    setleaveData({ Data: res.data.data, IsLoading: false });
                } else if (res.data.status_code === 2) {
                    commonservices.ClearLoginDetails();
                    navigate("/");
                }
                BindDataTables("table#LeavetblData");
            })
            .catch((err) => { });
    }

    useEffect(() => {
        BindDataTables("table#LeavetblData");
        bindList();
    }, []);

    function BindDataTables(selecter) {
        $(document).ready(function () {
            $(selecter)
                .DataTable({
                    destroy: true,
                    searching: false,
                    dom: "ft<'tbl-info'<'length-info'li<'paggination'p>>>",
                    aoColumnDefs: [
                        {
                            bSortable: false,
                            aTargets: [-1],
                        },
                    ],
                })
        });
    }

    const concernedElement = document.querySelector(".leave-filter-col");
    document.addEventListener("click", (event) => {
        if (concernedElement !== null) {
            if (!concernedElement.contains(event.target) && document.contains(event.target)) {
                setIsOpen(false)
            }
        }
    });

    return (
        <>
            <div className="position-relative leave-page">
                <Container fluid className="p-0">
                    <div className="table-section-col common-header-page">
                        <Row>
                            <Col xl={8} lg={6} md={4}>
                                <div className="page-title-left">Leave Request</div>
                            </Col>
                            <Col xl={4} lg={6} md={8} className="mt-auto mb-auto">
                                <div class="d-flex align-items-center justify-content-end">
                                    <div className="inner-search-bar inner-search-grey w-100">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={commonservices.LabelDisplayText('SearchAnything')}
                                            name="searchData"
                                            value={searchobj.title}
                                            onChange={(e) =>
                                                setSearchobj({
                                                    ...searchobj,
                                                    ["title"]: e.target.value,
                                                })
                                            }
                                            onKeyUp={(e) => e.keyCode === 13 && bindList()}
                                        />
                                        <Button
                                            variant="btn btn-icon-btn-lg search-button"
                                            title="Search"
                                            onClick={(e) => bindList()}
                                        >
                                            <i className="fa-light fa-magnifying-glass"></i>
                                        </Button>
                                    </div>
                                    <div className="leave-filter-col">
                                        <div className="custom-modal">
                                            <button className="bg-transparent border-0" onClick={() => setIsOpen(!isOpen)} title="Filter">
                                                <FilterIcon />
                                            </button>
                                            {isOpen && (
                                                <div className="custom-modal-body">
                                                    <Formik>
                                                        <Form>
                                                            <Row>
                                                                <Col lg={12} className="from-bottom-margin">
                                                                    <div className="form-floating ">
                                                                        <input
                                                                            type="text"
                                                                            name="Title"
                                                                            onKeyUp={(e) => e.keyCode === 13 && bindList()}
                                                                            onClick={(e)=> {                                                                                
                                                                                window.addEventListener('keydown',function(e) {
                                                                                    
                                                                                    if (e.keyIdentifier=='U+000A' || e.keyIdentifier=='Enter' || e.keyCode==13) {
                                                                                        // bindList()
                                                                                        if (e.target.nodeName=='INPUT' && e.target.type=='text') {
                                                                                            
                                                                                            e.preventDefault();
                                                                                               
                                                                                            return false;
                                                                                        }
                                                                                    }
                                                                                }, true);
                                                                            }}
                                                                            className="form-control"
                                                                            value={searchobj.title}
                                                                            placeholder={commonservices.LabelDisplayText('Title')}
                                                                            onChange={(e) =>
                                                                                setSearchobj({
                                                                                    ...searchobj,
                                                                                    ["title"]: e.target.value,
                                                                                })
                                                                            }
                                                                        />
                                                                        <label>{commonservices.LabelDisplayText('Title')}</label>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={6} className="from-bottom-margin">
                                                                    <div className="form-floating">
                                                                        <input

                                                                            type="date"
                                                                            name="StartDate"
                                                                            className="form-control calendar-icon"
                                                                            placeholder={commonservices.LabelDisplayText('StartDate')}
                                                                            onChange={(e) =>
                                                                                setSearchobj({
                                                                                    ...searchobj,
                                                                                    ["startDate"]: e.target.value,
                                                                                })
                                                                            }
                                                                            value={searchobj.startDate}
                                                                        />
                                                                        <label>{commonservices.LabelDisplayText('StartDate')}</label>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={6} className="from-bottom-margin">
                                                                    <div className="form-floating">
                                                                        <input
                                                                            type="date"
                                                                            name="EndDate"
                                                                            className="form-control calendar-icon"
                                                                            placeholder={commonservices.LabelDisplayText('EndDate')}
                                                                            onChange={(e) =>
                                                                                setSearchobj({
                                                                                    ...searchobj,
                                                                                    ["endDate"]: e.target.value,
                                                                                })
                                                                            }
                                                                            value={searchobj.endDate}
                                                                        />
                                                                        <label>{commonservices.LabelDisplayText('EndDate')}</label>
                                                                    </div>
                                                                </Col>

                                                                <Col xs={6} className="from-bottom-margin">
                                                                    <div className="form-floating">
                                                                        <Field as="select" name="LeaveApplicationStatus" className="form-control down-arrow-icon" value={searchobj.applicationStatus}
                                                                            onChange={(e) =>                                                                                
                                                                                setSearchobj({
                                                                                    ...searchobj,
                                                                                    ["applicationStatus"]: e.target.value,
                                                                                })
                                                                            }
                                                                        >
                                                                            <option value="">{commonservices.LabelDisplayText("Select")}</option>
                                                                            <option value="Approved">Approved</option>
                                                                            <option value="Rejected">Rejected</option>
                                                                            <option value="Cancelled">Cancelled</option>
                                                                            <option value="Pending">Pending</option>
                                                                        </Field>
                                                                        <label>{commonservices.LabelDisplayText("LeaveApplicationStatus")}</label>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={6} className="from-bottom-margin">
                                                                    <div className="form-floating">
                                                                        <Field as="select" name="MyApprovalStatus" className="form-control down-arrow-icon" value={searchobj.myApprovalStatus}
                                                                            onChange={(e) =>
                                                                                setSearchobj({
                                                                                    ...searchobj,
                                                                                    ["myApprovalStatus"]: e.target.value,
                                                                                })
                                                                            }
                                                                        >
                                                                            <option value="">{commonservices.LabelDisplayText("Select")}</option>
                                                                            <option value="Approved">Approved</option>
                                                                            <option value="Rejected">Rejected</option>
                                                                            <option value="Cancelled">Cancelled</option>
                                                                            <option value="Pending">Pending</option>
                                                                        </Field>
                                                                        <label>{commonservices.LabelDisplayText("MyApprovalStatus")}</label>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <div className="custom-modal-footer">
                                                                <Button className="btn btn-secondary"
                                                                    onClick={(e) => {
                                                                        
                                                                        bindList({
                                                                            "startDate": null,
                                                                            "endDate": null,
                                                                            "title": "",
                                                                            "leaveType": "",
                                                                            "applicationStatus": "",
                                                                            "myApprovalStatus": "",
                                                                            "searchText": ""
                                                                        });
                                                                    }}
                                                                    title="Clear"
                                                                >
                                                                    Clear
                                                                </Button>
                                                                <Button className="btn btn-primary"
                                                                    onClick={(e) => 
                                                                        bindList()}
                                                                        title="Filter"
                                                                >

                                                                    Filter
                                                                </Button>
                                                            </div>
                                                        </Form>
                                                    </Formik>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
                <Container className="p-0">
                    <div className="vault-tab vault-page">
                        <div className="table-col leave-request-col">
                            <div className="recent-task-section">
                                {
                                    leaveData.IsLoading ?
                                        <>
                                            <Loader
                                                type={LoaderType.Table}
                                                count={4}
                                            />
                                        </>
                                        :
                                        <div className="table-scroll">
                                            <Table id="LeavetblData" className="table table-inner-section">
                                                <thead>
                                                    <tr>
                                                        <th className="width-300px"><div className="username ps-0">Name</div></th>
                                                        <th className="width-100px ">Leave</th>
                                                        <th className="width-300px">Subject</th>
                                                        <th className="text-nowrap">Date From</th>
                                                        <th className="text-nowrap width-100px">#Days</th>
                                                        <th className="text-nowrap width-100px">Status</th>
                                                        <th className="action-class text-end width-100px">
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="table-section-col">
                                                    {
                                                        leaveData.Data.length > 0 &&
                                                        leaveData.Data.map((itemLeave, index) => (
                                                            <tr>
                                                                <td className="padding-top-bottom text-nowrap width-300px" title={itemLeave.EmployeeName}>
                                                                    <div className="d-flex align-items-center" >
                                                                        <img
                                                                            className="rounded-circle"
                                                                            // src={itemLeave.ProfilePic}
                                                                            src={itemLeave.ProfilePic !== null && itemLeave.ProfilePic !== '' ? itemLeave.ProfilePic : UserPlaceholder}
                                                                            alt={commonservices.getInitials(itemLeave.EmployeeName)}
                                                                            title={itemLeave.EmployeeName}
                                                                            width="34"
                                                                            height="34"
                                                                        />
                                                                        <div className="username project-description">
                                                                            {itemLeave.EmployeeName}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className="100px text-center" title={itemLeave.LeaveName}>
                                                                    <span className={`rounded-circle leave-request-type mx-auto ${itemLeave.LeaveCode.toLowerCase()}`}>
                                                                        {itemLeave.LeaveCode}
                                                                    </span>
                                                                </td>
                                                                <td className="text-nowrap width-300px padding-top-bottom" title={itemLeave.Title}>
                                                                    <div className="vault-description table-text-common">{itemLeave.Title}</div>
                                                                </td>
                                                                <td className="width-200px">
                                                                    <div className="d-flex table-text-common">{commonservices.getDateInDDMMYYYY(itemLeave.StartDate)}</div>
                                                                </td>
                                                                <td className="text-nowrap padding-top-bottom">
                                                                    <div className="table-text-common"> {itemLeave.NoOfDays}</div>
                                                                </td>
                                                                <td className="text-nowrap padding-top-bottom">
                                                                    <div className="leave-status">
                                                                        <label className={`leave-label ${itemLeave.ApplicationStatus.toLowerCase()}`}>
                                                                            {itemLeave.ApplicationStatus}
                                                                        </label>
                                                                    </div>
                                                                </td>
                                                                <td className="text-end text-nowrap">
                                                                    <Button className="icon-btn" title="Show" onClick={(e) => { setLeaveApplicationID(itemLeave.LeaveApplicationID); setIsShowApproveRejectLeaveModal(true); }}>
                                                                        <EyeshowPassword />
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }


                                                </tbody>
                                            </Table>
                                        </div>

                                }


                            </div>
                        </div>
                    </div>
                </Container>
            </div>

            {IsShowApproveRejectLeaveModal && (
                <ApproveRejectLeaveModal
                    show={IsShowApproveRejectLeaveModal}
                    leaveApplicationID={LeaveApplicationID}
                    bindList={bindList}
                    onHide={() => setIsShowApproveRejectLeaveModal(false)}
                />
            )}
        </>
    );
}
export default Leave;